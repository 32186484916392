import {createAsyncThunk} from "@reduxjs/toolkit";

import selectCourseChapterKeys from "./selectCourseChapterKeys";
import {courseService} from "../services/courseService";
import {keyProvider} from "../keyProvider";
import type {AppDispatch, RootState} from "../store";
import selectCourse from "../courses/selectCourse";

const deleteChapter = createAsyncThunk<
	{id: number; courseId: number; keys: string[]},
	{courseId: number; chapterId: number},
	{dispatch: AppDispatch; state: RootState}
>("chapters/deleteChapter", async function ({courseId, chapterId}, thunkApi) {
	const state = thunkApi.getState();
	const courseKey = keyProvider.course(courseId);

	const course = selectCourse(state, courseKey);
	if (!course) {
		throw new Error(`Course ${courseId} is not found`);
	}

	await courseService.deleteChapter(courseId, chapterId, course.type);

	const keys = selectCourseChapterKeys(state, courseKey).slice();

	const i = keys.indexOf(keyProvider.chapter(chapterId));
	if (i >= 0) {
		keys.splice(i, 1);
	}

	return {id: chapterId, courseId, keys};
});

export default deleteChapter;
