import {axiosInstance as client} from "./axiosInstance";
import type ChapterSession from "../chapterSessions/ChapterSession";
import {getChapterContent, getExercises} from "./courseService";
import type {ChapterContent} from "./dtos/ChapterContent";
import type {Exercise} from "./dtos/Exercise";
import type {CompletionStatus} from "../outcomes/ExerciseOutcomes";

const chapterSessionsService = {
	getChapterSessionOutcomes: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		sessionStartTime: string
	): Promise<
		{
			exerciseId: number;
			score: number;
			completionStatus: CompletionStatus;
			assessorComment?: string;
		}[]
	> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}` +
			`/sessions/${sessionStartTime}/outcomes`;

		const {data} = await client.get(url);

		return data;
	},

	getChapterSessions: async (
		studentId: number,
		courseId: number,
		chapterId: number
	): Promise<ChapterSession[]> => {
		const url = `/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}/sessions`;
		const {data} = await client.get(url);
		return data.content;
	},

	getChapterSessionContent: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		sessionStartTime: string
	): Promise<ChapterContent> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}` +
			`/sessions/${sessionStartTime}/contents`;

		return getChapterContent(url, chapterId);
	},

	getChapterSessionExercises: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		sessionStartTime: string,
		exerciseIds: number[]
	): Promise<Exercise[]> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}` +
			`/sessions/${sessionStartTime}/exercises`;

		return getExercises(url, exerciseIds);
	},

	createChapterSession: async (
		studentId: number,
		courseId: number,
		chapterId: number
	): Promise<ChapterSession> => {
		const url = `/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}/sessions`;
		const {data} = await client.post(url, {});
		return data;
	},

	submitChapterSession: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		sessionStartTime: string
	): Promise<ChapterSession> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}` +
			`/sessions/${sessionStartTime}`;
		const {data} = await client.patch(
			url,
			{state: "submitted"},
			{
				headers: {
					"Content-Type": "application/merge-patch+json",
				},
			}
		);

		return data;
	},
};

export default chapterSessionsService;
