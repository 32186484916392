import type {AxiosInstance} from "axios";

import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type StudentStatus from "../enrolment/StudentStatus";
import type {Page, PagedResponse} from "../../helpers/paginatedSearchHelpers";
import {createPage} from "../../helpers/paginatedSearchHelpers";

export type CourseStudentSearchCriteria = {
	ids?: number[];
	enrolledAfter?: string;
	enrolledBefore?: string;
	studentStatus?: StudentStatus;
	studyStatus?: "not_started" | "started";
	query?: string;
};

export type CourseStudentSearchResult = {
	id: number;
	userName: string;
	firstName?: string;
	lastName?: string;
	status: StudentStatus;
	enrolmentDate: string;
	studyStartDate?: string;
};

export type EnrolmentApplicationSearchCriteria = {
	query?: string;
};

export type EnrolmentApplicationSearchResult = {
	id: number;
	userName: string;
	firstName: string;
	lastName: string;
	applicationDate: string;
};

function createService(client: AxiosInstance) {
	async function enrolInCourse(
		courseId: number,
		userId: number
	): Promise<void> {
		try {
			await client.post(`/api/courses/${courseId}/students`, {id: userId});
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 409) {
				throw {code: "conflict"};
			}

			throw err;
		}
	}

	async function recordDecisionOnEnrolmentApplications(
		courseId: number,
		statement: "accepted" | "rejected",
		applicantIds: number[]
	): Promise<void> {
		const url = `/api/courses/${courseId}/enrolment-application-decisions`;

		await client.post(url, {statement, applicantIds});
	}

	async function searchCourseStudents(
		courseId: number,
		criteria: CourseStudentSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<CourseStudentSearchResult>> {
		const url = `/api/courses/${courseId}/students`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		criteria.ids?.forEach((id) => params.append("id", id.toString()));

		if (!criteria.studentStatus || criteria.studentStatus === "active") {
			params.append("enrolmentStatus", "accepted");
		}

		if (!criteria.studentStatus || criteria.studentStatus === "suspended") {
			params.append("enrolmentStatus", "suspended");
		}

		if (criteria.enrolledAfter) {
			params.append("enrolledAfter", criteria.enrolledAfter);
		}
		if (criteria.enrolledBefore) {
			params.append("enrolledBefore", criteria.enrolledBefore);
		}
		if (criteria.studyStatus) {
			params.append("studyStatus", criteria.studyStatus);
		}
		if (criteria.query) {
			params.append("query", criteria.query);
		}

		type Content = Omit<CourseStudentSearchResult, "studentStatus"> & {
			enrolmentStatus: "applied" | "accepted" | "suspended";
		};

		const {data} = await client.get<PagedResponse<Content>>(url, {params});

		return createPage<CourseStudentSearchResult, number, Content>(
			client,
			data.content,
			data.links,
			{
				preprocessItem({enrolmentStatus, ...r}) {
					return {
						...r,
						status: enrolmentStatus === "suspended" ? "suspended" : "active",
					};
				},
			}
		);
	}

	async function searchEnrolmentApplications(
		courseId: number,
		criteria: EnrolmentApplicationSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<EnrolmentApplicationSearchResult>> {
		const url = `/api/courses/${courseId}/students`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		params.append("enrolmentStatus", "applied");

		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	}

	async function selectIdsOfUsersHavingPendingEnrolmentApplications(
		courseId: number,
		criteria: EnrolmentApplicationSearchCriteria
	): Promise<number[]> {
		const url = `/api/courses/${courseId}/student-ids`;

		const params = new URLSearchParams();

		params.append("enrolmentStatus", "applied");
		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return data.content;
	}

	async function suspendStudents(
		courseId: number,
		studentIds: number[]
	): Promise<void> {
		const url = `/api/courses/${courseId}/suspended-students`;

		await client.post(url, studentIds);
	}

	async function unsuspendStudents(
		courseId: number,
		studentIds: number[]
	): Promise<void> {
		const url = `/api/courses/${courseId}/suspended-students`;

		await client.delete(url, {data: studentIds});
	}

	return {
		enrolInCourse: enrolInCourse,
		recordDecisionOnEnrolmentApplications: recordDecisionOnEnrolmentApplications,
		searchCourseStudents: searchCourseStudents,
		searchEnrolmentApplications: searchEnrolmentApplications,
		selectIdsOfUsersHavingPendingEnrolmentApplications: selectIdsOfUsersHavingPendingEnrolmentApplications,
		suspendStudents: suspendStudents,
		unsuspendStudents: unsuspendStudents,
	};
}

export default createService(client);
