import {useLocalization} from "@fluent/react";
import DeleteIcon from "@mui/icons-material/Delete";
import {Box, Grid, IconButton, TextField} from "@mui/material";
import React from "react";

import TextEditor from "../../utils/TextEditor";
import type FileUploader from "../../utils/attachments/FileUploader";

const MultiExerciseChoice = ({
	i,
	choice,
	fileUploader,
	onChange,
	onDelete,
}: {
	i: number;
	choice: {
		id: number;
		score: number;
		initialText: string;
		initialComment: string;
		text: string;
		comment: string;
	};
	fileUploader?: FileUploader;
	onChange: (
		diff: {score?: number; text?: string; comment?: string},
		i: number
	) => void;
	onDelete: (e: number) => void;
}): JSX.Element => {
	const {l10n} = useLocalization();
	return (
		<Box mb={2}>
			<Grid container spacing={3} wrap="nowrap">
				<Grid item xs={2}>
					<TextField
						id={`score-number-${choice.id}`}
						label={l10n.getString(
							"learning-material-multi-exercise-choice-score",
							null,
							"Score"
						)}
						type="number"
						value={choice.score}
						onChange={(e) => onChange({score: Number(e.target.value)}, i)}
					/>
				</Grid>

				<Grid item xs>
					<TextEditor
						id={`choice-${choice.id}`}
						label={
							l10n.getString(
								"learning-material-multi-exercise-editor-label-choice",
								null,
								"Choice"
							) +
							" " +
							`${i + 1}`
						}
						value={choice.text}
						fileUploader={fileUploader}
						onChange={(value) => onChange({text: value}, i)}
						mode="inline"
						initialValue={choice.initialText}
					/>

					<Box mt={2}>
						<TextEditor
							id={`comment-${choice.id}`}
							label={l10n.getString(
								"learning-material-multi-exercise-editor-label-comment",
								null,
								"Comment"
							)}
							value={choice.comment}
							fileUploader={fileUploader}
							onChange={(value) => onChange({comment: value}, i)}
							mode="inline"
							initialValue={choice.initialComment}
						/>
					</Box>
				</Grid>

				<Grid item>
					<Box mt={1} mx={-1.5}>
						<IconButton onClick={() => onDelete(i)} color="primary">
							<DeleteIcon />
						</IconButton>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default React.memo(MultiExerciseChoice);
