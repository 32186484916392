import {createSlice} from "@reduxjs/toolkit";

import fetchEnrolmentApplications from "./fetchEnrolmentApplications";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import type UserCourse from "../models/UserCourse";
import submitEnrolmentApplication from "./submitEnrolmentApplication";

type State = {
	applications: UserCourse[];
	status: FetchStatus;
};

const initialState: State = {
	applications: [],
	status: "none",
};

const enrolmentApplicationsSlice = createSlice({
	name: "enrolmentApplications",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchEnrolmentApplications.pending, (state) => {
			state.status = "pending";
		});
		builder.addCase(fetchEnrolmentApplications.rejected, (state, action) => {
			state.status = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchEnrolmentApplications.fulfilled, (state, action) => {
			state.applications = action.payload;
			state.status = "succeeded";
		});

		builder.addCase(
			submitEnrolmentApplication.fulfilled,
			(state, {payload}) => {
				if (payload.status === "pending") {
					state.applications = [];
					state.status = "none";
				}
			}
		);
	},
});

export default enrolmentApplicationsSlice.reducer;
