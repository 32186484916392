import {Localized} from "@fluent/react";
import ReplyIcon from "@mui/icons-material/Reply";
import {
	Divider,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {createStyles, makeStyles, withStyles} from "@mui/styles";
import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";

import StyledAvatar from "./StyledAvatar";
import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import selectChapter from "../../store/chapters/selectChapter";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type TutorQuestion from "../../store/tutorQuestions/TutorQuestion";
import {scrollIntoView} from "../../helpers/scrollHelpers";

const useStyles = makeStyles((theme) =>
	createStyles({
		selected: {
			"&.Mui-selected": {
				background: alpha(theme.palette.secondary.main, 0.25),
				"&:hover": {background: alpha(theme.palette.secondary.main, 0.25)},
			},
		},
		root: {
			"&:hover": {background: alpha(theme.palette.secondary.main, 0.1)},
		},
	})
);

const Replied = withStyles({
	root: {
		verticalAlign: "sub",
	},
})(ReplyIcon);

const MessageListItem = (props: {
	selected: boolean;
	messageKey: string;
	infoSelector: (
		message: TutorQuestion
	) => {
		avatar: React.ReactElement;
		date?: string;
		name: React.ReactNode;
		answered?: boolean;
	};
}): JSX.Element => {
	const classes = useStyles();
	const {selected, messageKey, infoSelector} = props;

	const message = useAppSelector(
		(state) => state.tutorQuestions.byKey[messageKey]
	);

	const chapterTitle = useAppSelector((state) =>
		message?.chapterId ? (
			selectChapter(state, keyProvider.chapter(message.chapterId))?.title
		) : (
			<Localized id="tutoring-subject-general-question">
				General question
			</Localized>
		)
	);

	const formatDate = useDateFormat();

	const item = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (selected) {
			setTimeout(() => scrollIntoView(item.current), 300);
		}
	}, [selected]);

	if (!message) {
		return <></>;
	}

	const {avatar, date, name, answered} = infoSelector(message);

	return (
		<>
			<ListItem classes={{root: classes.root}} disablePadding>
				<ListItemButton
					ref={item}
					selected={selected}
					component={Link}
					to={`?questionId=${message.id}`}
					classes={{selected: classes.selected}}
				>
					<ListItemAvatar>
						<StyledAvatar>{avatar}</StyledAvatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Grid container justifyContent="space-between" spacing={1}>
								<Grid item>
									<Typography
										component="span"
										variant="subtitle1"
										color="textPrimary"
									>
										{name}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										component="span"
										variant="caption"
										color="textSecondary"
									>
										{date && formatDate(parseDate(date))}
									</Typography>
								</Grid>
							</Grid>
						}
						secondary={
							answered ? (
								<>
									<Replied fontSize="small" /> {chapterTitle}
								</>
							) : (
								chapterTitle
							)
						}
					/>
				</ListItemButton>
			</ListItem>
			<Divider variant="inset" component="li" />
		</>
	);
};

export default MessageListItem;
