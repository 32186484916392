import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Container,
	Dialog,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Popover,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";

import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import ExerciseType from "../../store/exercises/ExerciseType";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type {
	ProgExercise,
	SupportedLanguage,
} from "../../store/services/dtos/EditableExercise";
import type ProgExerciseBuildResult from "../../store/services/dtos/ProgExerciseBuildResult";
import exerciseService from "../../store/services/exerciseService";
import type ProgFile from "../../store/studentResponses/ProgFile";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import CodeEditor from "../../utils/CodeEditor";
import type {CodeEditorApi} from "../../utils/CodeEditor/CodeEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import ContentEditorFooter from "./ContentEditorFooter";
import type ExerciseEditorProps from "./ExerciseEditorProps";
import ExerciseTagsSelector from "./ExerciseTagsSelector";
import PrivacyLevelSelector from "./PrivacyLevelSelector";
import useExerciseFileUploader from "./useExerciseFileUploader";
import selectCourse from "../../store/courses/selectCourse";
import useSnackbar from "../../store/ui/useSnackbar";
import TextEditor from "../../utils/TextEditor/TextEditor";
import SubmitButton from "../../utils/SubmitButton";
import DbSchemaAndState from "../content/exercises/prog/DbSchemaAndState";
import DbData from "../content/exercises/prog/DbData";
import type DbExerciseSolutionType from "../../store/exercises/prog/DbExerciseSolutionType";
import {
	TestRunParams,
	progExerciseService,
} from "../../store/services/progExerciseService";
import ProgAdditionalFile from "./ProgAdditionalFile";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import ProgFileEditorDialog from "./ProgFileEditorDialog";
import ProgExerciseTests from "./ProgExerciseTests";
import ProgExerciseTest from "../../store/exercises/prog/ProgExerciseTest";
import type ComparisonStrictness from "../../store/exercises/ComparisonStrictness";

const courseCoreToLanguage: {[key: string]: SupportedLanguage} = {
	"C++": "cpp",
	C: "c",
	CSHARP: "cs",
	DART: "dart",
	JAVA: "java",
	JS: "js",
	KOTLIN: "kotlin",
	PHP: "php",
	PYTHON3: "python",
	RUBY: "ruby",
	RUST: "rust",
	SCALA: "scala",
	SQL: "sql",
};

const languageDefaultExtensions: {[key in SupportedLanguage]: string} = {
	cpp: ".cpp",
	c: ".c",
	cs: ".cs",
	dart: ".dart",
	java: ".java",
	js: ".js",
	kotlin: ".kt",
	php: ".php",
	python: ".py",
	ruby: ".rb",
	rust: ".rs",
	scala: ".scala",
	sql: ".sql",
};

type Draft = {
	id: number;
	maxScore: number;
	comparisonStrictness: ComparisonStrictness;
	difficultyLevel: number;
	privacy: ExercisePrivacy;
	tags: string[];
};

type ProgExerciseEditorProps = ExerciseEditorProps & {
	onBuild: (
		exerciseId: number,
		exercise: ProgExercise
	) => Promise<ProgExerciseBuildResult>;
	onExampleOutputChanged: (exerciseId: number, output: string) => void;
};

type FileManager = {
	filename: string;
	load: () => Promise<ProgFile>;
	update: (content: string) => Promise<boolean>;
};

function MainFileField(props: {
	value: string;
	onChange: (value: string) => void;
	required?: boolean;
	error?: boolean;
}) {
	return (
		<TextField
			label={
				<Localized id="exercise-prog-editor-main-file-label">
					Main file
				</Localized>
			}
			fullWidth
			value={props.value}
			required={props.required}
			onChange={({target}) => props.onChange(target.value)}
			error={props.error}
		/>
	);
}

function RunCommandField(props: {
	value: string;
	onChange: (value: string) => void;
}) {
	return (
		<TextField
			label={
				<Localized id="exercise-prog-editor-run-command-label">
					Run comand
				</Localized>
			}
			fullWidth
			value={props.value}
			onChange={({target}) => props.onChange(target.value)}
		/>
	);
}

function AdditionalFiles(props: {
	courseId: number;
	exerciseId: number;
	onChange: (files: string[]) => void;
	files: string[];
	onOpenFileEditor: (fileManager: FileManager) => void;
	onConfirmDelete: (deleted: string, onConfirm: () => Promise<void>) => void;
}) {
	const {courseId, exerciseId, files, onChange} = props;

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();
	const generalError = l10n.getString(
		"error-general",
		null,
		"An error has occured"
	);

	async function uploadFile(file: File) {
		try {
			await progExerciseService.uploadFile(courseId, exerciseId, {
				fileGroup: "lib",
				file,
			});

			return true;
		} catch {
			showSnackbar("error", generalError);
			return false;
		}
	}

	async function addAdditionalFile(e: React.ChangeEvent<HTMLInputElement>) {
		const fileList = e.target.files;

		if (!fileList || exerciseId === 0) {
			return;
		}

		const uploaded = await uploadFile(fileList[0]);
		if (uploaded) {
			onChange(files.concat(fileList[0].name));
		}
	}

	async function removeAdditionalFile(name: string) {
		if (exerciseId === 0) {
			return;
		}

		try {
			await progExerciseService.deleteFile(courseId, exerciseId, {
				fileGroup: "lib",
				filename: name,
			});
		} catch {
			showSnackbar("error", generalError);
			return;
		}

		onChange(files.filter((file) => file !== name));
	}

	async function openAdditionalFile(filename: string) {
		props.onOpenFileEditor({
			filename,
			load: () => progExerciseService.getFile(courseId, exerciseId, filename),
			update: (content) => uploadFile(new File([content], filename)),
		});
	}

	return (
		<>
			<Box display="flex" alignItems="center" onChange={addAdditionalFile}>
				<Typography variant="subtitle2">
					<Localized id="exercise-prog-editor-additional-files-label">
						Additional files
					</Localized>
				</Typography>
				<IconButton size="small" color="primary" component="label">
					<AddIcon />
					<input type="file" hidden />
				</IconButton>
			</Box>

			{props.files.map((file) => (
				<ProgAdditionalFile
					key={file}
					file={file}
					onDelete={() =>
						props.onConfirmDelete(file, () => removeAdditionalFile(file))
					}
					onClick={() => openAdditionalFile(file)}
				/>
			))}
		</>
	);
}

function ConsoleProgTestsComp(
	props: {
		courseId: number;
		exerciseId: number;
		onExampleOutputChanged: (val: string) => void;
		onOpenFileEditor: (fileManager: FileManager) => void;
		onConfirmDelete: (deleted: string, onConfirm: () => Promise<void>) => void;
	},
	ref: React.ForwardedRef<{refetch: () => Promise<void>}>
) {
	const {courseId, exerciseId, onExampleOutputChanged} = props;

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();
	const generalError = l10n.getString(
		"error-general",
		null,
		"An error has occured"
	);

	const [tests, setTests] = useState<ProgExerciseTest[]>(() => []);
	const fetchTests = useCallback(
		() =>
			progExerciseService
				.getTests(courseId, exerciseId)
				.then((res) => setTests(res)),
		[courseId, exerciseId]
	);
	useEffect(() => {
		fetchTests();
	}, [fetchTests]);

	useEffect(() => {
		let i = tests.length - 1;

		while (i >= 0 && tests[i].status !== "updated") {
			i--;
		}

		onExampleOutputChanged(i >= 0 ? tests[i].exampleOutput : "");
	}, [onExampleOutputChanged, tests]);

	useImperativeHandle(ref, () => ({refetch: fetchTests}), [fetchTests]);

	async function addTest() {
		const test = await progExerciseService.addTest(courseId, exerciseId);
		setTests((prev) => prev.concat(test));
		return test.number;
	}

	async function deleteTest(number: number) {
		await progExerciseService.deleteTest(courseId, exerciseId, number);
		setTests((prev) => prev.filter((t) => t.number !== number));
	}

	async function runTest(runParams: TestRunParams) {
		const result = await progExerciseService.runTest(
			courseId,
			exerciseId,
			runParams
		);
		const output = result.status === "updated" ? result.output : "";

		setTests((prev) => {
			const upd = [...prev];
			const ind = prev.findIndex((t) => t.number === runParams.number);

			upd[ind] = {...upd[ind], exampleOutput: output, status: result.status};

			if (result.status === "error") {
				upd[ind].errorDescription = result.errorDescription;
			}

			return upd;
		});
	}

	async function uploadTestFile(testNumber: number, file: File) {
		try {
			await progExerciseService.uploadFile(courseId, exerciseId, {
				fileGroup: "inputfile",
				file,
				testNumber,
			});
			return true;
		} catch {
			showSnackbar("error", generalError);
			return false;
		}
	}

	async function addOrUpdateTestFile(testNumber: number, file: ProgFile) {
		const ind = tests.findIndex((t) => t.number === testNumber);

		const fileInd = tests[ind].inputFiles.findIndex(
			(f) => f.name === file.name
		);

		let updatedFiles: ProgFile[];
		if (fileInd >= 0) {
			updatedFiles = tests[ind].inputFiles.slice();
			updatedFiles[fileInd] = file;
		} else {
			updatedFiles = tests[ind].inputFiles.concat(file);
		}

		await progExerciseService.updateTestFiles(
			courseId,
			exerciseId,
			tests[ind].number,
			updatedFiles
		);

		setTests((prev) => {
			const upd = prev.slice();
			upd[ind] = {
				...upd[ind],
				status: "not_updated",
				inputFiles: updatedFiles,
			};
			return upd;
		});
	}

	const addTestInputFile = async (testNumber: number, file: File) => {
		const uploaded = uploadTestFile(testNumber, file);
		if (!uploaded) {
			return;
		}

		await addOrUpdateTestFile(testNumber, {
			name: file.name,
			content: await file.text(),
		});
	};

	const updateTestInputFile = async (
		testNumber: number,
		filename: string,
		content: string
	) => {
		const uploaded = uploadTestFile(testNumber, new File([content], filename));
		if (!uploaded) {
			return false;
		}

		await addOrUpdateTestFile(testNumber, {name: filename, content});

		return true;
	};

	const deleteTestInputFile = async (testNumber: number, filename: string) => {
		try {
			await progExerciseService.deleteFile(courseId, exerciseId, {
				fileGroup: "inputfile",
				filename,
				testNumber,
			});
		} catch {
			showSnackbar("error", generalError);
			return;
		}

		setTests((prev) => {
			const upd = [...prev];
			const ind = prev.findIndex((t) => t.number === testNumber);
			upd[ind] = {
				...upd[ind],
				inputFiles: upd[ind].inputFiles.filter((f) => f.name !== filename),
			};
			return upd;
		});
	};

	const openTestInputFile = (testNumber: number, filename: string) => {
		props.onOpenFileEditor({
			filename,
			load: async () =>
				tests
					.find((t) => t.number === testNumber)
					?.inputFiles.find((f) => f.name === filename) ?? {
					content: "",
					name: filename,
				},
			update: (content) => updateTestInputFile(testNumber, filename, content),
		});
	};

	return (
		<ProgExerciseTests
			tests={tests}
			onRunTest={runTest}
			onAddTest={addTest}
			onDeleteTest={deleteTest}
			onUploadInputFile={addTestInputFile}
			onDeleteInputFile={(testNumber, filename) =>
				props.onConfirmDelete(filename, () =>
					deleteTestInputFile(testNumber, filename)
				)
			}
			onClickInputFile={openTestInputFile}
		/>
	);
}
const ConsoleProgTests = React.forwardRef(ConsoleProgTestsComp);

function ComparisonStrictnessSelector(props: {
	value: ComparisonStrictness;
	onChange: (value: ComparisonStrictness) => void;
}) {
	const {onChange, value} = props;

	return (
		<>
			<Box mb={2}>
				<Typography variant="subtitle2">
					<Localized id="exercise-prog-editor-strictness-label">
						Output comparison strictness
					</Localized>
				</Typography>
			</Box>
			<RadioGroup
				onChange={({target}) => {
					onChange(target.value as ComparisonStrictness);
				}}
				value={value}
			>
				<FormControlLabel
					value={"strict"}
					label={
						<Localized id="exercise-prog-strictness-strict">
							The output of the program must be exactly the same as the example
							output (the most strict comparison level)
						</Localized>
					}
					control={<Radio />}
				/>
				<FormControlLabel
					value="ignore_space"
					label={
						<Localized id="exercise-prog-strictness-ignore-space">
							{`The verification of program output does not account for
					whitespace characters like "\\n", "\\t" and " "`}
						</Localized>
					}
					control={<Radio />}
				/>
				<FormControlLabel
					value="ignore_case"
					label={
						<Localized id="exercise-prog-strictness-ignore-case">
							The verification of program output is not case-sensitive
						</Localized>
					}
					control={<Radio />}
				/>
				<FormControlLabel
					value="ignore_space_and_case"
					label={
						<Localized id="exercise-prog-strictness-ignore-space-case">
							The verification of program output does not account for whitespace
							and is not case-sensitive (the least strict comparison level)
						</Localized>
					}
					control={<Radio />}
				/>
			</RadioGroup>
		</>
	);
}

function SolutionTypeField(props: {
	value: DbExerciseSolutionType;
	onChange: (val: DbExerciseSolutionType) => void;
}) {
	return (
		<TextField
			select
			label={
				<Localized id="exercise-prog-editor-sql-solution-type">
					Solution type
				</Localized>
			}
			value={props.value}
			onChange={({target}) => {
				props.onChange(target.value as DbExerciseSolutionType);
			}}
			fullWidth
		>
			<MenuItem value="cmd">
				<Localized id="exercise-prog-editor-sql-solution-type-command">
					Command
				</Localized>
			</MenuItem>
			<MenuItem value="query">
				<Localized id="exercise-prog-editor-sql-solution-type-query">
					Query
				</Localized>
			</MenuItem>
		</TextField>
	);
}

const ProgExerciseEditor = (props: ProgExerciseEditorProps): JSX.Element => {
	const {courseId, exerciseId, exercise, organisationName} = props;

	useEffect(() => props.onUnmount, [props.onUnmount]);

	const {l10n} = useLocalization();

	const showSnackbar = useSnackbar();

	const courseCore = useAppSelector(
		(state) => selectCourse(state, keyProvider.course(courseId))?.core ?? ""
	);

	const [draft, setDraft] = useState<Draft>(() => ({
		id: exerciseId ?? 0,
		difficultyLevel: 0,
		maxScore: 1,
		comparisonStrictness: "strict",
		privacy: ExercisePrivacy.PublicToOrganisation,
		tags: [],
	}));
	const [subtype, setSubtype] = useState<DbExerciseSolutionType>("cmd");
	const title = useRef<HTMLInputElement>(null);
	const question = useRef<TextEditorApi>(null);
	const solution = useRef<TextEditorApi>(null);
	const codeEditor = useRef<CodeEditorApi>(null);
	const [mainFile, setMainFile] = useState("");
	const [runCommand, setRunCommand] = useState("");
	const [additionalFiles, setAdditionalFiles] = useState<string[]>(() => []);
	const exampleOutput = useRef("");
	const prohibitedWords = useRef<HTMLInputElement>(null);
	const requiredWords = useRef<HTMLInputElement>(null);
	const category = useRef<HTMLInputElement>(null);

	const testsConsole = useRef<{refetch: () => Promise<void>}>(null);

	const initialQuestion = useRef("");
	const initialSolution = useRef("");

	if (exercise && exercise.type !== ExerciseType.Prog) {
		throw new Error("Unexpected exercise type");
	}

	const language = exercise?.language ?? courseCoreToLanguage[courseCore];

	const [initialCodeFiles, setInitialCodeFiles] = useState<ProgFile[]>(() =>
		language === "sql"
			? [
					{
						name: "Answer.sql",
						content: "",
					},
					{
						name: "InitialSchema.sql",
						content: "",
					},
			  ]
			: [
					{
						name: `program${languageDefaultExtensions[language] ?? ""}`,
						content: "",
					},
			  ]
	);

	useEffect(() => {
		if (title.current) {
			title.current.value = l10n.getString(
				"exercise-prog-editor-default-title"
			);
		}

		if (!exercise) {
			return;
		}

		setDraft({
			id: exerciseId ?? 0,
			maxScore: exercise.maxScore,
			comparisonStrictness: exercise.comparisonStrictness,
			difficultyLevel: exercise.difficultyLevel,
			privacy: exercise.privacy,
			tags: exercise.tags,
		});

		if (exercise.language === "sql") {
			setSubtype(exercise.subtype);
		}

		if (title.current) {
			title.current.value = exercise.title;
		}

		initialQuestion.current = exercise.question;
		initialSolution.current = exercise.solution;

		setInitialCodeFiles(exercise.files);

		setMainFile(exercise.mainFile);

		setRunCommand(exercise.command ?? "");

		setAdditionalFiles(exercise.additionalFiles);

		if (prohibitedWords.current) {
			prohibitedWords.current.value = exercise.prohibitedWords;
		}

		if (requiredWords.current) {
			requiredWords.current.value = exercise.requiredWords;
		}

		exampleOutput.current = exercise.exampleOutput;

		if (category.current) {
			category.current.value = exercise.category;
		}
	}, [exercise, exerciseId, l10n]);

	const [emptyFields, setEmptyFields] = useState({
		title: false,
		question: false,
		files: false,
		mainFile: false,
	});

	const [
		buildResult,
		setBuildResult,
	] = useState<ProgExerciseBuildResult | null>(null);
	const [rebuildRequired, setRebuildRequired] = useState(false);
	const [building, setBuilding] = useState(false);

	const [testsHidden, setTestsHidden] = useState(!draft.id);

	const userId = useAppSelector(selectUserId);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft.id
	);

	const [featureEnabled] = useFeatureEnabled();

	const [dirty, setDirty] = useState(false);

	const dirtyAndRebuild = useCallback(() => {
		setRebuildRequired(true);
		setDirty(true);
	}, []);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const {onExampleOutputChanged} = props;
	const exampleOutputChanged = useCallback(
		(val) => {
			onExampleOutputChanged(draft.id, val);
			exampleOutput.current = val;
		},
		[draft.id, onExampleOutputChanged]
	);

	const [fileEditorDialogOpen, setFileEditorDialogOpen] = useState(false);
	const [fileManager, setFileManager] = useState<{
		filename: string;
		load: () => Promise<ProgFile>;
		update: (content: string) => Promise<boolean>;
	}>(() => ({
		filename: "",
		async load() {
			return {content: "", name: ""};
		},
		async update() {
			return true;
		},
	}));

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const page = await exerciseService.searchTags(
				{
					scope: "organisation_or_user_exercises",
					organisationName,
					userId,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[organisationName, userId]
	);

	const updated = useRef(false);

	const initialSchemaAndState =
		buildResult?.db?.initialSchemaAndState ?? exercise?.initialSchemaAndState;

	const toEditableExercise = (exampleOutput: string): ProgExercise => {
		const base = exercise ?? {
			type: ExerciseType.Prog,
			authorId: userId,
			originId: courseId,
		};

		if (language === "sql") {
			return {
				...base,
				language: "sql",
				title: title.current?.value ?? "",
				question: question.current?.getContent() ?? "",
				solution: solution.current?.getContent() ?? "",
				maxScore: draft.maxScore,
				files: codeEditor.current?.getFiles() ?? [],
				additionalFiles: [],
				mainFile: "",
				command: "",
				exampleOutput: "",
				comparisonStrictness: "strict",
				prohibitedWords: prohibitedWords.current?.value ?? "",
				requiredWords: requiredWords.current?.value ?? "",
				privacy: draft.privacy,
				difficultyLevel: draft.difficultyLevel,
				category: category.current?.value ?? "",
				tags: draft.tags,
				initialSchemaAndState: initialSchemaAndState ?? [],
				subtype: subtype,
			};
		}

		return {
			...base,
			language,
			title: title.current?.value ?? "",
			question: question.current?.getContent() ?? "",
			solution: solution.current?.getContent() ?? "",
			maxScore: draft.maxScore,
			files: codeEditor.current?.getFiles() ?? [],
			additionalFiles,
			mainFile,
			command: runCommand,
			exampleOutput,
			comparisonStrictness: draft.comparisonStrictness,
			prohibitedWords: prohibitedWords.current?.value ?? "",
			requiredWords: requiredWords.current?.value ?? "",
			privacy: draft.privacy,
			difficultyLevel: draft.difficultyLevel,
			category: category.current?.value ?? "",
			tags: draft.tags,
			initialSchemaAndState: [],
		};
	};

	const save = async () => {
		const error = validate();
		if (error) {
			showSnackbar("error", error);
			return false;
		}

		setBuildResult(null);
		setTestsHidden(true);

		let id: number;
		try {
			id = await props.onSave(toEditableExercise(exampleOutput.current));

			if (draft.id === 0) {
				setDraft((prev) => ({...prev, id}));
			}

			setDirty(false);
			updated.current = true;
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		return true;
	};

	const complete = async () => {
		const saved = await save();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	};

	const build = async () => {
		if (draft.id === 0) {
			return;
		}

		const error = validate();
		if (error) {
			showSnackbar("error", error);
			return;
		}

		setBuildResult(null);

		setBuilding(true);

		let buildResult;
		try {
			buildResult = await props.onBuild(draft.id, toEditableExercise(""));
		} finally {
			setBuilding(false);
		}

		if (!buildResult) {
			return;
		}

		setBuildResult(buildResult);
		setRebuildRequired(false);
		setDirty(false);

		if (buildResult.buildStatus === "success") {
			setTestsHidden(false);
			testsConsole.current?.refetch();
		} else {
			setTestsHidden(true);
		}
	};

	function validate() {
		const codeFiles = codeEditor.current?.getFiles();

		let emptyFileExists = false;

		const questionEmpty = question.current?.getContent().length === 0;

		const titleEmpty = title.current?.value.length === 0;

		let mainFileEmpty = false;

		if (language !== "sql") {
			emptyFileExists = codeFiles?.some((f) => f.content.length === 0) ?? false;
			mainFileEmpty = (codeFiles?.length ?? 0) > 1 && mainFile?.length === 0;
		}

		setEmptyFields({
			title: titleEmpty,
			question: questionEmpty,
			mainFile: mainFileEmpty,
			files: emptyFileExists,
		});

		if (emptyFileExists || questionEmpty || titleEmpty || mainFileEmpty) {
			return l10n.getString(
				"exercise-editor-error-required-fields",
				null,
				"Some required fields are empty"
			);
		}

		const duplicateNames = codeFiles
			? new Set(codeFiles.map((f) => f.name)).size !== codeFiles.length
			: false;

		if (duplicateNames) {
			return l10n.getString(
				"exercise-prog-editor-error-duplicate-names",
				null,
				"Some code files have duplicate names"
			);
		}

		return null;
	}

	function openConfirmDeleteDialog(
		filename: string,
		onConfirm: () => Promise<void>
	) {
		openConfirmationDialog({
			title: (
				<Localized id="exercise-prog-editor-confirm-file-dialog-title">
					Delete file?
				</Localized>
			),
			description: (
				<Localized
					id="exercise-prog-editor-confirm-file-dialog-description"
					vars={{filename}}
				>
					{"The file '{$filename}' will be permanently deleted."}
				</Localized>
			),
			confirmBtnText: (
				<Localized id="exercise-prog-editor-confirm-file-dialog-confirm-btn">
					Delete
				</Localized>
			),
			onConfirm,
		});
	}

	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<TextField
						inputRef={title}
						autoFocus
						required
						fullWidth
						error={emptyFields.title}
						label={
							<Localized id="exercise-editor-title-label">
								Exercise title
							</Localized>
						}
						onChange={() => setDirty(true)}
						helperText={
							emptyFields.title && (
								<Localized id="exercise-editor-error-required-fields">
									The field is required
								</Localized>
							)
						}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						label={
							<Localized id="exercise-editor-score-label">Score</Localized>
						}
						type="number"
						value={draft.maxScore}
						InputProps={{inputProps: {min: 0}}}
						onChange={({target}) => {
							const maxScore = parseInt(target.value);
							if (!isNaN(maxScore)) {
								setDraft((prev) => ({...prev, maxScore}));
								setDirty(true);
							}
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<Box mb={1} display="flex">
						<InputLabel required error={emptyFields.question} shrink>
							<Localized id="exercise-editor-description-label">
								Description
							</Localized>
						</InputLabel>
					</Box>

					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						onChange={() => setDirty(true)}
						files={files}
						fileUploader={fileUploader ?? undefined}
						ref={question}
					/>
				</Grid>

				<Grid item xs={12}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Localized id="exercise-editor-solution-label">
								Solution
							</Localized>
						</AccordionSummary>
						<AccordionDetails>
							<Box width="100%">
								<TextEditor
									initialValue={initialSolution.current}
									ref={solution}
									onChange={() => setDirty(true)}
									fileUploader={fileUploader ?? undefined}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid item xs={12}>
					<Box mb={1}>
						<InputLabel required error={emptyFields.files} shrink>
							<Localized id="exercise-prog-editor-solution-files-label">
								Solution files
							</Localized>
						</InputLabel>
					</Box>
					<CodeEditor
						initialFiles={initialCodeFiles}
						ref={codeEditor}
						defaultExtension={languageDefaultExtensions[language]}
						fileManagement={language !== "sql"}
						onChange={dirtyAndRebuild}
					/>
				</Grid>

				{language === "sql" && (
					<Grid item xs={12} md={6}>
						<SolutionTypeField
							value={subtype}
							onChange={(val) => {
								setSubtype(val);
								dirtyAndRebuild();
							}}
						/>
					</Grid>
				)}

				{language === "sql" && (
					<Grid item xs={12}>
						<Typography variant="h6">
							<Localized id="exercise-prog-editor-sql-initial-schema">
								Initial schema and state
							</Localized>
						</Typography>
						{initialSchemaAndState && initialSchemaAndState.length > 0 ? (
							<Box mt={1}>
								<DbSchemaAndState schema={initialSchemaAndState} />
							</Box>
						) : (
							<Box mt={3}>
								<EmptySchema />
							</Box>
						)}
					</Grid>
				)}

				{language === "sql" && (
					<Grid item xs={12}>
						<Typography variant="h6">
							<Localized id="exercise-prog-editor-sql-test-results">
								Test results
							</Localized>
						</Typography>

						{(!buildResult || buildResult.buildStatus === "failed") && (
							<Box textAlign="center" mt={3}>
								<Typography>
									<Localized id="exercise-prog-editor-sql-no-build-result">
										Build the exercise to see the results
									</Localized>
								</Typography>
							</Box>
						)}

						{buildResult &&
							buildResult.db &&
							buildResult.buildStatus === "success" && (
								<>
									{buildResult.db.schemaAndState && (
										<>
											<Box my={1} display="flex">
												<Typography variant="subtitle2">
													<Localized id="exercise-prog-editor-sql-final-schema">
														Schema and state
													</Localized>
												</Typography>
											</Box>
											<DbSchemaAndState
												schema={buildResult.db.schemaAndState}
											/>
										</>
									)}
									{buildResult.db.queryResult && (
										<>
											<Box mb={1} display="flex">
												<Typography variant="subtitle2">
													<Localized id="exercise-prog-editor-sql-query-result">
														Query result
													</Localized>
												</Typography>
											</Box>
											<Paper elevation={1}>
												<DbData data={buildResult.db.queryResult} />
											</Paper>
										</>
									)}
								</>
							)}
					</Grid>
				)}

				{language !== "sql" && (
					<>
						<Grid item container spacing={3} xs={12}>
							<Grid item xs={12} md={6}>
								<MainFileField
									value={mainFile}
									onChange={(val) => {
										setMainFile(val);
										dirtyAndRebuild();
									}}
									required={(codeEditor.current?.getFiles().length ?? 0) > 1}
									error={emptyFields.mainFile}
								/>
							</Grid>
							{language === "js" && (
								<Grid item xs={12} md={6}>
									<RunCommandField
										value={runCommand}
										onChange={(val) => {
											setRunCommand(val);
											dirtyAndRebuild();
										}}
									/>
								</Grid>
							)}
						</Grid>

						{draft.id > 0 && (
							<Grid item xs={12}>
								<AdditionalFiles
									courseId={courseId}
									exerciseId={draft.id}
									onChange={(files) => {
										setAdditionalFiles(files);
										dirtyAndRebuild();
									}}
									files={additionalFiles}
									onOpenFileEditor={(fileManager) => {
										setFileEditorDialogOpen(true);
										setFileManager(fileManager);
									}}
									onConfirmDelete={openConfirmDeleteDialog}
								/>
							</Grid>
						)}

						{!testsHidden && (
							<Grid item xs={12}>
								<ConsoleProgTests
									courseId={courseId}
									exerciseId={draft.id}
									onExampleOutputChanged={exampleOutputChanged}
									onOpenFileEditor={(fileManager) => {
										setFileEditorDialogOpen(true);
										setFileManager(fileManager);
									}}
									onConfirmDelete={openConfirmDeleteDialog}
									ref={testsConsole}
								/>
							</Grid>
						)}

						<Grid item xs={12}>
							<ComparisonStrictnessSelector
								value={draft.comparisonStrictness}
								onChange={(val) => {
									setDraft((prev) => ({...prev, comparisonStrictness: val}));
									dirtyAndRebuild();
								}}
							/>
						</Grid>
					</>
				)}

				<Grid item container spacing={3} xs={12}>
					<Grid item xs={12} md={6}>
						<TextField
							label={
								<Localized id="exercise-prog-editor-prohibited-label">
									Prohibited words
								</Localized>
							}
							multiline
							fullWidth
							inputRef={prohibitedWords}
							onChange={dirtyAndRebuild}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							label={
								<Localized id="exercise-prog-editor-required-label">
									Required words
								</Localized>
							}
							multiline
							fullWidth
							inputRef={requiredWords}
							onChange={dirtyAndRebuild}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12} md={6}>
					<PrivacyLevelSelector
						onChange={(privacy) => {
							setDraft((prev) => ({...prev, privacy}));
							setDirty(true);
						}}
						value={draft.privacy}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ExerciseDifficultySelector
						name="difficulty"
						value={draft.difficultyLevel}
						onChange={(value) => {
							setDraft((prev) => ({...prev, difficultyLevel: value ?? 0}));
							setDirty(true);
						}}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextField
						id="caterogy"
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						onChange={() => setDirty(true)}
						inputRef={category}
						fullWidth
					/>
				</Grid>

				{featureEnabled(Feature.ExerciseTags) && (
					<Grid item xs={12}>
						<ExerciseTagsSelector
							freeSolo
							value={draft.tags}
							onChange={(tags) => {
								setDraft((prev) => ({...prev, tags}));
								setDirty(true);
							}}
							searchTags={searchTags}
						/>
					</Grid>
				)}
			</Grid>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={complete}
				onSave={save}
				disabled={!dirty}
				additionalActions={
					<Box display="flex" alignItems="center">
						<SubmitButton
							variant="text"
							onClick={build}
							disabled={draft.id === 0}
							inProgress={building}
						>
							<Localized id="exercise-prog-editor-build-btn">Build</Localized>
						</SubmitButton>

						<BuildResultIcon warning={rebuildRequired} result={buildResult} />
					</Box>
				}
			/>

			{confirmationDialog}

			<Dialog
				open={fileEditorDialogOpen}
				onClose={() => setFileEditorDialogOpen(false)}
				fullWidth
				maxWidth="md"
			>
				<ProgFileEditorDialog
					onClose={() => setFileEditorDialogOpen(false)}
					fileManager={fileManager}
				/>
			</Dialog>
		</>
	);
};

function EmptySchema() {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography variant="subtitle2">
				<Localized id="exercise-prog-editor-sql-initial-schema-empty-title">
					Schema is empty
				</Localized>
			</Typography>
			<Typography variant="body2">
				<Localized id="exercise-prog-editor-sql-initial-schema-empty-description">
					Edit InitialSchema.sql and build the exercise
				</Localized>
			</Typography>
		</Container>
	);
}

const useBuildResultStyles = makeStyles((theme) => ({
	success: {
		color: theme.palette.success.main,
	},
	error: {
		color: theme.palette.error.main,
	},
	warning: {
		color: theme.palette.warning.main,
	},
	popover: {
		pointerEvents: "none",
	},
	popoverContent: {
		pointerEvents: "auto",
		padding: theme.spacing(2),
	},
}));

function BuildResultIcon(props: {
	result: ProgExerciseBuildResult | null;
	warning?: boolean;
}) {
	const {result, warning} = props;
	const classes = useBuildResultStyles();

	const [opened, setOpened] = useState(false);

	const anchorEl = useRef(null);

	const open = () => {
		setOpened(true);
	};
	const close = () => {
		setOpened(false);
	};

	let icon = <></>;
	let message = <></>;
	switch (true) {
		case warning:
			icon = (
				<WarningOutlinedIcon
					className={classes.warning}
					ref={anchorEl}
					onMouseEnter={open}
					onMouseLeave={close}
				/>
			);

			message = (
				<Typography>
					<Localized id="exercise-prog-editor-build-warning">
						{`Please don't forget to rebuild the program and re-run all tests`}
					</Localized>
				</Typography>
			);
			break;
		case result?.buildStatus === "failed":
			icon = (
				<ErrorOutlineOutlinedIcon
					className={classes.error}
					ref={anchorEl}
					onMouseEnter={open}
					onMouseLeave={close}
				/>
			);

			message = (
				<>
					<Typography>
						<Localized id="exercise-prog-editor-build-error">
							Error: Your program did not build
						</Localized>
					</Typography>
					<pre>${result?.message}</pre>
				</>
			);
			break;
		case result?.buildStatus === "success":
			icon = (
				<CheckCircleOutlineOutlinedIcon
					className={classes.success}
					ref={anchorEl}
					onMouseEnter={open}
					onMouseLeave={close}
				/>
			);

			message = (
				<Typography>
					<Localized id="exercise-prog-editor-build-success">
						Build successful
					</Localized>
				</Typography>
			);

			break;
	}

	return (
		<>
			{icon}
			<Popover
				open={opened}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				onClose={close}
				PaperProps={{
					onMouseEnter: open,
					onMouseLeave: close,
				}}
				classes={{
					paper: classes.popoverContent,
					root: classes.popover,
				}}
			>
				{message}
			</Popover>
		</>
	);
}

export default ProgExerciseEditor;
