import {keyProvider} from "../store/keyProvider";

const resolvePath = (basePath: string, relativePath: string): string => {
	return (basePath + relativePath).replace("//", "/");
};

const oldOrigin = window.location.origin.replace("//vw5.", "//vw4.");
const oldRoot = oldOrigin + "/";

function resolveLinkToOldSite(path: string): string {
	let base = oldRoot;

	if (path.length > 0 && path[0] === "/") {
		base = oldOrigin;
	}

	return base + path;
}

function makeLinkToChapter(
	organisationName: string,
	courseId: number,
	chapterId: number
) {
	return `${window.location.origin}/organisations/${organisationName}/courses/${courseId}/chapters/${chapterId}`;
}

function getSectionPath(
	pathFrom: string,
	chapterId: number,
	sectionId: number
): string {
	const basePath = trimToCoursePath(pathFrom);
	return resolvePath(basePath, keyProvider.section(chapterId, sectionId));
}

function getSectionPathByKey(pathFrom: string, sectionKey: string): string {
	const basePath = trimToCoursePath(pathFrom);
	return resolvePath(basePath, sectionKey);
}

function getChapterPathByKey(pathFrom: string, chapterKey: string): string {
	const basePath = trimToCoursePath(pathFrom);
	return resolvePath(basePath, chapterKey);
}

function trimToCoursePath(path: string) {
	const chapterIndex = path.indexOf("/chapters");
	return chapterIndex > 0 ? path.slice(0, chapterIndex) : path;
}

export {
	makeLinkToChapter,
	getSectionPath,
	getSectionPathByKey,
	getChapterPathByKey,
	oldOrigin as oldSiteOrigin,
	resolveLinkToOldSite,
	resolvePath,
};
