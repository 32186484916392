import {Localized, useLocalization} from "@fluent/react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import {
	CircularProgress,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	useTheme,
} from "@mui/material";
import React from "react";

import LightTooltip from "./LightTooltip";
import type FetchStatus from "../store/FetchStatus";

function StatusListItem(props: {
	primaryText: string;
	secondaryText?: string;
	status: FetchStatus | "warning";

	labelOnPending: string;
	labelOnSuccess: string;
	labelOnWarning?: string;
}) {
	const theme = useTheme();
	const {l10n} = useLocalization();

	let icon = <></>;

	switch (props.status) {
		case "pending":
			icon = (
				<CircularProgress
					color="primary"
					size="1.5rem"
					aria-label={props.labelOnPending}
				/>
			);

			break;
		case "failed":
			icon = (
				<LightTooltip
					title={
						<Typography>
							<Localized id="error-general">Error</Localized>
						</Typography>
					}
				>
					<ErrorOutlineOutlinedIcon
						aria-label={l10n.getString("error-general")}
						aria-hidden={false}
						color="error"
					/>
				</LightTooltip>
			);

			break;
		case "succeeded":
			icon = (
				<LightTooltip
					title={<Typography>{props.labelOnSuccess}</Typography>}
					placement="top-start"
				>
					<CheckCircleOutlineOutlinedIcon
						aria-label={props.labelOnSuccess}
						aria-hidden={false}
						style={{color: theme.palette.success.main}}
					/>
				</LightTooltip>
			);

			break;
		case "warning":
			icon = (
				<LightTooltip
					title={<Typography>{props.labelOnWarning}</Typography>}
					placement="top-start"
				>
					<WarningOutlinedIcon
						aria-label={props.labelOnWarning}
						aria-hidden={false}
						style={{color: theme.palette.warning.main}}
					/>
				</LightTooltip>
			);
	}

	return (
		<ListItem>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText
				primary={props.primaryText}
				secondary={props.secondaryText}
			/>
		</ListItem>
	);
}

export default React.memo(StatusListItem);
