import {Localized} from "@fluent/react";
import {Paper, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import type ExerciseOutcomes from "../../store/courseProgress/ExerciseOutcomes";
import {CompletionStatus} from "../../store/outcomes/ExerciseOutcomes";
import CompletionStatusIcon from "../treeview/CompletionStatusIcon";

const useRowStyles = makeStyles({
	root: {
		"& > td": {
			borderBottom: "unset",
		},
	},
});

function ProgressTableExerciseRow(props: {
	outcomes: ExerciseOutcomes | undefined;
	maxScore: number;
	title: React.ReactElement;
}): JSX.Element {
	const classes = useRowStyles();

	const {outcomes, maxScore, title} = props;

	return (
		<TableRow className={classes.root}>
			<TableCell padding="checkbox" style={{paddingTop: 4}}>
				{outcomes && <ExerciseStatusIcon outcomes={outcomes} />}
			</TableCell>
			<TableCell style={{paddingLeft: 0}}>{title}</TableCell>
			<TableCell align="right">
				<Localized
					id="student-results-widget-score-label"
					vars={{
						earned: outcomes?.score ?? "–",
						total: outcomes?.maxScore ?? maxScore,
					}}
				>
					{`Score: ${outcomes?.score ?? "–"}/${outcomes?.maxScore ?? maxScore}`}
				</Localized>
			</TableCell>
		</TableRow>
	);
}

const useStatusIconStyles = makeStyles((theme) =>
	createStyles({
		tooltipPaper: {
			padding: theme.spacing(1),
		},
		tooltip: {
			backgroundColor: "transparent",
			maxWidth: "none",
		},
	})
);

function ExerciseStatusIcon(props: {outcomes: ExerciseOutcomes}): JSX.Element {
	const classes = useStatusIconStyles();

	const {outcomes} = props;

	let content;
	let icon;

	if (outcomes.submissionTime) {
		icon = <CompletionStatusIcon status={CompletionStatus.Complete} />;
		content = (
			<Typography>
				<Localized id="student-results-widget-exercise-status-completed">
					Completed
				</Localized>
			</Typography>
		);
	} else {
		icon = <CompletionStatusIcon status={CompletionStatus.Incomplete} />;
		content = (
			<Typography>
				<Localized id="student-results-widget-exercise-status-started">
					Started
				</Localized>
			</Typography>
		);
	}

	return (
		<Tooltip
			title={
				<Paper elevation={4} className={classes.tooltipPaper}>
					{content}
				</Paper>
			}
			classes={{tooltip: classes.tooltip}}
			placement="top-start"
		>
			<span>{icon}</span>
		</Tooltip>
	);
}

export default ProgressTableExerciseRow;
