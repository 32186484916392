import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "../store";
import type {ChapterExercise, Section} from "../services/dtos/ChapterContent";
import {prepareChapterExercise} from "../chapterExercises/makeSelectChapterExercise";
import {prepareSelectedSection} from "../sections/makeSelectSection";

const empty: Section[] = [];

function makeSelectChapterContent(): (
	state: RootState,
	chapterKey: string
) => Section[] {
	return createSelector(
		(state: RootState) => state.chapterSections.byKey,
		(state: RootState) => state.sections.byKey,
		(state: RootState) => state.chapterExercises.byKey,
		(state: RootState) => state.exercises.byKey,
		(_: RootState, chapterKey: string) => chapterKey,
		(sectionKeys, sections, chapterExercises, exercises, chapterKey) => {
			const keys = sectionKeys[chapterKey]?.sections;
			if (!keys) {
				return empty;
			}

			const res: Section[] = [];

			keys.forEach((key) => {
				const s = sections[key]?.section;
				if (!s) {
					return;
				}

				const subsections = s.subsections
					.map((k) =>
						prepareChapterExercise(
							chapterExercises[k],
							exercises[chapterExercises[k].exercise]
						)
					)
					.filter((s) => s !== null) as ChapterExercise[];

				const section = prepareSelectedSection(
					s,
					chapterExercises[s.exercise],
					exercises[chapterExercises[s.exercise].exercise]
				);
				if (!section) {
					return;
				}

				res.push({...section, subsections});
			});

			return res;
		}
	);
}

export default makeSelectChapterContent;
