import createFileUploader from "../../../utils/attachments/createFileUploader";
import DocumentFormat from "../../../utils/attachments/DocumentFormat";
import type FileUploader from "../../../utils/attachments/FileUploader";

function createStudentResponseFileUploaderFactory(
	userId: number,
	courseId: number
): (chapterId: number, exerciseId: number) => FileUploader | null {
	return function (chapterId, exerciseId) {
		if (!chapterId || !exerciseId || !userId || !courseId) {
			return null;
		}

		return createFileUploader({
			chapterId,
			courseId,
			documentFormat: DocumentFormat.StudentUpload,
			exerciseId,
			personId: userId,
		});
	};
}

export default createStudentResponseFileUploaderFactory;
