import {createSlice} from "@reduxjs/toolkit";

import FetchStatus from "../FetchStatus";
import {CompletionStatus, ExerciseOutcomes} from "../outcomes/ExerciseOutcomes";
import {updateOutcomes} from "../outcomes/outcomesSlice";
import {fetchChapterSessionOutcomes} from "./fetchChapterSessionOutcomes";
import {saveChapterSessionStudentResponse} from "./saveChapterSessionStudentResponse";
import {submitChapterSessionStudentResponse} from "./submitChapterSessionStudentResponse";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	sessionKey: string;
	outcomes: {[key: string]: ExerciseOutcomes | undefined};
	status: FetchStatus;
};

const initialState: State = {
	sessionKey: "",
	outcomes: {},
	status: "none",
};

export const chapterSessionOutcomesSlice = createSlice({
	name: "chapterSessionOutcomes",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchChapterSessionOutcomes.fulfilled, (state, action) => {
			state.outcomes = action.payload.outcomes;
			state.sessionKey = action.payload.sessionKey;
			state.status = "succeeded";
		});
		builder.addCase(fetchChapterSessionOutcomes.pending, (_, action) => {
			return {
				...initialState,
				status: "pending",
				sessionKey: action.meta.arg.sessionKey ?? "",
			};
		});
		builder.addCase(fetchChapterSessionOutcomes.rejected, (_, action) => {
			return {
				...initialState,
				status: inferFetchStatusFromError(action),
				sessionKey: action.meta.arg.sessionKey ?? "",
			};
		});

		builder.addCase(
			saveChapterSessionStudentResponse.fulfilled,
			(state, action) => {
				updateOutcomes(
					state.outcomes,
					CompletionStatus.Incomplete,
					action.payload.responseKey
				);
			}
		);

		builder.addCase(
			submitChapterSessionStudentResponse.fulfilled,
			(state, {payload}) => {
				const outcomes = payload.submissionResult.outcomes;

				if (outcomes) {
					updateOutcomes(
						state.outcomes,
						outcomes.completionStatus,
						payload.responseKey,
						outcomes.score
					);
				}
			}
		);
	},
});

export default chapterSessionOutcomesSlice.reducer;
