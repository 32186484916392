import {createAsyncThunk} from "@reduxjs/toolkit";

import {courseService} from "../services/courseService";
import type {AppDispatch, RootState} from "../store";
import selectChapter from "./selectChapter";

const updateChaptersOrder = createAsyncThunk<
	{courseId: number; chapterKeys: string[]},
	{
		courseId: number;
		chapterKeys: string[];
		chapterKey: string;
		place: {
			before?: string;
		};
	},
	{dispatch: AppDispatch; state: RootState}
>("chapters/updateChaptersOrder", async function (params, thunkApi) {
	const {courseId, chapterKey, place} = params;

	const state = thunkApi.getState();

	const chapterId = selectChapter(state, chapterKey)?.id;
	if (!chapterId) {
		throw new Error(`Chapter ${chapterKey} is not found`);
	}

	let chapterPlace = {};
	if (place.before) {
		const id = selectChapter(state, place.before)?.id;
		if (!id) {
			throw new Error(`Chapter ${place.before} is not found`);
		}

		chapterPlace = {before: id};
	}

	await courseService.moveChapter(courseId, chapterId, chapterPlace);

	return {
		courseId,
		chapterKeys: params.chapterKeys,
	};
});

export default updateChaptersOrder;
