import {createAsyncThunk} from "@reduxjs/toolkit";

import type ChapterStructure from "./ChapterStructure";
import type NormalisedStructure from "./NormalisedStructure";
import {normaliseChapterStructure} from "./NormalisedStructure";
import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";
import {AppDispatch, RootState} from "../store";

const updateChapterStructure = createAsyncThunk<
	{chapterKey: string; structure: NormalisedStructure},
	{courseId: number; chapterId: number; structure: ChapterStructure},
	{dispatch: AppDispatch; state: RootState}
>("sections/updateChapterStructure", async function (params, thunkApi) {
	const courseId = params.courseId;
	const chapterId = params.chapterId;

	const state = thunkApi.getState();

	const sections = state.sections.byKey;
	const chapterExercises = state.chapterExercises.byKey;
	const exercises = state.exercises.byKey;

	const structure = params.structure.map((s) => {
		const section = sections[s.sectionKey]?.section;
		if (!section) {
			throw new Error(`Section ${s.sectionKey} is not found`);
		}

		return {
			id: section.id,
			subsections: s.subsectionKeys.map((key: string) => {
				const exerciseKey = chapterExercises[key].exercise;
				const exercise = exercises[exerciseKey];

				return {
					id: exercise.id,
					type: exercise.type,
				};
			}),
		};
	});

	const response = await courseService.updateChapterStructure(
		courseId,
		chapterId,
		{
			sections: structure,
		}
	);

	return {
		chapterKey: keyProvider.chapter(chapterId),
		structure: normaliseChapterStructure(chapterId, response),
	};
});

export default updateChapterStructure;
