import {createSelector} from "reselect";
import ExerciseType from "../exercises/ExerciseType";
import {RootState} from "../store";

const emptyExercise = {
	chapterId: 0,
	number: 0,
	title: "",
	type: ExerciseType.Theory,
	maxScore: 0,
	id: 0,
};

const selectChapterExercise = createSelector(
	(state: RootState) => state.chapterExercises.byKey,
	(state: RootState) => state.exercises.byKey,
	(_: RootState, key: string) => key,
	(chapterExercises, exercises, key) => {
		const chapterExercise = chapterExercises[key];
		if (!chapterExercise) {
			return emptyExercise;
		}

		const exercise = exercises[chapterExercise.exercise];
		if (!exercise) {
			return emptyExercise;
		}

		return {
			chapterId: chapterExercise.chapterId,
			number: chapterExercise.number,
			title: exercise.title,
			type: exercise.type,
			maxScore: chapterExercise.maxScore ?? 0,
			id: exercise.id,
		};
	}
);

export default selectChapterExercise;
