import {Localized} from "@fluent/react";
import {Box, Button, Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import SubmitButton from "./SubmitButton";

const useStyles = makeStyles((theme) => ({
	footerPaper: {
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		paddingRight: theme.spacing(6),
	},
	submitBtn: {
		marginLeft: theme.spacing(5),
	},
}));

const ConfirmationFooter = (props: {
	onDiscard: () => void;
	onSave: () => void;
	saveInProgress?: boolean;
	saveDisabled?: boolean;
}): JSX.Element => {
	const classes = useStyles();
	const {onDiscard, onSave, saveInProgress, saveDisabled} = props;

	return (
		<Box width={1} height={80} position="fixed" bottom={0} left={0}>
			<Paper elevation={3} className={classes.footerPaper}>
				<Button onClick={onDiscard} color="primary" disabled={saveInProgress}>
					<Localized id="utils-footer-confirmation-discard">
						Discard changes
					</Localized>
				</Button>
				<SubmitButton
					onClick={onSave}
					inProgress={saveInProgress}
					className={classes.submitBtn}
					disabled={saveDisabled}
				>
					<Localized id="utils-footer-confirmation-save">Save</Localized>
				</SubmitButton>
			</Paper>
		</Box>
	);
};

export default ConfirmationFooter;
