import {answerShowingAllowed} from "../chapterExercises/selectAnswerShowingAllowed";
import type {CompletionStatus} from "../outcomes/ExerciseOutcomes";
import selectChapterSessionExercise from "./selectChapterSessionExercise";
import type {RootState} from "../store";

function selectChapterSessionExerciseAnswerShowingAllowed(
	state: RootState,
	chapterExeciseKey: string,
	completionStatus: CompletionStatus
): boolean {
	const exercise = selectChapterSessionExercise(state, chapterExeciseKey);
	if (!exercise) {
		return false;
	}

	return answerShowingAllowed(state, exercise, completionStatus);
}

export default selectChapterSessionExerciseAnswerShowingAllowed;
