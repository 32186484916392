import {createSelector} from "@reduxjs/toolkit";
import FetchStatus from "../FetchStatus";
import {RootState} from "../store";

const empty: readonly [string[], FetchStatus] = [[], "none"];

const selectChapterSessionKeys = createSelector(
	(state: RootState) => state.chapterSessions.chapterSessions.byKey,
	(_: RootState, chapterKey: string) => chapterKey,
	(chapterSessions, chapterKey): readonly [string[], FetchStatus] => {
		const chapterSession = chapterSessions[chapterKey];
		if (!chapterSession) {
			return empty;
		}

		return [chapterSession.sessions, chapterSession.status];
	}
);

export default selectChapterSessionKeys;
