export function changeRootArgs(asciiFormula: string): string {
	const expressionTree = getNode(asciiFormula);
	return nodeToFormula(expressionTree, "");
}

type Node = {
	value: string;
	left?: Node;
	right?: Node;
	appendix?: Node;
};

function getNode(formula: string): Node {
	const x1 = formula.indexOf("root(") + 5;
	if (x1 === 4) {
		return {
			value: formula,
		};
	}
	const x2 = findX2(formula, x1);

	const y2 = findY2(formula, x2);

	if (x2 < 0 || y2 < 0) {
		return {
			value: formula,
		};
	}

	return {
		value: formula.slice(0, x1 - 1),
		left: getNode(formula.slice(x1, x2)),
		right: getNode(formula.slice(x2 + 1, y2)),
		appendix: getNode(formula.slice(y2 + 1)),
	};
}

function findX2(formula: string, x1: number): number {
	let bracketCounter = 0;
	let pos = x1;
	for (; pos < formula.length; pos++) {
		if (formula[pos] === "(") {
			bracketCounter++;
		} else if (formula[pos] === ")") {
			bracketCounter--;
		}
		if (formula[pos] === "|" && bracketCounter === 0) {
			break;
		}
	}
	if (pos === formula.length) {
		return formula.length;
	}
	return pos;
}

function findY2(formula: string, x2: number): number {
	let bracketCounter = 0;
	let pos = x2;
	for (; pos < formula.length && bracketCounter !== -1; pos++) {
		if (formula[pos] === "(") {
			bracketCounter++;
		} else if (formula[pos] === ")") {
			bracketCounter--;
		}
	}
	if (pos === formula.length && bracketCounter >= 0) {
		return -1;
	}
	return pos - 1;
}

function nodeToFormula(node: Node, formula: string): string {
	formula += node.value;
	if (node.right) {
		formula += "(";
		formula = nodeToFormula(node.right, formula);
		formula += ")";
	}
	if (node.left) {
		formula += "(";
		formula = nodeToFormula(node.left, formula);
		formula += ")";
	}

	if (node.appendix) {
		formula = nodeToFormula(node.appendix, formula);
	}

	return formula;
}

export function extractVariables(expression: string): string[] {
	const funcs = [
		"sin",
		"cos",
		"tan",
		"asin",
		"acos",
		"atan",
		"sqrt",
		"log",
		"ln",
		"abs",
		"ceil",
		"floor",
		"round",
		"exp",
		"root",
		"e",
	];

	let newStr = expression.replace(/[^A-Za-z']/g, "");

	for (let i = 0; i < funcs.length; i++) {
		newStr = newStr.replace(funcs[i], "");
	}

	return Array.from(new Set(newStr.split("")));
}
