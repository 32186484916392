import {createSlice} from "@reduxjs/toolkit";

import Chapter, {draftKey} from "./Chapter";
import chapterDraftCreated from "./chapterDraftCreated";
import chapterDraftDeleted from "./chapterDraftDeleted";
import createChapter from "./createChapter";
import deleteChapter from "./deleteChapter";
import {getChapters} from "./getChapters";
import publishChapter from "./publishChapter";
import unpublishAllChapters from "./unpublishAllChapters";
import unpublishChapter from "./unpublishChapter";
import updateChapter from "./updateChapter";
import updateChaptersOrder from "./updateChaptersOrder";
import {keyProvider} from "../keyProvider";
import SubmissionMode from "./SubmissionMode";
import {SelectionModes} from "./SelectionMode";
import scheduleChapter from "./scheduleChapter";
import {patchField} from "../patching";
import {FeedbackVisibilities} from "./FeedbackVisibility";

type Chapters = {
	byKey: {
		[key: string]: Chapter;
	};
};

const initialState: Chapters = {
	byKey: {},
};

export const chaptersSlice = createSlice({
	name: "chapters",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(chapterDraftCreated, (state, action) => {
			state.byKey[draftKey] = {
				id: 0,
				courseId: action.payload.courseId,
				number: action.payload.number,
				title: action.payload.title,
				submissionMode: SubmissionMode.Individual,
				selectionMode: SelectionModes.All,
				structureType: "sections",
				sessionLimit: 1,
				feedbackVisibility: FeedbackVisibilities.Visible,
			};
		});

		builder.addCase(chapterDraftDeleted, (state) => {
			delete state.byKey[draftKey];
		});

		builder.addCase(createChapter.fulfilled, (state, action) => {
			const draft = state.byKey[draftKey];
			if (draft) {
				const id = action.payload.id;
				const key = keyProvider.chapter(id);

				state.byKey[key] = {
					id: id,
					courseId: action.payload.courseId,
					number: draft.number,
					title: action.payload.title,
					submissionMode: action.payload.submissionMode,
					selectionMode: action.payload.selectionMode,
					structureType: action.payload.structureType,
					sessionLimit: action.payload.sessionLimit,
					timeLimit: action.payload.timeLimit,
					feedbackVisibility: action.payload.feedbackVisibility,
				};

				delete state.byKey[draftKey];
			}
		});

		builder.addCase(deleteChapter.fulfilled, (state, action) => {
			const key = keyProvider.chapter(action.payload.id);

			delete state.byKey[key];

			updateNumbers(state, action.payload.keys);
		});

		builder.addCase(getChapters.fulfilled, (state, action) => {
			const chapters = action.payload.entities.chapters;

			Object.keys(chapters).forEach((key) => {
				state.byKey[key] = {...chapters[key]};
			});
		});

		builder.addCase(publishChapter.fulfilled, (state, {payload}) => {
			state.byKey[keyProvider.chapter(payload.id)].startDate =
				payload.startDate;
		});

		builder.addCase(scheduleChapter.fulfilled, (state, {payload}) => {
			state.byKey[keyProvider.chapter(payload.id)].startDate =
				payload.startDate;
		});

		builder.addCase(unpublishAllChapters.fulfilled, (state, action) => {
			for (const key of action.payload.keys) {
				unpublish(state, key);
			}
		});

		builder.addCase(unpublishChapter.fulfilled, (state, action) => {
			const key = keyProvider.chapter(action.payload.id);

			unpublish(state, key);
		});

		builder.addCase(updateChapter.fulfilled, (state, {payload}) => {
			const key = keyProvider.chapter(payload.id);

			const chapter = state.byKey[key];
			if (chapter) {
				patchField(chapter, "title", payload.title);
				patchField(chapter, "endDate", payload.endDate);
				patchField(chapter, "sessionLimit", payload.sessionLimit);
				patchField(chapter, "timeLimit", payload.timeLimit);
				patchField(chapter, "answerVisibility", payload.answerVisibility);
				patchField(chapter, "feedbackVisibility", payload.feedbackVisibility);
			}
		});

		builder.addCase(updateChaptersOrder.fulfilled, (state, action) => {
			updateNumbers(state, action.payload.chapterKeys);
		});
	},
});

function unpublish(state: Chapters, key: string) {
	const chapter = state.byKey[key];

	if (chapter) {
		delete chapter.startDate;
	}
}

function updateNumbers(state: Chapters, keys: string[]) {
	keys.forEach((key, i) => {
		state.byKey[key].number = i + 1;
	});
}

export default chaptersSlice.reducer;
