import {createSelector} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {RootState} from "../store";

const selectSectionOfExercise = createSelector(
	(state: RootState) => state.chapterExercises.byKey,
	(state: RootState) => state.exercises.byKey,
	(_: RootState, chapterExerciseKey: string) => chapterExerciseKey,
	(chapterExercises, exercises, chapterExerciseKey) => {
		const chapterExercise = chapterExercises[chapterExerciseKey];
		if (!chapterExercise) {
			return null;
		}

		const exercise = exercises[keyProvider.exercise(chapterExercise.sectionId)];
		if (!exercise) {
			return null;
		}

		return {
			id: chapterExercise.sectionId,
			title: exercise.title,
		};
	}
);

export default selectSectionOfExercise;
