import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React, {useState} from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import {organisationService} from "../../store/services/organisationService";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

function useDeleteAction(
	onDelete: () => void,
	openConfirmationDialog: OpenConfirmationDialog,
	showSnackbar: ShowSnackbar
): [(organisationName: string) => void, React.ReactElement] {
	const [deletionErrorOpen, setDeletionErrorOpen] = useState(false);
	const {l10n} = useLocalization();

	function deleteAction(organisationName: string) {
		return openConfirmationDialog({
			title: (
				<Localized id="organisations-confirm-delete-dialog-title">
					Delete organisation?
				</Localized>
			),
			description: (
				<Localized
					id="organisations-confirm-delete-dialog-description"
					elems={{
						paragraph: <DialogContentText></DialogContentText>,
					}}
					vars={{organisationName}}
				>
					<>
						{[
							"<paragraph>You are going to delete organisation '{$organisationName}'.</paragraph>",
							"<paragraph>The organisation will not be deleted if there are users or courses in the organisation.</paragraph>",
						].join("")}
					</>
				</Localized>
			),
			confirmBtnText: (
				<Localized id="organisations-confirm-delete-dialog-confirm-button-text">
					Delete
				</Localized>
			),
			onConfirm: async () => {
				try {
					await organisationService.deleteOrganisation(organisationName);
					onDelete();
				} catch (error) {
					if ((error as {code: string}).code === "conflict") {
						setDeletionErrorOpen(true);
					} else {
						showSnackbar("error", l10n.getString("error-general"));
					}
				}
			},
		});
	}

	return [
		deleteAction,
		<OrganisationWithUserOrCourseErrorDialog
			key="organisation-with-user-or-course-error-dialog"
			open={deletionErrorOpen}
			onClose={() => setDeletionErrorOpen(false)}
		/>,
	];
}

function OrganisationWithUserOrCourseErrorDialog(props: {
	open: boolean;
	onClose: () => void;
}) {
	const titleId = "error-dialog";

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			aria-labelledby={titleId}
			maxWidth="xs"
		>
			<DialogTitle id={titleId}>
				<Localized id="organisations-error-organisation-with-user-or-course-title">
					Cannot delete organisation
				</Localized>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Localized id="organisations-error-organisation-with-user-or-course-descr">
						The organisation cannot be deleted: there are users or courses in
						it.
					</Localized>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					<Localized id="organisations-error-organisation-with-user-or-course-ok">
						OK
					</Localized>
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default useDeleteAction;
