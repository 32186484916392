import type {AlertColor} from "@mui/material";
import {createAction} from "@reduxjs/toolkit";
import NotificationAction from "./NotificationAction";

const eventOccurred = createAction<{
	message: string;
	severity: AlertColor;
	options?: {
		action?: NotificationAction;
	};
}>("notifications/eventOccurred");

export default eventOccurred;
