import React, {useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import Administration from "./administration/Administration";
import Account from "../components/account/Account";
import CourseCreation from "../components/courseCreation/CourseCreation";
import Dashboard from "../components/dashboard/Dashboard";
import EnrolmentApplicationForm from "../components/enrolment/EnrolmentApplicationForm";
import BackDestination from "../components/nav/BackDestination";
import Management from "./course/Management";
import Studying from "./course/Studying";
import NavigationBar from "./NavigationBar";
import fetchFeatures from "../store/features/fetchFeatures";
import fetchOrganisationFeatures from "../store/features/fetchOrganisationFeatures";
import {selectFrontEndFeaturesFetchStatus} from "../store/features/selectFrontEndFeatures";
import {selectOrganisationFeaturesFetchStatus} from "../store/features/selectOrganisationFeatures";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import fetchUserOrganisations from "../store/organisation/fetchUserOrganisations";
import {fetchUserRolesInOrganisation} from "../store/organisation/organisationUserRolesSlice";
import useUserHasRoleInOrganisation from "../store/organisation/useUserHasRoleInOrganisation";
import {selectUserId} from "../store/userProfile/selectUserProfile";
import RedirectOnForbiddenError from "../store/userSession/RedirectOnForbiddenError";
import Condition from "../utils/Condition";
import ModalDialogSelector from "../utils/ModalDialogSelector";

function App(props: {organisationName: string}) {
	const {organisationName} = props;

	const userId = useAppSelector(selectUserId);

	const featuresFetchStatus = useAppSelector(selectFrontEndFeaturesFetchStatus);
	const orgFeaturesFetchStatus = useAppSelector(
		selectOrganisationFeaturesFetchStatus
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (userId) {
			dispatch(fetchUserOrganisations(userId));
		}
	}, [userId, dispatch]);

	useEffect(() => {
		if (featuresFetchStatus === "none") {
			dispatch(fetchFeatures());
		}

		if (orgFeaturesFetchStatus === "none") {
			dispatch(fetchOrganisationFeatures(organisationName));
		}
	}, [dispatch, organisationName, orgFeaturesFetchStatus, featuresFetchStatus]);

	const [userHasRole, fetchStatus] = useUserHasRoleInOrganisation();

	useEffect(() => {
		if (userId && organisationName && fetchStatus === "none") {
			dispatch(
				fetchUserRolesInOrganisation({
					userId,
					orgName: organisationName,
				})
			);
		}
	}, [dispatch, fetchStatus, organisationName, userId]);

	const awaitingRoles = fetchStatus === "none" || fetchStatus === "pending";

	return (
		<>
			<NavigationBar organisationName={organisationName} />

			<Switch>
				<Route path={`/account`}>
					<BackDestination key="account">
						<Account organisationName={organisationName} />
					</BackDestination>
				</Route>

				<Route path={`/courses/new`}>
					<RedirectOnForbiddenError to="/" />
					<BackDestination key="course-creation">
						<CourseCreation organisationName={organisationName} />
					</BackDestination>
				</Route>

				<Route path={`/courses/:id/management`}>
					<BackDestination key="management">
						<Management organisationName={organisationName} />
					</BackDestination>
				</Route>

				<Route path={`/courses/:id`}>
					<Studying organisationName={organisationName} />
				</Route>

				<Route path={`/administration`}>
					<BackDestination key="administration">
						<Administration organisationName={organisationName} />
					</BackDestination>
				</Route>

				<Route path={"/enrolment-application"}>
					<EnrolmentApplicationForm
						organisationName={organisationName}
						userId={userId}
					/>
				</Route>

				<Route path={`/`} exact>
					<Condition
						value={userHasRole("any")}
						pending={awaitingRoles}
						otherwise={<Redirect to={"/enrolment-application"} />}
					>
						<Dashboard organisationName={organisationName} />
					</Condition>
				</Route>
			</Switch>

			<ModalDialogSelector organisationName={organisationName} />
		</>
	);
}

export default App;
