import {createAsyncThunk} from "@reduxjs/toolkit";
import {tutoringService} from "../services/tutoringService";
import {RootState} from "../store";
import selectUserProfile from "../userProfile/selectUserProfile";

const sendAnswer = createAsyncThunk<
	{
		messageKey: string;
		answer: string;
		answerTime: string;
		tutorId: number;
		tutorName: string;
	},
	{
		courseId: number;
		questionId: number;
		answer: string;
		messageKey: string;
	},
	{
		state: RootState;
	}
>("tutorQuestions/sendAnswer", async (params, thunkApi) => {
	await tutoringService.sendAnswer(
		params.courseId,
		params.questionId,
		params.answer
	);

	const state = thunkApi.getState();

	const userProfile = selectUserProfile(state);

	return {
		messageKey: params.messageKey,
		answer: params.answer,
		answerTime: new Date().toISOString(),
		tutorId: userProfile.id,
		tutorName: userProfile.userName,
	};
});

export default sendAnswer;
