import parseDate from "./parseDate";

export function convertLocalTimeToUTC(date: string): string {
	return parseDate(date).toISOString();
}

export function convertDateToDateTimeInUTC(date: string): string {
	return parseDate(date).toISOString();
}

export function formatToLocalTimeZoneISO(dateString?: string): string {
	const date = dateString ? parseDate(dateString) : new Date();
	const offset = date.getTimezoneOffset() * 60000;
	return new Date(date.getTime() - offset).toISOString();
}

export function formatToLocalTimeZone(dateString?: string): string {
	return formatToLocalTimeZoneISO(dateString).slice(0, -8);
}

export function daysBetween(d1: string, d2: string): number {
	return Math.abs(
		Math.round(
			(parseDate(d2).getTime() - parseDate(d1).getTime()) /
				(1000 * 60 * 60 * 24)
		)
	);
}

export function addToDate(
	date: string,
	delta: {years?: number; months?: number; days?: number}
): string {
	const d = parseDate(date);

	return new Date(
		d.getFullYear() + (delta.years ?? 0),
		d.getMonth() + (delta.months ?? 0),
		d.getDate() + (delta.days ?? 0),
		d.getHours(),
		d.getMinutes(),
		d.getSeconds(),
		d.getMilliseconds()
	).toISOString();
}

export function resetTimeComponent(datetime: Date): Date {
	const d = new Date(datetime);

	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	d.setMilliseconds(0);

	return d;
}

export function formatAtLocalTimeZone(date: Date): string {
	const offset = date.getTimezoneOffset() * 60000;

	return new Date(date.getTime() + offset).toISOString();
}
