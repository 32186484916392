import ExerciseType from "../exercises/ExerciseType";
import {RootState} from "../store";

const empty: [number, number] = [0, 0];

function selectChapterSessionContentStats(
	state: RootState,
	sessionKey: string
): [nonTheoryExercisesCount: number, totalMaxScore: number] {
	if (state.chapterSessions.lastRequested.key !== sessionKey) {
		return empty;
	}

	const exercises = Object.values(
		state.chapterSessions.lastRequested.sessionExercises
	);

	const exerciseCount = exercises.reduce(
		(prev, curr) =>
			state.exercises.byKey[curr?.exercise ?? ""].type !== ExerciseType.Theory
				? ++prev
				: prev,
		0
	);

	const totalMaxScore = exercises.reduce(
		(prev, curr) => prev + (curr?.maxScore ?? 0),
		0
	);

	return [exerciseCount, totalMaxScore];
}

export default selectChapterSessionContentStats;
