import React, {ReactNode} from "react";

import MultipleResponse from "./MultipleResponse";
import SingleResponse from "./SingleResponse";
import AdditionalActions from "../AdditionalActions";
import type {MultiInteractions} from "../../../../store/exercises/Interactions";
import {MultiExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import type {MultiResponse} from "../../../../store/studentResponses/Response";
import type {
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";

const MultiResponseAreaSelector = (props: {
	id: string;

	subtype: MultiExerciseSubtype;
	interactions: MultiInteractions;
	response: MultiResponse | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;

	additionalActions?: ReactNode | ReactNode[];

	onSave: (response: ResponseToSave) => void;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;
}): JSX.Element => {
	const {additionalActions: actions, ...responseAreaProps} = props;
	const subtype = props.subtype;

	let ResponseArea = null;

	switch (subtype) {
		case MultiExerciseSubtype.Single:
			ResponseArea = SingleResponse;

			break;
		case MultiExerciseSubtype.Multiple:
		// fall through
		case MultiExerciseSubtype.AllOrNothing:
			ResponseArea = MultipleResponse;

			break;
	}

	if (!ResponseArea) {
		return <></>;
	}

	return (
		<>
			<AdditionalActions>{actions}</AdditionalActions>

			<ResponseArea {...responseAreaProps} />
		</>
	);
};

export default MultiResponseAreaSelector;
