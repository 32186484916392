import type {ReactLocalization} from "@fluent/react";
import {unwrapResult} from "@reduxjs/toolkit";

import unpublishChapter from "../../store/chapters/unpublishChapter";
import type {AppDispatch} from "../../store/store";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

function createUnpublishChapterAction(
	courseId: number,
	l10n: ReactLocalization,
	dispatch: AppDispatch,
	showSnackbar: ShowSnackbar
): (chapterId: number) => Promise<void> {
	return async function (chapterId: number) {
		const res = await dispatch(unpublishChapter({courseId, chapterId}));

		try {
			unwrapResult(res);
		} catch (error) {
			showSnackbar(
				"error",
				l10n.getString("error-general", null, "An error has occured")
			);
		}
	};
}

export default createUnpublishChapterAction;
