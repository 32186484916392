import React, {useContext, useEffect} from "react";
import type {ReactNode} from "react";
import {BackNavManagementContext} from "./BackNavProvider";

const BackDestination = (props: {
	children: ReactNode;
	path?: string;
	fallback?: string;
}): JSX.Element => {
	const {children, fallback, path} = props;

	const dispatch = useContext(BackNavManagementContext);

	useEffect(() => {
		dispatch({type: "add", payload: {path, fallback}});

		return () => {
			dispatch({type: "remove"});
		};
	}, [dispatch, fallback, path]);

	return <>{children}</>;
};

export default BackDestination;
