import {Localized} from "@fluent/react";
import {unwrapResult} from "@reduxjs/toolkit";
import React from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import recordDecision from "../../store/enrolment/recordDecision";
import {useAppDispatch} from "../../store/hooks";

function useRejectApplications(
	courseId: number,
	dispatch: ReturnType<typeof useAppDispatch>,

	openConfirmationDialog: OpenConfirmationDialog,
	onSuccess: () => void,
	onError: () => void
) {
	async function reject(applicantIds: number[]) {
		try {
			const res = await dispatch(
				recordDecision({courseId, statement: "rejected", applicantIds})
			);

			unwrapResult(res);

			onSuccess();
		} catch {
			onError();
		}
	}

	function confirm(applicantIds: number[], applicantName?: string) {
		openConfirmationDialog({
			title: (
				<Localized
					id="student-management-enrolment-applications-reject-confirmation-dialog-title"
					vars={{number: applicantIds.length}}
				>
					Reject enrolment applications
				</Localized>
			),
			description: (
				<Localized
					id={
						applicantName
							? "student-management-enrolment-applications-reject-confirmation-dialog-desc-with-name"
							: "student-management-enrolment-applications-reject-confirmation-dialog-desc-no-name"
					}
					vars={{number: applicantIds.length, name: applicantName ?? ""}}
				>
					You are going to reject enrolment applications
				</Localized>
			),
			confirmBtnText: (
				<Localized id="student-management-enrolment-applications-reject-confirmation-dialog-action-reject">
					Reject
				</Localized>
			),
			onConfirm: () => reject(applicantIds),
		});
	}

	return confirm;
}

export default useRejectApplications;
