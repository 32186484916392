import {Chip, Typography, useTheme} from "@mui/material";
import {withStyles} from "@mui/styles";
import React from "react";

import LightTooltip from "../../utils/LightTooltip";

const Tooltip = withStyles((theme) => ({
	tooltip: {
		padding: theme.spacing(2),
		width: "50ch",
	},
}))(LightTooltip);

const ExercisePreviewTooltip = (props: {
	children: React.ReactElement;
	exercise: {title: string; tags: string[]};
}): JSX.Element => {
	const {exercise} = props;
	const theme = useTheme();

	return (
		<Tooltip
			placement="right"
			enterDelay={500}
			enterNextDelay={500}
			title={
				<>
					<Typography
						variant="subtitle2"
						style={{
							marginBottom: exercise.tags.length > 0 ? theme.spacing(1) : 0,
						}}
					>
						{exercise.title}
					</Typography>
					{exercise.tags.map((tag) => (
						<Chip
							key={tag}
							label={tag}
							style={{margin: theme.spacing(0, 0.5, 0.5, 0)}}
							variant="outlined"
						/>
					))}
				</>
			}
		>
			{props.children}
		</Tooltip>
	);
};

export default ExercisePreviewTooltip;
