import {axiosInstance as client} from "./axiosInstance";
import type {RawTableData, RawTableSchema} from "./progExerciseService";
import {mapTableData, mapTableSchema} from "./progExerciseService";
import type {SavedResponse} from "./dtos/SavedResponse";
import ExerciseType from "../exercises/ExerciseType";
import type TableSchema from "../exercises/prog/TableSchema";
import type TableData from "../exercises/prog/TableData";
import type Feedback from "../studentResponses/Feedback";
import type {
	CorrectOutputResult,
	IncorrectOutputResult,
} from "../studentResponses/Feedback";
import type {
	ResponseToSave,
	ResponseToSubmit,
} from "../studentResponses/Response";
import type SubmissionResult from "../studentResponses/SubmissionResult";

type ErrorTestOutput = {
	error: "output" | "runtime";
	runtime_serial: number;
	error_place: {
		position: number;
		record: number;
	};
	log: {
		stream: number;
		message: string;
	}[];
	art_msg: string;
	cputime: number;
	right: string | RawTableSchema | RawTableData;
	wrong: string | null;
	file: string | null;
	res?: RawTableSchema | RawTableData;
};

type CorrectTestOutput = {
	cputime: number;
	expect: string;
	log: {
		stream: number;
		message: string;
	}[];
	runtime_serial: number;
	res?: RawTableSchema | RawTableData;
};

type RequestBody = {
	exerciseType: ExerciseType;
	processingState?: "none" | "pending" | "processed";
	completionState?: "complete" | "incomplete";
	response?: unknown;
	selfAssessmentId?: number;
};

export const studentResponseService = {
	saveResponse: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		exerciseId: number,
		response: ResponseToSave
	): Promise<Feedback> => {
		const body: RequestBody = {
			exerciseType: response.exerciseType,
		};

		switch (response.exerciseType) {
			case ExerciseType.Math:
				body.response = {steps: response.steps};

				break;
			case ExerciseType.Multi:
				body.response = {selectedChoices: response.selectedChoices};

				break;
			case ExerciseType.Open:
				body.response =
					"finalAnswer" in response
						? {solution: response.solution, finalAnswer: response.finalAnswer}
						: {solution: response.solution};

				break;
			case ExerciseType.Prog:
				if (response.action !== "test") {
					body.processingState = "none";
				}

				body.response = {files: response.files};

				break;
			case ExerciseType.Short:
				body.response = {shortText: response.shortText};

				break;
		}

		const result = await client.patch(
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}/sessions/latest/exercises/${exerciseId}/session`,
			body,
			{headers: {"Content-Type": "application/merge-patch+json"}}
		);

		const data = result.data.feedback;
		const selfAssessmentId = result.data.selfAssessmentId;

		let feedback: Feedback;

		switch (response.exerciseType) {
			case ExerciseType.Math:
				if (!data || response.action === "delete") {
					feedback = {
						type: response.exerciseType,
						status: "unspecified",
					};
				} else {
					const f = data.perStep[response.index];

					feedback = {
						type: response.exerciseType,
						status: f.correctness,
						message: f.message ?? "",
					};
				}

				break;
			case ExerciseType.Multi:
				feedback = {
					type: response.exerciseType,
					status: "unspecified",
				};

				break;
			case ExerciseType.Open:
				feedback = {
					type: response.exerciseType,
					status: "unspecified",
				};

				if (selfAssessmentId) {
					feedback.selfAssessmentId = selfAssessmentId;
				}

				break;
			case ExerciseType.Prog:
				if (response.action === "save") {
					feedback = {
						type: response.exerciseType,
						status: "unspecified",
					};

					break;
				}

				switch (true) {
					case !data:
						feedback = {
							type: response.exerciseType,
							status: "unspecified",
						};

						break;
					case "error" in data && data.error === "compile":
						feedback = {
							type: response.exerciseType,
							status: "incorrect",
							result: {
								error: "compile",
								compilerMessage: data.compiler_msg,
								message: data.art_msg,
								file: data.file,
								line: data.line,
							},
						};

						break;
					case response.language === "sql" && "error" in data:
						feedback = {
							type: response.exerciseType,
							status: "incorrect",
							result: {
								error: data.error,
								runtimes: [preprocessTestResult(data)],
							},
						};

						break;
					case response.language === "sql":
						feedback = {
							type: response.exerciseType,
							status: "correct",
							result: {
								mac: "",
								runtimes: [preprocessTestResult(data) as CorrectOutputResult],
							},
						};

						break;
					case "error" in data:
						feedback = {
							type: response.exerciseType,
							status: "incorrect",
							result: {
								error: data.error,
								runtimes: data.runtimes.map(preprocessTestResult),
							},
						};

						break;
					default:
						feedback = {
							type: response.exerciseType,
							status: "correct",
							result: {
								mac: data.mac,
								runtimes: data.runtimes.map(preprocessTestResult),
							},
						};

						break;
				}

				break;
			case ExerciseType.Short:
				feedback = {
					type: response.exerciseType,
					status: "unspecified",
				};
		}

		return feedback;
	},

	submitResponse: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		exerciseId: number,
		response: ResponseToSubmit
	): Promise<SubmissionResult> => {
		const body: RequestBody = {
			exerciseType: response.exerciseType,
			completionState: "complete",
		};

		switch (response.exerciseType) {
			case ExerciseType.Math:
				body.response = {steps: response.steps};

				break;
			case ExerciseType.Multi:
				body.response = {selectedChoices: response.selectedChoices};

				break;
			case ExerciseType.Open:
				if ("eventId" in response) {
					body.selfAssessmentId = response.eventId;
					body.processingState = "processed";
				} else {
					body.response =
						"finalAnswer" in response
							? {
									solution: response.solution,
									finalAnswer: response.finalAnswer,
							  }
							: {solution: response.solution};
				}

				break;
			case ExerciseType.Short:
				body.response = {shortText: response.shortText};

				break;
		}

		const {
			data,
		} = await client.patch(
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}/sessions/latest/exercises/${exerciseId}/session`,
			body,
			{headers: {"Content-Type": "application/merge-patch+json"}}
		);

		let feedback: Feedback;

		switch (response.exerciseType) {
			case ExerciseType.Math:
				if (!data.feedback) {
					feedback = {
						type: response.exerciseType,
						status: "unspecified",
					};
				} else {
					feedback = {
						type: response.exerciseType,
						status: data.feedback.correctness,
						message: data.feedback.message ?? "",
					};
				}

				break;
			case ExerciseType.Multi:
				if (data.feedback?.correctness === "partially_correct") {
					data.feedback.correctness = "incorrect";
				}

				feedback = {
					type: response.exerciseType,
					status: data.feedback?.correctness ?? "unspecified",
					perChoice: data.feedback?.perChoice ?? [],
				};

				break;
			case ExerciseType.Open:
				if (!data.feedback && "eventId" in response) {
					data.feedback = {correctness: "correct"};
				}

				feedback = {
					type: response.exerciseType,
					status: data.feedback?.correctness ?? "unspecified",
					message: data.feedback?.message,
				};

				break;
			case ExerciseType.Prog:
				feedback = {
					type: response.exerciseType,
					status: "correct",
				};

				break;

			case ExerciseType.Short:
				feedback = {
					type: response.exerciseType,
					status: data.feedback?.correctness ?? "unspecified",
					message: data.feedback?.message,
				};
		}

		return {
			feedback,
			outcomes: data.outcomes,
			selfAssessmentId: data.selfAssessmentId,
		};
	},

	getResponsesForChapter: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		exerciseIds: number[]
	): Promise<SavedResponse[]> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters` +
			`/${chapterId}/answers`;

		const params = new URLSearchParams();
		exerciseIds.forEach((id) => {
			params.append("id", id.toString());
		});

		const {data} = await client.get<SavedResponse[]>(url, {params});

		return data;
	},

	getResponsesForChapterSession: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		sessionStartTime: string,
		exerciseIds: number[]
	): Promise<SavedResponse[]> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}` +
			`/sessions/${sessionStartTime}/responses`;

		const params = new URLSearchParams();
		exerciseIds.forEach((id) => {
			params.append("id", id.toString());
		});

		const {data} = await client.get<SavedResponse[]>(url, {params});

		return data;
	},

	resetResponse: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		exerciseId: number
	): Promise<void> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}/chapters` +
			`/${chapterId}/exercises/${exerciseId}/answer`;

		await client.delete(url);
	},
};

function prepareDbResult(rt: ErrorTestOutput | CorrectTestOutput) {
	const res: {
		schemaAndState?: TableSchema;
		expectedSchemaAndState?: TableSchema;
		queryResult?: TableData;
		expectedQueryResult?: TableData;
	} = {};

	if ("error" in rt) {
		if (Array.isArray(rt.res)) {
			res.expectedSchemaAndState = mapTableSchema(rt.res);
			res.schemaAndState = mapTableSchema(rt.res);
		} else {
			res.expectedQueryResult = mapTableData(rt.right as RawTableData);
			res.queryResult = rt.res && mapTableData(rt.res);
		}
	} else if (Array.isArray(rt.res)) {
		res.schemaAndState = mapTableSchema(rt.res);
	} else {
		res.queryResult = rt.res && mapTableData(rt.res);
	}

	return res;
}

function preprocessTestResult(
	rt: ErrorTestOutput | CorrectTestOutput
): IncorrectOutputResult | CorrectOutputResult {
	let result: IncorrectOutputResult | CorrectOutputResult =
		"error" in rt
			? {
					error: rt.error,
					runtimeSerial: rt.runtime_serial,
					errorPlace: rt.error_place,
					log: rt.log ?? [],
					message: rt.art_msg,
					cputime: rt.cputime,
					right: typeof rt.right !== "string" ? "" : rt.right,
					wrong: rt.wrong,
					file: rt.file,
			  }
			: {
					expect: rt.expect,
					runtimeSerial: rt.runtime_serial,
					log: rt.log ?? [],
					cputime: rt.cputime,
			  };

	if ("res" in rt) {
		const db = prepareDbResult(rt);
		result = {...result, db};
	}

	return result;
}
