import type {RootState} from "../store";

function selectEnrolmentApplications(state: RootState) {
	return state.selfEnrolment.applications.applications;
}

function selectEnrolmentApplicationsFetchStatus(state: RootState) {
	return state.selfEnrolment.applications.status;
}

export default selectEnrolmentApplications;
export {selectEnrolmentApplicationsFetchStatus};
