import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {useLocation} from "react-router";
import {scrollToElement} from "../../../helpers/scrollHelpers";

const useStyles = makeStyles((theme) =>
	createStyles({
		anchorLink: {
			position: "absolute",
			marginTop: theme.spacing(-45),
		},
		exerciseNumber: {
			marginRight: theme.spacing(1),
		},
	})
);

type Props = {
	sectionNumber: number;
	exerciseNumber: number;
	exerciseId: number;
	title: string;
	metaIcon?: React.ReactNode;
};

const ExerciseTitle = (props: Props): JSX.Element => {
	const classes = useStyles();

	const exerciseElemId = `subsection-${props.exerciseId}`;
	const location = useLocation();
	const elementId = location.hash.slice(1);

	useEffect(() => {
		if (elementId && elementId === exerciseElemId) {
			scrollToElement(elementId);
		}
	}, [elementId, exerciseElemId]);

	useEffect(() => {
		let timeout: undefined | ReturnType<typeof setTimeout>;
		if (elementId && elementId === exerciseElemId) {
			timeout = setTimeout(() => scrollToElement(elementId), 1200);
		}
		return () => {
			timeout && clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box display="flex" alignItems="flex-start" mb={1}>
			<a className={classes.anchorLink} id={exerciseElemId} />
			<Typography variant="h5" style={{display: "flex"}}>
				<Typography
					variant="inherit"
					component="span"
					className={classes.exerciseNumber}
					color="textSecondary"
				>
					{`${props.sectionNumber}.${props.exerciseNumber}`}
				</Typography>
				{props.title}
			</Typography>
			{props.metaIcon && <Box ml={1}>{props.metaIcon}</Box>}
		</Box>
	);
};

export default ExerciseTitle;
