import {H5PEditorUI} from "@lumieducation/h5p-react";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";

import h5pContentService from "../../store/services/h5pContentService";

export type H5PEditorApi = {
	save: (exerciseId: number) => Promise<void>;
};

const H5PEditor = (
	props: {
		contentId: string | "new";
		onSave: (contentId: string) => void;
		onSaveError: (message: string) => void;
		onLoaded: () => void;
	},
	ref: ForwardedRef<H5PEditorApi>
): JSX.Element => {
	const {onSaveError, onLoaded} = props;

	const h5pEditor = useRef<H5PEditorUI>(null);

	const exerciseId = useRef(0);

	useImperativeHandle(ref, () => ({
		async save(id) {
			exerciseId.current = id;
			const returnData = await h5pEditor.current?.save();
			if (returnData) {
				props.onSave(returnData.contentId);
			}
		},
	}));

	return (
		<H5PEditorUI
			key={props.contentId}
			ref={h5pEditor}
			contentId={props.contentId}
			loadContentCallback={h5pContentService.getEditorData}
			saveContentCallback={(
				contentId: string,
				requestBody: Record<string, unknown>
			) => h5pContentService.save(exerciseId.current, contentId, requestBody)}
			onSaveError={onSaveError}
			onLoaded={onLoaded}
		/>
	);
};

export default React.forwardRef(H5PEditor);
