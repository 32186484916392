import {useLocalization} from "@fluent/react";
import {useEffect, useMemo, useState} from "react";

import {Page} from "../../helpers/paginatedSearchHelpers";
import FetchStatus from "../../store/FetchStatus";

function useLoadMore<T, K>(
	query: string,
	pageFetcher: (query: string, pageSize: number) => Promise<Page<T, K>>
) {
	const [page, setPage] = useState<Page<T, K> | null>(null);

	const [fetchStatus, setFetchStatus] = useState<FetchStatus>("none");

	const {l10n} = useLocalization();

	const [loadingMore, setLoadingMore] = useState(false);
	const loadMoreOption = useMemo<{name: string}>(
		() => ({
			name: loadingMore
				? l10n.getString("autocomplete-loading")
				: l10n.getString("autocomplete-load-more"),
		}),
		[l10n, loadingMore]
	);

	useEffect(() => {
		let active = true;

		setFetchStatus("pending");

		pageFetcher(query, 100)
			.then((p) => {
				if (active) {
					setPage(p);
					setFetchStatus("succeeded");
				}
			})
			.catch(() => {
				setFetchStatus("failed");
			});

		return () => {
			active = false;
		};
	}, [pageFetcher, query]);

	const hasNext = Boolean(page?.request["next"]);

	async function loadMore() {
		if (loadingMore || !hasNext) {
			return;
		}

		setLoadingMore(true);

		page?.request["next"]?.()
			.then((newPage) => {
				setPage({...newPage, content: [...page.content, ...newPage.content]});
			})
			.finally(() => setLoadingMore(false));
	}

	return {
		loading: fetchStatus === "none" || fetchStatus === "pending",
		loadMoreOption: hasNext ? loadMoreOption : null,
		options: fetchStatus !== "succeeded" || !page ? [] : page.content,
		loadMore,
	};
}

export default useLoadMore;
