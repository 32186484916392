import {createAsyncThunk} from "@reduxjs/toolkit";
import {userService} from "../services/userService";

const updateUserProfile = createAsyncThunk(
	"users/updateUserProfile",
	async (params: {
		userId: number;
		profile: {
			language: string;
			firstName: string;
			lastName: string;
			phoneNumber?: string;
		};
	}) => {
		await userService.updateUserProfile(params.userId, params.profile);

		return {
			id: params.userId,
			profile: params.profile,
		};
	}
);

export default updateUserProfile;
