import {Localized, useLocalization} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
	Button,
	Container,
	DialogActions,
	DialogContent,
	DialogContentText,
	TextField,
	Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";

import type FetchStatus from "../../store/FetchStatus";
import {organisationService} from "../../store/services/organisationService";
import type {Option} from "../../utils/autocomplete/Option";
import PaginatedSingleSelectionAutocomplete from "../../utils/autocomplete/PaginatedSingleSelectionAutocomplete";
import StatusPanelLayout from "../../utils/StatusPanelLayout";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function GroupCourseDeepCopyDialog(props: {
	titleElementId: string;
	groupName: string;
	courseName: string;
	defaultOrganisationName: string;
	onCancel: () => void;
	onCopy: (organisationName: string, newCourseName: string) => Promise<number>;
	ensureCanCopy: () => Promise<string | null>;
}) {
	const {l10n} = useLocalization();

	const [name, setName] = useState(() =>
		l10n.getString(
			"group-courses-deep-copy-dialog-default-course-name",
			{name: props.courseName.trim()},
			`${props.courseName} (copy)`
		)
	);

	const [selectedOrg, setSelectedOrg] = useState(props.defaultOrganisationName);

	const [
		defaultOrgOption,
		setDefaultOrgOption,
	] = useState<Option<string> | null>(null);

	const [emptyName, setEmptyName] = useState(false);
	const [emptyOrg, setEmptyOrg] = useState(false);

	const [error, setError] = useState<string | null>(null);

	const [checkInProgress, setCheckInProgress] = useState(true);
	const [status, setStatus] = useState<FetchStatus>("none");

	const [copyId, setCopyId] = useState(0);

	const fetchOrgPage = useCallback(
		(query: string, pageSize: number) => {
			return organisationService.searchGroupOrganisations(
				props.groupName,
				{query},
				{field: "displayName"},
				pageSize
			);
		},
		[props.groupName]
	);

	const {ensureCanCopy: ensureCanCreate} = props;

	useEffect(() => {
		ensureCanCreate()
			.then(setError)
			.finally(() => setCheckInProgress(false));
	}, [ensureCanCreate]);

	useEffect(() => {
		if (props.defaultOrganisationName) {
			organisationService
				.searchGroupOrganisations(
					props.groupName,
					{query: props.defaultOrganisationName},
					{field: "displayName"},
					1
				)
				.then((res) => {
					if (res.content.length > 0) {
						setDefaultOrgOption({
							id: props.defaultOrganisationName,
							name: res.content[0].displayName,
							secondaryName: props.defaultOrganisationName,
						});
					}
				});
		}
	}, [props.defaultOrganisationName, props.groupName]);

	async function copy() {
		if (error) {
			return;
		}

		if (name === "" || selectedOrg === "") {
			setEmptyName(name === "");
			setEmptyOrg(selectedOrg === "");
			return;
		}

		setStatus("pending");

		try {
			const id = await props.onCopy(selectedOrg, name);
			setStatus("succeeded");
			setCopyId(id);
		} catch {
			setStatus("failed");
		}
	}

	const title = (
		<WindowedDialogTitle
			id={props.titleElementId}
			title={
				<Localized id="group-courses-deep-copy-dialog-title">
					Deep copy
				</Localized>
			}
			onClose={props.onCancel}
		/>
	);

	if (status === "succeeded") {
		return (
			<>
				{title}

				<DialogContent dividers>
					<ContentOnSuccess courseId={copyId} organisationName={selectedOrg} />
				</DialogContent>
			</>
		);
	}

	if (status === "failed") {
		return (
			<>
				{title}

				<DialogContent dividers>
					<ContentOnError />
				</DialogContent>
			</>
		);
	}

	return (
		<>
			{title}

			<DialogContent dividers>
				{error && (
					<StatusPanelLayout
						icon={<ErrorIcon color="error" />}
						text={error}
						sx={{mb: 2}}
					/>
				)}

				<DialogContentText>
					<Localized
						id="group-courses-deep-copy-dialog-descr"
						vars={{course: props.courseName}}
					>
						You are going to create a deep copy of a course.
					</Localized>
				</DialogContentText>

				<DialogContentText>
					<Localized id="group-courses-deep-copy-dialog-descr-select-org">
						Select an organisation to add the copy to
					</Localized>
				</DialogContentText>

				<PaginatedSingleSelectionAutocomplete
					label={
						<Localized id="group-courses-deep-copy-dialog-label-organisation">
							Organisation
						</Localized>
					}
					required
					error={emptyOrg}
					defaultOption={defaultOrgOption ?? undefined}
					pageFetcher={fetchOrgPage}
					optionMapper={(v) => ({
						id: v.name,
						name: v.displayName,
						secondaryName: v.name,
					})}
					onChange={(name) => {
						setSelectedOrg(name ?? "");
						setEmptyOrg(false);
					}}
				/>

				<TextField
					required
					fullWidth
					label={
						<Localized id="group-courses-deep-copy-dialog-label-course-name">
							New course name
						</Localized>
					}
					value={name}
					error={emptyName}
					sx={{mt: 3}}
					onChange={({target}) => {
						setEmptyName(false);
						setName(target.value);
					}}
				/>
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="group-courses-deep-copy-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<SubmitButton
					inProgress={status === "pending"}
					variant="text"
					disabled={Boolean(error) || checkInProgress}
					onClick={copy}
				>
					<Localized id="group-courses-deep-copy-dialog-action-copy">
						Copy
					</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

function ContentOnSuccess(props: {organisationName: string; courseId: number}) {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				py: 4,
			}}
		>
			<DoneIcon sx={{height: "50px", width: "50px"}} color="success" />
			<Typography variant="h6">
				<Localized id="group-courses-deep-copy-dialog-success-title">
					Successfully copied
				</Localized>
			</Typography>
			<Typography>
				<Localized id="group-courses-deep-copy-dialog-success-descr">
					The new course was added to the organisation
				</Localized>
			</Typography>
			<Button
				color="primary"
				component="a"
				href={`/organisations/${props.organisationName}/courses/${props.courseId}/management/content`}
				target="_blank"
				endIcon={<OpenInNewIcon />}
				sx={{mt: 2}}
			>
				<Localized id="group-courses-deep-copy-dialog-success-action-open">
					Open
				</Localized>
			</Button>
		</Container>
	);
}

function ContentOnError() {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				py: 10,
			}}
		>
			<Typography variant="h6">
				<Localized id="group-courses-deep-copy-dialog-error-title">
					Copying failed
				</Localized>
			</Typography>
			<Typography>
				<Localized id="group-courses-deep-copy-dialog-error-descr">
					An incomplete course was possibly created
				</Localized>
			</Typography>
		</Container>
	);
}

export default GroupCourseDeepCopyDialog;
