import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";
import type NormalisedChapterContent from "./NormalisedChapterContent.ts";
import {normaliseChapterContent} from "./NormalisedChapterContent.ts";

export const getChapterContent = createAsyncThunk<
	{
		courseId: number;
		courseKey: string;
		chapterId: number;
		chapterKey: string;
		entities: NormalisedChapterContent;
	},
	{
		courseId: number;
		chapterId: number;
	}
>("chapters/getContent", async ({courseId, chapterId}) => {
	const content = await courseService.getChapterContent(courseId, chapterId);

	const entities = normaliseChapterContent(chapterId, content);

	return {
		courseId,
		courseKey: keyProvider.course(courseId),
		chapterId,
		chapterKey: keyProvider.chapter(chapterId),
		entities,
	};
});
