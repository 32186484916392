import {RootState} from "../store";

const empty: string[] = [];

function selectChapterSessionSectionKeys(
	state: RootState,
	sessionKey: string
): string[] {
	return state.chapterSessions.lastRequested.key === sessionKey
		? state.chapterSessions.lastRequested.sectionKeys
		: empty;
}

export default selectChapterSessionSectionKeys;
