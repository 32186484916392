import {createAsyncThunk} from "@reduxjs/toolkit";

import {userService} from "../services/userService";

const deleteUser = createAsyncThunk(
	"users/deleteUser",
	async (params: {userId: number; username: string; password: string}) => {
		await userService.deleteUser(
			params.userId,
			params.username,
			params.password
		);
	}
);

export default deleteUser;
