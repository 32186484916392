import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {createStyles, withStyles} from "@mui/styles";

const SuccessCircleIcon = withStyles((theme) =>
	createStyles({
		root: {
			color: theme.palette.success.main,
		},
	})
)(CheckCircleIcon);

export default SuccessCircleIcon;
