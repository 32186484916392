import {Box, Typography} from "@mui/material";
import React from "react";

const ContentItemTitle = (props: {
	number: string;
	name: string;
}): JSX.Element => {
	return (
		<Box component="span">
			<Typography
				variant="inherit"
				color="textSecondary"
				whiteSpace="pre"
				component="span"
			>
				{`${props.number} `}
			</Typography>
			{props.name}
		</Box>
	);
};

export default ContentItemTitle;
