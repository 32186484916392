import {Localized, useLocalization} from "@fluent/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
	Autocomplete,
	Button,
	ClickAwayListener,
	Grow,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader,
	Popper,
	TextField,
	Typography,
	autocompleteClasses,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import type {Modifier} from "@popperjs/core";
import clsx from "clsx";
import React, {useState} from "react";

import {useAppSelector} from "../../store/hooks";
import type Organisation from "../../store/organisation/Organisation";
import {selectSortedUserOrganisations} from "../../store/organisation/selectUserOrganisations";
import useFetchOrganisation from "../../store/organisation/useFetchOrganisation";
import useCurrentLocale from "../../i18n/useCurrentLocale";

const useStyles = makeStyles((theme) =>
	createStyles({
		popper: {
			zIndex: theme.zIndex.drawer + 3,
		},
		root: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.shape.borderRadius,
			boxShadow: theme.shadows[3],
			color: theme.palette.text.primary,
			width: 300,
		},
		orgList: {
			padding: theme.spacing(1, 0),
		},
	})
);

const searchEnableThreshold = 5;

const OrganisationSelector = (props: {
	currentOrganisationName: string;
}): JSX.Element => {
	const classes = useStyles();

	const locale = useCurrentLocale();
	const organisations = useAppSelector((state) =>
		selectSortedUserOrganisations(state, locale)
	);

	const [currentOrg] = useFetchOrganisation(props.currentOrganisationName);

	const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

	if (!currentOrg) {
		return <></>;
	}

	if (
		organisations.length === 0 ||
		(organisations.length === 1 && organisations[0] === currentOrg)
	) {
		return <Typography>{currentOrg.displayName}</Typography>;
	}

	const userNotInOrg = organisations.every((o) => o.name !== currentOrg.name);

	function close() {
		setMenuAnchor(null);
	}

	return (
		<>
			<Button
				color="inherit"
				disableRipple
				endIcon={menuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				onClick={(event) => setMenuAnchor(event.currentTarget)}
				style={{textTransform: "none"}}
			>
				{currentOrg.displayName || ""}
			</Button>

			<Popper
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				placement="bottom-start"
				transition
				className={classes.popper}
			>
				{({TransitionProps}) => (
					<Grow {...TransitionProps}>
						<div className={classes.root}>
							<ClickAwayListener onClickAway={close}>
								<div>
									{userNotInOrg && (
										<HeaderForExternalUser
											organisation={currentOrg}
											onItemClick={close}
										/>
									)}

									{organisations.length > searchEnableThreshold ? (
										<OrganisationListWithSearch
											current={currentOrg}
											organisations={organisations}
											userIsExternal={userNotInOrg}
											onItemClick={close}
										/>
									) : (
										<div className={classes.orgList}>
											<OrganisationList
												current={currentOrg}
												organisations={organisations}
											/>
										</div>
									)}
								</div>
							</ClickAwayListener>
						</div>
					</Grow>
				)}
			</Popper>
		</>
	);
};

const OrganisationList = (props: {
	current: Organisation;
	organisations: Organisation[];
}) => {
	const {current, organisations} = props;

	return (
		<>
			{organisations.map((o, i) => (
				<OrganisationListItem
					key={i}
					current={current.name === o.name}
					organisation={o}
				/>
			))}
		</>
	);
};

const useSearchListStyles = makeStyles((theme) => ({
	inputBase: {
		padding: theme.spacing(0, 2),
	},
	paper: {
		margin: 0,
	},
	root: {
		borderBottom: "1px solid",
		borderBottomColor: theme.palette.divider,
		paddingBottom: theme.spacing(2),
	},
	paddingTop: {
		paddingTop: theme.spacing(2),
	},
	listbox: {
		maxHeight: `calc(80vh - ${theme.spacing(4)})`,
		[`& .${autocompleteClasses.option}`]: {
			padding: 0,
		},
	},
}));

function OrganisationListWithSearch(props: {
	organisations: Organisation[];
	current: Organisation;
	userIsExternal: boolean;
	onItemClick?: () => void;
}) {
	const {current, organisations} = props;

	const classes = useSearchListStyles();

	const {l10n} = useLocalization();

	return (
		<Autocomplete
			classes={{
				paper: classes.paper,
				root: clsx(classes.root, {[classes.paddingTop]: !props.userIsExternal}),
				listbox: classes.listbox,
			}}
			open
			clearOnBlur={false}
			disablePortal
			options={organisations}
			renderInput={(params) => (
				<TextField
					ref={params.InputProps.ref}
					slotProps={{htmlInput: params.inputProps}}
					autoFocus
					fullWidth
					className={classes.inputBase}
					placeholder={l10n.getString(
						"organisation-selector-search-placeholder",
						null,
						"Search"
					)}
				/>
			)}
			slotProps={{
				popper: {
					modifiers: [relativePosition],
				},
			}}
			ListboxProps={{tabIndex: -1}}
			getOptionLabel={(org) => org.displayName}
			getOptionKey={(org) => org.name}
			renderOption={(prop, org) => (
				<OrganisationListItem
					key={prop.key}
					current={current.name === org.name}
					organisation={org}
					optionProps={prop}
					onClick={props.onItemClick}
				/>
			)}
			renderTags={() => null}
			onChange={(_, o) => {
				if (o && o.name !== current.name) {
					window.location.href = `/organisations/${o.name}`;
				}
			}}
		/>
	);
}

const relativePosition: Modifier<"applyStyles", object> = {
	name: "applyStyles",
	enabled: true,
	phase: "write",
	fn({state}) {
		Object.assign(state.elements.popper.style, {
			position: "relative",
		});
	},
};

function OrganisationListItem(props: {
	organisation: Organisation;
	current: boolean;
	optionProps?: React.HTMLAttributes<HTMLLIElement>;
	onClick?: () => void;
}) {
	const {current, organisation: o, onClick} = props;

	let link;
	if (!current) {
		link = {
			component: "a",
			href: `/organisations/${o.name}`,
		};
	}

	return (
		<ListItem disablePadding {...props.optionProps}>
			<ListItemButton
				onClick={(event: React.MouseEvent) => {
					event.stopPropagation();
					onClick?.();
				}}
				selected={current}
				{...link}
			>
				<ListItemText>{o.displayName}</ListItemText>
			</ListItemButton>
		</ListItem>
	);
}

function HeaderForExternalUser(props: {
	organisation: Organisation;
	onItemClick?: () => void;
}) {
	const {organisation} = props;

	return (
		<>
			<ListSubheader disableSticky component="div">
				<Localized id="organisation-selector-current-org-label">
					Current organisation
				</Localized>
			</ListSubheader>

			<OrganisationListItem
				current
				organisation={organisation}
				onClick={props.onItemClick}
			/>

			<ListSubheader disableSticky component="div">
				<Localized id="organisation-selector-user-orgs-label">
					Your organisations
				</Localized>
			</ListSubheader>
		</>
	);
}

export default OrganisationSelector;
