import parseDate from "../../helpers/parseDate";

type ChapterSession = {
	startTime: string;
	submissionTime?: string;
};

function calculateChapterSessionEndTime(
	startTime: string,
	timeLimit: number
): Date {
	return new Date(parseDate(startTime).getTime() + timeLimit * 1000);
}

export default ChapterSession;
export {calculateChapterSessionEndTime};
