import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import MoreVert from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	Checkbox,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useEffect, useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";

import CreateUserDialog from "./CreateUserDialog";
import Filters from "./Filters";
import {formatAtLocalTimeZone} from "../../../helpers/dateTimeHelpers";
import parseDate from "../../../helpers/parseDate";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import useMobileMode from "../../../hooks/useMobileMode";
import useNavBarHeight from "../../../hooks/useNavBarHeight";
import useDateTimeFormat from "../../../i18n/useDateTimeFormat";
import staffMemberRolesChanged from "../../../store/courses/staffMemberRolesChanged";
import studentStatusChanged from "../../../store/enrolment/studentStatusChanged";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import type UserRole from "../../../store/models/UserRole";
import {adminService} from "../../../store/services/adminService";
import type UserSearchResult from "../../../store/services/dtos/UserSearchResult";
import {userService} from "../../../store/services/userService";
import type {UserSearchCriteria} from "../../../store/services/userService";
import useSnackbar from "../../../store/ui/useSnackbar";
import {selectUserId} from "../../../store/userProfile/selectUserProfile";
import useAssignToOrganisationCoursesAction from "../../users/useAssignToOrganisationCoursesAction";
import useEnrolInOrganisationCourseAction from "../../users/useEnrolInOrganisationCourseAction";
import type DialogResult from "../../../utils/DialogResult";
import ExtendableBox from "../../../utils/ExtendableBox";
import ListDivider from "../../../utils/ListDivider";
import type HeadCell from "../../../utils/tables/HeadCell";
import LoadingErrorState from "../../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../../utils/tables/SortingHeader";
import SortOrder from "../../../utils/tables/SortOrder";
import TablePagination from "../../../utils/tables/TablePagination";
import TableToolbar from "../../../utils/tables/TableToolbar";
import useBulkSelection from "../../../utils/tables/useBulkSelection";
import usePaginationState from "../../../utils/tables/usePaginationState";

const filtersBarWidth = 320;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<UserSearchResult>[] = [
	{
		id: "userName",
		label: "administration-users-table-label-user-name",
		sortable: true,
	},
	{
		id: "firstName",
		label: "administration-users-table-label-first-name",
		sortable: true,
		width: 210,
	},
	{
		id: "lastName",
		label: "administration-users-table-label-last-name",
		sortable: true,
		width: 210,
	},
	{
		id: "lastLogin",
		label: "administration-users-table-label-last-login",
		sortable: false,
		width: 210,
		align: "right",
	},
];

const columnNumber = headCells.length + 2;

const Users = (props: {organisationName: string}): JSX.Element => {
	const {organisationName} = props;
	const navBarHeight = useNavBarHeight();

	const userId = useAppSelector(selectUserId);

	const classes = useStyles();

	const [createUserOpen, setCreateUserOpen] = useState(false);

	const [confirmDialog, openConfirmDialog] = useConfirmationDialog();

	const [usersPerPage, setUsersPerPage] = useState(10);

	const [sortField, setSortField] = useState<keyof UserSearchResult>(
		"userName"
	);
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const [openFilters, setOpenFilters] = useState(false);

	const location = useLocation();

	const [criteria, setCriteria] = useState<UserSearchCriteria>(() => {
		const search = new URLSearchParams(location.search);

		if (location.search === "") {
			return {};
		}

		const courseIds = search.getAll("courseId").map(parseInt);
		const roles = search.getAll("role") as UserRole[];
		const lastLoginAfter = search.get("lastLoginSince") ?? undefined;
		const lastLoginBefore = search.get("lastLoginBefore") ?? undefined;
		const query = search.get("query") ?? undefined;

		setOpenFilters(true);

		return {
			courseIds,
			roles,
			lastLoginAfter,
			lastLoginBefore,
			query,
		};
	});

	const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(
		null
	);
	const [menuUserId, setMenuUserId] = useState(0);

	const {l10n} = useLocalization();

	const {
		page: usersPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage: fetchUserPage,
		reloadPage: reload,
		retryFetching,
	} = usePaginationState<UserSearchResult>();

	const users = usersPage.content;

	const {
		select: selectUser,
		bulkSelectionCheckbox,
		selected: selectedUsers,
		resetSelection,
	} = useBulkSelection(usersPage, (u) => u.id);

	const mobileMode = useMobileMode("md");
	const showSnackbar = useSnackbar();
	const dispatch = useAppDispatch();

	const changeQuery = useCallback((query: string) => {
		setCriteria((prev) => ({...prev, query}));
	}, []);

	const [
		enrolInCourse,
		enrolInCourseDialog,
	] = useEnrolInOrganisationCourseAction(
		organisationName,
		mobileMode,
		(courseId) => {
			if (menuUserId === userId || selectedUsers.includes(userId)) {
				dispatch(studentStatusChanged({courseId}));
			}

			setMenuUserId(0);
			resetSelection();
		}
	);

	const [
		assignToCourses,
		assignToCoursesDialog,
	] = useAssignToOrganisationCoursesAction(
		organisationName,
		mobileMode,
		(courseIds) => {
			if (menuUserId === userId || selectedUsers.includes(userId)) {
				dispatch(staffMemberRolesChanged({courseIds}));
			}

			setMenuUserId(0);
			resetSelection();
		}
	);

	useEffect(() => {
		fetchFirstPage(
			() =>
				userService.searchUsersInOrganisation(
					organisationName,
					{
						...criteria,
						roles:
							criteria.roles && criteria.roles.length > 0
								? criteria.roles
								: undefined,
						lastLoginAfter: criteria.lastLoginAfter
							? formatAtLocalTimeZone(parseDate(criteria.lastLoginAfter))
							: undefined,
						lastLoginBefore: criteria.lastLoginBefore
							? formatAtLocalTimeZone(parseDate(criteria.lastLoginBefore))
							: undefined,
					},
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					usersPerPage
				),
			resetSelection
		);
	}, [
		criteria,
		fetchFirstPage,
		organisationName,
		resetSelection,
		sortField,
		sortOrder,
		usersPerPage,
	]);

	function reloadPage() {
		reload(resetSelection);
	}

	const closeCreateUserDialog = (result: DialogResult) => {
		if (result.status === "success") {
			reloadPage();
		}

		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}

		setCreateUserOpen(false);
	};

	const closeFilters = useCallback(() => setOpenFilters(false), []);

	const formatDate = useDateTimeFormat();

	const changeOrder = (orderBy: keyof UserSearchResult, order: SortOrder) => {
		setSortOrder(order);
		setSortField(orderBy);
	};

	const isSelected = (id: number) => selectedUsers.indexOf(id) !== -1;

	const grantAdmin = async (userId?: number) => {
		callWithSnackbar(
			() =>
				adminService.grantAdminRigts(
					organisationName,
					userId ? [userId] : selectedUsers
				),
			reloadPage
		);
	};

	const revokeAdmin = async (userId?: number) => {
		callWithSnackbar(
			() =>
				adminService.revokeAdminRights(
					organisationName,
					userId ? [userId] : selectedUsers
				),
			reloadPage
		);
	};

	const closeUserMenu = () => {
		setUserMenuAnchor(null);
		setMenuUserId(0);
	};

	async function callWithSnackbar(
		action: () => Promise<void>,
		onSuccess?: () => void
	) {
		try {
			await action();
			showSnackbar("success", "Done");
			onSuccess && onSuccess();
		} catch {
			showSnackbar("error", "An error has occured");
		}
	}

	function deleteUsers(userIds: number[]) {
		let title = "";
		let description = "";
		if (userIds.length === 1) {
			const userNameToDelete = users.find((u) => u.id === userIds[0])?.userName;

			title = l10n.getString("administration-delete-user");
			description =
				"You are going to delete " +
				(userNameToDelete ? `user ${userNameToDelete}. ` : "a user. ") +
				"All his data will be deleted as well, although courses and course content which he created will be retained.";
		} else if (userIds.length > 1) {
			title = "Delete users?";
			description =
				"You are going to delete " +
				userIds.length +
				" users. All their data will be deleted as well, although courses and course content which they created will be retained.";
		}

		openConfirmDialog({
			confirmBtnText: l10n.getString("administration-users-action-delete"),
			description,
			onConfirm: async () => {
				await callWithSnackbar(
					() => adminService.deleteUsers(organisationName, userIds),
					reloadPage
				);
			},
			title,
		});
	}

	const noUsersSelected = selectedUsers.length === 0;

	return (
		<>
			<div className={classes.root}>
				<ExtendableBox
					rightExtension={openFilters || mobileMode ? 0 : filtersBarWidth}
					minWidth={0}
				>
					<Box
						display="flex"
						flexDirection="column"
						pt={mobileMode ? 3 : 5}
						px={mobileMode ? 3 : 6}
						pb={1.5}
						position="relative"
						style={{height: `calc(100vh - ${navBarHeight + 1}px)`}}
					>
						<TableToolbar
							mainAction={
								<Button
									variant="contained"
									color="primary"
									startIcon={<AddIcon />}
									onClick={() => setCreateUserOpen(true)}
								>
									<Localized id="administration-users-main-action-new-user">
										New user
									</Localized>
								</Button>
							}
							searchPlaceholder={l10n.getString(
								"administration-users-search-placeholder"
							)}
							onToggleFilters={() => setOpenFilters((prev) => !prev)}
							query={criteria.query ?? ""}
							onQueryChange={changeQuery}
							actionsHint={
								noUsersSelected && (
									<em>
										<Localized id="administration-users-bulk-actions-hint-select-users">
											Select users to perform actions
										</Localized>
									</em>
								)
							}
							actions={[
								{
									name: l10n.getString(
										"administration-users-bulk-action-enrol-in-course",
										null,
										"Enrol in a course"
									),
									onClick: () => enrolInCourse(selectedUsers),
									disabled: noUsersSelected,
								},
								{
									name: l10n.getString(
										"administration-users-bulk-action-assign-to-courses",
										null,
										"Assign to courses"
									),
									onClick: () => assignToCourses(selectedUsers),
									disabled: noUsersSelected,
									divider: true,
								},
								{
									name: l10n.getString(
										"administration-users-bulk-action-grant-admin-role",
										null,
										"Grant administrator role"
									),
									onClick: grantAdmin,
									disabled: noUsersSelected,
								},
								{
									name: l10n.getString(
										"administration-users-bulk-action-revoke-admin-role",
										null,
										"Revoke administrator role"
									),
									onClick: revokeAdmin,
									disabled: noUsersSelected,
									divider: true,
								},
								{
									name: l10n.getString(
										"administration-users-bulk-action-delete",
										null,
										"Delete users"
									),
									onClick: () => deleteUsers(selectedUsers),
									disabled: noUsersSelected || selectedUsers.includes(userId),
								},
							]}
						/>
						<TableContainer className={classes.container}>
							<Table stickyHeader>
								<SortingHeader
									onOrderChange={changeOrder}
									order={sortOrder}
									orderBy={sortField}
									headCells={headCells}
									leftAnnex={
										<TableCell padding="checkbox" style={{zIndex: 3}}>
											{bulkSelectionCheckbox}
										</TableCell>
									}
									rightAnnex={<TableCell />}
									loading={pageFetchStatus === "pending"}
								/>
								<TableBody>
									{pageFetchStatus === "failed" && (
										<LoadingErrorState
											description={
												<Localized id="administration-users-loading-error-descr">
													Something has gone wrong, and we cannot load users
												</Localized>
											}
											columnNumber={columnNumber}
											onReload={retryFetching}
										/>
									)}
									{pageFetchStatus === "succeeded" && users.length === 0 && (
										<NoSearchResultsState
											columnNumber={columnNumber}
											title={
												<Localized id="administration-users-no-results">
													No users
												</Localized>
											}
											description={
												<Localized id="administration-users-no-results-descr">
													No users were found matching your search criteria. Try
													to adjust filters
												</Localized>
											}
										/>
									)}
									{pageFetchStatus !== "failed" &&
										users.map((user, index) => {
											const isItemSelected = isSelected(user.id);
											const labelId = `table-checkbox-${index}`;
											return (
												<TableRow
													hover
													key={user.id}
													tabIndex={-1}
													selected={isItemSelected}
												>
													<TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															inputProps={{"aria-labelledby": labelId}}
															onClick={() => selectUser(user.id)}
														/>
													</TableCell>
													<TableCell component="th" id={labelId} scope="row">
														<Link
															component={RouterLink}
															to={`users/${user.id}`}
														>
															{user.userName}
														</Link>
													</TableCell>
													<TableCell>{user.firstName}</TableCell>
													<TableCell>{user.lastName}</TableCell>
													<TableCell align="right">
														{user.lastLogin &&
															formatDate(parseDate(user.lastLogin))}
													</TableCell>
													<TableCell padding="checkbox">
														<IconButton
															onClick={(e) => {
																setUserMenuAnchor(e.currentTarget);
																setMenuUserId(user.id);
															}}
														>
															<MoreVert />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							ml={2}
						>
							<Typography variant="subtitle2">
								{selectedUsers.length > 0 && (
									<Localized
										id="administration-selected-row-number"
										vars={{selected: selectedUsers.length}}
									>{`${selectedUsers.length} selected`}</Localized>
								)}
							</Typography>
							<TablePagination
								onPageChange={fetchUserPage}
								pageSize={usersPerPage}
								onPageSizeChange={setUsersPerPage}
								first={Boolean(usersPage.request.first)}
								last={Boolean(usersPage.request.last)}
								next={Boolean(usersPage.request.next)}
								previous={Boolean(usersPage.request.previous)}
								label={
									<Localized id="administration-users-per-page">
										Users per page
									</Localized>
								}
								disabled={pageFetchStatus !== "succeeded"}
							/>
						</Box>
					</Box>
				</ExtendableBox>
				<Filters
					organisationName={organisationName}
					open={openFilters}
					width={filtersBarWidth}
					mobileMode={mobileMode}
					onClose={closeFilters}
					criteria={criteria}
					onCriteriaChange={setCriteria}
				/>
			</div>
			<Menu
				anchorEl={userMenuAnchor}
				open={Boolean(userMenuAnchor)}
				sx={{".MuiPaper-root": {minWidth: (theme) => theme.spacing(14)}}}
				onClose={closeUserMenu}
			>
				<MenuItem
					onClick={() => {
						enrolInCourse([menuUserId]);
						setUserMenuAnchor(null);
					}}
				>
					<Localized id="administration-users-action-enrol-in-course">
						Enrol in a course
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						assignToCourses([menuUserId]);
						setUserMenuAnchor(null);
					}}
				>
					<Localized id="administration-users-action-assign-to-courses">
						Assign to courses
					</Localized>
				</MenuItem>
				<ListDivider />
				<MenuItem
					onClick={() => {
						deleteUsers([menuUserId]);
						closeUserMenu();
					}}
					disabled={menuUserId === userId}
				>
					<Localized id="administration-users-action-delete">Delete</Localized>
				</MenuItem>
			</Menu>

			<CreateUserDialog
				organisationName={organisationName}
				open={createUserOpen}
				onClose={closeCreateUserDialog}
			/>

			{confirmDialog}
			{enrolInCourseDialog}
			{assignToCoursesDialog}
		</>
	);
};

export default Users;
