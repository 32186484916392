import type Chapter from "./Chapter";
import type {RootState} from "../store";

function selectChapter(
	state: RootState,
	key: string
): Readonly<Chapter> | null {
	return state.chapters.byKey[key] ?? null;
}

export default selectChapter;
