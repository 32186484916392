import type {AxiosInstance} from "axios";

import {axiosInstance as client} from "./axiosInstance";

interface ThemeColour {
	name: "primary" | "secondary" | string;
	value: string;
}

interface Theme {
	state: "enabled" | "disabled";
	colours: ThemeColour[];
}

function createService(client: AxiosInstance) {
	async function getTheme(organisationName: string) {
		const {data} = await client.get<Theme>(
			`/api/organisations/${organisationName}/theme`
		);

		return data;
	}

	return {
		getTheme: getTheme,
	};
}

export default createService(client);
