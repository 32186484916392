import {Localized, useLocalization} from "@fluent/react";
import {Box, Button, Grid, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";

import EmptyState from "./EmptyState";
import EnrolmentCards from "./EnrolmentCards";
import parseDate from "../../helpers/parseDate";
import {StudentLeftTabName} from "./LeftTabName";
import LeftTabItem from "./LeftTabItem";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type UserCourse from "../../store/models/UserCourse";
import fetchCoursesAvailableForEnrolment from "../../store/selfEnrolment/fetchCoursesAvailableForEnrolment";
import fetchEnrolmentApplications from "../../store/selfEnrolment/fetchEnrolmentApplications";
import selectCoursesAvailableForEnrolment, {
	selectCoursesAvailableForEnrolmentFetchStatus,
} from "../../store/selfEnrolment/selectCoursesAvailableForEnrolment";
import {selectEnrolmentApplicationsFetchStatus} from "../../store/selfEnrolment/selectEnrolmentApplications";
import {
	getStudentExam,
	getStudentExamNext,
	getStudentOrdinary,
	getStudentOrdinaryNext,
	getStudentPast,
	getStudentPastNext,
	getStudentStatistics,
} from "../../store/studentDashboardSlice";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import StudentCardDashboard, {StudentCardProps} from "./StudentCardDashboard";
import StudentCardDashboardUpcoming from "./StudentCardDashboardUpcoming";

const StudentDashboard = (props: {organisationName: string}): JSX.Element => {
	const theme = useTheme();

	const dispatch = useAppDispatch();

	const [selectedLeftTab, setSelectedLeftTab] = useState<StudentLeftTabName>(
		StudentLeftTabName.MyCourses
	);

	const {
		ordinary: ordinaryList,
		exam: examList,
		past: pastList,
		nextOrdinary,
		nextExam,
		nextPast,
		myCoursesValue,
		myExamsValue,
		myPastCoursesValue,
	} = useAppSelector((state) => state.studentdashboard);

	const userId = useAppSelector(selectUserId);

	const toEnrolCount = useAppSelector(
		(state) => selectCoursesAvailableForEnrolment(state, userId).length
	);

	const ordinaryRequested = useAppSelector(
		(state) => state.studentdashboard.ordinaryRequested !== "none"
	);
	const pastRequested = useAppSelector(
		(state) => state.studentdashboard.pastRequested !== "none"
	);
	const examRequested = useAppSelector(
		(state) => state.studentdashboard.examRequested !== "none"
	);
	const applicationsFetchStatus = useAppSelector(
		selectEnrolmentApplicationsFetchStatus
	);

	const availableCoursesFetchStatus = useAppSelector((state) =>
		selectCoursesAvailableForEnrolmentFetchStatus(state, userId)
	);

	const {organisationName: orgName} = props;

	const selectedTab = (tabName: StudentLeftTabName) => {
		return selectedLeftTab === tabName;
	};

	useEffect(() => {
		if (!ordinaryRequested) {
			dispatch(getStudentOrdinary({orgName, userId}));
			dispatch(getStudentStatistics({orgName, userId}));
		}
	}, [dispatch, ordinaryRequested, orgName, userId]);

	useEffect(() => {
		if (availableCoursesFetchStatus === "none") {
			dispatch(fetchCoursesAvailableForEnrolment({orgName, userId}));
		}
	}, [availableCoursesFetchStatus, dispatch, orgName, userId]);

	useEffect(() => {
		if (applicationsFetchStatus === "none") {
			dispatch(fetchEnrolmentApplications({orgName, userId}));
		}
	}, [applicationsFetchStatus, dispatch, orgName, userId]);

	useEffect(() => {
		if (selectedLeftTab === StudentLeftTabName.MyExams && !examRequested) {
			dispatch(getStudentExam({orgName, userId}));
		}
	}, [dispatch, examRequested, orgName, selectedLeftTab, userId]);

	useEffect(() => {
		if (selectedLeftTab === StudentLeftTabName.PastCourses && !pastRequested) {
			dispatch(getStudentPast({orgName, userId}));
		}
	}, [dispatch, orgName, pastRequested, selectedLeftTab, userId]);

	const now = new Date();

	const getCoursesCards = (
		userCourses: UserCourse[],
		emptyStateMessage: string,
		renderCard: (course: StudentCardProps) => React.ReactNode,
		onLoadMore?: () => void
	) => {
		if (userCourses.length === 0) {
			return (
				<Grid item xs={12}>
					<EmptyState message={emptyStateMessage} />
				</Grid>
			);
		}

		return (
			<>
				{userCourses.map((course) => (
					<Grid item xs={12} sm={6} md={4} xl={3} key={course.id}>
						{renderCard({
							id: course.id,
							title: course.name,
							startDate: course.startDate,
							endDate: course.endDate,
							image: course.picture || course.defaultPicture,
						})}
					</Grid>
				))}

				{onLoadMore && (
					<Grid
						item
						xs={12}
						style={{display: "flex", justifyContent: "center"}}
					>
						<Button onClick={onLoadMore} color="primary">
							<Localized id="dashboard-load-more-btn">Load more</Localized>
						</Button>
					</Grid>
				)}
			</>
		);
	};

	const getCoursesCount = () => {
		switch (selectedLeftTab) {
			case StudentLeftTabName.MyCourses:
				return myCoursesValue;
			case StudentLeftTabName.MyExams:
				return myExamsValue;
			case StudentLeftTabName.PastCourses:
				return myPastCoursesValue;
			case StudentLeftTabName.CoursesToEnrol:
				return toEnrolCount;
			default:
				return 0;
		}
	};

	const selectLeftTab = (tabName: StudentLeftTabName) => () => {
		setSelectedLeftTab(tabName);
	};

	const {l10n} = useLocalization();

	return (
		<Grid container>
			<Grid item xs={3}>
				<LeftTabItem
					statisticValue={myCoursesValue}
					tabName={StudentLeftTabName.MyCourses}
					selected={selectedTab(StudentLeftTabName.MyCourses)}
					onClick={selectLeftTab(StudentLeftTabName.MyCourses)}
				/>
				<LeftTabItem
					statisticValue={myExamsValue}
					tabName={StudentLeftTabName.MyExams}
					selected={selectedTab(StudentLeftTabName.MyExams)}
					onClick={selectLeftTab(StudentLeftTabName.MyExams)}
				/>
				<LeftTabItem
					statisticValue={toEnrolCount}
					tabName={StudentLeftTabName.CoursesToEnrol}
					selected={selectedTab(StudentLeftTabName.CoursesToEnrol)}
					onClick={selectLeftTab(StudentLeftTabName.CoursesToEnrol)}
				/>
				<LeftTabItem
					statisticValue={myPastCoursesValue}
					tabName={StudentLeftTabName.PastCourses}
					selected={selectedTab(StudentLeftTabName.PastCourses)}
					onClick={selectLeftTab(StudentLeftTabName.PastCourses)}
				/>
			</Grid>

			<Grid item xs={9} container spacing={3}>
				<Grid item xs={12}>
					<Box display="flex" style={{gap: theme.spacing(1.25)}}>
						<Typography variant="h5">
							<Localized id={selectedLeftTab}>{selectedLeftTab}</Localized>
						</Typography>
						<Typography variant="h5" color="textSecondary">
							{getCoursesCount()}
						</Typography>
					</Box>
				</Grid>

				{selectedTab(StudentLeftTabName.MyCourses) &&
					getCoursesCards(
						ordinaryList,
						l10n.getString(
							"dashboard-no-ongoing-or-upcoming-courses",
							null,
							"So far, you have no ongoing or upcoming courses"
						),
						(cardProps) => {
							return parseDate(cardProps.startDate) <= now ? (
								<StudentCardDashboard {...cardProps} />
							) : (
								<StudentCardDashboardUpcoming {...cardProps} />
							);
						},
						nextOrdinary.nextHref
							? () =>
									nextOrdinary.nextHref &&
									dispatch(getStudentOrdinaryNext(nextOrdinary.nextHref))
							: undefined
					)}

				{selectedTab(StudentLeftTabName.MyExams) &&
					getCoursesCards(
						examList,
						l10n.getString(
							"dashboard-no-ongoing-or-upcoming-exams",
							null,
							"So far, you have no ongoing or upcoming exams"
						),
						(cardProps) => <StudentCardDashboard {...cardProps} />,
						nextExam.nextHref
							? () =>
									nextExam.nextHref &&
									dispatch(getStudentExamNext(nextExam.nextHref))
							: undefined
					)}

				{selectedTab(StudentLeftTabName.CoursesToEnrol) && (
					<EnrolmentCards userId={userId} />
				)}

				{selectedTab(StudentLeftTabName.PastCourses) &&
					getCoursesCards(
						pastList,
						l10n.getString(
							"dashboard-no-past-courses",
							null,
							"You have no past courses"
						),
						(cardProps) => <StudentCardDashboard {...cardProps} />,
						nextPast.nextHref
							? () =>
									nextPast.nextHref &&
									dispatch(getStudentPastNext(nextPast.nextHref))
							: undefined
					)}
			</Grid>
		</Grid>
	);
};

export default StudentDashboard;
