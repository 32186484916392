import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import chapterSessionsService from "../services/chapterSessionsService";
import type ChapterSession from "./ChapterSession";

const fetchChapterSessions = createAsyncThunk<
	{
		chapterKey: string;
		sessions: {[key: string]: ChapterSession};
		sessionKeys: string[];
	},
	{studentId: number; courseId: number; chapterId: number}
>("chapterSessions/fetch", async ({studentId, courseId, chapterId}) => {
	const chapterSessions = await chapterSessionsService.getChapterSessions(
		studentId,
		courseId,
		chapterId
	);

	const byKey: {[key: string]: ChapterSession} = {};
	const sessionKeys = new Array<string>(chapterSessions.length);

	chapterSessions.sort(byStartTimeDesc).forEach((s, i) => {
		const key = keyProvider.chapterSession(chapterId, s.startTime);
		byKey[key] = s;
		sessionKeys[i] = key;
	});

	return {
		chapterKey: keyProvider.chapter(chapterId),
		sessions: byKey,
		sessionKeys,
	};
});

function byStartTimeDesc(s1: ChapterSession, s2: ChapterSession) {
	if (s1.startTime < s2.startTime) {
		return 1;
	}
	if (s1.startTime === s2.startTime) {
		return 0;
	}
	return -1;
}

export default fetchChapterSessions;
