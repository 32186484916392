import React from "react";

const ProgramOutput = (
	props: React.CSSProperties & {
		children: React.ReactNode;
	}
): JSX.Element => {
	const {children, ...style} = props;
	return (
		<pre style={{...style}} className="program-output">
			<samp>{children}</samp>
		</pre>
	);
};

export default ProgramOutput;
