import {Localized} from "@fluent/react";
import Check from "@mui/icons-material/Check";
import {
	LinearProgress,
	ListItemIcon,
	Menu,
	MenuItem,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import type {TableCellProps} from "@mui/material";
import React, {useState} from "react";

import ListDivider from "../ListDivider";
import SortOrder from "./SortOrder";

type Column<T extends string> = {
	name: React.ReactNode;
	width?: number | string;
	align?: TableCellProps["align"];
	sortOptions?:
		| [{field: T; label?: never}]
		| {field: T; label: React.ReactNode}[];
};

type ColumnDefs<K extends string, T extends string> = {
	[key in K]: Column<T>;
};

function Head<T extends string>(props: {
	columns: Column<T>[];

	leftAnnex?: React.ReactNode;
	rightAnnex?: React.ReactNode;

	sortOrder: SortOrder;
	sortField: T;

	loading?: boolean;

	onOrderChange: (orderBy: T, order: SortOrder) => void;
}) {
	const {sortOrder, sortField, onOrderChange} = props;

	const [sortMenuAnchor, setSortMenuAnchor] = useState<{
		column: number;
		anchor: HTMLElement;
	} | null>(null);

	function getLabel(column: Column<T>, i: number) {
		if (!column.sortOptions || column.sortOptions.length === 0) {
			return column.name;
		}

		if (column.sortOptions.length > 1) {
			const active =
				column.sortOptions.findIndex((c) => c.field === sortField) > -1;

			return (
				<TableSortLabel
					active={active}
					direction={active ? sortOrder : SortOrder.Asc}
					onClick={(e) => {
						setSortMenuAnchor({column: i, anchor: e.currentTarget});
					}}
				>
					{column.name}
				</TableSortLabel>
			);
		}

		const field = column.sortOptions[0].field;

		return (
			<TableSortLabel
				active={sortField === field}
				direction={sortField === field ? sortOrder : SortOrder.Asc}
				onClick={() => {
					onOrderChange(
						field,
						sortField === field && sortOrder === SortOrder.Asc
							? SortOrder.Desc
							: SortOrder.Asc
					);
				}}
			>
				{column.name}
			</TableSortLabel>
		);
	}

	function closeSortMenu() {
		setSortMenuAnchor(null);
	}

	const sortMenuColumn =
		sortMenuAnchor && props.columns[sortMenuAnchor?.column];

	return (
		<TableHead>
			<TableRow>
				{props.leftAnnex}

				{props.columns.map((c, i) => (
					<TableCell key={i} align={c.align} sx={{width: c.width}}>
						{getLabel(c, i)}
					</TableCell>
				))}

				{props.rightAnnex}
			</TableRow>

			<TableRow style={{visibility: props.loading ? "visible" : "hidden"}}>
				<TableCell
					colSpan={
						props.columns.length +
						(props.rightAnnex ? 1 : 0) +
						(props.leftAnnex ? 1 : 0)
					}
					padding="none"
					sx={{
						top: (theme) => `calc(1.5rem + ${theme.spacing(4)} + 1px)`,
						borderBottom: 0,
					}}
				>
					<LinearProgress />
				</TableCell>
			</TableRow>

			<Menu
				open={Boolean(sortMenuAnchor)}
				anchorEl={sortMenuAnchor?.anchor}
				onClose={closeSortMenu}
			>
				{sortMenuColumn?.sortOptions?.map((c) => (
					<MenuItem
						key={c.field}
						onClick={() => {
							closeSortMenu();
							onOrderChange(c.field, sortOrder);
						}}
					>
						<ListItemIcon
							sx={{
								visibility: sortField === c.field ? "visible" : "hidden",
							}}
						>
							<Check />
						</ListItemIcon>
						{c.label}
					</MenuItem>
				))}

				<ListDivider />

				<MenuItem
					onClick={() => {
						closeSortMenu();
						onOrderChange(sortField, SortOrder.Asc);
					}}
				>
					<ListItemIcon
						sx={{
							visibility: sortOrder === SortOrder.Asc ? "visible" : "hidden",
						}}
					>
						<Check />
					</ListItemIcon>
					<Localized id="sorting-header-sort-ascending">
						Sort ascending
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						closeSortMenu();
						onOrderChange(sortField, SortOrder.Desc);
					}}
				>
					<ListItemIcon
						sx={{
							visibility: sortOrder === SortOrder.Desc ? "visible" : "hidden",
						}}
					>
						<Check />
					</ListItemIcon>
					<Localized id="sorting-header-sort-descening">
						Sort descending
					</Localized>
				</MenuItem>
			</Menu>
		</TableHead>
	);
}

export default Head;
export type {ColumnDefs};
