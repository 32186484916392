import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectCourseContentStatisticsFetchStatus(
	state: RootState,
	courseKey: string
): FetchStatus {
	return state.courseContentStatistics.byKey[courseKey]?.status ?? "none";
}

export default selectCourseContentStatisticsFetchStatus;
