import type {AxiosInstance} from "axios";

import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type UserSession from "../userSession/UserSession";
import type UserProfile from "../userProfile/UserProfile";

type PreauthResponse = {
	code: string;
	redirectUri: string;
};

type GetSessionResponse = {
	user: {
		id: number;
		name: string;
		firstName: string;
		lastName: string;
		language: string;
		avatarUrl: string;
		superAdmin?: boolean;
	};
	endTime: string;
	inactivityTimeout: number;
};

const logoutUrl = "/api/logout";

function logoutRequest(request: {url?: string}): boolean {
	return request.url === logoutUrl;
}

function createService(client: AxiosInstance) {
	async function getCurrentSession(): Promise<{
		session: UserSession;
		user: UserProfile;
	} | null> {
		try {
			const {data} = await client.get<GetSessionResponse>(
				"/api/sessions/current"
			);

			return {
				user: {
					id: data.user.id,
					userName: data.user.name,
					firstName: data.user.firstName,
					lastName: data.user.lastName,
					language: data.user.language,
					profilePhotoLink: data.user.avatarUrl,
					isSuperAdmin: data.user.superAdmin,
				},
				session: {
					endTime: data.endTime,
					inactivityTimeout: data.inactivityTimeout,
				},
			};
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 401) {
				return null;
			}
			throw error;
		}
	}

	async function login(
		userName: string,
		password: string,
		remember: boolean
	): Promise<void> {
		await client.post("/api/sessions", {
			userName: userName,
			password: password,
			longTerm: remember,
		});
	}

	async function logout(): Promise<void> {
		await client.post(logoutUrl);
	}

	async function preauthenticate(params: {
		userName: string;
		password: string;
		longTerm: boolean;
		codeChallenge: string;
		redirectUri: string;
	}): Promise<PreauthResponse> {
		const {data} = await client.post<PreauthResponse>(
			"/api/preauthenticated-sessions",
			params
		);

		return data;
	}

	async function resetPassword(
		params: {email: string} | {username: string}
	): Promise<void> {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		const queryParams = new URLSearchParams();

		let param, info;

		if ("email" in params) {
			param = "email";
			info = params.email;
		} else {
			param = "username";
			info = params.username;
		}

		queryParams.append("param", param);
		queryParams.append("info", info);

		await client.post("/api/password-reset-requests", queryParams, config);
	}

	return {
		getCurrentSession,
		login,
		logout,
		preauthenticate,
		resetPassword,
	};
}

export default createService(client);
export {logoutRequest};
