import React, {useContext} from "react";
import {useTheme} from "@mui/material";

import SidebarContext, {SidebarType} from "./SidebarContext";
import AskTutorSidebar from "./askTutor/AskTutorSidebar";
import AnswerSidebar from "./exerciseAnswers/AnswerSidebar";

const ContentSidebar = (props: {
	width: number;
	sectionKey: string;
	courseId: number;
	mobileMode: boolean;
}): JSX.Element => {
	const {type, fullscreen} = useContext(SidebarContext);

	const {mobileMode, width, ...other} = props;

	const theme = useTheme();

	let sidebarWidth: number | string = width;
	if (mobileMode) {
		sidebarWidth = `calc(100vw - ${theme.spacing(7)})`;
	} else if (fullscreen) {
		sidebarWidth = "100vw";
	}

	return (
		<div
			style={{pointerEvents: type === SidebarType.None ? "none" : "inherit"}}
		>
			<AskTutorSidebar
				open={type === SidebarType.AskTutor}
				mobileMode={mobileMode}
				width={sidebarWidth}
				{...other}
			/>
			<AnswerSidebar
				open={type === SidebarType.Answer}
				mobileMode={mobileMode}
				width={sidebarWidth}
				{...other}
			/>
		</div>
	);
};

export default ContentSidebar;
