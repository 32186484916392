import {normalize, schema} from "normalizr";
import ChapterExercise from "../chapterExercises/ChapterExercise";
import {ExerciseWithContent} from "../exercises/Exercise";
import {keyProvider} from "../keyProvider";
import * as dto from "../services/dtos/Exercise";

type NormalisedSectionContent = {
	chapterExercises: {[key: string]: ChapterExercise};
	exercises: {[key: string]: ExerciseWithContent};
};

export function normaliseSectionContent(
	chapterId: number,
	content: dto.Exercise[]
): NormalisedSectionContent {
	const normalized = normalize<
		unknown,
		{
			chapterExercises: {[key: string]: ChapterExercise};
			exercises: {[key: string]: ExerciseWithContent};
		}
	>(
		{
			chapterId,
			exercises: content,
		},
		section
	);

	return normalized.entities;
}

export default NormalisedSectionContent;

const exercise = new schema.Entity(
	"exercises",
	{},
	{
		idAttribute: (value: ExerciseWithContent) => keyProvider.exercise(value.id),
	}
);

const chapterExercise = new schema.Entity(
	"chapterExercises",
	{
		exercise: exercise,
	},
	{
		processStrategy: (value: dto.Exercise, parent: SectionInput) => {
			return {
				chapterId: parent.chapterId,
				number: value.number,
				maxScore: value.maxScore,
				exercise: {
					id: value.id,
					type: value.type,
					title: value.title,
					question: value.question,
					difficultyLevel: value.difficultyLevel,
					category: value.category,
					subtype: value.interactionsType,
					interactions: value.interactions,
				},
			};
		},
		idAttribute: (value: dto.Exercise, parent: SectionInput) =>
			keyProvider.chapterExercise(parent.chapterId, value.id),
	}
);

type SectionInput = {
	chapterId: number;
	exercises: dto.Exercise[];
};

const section = new schema.Object({
	exercises: [chapterExercise],
});
