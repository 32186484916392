import {Localized, useLocalization} from "@fluent/react";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import React, {useCallback, useEffect, useRef, useState} from "react";

import useMobileMode from "../../hooks/useMobileMode";
import type FetchStatus from "../../store/FetchStatus";
import type {
	DataRetentionPolicy,
	DataRetentionPolicyPatch,
} from "../../store/services/organisationService";
import {organisationService} from "../../store/services/organisationService";
import {patchField} from "../../store/patching";
import useSnackbar from "../../store/ui/useSnackbar";
import AppBarDependentContainer from "../../utils/AppBarDependentContainer";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import LoadingError from "../../utils/errors/LoadingError";
import SubmitButton from "../../utils/SubmitButton";

const minimumRetentionPeriod = 30;
const indefiniteRetentionPeriod = 0;

function GroupDataRetentionSettings(props: {groupName: string}) {
	const [settings, setSettings] = useState<DataRetentionPolicy>({
		inactiveAccountRetentionPeriod: indefiniteRetentionPeriod,
		archivedCourseRetentionPeriod: indefiniteRetentionPeriod,
	});

	const [settingsFetchStatus, setSettingsFetchStatus] = useState<FetchStatus>(
		"none"
	);

	const [editorOpen, setEditorOpen] = useState<"account" | "course" | null>(
		null
	);

	const [dirty, setDirty] = useState(false);

	const theme = useTheme();
	const mobileMode = useMobileMode("md");
	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	const fetchSettings = useCallback((groupName: string) => {
		setSettingsFetchStatus("pending");

		organisationService
			.getDataRetentionPolicyOfGroup(groupName)
			.then((res) => {
				setSettings(res);
				setSettingsFetchStatus("succeeded");
			})
			.catch(() => setSettingsFetchStatus("failed"));
	}, []);

	useEffect(() => {
		fetchSettings(props.groupName);
	}, [fetchSettings, props.groupName]);

	async function update(patch: DataRetentionPolicyPatch) {
		try {
			await organisationService.updateDataRetentionPolicyOfGroup(
				props.groupName,
				patch
			);

			const s = {...settings};
			patchField(
				s,
				"inactiveAccountRetentionPeriod",
				patch.inactiveAccountRetentionPeriod
			);
			patchField(
				s,
				"archivedCourseRetentionPeriod",
				patch.archivedCourseRetentionPeriod
			);

			setSettings(s);

			closeEditor();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	if (settingsFetchStatus === "pending") {
		return (
			<AppBarDependentContainer>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					style={{width: "100vw", height: "100%"}}
				>
					<CircularProgress />
				</Box>
			</AppBarDependentContainer>
		);
	}

	if (settingsFetchStatus === "failed") {
		return (
			<LoadingError
				description={
					<Localized id="group-data-retention-settings-error-load">
						Failed to load data retention configuration for the group
					</Localized>
				}
				onReload={() => fetchSettings(props.groupName)}
			/>
		);
	}

	function openEditor(key: "account" | "course") {
		if (dirty) {
			return;
		}

		setEditorOpen(key);
	}

	function closeEditor() {
		setEditorOpen(null);
		setDirty(false);
	}

	return (
		<AppBarDependentContainer>
			<Box
				maxWidth={theme.breakpoints.values.xl}
				py={mobileMode ? 3 : 5}
				px={mobileMode ? 3 : 6}
				style={{
					gap: theme.spacing(6),
					display: "flex",
					flexDirection: "column",
					height: "100%",
					overflow: "auto",
				}}
			>
				{editorOpen === "account" && (
					<EditorContainer mobileMode={mobileMode}>
						<UserAccountAutoDeletionEditor
							inactiveAccountRetentionPeriod={
								settings.inactiveAccountRetentionPeriod ?? 0
							}
							mobileMode={mobileMode}
							onChange={setDirty}
							onSave={async (period) =>
								update({
									inactiveAccountRetentionPeriod:
										period === indefiniteRetentionPeriod ? null : period,
								})
							}
							onCancel={closeEditor}
						/>
					</EditorContainer>
				)}
				{editorOpen !== "account" && (
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<SectionTitle
								text={
									<Localized id="group-data-retention-settings-inactive-account-retention-title">
										Inactive user account retention
									</Localized>
								}
								editDisabled={dirty}
								onEdit={() => openEditor("account")}
							/>
						</Grid>
						<UserAccountAutoDeletion
							inactiveAccountRetentionPeriod={
								settings.inactiveAccountRetentionPeriod ??
								indefiniteRetentionPeriod
							}
						/>
					</Grid>
				)}

				{editorOpen === "course" && (
					<EditorContainer mobileMode={mobileMode}>
						<CourseAutoDeletionEditor
							archivedCourseRetentionPeriod={
								settings.archivedCourseRetentionPeriod ??
								indefiniteRetentionPeriod
							}
							mobileMode={mobileMode}
							onChange={setDirty}
							onSave={async (period) =>
								update({
									archivedCourseRetentionPeriod:
										period === indefiniteRetentionPeriod ? null : period,
								})
							}
							onCancel={closeEditor}
						/>
					</EditorContainer>
				)}
				{editorOpen !== "course" && (
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<SectionTitle
								text={
									<Localized id="group-data-retention-settings-archived-course-retention-title">
										Archived course retention
									</Localized>
								}
								editDisabled={dirty}
								onEdit={() => openEditor("course")}
							/>
						</Grid>
						<CourseAutoDeletion
							archivedCourseRetentionPeriod={
								settings.archivedCourseRetentionPeriod ??
								indefiniteRetentionPeriod
							}
						/>
					</Grid>
				)}
			</Box>
		</AppBarDependentContainer>
	);
}

function UserAccountAutoDeletion(props: {
	inactiveAccountRetentionPeriod: number;
}) {
	const theme = useTheme();

	return (
		<Grid item xs={12} container>
			<Grid item xs={12}>
				<Typography align="justify">
					<Localized
						id="group-data-retention-settings-inactive-account-retention-descr"
						vars={{period: props.inactiveAccountRetentionPeriod}}
					>
						User accounts having no roles in a particular organisation are
						automatically removed from that organisation after being inactive
						for N days
					</Localized>
				</Typography>
			</Grid>
			{props.inactiveAccountRetentionPeriod !== indefiniteRetentionPeriod && (
				<Grid item xs={12} style={{marginTop: theme.spacing(3)}}>
					<SettingNotice
						text={
							<Localized id="group-data-retention-settings-inactive-account-retention-notice">
								If a user has no other organisations, his account will be
								completely deleted from the system.
							</Localized>
						}
					/>
				</Grid>
			)}
		</Grid>
	);
}

function UserAccountAutoDeletionEditor(props: {
	inactiveAccountRetentionPeriod: number;
	mobileMode: boolean;
	onChange: (dirty: boolean) => void;
	onSave: (period: number) => Promise<void>;
	onCancel: () => void;
}) {
	const {inactiveAccountRetentionPeriod, onChange} = props;

	const theme = useTheme();
	const {l10n} = useLocalization();

	const initial = useRef({
		preiod: inactiveAccountRetentionPeriod || minimumRetentionPeriod,
		allowDeletion: inactiveAccountRetentionPeriod > 0,
	});

	const [allowDeletion, setAllowDeletion] = useState(
		initial.current.allowDeletion
	);
	const [period, setPeriod] = useState(initial.current.preiod);

	const [errorSmallPeriod, setErrorSmallPeriod] = useState(false);

	const [saving, setSaving] = useState(false);

	const dirty =
		initial.current.allowDeletion !== allowDeletion ||
		initial.current.preiod !== period;

	useEffect(() => {
		onChange(dirty);
	}, [dirty, onChange]);

	function validate() {
		if (period < minimumRetentionPeriod) {
			setErrorSmallPeriod(true);
			return false;
		}

		return true;
	}

	async function save() {
		if (!validate()) {
			return;
		}

		setSaving(true);

		await props.onSave(allowDeletion ? period : indefiniteRetentionPeriod);

		setSaving(false);
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6">
					<Localized id="group-data-retention-settings-inactive-account-retention-title">
						Inactive user account retention
					</Localized>
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				container
				spacing={1}
				style={{
					marginTop: theme.spacing(2),
					paddingRight: theme.spacing(props.mobileMode ? 1 : 2),
				}}
			>
				<Grid item xs={12} container>
					<FormControlLabel
						control={
							<Radio
								checked={!allowDeletion}
								onChange={() => {
									setAllowDeletion(false);
									setPeriod(initial.current.preiod);
									setErrorSmallPeriod(false);
								}}
							/>
						}
						label={
							<Localized id="group-data-retention-settings-inactive-account-retention-editor-option-keep">
								Inactive user accounts are kept indefinitely
							</Localized>
						}
					/>
				</Grid>

				<Grid item xs={12} container spacing={1}>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Radio
									checked={allowDeletion}
									onChange={() => {
										setAllowDeletion(true);
										setErrorSmallPeriod(false);
									}}
								/>
							}
							label={
								<Localized id="group-data-retention-settings-inactive-account-retention-editor-option-delete-in-days">
									Inactive user accounts having no roles in a particular
									organisation are deleted after specified number of days
								</Localized>
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						style={{
							gap: theme.spacing(2),
							marginLeft: `calc(1.5rem + ${theme.spacing(1)})`,
						}}
					>
						<Grid item xs={3}>
							<TextField
								id="days"
								type="number"
								fullWidth
								autoFocus
								InputProps={{
									inputProps: {
										min: minimumRetentionPeriod,
										"aria-label": l10n.getString(
											"group-data-retention-settings-label-period"
										),
									},
								}}
								value={period}
								disabled={!allowDeletion}
								error={errorSmallPeriod}
								helperText={
									<Localized
										id="group-data-retention-settings-hint-minimum-period"
										vars={{period: minimumRetentionPeriod}}
									>
										Minimum 30 days
									</Localized>
								}
								onChange={({target}) => {
									const value = parseInt(target.value || "0");
									if (value > 0) {
										setPeriod(value);
										setErrorSmallPeriod(false);
									}
								}}
							/>
						</Grid>
					</Grid>
					{allowDeletion && (
						<Grid item xs={12} style={{marginTop: theme.spacing(3)}}>
							<SettingNotice
								text={
									<Localized id="group-data-retention-settings-inactive-account-retention-notice">
										If a user has no other organisations, his account will be
										completely deleted from the system.
									</Localized>
								}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
			<EditorActions
				saveDisabled={!dirty}
				inProgress={saving}
				mobileMode={props.mobileMode}
				onSave={save}
				onCancel={props.onCancel}
			/>
		</Grid>
	);
}

function CourseAutoDeletion(props: {archivedCourseRetentionPeriod: number}) {
	const theme = useTheme();

	return (
		<Grid item xs={12} container>
			<Grid item xs={12}>
				<Typography align="justify">
					<Localized
						id="group-data-retention-settings-archived-course-retention-descr"
						vars={{period: props.archivedCourseRetentionPeriod}}
					>
						Archived courses are automatically deleted after N days
					</Localized>
				</Typography>
			</Grid>
			{props.archivedCourseRetentionPeriod !== indefiniteRetentionPeriod && (
				<Grid item xs={12} style={{marginTop: theme.spacing(3)}}>
					<SettingNotice
						text={
							<Localized id="group-data-retention-settings-archived-course-retention-notice">
								Students and staff are not deleted along with courses.
							</Localized>
						}
					/>
				</Grid>
			)}
		</Grid>
	);
}

function CourseAutoDeletionEditor(props: {
	archivedCourseRetentionPeriod: number;
	mobileMode: boolean;
	onChange: (dirty: boolean) => void;
	onSave: (period: number) => Promise<void>;
	onCancel: () => void;
}) {
	const {archivedCourseRetentionPeriod, onChange} = props;

	const theme = useTheme();
	const {l10n} = useLocalization();

	const initial = useRef({
		preiod: archivedCourseRetentionPeriod || minimumRetentionPeriod,
		allowDeletion: archivedCourseRetentionPeriod !== indefiniteRetentionPeriod,
	});

	const [allowDeletion, setAllowDeletion] = useState(
		initial.current.allowDeletion
	);
	const [period, setPeriod] = useState(initial.current.preiod);

	const [errorSmallPeriod, setErrorSmallPeriod] = useState(false);

	const [saving, setSaving] = useState(false);

	const dirty =
		initial.current.allowDeletion !== allowDeletion ||
		initial.current.preiod !== period;

	useEffect(() => {
		onChange(dirty);
	}, [dirty, onChange]);

	function validate() {
		if (period < minimumRetentionPeriod) {
			setErrorSmallPeriod(true);
			return false;
		}

		return true;
	}

	async function save() {
		if (!validate()) {
			return;
		}

		await props.onSave(allowDeletion ? period : indefiniteRetentionPeriod);

		setSaving(false);
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<SectionTitle
					text={
						<Localized id="group-data-retention-settings-archived-course-retention-title">
							Archived course retention
						</Localized>
					}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				spacing={1}
				style={{
					marginTop: theme.spacing(2),
					paddingRight: theme.spacing(props.mobileMode ? 1 : 2),
				}}
			>
				<Grid item xs={12} container>
					<FormControlLabel
						control={
							<Radio
								checked={!allowDeletion}
								onChange={() => {
									setAllowDeletion(false);
									setPeriod(initial.current.preiod);
									setErrorSmallPeriod(false);
								}}
							/>
						}
						label={
							<Localized id="group-data-retention-settings-archived-course-retention-editor-option-keep">
								Archived courses are kept indefinitely
							</Localized>
						}
					/>
				</Grid>

				<Grid item xs={12} container spacing={1}>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Radio
									checked={allowDeletion}
									onChange={() => {
										setAllowDeletion(true);
										setErrorSmallPeriod(false);
									}}
								/>
							}
							label={
								<Localized id="group-data-retention-settings-archived-course-retention-editor-option-delete-in-days">
									Archived courses are deleted after specified number of days
								</Localized>
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						style={{
							gap: theme.spacing(2),
							marginLeft: `calc(1.5rem + ${theme.spacing(1)})`,
						}}
					>
						<Grid item xs={3}>
							<TextField
								id="days"
								type="number"
								fullWidth
								autoFocus
								InputProps={{
									inputProps: {
										min: minimumRetentionPeriod,
										"aria-label": l10n.getString(
											"group-data-retention-settings-label-period"
										),
									},
								}}
								value={period}
								disabled={!allowDeletion}
								helperText={
									<Localized
										id="group-data-retention-settings-hint-minimum-period"
										vars={{period: minimumRetentionPeriod}}
									>
										Minimum 30 days
									</Localized>
								}
								error={errorSmallPeriod}
								onChange={({target}) => {
									const value = parseInt(target.value || "0");
									if (value > 0) {
										setPeriod(value);
										setErrorSmallPeriod(false);
									}
								}}
							/>
						</Grid>
					</Grid>
					{allowDeletion && (
						<Grid item xs={12} style={{marginTop: theme.spacing(3)}}>
							<SettingNotice
								text={
									<Localized id="group-data-retention-settings-archived-course-retention-notice">
										Students and staff are not deleted along with courses.
									</Localized>
								}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
			<EditorActions
				saveDisabled={
					initial.current.allowDeletion === allowDeletion &&
					initial.current.preiod === period
				}
				inProgress={saving}
				mobileMode={props.mobileMode}
				onSave={save}
				onCancel={props.onCancel}
			/>
		</Grid>
	);
}

function SectionTitle(props: {
	text: React.ReactNode;
	editDisabled?: boolean;
	onEdit?: () => void;
}) {
	const theme = useTheme();

	const {l10n} = useLocalization();

	return (
		<Box style={{display: "flex", alignItems: "center", gap: theme.spacing(1)}}>
			<Typography variant="h6">{props.text}</Typography>
			<span style={{visibility: props.onEdit ? "inherit" : "hidden"}}>
				<IconButtonWithTooltip
					tooltipTitle={
						<Localized id="group-data-retention-settings-action-edit">
							Edit
						</Localized>
					}
					aria-label={l10n.getString(
						"group-data-retention-settings-action-edit"
					)}
					disabled={props.editDisabled}
					onClick={props.onEdit}
				>
					<EditIcon />
				</IconButtonWithTooltip>
			</span>
		</Box>
	);
}

function SettingNotice(props: {text: React.ReactNode}) {
	const theme = useTheme();

	return (
		<Box display="flex" alignItems="center" style={{gap: theme.spacing(2)}}>
			<WarningIcon style={{color: theme.palette.warning.main}} />
			<Typography align="justify">{props.text}</Typography>
		</Box>
	);
}

function EditorContainer(props: {
	children: React.ReactNode;
	mobileMode: boolean;
}) {
	const theme = useTheme();

	return (
		<Paper
			style={{
				display: "flex",
				padding: theme.spacing(2, 1, 1, props.mobileMode ? 2 : 3),
				marginTop: theme.spacing(-1),
				marginRight: theme.spacing(props.mobileMode ? -2 : -3),
				marginLeft: theme.spacing(props.mobileMode ? -2 : -3),
			}}
		>
			{props.children}
		</Paper>
	);
}

function EditorActions(props: {
	inProgress: boolean;
	saveDisabled: boolean;
	mobileMode: boolean;
	onSave: () => void;
	onCancel: () => void;
}) {
	const theme = useTheme();

	return (
		<>
			<Grid item xs={12}>
				<Divider
					style={{margin: theme.spacing(2, -1, 0, props.mobileMode ? -2 : -3)}}
				/>
			</Grid>
			<Grid item xs={12} container justifyContent="flex-end" spacing={1}>
				<Grid item>
					<Button color="primary" onClick={props.onCancel}>
						<Localized id="group-data-retention-settings-editor-action-cancel">
							Cancel
						</Localized>
					</Button>
				</Grid>
				<Grid item>
					<SubmitButton
						color="primary"
						variant="text"
						disabled={props.saveDisabled}
						inProgress={props.inProgress}
						onClick={props.onSave}
					>
						<Localized id="group-data-retention-settings-editor-action-save">
							Save
						</Localized>
					</SubmitButton>
				</Grid>
			</Grid>
		</>
	);
}

export default GroupDataRetentionSettings;
