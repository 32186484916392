import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {analyticsService} from "../services/analyticsService";
import {RootState} from "../store";

export const fetchStudyPeriod = createAsyncThunk<
	{
		courseKey: string;
		studyPeriod: {startDate: string; endDate: string};
	},
	{courseId: number; studentId: number},
	{state: RootState}
>(
	"studyPreferences/fetchStudyPeriod",
	async ({courseId, studentId}, thunkApi) => {
		const courseKey = keyProvider.course(courseId);
		const course = thunkApi.getState().courses.byKey[courseKey]?.course;

		if (!course) {
			throw new Error(`Course ${courseId} is not found`);
		}

		let studyPeriod = await analyticsService.getCourseStudyPeriod(
			studentId,
			courseId
		);

		const {startDate, endDate} = course;

		if (!studyPeriod) {
			studyPeriod = {startDate, endDate};
		} else {
			if (
				studyPeriod.startDate < startDate ||
				studyPeriod.startDate > endDate
			) {
				studyPeriod.startDate = startDate;
			}
			if (studyPeriod.endDate < startDate || studyPeriod.endDate > endDate) {
				studyPeriod.endDate = endDate;
			}
		}

		return {courseKey, studyPeriod};
	}
);
