import {axiosInstance as client} from "./axiosInstance";

export type NotificationRule = {
	name: string;
};

export type NotificationRuleDefinition = {
	name: string;
	audience: "students" | "no_one";
	category: "study";
};

type UserNotification = {
	id: string;
	name: string;
	category: string;
	timestamp: string;
};

const notificationsService = {
	getAvailableNotificationRules: async (): Promise<
		NotificationRuleDefinition[]
	> => {
		const {data} = await client.get("/api/notification-rules");
		return data.content;
	},

	getCourseNotificationRules: async (
		courseId: number
	): Promise<NotificationRule[]> => {
		const {data} = await client.get(
			`/api/courses/${courseId}/notification-rules`
		);

		return data.content;
	},

	getUserCourseNotifications: async (
		userId: number,
		courseId: number
	): Promise<UserNotification[]> => {
		const {data} = await client.get(
			`/api/users/${userId}/notifications?category=study&courseId=${courseId}&sort=-timestamp`
		);

		return data.content;
	},

	updateCourseNotificationRules: async (
		courseId: number,
		rules: NotificationRule[]
	): Promise<void> => {
		await client.put(`/api/courses/${courseId}/notification-rules`, rules);
	},
};

export default notificationsService;
