import {useLocalization} from "@fluent/react";
import React, {FormEvent} from "react";
import {Button, CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

type Props = {
	disabled?: boolean;
	inProgress?: boolean;
	onClick?: (event?: FormEvent) => void;
	className?: string;
	children?: React.ReactNode;
	variant?: "contained" | "text" | "outlined";
	autoFocus?: boolean;
	color?: "inherit" | "primary" | "secondary";
};

const useStyles = makeStyles(() => ({
	button: {
		minWidth: "84px",
		whiteSpace: "nowrap",
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		position: "relative",
	},
}));

const SubmitButton = (props: Props): JSX.Element => {
	const classes = useStyles();
	const {l10n} = useLocalization();

	const color = props.color ?? "primary";

	return (
		<div
			className={`${
				props.className
					? `${props.className} ${classes.wrapper}`
					: classes.wrapper
			}`}
		>
			<Button
				className={classes.button}
				variant={props.variant ?? "contained"}
				color={color}
				disabled={props.disabled || props.inProgress}
				onClick={props.onClick}
				autoFocus={props.autoFocus}
			>
				{props.children ??
					l10n.getString("utils-button-submit", null, "Submit")}
			</Button>
			{props.inProgress && (
				<CircularProgress
					color={color}
					size={24}
					className={classes.buttonProgress}
				/>
			)}
		</div>
	);
};

export default SubmitButton;
