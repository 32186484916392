import {Box, Container} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useState} from "react";

import BottomShadowScroll from "./BottomShadowScroll";

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			zIndex: theme.zIndex.appBar,
		},
		content: {
			height: "100%",
			overflow: "auto",
			padding: 0,
		},
	})
);

const ScrollableContainer = (props: {
	header: React.ReactElement;
	children: React.ReactElement;
	footer?: React.ReactNode;
}): JSX.Element => {
	const [content, setContent] = useState<HTMLDivElement>();

	const classes = useStyles();

	const contentRef = useCallback((node) => {
		if (node !== null) {
			setContent(node);
		}
	}, []);

	return (
		<Box display="flex" flexDirection="column" height="100%" overflow="auto">
			<BottomShadowScroll scrollable={content}>
				<Box width="100%" className={classes.title}>
					{props.header}
				</Box>
			</BottomShadowScroll>
			<Container ref={contentRef} className={classes.content}>
				{props.children}
			</Container>
			{props.footer}
		</Box>
	);
};

export default ScrollableContainer;
