import type {InternalAxiosRequestConfig} from "axios";
import {useEffect} from "react";

import useCurrentLocale from "./useCurrentLocale";
import {axiosInstance as client} from "../store/services/axiosInstance";

function usePreferredLanguageInApiRequests(): void {
	const locale = useCurrentLocale();

	useEffect(() => {
		const id = client.interceptors.request.use(addAcceptLanguageHeader(locale));

		return () => client.interceptors.request.eject(id);
	}, [locale]);
}

function addAcceptLanguageHeader(locale: string) {
	return function (request: InternalAxiosRequestConfig) {
		if (request.headers) {
			request.headers["Accept-Language"] = locale;
		}
		return request;
	};
}

export default usePreferredLanguageInApiRequests;
