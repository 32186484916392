import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type OrganisationFeature from "../features/OrganisationFeature";
import {createPage} from "../../helpers/paginatedSearchHelpers";
import type {Page} from "../../helpers/paginatedSearchHelpers";
import UserRole from "../models/UserRole";
import type Organisation from "../organisation/Organisation";

export type GroupOrganisationSearchCriteria = {
	names?: string[];
	query?: string;
};

export type UserGroupOrganisationSearchCriteria = GroupOrganisationSearchCriteria & {
	roles?: UserRole[];
};

export type GroupOrganisationSearchResult = {
	name: string;
	displayName: string;
	countryCode: string;
	city: string;
};

export type UserGroupOrganisationSearchResult = GroupOrganisationSearchResult & {
	roles: UserRole[];
};

export type DataRetentionPolicy = {
	inactiveAccountRetentionPeriod?: number;
	archivedCourseRetentionPeriod?: number;
};

export type DataRetentionPolicyPatch = {
	inactiveAccountRetentionPeriod?: number | null;
	archivedCourseRetentionPeriod?: number | null;
};

export const organisationService = {
	createGroupOrganisation: async (
		groupName: string,
		org: {
			name: string;
			displayName: string;
			countryCode: string;
			city: string;
		}
	): Promise<void> => {
		try {
			await client.post(
				`/api/organisation-groups/${groupName}/organisations`,
				org
			);
		} catch (err) {
			if (httpClientError(err)) {
				if (err.response?.status === 400) {
					throw {code: "validation_failed"};
				}
				if (err.response?.status === 409) {
					throw {code: "name_already_used"};
				}
			}
			throw err;
		}
	},

	getOrganisationFeatures: async (
		orgName: string
	): Promise<OrganisationFeature[]> => {
		const {data} = await client.get<OrganisationFeature[]>(
			`/api/organisations/${orgName}/features`
		);

		return data;
	},

	getOrganisation: async (orgName: string): Promise<Organisation> => {
		try {
			const {data} = await client.get(`/api/organisations/${orgName}`);
			return data;
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 404) {
				throw {code: "not_found"};
			}
			throw error;
		}
	},

	deleteOrganisation: async (organisationName: string): Promise<void> => {
		try {
			await client.delete(`/api/organisations/${organisationName}`);
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 409) {
				throw {code: "conflict"};
			}
			throw error;
		}
	},

	searchGroupOrganisations: async (
		groupName: string,
		criteria: GroupOrganisationSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<GroupOrganisationSearchResult, string>> => {
		const url = `/api/organisation-groups/${groupName}/organisations`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		criteria.names?.forEach((n) => {
			params.append("name", n);
		});

		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	searchGroupOrganisationsOfUser: async (
		groupName: string,
		userId: number,
		criteria: UserGroupOrganisationSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<UserGroupOrganisationSearchResult, string>> => {
		const url = `/api/users/${userId}/organisation-groups/${groupName}/organisations`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		criteria.roles?.forEach((r) => {
			params.append("role", r === UserRole.Administrator ? "admin" : r);
		});

		criteria.names?.forEach((n) => {
			params.append("name", n);
		});

		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	updateOrganisation: async (
		organisationName: string,
		patch: {
			name?: string;
			displayName?: string;
			countryCode?: string;
			city?: string;
		}
	) => {
		const url = `/api/organisations/${organisationName}`;

		try {
			await client.patch(url, patch, {
				headers: {"Content-Type": "application/merge-patch+json"},
			});
		} catch (err) {
			if (httpClientError(err)) {
				if (err.response?.status === 400) {
					throw {code: "validation_failed"};
				}
				if (err.response?.status === 409) {
					throw {code: "name_already_used"};
				}
			}
			throw err;
		}
	},

	grantUseOfCourseTemplates: async (
		organisationName: string,
		templateIds: number[]
	) => {
		const url = `/api/organisations/${organisationName}/course-templates`;

		await client.post(url, templateIds);
	},

	revokeUseOfCourseTemplates: async (
		organisationName: string,
		templateIds: number[]
	) => {
		const url = `/api/organisations/${organisationName}/course-templates`;

		await client.delete(url, {data: templateIds});
	},

	getDataRetentionPolicyOfGroup: async (
		groupName: string
	): Promise<DataRetentionPolicy> => {
		const url = `/api/organisation-groups/${groupName}/data-retention-policy`;

		const {data} = await client.get(url);

		return data;
	},

	updateDataRetentionPolicyOfGroup: async (
		groupName: string,
		patch: DataRetentionPolicyPatch
	): Promise<void> => {
		const url = `/api/organisation-groups/${groupName}/data-retention-policy`;

		await client.patch(url, patch, {
			headers: {"Content-Type": "application/merge-patch+json"},
		});
	},
};
