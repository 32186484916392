import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import {CircularProgress} from "@mui/material";
import type {SxProps, Theme} from "@mui/material";
import React from "react";

import StatusPanelLayout from "./StatusPanelLayout";
import type FetchStatus from "../store/FetchStatus";

function StatusInfo(props: {
	status: FetchStatus;
	labelOnPending: React.ReactNode;
	labelOnSuccess: React.ReactNode;
	labelOnFailure: React.ReactNode;
	sx?: SxProps<Theme>;
}) {
	let icon = <></>;
	let text: React.ReactNode;

	switch (props.status) {
		case "pending":
			icon = (
				<CircularProgress
					color="primary"
					size="1.5rem"
					style={{display: "flex"}}
				/>
			);

			text = props.labelOnPending;

			break;
		case "succeeded":
			icon = <DoneIcon color="success" />;
			text = props.labelOnSuccess;

			break;
		case "failed":
			icon = <WarningIcon color="warning" />;
			text = props.labelOnFailure;

			break;
	}

	return <StatusPanelLayout icon={icon} text={text} sx={props.sx} />;
}

export default StatusInfo;
