import React from "react";
import {Divider, Drawer, Toolbar} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

import useNavBarHeight from "../hooks/useNavBarHeight";

const useStyles = makeStyles<
	Theme,
	{width: string | number; maxWidth?: string | number}
>((theme) =>
	createStyles({
		drawer: {
			width: ({width}) => width,
			maxWidth: ({maxWidth}) => maxWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: ({width}) => width,
			maxWidth: ({maxWidth}) => maxWidth,
		},
		drawerContainer: {
			overflow: "auto",
			height: "100%",
		},
		topMost: {
			zIndex: theme.zIndex.drawer + 3,
		},
	})
);

const AppBarDependentDrawer = (props: {
	anchor: "left" | "right";
	children: React.ReactElement;
	topFixedPanel?: React.ReactElement;
	bottomFixedPanel?: React.ReactElement;
	open: boolean;
	variant: "permanent" | "persistent" | "temporary";
	width: string | number;
	maxWidth?: string | number;
	unmountOnExit?: boolean;
	onClose?: () => void;
}): JSX.Element => {
	const classes = useStyles({width: props.width, maxWidth: props.maxWidth});

	const navBarHeight = useNavBarHeight();

	return (
		<Drawer
			open={props.open}
			className={classes.drawer}
			variant={props.variant}
			classes={{
				paper: classes.drawerPaper,
				root: props.variant === "temporary" ? classes.topMost : undefined,
			}}
			anchor={props.anchor}
			SlideProps={{
				unmountOnExit: props.unmountOnExit,
			}}
			ModalProps={{
				keepMounted: !props.unmountOnExit,
			}}
			onClose={props.onClose}
		>
			{props.variant !== "temporary" && (
				<Toolbar style={{minHeight: navBarHeight}} />
			)}

			{props.topFixedPanel}
			{props.topFixedPanel && <Divider />}

			<div className={classes.drawerContainer}>{props.children}</div>

			{props.bottomFixedPanel && <Divider />}
			{props.bottomFixedPanel}
		</Drawer>
	);
};

export default AppBarDependentDrawer;
