import {createSelector} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {RootState} from "../store";

const selectNumberOfChaptersWithSupportedStructure = createSelector(
	(state: RootState) => state.courseChapters.byKey,
	(state: RootState) => state.chapters.byKey,
	(_: RootState, courseId: number) => keyProvider.course(courseId),
	(
		courseChapters,
		chapters,
		courseKey
	): [supported: number, totalAmountOfChapters: number] => {
		const chapterKeys = courseChapters[courseKey]?.chapters ?? [];

		const supported = chapterKeys.reduce(
			(res, key) =>
				chapters[key].structureType === "sections" ? res + 1 : res,
			0
		);

		return [supported, chapterKeys.length];
	}
);

export default selectNumberOfChaptersWithSupportedStructure;
