import {Localized} from "@fluent/react";
import {Box, Grid, Typography} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		box: {
			height: theme.spacing(12),
			backgroundColor: theme.palette.primary.main,
			"& > *": {
				height: "100%",
			},
		},
		typographymain: {
			color: theme.palette.primary.contrastText,
		},
		typographysecond: {
			color: alpha(theme.palette.primary.contrastText, 0.7),
		},
	})
);

const DashboardHeader = (props: {userDisplayName: string}): JSX.Element => {
	const classes = useStyles();

	return (
		<Box className={classes.box}>
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item>
					<Localized
						id="dashboard-welcome"
						vars={{userName: props.userDisplayName}}
					>
						<Typography variant="h4" className={classes.typographymain}>
							{"Welcome, {$userName}"}
						</Typography>
					</Localized>
				</Grid>
				<Grid item>
					<Typography variant="body1" className={classes.typographysecond}>
						<Localized id="dashboard-good-day-message">
							{"It's a good day to learn something new"}
						</Localized>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DashboardHeader;
