import {useMemo} from "react";
import {contentService} from "../../store/services/contentService";
import type FileLoader from "../../utils/attachments/FileLoader";
import DocumentFormat from "../../utils/attachments/DocumentFormat";
import type UploadedFile from "../../utils/attachments/UploadedFile";

const useAnswerFileLoader = (
	courseId: number,
	messageId?: number,
	tutorId?: number
): FileLoader | undefined => {
	const loader = useMemo(() => {
		return messageId && tutorId
			? {
					async loadAll(): Promise<UploadedFile[]> {
						const files = await contentService.getAll({
							documentFormat: DocumentFormat.TutorAnswer,
							questionId: messageId,
							courseId,
							personId: tutorId,
						});
						return files;
					},
			  }
			: undefined;
	}, [courseId, messageId, tutorId]);

	return loader;
};

export default useAnswerFileLoader;
