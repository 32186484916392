import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {studentResponseService} from "../services/studentResponseService";

export const resetStudentResponse = createAsyncThunk(
	"studentResponses/resetStudentResponse",
	async (params: {
		studentId: number;
		courseId: number;
		chapterId: number;
		exerciseId: number;
	}) => {
		await studentResponseService.resetResponse(
			params.studentId,
			params.courseId,
			params.chapterId,
			params.exerciseId
		);

		const responseKey = keyProvider.chapterExercise(
			params.chapterId,
			params.exerciseId
		);

		return {responseKey};
	}
);
