import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

const empty: string[] = [];

function selectSubsectionKeys(state: RootState, key: string): string[] {
	return state.sections.byKey[key]?.section?.subsections ?? empty;
}

function selectSectionContentStatus(
	state: RootState,
	key: string
): FetchStatus {
	return state.sections.byKey[key]?.contentStatus ?? "none";
}

export {selectSectionContentStatus};
export default selectSubsectionKeys;
