import type CourseStudyModule from "./CourseStudyModule";
import type {RootState} from "../store";

const empty: CourseStudyModule[] = [];

function selectCourseStudyModules(state: RootState, courseKey: string) {
	return state.courseStudyModules.byKey[courseKey]?.modules ?? empty;
}

function selectCourseStudyModulesStatus(state: RootState, courseKey: string) {
	return state.courseStudyModules.byKey[courseKey]?.status ?? "none";
}

export default selectCourseStudyModules;
export {selectCourseStudyModulesStatus};
