import {createSlice} from "@reduxjs/toolkit";
import type ChapterExercise from "../chapterExercises/ChapterExercise";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import type Section from "../sections/Section";
import {fetchChapterSessionContent} from "./fetchSessionContent";
import {fetchSessionSectionContent} from "./fetchSessionSectionContent";
import type Response from "../studentResponses/Response";
import {fetchChapterSessionStudentResponses} from "./fetchChapterSessionStudentResponses";
import {submitChapterSessionStudentResponse} from "./submitChapterSessionStudentResponse";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type SectionState = {
	section?: Section;
	contentStatus: FetchStatus;
};

type State = {
	key: string;
	chapterKey: string;
	sections: {
		[key: string]: SectionState | undefined;
	};
	sessionExercises: {
		[key: string]: ChapterExercise | undefined;
	};
	sectionKeys: string[];
	responses: {[key: string]: Response | undefined};
	status: FetchStatus;
};

const initialState: State = {
	key: "",
	chapterKey: "",
	sections: {},
	sessionExercises: {},
	sectionKeys: [],
	responses: {},
	status: "none",
};

export const lastRequestedSessionSlice = createSlice({
	name: "lastRequestedSession",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchChapterSessionContent.fulfilled, (state, action) => {
			state.key = action.payload.sessionKey;
			state.chapterKey = keyProvider.chapter(action.payload.chapterId);

			const entities = action.payload.entities;
			for (const [key, section] of Object.entries(entities.sections)) {
				const s = obtainSectionState(state, key);

				s.section = section;
			}

			state.sessionExercises = entities.chapterExercises;
			state.sectionKeys =
				entities.chapters[
					keyProvider.chapter(action.payload.chapterId)
				].sections;
			state.status = "succeeded";
		});
		builder.addCase(fetchChapterSessionContent.pending, (_, action) => {
			return {
				...initialState,
				chapterKey: keyProvider.chapter(action.meta.arg.chapterId),
				key: action.meta.arg.sessionKey,
				status: "pending",
			};
		});
		builder.addCase(fetchChapterSessionContent.rejected, (_, action) => {
			return {
				...initialState,
				chapterKey: keyProvider.chapter(action.meta.arg.chapterId),
				key: action.meta.arg.sessionKey,
				status: inferFetchStatusFromError(action),
			};
		});

		builder.addCase(fetchSessionSectionContent.pending, (state, action) => {
			const s = obtainSectionState(state, action.meta.arg.sectionKey);

			s.contentStatus = "pending";
		});
		builder.addCase(fetchSessionSectionContent.rejected, (state, action) => {
			const s = obtainSectionState(state, action.meta.arg.sectionKey);

			s.contentStatus = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchSessionSectionContent.fulfilled, (state, action) => {
			const s = obtainSectionState(state, action.payload.sectionKey);

			s.contentStatus = "succeeded";
		});

		builder.addCase(
			fetchChapterSessionStudentResponses.fulfilled,
			(state, action) => {
				state.responses = action.payload.responses;
			}
		);

		builder.addCase(
			submitChapterSessionStudentResponse.fulfilled,
			(state, {payload}) => {
				state.responses[payload.responseKey] = payload.response;
			}
		);
	},
});

function obtainSectionState(slice: State, key: string) {
	let s = slice.sections[key];
	if (!s) {
		s = {
			contentStatus: "none",
		};
		slice.sections[key] = s;
	}

	return s;
}

export default lastRequestedSessionSlice.reducer;
