export enum TeacherLeftTabName {
	MyCourses = "dashboard-teacher-my-courses",
	MyExams = "dashboard-teacher-my-exams",
	PastCourses = "dashboard-teacher-past-courses",
}

export enum StudentLeftTabName {
	MyCourses = "dashboard-student-my-courses",
	MyExams = "dashboard-student-my-exams",
	PastCourses = "dashboard-student-past-courses",
	CoursesToEnrol = "dashboard-student-courses-to-enrol",
}

export type LeftTabName = TeacherLeftTabName | StudentLeftTabName;
