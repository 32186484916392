import {Localized} from "@fluent/react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import {ReactNode} from "react";
import React, {useCallback, useState} from "react";

import SubmitButton from "../utils/SubmitButton";

type ConfirmationDialogProps = {
	title: ReactNode;
	description: ReactNode;
	confirmBtnText: ReactNode;
	cancelBtnText?: ReactNode;
	onConfirm: () => Promise<void>;
	onCancel?: () => void;
};

export type OpenConfirmationDialog = (
	dialogProps: ConfirmationDialogProps
) => void;

const useConfirmationDialog = (): [
	JSX.Element,
	OpenConfirmationDialog,
	() => void
] => {
	const [open, setOpen] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	const [dialogProps, setDialogProps] = useState<ConfirmationDialogProps>(
		() => ({
			confirmBtnText: "",
			description: "",
			onConfirm: async () => {
				return;
			},
			title: "",
		})
	);

	const submit = async () => {
		setInProgress(true);
		try {
			await dialogProps.onConfirm();
		} finally {
			setOpen(false);
			setInProgress(false);
		}
	};

	const cancel = () => {
		if (inProgress) {
			return;
		}

		setOpen(false);
		dialogProps.onCancel && dialogProps.onCancel();
	};

	const dialog = (
		<Dialog
			open={open}
			aria-labelledby="confirm-dialog-title"
			aria-describedby="confirm-dialog-description"
			maxWidth="xs"
			fullWidth
			onClose={cancel}
		>
			<DialogTitle id="confirm-dialog-title">{dialogProps.title}</DialogTitle>

			<DialogContent id="confirm-dialog-description">
				<DialogContentText component="div">
					{dialogProps.description}
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button onClick={cancel} color="primary" disabled={inProgress}>
					{dialogProps.cancelBtnText ?? (
						<Localized id="confirmation-dialog-dissmiss-action-default-name">
							Cancel
						</Localized>
					)}
				</Button>

				<SubmitButton
					onClick={submit}
					autoFocus
					variant="text"
					inProgress={inProgress}
				>
					{dialogProps.confirmBtnText}
				</SubmitButton>
			</DialogActions>
		</Dialog>
	);

	const openDialog = useCallback((props: ConfirmationDialogProps) => {
		setDialogProps({
			...props,
			description: props.description,
		});
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setOpen(false);
	}, []);

	return [dialog, openDialog, closeDialog];
};

export default useConfirmationDialog;
