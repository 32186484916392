import {Localized, useLocalization} from "@fluent/react";
import {
	Grid2 as Grid,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

import ContentEditorFooter from "./ContentEditorFooter";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import ExerciseEditorProps from "./ExerciseEditorProps";
import {equalArrays} from "../../helpers/arrayHelpers";
import PrivacyLevelSelector from "./PrivacyLevelSelector";
import type {RulesEditorApi} from "./ShortTextAssessmentRulesEditor";
import ShortTextAssessmentRulesEditor from "./ShortTextAssessmentRulesEditor";
import {OpenExercisePatch} from "../../store/exercises/ExercisePatch";
import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import {OpenExerciseSubtype} from "../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import type {OpenExercise} from "../../store/services/dtos/EditableExercise";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useExerciseEditorTagSelector from "./useExerciseEditorTagSelector";
import useExerciseFileUploader from "./useExerciseFileUploader";
import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";

const OpenExerciseEditor = (props: ExerciseEditorProps): JSX.Element => {
	const {courseId, exercise, exerciseId} = props;

	const userId = useAppSelector(selectUserId);

	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();

	const [draft, setDraft] = useState(() => ({
		id: exerciseId ?? 0,
		difficultyLevel: 0,
		privacy: ExercisePrivacy.PublicToOrganisation,
		title: l10n.getString(
			"learning-material-open-exercise-editor-exercise-default-title"
		),
		maxScore: 1,
		category: "",
		tags: [] as string[],
	}));

	const questionEditor = useRef<TextEditorApi | null>(null);
	const solutionEditor = useRef<TextEditorApi | null>(null);

	const initialQuestion = useRef("");
	const initialSolution = useRef("");

	const [dirty, setDirty] = useState(false);

	const [emptyFields, setEmptyFields] = useState<{
		title?: boolean;
	}>({});

	const updated = useRef(false);

	const TagSelector = useExerciseEditorTagSelector(
		props.organisationName,
		userId
	);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft.id
	);

	if (exercise && exercise.type !== ExerciseType.Open) {
		throw new Error("Unexpected exercise type");
	}

	const savedExercise = useRef<OpenExercise | null>(exercise);

	const rulesEditor = useRef<RulesEditorApi>(null);
	const rulesChanged = useRef(false);

	useEffect(() => {
		if (!exercise) {
			return;
		}

		initialQuestion.current = exercise.question;
		initialSolution.current = exercise.solution;

		setDraft({
			id: exerciseId ?? 0,
			category: exercise.category,
			difficultyLevel: exercise.difficultyLevel,
			maxScore: exercise.maxScore,
			privacy: exercise.privacyLevel,
			title: exercise.title,
			tags: exercise.tags,
		});
	}, [exercise, exerciseId]);

	useEffect(() => props.onUnmount, [props.onUnmount]);

	const handleTitleUpdate = (title: string) => {
		setDraft((prev) => ({...prev, title}));
		setDirty(true);
	};

	const handleScoreUpdate = (maxScore: number) => {
		setDraft((prev) => ({...prev, maxScore}));
		setDirty(true);
	};

	const handlePrivacyLevelUpdate = (privacy: ExercisePrivacy) => {
		setDraft((prev) => ({...prev, privacy}));
		setDirty(true);
	};

	const handleDifficultyUpdate = (difficultyLevel: number) => {
		setDraft((prev) => ({
			...prev,
			difficultyLevel: difficultyLevel as number,
		}));

		setDirty(true);
	};

	const handleCategoryUpdate = (category: string) => {
		setDraft((prev) => ({...prev, category}));
		setDirty(true);
	};

	const handleTagsUpdate = (tags: string[]) => {
		setDraft((prev) => ({...prev, tags}));
		setDirty(true);
	};

	const save = async () => {
		const error = validate();
		if (error !== "") {
			showSnackbar("error", error);
			return false;
		}

		let ex: OpenExercise;

		try {
			if (!savedExercise.current) {
				ex = await create();
			} else {
				ex = await update(draft.id, savedExercise.current);
			}
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		updated.current = true;

		savedExercise.current = ex;

		questionEditor.current?.setContent(ex.question);
		questionEditor.current?.markAsSaved();

		solutionEditor.current?.setContent(ex.solution);
		solutionEditor.current?.markAsSaved();

		setDirty(false);
		rulesChanged.current = false;

		return true;
	};

	const create = async () => {
		const rules = rulesEditor.current?.getRules() ?? [];

		const {id, exercise} = await props.onCreate({
			type: ExerciseType.Open,
			originId: courseId,
			title: draft.title,
			category: draft.category,
			question: questionEditor.current?.getContent() ?? "",
			solution: solutionEditor.current?.getContent() ?? "",
			maxScore: draft.maxScore,
			privacyLevel: draft.privacy,
			difficultyLevel: draft.difficultyLevel,
			language: props.defaultLanguage,
			tags: draft.tags,
			settings:
				rules.length > 0
					? {
							subtype: OpenExerciseSubtype.AutoAssessed,
							finalAnswer: {rules: rules},
					  }
					: {subtype: OpenExerciseSubtype.Ordinary},
		});

		setDraft((prev) => ({...prev, id: id}));

		return exercise as OpenExercise;
	};

	const update = async (exerciseId: number, prev: OpenExercise) => {
		const patch: OpenExercisePatch = {};

		if (prev.title !== draft.title) {
			patch.title = draft.title;
		}

		const question = questionEditor.current?.getContent() ?? "";
		if (prev.question !== question) {
			patch.question = question;
		}

		const solution = solutionEditor.current?.getContent();
		if (prev.solution !== solution) {
			patch.solution = solution === "" ? null : solution;
		}

		if (prev.maxScore !== draft.maxScore) {
			patch.maxScore = draft.maxScore;
		}

		if (prev.difficultyLevel !== draft.difficultyLevel) {
			patch.difficultyLevel = draft.difficultyLevel;
		}

		if (prev.privacyLevel !== draft.privacy) {
			patch.privacyLevel = draft.privacy;
		}

		if (prev.category !== draft.category) {
			patch.category = draft.category || null;
		}

		if (!equalArrays(prev.tags, draft.tags)) {
			patch.tags = draft.tags;
		}

		if (rulesChanged.current) {
			const rules = rulesEditor.current?.getRules() ?? [];

			if (rules.length > 0) {
				patch.settings = {
					subtype: OpenExerciseSubtype.AutoAssessed,
					finalAnswer: {
						rules: rules,
					},
				};
			} else if (prev.settings.subtype !== OpenExerciseSubtype.Ordinary) {
				patch.settings = {
					subtype: OpenExerciseSubtype.Ordinary,
					finalAnswer: null,
				};
			}
		}

		const ex = await props.onUpdate(exerciseId, patch);

		return ex as OpenExercise;
	};

	function validate() {
		const empty: typeof emptyFields = {
			title: draft.title === "",
		};

		if (empty.title) {
			setEmptyFields(empty);

			return l10n.getString(
				"exercise-editor-error-required-fields",
				null,
				"Some required fields are empty"
			);
		}

		const rulesError = rulesEditor.current?.validate({allowNoRules: true});
		if (rulesError) {
			return rulesError;
		}

		return "";
	}

	const complete = async () => {
		const saved = await save();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	};

	return (
		<>
			<Grid container spacing={4}>
				<Grid size={12}>
					<TextField
						id="exercise-title"
						required
						autoFocus
						fullWidth
						value={draft.title}
						error={emptyFields.title}
						label={
							<Localized id="exercise-editor-title-label">
								Exercise title
							</Localized>
						}
						onChange={(e) => handleTitleUpdate(e.target.value)}
					/>
				</Grid>

				<Grid size={12}>
					<Grid container alignItems="center" spacing={4}>
						<Grid size={12}>
							<TextField
								id="score-number"
								label={
									<Localized id="exercise-editor-score-label">Score</Localized>
								}
								type="number"
								value={draft.maxScore}
								slotProps={{htmlInput: {min: 0}}}
								onChange={({target}) => {
									const s = parseInt(target.value);
									if (s >= 0) {
										handleScoreUpdate(s);
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid size={12}>
					<InputLabel shrink sx={{mb: 1}}>
						<Localized id="exercise-editor-description-label">
							Description
						</Localized>
					</InputLabel>
					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						ref={questionEditor}
						fileUploader={fileUploader ?? undefined}
						files={files}
						onDirty={() => setDirty(true)}
					/>
				</Grid>

				<Grid size={12}>
					<InputLabel shrink sx={{mb: 1}}>
						<Localized id="exercise-editor-solution-label">
							Example solution
						</Localized>
					</InputLabel>
					<TextEditor
						initialValue={initialSolution.current}
						fileUploader={fileUploader ?? undefined}
						ref={solutionEditor}
						onDirty={() => setDirty(true)}
					/>
				</Grid>

				<Grid size={12}>
					<Stack spacing={1}>
						<Typography variant="h6">
							<Localized id="learning-material-open-exercise-editor-label-assessment-rules">
								Assessment rules
							</Localized>
						</Typography>
						<ShortTextAssessmentRulesEditor
							rules={
								exercise && "finalAnswer" in exercise.settings
									? exercise.settings.finalAnswer.rules
									: []
							}
							notice={
								<Localized id="learning-material-open-exercise-editor-rules-notice">
									You can specify assessment rules for the final answer.
								</Localized>
							}
							editor={rulesEditor}
							maxScore={draft.maxScore}
							onDirty={() => {
								setDirty(true);
								rulesChanged.current = true;
							}}
						/>
					</Stack>
				</Grid>

				<Grid size={12} container spacing={3} alignItems="flex-end">
					<Grid size={{xs: 12, md: 6}}>
						<PrivacyLevelSelector
							onChange={handlePrivacyLevelUpdate}
							value={draft.privacy}
						/>
					</Grid>
					<Grid size={{xs: 12, md: 6}}>
						<ExerciseDifficultySelector
							name="difficulty"
							value={draft.difficultyLevel}
							onChange={(val) => handleDifficultyUpdate(val ?? 0)}
						/>
					</Grid>
				</Grid>

				<Grid size={12}>
					<TextField
						value={draft.category}
						id="caterogy"
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						onChange={(e) => handleCategoryUpdate(e.target.value)}
					/>
				</Grid>

				{TagSelector && (
					<Grid size={12}>
						<TagSelector
							freeSolo
							value={draft.tags}
							onChange={handleTagsUpdate}
						/>
					</Grid>
				)}
			</Grid>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={complete}
				onSave={save}
				disabled={!dirty}
			/>
		</>
	);
};

export default OpenExerciseEditor;
