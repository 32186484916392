import React, {useEffect, useRef, useState} from "react";

import useExerciseFileUploader from "./useExerciseFileUploader";
import {editingEnded} from "../../store/exercises/exericseDraftSlice";
import {saveTheoryDraft} from "../../store/exercises/saveTheoryDraft";
import selectExerciseDraft from "../../store/exercises/selectExerciseDraft";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import ContentEditorFooter from "./ContentEditorFooter";

type Props = {
	exercise: {
		title: string;
		content: string | undefined;
	};
	onClose: (updated: boolean) => void;

	courseId: number;
	chapterId: number;
	sectionId: number;
};

const SectionDescriptionEditor = (props: Props): JSX.Element => {
	const {exercise, courseId, chapterId, sectionId, onClose} = props;

	const userId = useAppSelector(selectUserId);

	const draft = useAppSelector(selectExerciseDraft);

	const dispatch = useAppDispatch();
	const [editingStarted, setEditingStarted] = useState(false);

	const description = useRef<TextEditorApi | null>(null);
	const initialDescription = useRef(exercise.content ?? "");

	const updated = useRef(false);

	useEffect(() => {
		return () => {
			dispatch(editingEnded());
		};
	}, [dispatch]);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft?.id ?? 0
	);

	const handleCancel = () => {
		onClose(updated.current);
	};

	const handleDraftSave = async () => {
		await dispatch(
			saveTheoryDraft({
				courseId,
				chapterId,
				sectionId,
				exerciseItem: {
					title: exercise.title,
					content: description.current?.getContent() ?? "",
				},
			})
		);

		updated.current = true;
	};

	const handleDraftDone = async () => {
		await handleDraftSave();
		onClose(updated.current);
	};

	return (
		<>
			<TextEditorWithAttachments
				onChange={() => setEditingStarted(true)}
				fileUploader={fileUploader ?? undefined}
				files={files}
				ref={description}
				initialValue={initialDescription.current}
			/>

			<ContentEditorFooter
				onCancel={handleCancel}
				onCompeleted={handleDraftDone}
				onSave={handleDraftSave}
				disabled={!editingStarted}
			/>
		</>
	);
};

export default SectionDescriptionEditor;
