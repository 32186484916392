import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectCourseChaptersFetchStatus(
	state: RootState,
	courseKey: string
): FetchStatus {
	return state.courseChapters.byKey[courseKey]?.status ?? "none";
}

export default selectCourseChaptersFetchStatus;
