import type CourseManagementModule from "./CourseManagementModule";
import type {RootState} from "../store";

const empty: CourseManagementModule[] = [];

function selectCourseManagementModules(state: RootState, courseKey: string) {
	return state.courseManagementModules.byKey[courseKey]?.modules ?? empty;
}

function selectCourseManagementModulesStatus(
	state: RootState,
	courseKey: string
) {
	return state.courseManagementModules.byKey[courseKey]?.status ?? "none";
}

export default selectCourseManagementModules;
export {selectCourseManagementModulesStatus};
