import React from "react";
import {Box, Typography} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

import IndexedLabel from "../../utils/IndexedLabel";

const useStyles = makeStyles<Theme, {disabled?: boolean; active?: boolean}>(
	(theme) =>
		createStyles({
			index: {
				color: ({disabled, active}) => {
					if (disabled) {
						return theme.palette.text.disabled;
					}
					return active
						? theme.palette.secondary.main
						: theme.palette.text.secondary;
				},
				marginRight: theme.spacing(2),
			},
			label: {
				color: ({disabled, active}) => {
					if (disabled) {
						return theme.palette.text.disabled;
					}
					return active
						? theme.palette.secondary.main
						: theme.palette.text.primary;
				},
			},
			countLabel: {
				visibility: ({disabled}) => (disabled ? "hidden" : "inherit"),
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(0.75),
			},
		})
);

const ChapterLabel = (props: {
	index: string;
	label: string;
	disabled?: boolean;
	active?: boolean;
	statusLabel?: React.ReactElement;
	itemCount?: number;
}): JSX.Element => {
	const classes = useStyles({disabled: props.disabled, active: props.active});

	return (
		<Box display="flex" alignItems="flex-start" justifyContent="space-between">
			<IndexedLabel
				index={props.index}
				text={props.label}
				classes={{
					index: classes.index,
					label: classes.label,
				}}
				variant="body1"
			/>
			<Box display="flex" mt={0.25}>
				{(props.itemCount ?? 0) > 0 && (
					<Typography variant="body2" className={classes.countLabel}>
						{props.itemCount}
					</Typography>
				)}
				{props.statusLabel}
			</Box>
		</Box>
	);
};

export default ChapterLabel;
