export enum MultiExerciseSubtype {
	Multiple = "multiple",
	Single = "single",
	Text = "text",
	AllOrNothing = "all-or-nothing",
}

export enum OpenExerciseSubtype {
	AutoAssessed = "auto-assessed",
	Ordinary = "ordinary",
}

export enum MathExerciseSubtype {
	Numerical = "Numerical",
	Polynomial = "Polynomial",
	Equation = "Equation",
	Differentiation = "Differentiation",
	Integration = "Integration",
}

type ExerciseSubtype =
	| MultiExerciseSubtype
	| OpenExerciseSubtype
	| MathExerciseSubtype;

export default ExerciseSubtype;
