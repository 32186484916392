import {Localized} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	DialogTitle,
	IconButton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import type {Theme} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, {useContext} from "react";

import CourseFramedPage, {TeacherCoursePage} from "../CourseFramedPage";
import AssessmentPageDialogContext from "./AssessmentPageDialogContext";

const ResponseAssessmentDialog = (props: {courseId: number}): JSX.Element => {
	const {courseId} = props;

	const {pageParams, closeDialog} = useContext(AssessmentPageDialogContext);

	const smallScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);

	return (
		<Dialog
			open={Boolean(pageParams)}
			keepMounted
			maxWidth="lg"
			fullWidth
			fullScreen={smallScreen}
			onClose={closeDialog}
		>
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6" component="span">
						<Localized id="follow-up-response-assessment-dialog-title">
							Student response assessment
						</Localized>
					</Typography>
					<IconButton onClick={closeDialog}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<CourseFramedPage
				courseId={courseId}
				coursePage={
					pageParams
						? TeacherCoursePage.FollowUpExercise
						: TeacherCoursePage.BulletinBoard
				}
				params={{
					studentId: pageParams?.studentId.toString(),
					chapterId: pageParams?.chapterId.toString(),
					exerciseId: pageParams?.exerciseId.toString(),
					exerciseType: pageParams?.exerciseType,
				}}
			/>
		</Dialog>
	);
};

export default ResponseAssessmentDialog;
