import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type User from "../../store/services/dtos/User";
import {userService} from "../../store/services/userService";
import updateUserProfile from "../../store/users/updateUserProfile";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import ProfileEditor from "./ProfileEditor";
import useSnackbar from "../../store/ui/useSnackbar";
import ChangePasswordDialog from "./ChangePasswordDialog";
import type DialogResult from "../../utils/DialogResult";
import useMobileMode from "../../hooks/useMobileMode";
import SlideUp from "../../utils/SlideUp";
import DeleteAccountDialog from "./DeleteAccountDialog";
import ChangeEmailDialog from "./ChangeEmailDialog";

const Profile = (): JSX.Element => {
	const dispatch = useAppDispatch();

	const userId = useAppSelector(selectUserId);

	const [user, setUser] = useState<User | null>(null);

	const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
	const [noPasswordWarningOpen, setNoPasswordWarningOpen] = useState(false);
	const [emailChangeOpen, setEmailChangeOpen] = useState(false);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	const showSnackbar = useSnackbar();
	const mobileMode = useMobileMode("sm");
	const {l10n} = useLocalization();

	useEffect(() => {
		if (userId) {
			userService.getUserProfile(userId).then((res) => {
				setUser(res);
			});
		}
	}, [userId]);

	if (!user) {
		return <></>;
	}

	function closePasswordChangeDialog(result: DialogResult) {
		setPasswordChangeOpen(false);
		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}
	}

	function closeEmailChangeDialog(result: DialogResult, email?: string) {
		setEmailChangeOpen(false);
		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}
		if (result.status === "success") {
			setUser((prev) => (prev ? {...prev, email} : null));
		}
	}

	function closeDeleteDialog(result: DialogResult) {
		setConfirmDeleteOpen(false);
		if (result.status === "error") {
			showSnackbar("error", result.message ?? "");
		}
	}

	async function saveChanges(updated: {
		language: string;
		firstName: string;
		lastName: string;
		phoneNumber?: string;
	}) {
		try {
			const res = await dispatch(updateUserProfile({userId, profile: updated}));
			unwrapResult(res);
			showSnackbar("success", l10n.getString("success-on-saving"));
			return true;
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}
	}

	const changeEmailTitleId = "change-user-email";
	const changePasswordTitleId = "change-password";
	const confirmDeleteTitleId = "delete-account";
	const noPasswordWarningTitleId = "no-password-warning";

	return (
		<>
			<ProfileEditor
				user={user}
				onChange={saveChanges}
				onDeleteAccount={() => setConfirmDeleteOpen(true)}
				onPasswordChangeClick={
					user.passwordSet || user.email
						? () => {
								if (!user.passwordSet) {
									setNoPasswordWarningOpen(true);
								} else {
									setPasswordChangeOpen(true);
								}
						  }
						: undefined
				}
				onEmailChangeClick={
					user.passwordSet ? () => setEmailChangeOpen(true) : undefined
				}
			/>

			<Dialog
				open={passwordChangeOpen}
				maxWidth="xs"
				fullScreen={mobileMode}
				fullWidth
				onClose={() => setPasswordChangeOpen(false)}
				aria-labelledby={changePasswordTitleId}
				TransitionComponent={mobileMode ? SlideUp : undefined}
			>
				<ChangePasswordDialog
					userId={userId}
					titleElementId={changePasswordTitleId}
					onClose={closePasswordChangeDialog}
					mobileMode={mobileMode}
				/>
			</Dialog>

			<Dialog
				open={emailChangeOpen}
				maxWidth="xs"
				fullScreen={mobileMode}
				fullWidth
				onClose={() => setEmailChangeOpen(false)}
				aria-labelledby={changeEmailTitleId}
				TransitionComponent={mobileMode ? SlideUp : undefined}
			>
				<ChangeEmailDialog
					titleElementId={changeEmailTitleId}
					userId={userId}
					initialEmail={user.email ?? ""}
					mobileMode={mobileMode}
					onClose={closeEmailChangeDialog}
				/>
			</Dialog>

			<Dialog
				open={confirmDeleteOpen}
				maxWidth="sm"
				fullScreen={mobileMode}
				fullWidth
				onClose={() => setConfirmDeleteOpen(false)}
				aria-labelledby={confirmDeleteTitleId}
				TransitionComponent={mobileMode ? SlideUp : undefined}
			>
				<DeleteAccountDialog
					user={user}
					mobileMode={mobileMode}
					titleElementId={confirmDeleteTitleId}
					onClose={closeDeleteDialog}
				/>
			</Dialog>

			<Dialog
				open={noPasswordWarningOpen}
				onClose={() => setNoPasswordWarningOpen(false)}
				aria-labelledby={noPasswordWarningTitleId}
				maxWidth="xs"
			>
				<DialogTitle id={noPasswordWarningTitleId}>
					<Localized id="account-no-password-warning-title">
						Account has no password
					</Localized>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Localized id="account-no-password-warning-descr">
							You can go to the login page and use Forgot password? link to set
							a password.
						</Localized>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setNoPasswordWarningOpen(false)}
						color="primary"
					>
						<Localized id="account-no-password-warning-ok-btn">OK</Localized>
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Profile;
