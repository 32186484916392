import React from "react";
import {FormControl, MenuItem, Select} from "@mui/material";
import TeX from "@matejmazur/react-katex";
import "katex/dist/katex.min.css";
import AsciiMathParser from "../../../../utils/asciiMathParser";

type Props = {
	operationLabels: string[];
	selectedLabel: string;
	onSelect: (label: string) => void;
	parser: AsciiMathParser;
	disabled?: boolean;
};

const OperationSelector = (props: Props): JSX.Element => {
	return (
		<FormControl fullWidth disabled={props.disabled}>
			<Select
				value={props.selectedLabel}
				onChange={(event) => props.onSelect(event.target.value)}
			>
				{props.operationLabels.map((label) => {
					return (
						<MenuItem value={label} key={label}>
							<TeX>{props.parser.parse(label)}</TeX>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default React.memo(OperationSelector);
