import {Paper, Stack, Typography} from "@mui/material";
import type {SxProps, Theme} from "@mui/material";
import React from "react";

function StatusPanelLayout(props: {
	icon: React.ReactElement;
	text: React.ReactNode;
	sx?: SxProps<Theme>;
}) {
	return (
		<Paper sx={[{p: 2}, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}>
			<Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
				{props.icon}
				<Typography align="justify">{props.text}</Typography>
			</Stack>
		</Paper>
	);
}

export default StatusPanelLayout;
