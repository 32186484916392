import {Localized} from "@fluent/react";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import type {StudentCardProps} from "./StudentCardDashboard";

const AvailableForEnrolmentCourseCard = (
	props: StudentCardProps
): JSX.Element => {
	const formatDate = useDateFormat();

	return (
		<Card>
			<CardMedia component="img" alt="" height="136" image={props.image} />
			<CardContent>
				<Typography
					variant="subtitle1"
					component="h2"
					gutterBottom
					style={{fontWeight: 500}}
				>
					{props.title}
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{`${formatDate(parseDate(props.startDate))} - ${formatDate(
						parseDate(props.endDate)
					)}`}
				</Typography>
			</CardContent>
			<CardActions>
				<Button
					component={Link}
					to={`/enrolment-application?courseId=${props.id}`}
					color="primary"
				>
					<Localized id="dashboard-enrol-in-course-btn">
						Enrol in the course
					</Localized>
				</Button>
			</CardActions>
		</Card>
	);
};

export default AvailableForEnrolmentCourseCard;
