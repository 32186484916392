import {createSelector} from "@reduxjs/toolkit";

import type Organisation from "./Organisation";
import type {RootState} from "../store";
import type FetchStatus from "../FetchStatus";

const selectUserOrganisations = createSelector(
	(state: RootState) => state.organisations.byKey,
	(state: RootState) => state.userOrganisations.organisations,
	(orgs, userOrgs) => {
		const res: Organisation[] = [];

		userOrgs.forEach((orgName) => {
			const org = orgs[orgName]?.organisation;
			if (org) {
				res.push(org);
			}
		});

		return res;
	}
);

const selectSortedUserOrganisations = createSelector(
	selectUserOrganisations,
	(_: RootState, locale: string) => locale,
	(orgs, locale) => {
		const collator = new Intl.Collator(locale);
		return orgs
			.slice()
			.sort((a, b) => collator.compare(a.displayName, b.displayName));
	}
);

function selectUserOrganisationsFetchStatus(state: RootState): FetchStatus {
	return state.userOrganisations.status;
}

export default selectUserOrganisations;
export {selectSortedUserOrganisations, selectUserOrganisationsFetchStatus};
