import {useRouteMatch} from "react-router-dom";
import {resolvePath} from "../helpers/pathHelpers";

const useSubrouteCreator = (): ((
	relativePath: string
) => {
	link: string;
	route: string;
}) => {
	const {url: baseUrl, path: basePath} = useRouteMatch();

	const createSubroute = (relativePath: string) => {
		return {
			link: resolvePath(baseUrl, relativePath),
			route: resolvePath(basePath, relativePath),
		};
	};

	return createSubroute;
};

export default useSubrouteCreator;
