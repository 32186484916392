import {createAsyncThunk} from "@reduxjs/toolkit";

import type {EditableExercise} from "../services/dtos/EditableExercise";
import exerciseService from "../services/exerciseService";

const createExercise = createAsyncThunk(
	"exercise/createExercise",
	async (params: {courseId: number; exercise: EditableExercise}) => {
		const [id, exercise] = await exerciseService.createExercise2({
			...params.exercise,
			originId: params.courseId,
		});

		return {id: id, exercise: exercise};
	}
);

export default createExercise;
