import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	ClickAwayListener,
	IconButton,
	InputAdornment,
	InputBase,
	Paper,
	Tab,
	Tabs,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {
	createRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";

import EditorCanvas from "./EditorCanvas";
import type {EditorCanvasApi} from "./EditorCanvas";
import {getLanguage} from "./SupportedLanguages";
import type ProgFile from "../../store/studentResponses/ProgFile";
import createSerialIdProvider from "../../helpers/createSerialIdProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		tabRoot: {
			textTransform: "none",
		},
		addFileBtn: {
			marginLeft: theme.spacing(1),
		},
	})
);

function TabPanel(props: {
	children?: React.ReactNode;
	index: number;
	value: number;
}) {
	const {children, value, index} = props;

	return <div hidden={value !== index}>{children}</div>;
}

type CodeFile = {
	content: string;
	name: string;
	readOnlyFromBeginning?: number;
	readOnlyFromEnd?: number;
};

export type CodeEditorApi = {
	getFiles: () => CodeFile[];
};

const id = createSerialIdProvider();

const CodeEditor = (
	props: {
		readonly?: boolean;
		highlightings?: {
			[file: string]: {
				ranges: {from: number; to?: number}[];
				color: string;
			}[];
		};
		initialFiles?: CodeFile[];
		defaultExtension?: string;
		fileManagement?: boolean;
		onChange?: () => void;
	},
	ref: React.ForwardedRef<CodeEditorApi>
): JSX.Element => {
	const {
		readonly,
		highlightings,
		initialFiles,
		onChange,
		fileManagement,
		defaultExtension,
	} = props;

	const classes = useStyles();

	const [selectedFileTab, setSelectedFileTab] = useState(0);

	const [codeFiles, setCodeFiles] = useState<(CodeFile & {id: string})[]>(
		() => []
	);

	const [canvasRefs, setCanvasRefs] = useState<{
		[key: string]: React.RefObject<EditorCanvasApi>;
	}>({});

	const cachedContent = useRef<ProgFile[] | null>(null);

	const addedFilesCount = useRef(1);

	const changeFiles = useCallback(() => {
		cachedContent.current = null;
		onChange && onChange();
	}, [onChange]);

	const changeReadOnlyRange = useCallback(
		(fileId, readOnlyRange) => {
			setCodeFiles((prev) => {
				const copy = [...prev];
				const i = copy.findIndex((f) => f.id === fileId);

				copy[i] = {
					...prev[i],
					readOnlyFromBeginning: readOnlyRange?.readOnlyFromBeginning,
					readOnlyFromEnd: readOnlyRange?.readOnlyFromEnd,
				};

				return copy;
			});

			onChange && onChange();
		},
		[onChange]
	);

	useEffect(() => {
		setCodeFiles(initialFiles?.map((f) => ({...f, id: id()})) ?? []);
	}, [initialFiles]);

	useEffect(() => {
		setCanvasRefs((prev) => {
			const refs: {[key: string]: React.RefObject<EditorCanvasApi>} = {};
			codeFiles.forEach((file) => {
				refs[file.id] = prev[file.id] ?? createRef<EditorCanvasApi>();
			});
			return refs;
		});
		cachedContent.current = null;
	}, [codeFiles]);

	useImperativeHandle(ref, () => ({
		getFiles() {
			if (cachedContent.current) {
				return cachedContent.current;
			}

			const content = Object.entries(canvasRefs).map(([key, ref]) => {
				const file = codeFiles.find((f) => f.id === key);
				return {
					name: file?.name ?? "",
					content: ref.current?.getContent() ?? "",
					readOnlyFromBeginning: file?.readOnlyFromBeginning,
					readOnlyFromEnd: file?.readOnlyFromEnd,
				};
			});

			cachedContent.current = content;

			return content;
		},
	}));

	function addFile() {
		setCodeFiles((prev) => [
			...prev,
			{
				name: `file_${addedFilesCount.current}${defaultExtension}`,
				content: "",
				id: id(),
			},
		]);

		setTimeout(() => setSelectedFileTab(codeFiles.length), 10);

		onChange && onChange();

		addedFilesCount.current += 1;
	}

	function deleteFile(id: string) {
		setSelectedFileTab((prev) => {
			const index = codeFiles.findIndex((f) => f.id === id);
			if (index === selectedFileTab) {
				return 0;
			}
			if (selectedFileTab === codeFiles.length - 1) {
				return prev - 1;
			}
			return prev;
		});

		setCodeFiles((prev) => prev.filter((f) => f.id !== id));

		onChange && onChange();
	}

	function changeTitle(id: string, newName: string) {
		if (codeFiles.some((f) => f.name === newName)) {
			return;
		}

		setCodeFiles((prev) => {
			const copy = [...prev];
			const i = copy.findIndex((f) => f.id === id);

			copy[i] = {
				...prev[i],
				name: newName,
				content: canvasRefs[prev[i].id].current?.getContent() ?? "",
			};

			return copy;
		});

		cachedContent.current = null;
		onChange && onChange();
	}

	return (
		<Paper>
			<Box display="flex" pt={0.5} pr={1}>
				<Tabs
					value={selectedFileTab}
					onChange={(_, index) => setSelectedFileTab(index)}
					variant="scrollable"
				>
					{codeFiles.map((file, i) => (
						<Tab
							key={file.id}
							label={
								fileManagement && i === selectedFileTab ? (
									<FileTitle
										text={file.name}
										onChange={(value) => changeTitle(file.id, value)}
										onDelete={() => deleteFile(file.id)}
										deleteDisabled={codeFiles.length === 1}
									/>
								) : (
									file.name
								)
							}
							classes={{root: classes.tabRoot}}
							disableRipple
							component="div"
						/>
					))}
				</Tabs>
				{fileManagement && (
					<IconButton onClick={addFile} className={classes.addFileBtn}>
						<AddIcon />
					</IconButton>
				)}
			</Box>
			{codeFiles.map((file, i) => {
				return (
					<TabPanel key={file.id} index={i} value={selectedFileTab}>
						<EditorCanvas
							value={file.content}
							onChange={changeFiles}
							lang={getLang(file.name)}
							id={file.id}
							readonly={readonly}
							highlightings={highlightings?.[file.name]}
							ref={canvasRefs[file.id]}
							readOnlyFromBeginning={file.readOnlyFromBeginning}
							readOnlyFromEnd={file.readOnlyFromEnd}
							onReadOnlyRangeChange={
								fileManagement ? changeReadOnlyRange : undefined
							}
						/>
					</TabPanel>
				);
			})}
		</Paper>
	);
};

function FileTitle(props: {
	text: string;
	editDisabled?: boolean;
	deleteDisabled?: boolean;
	onChange: (value: string) => void;
	onDelete: () => void;
}) {
	const [editing, setEdit] = useState(false);
	const [title, setTitle] = useState(props.text);

	function saveChange() {
		setEdit(false);
		props.onChange(title);
	}

	function cancel() {
		setEdit(false);
		setTitle(props.text);
	}

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			width="100%"
			alignItems="center"
		>
			{editing ? (
				<ClickAwayListener
					onClickAway={cancel}
					disableReactTree
					mouseEvent="onMouseDown"
				>
					<InputBase
						value={title}
						onChange={({target}) => {
							setTitle(target.value);
						}}
						onKeyUp={(event) => {
							if (event.key === "Enter") {
								saveChange();
							} else if (event.key === "Escape") {
								cancel();
							}
						}}
						autoFocus
						endAdornment={
							<InputAdornment position="end">
								<IconButton size="small" onClick={cancel}>
									<ClearIcon fontSize="inherit" />
								</IconButton>
								<IconButton size="small" onClick={saveChange}>
									<CheckIcon fontSize="inherit" />
								</IconButton>
							</InputAdornment>
						}
					/>
				</ClickAwayListener>
			) : (
				<>
					<Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
						{props.text}
					</Box>

					<Box display="flex" ml={1}>
						<IconButton
							size="small"
							disabled={props.editDisabled}
							onClick={(event) => {
								event.stopPropagation();
								setEdit(true);
							}}
						>
							<EditIcon fontSize="inherit" />
						</IconButton>
						<IconButton
							size="small"
							disabled={props.deleteDisabled}
							onClick={(event) => {
								event.stopPropagation();
								props.onDelete();
							}}
						>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
					</Box>
				</>
			)}
		</Box>
	);
}

function getLang(filename: string) {
	const splitted = filename.split(".");
	return getLanguage(splitted[splitted.length - 1]);
}

export default React.forwardRef(CodeEditor);
