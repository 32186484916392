import {Localized} from "@fluent/react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import {Typography} from "@mui/material";
import React from "react";

import MessageList from "./MessageList";
import MessageListLoadingErrorState from "./MessageListLoadingErrorState";
import {useAppSelector} from "../../store/hooks";
import selectAnsweredForUser from "../../store/tutorQuestions/selectAnsweredForUser";
import selectMessagesFetchStatus from "../../store/tutorQuestions/selectMessagesFetchStatus";
import selectSentByUser from "../../store/tutorQuestions/selectSentByUser";
import TutorQuestion from "../../store/tutorQuestions/TutorQuestion";
import StudentMessagesTab from "./StudentMessagesTab";

const StudentMessageList = (props: {
	courseId: number;
	studentId: number;
	selectedTab: StudentMessagesTab;
	selectedMessageKey: string | null;
	onNavClick?: () => void;
	onReload: () => void;
}): JSX.Element => {
	const messages = useAppSelector((state) =>
		selectSentByUser(state, props.studentId)
	);
	const answers = useAppSelector((state) =>
		selectAnsweredForUser(state, props.studentId)
	);
	const status = useAppSelector(selectMessagesFetchStatus);

	return (
		<MessageList
			header={
				props.selectedTab === StudentMessagesTab.Answered ? (
					<Localized id="tutoring-student-message-list-answered">
						Answered
					</Localized>
				) : (
					<Localized id="tutoring-student-message-list-sent">Sent</Localized>
				)
			}
			messageKeys={
				props.selectedTab === StudentMessagesTab.Answered ? answers : messages
			}
			selectedMessage={props.selectedMessageKey}
			messageInfoSelector={
				props.selectedTab === StudentMessagesTab.Answered
					? answerInfoSelector
					: sentInfoSelector
			}
			errorState={
				status === "failed" && (
					<MessageListLoadingErrorState onReload={props.onReload} />
				)
			}
			onNavClick={props.onNavClick}
		/>
	);
};

function answerInfoSelector(message: TutorQuestion) {
	return {
		avatar: (
			<Typography>{message.tutorName?.charAt(0).toUpperCase()}</Typography>
		),
		name: message.tutorName ?? "unknown",
		date: message.answerTime ?? "",
	};
}

function sentInfoSelector(message: TutorQuestion) {
	let name, avatar;
	if (message.answerTime) {
		name = message.tutorName ?? "unknown";
		avatar = (
			<Typography>{message.tutorName?.charAt(0).toUpperCase()}</Typography>
		);
	} else {
		name = (
			<Localized id="tutoring-message-default-course-tutors">
				Course tutors
			</Localized>
		);
		avatar = <ContactSupportIcon />;
	}

	return {
		avatar,
		name,
		date: message.sendTime,
		answered: Boolean(message.answerTime),
	};
}

export default StudentMessageList;
