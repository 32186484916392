import {Localized, useLocalization} from "@fluent/react";
import {
	Checkbox,
	Grid2 as Grid,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import React, {useCallback, useRef, useState} from "react";

import CourseTypeSelector from "./CourseTypeSelector";
import useDateFormat from "../../i18n/useDateFormat";
import parseDate from "../../helpers/parseDate";
import OrganisationSelector from "../organisations/OrganisationSelector";
import {courseService} from "../../store/services/courseService";
import type {OrganisationGroupCourseSearchCriteria} from "../../store/services/courseService";
import type OrganisationGroupCourseSearchResult from "../../store/services/dtos/OrganisationGroupCourseSearchResult";
import type {PaginatedAutocompleteApi} from "../../utils/autocomplete/PaginatedAutocomplete";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";
import LightTooltip from "../../utils/LightTooltip";
import SearchAndSelectionDialog from "../../utils/SearchAndSelectionDialog";
import SearchToolbar from "../../utils/SearchToolbar";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";

const headCells: HeadCell<OrganisationGroupCourseSearchResult>[] = [
	{
		id: "organisationName",
		label: "group-courses-selector-dialog-table-column-organisation",
		width: 160,
		sortable: false,
	},
	{
		id: "name",
		label: "group-courses-selector-dialog-table-column-name",
		sortable: true,
	},
	{
		id: "startDate",
		label: "group-courses-selector-dialog-table-column-start-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "type",
		label: "group-courses-selector-dialog-table-column-type",
		sortable: false,
		width: 100,
	},
];

const initialCriteria: OrganisationGroupCourseSearchCriteria = {};

function GroupCoursesSelectorDialog(props: {
	groupName: string;
	actionLabel: NonNullable<React.ReactNode>;
	multiple?: boolean;
	onSelected: (ids: number[]) => void;
	onCancel: () => void;
}) {
	const [criteria, setCriteria] = useState(initialCriteria);

	const formatDate = useDateFormat();

	const fetchPage = useCallback(
		(sort, pageSize) =>
			courseService.searchCoursesInOrganisationGroup(
				props.groupName,
				criteria,
				sort,
				pageSize
			),
		[criteria, props.groupName]
	);

	return (
		<SearchAndSelectionDialog
			toolbar={
				<Toolbar
					criteria={criteria}
					groupName={props.groupName}
					onCriteriaChange={setCriteria}
				/>
			}
			multiple={props.multiple}
			actionLabel={props.actionLabel}
			headCells={headCells}
			fetch={fetchPage}
			rowRenderer={(c, selected, select) => {
				const labelId = `table-checkbox-${c.id}`;

				return (
					<TableRow hover key={c.id} tabIndex={-1} selected={selected}>
						<TableCell padding="checkbox">
							<Checkbox
								checked={selected}
								inputProps={{"aria-labelledby": labelId}}
								onClick={select}
							/>
						</TableCell>
						<TableCell style={{width: 160}}>
							<LightTooltip
								title={<Typography>{c.organisationName}</Typography>}
								enterDelay={500}
								enterNextDelay={500}
							>
								<div
									style={{
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										overflow: "hidden",
										width: "inherit",
									}}
								>
									{c.organisationName}
								</div>
							</LightTooltip>
						</TableCell>
						<TableCell component="th" id={labelId} scope="row">
							{c.name}
						</TableCell>
						<TableCell align="right">
							{formatDate(parseDate(c.startDate))}
						</TableCell>
						<TableCell>
							<Localized id="group-courses-type" vars={{type: c.type}}>
								{c.type}
							</Localized>
						</TableCell>
					</TableRow>
				);
			}}
			noSearchResultsRenderer={() => (
				<NoSearchResultsState
					columnNumber={headCells.length + 1}
					title={
						<Localized id="group-courses-no-courses">No courses</Localized>
					}
					description={
						criteria === initialCriteria ? (
							<Localized id="group-courses-no-courses-descr">
								There are no courses in the organisation group
							</Localized>
						) : (
							<Localized id="group-courses-no-results-descr">
								No courses were found matching your search criteria. Try to
								adjust filters
							</Localized>
						)
					}
				/>
			)}
			generalErrorRenderer={(onReload) => (
				<LoadingErrorState
					description={
						<Localized id="group-courses-error-descr">
							Something has gone wrong, and we cannot load courses
						</Localized>
					}
					columnNumber={headCells.length + 1}
					onReload={onReload}
				/>
			)}
			onSelected={props.onSelected}
			onCancel={props.onCancel}
		/>
	);
}

function Toolbar(props: {
	groupName: string;
	criteria: OrganisationGroupCourseSearchCriteria;
	onCriteriaChange: (
		value:
			| OrganisationGroupCourseSearchCriteria
			| ((
					prevState: OrganisationGroupCourseSearchCriteria
			  ) => OrganisationGroupCourseSearchCriteria)
	) => void;
}) {
	const {criteria, onCriteriaChange} = props;

	const {l10n} = useLocalization();

	const orgSelector = useRef<PaginatedAutocompleteApi>(null);

	return (
		<>
			<SearchToolbar
				criteria={criteria}
				searchPlaceholder={l10n.getString(
					"group-courses-selector-dialog-search-placeholder"
				)}
				onCriteriaChange={onCriteriaChange}
				onClear={orgSelector.current?.resetSelection}
			>
				<Grid container spacing={4}>
					<Grid size={12}>
						<OrganisationSelector
							ref={orgSelector}
							groupName={props.groupName}
							label={
								<Localized id="group-courses-selector-dialog-filters-organisations">
									Organisations
								</Localized>
							}
							onChange={(organisationNames) =>
								onCriteriaChange((prev) => ({...prev, organisationNames}))
							}
						/>
					</Grid>
					<Grid size={4}>
						<CourseTypeSelector
							value={criteria.type}
							onChange={(type) => onCriteriaChange((prev) => ({...prev, type}))}
						/>
					</Grid>
					<Grid size={4}>
						<ContentLanguageSelector
							value={criteria.language ?? ""}
							withAny
							onChange={(language) =>
								onCriteriaChange((prev) => ({...prev, language}))
							}
						/>
					</Grid>
				</Grid>
			</SearchToolbar>
		</>
	);
}

export default GroupCoursesSelectorDialog;
