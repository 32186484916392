import {Box, Button, ClickAwayListener, TextField} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {
	ChangeEvent,
	KeyboardEvent,
	useMemo,
	useRef,
	useState,
} from "react";

import SymbolsPanel from "./SymbolsPanel";
import RadicalSign from "../../../../images/RadicalSign.svg";
import SubmitButton from "../../../../utils/SubmitButton";
import AsciiMathParser from "../../../../utils/asciiMathParser";

const useStyles = makeStyles((theme) =>
	createStyles({
		symbolsPanelButton: {
			backgroundColor: theme.palette.action.active,
			"&:hover": {
				backgroundColor: theme.palette.action.focus,
			},
			width: "24px",
			minWidth: "24px",
			maxHeight: "24px",
		},
		confirmBtn: {
			marginLeft: theme.spacing(2),
		},
	})
);

type Props = {
	value: string;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	label?: React.ReactNode;
	placeholder?: string;
	helperText?: React.ReactNode;
	confirmBtnText?: string;
	confirmInProgress?: boolean;
	onInputChange: (input: string) => void;
	onInputFinish?: () => void;
};

const FormulaInput = (props: Props): JSX.Element => {
	const classes = useStyles();

	const inputRef = useRef<HTMLTextAreaElement>(null);

	const [symbolPanelOpen, setSymbolPanelOpen] = useState(false);

	const parser = useMemo(() => {
		return new AsciiMathParser();
	}, []);

	const inputChangeHandler = (event: ChangeEvent<{value: string}>) => {
		props.onInputChange(event.target.value);
	};

	const openPanelClickHandler = () => {
		setSymbolPanelOpen((prev) => !prev);
	};

	const symbolClickHandler = (asciiSymbol: string) => {
		if (inputRef.current) {
			const input = inputRef.current;

			const newInput = [
				props.value.slice(0, input.selectionStart),
				asciiSymbol,
				props.value.slice(input.selectionEnd),
			].join("");

			props.onInputChange(newInput);
			inputRef.current.focus();
		}
	};

	const inputKeyUpHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			finishInput();
		}
	};

	const addButtonClick = () => {
		finishInput();
	};

	function finishInput() {
		props.onInputFinish && props.onInputFinish();
		inputRef.current?.focus();
	}

	return (
		<Box display="flex" alignItems="flex-end" flexGrow={1}>
			<ClickAwayListener onClickAway={() => setSymbolPanelOpen(false)}>
				<Box width="100%">
					<TextField
						fullWidth
						inputRef={inputRef}
						label={props.label}
						placeholder={props.placeholder}
						onChange={inputChangeHandler}
						onKeyUp={inputKeyUpHandler}
						value={props.value}
						disabled={props.disabled}
						error={props.error}
						helperText={props.helperText}
						required={props.required}
						InputProps={{
							endAdornment: (
								<Button
									onClick={openPanelClickHandler}
									className={classes.symbolsPanelButton}
									disabled={props.disabled}
								>
									<img alt="Symbols panel" src={RadicalSign} />
								</Button>
							),
						}}
					/>
					<SymbolsPanel
						open={symbolPanelOpen}
						parser={parser}
						onSymbolClick={symbolClickHandler}
						anchorRef={inputRef.current}
					/>
				</Box>
			</ClickAwayListener>
			{props.confirmBtnText && (
				<SubmitButton
					onClick={addButtonClick}
					disabled={!props.value}
					className={classes.confirmBtn}
					variant="text"
					inProgress={props.confirmInProgress}
				>
					{props.confirmBtnText}
				</SubmitButton>
			)}
		</Box>
	);
};

export default FormulaInput;
