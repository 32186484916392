import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../store";
import selectAnswered from "./selectAnswered";

const selectAnsweredForUser = createSelector(
	(state: RootState) => state.tutorQuestions.byKey,
	selectAnswered,
	(_: RootState, userId: number) => userId,
	(questions, answeredKeys, userId) => {
		return answeredKeys.filter((key) => questions[key]?.studentId === userId);
	}
);

export default selectAnsweredForUser;
