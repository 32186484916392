import {Box, Divider, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";

import AttachmentsList from "../../../utils/attachments/AttachmentsList";
import UploadedFile from "../../../utils/attachments/UploadedFile";
import useAssessorFileLoader from "./useAssessorFileLoader";

const AssessorComment = (props: {
	comment: string;
	studentId: number;
	courseId: number;
	chapterId: number;
	exerciseId: number;
}): JSX.Element => {
	const fileLoader = useAssessorFileLoader(
		props.courseId,
		props.chapterId,
		props.exerciseId,
		props.studentId
	);

	const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

	useEffect(() => {
		fileLoader.loadAll().then((files) => {
			setUploadedFiles(files);
		});
	}, [fileLoader]);

	return (
		<Box display="flex" flexDirection="column" width="100%" pt={2}>
			<Typography>{props.comment}</Typography>
			{uploadedFiles && uploadedFiles.length > 0 && (
				<>
					<Box mt={3} mb={2}>
						<Divider />
					</Box>
					<Typography component="span" variant="caption" color="textSecondary">
						Attachments
					</Typography>
					<Box mt={1}>
						<AttachmentsList items={uploadedFiles} />
					</Box>
				</>
			)}
		</Box>
	);
};

export default AssessorComment;
