import {createAsyncThunk} from "@reduxjs/toolkit";

import type TutorQuestion from "./TutorQuestion";
import selectChapterExercise from "../chapterExercises/selectChapterExercise";
import selectChapter from "../chapters/selectChapter";
import {keyProvider} from "../keyProvider";
import type {
	ExerciseQuestion,
	GeneralQuestion,
} from "../services/tutoringService";
import {tutoringService} from "../services/tutoringService";
import type {RootState} from "../store";

export const sendNewQuestion = createAsyncThunk<
	{questionKey: string; question: TutorQuestion},
	{
		studentId: number;
		courseId: number;
		question: string;
		exerciseKey: string | null;
		attachmentIds?: number[];
	},
	{
		state: RootState;
	}
>("tutoring/sendNewQuestion", async (params, thunkApi) => {
	let questionBody: ExerciseQuestion | GeneralQuestion = {
		text: params.question,
	};

	let exercise, chapter;
	if (params.exerciseKey) {
		const state = thunkApi.getState();

		exercise = selectChapterExercise(state, params.exerciseKey);
		chapter = selectChapter(state, keyProvider.chapter(exercise.chapterId));

		questionBody = {
			text: params.question,
			chapterId: exercise.chapterId,
			exerciseId: exercise.id,
			exerciseType: exercise.type,
		};
	}

	const questionId = await tutoringService.sendQuestion(
		params.courseId,
		params.studentId,
		questionBody,
		params.attachmentIds
	);

	const questionKey = keyProvider.tutorQuestion(questionId);

	const question: TutorQuestion = {
		id: questionId,
		question: params.question,
		sendTime: new Date().toISOString(),
		studentId: params.studentId,
		chapterId: chapter?.id,
		exerciseId: exercise?.id,
	};

	return {questionKey, question};
});
