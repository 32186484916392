const localeKey = "locale";

function createLocaleCache(): {
	getLocale: () => string | null;
	setLocale: (locale: string) => void;
} {
	return {
		getLocale() {
			return localStorage.getItem(localeKey);
		},
		setLocale(locale) {
			localStorage.setItem(localeKey, locale);
		},
	};
}

export default createLocaleCache;
