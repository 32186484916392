import {useEffect} from "react";

import type FetchStatus from "../FetchStatus";
import {useAppDispatch, useAppSelector} from "../hooks";
import fetchOrganisation from "./fetchOrganisation";
import type Organisation from "./Organisation";
import selectOrganisation, {
	selectOrganisationFetchStatus,
} from "./selectOrganisation";

function useFetchOrganisation(
	organisationName: string
): [Organisation | null, FetchStatus] {
	const organisation = useAppSelector((state) =>
		selectOrganisation(state, organisationName)
	);
	const orgFetchStatus = useAppSelector((state) =>
		selectOrganisationFetchStatus(state, organisationName)
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (organisationName !== "" && orgFetchStatus === "none") {
			dispatch(fetchOrganisation({organisationName}));
		}
	}, [dispatch, orgFetchStatus, organisationName]);

	return [organisation, orgFetchStatus];
}

export default useFetchOrganisation;
