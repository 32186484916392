import React, {useContext, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import LabelProps from "./LabelProps";
import CompletionStatusIcon from "./CompletionStatusIcon";
import AssessmentDeliveryContext from "../AssessmentDeliveryContext";
import ExerciseStatusHint from "../content/exercises/ExerciseStatusHint";

const ExerciseNavItem = (props: {
	exerciseKey: string;
	index: string;
	sectionPath: string;

	renderLabel: (params: LabelProps, exerciseKey: string) => React.ReactNode;
}): JSX.Element => {
	const {makeSelectChapterExercise, selectOutcomes} = useContext(
		AssessmentDeliveryContext
	);

	const selectChapterExercise = useMemo(makeSelectChapterExercise, [
		makeSelectChapterExercise,
	]);

	const exercise = useAppSelector((state) =>
		selectChapterExercise(state, props.exerciseKey)
	);
	const outcomes = useAppSelector((state) =>
		selectOutcomes(state, props.exerciseKey)
	);

	const location = useLocation();

	if (!exercise) {
		return <></>;
	}

	const hashLink = `#subsection-${exercise.id}`;

	return (
		<>
			{props.renderLabel(
				{
					link: props.sectionPath + hashLink,
					index: props.index,
					text: exercise.title,
					active: location.hash === hashLink,
					metaIcon: (
						<ExerciseStatusHint
							outcomes={outcomes}
							maxScore={exercise.maxScore}
						>
							<span>
								<CompletionStatusIcon status={outcomes?.completionStatus} />
							</span>
						</ExerciseStatusHint>
					),
				},
				props.exerciseKey
			)}
		</>
	);
};

export default ExerciseNavItem;
