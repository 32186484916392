import parseDate from "../../helpers/parseDate";
import type UserSession from "./UserSession";

type SerialisedSession = {
	end: number;
	inact: number;
};

const sessionKey = "session";

function cleanUp() {
	localStorage.removeItem("loggedIn");
}

cleanUp();

function createSessionStore(): {
	saveSession(s: UserSession): void;
	updateEndTime(time: string): void;
	getSession(): UserSession | null;
	removeSession(): void;
	addUpdateListener(onUpdate: (s: UserSession | null) => void): () => void;
} {
	let inactivityTimeout = 0;

	return {
		saveSession(s: UserSession) {
			inactivityTimeout = s.inactivityTimeout;
			localStorage.setItem(
				sessionKey,
				JSON.stringify({
					end: parseDate(s.endTime).getTime(),
					inact: s.inactivityTimeout,
				})
			);
		},

		updateEndTime(endTime) {
			localStorage.setItem(
				sessionKey,
				JSON.stringify({
					end: parseDate(endTime).getTime(),
					inact: inactivityTimeout,
				})
			);
		},

		getSession() {
			const str = localStorage.getItem(sessionKey);
			if (!str) {
				return null;
			}

			const parsed = parse(str);
			if (!parsed) {
				return null;
			}

			inactivityTimeout = parsed.inact;

			return {
				endTime: new Date(parsed.end).toISOString(),
				inactivityTimeout: parsed.inact,
			};
		},

		removeSession() {
			localStorage.removeItem(sessionKey);
		},

		addUpdateListener(onUpdate) {
			const listener = (ev: StorageEvent) => {
				if (ev.key !== sessionKey) {
					return;
				}

				if (!ev.newValue) {
					onUpdate(null);
					return;
				}

				const parsed = parse(ev.newValue);
				if (!parsed) {
					onUpdate(null);
					return;
				}

				onUpdate({
					endTime: new Date(parsed.end).toISOString(),
					inactivityTimeout: parsed.inact,
				});

				inactivityTimeout = parsed.inact;
			};

			window.addEventListener("storage", listener);

			return () => {
				window.removeEventListener("storage", listener);
			};
		},
	};
}

function parse(str: string): SerialisedSession | null {
	try {
		const parsed = JSON.parse(str);
		if (!("end" in parsed) || !("inact" in parsed)) {
			return null;
		}
		return parsed;
	} catch (err) {
		return null;
	}
}

export default createSessionStore();
