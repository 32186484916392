import {Paper, Slide} from "@mui/material";
import React from "react";

function MessageSlidebar(props: {
	children: React.ReactNode;
	in: boolean;
}): JSX.Element {
	if (!props.in) {
		return <></>;
	}

	return (
		<Slide direction="left" in={props.in}>
			<Paper style={{height: "100%", width: "100%"}} variant="outlined">
				{props.children}
			</Paper>
		</Slide>
	);
}

export default MessageSlidebar;
