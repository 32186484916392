import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	TextField,
} from "@mui/material";
import React, {useState} from "react";

import exerciseService from "../../store/services/exerciseService";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import ProgressButton from "../../utils/ProgressButton";
import SlideUp from "../../utils/SlideUp";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function useCopyAction(
	mobileMode: boolean,
	courseId: number,
	onSuccess: (id: number) => void,
	onError: () => void
): [(exerciseId: number, title: string) => void, JSX.Element] {
	const [exerciseId, setExerciseId] = useState(0);
	const [title, setTitle] = useState("");

	function openDialog(exerciseId: number, title: string) {
		setExerciseId(exerciseId);
		setTitle(title);
	}

	function close() {
		setExerciseId(0);
		setTitle("");
	}

	async function copy(title: string) {
		try {
			const copyExerciseId = await exerciseService.copyExercise(
				courseId,
				exerciseId,
				title
			);

			onSuccess(copyExerciseId);

			close();
		} catch {
			onError();
		}
	}

	const titleId = "copy-exercise-dialog";

	return [
		openDialog,
		<Dialog
			key={titleId}
			open={Boolean(exerciseId)}
			aria-labelledby={titleId}
			maxWidth="xs"
			fullWidth
			fullScreen={mobileMode}
			TransitionComponent={mobileMode ? SlideUp : undefined}
			onClose={close}
		>
			<CopyDialog
				titleElementId={titleId}
				exerciseTitle={title}
				mobileMode={mobileMode}
				onCancel={close}
				onCopy={copy}
			/>
		</Dialog>,
	];
}

function CopyDialog(props: {
	titleElementId: string;
	exerciseTitle: string;
	mobileMode?: boolean;
	onCopy: (title: string) => Promise<void>;
	onCancel: () => void;
}) {
	const {l10n} = useLocalization();

	const [title, setTitle] = useState(() =>
		l10n.getString(
			"exercise-bank-confirm-copy-dialog-default-title",
			{title: props.exerciseTitle.trim()},
			`${props.exerciseTitle} (copy)`
		)
	);

	const [empty, setEmpty] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	async function submit() {
		if (title === "") {
			setEmpty(true);
			return;
		}

		setInProgress(true);

		await props.onCopy(title);

		setInProgress(false);
	}

	return (
		<form
			noValidate
			onSubmit={(e) => {
				e.preventDefault();
				submit();
			}}
		>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="exercise-bank-confirm-copy-dialog-title">
							Copy exercise?
						</Localized>
					}
					actionInProgress={inProgress}
					actionButtonLabel={
						<Localized id="exercise-bank-confirm-copy-dialog-action-copy">
							Copy
						</Localized>
					}
					onAction={submit}
					onClose={props.onCancel}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="exercise-bank-confirm-copy-dialog-title">
							Copy exercise?
						</Localized>
					}
					inProgress={inProgress}
					onClose={props.onCancel}
				/>
			)}

			<DialogContent>
				<DialogContentText>
					<Localized
						id="exercise-bank-confirm-copy-dialog-description"
						vars={{title: props.exerciseTitle}}
					>
						You are going to copy exercise
					</Localized>
				</DialogContentText>

				<TextField
					required
					fullWidth
					autoFocus
					value={title}
					label={
						<Localized id="exercise-bank-confirm-copy-dialog-label-new-title">
							New title
						</Localized>
					}
					error={empty}
					onChange={({target}) => {
						setTitle(target.value);
						setEmpty(false);
					}}
				/>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button
						onClick={props.onCancel}
						color="primary"
						disabled={inProgress}
					>
						<Localized id="exercise-bank-confirm-copy-dialog-action-cancel">
							Cancel
						</Localized>
					</Button>
					<ProgressButton
						onClick={submit}
						inProgress={inProgress}
						type="submit"
					>
						<Localized id="exercise-bank-confirm-copy-dialog-action-copy">
							Copy
						</Localized>
					</ProgressButton>
				</DialogActions>
			)}
		</form>
	);
}

export default useCopyAction;
