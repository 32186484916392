import {createAsyncThunk} from "@reduxjs/toolkit";
import type DashboardName from "./DashboardName";
import type WidgetKey from "./WidgetKey";
import widgetService from "../services/widgetService";
import {keyProvider} from "../keyProvider";

const insertWidget = createAsyncThunk(
	"widgets/insert",
	async (params: {
		dashboardName: DashboardName;
		userId: number;
		courseId: number;
		widgetName: WidgetKey;
		place?: {before: WidgetKey};
	}) => {
		const {courseId, dashboardName, widgetName, place, userId} = params;

		await widgetService.insertWidget(
			userId,
			courseId,
			dashboardName,
			widgetName,
			place
		);

		return {
			dashboardName,
			courseKey: keyProvider.course(courseId),
			widgetName,
			place,
		};
	}
);

export default insertWidget;
