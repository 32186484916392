import {Localized, useLocalization} from "@fluent/react";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	Container,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Stack,
	Switch,
	Tooltip,
	Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import {getExerciseTypeDescription} from "../exerciseBank/exerciseTypeDescriptions";
import selectCourseProgress from "../../store/courseProgress/selectCourseProgress";
import ExerciseType from "../../store/exercises/ExerciseType";
import type FetchStatus from "../../store/FetchStatus";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {analyticsService} from "../../store/services/analyticsService";
import type {ExerciseReminder} from "../../store/services/analyticsService";
import useSnackbar from "../../store/ui/useSnackbar";
import LoadingError from "../../utils/errors/LoadingError";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";

function ExerciseRemindersWidget(props: {
	id: string;
	title: React.ReactNode;
	studentId: number;
	courseId: number;
}) {
	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody style={{marginLeft: 0, marginRight: 0}}>
				<ExerciseReminders
					courseId={props.courseId}
					studentId={props.studentId}
				/>
			</WidgetBody>
		</Widget>
	);
}

function createFakeReminders(): ExerciseReminder[] {
	return Array.from({length: 3}, () => ({
		chapterId: 0,
		exerciseId: 0,
		exerciseNumber: "",
		exerciseTitle: "",
		exerciseType: ExerciseType.External,
		sectionId: 0,
	}));
}

function ExerciseReminders(props: {studentId: number; courseId: number}) {
	const {studentId, courseId} = props;

	const [reminders, setReminders] = useState<ExerciseReminder[]>(() => []);

	const [showAll, setShowAll] = useState(false);

	const [status, setStatus] = useState<FetchStatus>("none");

	const progress = useAppSelector((state) =>
		selectCourseProgress(state, keyProvider.course(courseId))
	);

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	async function fetchReminders(
		studentId: number,
		courseId: number,
		showAll: boolean
	) {
		setStatus("pending");

		try {
			const res = await analyticsService.getExerciseReminders(
				studentId,
				courseId,
				{includeHidden: showAll}
			);

			if (showAll) {
				res.sort((r1, r2) => (r1.hidden ? 1 : 0) - (r2.hidden ? 1 : 0));
			}

			setReminders(res);

			setStatus("succeeded");
		} catch {
			setReminders([]);
			setStatus("failed");
		}
	}

	useEffect(() => {
		fetchReminders(studentId, courseId, showAll);
	}, [courseId, showAll, studentId]);

	async function hideReminder(chapterId: number, exerciseId: number) {
		try {
			await analyticsService.hideExerciseReminder(
				studentId,
				courseId,
				chapterId,
				exerciseId
			);

			if (showAll) {
				setReminders((prev) => {
					const ind = prev.findIndex(
						(r) => r.chapterId === chapterId && r.exerciseId === exerciseId
					);

					const upd = [...prev];
					upd[ind] = {...upd[ind], hidden: true};

					return upd;
				});
			} else {
				setReminders((prev) =>
					prev.filter(
						(r) => r.chapterId !== chapterId || r.exerciseId !== exerciseId
					)
				);
			}
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	const fetching = status === "pending" || status === "none";

	const exerciseReminders =
		fetching || reminders.length === 0 ? createFakeReminders() : reminders;

	return (
		<Stack sx={{width: 1}}>
			<Stack
				direction={{md: "row", xs: "column-reverse"}}
				gap={1}
				sx={{
					justifyContent: "space-between",
					alignItems: {md: "center"},
					px: 3,
				}}
			>
				<Typography
					variant="subtitle1"
					component="p"
					sx={[
						(fetching || reminders.length === 0) && {
							visibility: {xs: "collapse", md: "hidden"},
						},
					]}
				>
					{showAll ? (
						<Localized id="my-corner-exercise-reminders-widget-content-desc-history">
							You had challenges in the following exercises:
						</Localized>
					) : (
						<Localized id="my-corner-exercise-reminders-widget-content-desc-current">
							Last time you had challenges in the following exercises:
						</Localized>
					)}
				</Typography>
				<FormControlLabel
					disabled={fetching}
					control={
						<Switch
							color="primary"
							checked={showAll}
							onChange={(_, checked) => setShowAll(checked)}
						/>
					}
					label={
						<Localized id="my-corner-exercise-reminders-widget-action-show-history">
							Show full history
						</Localized>
					}
					labelPlacement="end"
					sx={{justifyContent: "flex-start"}}
				/>
			</Stack>
			<List
				sx={{
					position: "relative",
					paddingBottom: 0,
					height: (theme) => `calc(1.5rem + ${theme.spacing(3)}`,
				}}
			>
				{reminders.length === 0 &&
					(status === "succeeded" || status === "failed") && (
						<ListItem
							sx={{
								position: "absolute",
								inset: 0,
								zIndex: 1,
								bgcolor: (theme) => theme.palette.background.paper,
							}}
						>
							{status === "succeeded" && <EmptyState fullHistory={showAll} />}
							{status === "failed" && (
								<ErrorState
									onReload={() => fetchReminders(studentId, courseId, showAll)}
								/>
							)}
						</ListItem>
					)}

				{exerciseReminders.map((r) =>
					r.exerciseId === 0 ? (
						<ReminderPlaceholder key={Math.random()} />
					) : (
						<ReminderRow
							key={r.exerciseId}
							courseId={courseId}
							reminder={r}
							completed={Boolean(
								progress.find((p) => p.exerciseId === r.exerciseId)
									?.submissionTime
							)}
							onHide={() => hideReminder(r.chapterId, r.exerciseId)}
						/>
					)
				)}
			</List>
		</Stack>
	);
}

function ReminderRow(props: {
	courseId: number;
	reminder: ExerciseReminder;
	completed?: boolean;
	onHide: () => void;
}) {
	const {courseId, reminder: r, completed} = props;

	const {l10n} = useLocalization();

	const type = getExerciseTypeDescription(r.exerciseType);

	return (
		<ListItem
			key={r.exerciseId}
			sx={(theme) => ({
				px: theme.spacing(3),
				"&:hover": {
					bgcolor: theme.palette.action.hover,
				},
			})}
			disableGutters
		>
			<ListItemIcon>
				<Tooltip
					title={
						completed ? (
							<Localized id="my-corner-exercise-reminders-widget-tooltip-completed">
								Completed
							</Localized>
						) : (
							type.displayName
						)
					}
					enterDelay={500}
					enterNextDelay={500}
				>
					{completed ? (
						<CheckCircleIcon
							color="success"
							aria-label={l10n.getString(
								"my-corner-exercise-reminders-widget-tooltip-completed"
							)}
							aria-hidden={false}
						/>
					) : (
						type.icon
					)}
				</Tooltip>
			</ListItemIcon>
			<ListItemText secondary={type.displayName}>
				<Link
					to={
						`/courses/${courseId}/chapters/${r.chapterId}` +
						`/sections/${r.sectionId}#subsection-${r.exerciseId}`
					}
					component={RouterLink}
				>
					{`${r.exerciseNumber}. ${r.exerciseTitle}`}
				</Link>
			</ListItemText>
			{!r.hidden && (
				<IconButtonWithTooltip
					tooltipTitle={
						<Localized id="my-corner-exercise-reminders-widget-action-forget-reminder">
							Forget
						</Localized>
					}
					edge="end"
					aria-label={l10n.getString(
						"my-corner-exercise-reminders-widget-action-forget-reminder"
					)}
					onClick={props.onHide}
				>
					<ClearIcon />
				</IconButtonWithTooltip>
			)}
		</ListItem>
	);
}

function ReminderPlaceholder() {
	return (
		<ListItem
			disableGutters
			sx={(theme) => ({
				px: theme.spacing(3),
				height: (theme) => `calc(1.5rem + ${theme.spacing(3)}`,
			})}
		>
			<ListItemIcon>
				<Skeleton variant="rectangular" width="1.5rem" height="1.5rem" />
			</ListItemIcon>
			<ListItemText
				secondary={
					<Skeleton width="10ch">
						<Typography variant="inherit" component="span">
							.
						</Typography>
					</Skeleton>
				}
			>
				<Skeleton width={`${randomN(50, 70)}ch`} sx={{maxWidth: "100%"}}>
					<Typography variant="inherit" component="span">
						.
					</Typography>
				</Skeleton>
			</ListItemText>
		</ListItem>
	);
}

function randomN(min: number, max: number) {
	return min + Math.floor(Math.random() * (max - min));
}

function EmptyState(props: {fullHistory: boolean}) {
	return (
		<Container>
			<Stack sx={{alignItems: "center"}}>
				<Typography variant="h6">
					<Localized id="my-corner-exercise-reminders-widget-no-results-title">
						Congratulations!
					</Localized>
				</Typography>
				<Typography align="center">
					{props.fullHistory ? (
						<Localized id="my-corner-exercise-reminders-widget-no-results-history">
							You had no challenges in exercises
						</Localized>
					) : (
						<Localized id="my-corner-exercise-reminders-widget-no-results-current">
							Last time you had no challenges in exercises
						</Localized>
					)}
				</Typography>
			</Stack>
		</Container>
	);
}

function ErrorState(props: {onReload: () => void}) {
	return (
		<Container>
			<LoadingError
				variant="block"
				description={
					<Localized id="my-corner-exercise-reminders-widget-loading-error-desc">
						Something has gone wrong, and we cannot load exercises
					</Localized>
				}
				onReload={props.onReload}
			/>
		</Container>
	);
}

export default ExerciseRemindersWidget;
