import {useLocalization} from "@fluent/react";
import {Divider, Grid} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {Fragment, useCallback, useContext, useMemo} from "react";

import ExerciseContent from "./ExerciseContent";
import CourseFeature from "../../../store/courses/CourseFeature";
import selectCourse from "../../../store/courses/selectCourse";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {keyProvider} from "../../../store/keyProvider";
import type SelectionMode from "../../../store/chapters/SelectionMode";
import {SelectionModes} from "../../../store/chapters/SelectionMode";
import SubmissionMode from "../../../store/chapters/SubmissionMode";
import type {OpenConfirmationDialog} from "../../../hooks/useConfirmationDialog";
import {resetStudentResponse} from "../../../store/studentResponses/resetStudentResponse";
import {unwrapResult} from "@reduxjs/toolkit";
import {ResponseToSave} from "../../../store/studentResponses/Response";
import {selectUserId} from "../../../store/userProfile/selectUserProfile";
import type {ShowSnackbar} from "../../../store/ui/useSnackbar";
import createStudentResponseFileUploaderFactory from "./createStudentResponseFileUploaderFactory";
import type {CompletionStatus} from "../../../store/outcomes/ExerciseOutcomes";
import postOutcomes from "../../../store/outcomes/postOutcomes";
import AssessmentDeliveryContext from "../../AssessmentDeliveryContext";

const useStyles = makeStyles((theme) =>
	createStyles({
		divider: {
			marginLeft: theme.spacing(-6),
			marginRight: theme.spacing(-6),
			marginTop: theme.spacing(7),
			marginBottom: theme.spacing(7),
		},
	})
);

type Props = {
	courseId: number;
	sectionNumber: number;
	exerciseKeys: string[];
	submissionMode: SubmissionMode;
	selectionMode: SelectionMode;
	readonly?: boolean;
	submissionDisabled?: boolean;

	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
};

const Exercises = (props: Props): JSX.Element => {
	const classes = useStyles();

	function notLast(i: number) {
		return i !== props.exerciseKeys.length - 1;
	}

	const userId = useAppSelector((state) => selectUserId(state));
	const course = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(props.courseId))
	);

	const {saveResponse, submitResponse} = useContext(AssessmentDeliveryContext);

	const dispatch = useAppDispatch();

	const {courseId, openConfirmDialog, showSnackbar} = props;

	const features = course?.features || [];

	const noExplicitSessions =
		props.submissionMode !== SubmissionMode.Simultaneous &&
		props.selectionMode !== SelectionModes.Random;

	const emptyAnswerAllowed = features.includes(
		CourseFeature.EmptyAnswersToOpenExercises
	);
	const difficultyVisible = features.includes(CourseFeature.ExerciseDifficulty);
	const redoing =
		noExplicitSessions && features.includes(CourseFeature.RedoingExercises);
	const selfAssessment =
		noExplicitSessions && features.includes(CourseFeature.SelfAssessment);

	const tutoringEnabled =
		noExplicitSessions && features.includes(CourseFeature.Tutoring);

	const {l10n} = useLocalization();
	const redoExercise = useCallback(
		async (chapterId: number, exerciseId: number) => {
			openConfirmDialog({
				title: l10n.getString(
					"content-exercise-dialog-redo-exercise-title",
					null,
					"Redo exercise"
				),
				description: l10n.getString(
					"content-exercise-dialog-redo-exercise-description",
					null,
					"If you proceed, you will be able to answer to this exercise one more time, " +
						"but your current response and result will be lost."
				),
				confirmBtnText: l10n.getString(
					"content-exercise-dialog-redo-exercise-button-redo",
					null,
					"Redo"
				),
				onConfirm: async () => {
					await dispatch(
						resetStudentResponse({
							studentId: userId,
							courseId,
							chapterId,
							exerciseId,
						})
					);
				},
			});
		},
		[courseId, dispatch, openConfirmDialog, userId, l10n]
	);

	const saveStudentResponse = async (
		exerciseId: number,
		response: ResponseToSave,
		successMessage?: string
	) => {
		const feedback = saveResponse(exerciseId, response);

		if (successMessage) {
			showSnackbar("success", successMessage);
		}

		return feedback;
	};

	const postExerciseOutcomes = async (
		chapterId: number,
		exerciseId: number,
		outcomes: {
			completionStatus: CompletionStatus;
			score?: number;
		}
	) => {
		const result = await dispatch(
			postOutcomes({
				studentId: userId,
				courseId,
				chapterId,
				exerciseId,
				outcomes,
			})
		);
		unwrapResult(result);
	};

	const fileUploadFactory = useMemo(
		() => createStudentResponseFileUploaderFactory(userId, courseId),
		[courseId, userId]
	);

	return (
		<Grid container>
			{props.exerciseKeys.map((exerciseKey, i) => (
				<Fragment key={exerciseKey}>
					<Grid item xs={12}>
						<ExerciseContent
							emptyAnswerAllowed={emptyAnswerAllowed}
							difficultyVisible={difficultyVisible}
							selfAssessment={selfAssessment}
							tutoringEnabled={tutoringEnabled}
							sectionNumber={props.sectionNumber}
							exerciseKey={exerciseKey}
							courseId={props.courseId}
							userId={userId}
							readonly={props.readonly}
							submissionDisabled={props.submissionDisabled}
							openConfirmDialog={openConfirmDialog}
							onSave={saveStudentResponse}
							onSubmit={
								props.submissionMode === SubmissionMode.Individual
									? submitResponse
									: undefined
							}
							redoExercise={redoing ? redoExercise : undefined}
							onPostOutcomes={postExerciseOutcomes}
							createFileUploader={fileUploadFactory}
						/>
					</Grid>

					{notLast(i) && (
						<Grid item xs={12}>
							<Divider className={classes.divider} />
						</Grid>
					)}
				</Fragment>
			))}
		</Grid>
	);
};

export default React.memo(Exercises);
