import {createAsyncThunk} from "@reduxjs/toolkit";

import {courseService} from "../services/courseService";

const unpublishChapter = createAsyncThunk(
	"chapters/unpublishChapter",
	async function (params: {courseId: number; chapterId: number}) {
		const {courseId, chapterId} = params;

		await courseService.updateChapter(courseId, chapterId, {startDate: null});

		return {
			id: chapterId,
		};
	}
);

export default unpublishChapter;
