import {Button, CircularProgress} from "@mui/material";
import type {ButtonProps} from "@mui/material";
import React from "react";

function ProgressButton(props: {inProgress?: boolean} & ButtonProps) {
	const {inProgress, onClick, sx, children, disabled, ...rest} = props;

	return (
		<Button
			disabled={disabled || inProgress}
			sx={[
				{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "relative",
				},
				...(Array.isArray(sx) ? sx : [sx]),
			]}
			onClick={onClick}
			{...rest}
		>
			{inProgress && (
				<CircularProgress
					size={"1.5rem"}
					color="inherit"
					sx={{position: "absolute"}}
				/>
			)}
			{children}
		</Button>
	);
}

export default ProgressButton;
