import type {RootState} from "../store";
import type {ChapterStats} from "./chapterStatisticsSlice";

const empty: ChapterStats = {};

function selectChapterStatisticsByType(
	state: RootState,
	chapterKey: string
): ChapterStats {
	return state.chapterStatistics.byKey[chapterKey] ?? empty;
}

export default selectChapterStatisticsByType;
