import {Container, TableCell, TableRow} from "@mui/material";
import React from "react";

function TableStateRow(props: {
	children: NonNullable<React.ReactNode>;
	columnNumber?: number;
	spanWholeTable?: boolean;
}) {
	return (
		<TableRow>
			<TableCell
				colSpan={props.columnNumber}
				sx={[
					{borderBottom: "none"},
					Boolean(props.spanWholeTable) && {
						position: "absolute",
						inset: 0,
					},
				]}
			>
				<Container
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						pt: props.spanWholeTable ? 0 : 6,
						height: 1,
					}}
				>
					{props.children}
				</Container>
			</TableCell>
		</TableRow>
	);
}

export default TableStateRow;
