import {Box} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";
import {Link, useLocation} from "react-router-dom";

import IndexedLabel from "../../../utils/IndexedLabel";

const useStyles = makeStyles<Theme, {active?: boolean}>((theme) =>
	createStyles({
		index: {
			color: ({active}) =>
				active ? theme.palette.secondary.main : theme.palette.text.secondary,
			marginRight: theme.spacing(2),
		},
		label: {
			color: ({active}) =>
				active ? theme.palette.secondary.main : theme.palette.text.primary,
		},
	})
);

const WidgetNavigationItem = (props: {
	targetId: string;
	number: number;
	title: React.ReactNode;
	onClick?: () => void;
}): JSX.Element => {
	const target = `#${props.targetId}`;

	const location = useLocation();
	const active = location.hash === target;

	const classes = useStyles({active});

	return (
		<Box display="flex" flexGrow={1}>
			<Link
				to={target}
				style={{textDecoration: "none"}}
				onClick={props.onClick}
			>
				<IndexedLabel
					index={`${props.number}`}
					text={props.title}
					classes={{
						index: classes.index,
						label: classes.label,
					}}
					variant="body1"
				/>
			</Link>
		</Box>
	);
};

export default WidgetNavigationItem;
