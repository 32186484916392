import {useMemo} from "react";

import makeURL from "./makeURL";
import type ProctoringSettings from "../../store/proctoring/ProctoringSettings";

function useComputerMonitoringToolDownloadLink(
	settings: ProctoringSettings,
	studentId: number
) {
	return useMemo(
		() =>
			makeURL("https://swl.smowltech.net/monitor/SCMdownload.php", {
				entity_Name: settings.entityName,
				user_idUser: studentId.toString(),
				lang: "en",
			}),
		[settings.entityName, studentId]
	);
}

export default useComputerMonitoringToolDownloadLink;
