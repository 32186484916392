import {createAsyncThunk} from "@reduxjs/toolkit";

import {courseService} from "../services/courseService";

type Params = {
	courseId: number;
	chapterId: number;
	sectionItem: {
		title: string;
		content: string;
	};
};

export const addSectionItem = createAsyncThunk(
	"sections/insertSection",
	(params: Params) =>
		courseService.addSection(
			params.courseId,
			params.chapterId,
			params.sectionItem
		)
);
