import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectStudyPeriodStatus(
	state: RootState,
	courseKey: string
): FetchStatus {
	return state.studentPreferences.byCourseKey[courseKey]?.status ?? "none";
}

export default selectStudyPeriodStatus;
