import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {studentResponseService} from "../services/studentResponseService";
import {ResponseToSave} from "./Response";

export const saveStudentResponse = createAsyncThunk(
	"studentResponses/saveStudentResponse",
	async (params: {
		studentId: number;
		courseId: number;
		chapterId: number;
		exerciseId: number;
		response: ResponseToSave;
	}) => {
		const feedback = await studentResponseService.saveResponse(
			params.studentId,
			params.courseId,
			params.chapterId,
			params.exerciseId,
			params.response
		);

		const responseKey = keyProvider.chapterExercise(
			params.chapterId,
			params.exerciseId
		);

		return {
			responseKey,
			response: params.response,
			feedback,
		};
	}
);
