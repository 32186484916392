import {useLocalization} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import SubmitButton from "./SubmitButton";

const useStyles = makeStyles((theme) =>
	createStyles({
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
	})
);

function FullScreenDialogTitle(props: {
	id: string;
	title: React.ReactNode;
	actionButtonLabel: React.ReactNode;
	actionInProgress?: boolean;
	onAction: () => void;
	onClose: () => void;
}): JSX.Element {
	const classes = useStyles();
	const {l10n} = useLocalization();
	return (
		<AppBar className={classes.appBar} id={props.id}>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label={l10n.getString(
						"dialog-title-close-btn-label",
						null,
						"Close"
					)}
					disabled={props.actionInProgress}
					onClick={props.onClose}
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title} noWrap>
					{props.title}
				</Typography>
				<SubmitButton
					onClick={props.onAction}
					inProgress={props.actionInProgress}
					variant="text"
					autoFocus
					color="inherit"
				>
					{props.actionButtonLabel}
				</SubmitButton>
			</Toolbar>
		</AppBar>
	);
}

export default FullScreenDialogTitle;
