import React from "react";
import {Route} from "react-router-dom";
import type {RouteProps} from "react-router-dom";

function ObservableRoute(
	props: RouteProps & {onRender?: () => void}
): JSX.Element {
	const {onRender, children, render, ...other} = props;
	return (
		<Route
			{...other}
			render={(params) => {
				setTimeout(() => onRender?.(), 10);
				if (children) {
					return children;
				}
				if (render) {
					return render(params);
				}
				return <></>;
			}}
		/>
	);
}

export default ObservableRoute;
