import {createSlice} from "@reduxjs/toolkit";

import createChapter from "./createChapter";
import {getChapterContent} from "./getChapterContent";
import updateChapterStructure from "./updateChapterStructure";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	sections: string[];
	status: FetchStatus;
};

type SliceState = {
	byKey: {
		[key: string]: State | undefined;
	};
};

const initialState: SliceState = {
	byKey: {},
};

export const chapterSectionsSlice = createSlice({
	name: "chapterSections",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(createChapter.fulfilled, (state, action) => {
			const chapterKey = keyProvider.chapter(action.payload.id);

			state.byKey[chapterKey] = {
				sections: [],
				status: "succeeded",
			};
		});

		builder.addCase(getChapterContent.pending, (state, action) => {
			const chapterKey = keyProvider.chapter(action.meta.arg.chapterId);

			updateStatus(state, chapterKey, "pending");
		});

		builder.addCase(getChapterContent.rejected, (state, action) => {
			const chapterKey = keyProvider.chapter(action.meta.arg.chapterId);

			updateStatus(state, chapterKey, inferFetchStatusFromError(action));
		});

		builder.addCase(getChapterContent.fulfilled, (state, action) => {
			const chapterKey = action.payload.chapterKey;
			const entities = action.payload.entities;

			state.byKey[chapterKey] = {
				sections: entities.chapters[chapterKey].sections,
				status: "succeeded",
			};
		});

		builder.addCase(updateChapterStructure.fulfilled, (state, action) => {
			const chapterKey = action.payload.chapterKey;
			const structure = action.payload.structure;

			const s = state.byKey[chapterKey];
			if (s) {
				s.sections = structure.sectionKeys;
			}
		});
	},
});

function updateStatus(state: SliceState, key: string, status: FetchStatus) {
	const s = state.byKey[key];
	if (!s) {
		state.byKey[key] = {
			sections: [],
			status: status,
		};
	} else {
		s.status = status;
	}
}

export default chapterSectionsSlice.reducer;
