import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import chapterSessionsService from "../services/chapterSessionsService";
import {RootState} from "../store";
import selectChapterSession from "./selectChapterSession";
import selectLastRequestedSessionKey from "./selectLastRequestedSessionKey";

export const submitChapterSession = createAsyncThunk<
	{key: string; submissionTime?: string; sessionKey: string},
	{studentId: number; courseId: number; chapterId: number},
	{state: RootState}
>(
	"chapterSessions/submit",
	async ({studentId, courseId, chapterId}, thunkApi) => {
		const state = thunkApi.getState();

		const sessionKey = selectLastRequestedSessionKey(state);

		const session = selectChapterSession(state, sessionKey);
		if (!session) {
			throw new Error(`Chapter session ${sessionKey} not found`);
		}

		const submitted = await chapterSessionsService.submitChapterSession(
			studentId,
			courseId,
			chapterId,
			session.startTime
		);

		return {
			key: keyProvider.chapter(chapterId),
			submissionTime: submitted.submissionTime,
			sessionKey,
		};
	}
);
