import {EditorState, Extension} from "@codemirror/state";
import {EditorView} from "@codemirror/view";
import {RangeSet} from "@codemirror/rangeset";
import {RangeSetBuilder} from "@codemirror/rangeset";
import {GutterMarker, gutter} from "@codemirror/gutter";

function calculateBreakpointsPositions(
	view: EditorView,
	range: {from: number; to: number} | null,
	pos: number
) {
	let editableLinesPos = [];

	if (range) {
		editableLinesPos.push(view.state.doc.line(range.from).from);
		editableLinesPos.push(view.state.doc.line(range.to).from);
	}

	const lineNumAt = (pos: number) => view.state.doc.lineAt(pos).number;

	if (editableLinesPos.includes(pos)) {
		editableLinesPos = editableLinesPos.filter((p) => p !== pos);
	} else if (editableLinesPos.length < 2) {
		if (editableLinesPos.length === 1 && editableLinesPos[0] > pos) {
			editableLinesPos.unshift(pos);
		} else {
			editableLinesPos.push(pos);
		}
	} else {
		const middle =
			(lineNumAt(editableLinesPos[0]) + lineNumAt(editableLinesPos[1])) / 2;
		const ind = lineNumAt(pos) <= middle ? 0 : 1;
		editableLinesPos[ind] = pos;
	}

	return editableLinesPos;
}

const breakpointMarker = new (class extends GutterMarker {
	toDOM() {
		const elem = document.createElement("span");
		elem.classList.add("readonly-selector");
		return elem;
	}
})();

function createEditableRangeSet(
	view: EditorView,
	range: {from: number; to: number} | null
) {
	if (!range) {
		return RangeSet.empty;
	}

	const builder = new RangeSetBuilder<GutterMarker>();

	const fromPos = view.state.doc.line(range.from).from;
	builder.add(fromPos, fromPos, breakpointMarker);

	if (range.from !== range.to) {
		const toPos = view.state.doc.line(range.to).from;
		builder.add(toPos, toPos, breakpointMarker);
	}

	return builder.finish();
}

const editableRangeSelectorExtension = (
	onSelectionChange: (range: {from: number; to: number} | null) => void,
	getEditableRange: (state: EditorState) => {from: number; to: number} | null
): Extension[] => [
	gutter({
		class: "cm-breakpoint-gutter",
		markers: (v) => createEditableRangeSet(v, getEditableRange(v.state)),
		initialSpacer: () => breakpointMarker,
		domEventHandlers: {
			mousedown(view, line) {
				const bp = calculateBreakpointsPositions(
					view,
					getEditableRange(view.state),
					line.from
				);

				if (bp.length > 0) {
					const firstLine = view.state.doc.lineAt(bp[0]).number;
					onSelectionChange({
						from: firstLine,
						to:
							bp.length === 2 ? view.state.doc.lineAt(bp[1]).number : firstLine,
					});
				} else {
					onSelectionChange(null);
				}

				return true;
			},
		},
	}),
];

export default editableRangeSelectorExtension;
