import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {scrollToTop} from "../helpers/scrollHelpers";

const ScrollToTopOnMount = (): JSX.Element => {
	const location = useLocation();

	useEffect(() => {
		if (!location.hash) {
			scrollToTop();
		}
	}, [location.hash]);

	return <></>;
};

export default ScrollToTopOnMount;
