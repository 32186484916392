import {createAsyncThunk} from "@reduxjs/toolkit";

import {userService} from "../services/userService";

const fetchUserOrganisationGroups = createAsyncThunk(
	"userOrganisationGroups/fetch",
	async (params: {userId: number}) => {
		const groups = await userService.getOrganisationGroups(params.userId);
		return {groups};
	}
);

export default fetchUserOrganisationGroups;
