import {EditorState, Extension, Transaction} from "@codemirror/state";
import {RangeSetBuilder} from "@codemirror/rangeset";
import {
	Decoration,
	DecorationSet,
	EditorView,
	ViewPlugin,
	ViewUpdate,
} from "@codemirror/view";

type Range = {
	from: number;
	to: number;
};

function subtractRanges(selection: Range, readOnlyRanges: Range[]) {
	const result: Range[] = [];

	let sel = selection;

	const emptyRange = {
		from: 0,
		to: 0,
	};

	readOnlyRanges.forEach((r) => {
		if (sel.from >= r.from && sel.from <= r.to) {
			if (sel.to <= r.to) {
				sel = emptyRange;
			} else {
				sel.from = r.to + 1;
			}
		} else if (sel.from < r.from && sel.to >= r.from) {
			result.push({
				from: sel.from,
				to: r.from - 1,
			});

			if (sel.to > r.to) {
				sel.from = r.to + 1;
			} else {
				sel = emptyRange;
			}
		}
	});

	if (
		sel !== emptyRange &&
		!result.some((r) => r.from === sel.from && r.to === sel.to)
	) {
		result.push(sel);
	}

	return result;
}

const deleteSelection = (
	getReadOnlyRanges: (state: EditorState) => Range[]
): Extension =>
	EditorState.transactionFilter.of((tr: Transaction) => {
		if (
			tr.isUserEvent("delete.selection") &&
			!tr.isUserEvent("delete.selection.deletable")
		) {
			const initialSelections = tr.startState.selection.ranges.map((range) => ({
				from: range.from,
				to: range.to,
			}));

			if (initialSelections.length > 0) {
				const readOnlyRanges = getReadOnlyRanges(tr.startState);
				const result = subtractRanges(initialSelections[0], readOnlyRanges);
				return result.map((range) =>
					tr.startState.update({
						changes: {
							from: range.from,
							to: range.to,
						},
						annotations: Transaction.userEvent.of(
							`${tr.annotation(Transaction.userEvent)}.deletable`
						),
					})
				);
			}
		}

		return tr;
	});

const preventModify = (
	getReadOnlyRanges: (state: EditorState) => Range[]
): Extension =>
	EditorState.changeFilter.of((tr: Transaction) => {
		try {
			const rangesBefore = getReadOnlyRanges(tr.startState);
			const rangesAfter = getReadOnlyRanges(tr.state);

			for (let i = 0; i < rangesBefore.length; i++) {
				const targetFromBefore = rangesBefore[i].from ?? 0;
				const targetToBefore =
					rangesBefore[i].to ??
					tr.startState.doc.line(tr.startState.doc.lines).to;

				const targetFromAfter = rangesAfter[i].from ?? 0;
				const targetToAfter =
					rangesAfter[i].to ?? tr.state.doc.line(tr.state.doc.lines).to;

				if (
					tr.startState.sliceDoc(targetFromBefore, targetToBefore) !==
					tr.state.sliceDoc(targetFromAfter, targetToAfter)
				) {
					return false;
				}

				for (let i = 0; i < rangesAfter.length - 1; i++) {
					if (rangesAfter[i].to + 1 === rangesAfter[i + 1].from) {
						return false;
					}
				}
			}
		} catch (e) {
			return false;
		}
		return true;
	});

const readonlyLine = Decoration.line({
	attributes: {style: "background: #eeeeeed6"},
});

function readonlyDeco(
	view: EditorView,
	getReadOnlyRanges: (state: EditorState) => Range[]
) {
	const ranges = getReadOnlyRanges(view.state);
	const builder = new RangeSetBuilder<Decoration>();

	for (const {from, to} of ranges) {
		for (let pos = from; pos <= to; ) {
			const line = view.state.doc.lineAt(pos);
			builder.add(line.from, line.from, readonlyLine);
			pos = line.to + 1;
		}
	}

	return builder.finish();
}

const paintReadOnly = (
	getReadOnlyRanges: (state: EditorState) => Range[]
): Extension => {
	return ViewPlugin.fromClass(
		class {
			decorations: DecorationSet;

			constructor(view: EditorView) {
				this.decorations = readonlyDeco(view, getReadOnlyRanges);
			}

			update(update: ViewUpdate) {
				if (update.docChanged || update.viewportChanged) {
					this.decorations = readonlyDeco(update.view, getReadOnlyRanges);
				}
			}
		},
		{
			decorations: (v) => v.decorations,
		}
	);
};

const readOnlyRangesExtension = (
	getReadOnlyRanges: (state: EditorState) => Range[]
): Extension => [
	deleteSelection(getReadOnlyRanges),
	preventModify(getReadOnlyRanges),
	paintReadOnly(getReadOnlyRanges),
];

export default readOnlyRangesExtension;
export {paintReadOnly};
