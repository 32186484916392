import {createSlice} from "@reduxjs/toolkit";
import {fetchStudentMessages, fetchTutorMessages} from "./fetchMessages";
import {sendNewQuestion} from "./sendNewQuestion";
import sendAnswer from "./sendAnswer";
import type TutorQuestion from "./TutorQuestion";

type TutorQuestions = {
	byKey: {
		[key: string]: TutorQuestion;
	};
	keys: string[];
	answered: string[];
	courseId: number;
};

const initialState: TutorQuestions = {
	byKey: {},
	keys: [],
	answered: [],
	courseId: 0,
};

export const tutorQuestionsSlice = createSlice({
	name: "tutorQuestions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchStudentMessages.fulfilled, (state, action) => {
			state.byKey = action.payload.entities;
			state.answered = action.payload.answered;
			state.courseId = action.payload.courseId;
			state.keys = action.payload.keys;
		});

		builder.addCase(sendAnswer.fulfilled, (state, action) => {
			state.byKey[action.payload.messageKey] = {
				...state.byKey[action.payload.messageKey],
				answer: action.payload.answer,
				answerTime: action.payload.answerTime,
				tutorId: action.payload.tutorId,
				tutorName: action.payload.tutorName,
			};
			state.answered.unshift(action.payload.messageKey);
		});

		builder.addCase(fetchTutorMessages.fulfilled, (state, action) => {
			state.byKey = action.payload.entities;
			state.answered = action.payload.answered;
			state.courseId = action.payload.courseId;
			state.keys = action.payload.keys;
		});

		builder.addCase(sendNewQuestion.fulfilled, (state, action) => {
			state.byKey[action.payload.questionKey] = action.payload.question;
			state.keys.unshift(action.payload.questionKey);
		});
	},
});

export default tutorQuestionsSlice.reducer;
