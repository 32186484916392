import {createSlice} from "@reduxjs/toolkit";
import type FetchStatus from "../FetchStatus";
import WidgetKey, {FollowUpWidgetKey, MyCornerWidgetKey} from "./WidgetKey";
import fetchWidgets from "./fetchWidgets";
import {keyProvider} from "../keyProvider";
import removeWidget from "./removeWidget";
import insertWidget from "./insertWidget";
import type DashboardName from "./DashboardName";
import defineFetchStatus from "../inferFetchStatusFromError";
import deleteCourses from "../courses/deleteCourses";

type State = {
	"follow-up": {
		byKey: {
			[courseKey in string]:
				| {
						keys: FollowUpWidgetKey[];
						status: FetchStatus;
				  }
				| undefined;
		};
	};
	my_corner: {
		byKey: {
			[courseKey in string]:
				| {
						keys: MyCornerWidgetKey[];
						status: FetchStatus;
				  }
				| undefined;
		};
	};
	editing: boolean;
};

const initialState: State = {
	"follow-up": {byKey: {}},
	my_corner: {byKey: {}},
	editing: false,
};

const courseWidgetKeysSlice = createSlice({
	name: "courseWidgetKeys",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchWidgets.fulfilled, (state, {payload}) => {
			state[payload.dashboardName].byKey[payload.courseKey] = {
				keys: payload.widgetKeys as typeof payload.dashboardName extends "follow-up"
					? FollowUpWidgetKey[]
					: MyCornerWidgetKey[],
				status: "succeeded",
			};
		});
		builder.addCase(fetchWidgets.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const dashboardName = action.meta.arg.dashboardName;

			const s = obtainState(state, dashboardName, courseKey);
			s.keys = [];
			s.status = "pending";
		});
		builder.addCase(fetchWidgets.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const dashboardName = action.meta.arg.dashboardName;

			const s = obtainState(state, dashboardName, courseKey);
			s.status = defineFetchStatus(action);
		});

		builder.addCase(insertWidget.fulfilled, (state, {payload}) => {
			state.editing = false;

			const {courseKey, dashboardName, widgetName, place} = payload;

			const s = state[dashboardName].byKey[courseKey];
			if (!s) {
				return;
			}

			const filtered = (s.keys as WidgetKey[]).filter((k) => k !== widgetName);

			let insertAt = filtered.length;
			if (place?.before) {
				insertAt = filtered.findIndex((k) => k === place.before);
				if (insertAt < 0) {
					insertAt = 0;
				}
			}

			filtered.splice(insertAt, 0, widgetName);

			s.keys = filtered as FollowUpWidgetKey[] | MyCornerWidgetKey[];
		});
		builder.addCase(insertWidget.pending, (state) => {
			state.editing = true;
		});
		builder.addCase(insertWidget.rejected, (state) => {
			state.editing = false;
		});

		builder.addCase(removeWidget.fulfilled, (state, {payload}) => {
			state.editing = false;

			const {courseKey, dashboardName} = payload;

			const s = state[dashboardName].byKey[courseKey];
			if (!s) {
				return;
			}

			s.keys = (s.keys as WidgetKey[]).filter(
				(k) => k !== payload.widgetKey
			) as FollowUpWidgetKey[] | MyCornerWidgetKey[];
		});
		builder.addCase(removeWidget.pending, (state) => {
			state.editing = true;
		});
		builder.addCase(removeWidget.rejected, (state) => {
			state.editing = false;
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				delete state["follow-up"].byKey[keyProvider.course(id)];
				delete state["my_corner"].byKey[keyProvider.course(id)];
			});
		});
	},
});

function obtainState(state: State, dashboardName: DashboardName, key: string) {
	let s = state[dashboardName].byKey[key];
	if (!s) {
		s = {
			keys: [],
			status: "none",
		};
		state[dashboardName].byKey[key] = s;
	}

	return s;
}

export default courseWidgetKeysSlice.reducer;
