import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";
import type Organisation from "./Organisation";

function selectOrganisation(
	state: RootState,
	name: string
): Organisation | null {
	return state.organisations.byKey[name]?.organisation ?? null;
}

function selectOrganisationFetchStatus(
	state: RootState,
	name: string
): FetchStatus {
	return state.organisations.byKey[name]?.status ?? "none";
}

export default selectOrganisation;
export {selectOrganisationFetchStatus};
