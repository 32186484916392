import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	DialogContentText,
	Divider,
	Fade,
	Grid,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import UserRole from "../../store/models/UserRole";
import {adminService} from "../../store/services/adminService";
import type {
	UserGroupOrganisationSearchCriteria,
	UserGroupOrganisationSearchResult,
} from "../../store/services/organisationService";
import {organisationService} from "../../store/services/organisationService";
import useSnackbar from "../../store/ui/useSnackbar";
import useAssignToGroupCoursesAction from "../users/useAssignToGroupCoursesAction";
import useEnrolInGroupCourseAction from "../users/useEnrolInGroupCourseAction";
import useGrantAdminInGroupOrganisationAction from "../users/useGrantAdminInGroupOrganisationAction";
import UserCourses from "../users/UserCourses";
import UserRoleChips from "../users/UserRoleChips";
import ChipsSelector from "../../utils/ChipsSelector";
import DualTextLabel from "../../utils/DualTextLabel";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import SearchToolbar from "../../utils/SearchToolbar";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

const useRootStyles = makeStyles<Theme, {navBarHeight: number}>((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
			height: ({navBarHeight}) => `calc(100vh - ${navBarHeight + 1}px)`,
			width: "100%",
		},
		container: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
		},
		overlay: {
			background: theme.palette.background.paper,
			height: "100%",
			width: "100%",
			position: "relative",
		},
	})
);

const emptyOrg = {
	city: "",
	countryCode: "",
	displayName: "",
	name: "",
	roles: [],
};

function GroupUserOrganisations(props: {
	groupName: string;
	userId: number;
	userName: string;
}) {
	const {groupName, userId} = props;

	const [orgs, setOrgs] = useState<UserGroupOrganisationSearchResult[]>([]);

	const [expanded, setExpanded] = useState(false);
	const [expandedOrgName, setExpandedOrgName] = useState("");

	const navBarHeight = useNavBarHeight();
	const classes = useRootStyles({navBarHeight});

	const history = useHistory();

	const mobileMode = useMobileMode("md");

	const organisationPage = useRef<{reload?: () => void}>({});

	const expandedOrg = orgs.find((o) => o.name === expandedOrgName) ?? emptyOrg;

	const memberOfMultipleOrganisations = orgs.length > 1;

	return (
		<Box
			pt={mobileMode ? 3 : 5}
			px={mobileMode ? 3 : 6}
			pb={1.5}
			className={classes.root}
		>
			<div className={classes.container}>
				<Organisations
					groupName={groupName}
					userId={userId}
					userName={props.userName}
					onExpand={(name) => {
						setExpandedOrgName(name);
						setExpanded(true);
					}}
					onFetched={setOrgs}
					hidden={expanded}
					pageRef={organisationPage}
				/>
				<Fade in={expanded} mountOnEnter unmountOnExit>
					<Box position="absolute" style={{inset: 0}}>
						<Box className={classes.overlay} zIndex={2}>
							<CoursesInOrganisation
								userId={userId}
								organisation={expandedOrg}
								userName={props.userName}
								memberOfMultipleOrganisations={memberOfMultipleOrganisations}
								onCollapse={() => setExpanded(false)}
								onRolesUpdated={() => organisationPage.current.reload?.()}
								onDelete={() => {
									setExpanded(false);

									if (memberOfMultipleOrganisations) {
										organisationPage.current.reload?.();
									} else {
										history.push("/users");
									}
								}}
							/>
						</Box>
					</Box>
				</Fade>
			</div>
		</Box>
	);
}

const useOrgTableStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
		mainAction: {
			whiteSpace: "nowrap",
		},
	})
);

const organisationHeadCells: HeadCell<UserGroupOrganisationSearchResult>[] = [
	{
		id: "name",
		label: "group-user-organisations-table-column-name",
		sortable: true,
	},
	{
		id: "roles",
		label: "group-user-organisations-table-column-roles",
		sortable: false,
		width: 400,
		align: "right",
	},
];

const columnNumber = organisationHeadCells.length + 1;

const rolesInOrg = [
	UserRole.Student,
	UserRole.Teacher,
	UserRole.Tutor,
	UserRole.Administrator,
];

const initialOrgSearchCriteria: UserGroupOrganisationSearchCriteria = {};

function Organisations(props: {
	groupName: string;
	userId: number;
	userName: string;
	hidden?: boolean;
	onExpand: (organisationName: string) => void;
	onFetched: (orgs: UserGroupOrganisationSearchResult[]) => void;

	pageRef: React.Ref<{reload?: () => void}>;
}) {
	const {groupName, userId, onFetched} = props;

	const classes = useOrgTableStyles();

	const [criteria, setCriteria] = useState(initialOrgSearchCriteria);

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof UserGroupOrganisationSearchResult
	>("name");

	const [orgsPerPage, setOrgsPerPage] = useState(10);

	const {
		page: orgsPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage,
		retryFetching,
	} = usePaginationState<UserGroupOrganisationSearchResult, string>();

	const organisations = orgsPage.content;

	const {l10n} = useLocalization();
	const mobileMode = useMobileMode("md");

	const [addRoleMenu, setAddRoleMenu] = useState<HTMLButtonElement | null>(
		null
	);

	useImperativeHandle(props.pageRef, () => ({reload: retryFetching}), [
		retryFetching,
	]);

	const [enrolInCourse, enrolInCourseDialog] = useEnrolInGroupCourseAction(
		groupName,
		mobileMode,
		retryFetching
	);

	const [
		assignToCourses,
		assignToCoursesDialog,
	] = useAssignToGroupCoursesAction(groupName, mobileMode, retryFetching);

	const [grantAdmin, grantAdminDialog] = useGrantAdminInGroupOrganisationAction(
		groupName,
		retryFetching
	);

	useEffect(() => {
		fetchFirstPage(() =>
			organisationService.searchGroupOrganisationsOfUser(
				groupName,
				userId,
				criteria,
				{field: sortField, descending: sortOrder === SortOrder.Desc},
				orgsPerPage
			)
		);
	}, [
		criteria,
		fetchFirstPage,
		groupName,
		orgsPerPage,
		sortField,
		sortOrder,
		userId,
	]);

	useEffect(() => {
		onFetched(orgsPage.content);
	}, [orgsPage.content, onFetched]);

	if (props.hidden) {
		return <></>;
	}

	return (
		<>
			<SearchToolbar
				criteria={criteria}
				searchPlaceholder={l10n.getString(
					"group-user-organisations-search-placeholder"
				)}
				mainAction={
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						onClick={(event) => setAddRoleMenu(event.currentTarget)}
						className={classes.mainAction}
					>
						<Localized id="group-user-organisations-main-action-add-role">
							Add role
						</Localized>
					</Button>
				}
				onCriteriaChange={setCriteria}
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<ChipsSelector
							options={rolesInOrg}
							color="secondary"
							variant="outlined"
							value={criteria.roles ?? []}
							label={
								<Localized id="group-user-organisations-filter-roles">
									Roles
								</Localized>
							}
							onChange={(roles) =>
								setCriteria((prev) =>
									roles.length === rolesInOrg.length || roles.length === 0
										? {...prev, roles: undefined}
										: {...prev, roles}
								)
							}
							getOptionLabel={(role) => (
								<Localized id="user-role-chips-role" vars={{role}} />
							)}
						/>
					</Grid>
				</Grid>
			</SearchToolbar>
			<TableContainer className={classes.container} style={{}}>
				<Table stickyHeader>
					<SortingHeader
						onOrderChange={changeOrder}
						order={sortOrder}
						orderBy={sortField}
						headCells={organisationHeadCells}
						rightAnnex={<TableCell />}
						loading={pageFetchStatus === "pending"}
					/>
					<TableBody>
						{pageFetchStatus === "failed" && (
							<LoadingErrorState
								description={
									<Localized id="group-user-organisations-loading-error-descr">
										Something has gone wrong, and we cannot load organisations
									</Localized>
								}
								columnNumber={columnNumber}
								onReload={retryFetching}
							/>
						)}
						{pageFetchStatus === "succeeded" && organisations.length === 0 && (
							<NoSearchResultsState
								columnNumber={columnNumber}
								title={
									<Localized id="group-user-organisations-no-organisations">
										No organisations
									</Localized>
								}
								description={
									criteria === initialOrgSearchCriteria ? (
										<Localized id="group-user-organisations-no-organisations-descr">
											There are no organisations for the user
										</Localized>
									) : (
										<Localized id="group-user-organisations-no-results-descr">
											No organisations were found matching your search criteria.
											Try to adjust filters
										</Localized>
									)
								}
							/>
						)}
						{pageFetchStatus !== "failed" &&
							organisations.length > 0 &&
							organisations.map((o, index) => {
								const labelId = `table-checkbox-${index}`;
								return (
									<TableRow hover key={o.name} tabIndex={-1}>
										<TableCell component="th" id={labelId} scope="row">
											<DualTextLabel
												primaryText={o.displayName}
												secondaryText={o.name}
											/>
										</TableCell>
										<TableCell>
											<UserRoleChips roles={o.roles} align="right" />
										</TableCell>
										<TableCell padding="checkbox">
											<IconButtonWithTooltip
												tooltipTitle={
													<Localized id="group-user-organisations-action-expand">
														Expand
													</Localized>
												}
												aria-label={l10n.getString(
													"group-user-organisations-action-expand"
												)}
												onClick={() => props.onExpand(o.name)}
											>
												<FullscreenIcon />
											</IconButtonWithTooltip>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box display="flex" justifyContent="flex-end" alignItems="center" ml={2}>
				<TablePagination
					onPageChange={fetchRelatedPage}
					pageSize={orgsPerPage}
					onPageSizeChange={setOrgsPerPage}
					first={Boolean(orgsPage.request.first)}
					last={Boolean(orgsPage.request.last)}
					next={Boolean(orgsPage.request.next)}
					previous={Boolean(orgsPage.request.previous)}
					label={
						<Localized id="group-organisations-per-page">
							Organisations per page
						</Localized>
					}
					disabled={pageFetchStatus !== "succeeded"}
				/>
			</Box>

			<Menu
				open={Boolean(addRoleMenu)}
				anchorEl={addRoleMenu}
				onClose={() => setAddRoleMenu(null)}
			>
				<MenuItem
					onClick={() => {
						setAddRoleMenu(null);
						enrolInCourse([userId]);
					}}
				>
					<Localized id="group-user-organisations-action-enrol">
						Enrol in a course
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAddRoleMenu(null);
						assignToCourses([userId]);
					}}
				>
					<Localized id="group-user-organisations-action-assign-to-courses">
						Assign to courses
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAddRoleMenu(null);
						grantAdmin([userId], props.userName);
					}}
				>
					<Localized id="group-user-organisations-action-grant-admin">
						Grant administrator role
					</Localized>
				</MenuItem>
			</Menu>

			{enrolInCourseDialog}
			{assignToCoursesDialog}
			{grantAdminDialog}
		</>
	);
}

function CoursesInOrganisation(props: {
	userId: number;
	userName: string;
	organisation: UserGroupOrganisationSearchResult;
	memberOfMultipleOrganisations: boolean;
	onCollapse: () => void;
	onRolesUpdated: () => void;
	onDelete: () => void;
}) {
	const {userId, organisation, onRolesUpdated} = props;

	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	function withAdminRoleUpdate(
		action: (name: string, ids: number[]) => Promise<void>
	) {
		return async function () {
			try {
				await action(organisation.name, [userId]);
				onRolesUpdated();
			} catch {
				showSnackbar("error", l10n.getString("error-general"));
			}
		};
	}

	async function deleteUser() {
		try {
			await adminService.deleteUsers(organisation.name, [userId]);
			props.onDelete();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	function confirmGrantAdmin() {
		openConfirmationDialog({
			title: (
				<Localized id="group-user-organisation-confirm-grant-admin-title">
					Grant administrator role?
				</Localized>
			),
			description: (
				<Localized
					id="group-user-organisation-confirm-grant-admin-descr"
					vars={{organisation: organisation.displayName, user: props.userName}}
				>
					Are you sure you want the user to be an administrator in organisation?
				</Localized>
			),
			confirmBtnText: (
				<Localized id="group-user-organisation-confirm-grant-admin-action-confirm">
					Grant
				</Localized>
			),
			onConfirm: withAdminRoleUpdate(adminService.grantAdminRigts),
		});
	}

	function confirmRevokeAdmin() {
		openConfirmationDialog({
			title: (
				<Localized id="group-user-organisation-confirm-revoke-admin-title">
					Revoke administrator role?
				</Localized>
			),
			description: (
				<Localized
					id="group-user-organisation-confirm-revoke-admin-descr"
					vars={{organisation: organisation.displayName, user: props.userName}}
				>
					Are you sure you want the user to no more be an administrator in
					organisation?
				</Localized>
			),
			confirmBtnText: (
				<Localized id="group-user-organisation-confirm-revoke-admin-action-confirm">
					Revoke
				</Localized>
			),
			onConfirm: withAdminRoleUpdate(adminService.revokeAdminRights),
		});
	}

	function confirmDelete() {
		openConfirmationDialog({
			title: (
				<Localized id="group-user-organisation-confirm-delete-title">
					Delete user?
				</Localized>
			),
			description: (
				<>
					<DialogContentText>
						<Localized
							id="group-user-organisation-confirm-delete-descr"
							vars={{
								user: props.userName,
								organisation: organisation.displayName,
							}}
						>
							You are going to remove user from organisation. All his data will
							be deleted as well, although courses and course content which he
							created will be retained.
						</Localized>
					</DialogContentText>
					{!props.memberOfMultipleOrganisations && (
						<DialogContentText>
							<Localized
								id="group-user-organisation-confirm-delete-descr-notice"
								elems={{bold: <span style={{fontWeight: 500}}></span>}}
							>
								<>
									Attention: the user has no other organisations in the group,
									so you will be no more able to manage his account. In
									addition, his account will be permanently deleted from the
									system if he also is not a member of any organisation outside
									the group.
								</>
							</Localized>
						</DialogContentText>
					)}
				</>
			),
			confirmBtnText: (
				<Localized id="group-user-organisation-confirm-delete-action-confirm">
					Delete
				</Localized>
			),
			onConfirm: deleteUser,
		});
	}

	return (
		<>
			<UserCourses
				topPanel={
					<SummaryOrganisationRow
						organisation={organisation}
						onCollapse={props.onCollapse}
						onGrantAdmin={confirmGrantAdmin}
						onRevokeAdmin={confirmRevokeAdmin}
						onDelete={confirmDelete}
					/>
				}
				userId={userId}
				organisationName={organisation.name}
				onRolesUpdated={props.onRolesUpdated}
			/>

			{confirmationDialog}
		</>
	);
}

const useRowStyles = makeStyles(() =>
	createStyles({
		noBottomBorder: {
			"& > td": {
				borderBottom: 0,
			},
			"& > th": {
				borderBottom: 0,
			},
		},
	})
);

const useSummaryStyles = makeStyles((theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(1),
		},
		menu: {
			minWidth: theme.spacing(14),
		},
		menuDivider: {
			margin: theme.spacing(1, 0),
		},
	})
);

function SummaryOrganisationRow(props: {
	organisation: UserGroupOrganisationSearchResult;
	onCollapse: () => void;
	onGrantAdmin: () => void;
	onRevokeAdmin: () => void;
	onDelete: () => void;
}) {
	const {organisation: o} = props;

	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

	const {l10n} = useLocalization();

	const classes = useSummaryStyles();
	const rowClasses = useRowStyles();

	function closeMenu() {
		setMenuAnchor(null);
	}

	const admin = o.roles.includes(UserRole.Administrator);

	return (
		<Table stickyHeader className={classes.root}>
			<TableHead>
				<TableRow className={rowClasses.noBottomBorder}>
					<TableCell>
						<DualTextLabel primaryText={o.displayName} secondaryText={o.name} />
					</TableCell>
					<TableCell>
						<UserRoleChips roles={o.roles} align="right" />
					</TableCell>
					<TableCell padding="checkbox">
						<IconButtonWithTooltip
							tooltipTitle={
								<Localized id="group-user-organisations-actions-label">
									Actions
								</Localized>
							}
							aria-label={l10n.getString(
								"group-user-organisations-actions-label"
							)}
							onClick={(e) => {
								setMenuAnchor(e.currentTarget);
							}}
						>
							<MoreVertIcon />
						</IconButtonWithTooltip>
					</TableCell>
					<TableCell padding="checkbox">
						<IconButtonWithTooltip
							tooltipTitle={
								<Localized id="group-user-organisations-action-collapse">
									Collapse
								</Localized>
							}
							aria-label={l10n.getString(
								"group-user-organisations-action-collapse"
							)}
							onClick={props.onCollapse}
						>
							<FullscreenExitIcon />
						</IconButtonWithTooltip>
					</TableCell>
				</TableRow>
			</TableHead>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				PaperProps={{className: classes.menu}}
				onClose={closeMenu}
			>
				{!admin && (
					<MenuItem
						onClick={() => {
							closeMenu();
							props.onGrantAdmin();
						}}
					>
						<Localized id="group-user-organisations-action-grant-admin">
							Grant administrator role
						</Localized>
					</MenuItem>
				)}
				{admin && (
					<MenuItem
						onClick={() => {
							closeMenu();
							props.onRevokeAdmin();
						}}
					>
						<Localized id="group-user-organisations-action-revoke-admin">
							Revoke administrator role
						</Localized>
					</MenuItem>
				)}

				<Divider component="li" className={classes.menuDivider} />

				<MenuItem
					onClick={() => {
						closeMenu();
						props.onDelete();
					}}
				>
					<Localized id="group-user-organisations-action-delete">
						Delete
					</Localized>
				</MenuItem>
			</Menu>
		</Table>
	);
}

export default GroupUserOrganisations;
