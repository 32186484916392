import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import type {DraggableProvidedDragHandleProps} from "react-beautiful-dnd";
import {useTheme} from "@mui/material";

const DragIndicator = (props: {
	dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
	className: string;
}): JSX.Element => {
	const theme = useTheme();

	return (
		<span
			className={props.className}
			style={{display: "flex"}}
			{...props.dragHandleProps}
		>
			<DragIndicatorIcon style={{color: theme.palette.text.secondary}} />
		</span>
	);
};

export default DragIndicator;
