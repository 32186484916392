import {createAsyncThunk} from "@reduxjs/toolkit";

import widgetService from "../services/widgetService";
import type DashboardName from "./DashboardName";
import {keyProvider} from "../keyProvider";
import type {FollowUpWidgetKey, MyCornerWidgetKey} from "./WidgetKey";
import type WidgetSettings from "./WidgetSettings";

type ThunkResult = {
	dashboardName: "follow-up" | "my_corner";
	courseKey: string;
	widgetSettings: {[key: string]: WidgetSettings};
} & (
	| {
			dashboardName: "follow-up";
			widgetKeys: FollowUpWidgetKey[];
	  }
	| {
			dashboardName: "my_corner";
			widgetKeys: MyCornerWidgetKey[];
	  }
);

const fetchWidgets = createAsyncThunk<
	ThunkResult,
	{dashboardName: DashboardName; userId: number; courseId: number}
>(
	"widgets/fetch",
	async ({dashboardName, userId, courseId}): Promise<ThunkResult> => {
		const widgets = await widgetService.getWidgets(
			userId,
			courseId,
			dashboardName
		);

		const widgetKeys = widgets.map((w) => w.name);

		const widgetSettings: {[key: string]: WidgetSettings} = {};
		widgets.forEach((w) => {
			widgetSettings[keyProvider.widgetSettings(courseId, w.name)] = w.settings;
		});

		return {
			courseKey: keyProvider.course(courseId),
			dashboardName,
			widgetKeys,
			widgetSettings,
		} as ThunkResult;
	}
);

export default fetchWidgets;
