import {useTheme} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {pushElementToViewport} from "../../helpers/scrollHelpers";
import selectChapterExercise from "../../store/chapterExercises/selectChapterExercise";
import {useAppSelector} from "../../store/hooks";

export enum SidebarType {
	None,
	AskTutor,
	Answer,
}

function noop() {
	return;
}

const SidebarContext = React.createContext<{
	type: SidebarType;
	chapterExerciseKey: string;
	fullscreen: boolean;
	open: (sidebarType: SidebarType, chapterExerciseKey: string) => void;
	close: () => void;
	toggleFullscreen: () => void;
}>({
	type: SidebarType.None,
	chapterExerciseKey: "",
	fullscreen: false,
	open: noop,
	close: noop,
	toggleFullscreen: noop,
});

export function SidebarContextProvider(props: {
	children: React.ReactElement | React.ReactElement[];
}): JSX.Element {
	const [type, setType] = useState(SidebarType.None);
	const [chapterExerciseKey, setChapterExerciseKey] = useState("");
	const [fullscreen, setFullscreen] = useState(false);

	const exerciseId = useAppSelector((state) =>
		chapterExerciseKey ? selectChapterExercise(state, chapterExerciseKey).id : 0
	);

	const theme = useTheme();
	const exerciseTitleAnchorMargin = parseInt(theme.spacing(-45));

	const open = useCallback(
		(sidebarType: SidebarType, key: string) => {
			if (sidebarType !== type) {
				setType(sidebarType);
			}
			if (chapterExerciseKey !== key) {
				setChapterExerciseKey(key);
			}
		},
		[chapterExerciseKey, type]
	);

	const close = useCallback(() => {
		setChapterExerciseKey("");
		setType(SidebarType.None);
	}, []);

	useEffect(() => {
		if (exerciseId) {
			pushElementToViewport(`subsection-${exerciseId}`, {
				bottom: exerciseTitleAnchorMargin,
			});
		}
	}, [exerciseId, exerciseTitleAnchorMargin]);

	const toggleFullscreen = useCallback(() => {
		setFullscreen((prev) => !prev);
	}, []);

	const value = useMemo(
		() => ({
			open,
			close,
			type,
			chapterExerciseKey,
			fullscreen,
			toggleFullscreen,
		}),
		[open, close, type, chapterExerciseKey, fullscreen, toggleFullscreen]
	);

	return (
		<SidebarContext.Provider value={value}>
			{props.children}
		</SidebarContext.Provider>
	);
}

export default SidebarContext;
