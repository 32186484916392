import {AlertColor} from "@mui/material";
import {useCallback} from "react";
import {useAppDispatch} from "../hooks";
import eventOccurred from "./eventOccurred";
import NotificationAction from "./NotificationAction";

export type ShowSnackbar = (
	severity: AlertColor,
	message: string,
	options?: {action?: NotificationAction}
) => void;

const useSnackbar = (): ShowSnackbar => {
	const dispatch = useAppDispatch();

	const show = useCallback(
		(
			severity: AlertColor,
			message: string,
			options?: {action?: NotificationAction}
		) => {
			dispatch(
				eventOccurred({
					message,
					severity,
					options,
				})
			);
		},
		[dispatch]
	);

	return show;
};

export default useSnackbar;
