import {createAsyncThunk, unwrapResult} from "@reduxjs/toolkit";
import type {EditableExercise} from "../services/dtos/EditableExercise";
import {sectionService} from "../services/sectionService";
import {AppDispatch, RootState} from "../store";
import type {DraftContent} from "./exericseDraftSlice";
import {saveExercise} from "./saveExercise";

type ExerciseDraftParams = {
	courseId: number;
	chapterId: number;
	sectionId: number;
	exercise: EditableExercise;
};

export const saveExerciseDraft = createAsyncThunk<
	DraftContent,
	ExerciseDraftParams,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>(
	"exercise/saveExerciseDraft",
	async ({courseId, exercise, chapterId, sectionId}, thunkApi) => {
		const draft = thunkApi.getState().ui.exerciseDraft.draft;

		if (!draft) {
			throw new Error("Incorrect operation.");
		}

		const draftContent = {...draft};

		const result = await thunkApi.dispatch(
			saveExercise({courseId, exercise, exerciseId: draft.id})
		);

		const {exerciseId: id} = unwrapResult(result);

		if (draft.id === 0) {
			sectionService.addExercises(courseId, chapterId, sectionId, [
				{id, type: exercise.type},
			]);

			draftContent.id = id;
		}

		return draftContent;
	}
);
