import {Container, SxProps} from "@mui/material";
import type {Theme} from "@mui/material";
import React from "react";

import useNavBarHeight from "../hooks/useNavBarHeight";

const PageContainer = (props: {
	children: NonNullable<React.ReactNode>;
	sx?: SxProps<Theme>;
}): JSX.Element => {
	const navBarHeight = useNavBarHeight();

	return (
		<Container
			maxWidth="md"
			sx={[
				{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: `calc(100vh - ${navBarHeight}px)`,
				},
				...(Array.isArray(props.sx) ? props.sx : [props.sx]),
			]}
		>
			{props.children}
		</Container>
	);
};

export default PageContainer;
