import SettingsIcon from "@mui/icons-material/Settings";
import {
	AppBar,
	Box,
	IconButton,
	Slide,
	Tabs,
	Toolbar,
	Typography,
	useScrollTrigger,
} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";
import type {ReactNode} from "react";
import {Link} from "react-router-dom";

import BackButton from "./BackButton";

const useStyles = makeStyles((theme) =>
	createStyles({
		appbar: {
			zIndex: theme.zIndex.drawer + 1,
			top: theme.mixins.toolbar.minHeight,
		},
		title: {
			color: theme.palette.primary.contrastText,
		},
		subtitle: {
			color: alpha(theme.palette.primary.contrastText, 0.7),
			marginTop: theme.spacing(-0.5),
		},
		icon: {
			marginLeft: theme.spacing(0.5),
			color: theme.palette.primary.contrastText,
		},
		pageHeaderToolbar: {
			minHeight: "56px",
		},
	})
);

interface Props {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	settingsUrl?: string;
	children?: ReactNode | ReactNode[];
	selectedTab?: string | boolean;
	backButtonDisabled?: boolean;
}

function HideOnScroll(props: {children: React.ReactElement}) {
	const {children} = props;
	const trigger = useScrollTrigger();

	return (
		<Slide appear={false} in={!trigger}>
			{children}
		</Slide>
	);
}

const SubtitleNav = (props: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<>
			<HideOnScroll>
				<AppBar className={classes.appbar} id="subtitle-nav-bar">
					<Toolbar className={classes.pageHeaderToolbar}>
						<Box
							display="flex"
							alignItems="center"
							color="primary"
							overflow="hidden"
						>
							<BackButton disabled={props.backButtonDisabled} />

							<Box ml={2} overflow="hidden">
								<Typography className={classes.title} variant="h6" noWrap>
									{props.title}
								</Typography>
								{props.subtitle && (
									<Typography
										className={classes.subtitle}
										variant="subtitle2"
										noWrap
									>
										{props.subtitle}
									</Typography>
								)}
							</Box>

							{props.settingsUrl && (
								<IconButton
									className={classes.icon}
									component={Link}
									to={props.settingsUrl}
								>
									<SettingsIcon />
								</IconButton>
							)}
						</Box>
					</Toolbar>

					{props.children && (
						<Toolbar>
							<Tabs
								value={props.selectedTab}
								variant="scrollable"
								indicatorColor="secondary"
								textColor="inherit"
							>
								{props.children}
							</Tabs>
						</Toolbar>
					)}
				</AppBar>
			</HideOnScroll>

			<Toolbar className={classes.pageHeaderToolbar} />
			{props.children && <Toolbar />}
		</>
	);
};

export default SubtitleNav;
