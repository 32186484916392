import {createSelector} from "@reduxjs/toolkit";

import selectChapter from "../chapters/selectChapter";
import SubmissionMode from "../chapters/SubmissionMode";
import {CompletionStatus} from "../outcomes/ExerciseOutcomes";
import selectAllChapterSessionOutcomes from "./selectAllChapterSessionOutcomes";
import selectChapterSession from "./selectChapterSession";
import selectChapterSessionContentStats from "./selectChapterSessionContentStats";
import type {RootState} from "../store";

type SessionStatus = "submitted" | "ongoing" | "unknown";

const selectChapterSessionStatus = createSelector(
	[
		(state: RootState, chapterKey: string) => selectChapter(state, chapterKey),
		(state: RootState, chapterKey: string, sessionKey: string) =>
			selectChapterSession(state, sessionKey),
		(state: RootState, chapterKey: string, sessionKey: string) =>
			selectAllChapterSessionOutcomes(state, sessionKey),
		(state: RootState, chapterKey: string, sessionKey: string) =>
			selectChapterSessionContentStats(state, sessionKey),
	],
	(chapter, session, sessionOutcomes, stats): SessionStatus => {
		if (!chapter || !session) {
			return "unknown";
		}

		if (chapter.submissionMode === SubmissionMode.Simultaneous) {
			return session.submissionTime ? "submitted" : "ongoing";
		}

		const [outcomes, outcomesFetchStatus] = sessionOutcomes;

		if (outcomesFetchStatus !== "succeeded") {
			return "unknown";
		}

		const [numberOfExercises] = stats;

		const numberOfCompletedExercises = outcomes.reduce(
			(prev, curr) =>
				curr.completionStatus === CompletionStatus.Complete ? ++prev : prev,
			0
		);

		if (numberOfCompletedExercises === numberOfExercises) {
			return "submitted";
		}

		return "ongoing";
	}
);
export default selectChapterSessionStatus;
