import {createSelector} from "@reduxjs/toolkit";

import type AnswerVisibility from "./AnswerVisibility";
import type Exercise from "../exercises/Exercise";
import type ChapterExerciseEntity from "../chapterExercises/ChapterExercise";
import type {ExerciseWithContent} from "../exercises/Exercise";
import type {RootState} from "../store";
import selectChapterSessionExercise from "../chapterSessions/selectChapterSessionExercise";

export type ChapterExercise = Exercise & {
	chapterId: number;
	number: number;
	maxScore: number;
	answerVisibility?: AnswerVisibility;
};

export type ChapterExerciseWithContent = ExerciseWithContent & {
	chapterId: number;
	number: number;
	maxScore: number;
	answerVisibility?: AnswerVisibility;
};

function selectChapterExercise(state: RootState, key: string) {
	return state.chapterExercises.byKey[key];
}

function selectExercise(
	state: RootState,
	key: string
): Exercise | ExerciseWithContent | undefined {
	const exerciseKey = selectChapterExercise(state, key)?.exercise;

	return exerciseKey ? state.exercises.byKey[exerciseKey] : undefined;
}

function makeSelectChapterExercise(): (
	state: RootState,
	key: string
) => ChapterExercise | null {
	return createSelector(
		[selectChapterExercise, selectExercise],
		prepareChapterExercise
	);
}

function makeSelectChapterExerciseWithContent(): (
	state: RootState,
	key: string
) => ChapterExerciseWithContent | null {
	return createSelector(
		[selectChapterExercise, selectExercise],
		prepareChapterExerciseWithContent
	);
}

function selectSessionExercise(
	state: RootState,
	key: string
): Exercise | ExerciseWithContent | undefined {
	const exerciseKey = selectChapterSessionExercise(state, key)?.exercise;

	return exerciseKey ? state.exercises.byKey[exerciseKey] : undefined;
}

function makeSelectChapterSessionExercise(): (
	state: RootState,
	key: string
) => ChapterExercise | null {
	return createSelector(
		[selectChapterSessionExercise, selectSessionExercise],
		prepareChapterExercise
	);
}

function makeSelectChapterSessionExerciseWithContent(): (
	state: RootState,
	key: string
) => ChapterExerciseWithContent | null {
	return createSelector(
		[selectChapterSessionExercise, selectSessionExercise],
		prepareChapterExerciseWithContent
	);
}

function prepareChapterExerciseWithContent(
	chapterExercise?: ChapterExerciseEntity | null,
	exercise?: Exercise | ExerciseWithContent
) {
	if (!(chapterExercise && exercise && "question" in exercise)) {
		return null;
	}

	return {
		...exercise,
		chapterId: chapterExercise.chapterId,
		number: chapterExercise.number,
		maxScore: chapterExercise.maxScore ?? 0,
		answerVisibility: chapterExercise.answerVisibility,
	};
}

function prepareChapterExercise(
	chapterExercise?: ChapterExerciseEntity | null,
	exercise?: Exercise | ExerciseWithContent
): ChapterExercise | null {
	if (!chapterExercise || !exercise) {
		return null;
	}

	const e: ChapterExercise = {
		id: exercise.id,
		type: exercise.type,
		title: exercise.title,

		difficultyLevel: exercise.difficultyLevel,

		chapterId: chapterExercise.chapterId,
		number: chapterExercise.number,
		maxScore: chapterExercise.maxScore ?? 0,
		answerVisibility: chapterExercise.answerVisibility,
	};

	if (exercise.authorId) {
		e.authorId = exercise.authorId;
	}

	if (exercise.originId) {
		e.originId = exercise.originId;
	}

	return e;
}

export {
	makeSelectChapterExerciseWithContent,
	makeSelectChapterSessionExerciseWithContent,
	makeSelectChapterExercise,
	makeSelectChapterSessionExercise,
	prepareChapterExercise,
};
