import {AxiosError} from "axios";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {axiosInstance} from "../services/axiosInstance";

const RedirectOnForbiddenError = (props: {to: string}): JSX.Element => {
	const history = useHistory();

	useEffect(() => {
		const errorHandler = createErrorHandler(props.to, history.push);

		const id = axiosInstance.interceptors.response.use(
			(response) => response,
			errorHandler
		);

		return () => {
			axiosInstance.interceptors.response.eject(id);
		};
	}, [history.push, props.to]);

	return <></>;
};

function createErrorHandler(path: string, redirectTo: (path: string) => void) {
	return async function (error: AxiosError) {
		if (error.response?.status === 403) {
			redirectTo(path);
		}

		return Promise.reject(error);
	};
}

export default RedirectOnForbiddenError;
