import {createAsyncThunk} from "@reduxjs/toolkit";
import {normalize, schema} from "normalizr";

import Chapter from "./Chapter";
import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";
import * as dto from "../services/dtos/Chapter";

export const getChapters = createAsyncThunk(
	"courses/getChapters",
	async (courseId: number) => {
		const chapters = await courseService.getChaptersFromCourse(courseId);

		chapters.sort((a: dto.Chapter, b: dto.Chapter) => a.number - b.number);

		const normalized = normalize<unknown, {chapters: {[key: string]: Chapter}}>(
			{
				courseId: courseId,
				chapters: chapters,
			},
			chaptersArray
		);

		const entities =
			chapters.length === 0 ? {chapters: {}} : normalized.entities;

		return {
			result: {
				courseKey: keyProvider.course(courseId),
				chapters: normalized.result.chapters,
			},
			entities,
		};
	}
);

const chapter = new schema.Entity<dto.Chapter>(
	"chapters",
	{},
	{
		idAttribute: (value: dto.Chapter) => keyProvider.chapter(value.id),
		processStrategy: (
			value: dto.Chapter,
			parent: {
				courseId: number;
				chapters: dto.Chapter[];
			}
		) => {
			return {...value, courseId: parent.courseId};
		},
	}
);

const chaptersArray = new schema.Object({
	chapters: [chapter],
});
