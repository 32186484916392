import React from "react";
import {MultiFeedbackSubmitted} from "../../../../store/studentResponses/Feedback";
import ResponseFeedback from "../ResponseFeedback";

function createChoiceFeedback(
	choiceId: number,
	feedback: MultiFeedbackSubmitted
): JSX.Element {
	const choiceFeedback = feedback.perChoice.find(
		(ch) => ch.choiceId === choiceId
	);

	if (!choiceFeedback) {
		return <></>;
	}

	return (
		<ResponseFeedback
			comment={choiceFeedback.message}
			state={choiceFeedback.correctness}
		/>
	);
}

export default createChoiceFeedback;
