import {unwrapResult} from "@reduxjs/toolkit";
import React, {useCallback, useRef} from "react";
import buildProgExercise from "../../store/exercises/prog/buildExercise";
import ExerciseType from "../../store/exercises/ExerciseType";

import {saveExercise} from "../../store/exercises/saveExercise";
import {useAppDispatch} from "../../store/hooks";
import type {
	EditableExercise,
	ProgExercise,
} from "../../store/services/dtos/EditableExercise";
import type ExerciseEditorProps from "../learningMaterial/ExerciseEditorProps";
import ExternalExerciseEditor from "../learningMaterial/ExternalExerciseEditor";
import MathExerciseEditor from "../learningMaterial/MathExerciseEditor";
import MultiExerciseEditor from "../learningMaterial/MultiExerciseEditor";
import OpenExerciseEditor from "../learningMaterial/OpenExerciseEditor";
import ProgExerciseEditor from "../learningMaterial/ProgExerciseEditor";
import exampleOutputChanged from "../../store/exercises/prog/exampleOutputChanged";

type ExerciseCreation = {
	organisationName: string;
	courseId: number;
	exerciseType: ExerciseType;
	onClose: (
		exerciseId: number | null,
		updated: EditableExercise | null
	) => void;
};

type ExerciseEditing = {
	organisationName: string;
	courseId: number;
	exerciseId: number;
	exercise: EditableExercise;
	onClose: (
		exerciseId: number | null,
		updated: EditableExercise | null
	) => void;
};

type ExerciseBankEditorProps = ExerciseCreation | ExerciseEditing;

function editing(props: ExerciseBankEditorProps): props is ExerciseEditing {
	return (props as ExerciseEditing).exerciseId !== undefined;
}

const ExerciseEditor = (props: ExerciseBankEditorProps): JSX.Element => {
	const {courseId, onClose} = props;

	const exerciseId = useRef<number | null>(
		editing(props) ? props.exerciseId : null
	);

	const updatedExercise = useRef<EditableExercise | null>(
		editing(props) ? props.exercise : null
	);

	const exerciseType = editing(props)
		? props.exercise.type
		: props.exerciseType;

	const dispatch = useAppDispatch();

	const changeExampleOutput = useCallback(
		(exerciseId: number, exampleOutput: string) => {
			dispatch(
				exampleOutputChanged({
					exerciseId,
					exampleOutput,
				})
			);

			if (updatedExercise.current?.type === ExerciseType.Prog) {
				updatedExercise.current = {
					...updatedExercise.current,
					exampleOutput,
				};
			}
		},
		[dispatch]
	);

	async function save(exercise: EditableExercise) {
		const args = exerciseId.current
			? {courseId, exercise, exerciseId: exerciseId.current}
			: {courseId, exercise};
		const result = await dispatch(saveExercise(args));

		const {exerciseId: id} = unwrapResult(result);

		exerciseId.current = id;
		updatedExercise.current = exercise;

		return id;
	}

	const editorProps = {
		organisationName: props.organisationName,
		courseId,
		exerciseId: exerciseId.current ?? 0,
		onClose: () => onClose(exerciseId.current, updatedExercise.current),
		onSave: save,
		exercise: editing(props) ? props.exercise : null,
	};

	if (exerciseType === ExerciseType.Prog) {
		const build = async (exerciseId: number, exercise: ProgExercise) => {
			const result = await dispatch(
				buildProgExercise({
					courseId,
					exerciseId,
					exercise,
				})
			);

			const {buildResult} = unwrapResult(result);

			updatedExercise.current = exercise;

			return buildResult;
		};

		return (
			<ProgExerciseEditor
				{...editorProps}
				onBuild={build}
				onExampleOutputChanged={changeExampleOutput}
			/>
		);
	}

	const Editor = editors[exerciseType];

	return <>{Editor && <Editor {...editorProps} />}</>;
};

const editors: {
	[key in ExerciseType]?: (props: ExerciseEditorProps) => JSX.Element;
} = {
	[ExerciseType.Math]: MathExerciseEditor,
	[ExerciseType.Multi]: MultiExerciseEditor,
	[ExerciseType.Open]: OpenExerciseEditor,
	[ExerciseType.External]: ExternalExerciseEditor,
};

export default ExerciseEditor;
