import {Localized, useLocalization} from "@fluent/react";
import type {ReactLocalization as Localization} from "@fluent/react";
import {Box, Button, Divider, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useMemo, useRef, useState} from "react";
import type {ReactNode} from "react";

import type {ProgInteractions} from "../../../../store/exercises/Interactions";
import CodeEditor from "../../../../utils/CodeEditor";
import AdditionalActions from "../AdditionalActions";
import ExerciseExpandablePanel from "../ExerciseExpandablePanel";
import SubmitButton from "../../../../utils/SubmitButton";
import SubmitResultCard from "../SubmitResultCard";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import type ProgFile from "../../../../store/studentResponses/ProgFile";
import {describeError, describeStrictness} from "./descriptions";
import TestResultPanel from "./TestResultPanel";
import ProgramOutput from "./ProgramOutput";
import {countLines} from "../../../../helpers/fileHelpers";
import type {OpenConfirmationDialog} from "../../../../hooks/useConfirmationDialog";
import type Feedback from "../../../../store/studentResponses/Feedback";
import {
	ProgFeedback,
	ProgramRunOutput,
	compilationError,
} from "../../../../store/studentResponses/Feedback";
import type {ProgResponse} from "../../../../store/studentResponses/Response";
import type {
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import type {CodeEditorApi} from "../../../../utils/CodeEditor/CodeEditor";
import DbSchemaAndState from "./DbSchemaAndState";
import SqlResultPanel from "./SqlResultPanel";
import type {SupportedLanguage} from "../../../../store/services/dtos/EditableExercise";

const useStyles = makeStyles((theme) =>
	createStyles({
		bold: {
			fontWeight: 500,
		},
		strictness: {
			display: "block",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(4),
		},
		resetBtn: {
			color: theme.palette.error.main,
		},
		divider: {
			margin: theme.spacing(2, 0),
		},
	})
);

const errorLineColor = "#ff7777ab";

const ResponseArea = (props: {
	interactions: ProgInteractions;
	response: ProgResponse | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting?: boolean;

	additionalActions?: ReactNode | ReactNode[];

	onSave: (
		response: ResponseToSave,
		successMessage?: string
	) => Promise<Feedback>;
	onSubmit?: (response: ResponseToSubmit) => Promise<unknown>;

	openConfirmDialog: OpenConfirmationDialog;
}): JSX.Element => {
	const classes = useStyles();

	const {onSubmit, interactions, response} = props;

	const [initialFiles, setInitialFiles] = useState<ProgFile[]>([]);

	const [saving, setSaving] = useState(false);
	const [running, setRunning] = useState(false);

	const [progFeedback, setProgFeedback] = useState<ProgFeedback | null>(null);

	const testResult =
		progFeedback && "result" in progFeedback ? progFeedback.result : null;

	const {l10n} = useLocalization();

	useEffect(() => {
		setInitialFiles(
			createInitialFiles(interactions.files, response?.files ?? [], l10n)
		);
	}, [interactions.files, l10n, response]);

	const codeEditor = useRef<CodeEditorApi | null>(null);

	const highlightings = useMemo(
		() =>
			testResult &&
			compilationError(testResult) &&
			testResult.line &&
			testResult.file
				? {
						[testResult.file]: [
							{
								ranges: [
									{
										from: testResult.line - 1,
										to: testResult.line + 1,
									},
								],
								color: errorLineColor,
							},
						],
				  }
				: {},
		[testResult]
	);

	const resetResponse = async () => {
		await save(
			interactions.files.map((f) => ({
				name: f.name,
				content: "",
			}))
		);

		setInitialFiles(createInitialFiles(interactions.files, [], l10n));
		setProgFeedback(null);
	};

	if (!interactions) {
		return <></>;
	}

	const run = async () => {
		setProgFeedback(null);
		setRunning(true);

		try {
			const res = await props.onSave(
				createResponse(
					prepareResponseFiles(
						interactions.files,
						codeEditor.current?.getFiles() ?? []
					),
					true,
					interactions.language
				)
			);

			setProgFeedback(res as ProgFeedback);
		} finally {
			setRunning(false);
		}
	};

	const submit =
		onSubmit &&
		(async () => {
			if (
				(testResult && !("error" in testResult)) ||
				(!testResult && progFeedback)
			) {
				await onSubmit({
					exerciseType: ExerciseType.Prog,
					files: prepareResponseFiles(
						interactions.files,
						codeEditor.current?.getFiles() ?? []
					),
				});

				setProgFeedback(null);
			}
		});

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || readonly;

	async function save(files: ProgFile[], successMessage?: string) {
		if (
			(testResult && !("error" in testResult)) ||
			(!testResult && progFeedback)
		) {
			setProgFeedback(null);
		}

		setSaving(true);

		try {
			await props.onSave(
				createResponse(files, false, interactions.language),
				successMessage
			);
		} finally {
			setSaving(false);
		}
	}

	function createResultCard() {
		if (!progFeedback) {
			return <></>;
		}

		if (
			progFeedback.status === "incorrect" &&
			testResult &&
			"error" in testResult
		) {
			return (
				<SubmitResultCard
					success={false}
					text={describeError(l10n, testResult.error)}
				/>
			);
		}

		let text = "";

		if (progFeedback.status === "correct") {
			text = l10n.getString(
				"exercise-prog-response-area-correct-solution",
				null,
				"Your solution is working correctly."
			);
		}

		if (submit) {
			text += l10n.getString(
				"exercise-prog-response-area-allow-submit",
				null,
				"You may submit your solution now. After submitting, it won't be possible to edit your solution anymore."
			);
		}

		if (!text) {
			return <></>;
		}

		return (
			<Box>
				<Divider className={classes.divider} />
				<SubmitResultCard success text={text} />
			</Box>
		);
	}

	function createTestResultPanel(result: ProgramRunOutput) {
		if (compilationError(result)) {
			return interactions.language === "sql" ? (
				<SqlResultPanel result={result} />
			) : (
				<TestResultPanel
					result={result}
					strictness={interactions.comparisonStrictness}
				/>
			);
		}

		return interactions.language === "sql" ? (
			<SqlResultPanel result={result.runtimes[0]} />
		) : (
			result.runtimes.map((rt) => (
				<TestResultPanel
					result={rt}
					strictness={interactions.comparisonStrictness}
					key={`test-result-${id()}`}
				/>
			))
		);
	}

	return (
		<>
			{interactions.exampleOutput && (
				<>
					<Typography variant="subtitle1" className={classes.bold}>
						<Localized id="exercise-prog-response-area-example-output">
							Example output:
						</Localized>
					</Typography>

					<ProgramOutput>{interactions.exampleOutput}</ProgramOutput>

					<Typography variant="caption" className={classes.strictness}>
						{describeStrictness(l10n, interactions.comparisonStrictness)}
					</Typography>
				</>
			)}

			{interactions.initialSchemaAndState &&
				interactions.initialSchemaAndState.length > 0 && (
					<>
						<Typography variant="subtitle1" className={classes.bold}>
							<Localized id="exercise-prog-response-area-initial-schema">
								Initial schema and state:
							</Localized>
						</Typography>
						<Box mt={2} mb={3}>
							<DbSchemaAndState schema={interactions.initialSchemaAndState} />
						</Box>
					</>
				)}

			<ExerciseExpandablePanel
				lazyLoading
				summary={
					<Localized id="content-exercise-do">Do the exercise</Localized>
				}
			>
				<Box
					display="flex"
					flexDirection="column"
					style={{gap: 16}}
					flexGrow={1}
					width={1}
				>
					<AdditionalActions>{props.additionalActions}</AdditionalActions>
					<CodeEditor
						ref={codeEditor}
						initialFiles={initialFiles}
						readonly={readonly}
						highlightings={highlightings}
					/>
					<Box display="flex" justifyContent="space-between">
						<Button
							className={classes.resetBtn}
							onClick={() =>
								props.openConfirmDialog({
									title: l10n.getString(
										"exercise-prog-response-area-reset-dialog-title",
										null,
										"Reset response?"
									),
									description: l10n.getString(
										"exercise-prog-response-area-reset-dialog-description",
										null,
										"All previous work on this exercise will be lost."
									),
									confirmBtnText: l10n.getString(
										"exercise-prog-response-area-reset-dialog-button-reset",
										null,
										"Reset"
									),
									onConfirm: resetResponse,
								})
							}
							disabled={running || saving || readonly}
						>
							<Localized id="exercise-prog-response-area-button-reset">
								Reset
							</Localized>
						</Button>
						<Box display="flex">
							<SubmitButton
								variant="text"
								inProgress={saving}
								disabled={running || readonly}
								onClick={() =>
									save(
										prepareResponseFiles(
											interactions.files,
											codeEditor.current?.getFiles() ?? []
										),
										"Saved"
									)
								}
							>
								<Localized id="exercise-prog-response-area-button-save">
									Save
								</Localized>
							</SubmitButton>
							<Box ml={1}>
								<SubmitButton
									inProgress={running}
									onClick={run}
									disabled={submissionDisabled}
								>
									<Localized id="exercise-prog-response-area-button-run">
										Run
									</Localized>
								</SubmitButton>
							</Box>
						</Box>
					</Box>
					{createResultCard()}
					{submit && progFeedback && progFeedback.status !== "incorrect" && (
						<Box display="flex" justifyContent="flex-end" mt={1}>
							<SubmitButton inProgress={props.submitting} onClick={submit}>
								<Localized id="exercise-prog-response-area-button-submit">
									Submit
								</Localized>
							</SubmitButton>
						</Box>
					)}
					{testResult && createTestResultPanel(testResult)}
				</Box>
			</ExerciseExpandablePanel>
		</>
	);
};

function createResponse(
	files: ProgFile[],
	test: boolean,
	language: SupportedLanguage
): ResponseToSave {
	return {
		exerciseType: ExerciseType.Prog,
		files: files,
		action: test ? "test" : "save",
		language,
	};
}

type EditorFiles = NonNullable<
	Parameters<typeof CodeEditor>[0]["initialFiles"]
>;

function createInitialFiles(
	exerciseFiles: ProgInteractions["files"],
	responseFiles: ProgResponse["files"],
	l10n: Localization
) {
	return exerciseFiles.map((ef) => {
		const rf = responseFiles.find((f) => f.name === ef.name);

		const f: EditorFiles[0] = {
			name: ef.name,
			content: "",
		};

		if (rf?.content) {
			f.content = rf.content;
		} else if (ef.readOnlyBeginning || ef.readOnlyEnd) {
			const h = l10n.getString(
				"exercise-prog-response-area-hint-put-your-code-here",
				null,
				"Put your code here"
			);

			f.content = "\n" + commentLine(cutExtension(ef.name), h) + "\n\n";
		}

		if (ef.readOnlyBeginning) {
			f.content = ef.readOnlyBeginning + f.content;
			f.readOnlyFromBeginning = countLines(ef.readOnlyBeginning) - 1;
		}

		if (ef.readOnlyEnd) {
			f.content = f.content + ef.readOnlyEnd;
			f.readOnlyFromEnd = countLines(ef.readOnlyEnd);
		}

		return f;
	});
}

function prepareResponseFiles(
	exerciseFiles: ProgInteractions["files"],
	editorFiles: EditorFiles
) {
	return editorFiles.map((f) => {
		const rf = {
			name: f.name,
			content: f.content,
		};

		if (f.readOnlyFromBeginning || f.readOnlyFromEnd) {
			const ef = exerciseFiles.find((ef) => ef.name === f.name);

			rf.content = rf.content.substring(
				ef?.readOnlyBeginning?.length ?? 0,
				rf.content.length - (ef?.readOnlyEnd?.length ?? 0)
			);
		}

		return rf;
	});
}

const commentDelimiters: {
	[key: string]:
		| {singleLine?: string; multiLine?: {start: string; end: string}}
		| undefined;
} = {
	cpp: {
		singleLine: "//",
	},
	c: {
		singleLine: "//",
	},
	cs: {
		singleLine: "//",
	},
	dart: {
		singleLine: "//",
	},
	html: {
		multiLine: {
			start: "<!--",
			end: "-->",
		},
	},
	java: {
		singleLine: "//",
	},
	js: {
		singleLine: "//",
	},
	kt: {
		singleLine: "//",
	},
	php: {
		singleLine: "//",
	},
	py: {
		singleLine: "#",
	},
	rb: {
		singleLine: "##",
	},
	rs: {
		singleLine: "//",
	},
	scala: {
		singleLine: "//",
	},
	sql: {
		singleLine: "--",
	},
};

function commentLine(extension: string, line: string) {
	const def = commentDelimiters[extension];
	if (!def) {
		return line;
	}

	if (def.singleLine) {
		line = def.singleLine + " " + line;
	} else if (def.multiLine) {
		line = def.multiLine.start + " " + line + " " + def.multiLine.end;
	}

	return line;
}

function cutExtension(name: string) {
	let extension = "";

	const i = name.lastIndexOf(".");

	if (i >= 0 && i + 1 < name.length) {
		extension = name.substring(i + 1);
	}

	return extension;
}

let idCounter = 0;
function id() {
	return idCounter++;
}

export default ResponseArea;
