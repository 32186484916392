import {Localized} from "@fluent/react";
import ReplyIcon from "@mui/icons-material/Reply";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useMemo, useState} from "react";

import parseDate from "../../helpers/parseDate";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import AttachmentsList from "../../utils/attachments/AttachmentsList";
import type UploadedFile from "../../utils/attachments/UploadedFile";
import HtmlContent from "../../utils/HtmlContent";
import type FileLoader from "../../utils/attachments/FileLoader";

const useStyles = makeStyles((theme) =>
	createStyles({
		summary: {
			display: "flex",
			flexGrow: 1,
			justifyContent: "space-between",
			gap: theme.spacing(1),
		},
	})
);

const Message = (props: {
	expanded?: boolean;
	avatar: React.ReactElement;
	name: string;
	content: string;
	date?: string;
	fileLoader?: FileLoader;
	onChange: (expanded: boolean) => void;
	onReply?: () => void;
}): JSX.Element => {
	const classes = useStyles();

	const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

	useEffect(() => {
		if (props.fileLoader) {
			props.fileLoader.loadAll().then((files) => {
				setUploadedFiles(files);
			});
		}
	}, [props.fileLoader]);

	return (
		<Accordion
			expanded={props.expanded}
			onChange={(_, expanded) => props.onChange(expanded)}
			square
			elevation={3}
		>
			<AccordionSummary
				style={{display: "flex", flexGrow: 1, justifyContent: "space-between"}}
				classes={{content: classes.summary}}
			>
				{props.expanded ? (
					<>
						<MessageSummary
							name={props.name}
							avatar={props.avatar}
							date={props.date}
						/>
						{props.onReply && <ReplyButton onClick={props.onReply} />}
					</>
				) : (
					<MessagePreview
						name={props.name}
						avatar={props.avatar}
						date={props.date}
						content={props.content}
					/>
				)}
			</AccordionSummary>
			<AccordionDetails>
				<Box
					display="flex"
					flexDirection="column"
					width="100%"
					pl={1}
					pr={1}
					overflow="auto"
				>
					<Typography component="div" variant="body1" color="textPrimary">
						<HtmlContent content={props.content} />
					</Typography>

					{uploadedFiles && uploadedFiles.length > 0 && (
						<>
							<Box mt={3} mb={2}>
								<Divider />
							</Box>
							<Typography
								component="span"
								variant="caption"
								color="textSecondary"
							>
								<Localized id="tutoring-message-attachments">
									Attachments
								</Localized>
							</Typography>
							<Box mt={1}>
								<AttachmentsList items={uploadedFiles} />
							</Box>
						</>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

function ReplyButton(props: {onClick: () => void}) {
	return (
		<Box>
			<Tooltip
				title={<Localized id="tutoring-tutor-reply-btn">Reply</Localized>}
				enterDelay={500}
				enterNextDelay={500}
			>
				<IconButton
					onClick={(event: {stopPropagation: () => void}) => {
						event.stopPropagation();
						props.onClick();
					}}
				>
					<ReplyIcon />
				</IconButton>
			</Tooltip>
		</Box>
	);
}

const MessageSummary = (props: {
	name: string;
	avatar: React.ReactElement;
	date?: string;
}) => {
	const formatDateTime = useDateTimeFormat();

	return (
		<Box display="flex" alignItems="center" pl={1} pr={1}>
			{props.avatar}
			<Box ml={1} display="flex" flexDirection="column">
				<Typography component="span" variant="subtitle1" color="textPrimary">
					{props.name}
				</Typography>
				<Typography component="span" variant="caption" color="textSecondary">
					{props.date && formatDateTime(parseDate(props.date))}
				</Typography>
			</Box>
		</Box>
	);
};

const MessagePreview = (props: {
	name: string;
	avatar: React.ReactElement;
	date?: string;
	content: string;
}) => {
	const formatDateTime = useDateTimeFormat();

	const flattenedContent = useMemo(() => {
		return flatten(props.content);
	}, [props.content]);

	return (
		<Box
			display="flex"
			alignItems="flex-start"
			justifyContent="space-between"
			flexGrow={1}
		>
			<Box display="flex" alignItems="center" pl={1} pr={1}>
				{props.avatar}
				<Box ml={1} display="flex" flexDirection="column">
					<Typography component="span" variant="subtitle1" color="textPrimary">
						{props.name}
					</Typography>
					<Typography
						component="span"
						variant="body2"
						color="textSecondary"
						noWrap
						style={{width: "32vw"}}
					>
						{flattenedContent}
					</Typography>
				</Box>
			</Box>
			<Typography component="span" variant="caption" color="textSecondary">
				{props.date && formatDateTime(parseDate(props.date))}
			</Typography>
		</Box>
	);
};

function flatten(html: string) {
	const div = document.createElement("div");
	div.innerHTML = html;
	return div.textContent || div.innerText || "";
}

export default Message;
