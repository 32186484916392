import {Localized} from "@fluent/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Box, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import type {ReactNode} from "react";
import React from "react";
import {DraggableProvidedDragHandleProps} from "react-beautiful-dnd";
import {Link} from "react-router-dom";

import DragIndicator from "./DragIndicator";
import MenuLink from "./MenuLink";
import {useAppDispatch} from "../../store/hooks";
import {ModalDialogName, openDialog} from "../../store/ui/modalDialogSlice";
import IndexedLabel from "../../utils/IndexedLabel";

export type MenuItem = {
	action: string;
	text: ReactNode;
	link?: string;
	handleDelete?: () => void;
	courseId?: number;
	sectionId?: number;
	chapterId?: number;
	disabled?: boolean;
};

interface Props {
	provided: DraggableProvidedDragHandleProps | undefined | null;
	index: string;
	content: string;
	navigationPath: string;
	menuItems?: MenuItem[];
	active?: boolean;
	viewOnly?: boolean;
	onClick?: () => void;
}

const useStyles = makeStyles<Theme, {active?: boolean}>((theme) =>
	createStyles({
		index: {
			color: ({active}) =>
				active ? theme.palette.secondary.main : theme.palette.text.secondary,
			marginRight: theme.spacing(2),
		},
		label: {
			color: ({active}) =>
				active ? theme.palette.secondary.main : theme.palette.text.primary,
		},
		subitemDragHandle: {
			visibility: "hidden",
		},
		subitem: {
			"&:hover $subitemDragHandle": {
				visibility: "visible",
			},
		},
	})
);

const ChildDraggableComponent = (props: Props): JSX.Element => {
	const classes = useStyles({active: props.active});

	const dispatch = useAppDispatch();

	const [menuAnchor, setMenuAnchor] = React.useState<null | Element>(null);
	const open = Boolean(menuAnchor);

	const closeMenu = () => {
		setMenuAnchor(null);
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="flex-start"
				className={classes.subitem}
				ml={props.viewOnly ? 2.5 : 0}
			>
				<Box py={1.5} display="flex" flexGrow={1}>
					{!props.viewOnly && (
						<DragIndicator
							dragHandleProps={props.provided}
							className={classes.subitemDragHandle}
						/>
					)}
					<Link
						onClick={props.onClick}
						style={{
							textDecoration: "none",
							color: "inherit",
							display: "flex",
							alignItems: "center",
						}}
						to={props.navigationPath}
					>
						<IndexedLabel
							index={props.index}
							text={props.content}
							classes={{
								index: classes.index,
								label: classes.label,
							}}
							variant={"body2"}
						/>
					</Link>
				</Box>
				{!props.viewOnly && (
					<Tooltip
						title={
							<Localized id="dragdrop-actions-button-tooltip">
								Actions
							</Localized>
						}
						enterDelay={500}
						enterNextDelay={500}
					>
						<IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
							<MoreVertIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>

			<Menu
				id="long-menu"
				anchorEl={menuAnchor}
				open={open}
				onClose={closeMenu}
			>
				{props.menuItems?.map((item, itemKey) => {
					if (item.action === "Delete") {
						return (
							<MenuItem
								key={itemKey}
								onClick={() => {
									item.handleDelete && item.handleDelete();
									closeMenu();
								}}
								disabled={item.disabled}
							>
								{item.text}
							</MenuItem>
						);
					}

					if (item.action === "Add exercise") {
						return (
							<MenuItem
								key={itemKey}
								onClick={() => {
									dispatch(
										openDialog({
											dialogName: ModalDialogName.AddExercise,
											dialogParams: {
												courseId: item.courseId || 0,
												sectionId: item.sectionId || 0,
												chapterId: item.chapterId || 0,
											},
										})
									);
									closeMenu();
								}}
							>
								{item.text}
							</MenuItem>
						);
					}

					return (
						<MenuLink key={item.action} href={item.link}>
							<MenuItem onClick={closeMenu}>{item.text}</MenuItem>
						</MenuLink>
					);
				})}
			</Menu>
		</>
	);
};

export default ChildDraggableComponent;
