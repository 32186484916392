import {Localized, useLocalization} from "@fluent/react";
import MoreVert from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	Checkbox,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Toolbar,
	Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";

import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import parseDate from "../../helpers/parseDate";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import studentStatusChanged from "../../store/enrolment/studentStatusChanged";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import enrolmentService from "../../store/services/enrolmentService";
import type {
	EnrolmentApplicationSearchCriteria,
	EnrolmentApplicationSearchResult,
} from "../../store/services/enrolmentService";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import StudentName from "./StudentName";
import useAcceptApplications from "./useAcceptApplications";
import useRejectApplications from "./useRejectApplications";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import {populate} from "../../utils/populate";
import TableHead from "../../utils/tables/Head";
import type {ColumnDefs} from "../../utils/tables/Head";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

function EnrolmentApplicationsWidget(props: {
	courseId: number;
	viewOnly?: boolean;
	onAccepted: () => void;
}) {
	return (
		<Widget id="enrolment-applications">
			<WidgetTitle>
				<Localized id="student-management-enrolment-applications-title">
					Enrolment applications
				</Localized>
			</WidgetTitle>
			<WidgetBody>
				<EnrolmentApplications
					courseId={props.courseId}
					viewOnly={props.viewOnly}
					onAccepted={props.onAccepted}
				/>
			</WidgetBody>
		</Widget>
	);
}

const columnKeys = ["applicant", "applicationDate"] as const;

const columnDefs: ColumnDefs<
	typeof columnKeys[number],
	keyof EnrolmentApplicationSearchResult
> = {
	applicant: {
		name: (
			<Localized id="student-management-enrolment-applications-table-column-name">
				Applicant name
			</Localized>
		),
		sortOptions: [
			{
				field: "firstName",
				label: (
					<Localized id="student-management-enrolment-applications-table-column-name-sort-by-firstname">
						Sort by first name
					</Localized>
				),
			},
			{
				field: "lastName",
				label: (
					<Localized id="student-management-enrolment-applications-table-column-name-sort-by-lastname">
						Sort by last name
					</Localized>
				),
			},
			{
				field: "userName",
				label: (
					<Localized id="student-management-enrolment-applications-table-column-name-sort-by-username">
						Sort by username
					</Localized>
				),
			},
		],
	},
	applicationDate: {
		name: (
			<Localized id="student-management-enrolment-applications-table-column-application-date">
				Application date
			</Localized>
		),
		align: "right",
		width: "12rem",
		sortOptions: [{field: "applicationDate"}],
	},
};

const columns = columnKeys.map((key) => columnDefs[key]);

const initialCriteria: EnrolmentApplicationSearchCriteria = {};

const emptyResult: EnrolmentApplicationSearchResult = {
	id: 0,
	userName: "",
	firstName: "",
	lastName: "",
	applicationDate: "2022-02-02",
};

function EnrolmentApplications(props: {
	courseId: number;
	viewOnly?: boolean;
	onAccepted: () => void;
}) {
	const {courseId} = props;

	const [criteria, setCriteria] = useState(initialCriteria);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val}));
	}, []);

	const [applicationsPerPage, setApplicationsPerPage] = useState(5);

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof EnrolmentApplicationSearchResult
	>("lastName");

	const {
		page: applicationsPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
		reloadPage,
	} = usePaginationState<EnrolmentApplicationSearchResult>();

	const applications = populate(
		applicationsPerPage,
		pageFetchStatus === "succeeded" && applicationsPage?.content
			? applicationsPage.content
			: [],
		() => ({...emptyResult, id: Math.random()})
	);

	const {
		select: selectApplication,
		bulkSelectionCheckbox,
		selected: selectedApplications,
		resetSelection,
	} = useBulkSelection(applicationsPage, (s) => s.id);

	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();
	const dispatch = useAppDispatch();

	const userId = useAppSelector(selectUserId);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const acceptApplications = useAcceptApplications(
		courseId,
		dispatch,
		openConfirmationDialog,
		(applicantIds) => {
			reloadPage(resetSelection);

			props.onAccepted();

			if (applicantIds.includes(userId)) {
				dispatch(studentStatusChanged({courseId}));
			}
		},
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	const rejectApplications = useRejectApplications(
		courseId,
		dispatch,
		openConfirmationDialog,
		() => reloadPage(resetSelection),
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	useEffect(() => {
		fetchFirstPage(
			() =>
				enrolmentService.searchEnrolmentApplications(
					courseId,
					criteria,
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					applicationsPerPage
				),
			resetSelection
		);
	}, [
		applicationsPerPage,
		courseId,
		criteria,
		fetchFirstPage,
		resetSelection,
		sortField,
		sortOrder,
	]);

	function selectedApplication(id: number) {
		return selectedApplications.includes(id);
	}

	const loading = pageFetchStatus === "pending";

	const columnNumber = columns.length + (props.viewOnly ? 0 : 2);

	return (
		<Stack spacing={2} sx={{width: 1}}>
			{selectedApplications.length === 0 && (
				<TableToolbar
					query={criteria.query ?? ""}
					actions={[]}
					onQueryChange={setQuery}
					searchPlaceholder={l10n.getString(
						"student-management-enrolment-applications-search-placeholder"
					)}
				/>
			)}
			{selectedApplications.length > 0 && (
				<Toolbar disableGutters>
					<Stack direction="row" spacing={1}>
						<Button
							variant="outlined"
							onClick={() => acceptApplications(selectedApplications)}
						>
							<Localized id="student-management-enrolment-applications-bulk-action-accept">
								Accept applications
							</Localized>
						</Button>
						<Button
							variant="outlined"
							onClick={() => rejectApplications(selectedApplications)}
						>
							<Localized id="student-management-enrolment-applications-bulk-action-reject">
								Reject applications
							</Localized>
						</Button>
					</Stack>
				</Toolbar>
			)}

			<Table stickyHeader>
				<TableHead
					columns={columns}
					leftAnnex={
						!props.viewOnly && (
							<TableCell padding="checkbox" sx={{zIndex: 3}}>
								{bulkSelectionCheckbox}
							</TableCell>
						)
					}
					rightAnnex={!props.viewOnly && <TableCell padding="checkbox" />}
					sortOrder={sortOrder}
					sortField={sortField}
					onOrderChange={changeOrder}
				/>
				<TableBody sx={{position: "relative"}}>
					{pageFetchStatus === "failed" && (
						<LoadingErrorState
							description={
								<Localized id="student-management-enrolment-applications-loading-error-descr">
									Something has gone wrong, and we cannot load enrolment
									applications
								</Localized>
							}
							columnNumber={columnNumber}
							spanWholeTable
							onReload={retryFetching}
						/>
					)}
					{pageFetchStatus === "succeeded" &&
						applicationsPage.content.length === 0 && (
							<NoSearchResultsState
								columnNumber={columnNumber}
								title={
									<Localized id="student-management-enrolment-applications-no-applications">
										No applications
									</Localized>
								}
								description={
									criteria === initialCriteria ? (
										<Localized id="student-management-enrolment-applications-no-applications-descr">
											There are no pending enrolment applications
										</Localized>
									) : (
										<Localized id="student-management-enrolment-applications-no-results-descr">
											No enrolment applications were found matching your search
											criteria. Try to adjust filters
										</Localized>
									)
								}
								spanWholeTable
							/>
						)}
					{applications.map((s) => {
						const selected = selectedApplication(s.id);

						return (
							<ApplicationRow
								key={s.id}
								application={s}
								loading={loading}
								selectionCellContent={
									!props.viewOnly && (
										<Checkbox
											checked={selected}
											inputProps={{
												"aria-label": l10n.getString(
													"student-management-enrolment-applications-select-label"
												),
											}}
											onClick={() => selectApplication(s.id)}
										/>
									)
								}
								selected={selected}
								viewOnly={props.viewOnly}
								onAccept={() => acceptApplications([s.id], s.userName)}
								onReject={() => rejectApplications([s.id], s.userName)}
							/>
						);
					})}
				</TableBody>
			</Table>
			<Stack
				direction="row"
				sx={{justifyContent: "space-between", alignItems: "center"}}
			>
				<Typography variant="subtitle2">
					{selectedApplications.length > 0 && (
						<Localized
							id="student-management-enrolment-applications-selected-row-number"
							vars={{selected: selectedApplications.length}}
						>{`${selectedApplications.length} selected`}</Localized>
					)}
				</Typography>
				<TablePagination
					onPageChange={fetchRelatedPage}
					pageSize={applicationsPerPage}
					onPageSizeChange={setApplicationsPerPage}
					first={Boolean(applicationsPage.request.first)}
					last={Boolean(applicationsPage.request.last)}
					next={Boolean(applicationsPage.request.next)}
					previous={Boolean(applicationsPage.request.previous)}
					label={
						<Localized id="student-management-enrolment-applications-per-page">
							Applications per page
						</Localized>
					}
					disabled={pageFetchStatus !== "succeeded"}
					pageSizeOptions={[5, 10, 25, 50]}
				/>
			</Stack>

			{confirmationDialog}
		</Stack>
	);
}

function ApplicationRow(props: {
	application: EnrolmentApplicationSearchResult;
	loading?: boolean;
	selected?: boolean;
	selectionCellContent?: React.ReactNode;
	viewOnly?: boolean;
	onAccept: () => void;
	onReject: () => void;
}) {
	const {application} = props;

	const formatDate = useDateTimeFormat();

	const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

	function closeMenu() {
		setMenuAnchor(null);
	}

	return (
		<TableRow
			hover
			tabIndex={-1}
			selected={props.selected}
			sx={[
				!props.application.userName &&
					!props.loading && {
						visibility: "hidden",
						"& > td": {
							borderBottom: "1px solid transparent",
						},
					},
			]}
		>
			{props.selectionCellContent && (
				<TableCell padding="checkbox">{props.selectionCellContent}</TableCell>
			)}
			<TableCell>
				<StudentName student={application} loading={props.loading} />
			</TableCell>
			<TableCell
				align={columnDefs.applicationDate.align}
				sx={{width: columnDefs.applicationDate.width}}
			>
				{props.loading && (
					<Box sx={{display: "flex", justifyContent: "flex-end"}}>
						<Skeleton width="50%" />
					</Box>
				)}
				{!props.loading &&
					application.applicationDate &&
					formatDate(parseDate(application.applicationDate))}
			</TableCell>
			{!props.viewOnly && (
				<TableCell padding="checkbox">
					<Stack
						direction="row"
						spacing={1}
						sx={[
							{display: {xs: "none", md: "flex"}},
							!props.application.userName && {visibility: "hidden"},
						]}
					>
						<Button onClick={props.onAccept}>
							<Localized id="student-management-enrolment-applications-row-action-accept">
								Accept
							</Localized>
						</Button>
						<Button onClick={props.onReject}>
							<Localized id="student-management-enrolment-applications-row-action-reject">
								Reject
							</Localized>
						</Button>
					</Stack>
					<IconButtonWithTooltip
						tooltipTitle={
							<Localized id="student-management-enrolment-applications-row-actions">
								Actions
							</Localized>
						}
						sx={[
							{display: {md: "none"}},
							!props.application.userName && {visibility: "hidden"},
						]}
						onClick={(event) => setMenuAnchor(event.currentTarget)}
					>
						<MoreVert />
					</IconButtonWithTooltip>
				</TableCell>
			)}

			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				slotProps={{paper: {sx: {minWidth: (theme) => theme.spacing(14)}}}}
				onClose={closeMenu}
			>
				<MenuItem
					onClick={() => {
						props.onAccept();
						closeMenu();
					}}
				>
					<Localized id="student-management-enrolment-applications-row-action-accept">
						Accept
					</Localized>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.onReject();
						closeMenu();
					}}
				>
					<Localized id="student-management-enrolment-applications-row-action-reject">
						Reject
					</Localized>
				</MenuItem>
			</Menu>
		</TableRow>
	);
}

export default EnrolmentApplicationsWidget;
