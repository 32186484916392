import {Localized, useLocalization} from "@fluent/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";

import ContentEditorFooter from "./ContentEditorFooter";
import type ExerciseEditorProps from "./ExerciseEditorProps";
import MultiExerciseChoice from "./MultiExerciseChoice";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import exerciseService from "../../store/services/exerciseService";
import ExerciseTagsSelector from "./ExerciseTagsSelector";
import useExerciseFileUploader from "./useExerciseFileUploader";
import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import {MultiExerciseSubtype} from "../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import PrivacyLevelSelector from "./PrivacyLevelSelector";

let counter = 0;

function id(): number {
	return counter++;
}

function createEmptyChoice() {
	return {
		id: id(),
		initialText: "",
		initialComment: "",
		text: "",
		score: 0,
		comment: "",
	};
}

const MultiExerciseEditor = (props: ExerciseEditorProps): JSX.Element => {
	const {courseId, exercise, exerciseId, organisationName} = props;

	const userId = useAppSelector(selectUserId);

	const {l10n} = useLocalization();

	const [draft, setDraft] = useState(() => ({
		id: exerciseId ?? 0,
		difficultyLevel: 0,
		privacy: ExercisePrivacy.PublicToOrganisation,
		title: l10n.getString(
			"learning-material-multi-exercise-editor-new-exercise"
		),
		category: "",
		tags: [] as string[],
		subtype: MultiExerciseSubtype.Single,
		allOrNothing: false,
		choices: [createEmptyChoice(), createEmptyChoice(), createEmptyChoice()],
	}));

	const question = useRef<TextEditorApi | null>(null);
	const solution = useRef<TextEditorApi | null>(null);

	const [errors, setErrors] = useState({title: ""});

	const showSnackbar = useSnackbar();

	const initialQuestion = useRef("");
	const initialSolution = useRef("");

	const updated = useRef(false);

	if (exercise && exercise.type !== ExerciseType.Multi) {
		throw new Error("Unexpected exercise type");
	}

	const [featureEnabled] = useFeatureEnabled();

	useEffect(() => {
		if (!exercise) {
			return;
		}

		initialQuestion.current = exercise.question;
		initialSolution.current = exercise.solution;

		const allOrNothing = exercise.subtype === MultiExerciseSubtype.AllOrNothing;

		let subtype = exercise.subtype;
		if (allOrNothing) {
			subtype = MultiExerciseSubtype.Multiple;
		}

		const choices = exercise.choices.map((choice) => ({
			...choice,
			id: id(),
			initialText: choice.text,
			initialComment: choice.comment,
		}));

		setDraft({
			id: exerciseId ?? 0,
			difficultyLevel: exercise.difficultyLevel,
			privacy: exercise.privacy,
			title: exercise.title,
			category: exercise.category,
			tags: exercise.tags,
			subtype,
			choices,
			allOrNothing,
		});
	}, [exercise, exerciseId]);

	const [editingStarted, setEditingStarted] = useState(!exercise);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft.id
	);

	useEffect(() => props.onUnmount, [props.onUnmount]);

	const controlEditingStarted = useCallback(() => {
		if (!editingStarted) {
			setEditingStarted(true);
		}
	}, [editingStarted]);

	const handleTitleUpdate = (title: string) => {
		setDraft((prev) => ({...prev, title}));

		setErrors((prev) => ({...prev, title: ""}));
		controlEditingStarted();
	};

	const handleChoiceUpdate = useCallback(
		(diff: {score?: number; text?: string; comment?: string}, i: number) => {
			setDraft((prev) => {
				const choices = [...prev.choices];
				choices[i] = {...choices[i], ...diff};
				return {...prev, choices: choices};
			});
			controlEditingStarted();
		},
		[controlEditingStarted]
	);

	const handleChoiceDelete = useCallback(
		(i: number) => {
			controlEditingStarted();
			setDraft((prev) => {
				const choices = [...prev.choices];
				choices.splice(i, 1);
				return {...prev, choices: choices};
			});
		},
		[controlEditingStarted]
	);

	const handlePrivacyLevelUpdate = (privacy: ExercisePrivacy) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, privacy}));
	};

	const handleDifficultyUpdate = (difficultyLevel: number | null) => {
		controlEditingStarted();
		setDraft((prev) => ({
			...prev,
			difficultyLevel: difficultyLevel ?? 0,
		}));
	};

	const handleTagsUpdate = (tags: string[]) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, tags}));
	};

	const handleCategoryUpdate = (category: string) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, category}));
	};

	const handleAllOrNothingUpdate = (allOrNothing: boolean) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, allOrNothing}));
	};

	const handleExerciseTypeUpdate = (subtype: MultiExerciseSubtype) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, subtype}));
	};

	const handleAddChoice = () => {
		setDraft((prev) => ({
			...prev,
			choices: [...prev.choices, createEmptyChoice()],
		}));

		controlEditingStarted();
	};

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const page = await exerciseService.searchTags(
				{
					scope: "organisation_or_user_exercises",
					organisationName,
					userId,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[organisationName, userId]
	);

	const validate = () => {
		if (draft.title === "") {
			setErrors((prev) => ({
				...prev,
				title: l10n.getString(
					"exercise-editor-error-required-field",
					null,
					"The field is required"
				),
			}));

			return l10n.getString(
				"exercise-editor-error-required-fields",
				null,
				"Some required fields are empty"
			);
		}

		if (draft.choices.length < 2) {
			return l10n.getString(
				"learning-material-multi-exercise-editor-validate-min-choices",
				null,
				"There should be at least two choices"
			);
		}

		if (
			draft.subtype === MultiExerciseSubtype.Multiple &&
			draft.choices.every((c) => c.score <= 0)
		) {
			return l10n.getString(
				"learning-material-multi-exercise-editor-validate-positive-score",
				null,
				"At least one choice should have a positive score"
			);
		}

		if (
			draft.subtype === MultiExerciseSubtype.Single &&
			draft.choices.reduce((p, c) => (c.score > 0 ? p + 1 : p), 0) !== 1
		) {
			return l10n.getString(
				"learning-material-multi-exercise-editor-validate-one-positive-score",
				null,
				"One and only one choice should have positive score"
			);
		}

		return null;
	};

	const handleDraftSave = async () => {
		const error = validate();
		if (error) {
			showSnackbar("error", error);
			return false;
		}

		try {
			const base = exercise ?? {
				type: ExerciseType.Multi,
				authorId: userId,
				originId: courseId,
			};

			const id = await props.onSave({
				...base,
				title: draft.title,
				category: draft.category,
				question: question.current?.getContent() ?? "",
				solution: solution.current?.getContent() ?? "",
				privacy: draft.privacy,
				difficultyLevel: draft.difficultyLevel,
				contentLanguage: props.defaultLanguage,
				tags: draft.tags,
				choices: draft.choices.map((ch, i) => ({
					id: i + 1,
					score: ch.score,
					text: ch.text,
					comment: ch.comment,
				})),
				subtype:
					draft.allOrNothing && draft.subtype === MultiExerciseSubtype.Multiple
						? MultiExerciseSubtype.AllOrNothing
						: draft.subtype,
			});

			setDraft((prev) => ({...prev, id}));
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		setEditingStarted(false);
		updated.current = true;

		return true;
	};

	const handleDraftDone = async () => {
		const saved = await handleDraftSave();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	};

	return (
		<>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<TextField
						autoFocus
						fullWidth
						error={Boolean(errors.title)}
						value={draft.title}
						id="exerciseTitle"
						label={
							<Localized id="exercise-editor-title-label">
								Exercise title
							</Localized>
						}
						onChange={(e) => handleTitleUpdate(e.target.value)}
						helperText={errors.title}
					/>
				</Grid>

				<Grid item>
					<Box mt={2} mb={1}>
						<Typography variant="body2">
							<Localized id="exercise-editor-description-label">
								Description
							</Localized>
						</Typography>
					</Box>

					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						onChange={() => setEditingStarted(true)}
						fileUploader={fileUploader ?? undefined}
						files={files}
						ref={question}
					/>
				</Grid>

				<Grid item>
					<Box display="flex" flex-direction="row" alignItems="flex-end">
						<Box mb={0.5}>
							<Box mt={2} mb={1}>
								<Typography variant="body2">
									<Localized id="learning-material-multi-exercise-editor-new-type">
										Exercise type:
									</Localized>
								</Typography>
							</Box>

							<FormControl>
								<Select
									style={{minWidth: "22ch"}}
									id="exercise-type"
									value={draft.subtype}
									onChange={(e) =>
										handleExerciseTypeUpdate(
											e.target.value as MultiExerciseSubtype
										)
									}
								>
									<MenuItem value={MultiExerciseSubtype.Single}>
										<Localized id="learning-material-multi-exercise-editor-new-type-single">
											Single answer
										</Localized>
									</MenuItem>
									<MenuItem value={MultiExerciseSubtype.Multiple}>
										<Localized id="learning-material-multi-exercise-editor-new-type-multiple">
											Multiple answers
										</Localized>
									</MenuItem>
								</Select>
							</FormControl>
						</Box>

						{draft.subtype === MultiExerciseSubtype.Multiple && (
							<Box ml={3}>
								<FormControlLabel
									control={
										<Checkbox
											checked={draft.allOrNothing}
											onChange={(e) =>
												handleAllOrNothingUpdate(e.target.checked)
											}
										/>
									}
									label={
										<Localized id="learning-material-multi-exercise-editor-new-type-multiple-all-or-nothing">
											All-or-nothing scoring
										</Localized>
									}
								/>
							</Box>
						)}
					</Box>
				</Grid>

				<Grid item>
					<Paper>
						<Box my={2} mx={3}>
							{draft.choices.map((choice, i) => (
								<MultiExerciseChoice
									key={choice.id}
									i={i}
									choice={choice}
									fileUploader={fileUploader ?? undefined}
									onChange={handleChoiceUpdate}
									onDelete={handleChoiceDelete}
								/>
							))}

							<Box
								display="flex"
								justifyContent="flex-end"
								style={{width: "100%"}}
								pt={1}
								pb={2}
							>
								<Button color="primary" onClick={handleAddChoice}>
									<Localized id="learning-material-multi-exercise-editor-new-type-multiple-add-choice">
										+ Add choice
									</Localized>
								</Button>
							</Box>
						</Box>
					</Paper>
				</Grid>

				<Grid item>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>
								<Localized id="exercise-editor-solution-label">
									Solution
								</Localized>
							</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<Box width="100%">
								<TextEditor
									initialValue={initialSolution.current}
									ref={solution}
									onChange={() => setEditingStarted(true)}
									fileUploader={fileUploader ?? undefined}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid item container spacing={3} alignItems="flex-end">
					<Grid item xs={12} md={6}>
						<PrivacyLevelSelector
							value={draft.privacy}
							onChange={handlePrivacyLevelUpdate}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ExerciseDifficultySelector
							name="difficulty"
							value={draft.difficultyLevel}
							onChange={handleDifficultyUpdate}
						/>
					</Grid>
				</Grid>

				<Grid item>
					<TextField
						value={draft.category}
						id="caterogy"
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						onChange={(e) => handleCategoryUpdate(e.target.value)}
					/>
				</Grid>

				{featureEnabled(Feature.ExerciseTags) && (
					<Grid item>
						<ExerciseTagsSelector
							freeSolo
							value={draft.tags}
							onChange={handleTagsUpdate}
							searchTags={searchTags}
						/>
					</Grid>
				)}
			</Grid>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={handleDraftDone}
				onSave={handleDraftSave}
				disabled={!editingStarted}
			/>
		</>
	);
};

export default MultiExerciseEditor;
