import {Localized} from "@fluent/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
	Collapse,
	IconButton,
	Skeleton,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useState} from "react";

import parseDate from "../../helpers/parseDate";
import type Chapter from "../../store/chapters/Chapter";
import {SelectionModes} from "../../store/chapters/SelectionMode";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import selectChapter from "../../store/chapters/selectChapter";
import type ChapterProgress from "../../store/courseProgress/ChapterProgress";
import {useAppSelector} from "../../store/hooks";
import ProgressBar from "../../utils/ProgressBar";
import ChapterProgressBar from "../myCorner/ChapterProgressBar";
import type {ProgressByType} from "../../store/services/analyticsService";

const useRowStyles = makeStyles({
	root: {
		"& > td": {
			borderBottom: "unset",
		},
	},
});

function ProgressTableChapterRow(props: {
	studentId: number;
	chapterKey: string;
	chapterProgress: ChapterProgress | null;
	loading?: boolean;
	bar?: {type: "plain"} | {type: "stacked"; progressByType: ProgressByType[]};
	renderTitle: (chapter: Chapter) => React.ReactNode;
	renderSectionTable: (
		chapterId: number,
		courseId: number,
		chapterProgress: ChapterProgress,
		sessionRequired?: boolean
	) => React.ReactElement;
}): JSX.Element {
	const {chapterProgress, loading} = props;

	const classes = useRowStyles();

	const chapter = useAppSelector((state) =>
		selectChapter(state, props.chapterKey)
	);

	const [open, setOpen] = useState(false);

	if (!chapter) {
		return <></>;
	}

	if (loading) {
		return (
			<TableRow>
				<TableCell>{props.renderTitle(chapter)}</TableCell>
				<TableCell>
					<ProgressBar
						theme="grey"
						leftLabel={<Skeleton variant="rectangular" width="3.5rem" />}
						rightLabel={<Skeleton variant="rectangular" width="4.5rem" />}
					/>
				</TableCell>
			</TableRow>
		);
	}

	if (!chapter.startDate || new Date() < parseDate(chapter.startDate)) {
		return (
			<TableRow>
				<TableCell>{props.renderTitle(chapter)}</TableCell>
				<TableCell>
					<ProgressBar
						theme="grey"
						leftLabel={
							<Typography variant="body2" color="textSecondary">
								<Localized id="student-results-widget-label-not-published">
									Not published
								</Localized>
							</Typography>
						}
					/>
				</TableCell>
				<TableCell padding="checkbox" />
			</TableRow>
		);
	}

	if (!chapterProgress) {
		return <></>;
	}

	const sessionRequired =
		chapter.submissionMode === SubmissionMode.Simultaneous ||
		chapter.selectionMode === SelectionModes.Random;

	const expandable = !sessionRequired || chapterProgress.startTime;

	return (
		<>
			<TableRow className={classes.root}>
				<TableCell>{props.renderTitle(chapter)}</TableCell>
				<TableCell>
					<ChapterProgressBar
						chapter={chapter}
						chapterProgress={chapterProgress}
						bar={props.bar}
					/>
				</TableCell>
				<TableCell padding="checkbox">
					{expandable && (
						<IconButton onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			{expandable && (
				<TableRow>
					<TableCell
						colSpan={2}
						style={{paddingTop: 0, paddingBottom: 0, paddingRight: 0}}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{props.renderSectionTable(
								chapter.id,
								chapter.courseId,
								chapterProgress,
								sessionRequired
							)}
						</Collapse>
					</TableCell>
					<TableCell padding="checkbox" />
				</TableRow>
			)}
		</>
	);
}

export default ProgressTableChapterRow;
