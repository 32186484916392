import {Link, Table, TableBody} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

import selectProgressInChapter from "../../store/courseProgress/selectProgressInChapter";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import ExerciseOutcomes from "../../store/courseProgress/ExerciseOutcomes";
import ProgressTableExerciseRow from "../analytics/ProgressTableExerciseRow";
import ContentItemTitle from "../analytics/ContentItemTitle";
import type {ChapterExercise} from "../../store/services/dtos/ChapterContent";
import ProgressTableChapterRow from "../analytics/ProgressTableChapterRow";
import ProgressTableSections from "../analytics/ProgressTableSections";

const ProgressInChaptersWidget = (props: {
	id: string;
	title: React.ReactNode;
	courseId: number;
}): JSX.Element => {
	const chapters = useAppSelector((state) =>
		selectCourseChapterKeys(state, keyProvider.course(props.courseId))
	);

	const studentId = useAppSelector(selectUserId);

	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody>
				<Table>
					<TableBody>
						{chapters.map((key) => (
							<ProgressRow chapterKey={key} studentId={studentId} key={key} />
						))}
					</TableBody>
				</Table>
			</WidgetBody>
		</Widget>
	);
};

function ProgressRow(props: {chapterKey: string; studentId: number}) {
	const {chapterKey, studentId} = props;

	const chapterProgress = useAppSelector((state) =>
		selectProgressInChapter(state, chapterKey)
	);

	return (
		<ProgressTableChapterRow
			studentId={studentId}
			chapterKey={chapterKey}
			chapterProgress={chapterProgress}
			renderTitle={(chapter) => (
				<Link
					component={RouterLink}
					to={`/courses/${chapter.courseId}/chapters/${chapter.id}`}
					color="inherit"
				>
					<ContentItemTitle name={chapter.title} number={`${chapter.number}`} />
				</Link>
			)}
			renderSectionTable={(
				chapterId,
				courseId,
				chapterProgress,
				sessionRequired
			) => (
				<ProgressTableSections
					studentId={studentId}
					chapterId={chapterId}
					courseId={courseId}
					chapterProgress={chapterProgress}
					sessionRequired={sessionRequired}
					renderExerciseRow={(section, exercise, outcomes) => (
						<ExerciseRow
							key={exercise.id}
							courseId={courseId}
							section={section}
							exercise={exercise}
							outcomes={outcomes}
						/>
					)}
				/>
			)}
		/>
	);
}

function ExerciseRow(props: {
	courseId: number;
	section: {
		id: number;
		number: number;
	};
	exercise: ChapterExercise;
	outcomes?: ExerciseOutcomes;
}) {
	const {outcomes, exercise, section} = props;

	return (
		<ProgressTableExerciseRow
			outcomes={outcomes}
			maxScore={exercise.maxScore ?? 0}
			title={
				<Link
					color="inherit"
					to={
						`/courses/${props.courseId}/chapters/${exercise.chapterId}` +
						`/sections/${section.id}#subsection-${exercise.id}`
					}
					component={RouterLink}
				>
					<ContentItemTitle
						number={`${section.number}.${exercise.number}`}
						name={exercise.title}
					/>
				</Link>
			}
		/>
	);
}

export default ProgressInChaptersWidget;
