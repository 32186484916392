import {Autocomplete, Box, debounce} from "@mui/material";
import type {
	AutocompleteChangeReason,
	AutocompleteGetTagProps,
} from "@mui/material";
import React, {useImperativeHandle, useMemo, useState} from "react";

import type {Page} from "../../helpers/paginatedSearchHelpers";
import inputRenderer from "./inputRenderer";
import type {Option, Optional} from "./Option";
import optionRenderer from "./optionRenderer";
import useLoadMore from "./useLoadMore";

export type PaginatedAutocompleteApi = {
	resetSelection: () => void;
};

function PaginatedAutocomplete<T, K>(props: {
	noOptionsText?: React.ReactNode;
	limitTags?: number;
	label?: React.ReactNode;
	pageFetcher: (query: string, pageSize: number) => Promise<Page<T, K>>;
	optionMapper: (val: T) => Option<K>;
	tagsRenderer?: (
		options: Optional<Option<K>, "id">[],
		getTagProps: AutocompleteGetTagProps
	) => React.ReactNode;
	onChange: (key: K[]) => void;

	apiRef?: React.Ref<PaginatedAutocompleteApi>;
}) {
	type POption = Optional<Option<K>, "id">;

	const {pageFetcher, onChange, optionMapper} = props;

	const [query, setQuery] = useState("");

	const changeQuery = useMemo(() => {
		return debounce((val: string) => setQuery(val), 500);
	}, []);

	const [value, setValue] = useState<POption[]>([]);

	const [inputValue, setInputValue] = useState("");

	const {loadMoreOption, loading, options, loadMore} = useLoadMore(
		query,
		pageFetcher
	);

	useImperativeHandle(
		props.apiRef,
		(): PaginatedAutocompleteApi => ({
			resetSelection() {
				setValue([]);
			},
		}),
		[]
	);

	function changeSelected(
		_: unknown,
		selected: POption[],
		reason: AutocompleteChangeReason
	) {
		if (loadMoreOption && selected.includes(loadMoreOption)) {
			loadMore();
			return;
		}

		if (reason === "selectOption" || reason === "removeOption") {
			setValue(selected);

			const ids: K[] = [];
			selected.forEach((opt) => opt.id && ids.push(opt.id));
			onChange(ids);
		} else if (reason === "clear") {
			setValue([]);
			onChange([]);
		}
	}

	function changeInput(val: string) {
		changeQuery(val);
		setInputValue(val);
	}

	function renderOption(
		props: React.HTMLAttributes<HTMLElement>,
		option: POption,
		state: {selected: boolean}
	) {
		if (option === loadMoreOption) {
			return (
				<Box {...props} sx={{color: (theme) => theme.palette.text.secondary}}>
					{option.name}
				</Box>
			);
		}

		return optionRenderer(props, option, state);
	}

	return (
		<Autocomplete
			multiple
			limitTags={props.limitTags}
			options={options.map(optionMapper)}
			value={value}
			disableCloseOnSelect
			loading={loading}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			noOptionsText={props.noOptionsText}
			getOptionLabel={(o) => o.name}
			getOptionKey={(o) => `${o.id ?? "load-more"}`}
			filterOptions={(options: POption[]) => {
				const filtered =
					options.length > 0 && loadMoreOption
						? [...options, loadMoreOption]
						: options;

				return [...filtered];
			}}
			inputValue={inputValue}
			onChange={changeSelected}
			onInputChange={(_, val, reason) => {
				if (reason !== "reset") {
					changeInput(val);
				}
			}}
			onBlur={() => {
				changeInput("");
			}}
			renderOption={renderOption}
			renderInput={inputRenderer(props.label, loading)}
			renderTags={props.tagsRenderer}
			size="small"
		/>
	);
}

export default PaginatedAutocomplete;
