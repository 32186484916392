import type {RootState} from "../store";

function selectProctoringSettings(state: RootState) {
	return state.proctoring.settings.values;
}

function selectProctoringSettingsStatus(state: RootState) {
	return state.proctoring.settings.status;
}

export {selectProctoringSettingsStatus};
export default selectProctoringSettings;
