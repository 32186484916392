import type {AxiosInstance} from "axios";

import {axiosInstance as client} from "./axiosInstance";
import type ProctoringSettings from "../proctoring/ProctoringSettings";

function createService(client: AxiosInstance) {
	async function getSettings(
		organisationName: string
	): Promise<ProctoringSettings> {
		const {data} = await client.get(
			`/api/organisations/${organisationName}/proctoring/monitoring-settings`
		);

		return data;
	}

	return {
		getSettings: getSettings,
	};
}

export default createService(client);
