import type {RootState} from "../store";
import type Response from "../studentResponses/Response";

function selectChapterSessionStudentResponse(
	state: RootState,
	chapterExerciseKey: string
): Response | null {
	return (
		state.chapterSessions.lastRequested.responses[chapterExerciseKey] ?? null
	);
}

export default selectChapterSessionStudentResponse;
