import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {Box, IconButton, Typography} from "@mui/material";
import React from "react";

function SidebarHeader(props: {
	title: React.ReactNode;
	fullscreen?: boolean;
	closureDisabled?: boolean;
	onToggleFullscreen?: () => void;
	onClose: () => void;
}): JSX.Element {
	const {
		title,
		closureDisabled,
		onClose,
		onToggleFullscreen,
		fullscreen,
	} = props;

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			my={1}
			ml={2}
			mr={0.5}
		>
			<Typography variant="h6">{title}</Typography>

			<Box>
				{onToggleFullscreen && (
					<IconButton onClick={onToggleFullscreen}>
						{fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
					</IconButton>
				)}

				<IconButton disabled={closureDisabled} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Box>
		</Box>
	);
}

export default SidebarHeader;
