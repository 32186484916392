import {createAsyncThunk} from "@reduxjs/toolkit";

import type NormalisedSectionContent from "../sections/NormalisedSectionContent";
import {normaliseSectionContent} from "../sections/NormalisedSectionContent";
import chapterSessionsService from "../services/chapterSessionsService";
import {RootState} from "../store";
import selectChapterSession from "./selectChapterSession";
import selectChapterSessionSection from "./selectChapterSessionSection";

export const fetchSessionSectionContent = createAsyncThunk<
	{
		data: NormalisedSectionContent;
		sectionKey: string;
	},
	{
		studentId: number;
		courseId: number;
		sectionKey: string;
		sessionKey: string;
	},
	{
		state: RootState;
	}
>(
	"chapterSessions/fetchSectionContent",
	async ({studentId, courseId, sectionKey, sessionKey}, thunkApi) => {
		const store = thunkApi.getState();

		const section = selectChapterSessionSection(store, sectionKey);
		if (!section) {
			throw new Error(`Section ${sectionKey} is not found`);
		}

		const session = selectChapterSession(store, sessionKey);
		if (!session) {
			throw new Error(`Chapter session ${sessionKey} is not found`);
		}

		const content = await chapterSessionsService.getChapterSessionExercises(
			studentId,
			courseId,
			section.chapterId,
			session.startTime,
			[section.id, ...section.subsectionIds]
		);

		const entities = normaliseSectionContent(section.chapterId, content);

		return {data: entities, sectionKey};
	}
);
