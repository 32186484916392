import {Localized} from "@fluent/react";
import {Paper, Tooltip, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";
import {CompletionStatus} from "../../../store/outcomes/ExerciseOutcomes";
import type {ExerciseOutcomes} from "../../../store/outcomes/ExerciseOutcomes";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(1),
		},
		tooltip: {
			backgroundColor: "transparent",
			maxWidth: "none",
		},
	})
);

const ExerciseStatusHint = (props: {
	maxScore: number;
	outcomes?: ExerciseOutcomes;
	children: React.ReactElement;
}): JSX.Element => {
	const classes = useStyles();

	const {maxScore, outcomes} = props;

	let content = null;
	switch (outcomes?.completionStatus) {
		case CompletionStatus.Complete:
			content = (
				<Typography>
					<Localized
						id="content-exercise-status-hint-completed"
						vars={{score: outcomes.score, maxScore}}
					>
						{"Completed. Earned score: {$score} out of {$maxScore}"}
					</Localized>
				</Typography>
			);

			break;
		case CompletionStatus.Incomplete:
			content = (
				<Typography>
					<Localized id="content-exercise-status-hint-started">
						Started
					</Localized>
				</Typography>
			);

			break;
	}

	if (!content) {
		return props.children;
	}

	return (
		<Tooltip
			title={
				<Paper elevation={8} className={classes.paper}>
					{content}
				</Paper>
			}
			classes={{tooltip: classes.tooltip}}
			placement="right"
		>
			{props.children}
		</Tooltip>
	);
};

export default ExerciseStatusHint;
