import React from "react";

import LoadingError from "../errors/LoadingError";
import TableStateRow from "./TableStateRow";

function LoadingErrorState(props: {
	columnNumber: number;
	spanWholeTable?: boolean;
	title?: React.ReactNode;
	description: React.ReactNode;
	onReload?: () => void;
}) {
	return (
		<TableStateRow
			columnNumber={props.columnNumber}
			spanWholeTable={props.spanWholeTable}
		>
			<LoadingError
				title={props.title}
				description={props.description}
				variant="block"
				onReload={props.onReload}
			/>
		</TableStateRow>
	);
}

export default LoadingErrorState;
