export function countLines(text: string): number {
	let n = 1;

	for (const ch of text) {
		if (ch === "\n") {
			n++;
		}
	}

	return n;
}

export function textFile(filename: string): boolean {
	const i = filename.lastIndexOf(".");

	return i > -1 && textExtensions.has(filename.slice(i + 1));
}

const textExtensions = new Set([
	"ada",
	"adb",
	"ads",
	"applescript",
	"as",
	"ascx",
	"asm",
	"asmx",
	"asp",
	"aspx",
	"atom",
	"bas",
	"bash",
	"bashrc",
	"bat",
	"bbcolors",
	"bdsgroup",
	"bdsproj",
	"bib",
	"bowerrc",
	"c",
	"cbl",
	"cc",
	"cfc",
	"cfg",
	"cfm",
	"cfml",
	"cgi",
	"clj",
	"cls",
	"cmake",
	"cmd",
	"cnf",
	"cob",
	"coffee",
	"coffeekup",
	"conf",
	"cpp",
	"cpt",
	"cpy",
	"crt",
	"cs",
	"csh",
	"cson",
	"csr",
	"css",
	"csslintrc",
	"csv",
	"ctl",
	"curlrc",
	"cxx",
	"dart",
	"dfm",
	"diff",
	"dof",
	"dpk",
	"dproj",
	"dtd",
	"eco",
	"editorconfig",
	"ejs",
	"el",
	"emacs",
	"eml",
	"ent",
	"erb",
	"erl",
	"eslintignore",
	"eslintrc",
	"ex",
	"exs",
	"f",
	"f03",
	"f77",
	"f90",
	"f95",
	"fish",
	"for",
	"fpp",
	"frm",
	"ftn",
	"gemrc",
	"gitattributes",
	"gitconfig",
	"gitignore",
	"gitkeep",
	"gitmodules",
	"go",
	"gpp",
	"gradle",
	"groovy",
	"groupproj",
	"grunit",
	"gtmpl",
	"gvimrc",
	"h",
	"haml",
	"hbs",
	"hgignore",
	"hh",
	"hpp",
	"hrl",
	"hs",
	"hta",
	"htaccess",
	"htc",
	"htm",
	"html",
	"htpasswd",
	"hxx",
	"iced",
	"inc",
	"ini",
	"ino",
	"int",
	"irbrc",
	"itcl",
	"itermcolors",
	"itk",
	"jade",
	"java",
	"jhtm",
	"jhtml",
	"js",
	"jscsrc",
	"jshintignore",
	"jshintrc",
	"json",
	"json5",
	"jsonld",
	"jsp",
	"jspx",
	"jsx",
	"ksh",
	"less",
	"lhs",
	"lisp",
	"log",
	"ls",
	"lsp",
	"lua",
	"m",
	"mak",
	"map",
	"markdown",
	"master",
	"md",
	"mdown",
	"mdwn",
	"mdx",
	"metadata",
	"mht",
	"mhtml",
	"mjs",
	"mk",
	"mkd",
	"mkdn",
	"mkdown",
	"ml",
	"mli",
	"mm",
	"mxml",
	"nfm",
	"nfo",
	"njk",
	"noon",
	"npmignore",
	"npmrc",
	"nvmrc",
	"ops",
	"pas",
	"pasm",
	"patch",
	"pbxproj",
	"pch",
	"pem",
	"pg",
	"php",
	"php3",
	"php4",
	"php5",
	"phpt",
	"phtml",
	"pir",
	"pl",
	"pm",
	"pmc",
	"pod",
	"pot",
	"properties",
	"props",
	"pt",
	"pug",
	"py",
	"r",
	"rake",
	"rb",
	"rdoc",
	"rdoc_options",
	"resx",
	"rhtml",
	"rjs",
	"rlib",
	"rmd",
	"ron",
	"rs",
	"rss",
	"rst",
	"rtf",
	"rvmrc",
	"rxml",
	"s",
	"sass",
	"scala",
	"scm",
	"scss",
	"seestyle",
	"sh",
	"shtml",
	"sls",
	"spec",
	"sql",
	"sqlite",
	"ss",
	"sss",
	"st",
	"strings",
	"sty",
	"styl",
	"stylus",
	"sub",
	"sublime-build",
	"sublime-commands",
	"sublime-completions",
	"sublime-keymap",
	"sublime-macro",
	"sublime-menu",
	"sublime-project",
	"sublime-settings",
	"sublime-workspace",
	"sv",
	"svc",
	"svg",
	"t",
	"tcl",
	"tcsh",
	"terminal",
	"tex",
	"text",
	"textile",
	"tg",
	"tmLanguage",
	"tmTheme",
	"tmpl",
	"tpl",
	"ts",
	"tsv",
	"tsx",
	"tt",
	"tt2",
	"ttml",
	"txt",
	"v",
	"vb",
	"vbs",
	"vh",
	"vhd",
	"vhdl",
	"vim",
	"viminfo",
	"vimrc",
	"vue",
	"webapp",
	"wxml",
	"wxss",
	"x-php",
	"xaml",
	"xht",
	"xhtml",
	"xml",
	"xs",
	"xsd",
	"xsl",
	"xslt",
	"yaml",
	"yml",
	"zsh",
	"zshrc",
]);
