import TeX from "@matejmazur/react-katex";
import {Box, useTheme} from "@mui/material";
import React, {useMemo} from "react";

import "katex/dist/katex.min.css";

import type AnswerStep from "./AnswerStep";
import AnswerStepEditMenu from "./AnswerStepEditMenu";
import ResponseFeedback from "../ResponseFeedback";
import {changeRootArgs} from "../../../../helpers/mathParseHelpers";
import type {MathFeedback} from "../../../../store/studentResponses/Feedback";
import AsciiMathParser from "../../../../utils/asciiMathParser";

type Props = {
	step: AnswerStep;
	feedback?: MathFeedback;

	added?: boolean;
	showMenu?: boolean;

	onEdit?: () => void;
	onCopy?: () => void;
	onDelete?: () => void;

	parser: AsciiMathParser;
};

const AnswerStepLine = (props: Props): JSX.Element => {
	const theme = useTheme();

	const copyStep = () => {
		props.onCopy?.();
	};
	const editStep = () => {
		props.onEdit?.();
	};
	const deleteStep = () => {
		props.onDelete?.();
	};

	const {
		parser,
		step: {operation, asciiFormula},
		feedback,
	} = props;

	const formula = useMemo(() => {
		const formula = changeRootArgs(asciiFormula);
		return parser.parse(operation + formula);
	}, [parser, operation, asciiFormula]);

	return (
		<Box
			display="flex"
			mt={2}
			style={{gap: theme.spacing(1), height: "1.5rem"}}
			alignItems="center"
		>
			<TeX>{formula}</TeX>

			{feedback && feedback.status !== "unspecified" && (
				<ResponseFeedback state={feedback.status} comment={feedback.message} />
			)}

			{props.showMenu && (
				<AnswerStepEditMenu
					onCopyClick={copyStep}
					onEditClick={editStep}
					onDeleteClick={deleteStep}
				/>
			)}
		</Box>
	);
};

export default AnswerStepLine;
