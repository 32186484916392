import ExerciseType from "../exercises/ExerciseType";
import {axiosInstance as client} from "./axiosInstance";

export const sectionService = {
	addExercises: async (
		courseId: number,
		chapterId: number,
		sectionId: number,
		exercises: {id: number; type: ExerciseType}[]
	): Promise<void> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}/exercises`;

		await client.post(url, exercises);
	},

	patchSectionSettings: async (
		courseId: number,
		chapterId: number,
		sectionId: number,
		settings: {
			selectionSize?: number;
			maxScorePerExercise?: number;
		}
	): Promise<void> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}`;

		await client.patch(url, settings, {
			headers: {
				"Content-Type": "application/merge-patch+json",
			},
		});
	},
};
