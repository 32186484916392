import {Skeleton} from "@mui/material";
import React from "react";

import ProgressTooltip from "./ProgressTooltip";
import ProgressBar from "../../utils/ProgressBar";

function PlainProgressBar(props: {
	overallProgress: {numberOfCompleted: number; score: number};
	stats: {
		numberOfExercises: number;
		maxScore: number;
	};
	loading?: boolean;
	leftLabel: React.ReactNode;
	rightLabel: React.ReactNode;
}): JSX.Element {
	const {stats, overallProgress, loading, leftLabel, rightLabel} = props;

	return (
		<ProgressTooltip
			completed={overallProgress.numberOfCompleted}
			total={stats.numberOfExercises}
			score={overallProgress.score}
			maxScore={stats.maxScore}
		>
			<ProgressBar
				theme={props.loading ? "grey" : "secondary"}
				value={overallProgress.numberOfCompleted}
				maxValue={stats.numberOfExercises}
				leftLabel={
					loading ? (
						<Skeleton variant="rectangular">{leftLabel}</Skeleton>
					) : (
						leftLabel
					)
				}
				rightLabel={
					loading ? (
						<Skeleton variant="rectangular">{rightLabel}</Skeleton>
					) : (
						rightLabel
					)
				}
			/>
		</ProgressTooltip>
	);
}

export default PlainProgressBar;
