import {Localized, useLocalization} from "@fluent/react";
import {Box, TextField, useTheme} from "@mui/material";
import React, {useCallback} from "react";

import useStateWithDebounce from "../../../hooks/useStateWithDebounce";
import SidebarBody from "../../sidebars/SidebarBody";
import SidebarHeader from "../../sidebars/SidebarHeader";
import SidebarSection from "../../sidebars/SidebarSection";
import {courseService} from "../../../store/services/courseService";
import type {UserSearchCriteria} from "../../../store/services/userService";
import UserRoleSelector from "../../users/UserRoleSelector";
import AppBarDependentDrawer from "../../../utils/AppBarDependentDrawer";
import PaginatedAutocomplete from "../../../utils/autocomplete/PaginatedAutocomplete";

const Filters = (props: {
	organisationName: string;
	open: boolean;
	width: number;
	mobileMode: boolean;

	criteria: UserSearchCriteria;

	onClose: () => void;
	onCriteriaChange: (
		set: (prev: UserSearchCriteria) => UserSearchCriteria
	) => void;
}): JSX.Element => {
	const {open, organisationName, onCriteriaChange} = props;

	const {l10n} = useLocalization();
	const theme = useTheme();

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	const fetchCoursePage = useCallback(
		(query: string, pageSize: number) => {
			return courseService.searchOrganisationCourses(
				organisationName,
				{query},
				{field: "name"},
				pageSize
			);
		},
		[organisationName]
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			onClose={props.onClose}
		>
			<SidebarBody>
				<Localized id="administration-filters" attrs={{title: true}}>
					<SidebarHeader title="Filters" onClose={props.onClose} />
				</Localized>
				<SidebarSection
					title={l10n.getString("administration-users-filters-roles")}
				>
					<UserRoleSelector
						selected={criteria.roles ?? []}
						onChange={(roles) => setCriteria((prev) => ({...prev, roles}))}
					/>
				</SidebarSection>
				<SidebarSection title={l10n.getString("administration-courses")}>
					<PaginatedAutocomplete
						noOptionsText={
							<Localized id="administration-users-course-selector-no-course">
								No such course
							</Localized>
						}
						pageFetcher={fetchCoursePage}
						optionMapper={(x) => x}
						onChange={(ids) =>
							setCriteria((prev) => ({...prev, courseIds: ids}))
						}
					/>
				</SidebarSection>
				<SidebarSection title={l10n.getString("administration-lastlogin")}>
					<Box display="flex">
						<TextField
							fullWidth
							type="date"
							label={<Localized id="administration-from">From</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								marginRight: theme.spacing(1),
							}}
							value={criteria.lastLoginAfter ?? ""}
							onChange={(event) =>
								setCriteria((prev) => ({
									...prev,
									lastLoginAfter: event.target.value,
								}))
							}
							InputProps={{inputProps: {max: criteria.lastLoginBefore}}}
						/>
						<TextField
							fullWidth
							type="date"
							label={<Localized id="administration-to">To</Localized>}
							InputLabelProps={{
								shrink: true,
							}}
							value={criteria.lastLoginBefore ?? ""}
							onChange={(event) =>
								setCriteria((prev) => ({
									...prev,
									lastLoginBefore: event.target.value,
								}))
							}
							InputProps={{inputProps: {min: criteria.lastLoginAfter}}}
						/>
					</Box>
				</SidebarSection>
			</SidebarBody>
		</AppBarDependentDrawer>
	);
};

export default React.memo(Filters);
