import {axiosInstance as client} from "./axiosInstance";
import {createPage} from "../../helpers/paginatedSearchHelpers";
import type {Page} from "../../helpers/paginatedSearchHelpers";
import type ExerciseType from "../exercises/ExerciseType";

type StudentChapterProgress = {
	chapterId: number;
	number: number;
	startTime?: string;
	submissionTime?: string;
	numberOfExercises: number;
	maxScore: number;
	outcomes: {
		exerciseId: number;
		exerciseType: ExerciseType;
		startTime: string;
		submissionTime?: string;
		score: number;
		maxScore: number;
	}[];
};

export type StudentRanking = {
	number: number;
	ranking: number;
	id: number;
	score: number;
	numberOfCompleted: number;
};

export type AssessmentStatus = "assessed" | "not_assessed";

export type StudentResultsInCourse = {
	id: number;
	userName: string;
	firstName: string;
	lastName: string;
	score: number;
	numberOfCompletedExercises: number;
	assessmentStatus: AssessmentStatus;
};

export type StudentProgressByType = {
	studentId: number;
	chapters: ProgressByChapter[];
};
export type ProgressByChapter = {
	chapterId: number;
	progress: ProgressByType[];
};
export type ProgressByType = {
	exerciseType: ExerciseType;
	numberOfCompleted: number;
	score: number;
};

export type StudentResultsSearchCriteria = {
	query: string;
	assessmentStatus?: AssessmentStatus;
};

export const analyticsService = {
	getCourseStudyPeriod: async (
		studentId: number,
		courseId: number
	): Promise<{
		startDate: string;
		endDate: string;
	} | null> => {
		const {data} = await client.get(
			`/api/students/${studentId}/courses/${courseId}/study-period`
		);

		if (!data) {
			return null;
		}

		return {
			startDate: data.start_date,
			endDate: data.end_date,
		};
	},

	updateCourseStudyPeriod: async (
		studentId: number,
		courseId: number,
		period: {startDate: string; endDate: string}
	): Promise<void> => {
		await client.put(
			`/api/students/${studentId}/courses/${courseId}/study-period`,
			{
				start_date: period.startDate,
				end_date: period.endDate,
			}
		);
	},

	getStudentCourseProgress: async (
		studentId: number,
		courseId: number
	): Promise<StudentChapterProgress[]> => {
		const {data} = await client.get(
			`/api/students/${studentId}/courses/${courseId}/progress`
		);

		return data;
	},

	getStudentRankings: async (
		studentId: number,
		courseId: number,
		distance = 4
	): Promise<StudentRanking[]> => {
		const params = new URLSearchParams();
		params.append("distance", distance.toString());
		params.append("neighbourhoodOf", studentId.toString());

		const {data} = await client.get(
			`/api/courses/${courseId}/student-rankings`,
			{params}
		);

		return data;
	},

	getProgressByType: async (
		courseId: number,
		...studentIds: number[]
	): Promise<StudentProgressByType[]> => {
		const params = new URLSearchParams();

		studentIds.forEach((id) => params.append("id", id.toString()));

		const {
			data,
		} = await client.get(
			`/api/courses/${courseId}/student-progress-by-exercise-type`,
			{params}
		);

		return data.content;
	},

	searchStudentResultsInCourse: async (
		courseId: number,
		criteria: StudentResultsSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<StudentResultsInCourse>> => {
		const url = `/api/courses/${courseId}/student-results`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);

		params.append("pageSize", pageSize.toString());

		if (criteria.query) {
			params.append("query", criteria.query);
		}

		if (criteria.assessmentStatus) {
			params.append("assessmentStatus", criteria.assessmentStatus);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	markAssessed: async (
		courseId: number,
		studentIds: number[]
	): Promise<void> => {
		await client.post(`/api/courses/${courseId}/assessed-students`, studentIds);
	},

	markUnassessed: async (
		courseId: number,
		studentIds: number[]
	): Promise<void> => {
		await client.delete(`/api/courses/${courseId}/assessed-students`, {
			data: studentIds,
		});
	},
};
