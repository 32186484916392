import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";
import selectLastRequestedSessionKey from "./selectLastRequestedSessionKey";

function selectChapterSessionContentStatus(
	state: RootState,
	sessionKey: string
): FetchStatus {
	const lastRequestedSessionKey = selectLastRequestedSessionKey(state);
	if (sessionKey !== lastRequestedSessionKey) {
		return "none";
	}

	return state.chapterSessions.lastRequested.status;
}

export default selectChapterSessionContentStatus;
