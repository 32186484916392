import {createAsyncThunk} from "@reduxjs/toolkit";

import widgetService from "../services/widgetService";
import type DashboardName from "./DashboardName";
import {keyProvider} from "../keyProvider";
import type WidgetKey from "./WidgetKey";

const removeWidget = createAsyncThunk(
	"widgets/remove",
	async (params: {
		dashboardName: DashboardName;
		userId: number;
		courseId: number;
		widgetKey: WidgetKey;
	}) => {
		const {courseId, dashboardName, userId, widgetKey} = params;

		await widgetService.deleteWidget(
			userId,
			courseId,
			dashboardName,
			widgetKey
		);

		return {dashboardName, courseKey: keyProvider.course(courseId), widgetKey};
	}
);

export default removeWidget;
