import React, {useCallback, useState} from "react";
import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@mui/material";

import {adminService} from "../../store/services/adminService";
import {organisationService} from "../../store/services/organisationService";
import PaginatedSingleSelectionAutocomplete from "../../utils/autocomplete/PaginatedSingleSelectionAutocomplete";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";
import useSnackbar from "../../store/ui/useSnackbar";

function GrantAdminRoleDialog(props: {
	titleElementId: string;
	groupName: string;
	userIds: number[];
	selectedUserName?: string;
	onCancel: () => void;
	onGranted: () => void;
}) {
	const {groupName, selectedUserName} = props;

	const [selectedOrg, setSelectedOrg] = useState("");
	const [emptyOrg, setEmptyOrg] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	const fetchPage = useCallback(
		(query: string, pageSize: number) => {
			return organisationService.searchGroupOrganisations(
				groupName,
				{query},
				{field: "displayName"},
				pageSize
			);
		},
		[groupName]
	);

	async function grant() {
		if (selectedOrg === "") {
			setEmptyOrg(true);
			return;
		}

		setInProgress(true);

		try {
			await adminService.grantAdminRigts(selectedOrg, props.userIds);
			props.onGranted();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		} finally {
			setInProgress(false);
		}
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={
					<Localized id="group-users-grant-admin-role-dialog-title">
						Grant administrator role
					</Localized>
				}
				inProgress={inProgress}
				onClose={props.onCancel}
			/>

			<DialogContent dividers>
				<DialogContentText>
					{(props.userIds.length > 1 || !selectedUserName) && (
						<Localized
							id="group-users-grant-admin-role-dialog-content-no-username"
							vars={{number: props.userIds.length}}
						>
							Select an organisation where theses users should be
							administrators.
						</Localized>
					)}
					{props.userIds.length === 1 && selectedUserName && (
						<Localized
							id="group-users-grant-admin-role-dialog-content-one-user"
							vars={{user: selectedUserName}}
						>
							Select an organisation where the user should be an administrator.
						</Localized>
					)}
				</DialogContentText>

				<PaginatedSingleSelectionAutocomplete
					label={
						<Localized id="group-users-grant-admin-role-dialog-label-organisation">
							Organisation
						</Localized>
					}
					required
					error={emptyOrg}
					pageFetcher={fetchPage}
					optionMapper={(v) => ({
						id: v.name,
						name: v.displayName,
						secondaryName: v.name,
					})}
					onChange={(name) => {
						setSelectedOrg(name ?? "");
						setEmptyOrg(false);
					}}
				/>
			</DialogContent>

			<DialogActions>
				<Button color="primary" disabled={inProgress} onClick={props.onCancel}>
					<Localized id="group-users-grant-admin-role-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<SubmitButton inProgress={inProgress} variant="text" onClick={grant}>
					<Localized id="group-users-grant-admin-role-dialog-action-grant">
						Grant
					</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

export default GrantAdminRoleDialog;
