import type {AxiosInstance} from "axios";

import {axiosInstance as client} from "./axiosInstance";
import type WidgetKey from "../widgets/WidgetKey";
import type DashboardName from "../widgets/DashboardName";
import type WidgetSettings from "../widgets/WidgetSettings";

function createService(client: AxiosInstance) {
	async function getWidgets(
		userId: number,
		courseId: number,
		dashboardName: DashboardName
	): Promise<{name: WidgetKey; settings: WidgetSettings}[]> {
		const {data} = await client.get(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets`
		);

		return data.content;
	}

	async function deleteWidget(
		userId: number,
		courseId: number,
		dashboardName: DashboardName,
		widgetKey: WidgetKey
	): Promise<void> {
		await client.delete(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets/${widgetKey}`
		);
	}

	async function insertWidget(
		userId: number,
		courseId: number,
		dashboardName: DashboardName,
		widgetName: WidgetKey,
		place?: {
			before: WidgetKey;
		}
	): Promise<void> {
		await client.post(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets`,
			{
				name: widgetName,
				before: place?.before,
			}
		);
	}

	async function updateSettings(
		userId: number,
		courseId: number,
		dashboardName: DashboardName,
		widgetKey: WidgetKey,
		settings: WidgetSettings
	) {
		await client.put(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets/${widgetKey}`,
			{
				name: widgetKey,
				settings,
			}
		);
	}

	return {
		getWidgets,
		deleteWidget,
		insertWidget,
		updateSettings,
	};
}

export default createService(client);
