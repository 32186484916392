import type {AxiosInstance} from "axios";

import {axiosInstance as client} from "./axiosInstance";
import type FrontEndFeature from "../features/FrontEndFeature";

type Response = {
	[key in FrontEndFeature]: boolean;
};

function createService(client: AxiosInstance) {
	async function getFeatures() {
		const {data} = await client.get<Response>(`/features.json`);

		return Object.entries(data)
			.filter(([, value]) => value)
			.map(([key]) => key as FrontEndFeature);
	}

	return {
		getFeatures: getFeatures,
	};
}

export default createService(client);
