import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"&, .MuiListItemIcon-root": {
				color: (p: {active: boolean}) =>
					p.active
						? theme.palette.secondary.main
						: theme.palette.text.secondary,
			},
		},
	})
);

const NavigationItem = (props: {
	icon: React.ReactNode;
	label: React.ReactNode;
	messageCount: number;
	active: boolean;
	onClick: () => void;
}): JSX.Element => {
	const classes = useStyles({active: props.active});

	return (
		<ListItem className={classes.root} disablePadding>
			<ListItemButton onClick={props.onClick}>
				<ListItemIcon>{props.icon}</ListItemIcon>
				<ListItemText
					primary={props.label}
					primaryTypographyProps={{variant: "subtitle2"}}
				/>
				<ListItemText
					secondary={props.messageCount}
					secondaryTypographyProps={{variant: "body2"}}
					sx={{textAlign: "right"}}
				/>
			</ListItemButton>
		</ListItem>
	);
};

export default NavigationItem;
