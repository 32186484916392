import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Box, DialogContent} from "@mui/material";
import {SimpleTreeView} from "@mui/x-tree-view";
import React, {useCallback, useState} from "react";

import ExerciseLabel from "../treeview/ExerciseLabel";
import type LabelProps from "../treeview/LabelProps";
import SectionLabel from "../treeview/SectionLabel";
import {getChapters} from "../../store/chapters/getChapters";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import ChapterNavItem from "../treeview/ChapterNavItem";
import type Chapter from "../../store/chapters/Chapter";
import {SelectionModes} from "../../store/chapters/SelectionMode";
import parseDate from "../../helpers/parseDate";

const ChooseExerciseDialogContent = (props: {
	courseId: number;
	onExerciseSelected: (exerciseKey: string) => void;
}): JSX.Element => {
	const {onExerciseSelected} = props;

	const [expanded, setExpanded] = useState<string[]>([]);

	const renderExerciseLabel = useCallback(
		(params: LabelProps, exerciseKey: string) => (
			<ExerciseLabel
				index={params.index}
				text={params.text}
				onClick={() => onExerciseSelected(exerciseKey)}
			/>
		),
		[onExerciseSelected]
	);

	const chapterKeys = useAppSelector((state) =>
		selectCourseChapterKeys(state, keyProvider.course(props.courseId))
	);

	const dispatch = useAppDispatch();

	if (chapterKeys.length === 0) {
		dispatch(getChapters(props.courseId));
	}

	function defineChapterNavItemStyle(chapter: Chapter) {
		const ordinaryChapter =
			chapter.submissionMode !== SubmissionMode.Simultaneous &&
			chapter.selectionMode !== SelectionModes.Random;

		return {
			expandable: ordinaryChapter,
			clickable: ordinaryChapter,
			disabled:
				!ordinaryChapter ||
				!chapter.startDate ||
				parseDate(chapter.startDate) >= new Date(),
		};
	}

	return (
		<DialogContent dividers>
			<Box mt={2}>
				<SimpleTreeView
					expandedItems={expanded}
					selectedItems={null}
					slots={{expandIcon: ExpandIcon, collapseIcon: CollapseIcon}}
				>
					{chapterKeys.map((key) => (
						<ChapterNavItem
							key={key}
							courseId={props.courseId}
							chapterKey={key}
							renderSectionLabel={renderSectionLabel}
							renderExerciseLabel={renderExerciseLabel}
							defineItemType={defineChapterNavItemStyle}
							onClick={(chapter) => {
								const {expandable} = defineChapterNavItemStyle(chapter);
								if (expandable) {
									setExpanded((prev) => (prev.includes(key) ? [] : [key]));
								}
							}}
						/>
					))}
				</SimpleTreeView>
			</Box>
		</DialogContent>
	);
};

function ExpandIcon() {
	return <ExpandMoreIcon color="primary" />;
}

function CollapseIcon() {
	return <ExpandLessIcon color="primary" />;
}

function renderSectionLabel(params: LabelProps) {
	return <SectionLabel index={params.index} text={params.text} />;
}

export default ChooseExerciseDialogContent;
