import {RootState} from "../store";
import {CompletionStatus, ExerciseOutcomes} from "../outcomes/ExerciseOutcomes";

const empty = {
	completionStatus: CompletionStatus.Unattempted,
	score: 0,
};

const selectChapterSessionOutcomes = (
	state: RootState,
	sessionKey: string,
	chapterExerciseKey: string
): Readonly<ExerciseOutcomes> => {
	if (state.chapterSessions.outcomes.sessionKey !== sessionKey) {
		return empty;
	}
	return state.chapterSessions.outcomes.outcomes[chapterExerciseKey] ?? empty;
};

export default selectChapterSessionOutcomes;
