import {Localized} from "@fluent/react";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";

type Props = {
	disabled?: boolean;
	onClick?: () => void;
};

const useStyles = makeStyles(() => ({
	button: {
		width: "84px",
	},
}));

const SaveButton = (props: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<Button
			className={classes.button}
			color="primary"
			disabled={props.disabled}
			onClick={props.onClick}
		>
			<Localized id="utils-button-save">Save</Localized>
		</Button>
	);
};

export default SaveButton;
