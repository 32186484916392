import {Box, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useMemo} from "react";

import ExerciseQuestion from "./exercises/ExerciseQuestion";
import Exercises from "./exercises/Exercises";
import {useAppSelector} from "../../store/hooks";
import type SelectionMode from "../../store/chapters/SelectionMode";
import type SubmissionMode from "../../store/chapters/SubmissionMode";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useSnackbar from "../../store/ui/useSnackbar";
import AssessmentDeliveryContext from "../AssessmentDeliveryContext";

type Props = {
	courseId: number;
	sectionKey: string;
	submissionMode: SubmissionMode;
	selectionMode: SelectionMode;
	readonly?: boolean;
	submissionDisabled?: boolean;
};

function SectionContent({
	courseId,
	sectionKey,
	submissionMode,
	selectionMode,
	readonly,
	submissionDisabled,
}: Props): JSX.Element {
	const {
		fetchSectionContent,
		fetchResponses,
		makeSelectSection,
		selectSectionContentStatus,
		selectSubsectionKeys,
	} = useContext(AssessmentDeliveryContext);

	const selectSection = useMemo(makeSelectSection, [makeSelectSection]);

	const section = useAppSelector((state) => selectSection(state, sectionKey));

	const subsections = useAppSelector((state) =>
		selectSubsectionKeys(state, sectionKey)
	);

	const contentRequested = useAppSelector(
		(state) => selectSectionContentStatus(state, sectionKey) !== "none"
	);

	useEffect(() => {
		if (!contentRequested) {
			fetchSectionContent(sectionKey);
		}
	}, [contentRequested, fetchSectionContent, sectionKey]);

	useEffect(() => {
		fetchResponses(sectionKey);
	}, [fetchResponses, sectionKey]);

	const showSnackbar = useSnackbar();
	const [confirmDialog, openConfirmDialog] = useConfirmationDialog();

	const theme = useTheme();

	if (!section) {
		return <></>;
	}

	return (
		<>
			<Typography variant="h4" style={{gap: theme.spacing(1), display: "flex"}}>
				<Typography component="span" variant="inherit" color="textSecondary">
					{section.number}
				</Typography>
				{section.title}
			</Typography>

			<Box mt={4} mb={7}>
				<ExerciseQuestion text={section.content} />
			</Box>

			<Exercises
				courseId={courseId}
				sectionNumber={section.number}
				exerciseKeys={subsections}
				submissionMode={submissionMode}
				selectionMode={selectionMode}
				readonly={readonly}
				submissionDisabled={submissionDisabled}
				openConfirmDialog={openConfirmDialog}
				showSnackbar={showSnackbar}
			/>

			{confirmDialog}
		</>
	);
}

export default React.memo(SectionContent);
