import {Localized} from "@fluent/react";
import {Typography} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import ExerciseType from "../../store/exercises/ExerciseType";
import exerciseColorMap from "../followUp/exerciseColorMap";
import calculatePercentage from "../../helpers/calculatePercentage";

const useStyles = makeStyles<Theme, {color: string}>((theme) =>
	createStyles({
		colorBar: {
			height: theme.spacing(0.5),
			width: theme.spacing(3),
			background: ({color}) => color,
		},
		root: {
			display: "flex",
			alignItems: "center",
			gap: theme.spacing(0.5),
		},
	})
);

function LegendItem(props: {
	type: ExerciseType;
	value: number;
	score: number;
	maxValue?: number;
	maxScore?: number;
}) {
	const {value, maxValue, score, maxScore} = props;
	const classes = useStyles({color: exerciseColorMap[props.type]});

	const percentage = maxValue && calculatePercentage(value, maxValue);
	const scorePercentage = maxScore && calculatePercentage(score, maxScore);

	return (
		<div className={classes.root}>
			<div className={classes.colorBar}></div>
			<Typography>
				{maxScore && maxValue ? (
					<Localized
						id={`widget-progress-bar-legend-item-with-max-${props.type}`}
						vars={{
							value,
							maxValue,
							score,
							maxScore,
							percentage: percentage ?? 0,
							scorePercentage: scorePercentage ?? 0,
						}}
					>
						{`– ${props.type} exercises: completed ${value}/${maxValue}, ${percentage}%; ` +
							`score ${score}/${maxScore}, ${scorePercentage}%`}
					</Localized>
				) : (
					<Localized
						id={`widget-progress-bar-legend-item-${props.type}`}
						vars={{value, score}}
					>{`– ${props.type} exercises: completed ${value}; score ${score}`}</Localized>
				)}
			</Typography>
		</div>
	);
}

const typesInOrder = [
	ExerciseType.Math,
	ExerciseType.Prog,
	ExerciseType.Multi,
	ExerciseType.Short,
	ExerciseType.Open,
	ExerciseType.External,
];

function ProgressBarLegend(props: {
	progress: {
		[key in ExerciseType]?: {numberOfCompleted: number; score: number};
	};
	stats?: {
		[key in ExerciseType]?: {numberOfExercises: number; maxScore: number};
	};
}): JSX.Element {
	const {progress, stats} = props;

	const items: JSX.Element[] = [];

	typesInOrder.forEach((t) => {
		const p = progress[t];
		const st = stats && stats[t];

		if (!p && !st) {
			return;
		}

		items.push(
			<LegendItem
				key={t}
				type={t}
				value={p?.numberOfCompleted ?? 0}
				score={p?.score ?? 0}
				maxValue={st?.numberOfExercises}
				maxScore={st?.maxScore}
			/>
		);
	});

	return <>{items}</>;
}

function calculateValuesForColors(
	progress: {[key in ExerciseType]?: {numberOfCompleted: number}}
): {
	color: string;
	value: number;
}[] {
	const values: {
		color: string;
		value: number;
	}[] = [];

	typesInOrder.forEach((t) => {
		const v = progress[t];
		if (v && v.numberOfCompleted > 0) {
			values.push({color: exerciseColorMap[t], value: v.numberOfCompleted});
		}
	});

	return values;
}

export {calculateValuesForColors};
export default ProgressBarLegend;
