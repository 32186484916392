import {Localized} from "@fluent/react";
import {Box, Container, Link, Typography, useTheme} from "@mui/material";
import React from "react";

import {resolveLinkToOldSite} from "../../helpers/pathHelpers";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import CourseType from "../../store/courses/CourseType";
import selectCourse from "../../store/courses/selectCourse";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";

const CourseChaptersHaveOldStructureWarning = (props: {
	courseId: number;
	all: boolean;
}): JSX.Element => {
	const navBarHeight = useNavBarHeight();
	const theme = useTheme();

	const exam = useAppSelector(
		(state) =>
			selectCourse(state, keyProvider.course(props.courseId))?.type ===
			CourseType.Exam
	);

	const link = resolveLinkToOldSite(
		`teacher/${props.courseId}/#/theory${exam ? "?exam=1" : ""}`
	);

	return (
		<Container
			style={{
				height: `calc(100vh - ${navBarHeight}px + ${theme.spacing(12)})`,
				marginBottom: theme.spacing(6),
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				height="100%"
			>
				<Typography variant="h6">
					{props.all ? (
						<Localized id="learning-material-content-old-course-warning-title-all-chapters">
							Chapters of the course have old structure
						</Localized>
					) : (
						<Localized id="learning-material-content-old-course-warning-title-some-chapters">
							Some chapters of the course have old structure
						</Localized>
					)}
				</Typography>
				<Typography>
					<Localized
						id="learning-material-content-old-course-warning-link"
						elems={{ref: <Link href={link}></Link>}}
					>
						<>{"You can edit them in <ref>the old interface</ref>."}</>
					</Localized>
				</Typography>
			</Box>
		</Container>
	);
};

const ChapterHasOldStructureWarning = (props: {
	courseId: number;
}): JSX.Element => {
	const exam = useAppSelector(
		(state) =>
			selectCourse(state, keyProvider.course(props.courseId))?.type ===
			CourseType.Exam
	);

	const link = resolveLinkToOldSite(
		`teacher/${props.courseId}/#/theory${exam ? "?exam=1" : ""}`
	);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" py={4}>
			<Typography variant="subtitle2">
				<Localized id="learning-material-content-old-chapter-warning-title">
					Chapter has old structure
				</Localized>
			</Typography>
			<Typography variant="body2">
				<Localized
					id="learning-material-content-old-chapter-warning-link"
					elems={{ref: <Link href={link}></Link>}}
				>
					<>{"You can edit it in <ref>the old interface</ref>"}</>
				</Localized>
			</Typography>
		</Box>
	);
};

export default CourseChaptersHaveOldStructureWarning;
export {ChapterHasOldStructureWarning};
