import {Localized} from "@fluent/react";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {useParams} from "react-router-dom";

import DividerWithButton from "./DividerWithButton";
import LearningMaterialExerciseContent from "./LearningMaterialExerciseContent";
import MathExerciseEditor from "./MathExerciseEditor";
import MultiExerciseEditor from "./MultiExerciseEditor";
import OpenExerciseEditor from "./OpenExerciseEditor";
import SectionDescriptionEditor from "./SectionDescriptionEditor";
import SectionTitleEditor from "./SectionTitleEditor";
import updateSectionSettings from "../../store/sections/updateSectionSettings";
import SectionSettingsEditor from "./SectionSettingsEditor";
import TheoryExerciseEditor from "./TheoryExerciseEditor";
import ExerciseQuestion from "../content/exercises/ExerciseQuestion";
import ExerciseType from "../../store/exercises/ExerciseType";
import {
	DraftType,
	editingEnded,
	editingStarted,
} from "../../store/exercises/exericseDraftSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {getSectionContent} from "../../store/sections/getSectionContent";
import makeSelectSection from "../../store/sections/makeSelectSection";
import selectSubsectionKeys, {
	selectSectionContentStatus,
} from "../../store/sections/selectSubsectionKeys";
import {ModalDialogName, openDialog} from "../../store/ui/modalDialogSlice";
import selectChapter from "../../store/chapters/selectChapter";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import {fetchChapterExercise} from "../../store/chapterExercises/fetchChapterExercise";
import ExternalExerciseEditor from "./ExternalExerciseEditor";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import selectExerciseDraft from "../../store/exercises/selectExerciseDraft";
import type {
	EditableExercise,
	ProgExercise,
} from "../../store/services/dtos/EditableExercise";
import {saveExerciseDraft} from "../../store/exercises/saveExerciseDraft";
import useSnackbar from "../../store/ui/useSnackbar";
import {SelectionModes} from "../../store/chapters/SelectionMode";
import ProgExerciseEditor from "./ProgExerciseEditor";
import buildProgExercise from "../../store/exercises/prog/buildExercise";
import ExtendableBox from "../../utils/ExtendableBox";
import exampleOutputChanged from "../../store/exercises/prog/exampleOutputChanged";
import SidebarContext, {SidebarType} from "../sidebars/SidebarContext";
import ContentSidebar from "../sidebars/ContentSidebar";

const sidebarDrawerWidth = 320;

const useStyles = makeStyles((theme) =>
	createStyles({
		smallGutters: {
			display: "flex",
			gap: theme.spacing(1),
		},
		divider: {
			marginLeft: theme.spacing(-3),
			marginRight: theme.spacing(-6),
		},
	})
);

const SectionContentManagement = (props: {
	organisationName: string;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
}): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();

	const dispatch = useAppDispatch();

	const showSnackbar = useSnackbar();

	const mobileMode = useMobileMode("lg");

	const selectSection = useMemo(makeSelectSection, []);

	const params = useParams<{
		id: string;
		chapterId: string;
		sectionId: string;
	}>();

	const courseId = Number(params.id);
	const chapterId = Number(params.chapterId);
	const sectionId = Number(params.sectionId);

	const sectionKey = keyProvider.section(chapterId, sectionId);

	const draft = useAppSelector(selectExerciseDraft);

	const editing = draft !== null;

	const section = useAppSelector((state) => selectSection(state, sectionKey));
	const subsectionKeys = useAppSelector((state) =>
		selectSubsectionKeys(state, sectionKey)
	);

	const [editorOpen, setEditorOpen] = useState<boolean>();
	const [sectionTitleEdit, setSectionTitleEdit] = useState(false);
	const [sectionDesriptionEdit, setSectionDescriptionEdit] = useState(false);

	const contentRequested = useAppSelector(
		(state) => selectSectionContentStatus(state, sectionKey) !== "none"
	);

	const chapterKey = keyProvider.chapter(chapterId);
	const chapter = useAppSelector((state) => selectChapter(state, chapterKey));

	useEffect(() => {
		if (!contentRequested && section) {
			dispatch(getSectionContent({courseId, sectionKey}));
		}
	}, [contentRequested, courseId, dispatch, section, sectionKey]);

	useEffect(() => {
		if (draft?.sectionId !== sectionId) {
			setEditorOpen(false);
		}
	}, [draft?.sectionId, sectionId]);

	useEffect(() => {
		if (draft?.newlyAdded !== editorOpen) {
			setEditorOpen(draft?.newlyAdded);
		}
	}, [editorOpen, draft?.newlyAdded]);

	const endEditing = useCallback(() => {
		dispatch(editingEnded());
	}, [dispatch]);

	const changeExampleOutput = useCallback(
		(exerciseId: number, exampleOutput: string) => {
			dispatch(
				exampleOutputChanged({
					exerciseId,
					exampleOutput,
				})
			);
		},
		[dispatch]
	);

	const patchSectionSettings = useCallback(
		(settings: {selectionSize?: number; maxScorePerExercise?: number}) => {
			dispatch(
				updateSectionSettings({courseId, chapterId, sectionId, settings})
			);
		},
		[courseId, chapterId, sectionId, dispatch]
	);

	const sidebarCtx = useContext(SidebarContext);

	if (!section || !chapter) {
		return <></>;
	}

	const submissionMode = chapter.submissionMode;

	const chapterPublishedOrScheduled = Boolean(chapter.startDate);

	const selectionMode = chapter.selectionMode;

	const editingAllowed = section.originId === courseId && !props.viewOnly;

	const openAddExerciseDialog = () => {
		dispatch(
			openDialog({
				dialogName: ModalDialogName.AddExercise,
				dialogParams: {
					chapterId,
					courseId,
					sectionId,
				},
			})
		);
		setEditorOpen(true);
	};

	const handleSectionUpdate = () => {
		dispatch(
			editingStarted({
				id: section.id,
				type: DraftType.Section,
				title: section.title,
				content: section.content,
			})
		);
	};

	const handleSectionDescriptionUpdate = () => {
		handleSectionUpdate();
		setSectionDescriptionEdit(true);
	};

	const closeEditor = async (updated: boolean) => {
		setEditorOpen(false);
		if (updated) {
			await dispatch(getChapterContent({courseId, chapterId}));
			dispatch(getSectionContent({courseId, sectionKey}));
		}
	};

	const closeDescriptionEditor = (updated: boolean) => {
		setSectionDescriptionEdit(false);
		if (updated) {
			dispatch(
				fetchChapterExercise({
					courseId,
					chapterId,
					exerciseId: section.id,
					sectionId: section.id,
				})
			);
		}
	};

	const saveEdited = async (exercise: EditableExercise) => {
		const result = await dispatch(
			saveExerciseDraft({
				courseId,
				chapterId,
				sectionId,
				exercise,
			})
		);

		return unwrapResult(result).id;
	};

	const build = async (exerciseId: number, exercise: ProgExercise) => {
		const result = await dispatch(
			buildProgExercise({
				courseId,
				exerciseId,
				exercise,
			})
		);

		return unwrapResult(result).buildResult;
	};

	const selectExerciseEditor = (exerciseType?: ExerciseType) => {
		const editorProps = {
			organisationName: props.organisationName,
			courseId,
			exercise: null,
			onClose: closeEditor,
			onSave: saveEdited,
			onUnmount: endEditing,
		};

		switch (exerciseType) {
			case ExerciseType.Theory:
				return (
					<TheoryExerciseEditor
						courseId={courseId}
						chapterId={chapterId}
						sectionId={sectionId}
						onClose={closeEditor}
					/>
				);
			case ExerciseType.Open:
				return <OpenExerciseEditor {...editorProps} />;
			case ExerciseType.Multi:
				return <MultiExerciseEditor {...editorProps} />;
			case ExerciseType.Math:
				return <MathExerciseEditor {...editorProps} />;
			case ExerciseType.External:
				return <ExternalExerciseEditor {...editorProps} />;
			case ExerciseType.Prog:
				return (
					<ProgExerciseEditor
						{...editorProps}
						onBuild={build}
						onExampleOutputChanged={changeExampleOutput}
					/>
				);
			default:
				return <></>;
		}
	};

	const handleSectionTitleUpdate = () => {
		handleSectionUpdate();
		setSectionTitleEdit(true);
	};

	const DescriptionTitle = () => {
		if (section.content || sectionDesriptionEdit) {
			return (
				<Box mt={4} mb={1} display="flex" alignItems="center">
					<Localized
						id="learning-material-section-description-label"
						elems={{
							hint: (
								<Typography
									component="span"
									variant="inherit"
									color="textSecondary"
								></Typography>
							),
						}}
					>
						<Typography variant="subtitle2" className={classes.smallGutters}>
							{"Description <hint>(optional)</hint>"}
						</Typography>
					</Localized>

					{!sectionDesriptionEdit && editingAllowed && (
						<IconButton onClick={handleSectionDescriptionUpdate}>
							<EditIcon />
						</IconButton>
					)}
				</Box>
			);
		}

		return props.viewOnly ? (
			<></>
		) : (
			<Box mt={4}>
				<Button color="primary" onClick={handleSectionDescriptionUpdate}>
					<Localized id="learning-material-button-add-description">
						+ Add description
					</Localized>
				</Button>
			</Box>
		);
	};

	const content = (
		<Grid
			container
			style={{
				paddingBottom: editing ? theme.spacing(10) : theme.spacing(3),
				gap: theme.spacing(6),
			}}
		>
			<Grid item xs={12}>
				{sectionTitleEdit ? (
					<SectionTitleEditor
						setEdit={setSectionTitleEdit}
						exercise={section}
						courseId={courseId}
						chapterId={chapterId}
						sectionId={sectionId}
					/>
				) : (
					<Typography variant="h4" className={classes.smallGutters}>
						<Typography
							component="span"
							variant="inherit"
							color="textSecondary"
						>
							{section.number}
						</Typography>

						{section.title}

						{editingAllowed && (
							<IconButton onClick={handleSectionTitleUpdate}>
								<EditIcon />
							</IconButton>
						)}
					</Typography>
				)}

				<DescriptionTitle />

				{sectionDesriptionEdit ? (
					<SectionDescriptionEditor
						exercise={section}
						courseId={courseId}
						chapterId={chapterId}
						sectionId={sectionId}
						onClose={closeDescriptionEditor}
					/>
				) : (
					<ExerciseQuestion text={section.content || ""} />
				)}

				{selectionMode === SelectionModes.Random && (
					<Box mt={3}>
						<SectionSettingsEditor
							disabled={chapterPublishedOrScheduled}
							settings={settings(section)}
							onChange={patchSectionSettings}
							key={sectionKey}
						/>
					</Box>
				)}
			</Grid>

			{subsectionKeys.map((exerciseKey: string) => (
				<Fragment key={exerciseKey}>
					<Grid item xs={12}>
						<Divider className={classes.divider} />
					</Grid>

					<Grid item xs={12}>
						<LearningMaterialExerciseContent
							organisationName={props.organisationName}
							exerciseKey={exerciseKey}
							courseId={courseId}
							section={section}
							submissionMode={submissionMode}
							deletionDisabled={chapterPublishedOrScheduled || props.viewOnly}
							settingsEditingDisabled={
								chapterPublishedOrScheduled || props.viewOnly
							}
							viewOnly={props.viewOnly}
							showSnackbar={showSnackbar}
							openConfirmDialog={props.openConfirmDialog}
						/>
					</Grid>
				</Fragment>
			))}

			{!props.viewOnly && (
				<Grid item xs={12}>
					<DividerWithButton
						buttonHidden={editing}
						onClick={openAddExerciseDialog}
					>
						<Localized id="learning-material-section-management-button-add-subsection">
							+ Add Subsection
						</Localized>
					</DividerWithButton>
				</Grid>
			)}

			{editorOpen && (
				<Grid item xs={12} style={{marginBottom: theme.spacing(6)}}>
					{selectExerciseEditor(draft?.exerciseType)}
				</Grid>
			)}
		</Grid>
	);

	return (
		<Box display="flex">
			<ExtendableBox
				rightExtension={
					sidebarCtx.type !== SidebarType.None ||
					sidebarCtx.fullscreen ||
					mobileMode
						? 0
						: sidebarDrawerWidth
				}
				minWidth={0}
			>
				{content}
			</ExtendableBox>
			<ContentSidebar
				width={sidebarDrawerWidth}
				sectionKey={sectionKey}
				courseId={courseId}
				mobileMode={mobileMode}
			/>
		</Box>
	);
};

function settings<
	T extends {selectionSize: number; maxScorePerExercise: number}
>(section: T) {
	return {
		selectionSize: section.selectionSize,
		maxScorePerExercise: section.maxScorePerExercise,
	};
}

export default SectionContentManagement;
