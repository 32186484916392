import type FileUploader from "../../utils/attachments/FileUploader";
import type UploadedFile from "../../utils/attachments/UploadedFile";
import DocumentFormat from "../../utils/attachments/DocumentFormat";
import useFileUploader from "../../utils/attachments/useFileUploader";
import createFileUploader from "../../utils/attachments/createFileUploader";
import {useCallback} from "react";

function useExerciseFileUploader(
	userId: number,
	courseId: number,
	exerciseId: number
): [UploadedFile[], FileUploader | null] {
	const creator = useCallback(
		() =>
			exerciseId
				? createFileUploader({
						courseId,
						documentFormat: DocumentFormat.ExerciseContent,
						exerciseId,
						personId: userId,
				  })
				: null,
		[courseId, exerciseId, userId]
	);

	return useFileUploader(creator);
}
export default useExerciseFileUploader;
