import {IconButton, IconButtonProps, Tooltip} from "@mui/material";
import React from "react";

function IconButtonWithTooltip(
	props: IconButtonProps & {tooltipTitle: NonNullable<React.ReactNode>}
) {
	const {tooltipTitle, ...iconButton} = props;

	return (
		<Tooltip title={tooltipTitle} enterDelay={500} enterNextDelay={500}>
			<span>
				<IconButton {...iconButton} />
			</span>
		</Tooltip>
	);
}

export default IconButtonWithTooltip;
