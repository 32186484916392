import {useCallback} from "react";
import type FetchStatus from "../FetchStatus";
import {useAppSelector} from "../hooks";
import UserRole from "../models/UserRole";
import selectUserRolesInOrganisation, {
	selectUserRolesInOrganisationFetchStatus,
} from "./selectUserRolesInOrganisation";

const useUserHasRoleInOrganisation = (): [
	(roleName: UserRole | "any") => boolean,
	FetchStatus
] => {
	const roles = useAppSelector(selectUserRolesInOrganisation);
	const fetchStatus = useAppSelector(selectUserRolesInOrganisationFetchStatus);

	const has = useCallback(
		(roleName: UserRole | "any") =>
			roleName === "any" ? roles.length > 0 : roles.includes(roleName),
		[roles]
	);

	return [has, fetchStatus];
};

export default useUserHasRoleInOrganisation;
