import {createAsyncThunk} from "@reduxjs/toolkit";

import authService from "../services/authService";
import sessionStore from "./sessionStore";

const refreshUserSession = createAsyncThunk("userSession/refresh", async () => {
	const current = await authService.getCurrentSession();
	if (current) {
		sessionStore.saveSession(current.session);
	} else {
		sessionStore.removeSession();
	}

	return current;
});

export default refreshUserSession;
