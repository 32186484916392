import React from "react";

import {SelectionModes} from "../../store/chapters/SelectionMode";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import TestProgress from "./TestProgress";
import type ChapterProgress from "../../store/courseProgress/ChapterProgress";
import type Chapter from "../../store/chapters/Chapter";
import ProgressTooltip from "../analytics/ProgressTooltip";
import StackedProgressBar from "../../utils/StackedProgressBar";
import type {ProgressByType} from "../../store/services/analyticsService";
import ProgressBarLegend, {
	calculateValuesForColors,
} from "../analytics/ProgressBarLegend";
import {useAppSelector} from "../../store/hooks";
import selectChapterStatisticsByType from "../../store/courseStatistics/selectCourseChaptersStatistics";
import {keyProvider} from "../../store/keyProvider";
import CompletionLabel from "../analytics/CompletionLabel";
import PlainProgressBar from "../analytics/PlainProgressBar";
import ScoreLabel from "../analytics/ScoreLabel";

const ChapterProgressBar = (props: {
	chapter: Chapter;
	chapterProgress: ChapterProgress;
	bar?: {type: "plain"} | {type: "stacked"; progressByType: ProgressByType[]};
}): JSX.Element => {
	const {chapter, chapterProgress} = props;

	if (
		chapter.submissionMode === SubmissionMode.Simultaneous ||
		chapter.selectionMode === SelectionModes.Random
	) {
		return (
			<TestProgress
				test={chapter}
				chapterProgress={chapterProgress}
				bar={props.bar}
			/>
		);
	}

	const submitted = chapterProgress.outcomes.filter((o) => o.submissionTime);

	const score = submitted.reduce((prev, s) => prev + s.score, 0);

	const progressLabel = (
		<CompletionLabel
			completed={submitted.length}
			total={chapterProgress.numberOfExercises}
		/>
	);

	const scoreLabel = (
		<ScoreLabel score={score} maxScore={chapterProgress.maxScore} />
	);

	const chapterStats = {
		maxScore: chapterProgress.maxScore,
		numberOfExercises: chapterProgress.numberOfExercises,
	};

	const overallProgress = {numberOfCompleted: submitted.length, score};

	return props.bar && props.bar.type === "stacked" ? (
		<StackedBar
			chapterId={chapter.id}
			chapterStats={chapterStats}
			progressByType={props.bar.progressByType}
			overallProgress={overallProgress}
			leftLabel={progressLabel}
			rightLabel={scoreLabel}
		/>
	) : (
		<PlainProgressBar
			stats={chapterStats}
			overallProgress={overallProgress}
			leftLabel={progressLabel}
			rightLabel={scoreLabel}
		/>
	);
};

function StackedBar(props: {
	chapterId: number;
	progressByType: ProgressByType[];
	overallProgress: {
		numberOfCompleted: number;
		score: number;
	};
	chapterStats: {
		numberOfExercises: number;
		maxScore: number;
	};
	leftLabel: React.ReactNode;
	rightLabel: React.ReactNode;
}) {
	const {chapterStats, progressByType, overallProgress} = props;

	const statsByType = useAppSelector((state) =>
		selectChapterStatisticsByType(state, keyProvider.chapter(props.chapterId))
	);

	return (
		<ProgressTooltip
			completed={overallProgress.numberOfCompleted}
			total={chapterStats.numberOfExercises}
			score={overallProgress.score}
			maxScore={chapterStats.maxScore}
			additionalText={
				<ProgressBarLegend
					stats={statsByType}
					progress={progressByType.reduce(
						(prev, curr) => ({...prev, [curr.exerciseType]: curr}),
						{}
					)}
				/>
			}
		>
			<StackedProgressBar
				maxValue={chapterStats.numberOfExercises}
				rightLabel={props.rightLabel}
				values={calculateValuesForColors(
					progressByType.reduce(
						(prev, curr) => ({
							...prev,
							[curr.exerciseType]: {numberOfCompleted: curr.numberOfCompleted},
						}),
						{}
					)
				)}
				leftLabel={props.leftLabel}
			/>
		</ProgressTooltip>
	);
}

export default ChapterProgressBar;
