import {Localized} from "@fluent/react";
import {Typography} from "@mui/material";
import React from "react";

const ScoreLabel = (props: {score: number; maxScore: number}): JSX.Element => {
	const {maxScore, score} = props;

	return (
		<Typography variant="body2" color="textSecondary">
			<Localized
				id="widget-progress-score-label"
				vars={{score, maxScore}}
			>{`Score: ${score}/${maxScore}`}</Localized>
		</Typography>
	);
};

export default ScoreLabel;
