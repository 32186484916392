import {Dialog} from "@mui/material";
import React, {useState} from "react";

import ChangeEmailDialog from "./ChangeEmailDialog";
import {userService} from "../../store/services/userService";
import SlideUp from "../../utils/SlideUp";

function useChangeEmailAction(
	mobileMode: boolean,
	onSuccess: (email: string) => void,
	onError: () => void
): [(userId: number, initialEmail?: string) => void, JSX.Element] {
	const [userId, setUserId] = useState(0);
	const [email, setEmail] = useState("");

	function changeEmail(userId: number, email?: string) {
		setUserId(userId);
		setEmail(email ?? "");
	}

	function close() {
		setUserId(0);
		setEmail("");
	}

	async function updateEmail(email: string) {
		try {
			await userService.patchCredentials(userId, {email});
			onSuccess(email);
			close();
		} catch {
			onError();
		}
	}

	const changeEmailTitleId = "change-user-email";

	return [
		changeEmail,
		<Dialog
			key={changeEmailTitleId}
			open={Boolean(userId)}
			aria-labelledby={changeEmailTitleId}
			maxWidth="xs"
			fullWidth
			fullScreen={mobileMode}
			TransitionComponent={mobileMode ? SlideUp : undefined}
			onClose={close}
		>
			<ChangeEmailDialog
				titleElementId={changeEmailTitleId}
				initialEmail={email}
				onCancel={close}
				onChange={updateEmail}
			/>
		</Dialog>,
	];
}

export default useChangeEmailAction;
