import type ExerciseAnswer from "./ExerciseAnswer";
import type {RootState} from "../store";

function selectExerciseAnswer(
	state: RootState,
	execiseKey: string
): ExerciseAnswer | undefined {
	return state.exerciseAnswers.byKey[execiseKey];
}

export default selectExerciseAnswer;
