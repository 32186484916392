import {createAsyncThunk} from "@reduxjs/toolkit";

import {userService} from "../services/userService";

const leaveOrganisation = createAsyncThunk(
	"organisations/leave",
	async (params: {orgName: string; userId: number; password: string}) => {
		await userService.leaveOrganisation(
			params.orgName,
			params.userId,
			params.password
		);

		return {organisationName: params.orgName};
	}
);

export default leaveOrganisation;
