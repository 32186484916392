import {
	Autocomplete,
	Box,
	CircularProgress,
	ListItem,
	ListItemText,
	TextField,
	debounce,
} from "@mui/material";
import type {AutocompleteChangeReason} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";

import type {Page} from "../../helpers/paginatedSearchHelpers";
import type {Option, Optional} from "./Option";
import useLoadMore from "./useLoadMore";

function PaginatedSingleSelectionAutocomplete<T, K>(props: {
	label?: React.ReactNode;
	noOptionsText?: React.ReactNode;
	error?: boolean;
	required?: boolean;
	defaultOption?: Option<K>;
	pageFetcher: (query: string, pageSize: number) => Promise<Page<T, K>>;
	optionMapper: (val: T) => Option<K>;
	onChange: (key: K | null) => void;
}) {
	type POption = Optional<Option<K>, "id">;

	const {pageFetcher, onChange, optionMapper} = props;

	const [query, setQuery] = useState("");
	const changeQuery = useMemo(() => {
		return debounce(setQuery, 500);
	}, []);

	const [value, setValue] = useState<POption | null>(
		props.defaultOption ?? null
	);

	const [inputValue, setInputValue] = useState(props.defaultOption?.name ?? "");

	const [listOpen, setListOpen] = useState(false);

	const {options, loadMoreOption, loading, loadMore} = useLoadMore(
		query,
		pageFetcher
	);

	const defaultSet = useRef(false);

	useEffect(() => {
		if (props.defaultOption && !defaultSet.current) {
			defaultSet.current = true;
			setInputValue(props.defaultOption.name);
			setValue(props.defaultOption);
		}
	}, [props.defaultOption]);

	function changeSelected(
		_: unknown,
		selected: POption | null,
		reason: AutocompleteChangeReason
	) {
		if (loadMoreOption && selected === loadMoreOption) {
			loadMore();
			return;
		}

		if (reason === "selectOption" || reason === "removeOption") {
			setValue(selected);
			onChange(selected?.id ?? null);
		} else if (reason === "clear") {
			setValue(null);
			onChange(null);
		}

		setListOpen(false);
	}

	function renderOption(
		props: React.HTMLAttributes<HTMLElement>,
		option: POption
	) {
		if (option === loadMoreOption) {
			return (
				<Box {...props} sx={{color: (theme) => theme.palette.text.secondary}}>
					{option.name}
				</Box>
			);
		}

		return (
			<ListItem component="div" dense disableGutters {...props}>
				<ListItemText primary={option.name} secondary={option.secondaryName} />
			</ListItem>
		);
	}

	let all = options.map(optionMapper);
	if (
		props.defaultOption &&
		all.findIndex((o) => o.id === props.defaultOption?.id) < 0
	) {
		all = [...all, props.defaultOption];
	}

	return (
		<Autocomplete
			open={listOpen}
			size="small"
			options={all}
			value={value}
			loading={loading}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(opt) => {
				if (opt !== loadMoreOption) {
					return opt.name;
				}
				return inputValue;
			}}
			getOptionKey={(o) => `${o.id ?? "load-more"}`}
			noOptionsText={props.noOptionsText}
			filterOptions={(options) => {
				const filtered =
					options.length > 0 && loadMoreOption
						? [...options, loadMoreOption]
						: options;

				return [...filtered];
			}}
			inputValue={inputValue}
			onChange={changeSelected}
			onInputChange={(_, val, reason) => {
				setInputValue(val);
				changeQuery(reason === "reset" ? "" : val);
			}}
			renderOption={renderOption}
			renderInput={(params) => (
				<TextField
					{...params}
					error={props.error}
					required={props.required}
					label={props.label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading && <CircularProgress color="primary" size={20} />}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			onOpen={() => setListOpen(true)}
			onClose={(_, reason) => {
				if (reason !== "selectOption") {
					setListOpen(false);
				}
			}}
		/>
	);
}

export default PaginatedSingleSelectionAutocomplete;
