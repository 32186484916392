import {Localized} from "@fluent/react";
import {Container} from "@mui/material";
import React from "react";

import LoadingError from "../../utils/errors/LoadingError";

function MessageListLoadingErrorState(props: {onReload?: () => void}) {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<LoadingError
				description={
					<Localized id="tutoring-message-list-error">
						Something has gone wrong, and we cannot load messages
					</Localized>
				}
				onReload={props.onReload}
			/>
		</Container>
	);
}

export default MessageListLoadingErrorState;
