import {Localized, useLocalization} from "@fluent/react";
import {Button, Grid, MenuItem, TextField} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {draftKey} from "../../store/chapters/Chapter";
import chapterDraftCreated from "../../store/chapters/chapterDraftCreated";
import chapterDraftDeleted from "../../store/chapters/chapterDraftDeleted";
import createChapter from "../../store/chapters/createChapter";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectCourse from "../../store/courses/selectCourse";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import SelectionMode, {
	SelectionModes,
} from "../../store/chapters/SelectionMode";
import CourseType from "../../store/courses/CourseType";

function NewChapterEditor({courseId}: {courseId: number}): JSX.Element {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const [featureEnabled] = useFeatureEnabled();

	const courseType = useAppSelector(
		(state) =>
			selectCourse(state, keyProvider.course(courseId))?.type ??
			CourseType.Ordinary
	);

	const {l10n} = useLocalization();
	const defaultTitle = l10n.getString(
		"learning-material-new-chapter-form-placeholder-new-chapter",
		null,
		"New chapter"
	);
	const [title, setTitle] = useState(defaultTitle);

	const [submissionMode, setSubmissionMode] = useState(
		SubmissionMode.Individual
	);

	const [selectionMode, setSelectionMode] = useState<SelectionMode>(
		SelectionModes.All
	);

	const [saving, setSaving] = useState(false);

	const number = useAppSelector((state) => {
		let n = 1;

		const keys = selectCourseChapterKeys(state, keyProvider.course(courseId));
		if (keys.length > 0) {
			n = keys.length;

			if (keys[n - 1] !== draftKey) {
				n += 1;
			}
		}

		return n;
	});

	useEffect(() => {
		if (!saving) {
			dispatch(chapterDraftCreated({courseId, number, title: defaultTitle}));
		}
	}, [courseId, dispatch, number, saving, defaultTitle]);

	useEffect(() => {
		return () => {
			dispatch(chapterDraftDeleted({courseId}));
		};
	}, [courseId, dispatch]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	};

	const handleCancel = () => {
		history.goBack();
	};

	const handleSave = async () => {
		setSaving(true);

		const result = await dispatch(
			createChapter({courseId, title, number, submissionMode, selectionMode})
		);

		const chapter = unwrapResult(result);

		history.push(`${chapter.id}`);
	};

	const practiceTestsEnabled = featureEnabled(Feature.PracticeTests);

	return (
		<Grid container spacing={3} direction="column">
			<Grid item>
				<TextField
					label={l10n.getString(
						"learning-material-form-label-chapter-title",
						null,
						"Chapter title"
					)}
					value={title}
					onChange={handleChange}
					fullWidth
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
			<Grid item container spacing={3}>
				<Grid item xs={12} md={6} xl={4}>
					{(courseType === CourseType.Exam || practiceTestsEnabled) && (
						<TextField
							select
							label="Submission mode"
							value={submissionMode}
							onChange={(event) =>
								setSubmissionMode(event.target.value as SubmissionMode)
							}
							fullWidth
						>
							<MenuItem value={SubmissionMode.Individual}>
								{"Individual"}
							</MenuItem>
							<MenuItem value={SubmissionMode.Simultaneous}>
								{"Simultaneous"}
							</MenuItem>
						</TextField>
					)}
				</Grid>
				{practiceTestsEnabled && (
					<Grid item xs={12} md={6} xl={4}>
						<TextField
							select
							label="Selection mode"
							value={selectionMode}
							onChange={(event) =>
								setSelectionMode(event.target.value as SelectionMode)
							}
							disabled={courseType === CourseType.Exam}
							fullWidth
						>
							<MenuItem value={SelectionModes.All}>{"All"}</MenuItem>
							<MenuItem value={SelectionModes.Random}>{"Random"}</MenuItem>
						</TextField>
					</Grid>
				)}
			</Grid>
			<Grid item container justifyContent="flex-end" spacing={3}>
				<Grid item>
					<Button color="primary" onClick={handleCancel}>
						<Localized id="learning-material-new-chapter-form-button-cancel">
							Cancel
						</Localized>
					</Button>
				</Grid>

				<Grid item>
					<Button variant="contained" color="primary" onClick={handleSave}>
						<Localized id="learning-material-new-chapter-form-button-save">
							Save
						</Localized>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default NewChapterEditor;
