import {Box, Typography, alpha} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2, 3, 0),
			display: "flex",
		},
		subtitle: {
			color: alpha(theme.palette.text.primary, 0.7),
			marginTop: theme.spacing(-0.5),
		},
	})
);

const WidgetTitle = (props: {
	children: React.ReactNode;
	actions?: React.ReactNode;
	subtitle?: React.ReactNode;
}): JSX.Element => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Box flexGrow={1}>
				<Typography component="h2" variant="h6">
					{props.children}
				</Typography>

				<Typography
					className={classes.subtitle}
					variant="subtitle2"
					style={{visibility: props.subtitle ? "inherit" : "hidden"}}
				>
					{props.subtitle || "subtitle"}
				</Typography>
			</Box>

			{props.actions}
		</div>
	);
};

export default WidgetTitle;
