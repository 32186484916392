import React, {useState} from "react";

import AssignToGroupCoursesDialog from "./AssignToGroupCoursesDialog";

function useAssignToGroupCoursesAction(
	groupName: string,
	mobileMode: boolean,
	onDone?: () => void
): [(userIds: number[]) => void, JSX.Element] {
	const [userIds, setUserIds] = useState<number[]>([]);

	return [
		setUserIds,
		<AssignToGroupCoursesDialog
			key="assign-to-group-courses"
			open={userIds.length > 0}
			mobileMode={mobileMode}
			groupName={groupName}
			userIds={userIds}
			onCancel={() => {
				setUserIds([]);
			}}
			onDone={() => {
				setUserIds([]);
				onDone?.();
			}}
		/>,
	];
}

export default useAssignToGroupCoursesAction;
