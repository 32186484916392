import {createSlice} from "@reduxjs/toolkit";

import type FetchStatus from "../FetchStatus";
import fetchOrganisation from "./fetchOrganisation";
import fetchUserOrganisations from "./fetchUserOrganisations";
import type Organisation from "./Organisation";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	organisation?: Organisation;
	status: FetchStatus;
};

type SliceState = {
	byKey: {
		[name: string]: State | undefined;
	};
};

const initialState: SliceState = {byKey: {}};

export const organisationsSlice = createSlice({
	name: "organisations",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchOrganisation.fulfilled, (state, {payload}) => {
			state.byKey[payload.name] = {
				organisation: payload,
				status: "succeeded",
			};
		});
		builder.addCase(fetchOrganisation.pending, (state, action) => {
			updateStatus(state, action.meta.arg.organisationName, "pending");
		});
		builder.addCase(fetchOrganisation.rejected, (state, action) => {
			updateStatus(
				state,
				action.meta.arg.organisationName,
				action.error.code === "not_found"
					? "succeeded"
					: inferFetchStatusFromError(action)
			);
		});

		builder.addCase(fetchUserOrganisations.fulfilled, (state, {payload}) => {
			payload.forEach((o) => {
				state.byKey[o.name] = {organisation: o, status: "succeeded"};
			});
		});
	},
});

function updateStatus(state: SliceState, key: string, status: FetchStatus) {
	const s = state.byKey[key];
	if (!s) {
		state.byKey[key] = {
			status: status,
		};
	} else {
		s.status = status;
	}
}

export default organisationsSlice.reducer;
