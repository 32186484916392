import {createSelector} from "@reduxjs/toolkit";
import parseDate from "../../helpers/parseDate";
import type {RootState} from "../store";
import selectCourseChapterKeys from "./selectCourseChapterKeys";

const selectCourseChaptersGroupedByDate = createSelector(
	[selectCourseChapterKeys, (state: RootState) => state.chapters.byKey],
	(chapterKeys, allChapters) => {
		const pasts: string[] = [];
		const ongoing: string[] = [];
		const upcoming: string[] = [];

		const now = new Date();

		chapterKeys.forEach((key) => {
			const chapter = allChapters[key];

			const startDate = chapter.startDate
				? parseDate(chapter.startDate)
				: undefined;

			const endDate = chapter.endDate ? parseDate(chapter.endDate) : undefined;

			if (endDate && endDate <= now) {
				pasts.push(key);
			} else if (!startDate || now < startDate) {
				upcoming.push(key);
			} else {
				ongoing.push(key);
			}
		});

		return [pasts, ongoing, upcoming];
	}
);

export default selectCourseChaptersGroupedByDate;
