import {createAsyncThunk} from "@reduxjs/toolkit";
import {userService} from "../services/userService";

const fetchUserOrganisations = createAsyncThunk(
	"userOrganisations/fetch",
	async (userId: number) => {
		const organisations = await userService.getUserOrganisations(userId);

		return organisations.sort((o1, o2) => {
			if (o1.name > o2.name) {
				return 1;
			}
			return o1.name < o2.name ? -1 : 0;
		});
	}
);

export default fetchUserOrganisations;
