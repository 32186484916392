import {Localized} from "@fluent/react";
import React, {useCallback} from "react";

import AssignToCoursesDialog from "./AssignToCoursesDialog";
import OrganisationCoursesSelectorDialog from "../courses/OrganisationCoursesSelectorDialog";
import {courseService} from "../../store/services/courseService";

function AssignToOrganisationCoursesDialog(props: {
	open: boolean;
	mobileMode: boolean;
	organisationName: string;
	userIds: number[];
	onCancel: () => void;
	onDone: (courseIds: number[]) => void;
}) {
	const {organisationName} = props;

	const fetchCourses = useCallback(
		async (ids: number[]) => {
			const res = await courseService.searchOrganisationCourses(
				props.organisationName,
				{ids},
				{field: "name"},
				ids.length
			);

			return res.content;
		},
		[props.organisationName]
	);

	const coursesSelector = useCallback(
		(props: {onSelected: (ids: number[]) => void; onCancel: () => void}) => (
			<OrganisationCoursesSelectorDialog
				multiple
				organisationName={organisationName}
				actionLabel={
					<Localized id="users-assign-to-courses-dialog-action-choose-rooles">
						Choose roles
					</Localized>
				}
				onSelected={props.onSelected}
				onCancel={props.onCancel}
			/>
		),
		[organisationName]
	);

	return (
		<AssignToCoursesDialog
			open={props.open}
			mobileMode={props.mobileMode}
			userIds={props.userIds}
			CoursesSelector={coursesSelector}
			fetchCourses={fetchCourses}
			onCancel={props.onCancel}
			onDone={props.onDone}
		/>
	);
}

export default AssignToOrganisationCoursesDialog;
