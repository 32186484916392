import {Localized} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useMemo, useState} from "react";
import {Redirect, useHistory, useLocation} from "react-router";
import {Link as RouterLink} from "react-router-dom";

import ForgotPassword from "./ForgotPassword";
import Loading from "./Loading";
import PageLayout from "./PageLayout";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import useFetchOrganisation from "../../store/organisation/useFetchOrganisation";
import authService from "../../store/services/authService";
import useSnackbar from "../../store/ui/useSnackbar";
import loginUser from "../../store/userSession/loginUser";
import selectLoggedIn from "../../store/userSession/selectLoggedIn";
import type DialogResult from "../../utils/DialogResult";
import OrganisationNotFoundError from "../../utils/errors/OrganisationNotFoundError";

type Credentials = {
	password: string;
	username: string;
};

function Login(props: {
	logo: string;
	organisationName: string;
	signUpAvailable?: boolean;
}): JSX.Element {
	const {logo, organisationName} = props;

	const dispatch = useAppDispatch();
	const history = useHistory();
	const showSnackbar = useSnackbar();

	const location = useLocation<{
		from: {pathname: string};
	}>();
	const {from} = location.state || {from: {pathname: "/"}};

	const query = useMemo(() => new URLSearchParams(location.search), [
		location.search,
	]);

	const delegatedLogin = Boolean(query.get("redirectUri"));

	const [userCredentials, setUserCredentials] = useState<Credentials>({
		password: "",
		username: "",
	});

	const [empty, setEmpty] = useState<{password?: boolean; username?: boolean}>(
		{}
	);

	const [showPassword, setShowPassword] = useState(false);
	const [remember, setRemember] = useState(false);
	const [error, setError] = useState(false);
	const [redirectUri, setRedirectUri] = useState("");
	const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

	const loggedIn = useAppSelector(selectLoggedIn);
	const customThemeEnabled = useAppSelector((state) => state.theme.enabled);

	const [org, orgFetchStatus] = useFetchOrganisation(organisationName);

	useEffect(() => {
		if (loggedIn && !delegatedLogin) {
			history.replace(from);
		}
	}, [delegatedLogin, from, history, loggedIn]);

	const changeCredentials = (prop: keyof Credentials) => (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setUserCredentials((prevState) => ({
			...prevState,
			[prop]: event.target.value,
		}));
		setEmpty((prev) => ({...prev, [prop]: false}));
		setError(false);
	};

	const login = async () => {
		const password = userCredentials.password;
		const username = userCredentials.username.trim();

		if (username === "" || password === "") {
			setEmpty({username: username === "", password: password === ""});
			return;
		}

		// Prevent password manager from saving username with extra whitespace
		setUserCredentials({username, password});

		if (delegatedLogin) {
			const redirectUri = query.get("redirectUri") ?? "";
			const codeChallenge = query.get("codeChallenge") ?? "";
			const state = query.get("state") ?? "";

			const resp = await authService.preauthenticate({
				userName: username,
				password,
				longTerm: remember,
				codeChallenge,
				redirectUri,
			});

			const params = new URLSearchParams();

			params.append("code", resp.code);
			params.append("state", state);

			const uri = resp.redirectUri + "?" + params.toString();

			window.location.href = uri;
			setRedirectUri(uri);

			return;
		}

		const res = await dispatch(loginUser({username, password, remember}));
		try {
			unwrapResult(res);
		} catch {
			setError(true);
		}
	};

	const closeForgotPassword = (result: DialogResult) => {
		setForgotPasswordOpen(false);
		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}
	};

	if (organisationName) {
		if (orgFetchStatus === "none" || orgFetchStatus === "pending") {
			return (
				<PageLayout logo={logo}>
					<Loading />
				</PageLayout>
			);
		}

		if (orgFetchStatus === "succeeded" && !org) {
			return (
				<PageLayout logo={logo}>
					<OrganisationNotFoundError />
				</PageLayout>
			);
		}
	}

	if (loggedIn && !delegatedLogin) {
		return <Redirect to="/" />;
	}

	if (redirectUri) {
		return (
			<PageLayout logo={logo}>
				<Redirecting to={redirectUri} />
			</PageLayout>
		);
	}

	let titleMessageId = "login-welcome-viope";

	if (delegatedLogin) {
		titleMessageId = "login-delegated-title";
	} else if (customThemeEnabled) {
		titleMessageId = "login-welcome";
	}

	return (
		<PageLayout logo={props.logo}>
			<Stack
				spacing={2}
				component="form"
				onSubmit={(e) => {
					e.preventDefault();
					login();
				}}
			>
				<Typography component="h1" variant="h4" sx={{mb: 2}}>
					<Localized id={titleMessageId}>Welcome</Localized>
				</Typography>

				<TextField
					label={<Localized id="login-username">Username</Localized>}
					value={userCredentials.username}
					onChange={changeCredentials("username")}
					fullWidth
					autoComplete="username"
					error={empty.username}
				/>

				<TextField
					label={<Localized id="login-password">Password</Localized>}
					type={showPassword ? "text" : "password"}
					value={userCredentials.password}
					onChange={changeCredentials("password")}
					fullWidth
					autoComplete="current-password"
					slotProps={{
						input: {
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword((prevState) => !prevState)}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						},
					}}
					error={empty.password}
				/>

				<Stack
					direction="row"
					sx={{alignItems: "center", justifyContent: "space-between"}}
				>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								value="remember"
								onChange={() => setRemember((prev) => !prev)}
							/>
						}
						label={<Localized id="login-remember-me">Remember me</Localized>}
						slotProps={{typography: {whiteSpace: "nowrap"}}}
					/>

					<Link
						component="button"
						variant="body2"
						onClick={() => setForgotPasswordOpen(true)}
						type="button"
					>
						<Localized id="login-forgot-password">Forgot password?</Localized>
					</Link>

					<ForgotPassword
						open={forgotPasswordOpen}
						onClose={closeForgotPassword}
					/>
				</Stack>

				<Stack spacing={1} sx={{mt: -1}}>
					<Typography
						color="error"
						sx={{visibility: error ? "visible" : "hidden"}}
						align="center"
					>
						<Localized id="login-incorrect-username-or-password">
							Incorrect username or password. Please try again
						</Localized>
					</Typography>

					<Button
						color="primary"
						fullWidth
						variant="contained"
						disabled={
							userCredentials.password.length === 0 ||
							userCredentials.username.length === 0
						}
						type="submit"
					>
						<Localized id="login-log-in">LOG IN</Localized>
					</Button>
				</Stack>

				{props.signUpAvailable && (
					<Typography align="center">
						<Localized
							id="login-create-account-link"
							elems={{
								ref: <Link to="/sign-up" component={RouterLink}></Link>,
							}}
						>
							<> {`New user? <ref>Create account</ref>`}</>
						</Localized>
					</Typography>
				)}
			</Stack>
		</PageLayout>
	);
}

function Redirecting(props: {to: string}) {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DoneIcon style={{height: "50px", width: "50px"}} color="primary" />

			<Typography variant="h6" component="h1" align="center">
				<Localized id="login-delegated-success-message">
					You have successfully logged in
				</Localized>
			</Typography>

			<Typography align="center">
				<Localized
					id="login-delegated-redirection-message"
					elems={{
						ref: <Link href={props.to}></Link>,
					}}
				>
					<>{`Now we are redirecting you back to <ref>the editor</ref>. You can close this tab afterwards`}</>
				</Localized>
			</Typography>
		</Container>
	);
}

export default Login;
