import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Checkbox,
	Chip,
	Dialog,
	Divider,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useEffect, useState} from "react";

import NewTemplateDialog from "./NewTemplateDialog";
import GroupCourseDeepCopyDialog from "./GroupCourseDeepCopyDialog";
import GroupCourseFilters from "./GroupCourseFilters";
import useDateFormat from "../../i18n/useDateFormat";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import {addToDate, formatAtLocalTimeZone} from "../../helpers/dateTimeHelpers";
import parseDate from "../../helpers/parseDate";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import CourseType from "../../store/courses/CourseType";
import {adminService} from "../../store/services/adminService";
import type {OrganisationGroupCourseSearchCriteria} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import OrganisationGroupCourseSearchResult from "../../store/services/dtos/OrganisationGroupCourseSearchResult";
import useSnackbar from "../../store/ui/useSnackbar";
import useArchiveCoursesAction from "./useArchiveCoursesAction";
import useDeleteAction from "./useDeleteAction";
import useRestoreCoursesConfirmation from "./useRestoreCoursesConfirmation";
import ExtendableBox from "../../utils/ExtendableBox";
import LightTooltip from "../../utils/LightTooltip";
import HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";

const filtersBarWidth = 320;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<OrganisationGroupCourseSearchResult>[] = [
	{
		id: "organisationName",
		label: "group-courses-table-label-organisation",
		width: 160,
		sortable: false,
	},
	{
		id: "name",
		label: "group-courses-table-label-name",
		sortable: true,
	},
	{
		id: "startDate",
		label: "group-courses-table-label-start-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "endDate",
		label: "group-courses-table-label-end-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "archivalDate",
		label: "group-courses-table-label-archival-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "type",
		label: "group-courses-table-label-type",
		sortable: false,
		width: 100,
	},
];

const columnNumber = headCells.length + 1;

const initialCriteria: OrganisationGroupCourseSearchCriteria = {};

async function checkTemplate(templateId: number, groupName: string) {
	try {
		const {content} = await courseService.searchGroupTemplates(
			groupName,
			{ids: [templateId]},
			{field: "name"},
			1
		);

		if (content.length === 0) {
			return "not-available";
		}

		const template = content[0];

		if (!template.organisationGroupName && !template.empty) {
			return "not-empty-viope-template";
		}

		if (
			template.organisationGroupName &&
			template.organisationGroupName !== groupName
		) {
			return "not-owned";
		}

		return null;
	} catch {
		return "general";
	}
}

function GroupCourses(props: {groupName: string}) {
	const {groupName} = props;

	const classes = useStyles();
	const {l10n} = useLocalization();

	const [filtersOpen, setFiltersOpen] = useState(false);
	const [criteria, setCriteria] = useState(initialCriteria);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val ?? undefined}));
	}, []);

	const [coursesPerPage, setCoursesPerPage] = useState(10);

	const [sortField, setSortField] = useState<
		keyof OrganisationGroupCourseSearchResult
	>("name");
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const {
		page: coursesPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
		reloadPage,
	} = usePaginationState<OrganisationGroupCourseSearchResult>();

	const courses = coursesPage.content;

	const {
		select: selectCourse,
		bulkSelectionCheckbox,
		selected: selectedCourses,
	} = useBulkSelection(coursesPage, (c) => c.id);

	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [
		menuCourse,
		setMenuCourse,
	] = useState<OrganisationGroupCourseSearchResult | null>(null);

	const [newTemplateDialogOpen, setNewTemplateDialogOpen] = useState(false);
	const [deepCopyDialogOpen, setDeepCopyDialogOpen] = useState(false);

	const [archive, archiveCoursesDialog] = useArchiveCoursesAction(
		async (organisationName, schedule) => {
			await adminService.archiveCourses(organisationName, schedule);
		},
		reloadPage,
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const confirmRestore = useRestoreCoursesConfirmation(
		openConfirmationDialog,
		async (organisationName, ids) => {
			await adminService.restoreCourses(organisationName, ids);
			retryFetching?.();
		}
	);

	const [deleteCourses, deleteCourseDialog] = useDeleteAction(
		async (organisationName, ids, deleteAssociated) => {
			await adminService.deleteCourses(organisationName, ids, {
				deleteAssociated,
			});
		},
		reloadPage,
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	useEffect(() => {
		fetchFirstPage(() =>
			courseService.searchCoursesInOrganisationGroup(
				groupName,
				{
					...criteria,
					startDateAfter: criteria.startDateAfter
						? formatAtLocalTimeZone(parseDate(criteria.startDateAfter))
						: undefined,
					startDateBefore: criteria.startDateBefore
						? formatAtLocalTimeZone(
								parseDate(addToDate(criteria.startDateBefore, {days: 1}))
						  )
						: undefined,
					endDateAfter: criteria.endDateAfter
						? formatAtLocalTimeZone(parseDate(criteria.endDateAfter))
						: undefined,
					endDateBefore: criteria.endDateBefore
						? formatAtLocalTimeZone(
								parseDate(addToDate(criteria.endDateBefore, {days: 1}))
						  )
						: undefined,
				},
				{field: sortField, descending: sortOrder === SortOrder.Desc},
				coursesPerPage
			)
		);
	}, [
		coursesPerPage,
		criteria,
		fetchFirstPage,
		groupName,
		sortField,
		sortOrder,
	]);

	function changeOrder(
		orderBy: keyof OrganisationGroupCourseSearchResult,
		order: SortOrder
	) {
		setSortOrder(order);
		setSortField(orderBy);
	}

	function selectedCourse(id: number) {
		return selectedCourses.indexOf(id) !== -1;
	}

	const mobileMode = useMobileMode("md");
	const navBarHeight = useNavBarHeight();
	const theme = useTheme();
	const formatDate = useDateFormat();
	const formatDatetime = useDateTimeFormat();
	const showSnackbar = useSnackbar();

	const ensureCanCreateTemplate = useCallback(async () => {
		if (!menuCourse?.templateId) {
			return null;
		}

		const errCode = await checkTemplate(menuCourse.templateId, groupName);

		switch (errCode) {
			case "not-available":
				return l10n.getString("group-courses-new-template-error-not-available");
			case "not-empty-viope-template":
				return l10n.getString(
					"group-courses-new-template-error-not-empty-viope-template"
				);
			case "not-owned":
				return l10n.getString("group-courses-new-template-error-not-owned");
			case "general":
				return l10n.getString("group-courses-error-general");
		}

		return null;
	}, [groupName, l10n, menuCourse?.templateId]);

	async function createTemplate(name: string) {
		if (!menuCourse) {
			return;
		}

		try {
			await courseService.createTemplateInOrganisationGroup(
				groupName,
				menuCourse.id,
				name
			);

			setNewTemplateDialogOpen(false);

			showSnackbar(
				"success",
				l10n.getString(
					"group-courses-message-template-created",
					null,
					"Created"
				)
			);
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	async function restore(organisationName: string, courseId: number) {
		await adminService.restoreCourses(organisationName, [courseId]);
		retryFetching?.();
	}

	const ensureCanDeepCopy = useCallback(async () => {
		if (!menuCourse?.templateId) {
			return null;
		}

		const errCode = await checkTemplate(menuCourse.templateId, groupName);

		switch (errCode) {
			case "not-available":
				return l10n.getString("group-courses-deep-copy-error-not-available");
			case "not-empty-viope-template":
				return l10n.getString(
					"group-courses-deep-copy-error-not-empty-viope-template"
				);
			case "not-owned":
				return l10n.getString("group-courses-deep-copy-error-not-owned");
			case "general":
				return l10n.getString("group-courses-error-general");
		}

		return null;
	}, [groupName, l10n, menuCourse?.templateId]);

	async function deepCopy(organisationName: string, newCourseName: string) {
		if (!menuCourse) {
			return null;
		}

		try {
			const id = await courseService.copyCourse(
				organisationName,
				menuCourse.id,
				newCourseName
			);

			retryFetching?.();

			return id;
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return null;
		}
	}

	const now = new Date();

	function createCourseActions(courseId: number) {
		const course = courses.find((course) => course.id === courseId);
		if (!course) {
			return null;
		}

		const archivalDate = course.archivalDate && parseDate(course.archivalDate);

		const items = [];

		items.push(
			<MenuItem
				key="manage"
				component="a"
				href={`/organisations/${course.organisationName}/courses/${courseId}/management/content`}
				target="_blank"
				onClick={() => {
					setMenuAnchor(null);
				}}
			>
				<ListItemText
					primary={
						<Localized id="group-courses-action-manage">Manage</Localized>
					}
					style={{margin: 0}}
				/>
				<OpenInNewIcon
					fontSize="inherit"
					style={{marginLeft: theme.spacing(1)}}
				/>
			</MenuItem>
		);

		items.push(
			<MenuItem
				key="create-templte"
				disabled={menuCourse?.type === CourseType.Exam}
				onClick={() => {
					setNewTemplateDialogOpen(true);
					setMenuAnchor(null);
				}}
			>
				<Localized id="group-courses-action-create-template">
					Create template
				</Localized>
			</MenuItem>
		);

		items.push(
			<Divider
				key="divider1"
				component="li"
				style={{
					margin: theme.spacing(1, 0),
				}}
			/>
		);

		if (!archivalDate || archivalDate > now) {
			items.push(
				<MenuItem
					key="archive"
					onClick={() => {
						archive(course.organisationName, [courseId]);
						setMenuAnchor(null);
					}}
				>
					<Localized id="group-courses-action-archive">Archive</Localized>
				</MenuItem>
			);
		}

		if (archivalDate && archivalDate > now) {
			items.push(
				<MenuItem
					key="cancel-archiving"
					onClick={() => {
						restore(course.organisationName, courseId);
						setMenuAnchor(null);
					}}
				>
					<Localized id="group-courses-action-cancel-archiving">
						Cancel archiving
					</Localized>
				</MenuItem>
			);
		}

		if (archivalDate && archivalDate <= now) {
			items.push(
				<MenuItem
					key="restore"
					onClick={() => {
						confirmRestore(course.organisationName, [courseId], course.name);
						setMenuAnchor(null);
					}}
				>
					<Localized id="group-courses-action-restore">Restore</Localized>
				</MenuItem>
			);
		}

		items.push(
			<Divider
				key="divider2"
				component="li"
				style={{
					margin: theme.spacing(1, 0),
				}}
			/>
		);

		items.push(
			<MenuItem
				key="delete"
				onClick={() => {
					deleteCourses(course.organisationName, [courseId], course.name);
					setMenuAnchor(null);
				}}
			>
				<Localized id="group-courses-action-delete">Delete</Localized>
			</MenuItem>
		);

		return items;
	}

	const newTemplateDialogId = "new-template";
	const deepCopyDialogId = "deep-copy";

	return (
		<>
			<div className={classes.root}>
				<ExtendableBox
					rightExtension={filtersOpen || mobileMode ? 0 : filtersBarWidth}
					minWidth={0}
				>
					<Box
						display="flex"
						flexDirection="column"
						pt={mobileMode ? 3 : 5}
						px={mobileMode ? 3 : 6}
						pb={1.5}
						position="relative"
						style={{height: `calc(100vh - ${navBarHeight + 1}px)`}}
					>
						<TableToolbar
							query={criteria.query ?? ""}
							actions={[]}
							searchPlaceholder={l10n.getString(
								"group-courses-toolbar-search-placeholder"
							)}
							onToggleFilters={() => setFiltersOpen((prev) => !prev)}
							onQueryChange={setQuery}
						/>
						<TableContainer className={classes.container}>
							<Table stickyHeader>
								<SortingHeader
									onOrderChange={changeOrder}
									order={sortOrder}
									orderBy={sortField}
									headCells={headCells}
									leftAnnex={
										false && (
											<TableCell padding="checkbox" style={{zIndex: 3}}>
												{bulkSelectionCheckbox}
											</TableCell>
										)
									}
									rightAnnex={<TableCell />}
									loading={pageFetchStatus === "pending"}
								/>
								<TableBody>
									{pageFetchStatus === "failed" && (
										<LoadingErrorState
											description={
												<Localized id="group-courses-loading-error-descr">
													Something has gone wrong, and we cannot load courses
												</Localized>
											}
											columnNumber={columnNumber}
											onReload={retryFetching}
										/>
									)}
									{pageFetchStatus === "succeeded" && courses.length === 0 && (
										<NoSearchResultsState
											columnNumber={columnNumber}
											title={
												<Localized id="group-courses-no-courses">
													No courses
												</Localized>
											}
											description={
												criteria === initialCriteria ? (
													<Localized id="group-courses-no-courses-descr">
														There are no courses in the organisation group
													</Localized>
												) : (
													<Localized id="group-courses-no-results-descr">
														No courses were found matching your search criteria.
														Try to adjust filters
													</Localized>
												)
											}
										/>
									)}
									{pageFetchStatus !== "failed" &&
										courses.map((course, index) => {
											const selected = selectedCourse(course.id);
											const labelId = `table-checkbox-${index}`;
											const archivalDate =
												course.archivalDate && parseDate(course.archivalDate);
											const archived = Boolean(
												archivalDate && archivalDate <= now
											);

											return (
												<TableRow
													hover
													key={course.id}
													tabIndex={-1}
													selected={selected}
												>
													{false && (
														<TableCell padding="checkbox">
															<Checkbox
																checked={selected}
																inputProps={{"aria-labelledby": labelId}}
																onClick={() => selectCourse(course.id)}
															/>
														</TableCell>
													)}
													<TableCell style={{width: 160}}>
														<LightTooltip
															title={
																<Typography>
																	{course.organisationName}
																</Typography>
															}
															enterDelay={500}
															enterNextDelay={500}
														>
															<div
																style={{
																	whiteSpace: "nowrap",
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	width: "inherit",
																}}
															>
																{course.organisationName}
															</div>
														</LightTooltip>
													</TableCell>
													<TableCell
														component="th"
														id={labelId}
														scope="row"
														style={{
															paddingTop: archived
																? theme.spacing(1.5)
																: undefined,
															paddingBottom: archived
																? theme.spacing(1.5)
																: undefined,
															minWidth: 200,
														}}
													>
														<Box
															display="flex"
															justifyContent="space-between"
															alignItems="center"
														>
															{course.name}
															{archived && (
																<Chip
																	label={
																		<Localized id="group-courses-course-label-archived">
																			Archived
																		</Localized>
																	}
																	size="small"
																	variant="outlined"
																/>
															)}
														</Box>
													</TableCell>
													<TableCell align="right">
														{formatDate(parseDate(course.startDate))}
													</TableCell>
													<TableCell align="right">
														{formatDate(parseDate(course.endDate))}
													</TableCell>
													<TableCell align="right">
														{archivalDate && (
															<Tooltip title={formatDatetime(archivalDate)}>
																<div>{formatDate(archivalDate)}</div>
															</Tooltip>
														)}
													</TableCell>
													<TableCell>
														<Localized
															id="group-courses-type"
															vars={{type: course.type}}
														>
															{course.type}
														</Localized>
													</TableCell>
													<TableCell padding="checkbox">
														<Tooltip
															title={
																<Localized id="group-courses-course-actions-label">
																	Actions
																</Localized>
															}
														>
															<IconButton
																aria-label={l10n.getString(
																	"group-courses-course-actions-label"
																)}
																onClick={(e) => {
																	setMenuAnchor(e.currentTarget);
																	setMenuCourse(course);
																}}
															>
																<MoreVertIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							ml={2}
						>
							<Typography variant="subtitle2">
								{selectedCourses.length > 0 && (
									<Localized
										id="group-administration-selected-row-number"
										vars={{selected: selectedCourses.length}}
									>{`${selectedCourses.length} selected`}</Localized>
								)}
							</Typography>
							<TablePagination
								onPageChange={fetchRelatedPage}
								pageSize={coursesPerPage}
								onPageSizeChange={setCoursesPerPage}
								first={Boolean(coursesPage.request.first)}
								last={Boolean(coursesPage.request.last)}
								next={Boolean(coursesPage.request.next)}
								previous={Boolean(coursesPage.request.previous)}
								label={
									<Localized id="group-courses-per-page">
										Courses per page
									</Localized>
								}
								disabled={pageFetchStatus !== "succeeded"}
							/>
						</Box>
					</Box>
				</ExtendableBox>
				<GroupCourseFilters
					groupName={groupName}
					open={filtersOpen}
					width={
						mobileMode ? `calc(100vw - ${theme.spacing(7)})` : filtersBarWidth
					}
					mobileMode={mobileMode}
					criteria={criteria}
					onCriteriaChange={setCriteria}
					onClose={() => setFiltersOpen(false)}
				/>
			</div>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				onClose={() => setMenuAnchor(null)}
			>
				{createCourseActions(menuCourse?.id ?? 0)}
				<MenuItem
					disabled={menuCourse?.type === CourseType.Exam}
					onClick={() => {
						setDeepCopyDialogOpen(true);
						setMenuAnchor(null);
					}}
				>
					<Localized id="group-courses-action-deep-copy">Deep copy</Localized>
				</MenuItem>
			</Menu>

			<Dialog
				id={newTemplateDialogId}
				open={newTemplateDialogOpen}
				maxWidth="md"
				fullWidth
				onClose={() => setNewTemplateDialogOpen(false)}
			>
				<NewTemplateDialog
					courseName={menuCourse?.name ?? ""}
					titleElementId={newTemplateDialogId}
					onCancel={() => {
						setNewTemplateDialogOpen(false);
					}}
					onCreate={createTemplate}
					ensureCanCreate={ensureCanCreateTemplate}
				/>
			</Dialog>

			<Dialog
				id={deepCopyDialogId}
				open={deepCopyDialogOpen}
				maxWidth="md"
				fullWidth
				onClose={() => setDeepCopyDialogOpen(false)}
			>
				<GroupCourseDeepCopyDialog
					titleElementId={deepCopyDialogId}
					groupName={groupName}
					courseName={menuCourse?.name ?? ""}
					defaultOrganisationName={menuCourse?.organisationName ?? ""}
					onCancel={() => setDeepCopyDialogOpen(false)}
					onCopy={deepCopy}
					ensureCanCopy={ensureCanDeepCopy}
				/>
			</Dialog>

			{confirmationDialog}
			{archiveCoursesDialog}
			{deleteCourseDialog}
		</>
	);
}

export default GroupCourses;
