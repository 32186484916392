import {createSlice} from "@reduxjs/toolkit";

import type Exercise from "./Exercise";
import type {ExerciseWithContent} from "./Exercise";
import ExerciseType from "./ExerciseType";
import {getChapterContent} from "../chapters/getChapterContent";
import {getSectionContent} from "../sections/getSectionContent";
import {fetchChapterExercise} from "../chapterExercises/fetchChapterExercise";
import {saveExercise} from "./saveExercise";
import {keyProvider} from "../keyProvider";
import {saveTheoryDraft} from "./saveTheoryDraft";
import {fetchChapterSessionContent} from "../chapterSessions/fetchSessionContent";
import {fetchSessionSectionContent} from "../chapterSessions/fetchSessionSectionContent";
import buildProgExercise from "./prog/buildExercise";
import exampleOutputChanged from "./prog/exampleOutputChanged";

type Exercises = {
	byKey: {
		[key: string]: ExerciseWithContent | Exercise;
	};
};

const initialState: Exercises = {
	byKey: {},
};

export const exercisesSlice = createSlice({
	name: "exercises",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getChapterContent.fulfilled, (state, action) => {
			const exercises = action.payload.entities.exercises;

			Object.keys(exercises).forEach((key) => {
				const src = state.byKey[key];
				const val = exercises[key];

				state.byKey[key] = {...src, ...val};
			});
		});

		builder.addCase(fetchChapterSessionContent.fulfilled, (state, action) => {
			const exercises = action.payload.entities.exercises;

			Object.keys(exercises).forEach((key) => {
				const src = state.byKey[key];
				const val = exercises[key];

				state.byKey[key] = {...src, ...val};
			});
		});

		builder.addCase(getSectionContent.fulfilled, (state, action) => {
			const exercises = action.payload.data.exercises;

			Object.entries(exercises).forEach(([key, val]) => {
				state.byKey[key] = updateExercise(state.byKey[key], val);
			});
		});

		builder.addCase(fetchSessionSectionContent.fulfilled, (state, action) => {
			const exercises = action.payload.data.exercises;

			Object.entries(exercises).forEach(([key, val]) => {
				state.byKey[key] = updateExercise(state.byKey[key], val);
			});
		});

		builder.addCase(fetchChapterExercise.fulfilled, (state, action) => {
			const {exercise, exerciseKey} = action.payload;

			state.byKey[exerciseKey] = updateExercise(
				state.byKey[exerciseKey],
				exercise as ExerciseWithContent
			);
		});

		builder.addCase(saveExercise.fulfilled, (state, {payload}) => {
			const exerciseKey = keyProvider.exercise(payload.exerciseId);

			if (state.byKey[exerciseKey]) {
				state.byKey[exerciseKey] = payload.exercise;
			}
		});

		builder.addCase(buildProgExercise.fulfilled, (state, {payload}) => {
			const exerciseKey = keyProvider.exercise(payload.exerciseId);

			if (state.byKey[exerciseKey]) {
				state.byKey[exerciseKey] = payload.exercise;
			}
		});

		builder.addCase(exampleOutputChanged, (state, {payload}) => {
			const exerciseKey = keyProvider.exercise(payload.exerciseId);
			const exercise = state.byKey[exerciseKey];
			if (
				exercise &&
				"question" in exercise &&
				exercise.type === ExerciseType.Prog
			) {
				state.byKey[exerciseKey] = {
					...exercise,
					interactions: {
						...exercise.interactions,
						exampleOutput: payload.exampleOutput,
					},
				};
			}
		});

		builder.addCase(saveTheoryDraft.fulfilled, (state, {payload}) => {
			const exerciseKey = keyProvider.exercise(payload.id);

			const old = state.byKey[exerciseKey];
			if (old) {
				state.byKey[exerciseKey] = {
					...old,
					type: ExerciseType.Theory,
					title: payload.title,
					question: payload.content,
				};
			}
		});
	},
});

function choiceComparator(choice1: {id: number}, choice2: {id: number}) {
	return choice1.id - choice2.id;
}

export default exercisesSlice.reducer;

function updateExercise(
	old: Exercise | ExerciseWithContent,
	newValues: ExerciseWithContent
) {
	const updated = {...newValues};

	if (old?.authorId) {
		updated.authorId = old.authorId;
	}
	if (old?.originId) {
		updated.originId = old.originId;
	}

	if (updated.type === ExerciseType.Multi) {
		updated.interactions.choices.sort(choiceComparator);
	}

	return updated;
}
