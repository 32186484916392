// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	font-family: Roboto, Helvetica, Arial, sans-serif;
}

img[data-v-latex-math] {
	vertical-align: middle;
	padding-top: 3px;
	padding-bottom: 3px;
}

code,
pre {
	background-color: #eeeeee;
}

code {
	border-radius: 0.25rem;
	font-size: 0.95rem;
	padding: 0.125rem 0.25rem;
}

pre {
	box-shadow: inset 0 0 1.75px #bdbdbd;
	font-size: 0.875rem;
	padding: 0.875em;
	overflow-x: auto;
}

.media-viewer-container .mce-preview-object,
.pdf-viewer-container .mce-preview-object {
	display: block;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 100%;
}

.pdf-viewer-container .mce-preview-object {
	padding-top: 56.25%;
}

.media-viewer-container--21x9 .mce-preview-object {
	padding-top: 42.857143%;
}
.media-viewer-container--16x9 .mce-preview-object {
	padding-top: 56.25%;
}
.media-viewer-container--4x3 .mce-preview-object {
	padding-top: 75%;
}
.media-viewer-container--1x1 .mce-preview-object {
	padding-top: 100%;
}

.pdf-viewer-container .pdf-viewer,
.media-viewer-container .media-viewer {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/utils/TextEditor/content.css"],"names":[],"mappings":"AAAA;CACC,iDAAiD;AAClD;;AAEA;CACC,sBAAsB;CACtB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;;CAEC,yBAAyB;AAC1B;;AAEA;CACC,sBAAsB;CACtB,kBAAkB;CAClB,yBAAyB;AAC1B;;AAEA;CACC,oCAAoC;CACpC,mBAAmB;CACnB,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;;CAEC,cAAc;CACd,gBAAgB;CAChB,UAAU;CACV,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,uBAAuB;AACxB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,iBAAiB;AAClB;;AAEA;;CAEC,SAAS;CACT,YAAY;CACZ,OAAO;CACP,kBAAkB;CAClB,MAAM;CACN,WAAW;AACZ","sourcesContent":["body {\n\tfont-family: Roboto, Helvetica, Arial, sans-serif;\n}\n\nimg[data-v-latex-math] {\n\tvertical-align: middle;\n\tpadding-top: 3px;\n\tpadding-bottom: 3px;\n}\n\ncode,\npre {\n\tbackground-color: #eeeeee;\n}\n\ncode {\n\tborder-radius: 0.25rem;\n\tfont-size: 0.95rem;\n\tpadding: 0.125rem 0.25rem;\n}\n\npre {\n\tbox-shadow: inset 0 0 1.75px #bdbdbd;\n\tfont-size: 0.875rem;\n\tpadding: 0.875em;\n\toverflow-x: auto;\n}\n\n.media-viewer-container .mce-preview-object,\n.pdf-viewer-container .mce-preview-object {\n\tdisplay: block;\n\toverflow: hidden;\n\tpadding: 0;\n\tposition: relative;\n\twidth: 100%;\n}\n\n.pdf-viewer-container .mce-preview-object {\n\tpadding-top: 56.25%;\n}\n\n.media-viewer-container--21x9 .mce-preview-object {\n\tpadding-top: 42.857143%;\n}\n.media-viewer-container--16x9 .mce-preview-object {\n\tpadding-top: 56.25%;\n}\n.media-viewer-container--4x3 .mce-preview-object {\n\tpadding-top: 75%;\n}\n.media-viewer-container--1x1 .mce-preview-object {\n\tpadding-top: 100%;\n}\n\n.pdf-viewer-container .pdf-viewer,\n.media-viewer-container .media-viewer {\n\tborder: 0;\n\theight: 100%;\n\tleft: 0;\n\tposition: absolute;\n\ttop: 0;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
