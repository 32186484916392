import React, {useEffect, useRef} from "react";
import {generatePath} from "react-router";

import {resolveLinkToOldSite} from "../helpers/pathHelpers";
import useNavBarHeight from "../hooks/useNavBarHeight";
import useCurrentLocale from "../i18n/useCurrentLocale";

export enum TeacherCoursePage {
	BulletinBoard = "/teacher/:courseId/?langOvrd=:language#/bulletin_board",
	ExerciseBank = "/teacher/:courseId/?langOvrd=:language#/exbank",
	ExtraMaterials = "/teacher/:courseId/?langOvrd=:language#/extra_material",
	FollowUp = "/teacher/:courseId/?langOvrd=:language#/followup",
	FollowUpExercise = "/teacher/:courseId/?langOvrd=language#/followup/chapter/:chapterId/student/:studentId/:exerciseType/:exerciseId",
	Forum = "/teacher/:courseId/?langOvrd=:language#/forum/thread",
	ProgExerciseEditor = "/teacher/:courseId/?langOvrd=:language#/exercise_template/prog/:exerciseId",
	Statistics = "/teacher/:courseId/?langOvrd=:language#/stat",
	Students = "/teacher/:courseId/?langOvrd=:language#/students",
}

export enum StudentCoursePage {
	BulletinBoard = "/student/:courseId/?langOvrd=:language#/bulletin_board",
	ExtraMaterials = "/student/:courseId/?langOvrd=:language#/extra_material",
	MyCorner = "/student/:courseId/?langOvrd=:language#/my_corner",
	Forum = "/student/:courseId/?langOvrd=:language#/forum/thread",
}

const CourseFramedPage = (props: {
	courseId: number;
	coursePage: TeacherCoursePage | StudentCoursePage;
	params?: {[key: string]: string | undefined};
}): JSX.Element => {
	const appbarHeight = useNavBarHeight();

	const locale = useCurrentLocale();

	const path = generatePath(props.coursePage, ({
		courseId: props.courseId,
		language: locale,
		...props.params,
	} as unknown) as {courseId: number; "language#": string});

	const src = resolveLinkToOldSite(path);

	const frame = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		frame.current?.contentWindow?.location.replace(src);
	}, [src]);

	return (
		<iframe
			height={`${window.innerHeight - 6 - appbarHeight}`}
			style={{border: 0, width: "100%"}}
			ref={frame}
		/>
	);
};

export default CourseFramedPage;
