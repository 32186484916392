import {createSlice} from "@reduxjs/toolkit";

import deleteCourses from "../courses/deleteCourses";
import type FetchStatus from "../FetchStatus";
import fetchCourseContentStatistics from "./fetchCourseContentStatistics";
import {keyProvider} from "../keyProvider";

type State = {
	status: FetchStatus;
};

type SliceState = {
	byKey: {
		[courseKey: string]: State | undefined;
	};
};

const initialState: SliceState = {
	byKey: {},
};

const courseContentStatisticsSlice = createSlice({
	name: "courseContentStatistics",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCourseContentStatistics.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			state.byKey[courseKey] = {status: "pending"};
		});
		builder.addCase(fetchCourseContentStatistics.fulfilled, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			state.byKey[courseKey] = {status: "succeeded"};
		});
		builder.addCase(fetchCourseContentStatistics.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			state.byKey[courseKey] = {status: "failed"};
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				delete state.byKey[keyProvider.course(id)];
			});
		});
	},
});

export default courseContentStatisticsSlice.reducer;
