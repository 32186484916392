import {createSlice} from "@reduxjs/toolkit";

import fetchProctoringSettings from "./fetchProctoringSettings";
import type FetchStatus from "../FetchStatus";
import type ProctoringSettings from "./ProctoringSettings";

type State = {
	values: ProctoringSettings;
	status: FetchStatus;
};

const initialState: State = {
	values: {
		entityName: "",
		licenceKey: "",
	},
	status: "none",
};

const settingsSlice = createSlice({
	name: "proctoringSettings",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchProctoringSettings.pending, (state) => {
			state.status = "pending";
		});
		builder.addCase(fetchProctoringSettings.rejected, (state) => {
			state.status = "failed";
		});
		builder.addCase(fetchProctoringSettings.fulfilled, (state, {payload}) => {
			state.status = "succeeded";
			state.values = payload;
		});
	},
});

export default settingsSlice.reducer;
