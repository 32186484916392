import {createSlice} from "@reduxjs/toolkit";

import type FetchStatus from "../FetchStatus";
import type OrganisationGroup from "./UserOrganisationGroup";
import logoutUser from "../userSession/logoutUser";
import fetchUserOrganisationGroups from "./fetchUserOrganisationGroups";

type State = {groups: OrganisationGroup[]; status: FetchStatus};

const initialState: State = {
	groups: [],
	status: "none",
};

export const userOrganisationGroupsSlice = createSlice({
	name: "userOrganisationGroups",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchUserOrganisationGroups.fulfilled,
			(state, {payload}) => {
				state.status = "succeeded";
				state.groups = payload.groups;
			}
		);
		builder.addCase(fetchUserOrganisationGroups.pending, (state) => {
			state.status = "pending";
			state.groups = [];
		});
		builder.addCase(fetchUserOrganisationGroups.rejected, (state) => {
			state.status = "failed";
		});

		builder.addCase(logoutUser.fulfilled, () => initialState);
	},
});

export default userOrganisationGroupsSlice.reducer;
