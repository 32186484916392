import {Localized} from "@fluent/react";
import {Box, Grid, useTheme} from "@mui/material";
import React from "react";

import useMobileMode from "../../../hooks/useMobileMode";
import RecommendationsWidget from "./RecommendationsWidget";

function Dashboard(props: {organisationName: string}): JSX.Element {
	const theme = useTheme();
	const mobileMode = useMobileMode("md");

	return (
		<Box display="flex" style={{maxWidth: theme.breakpoints.values.xl}}>
			<Box
				pt={mobileMode ? 3 : 5}
				px={mobileMode ? 3 : 6}
				display="flex"
				style={{width: "100%"}}
			>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<RecommendationsWidget
							id="recommendations"
							title={
								<Localized id="administration-dashboard-recommendations-title">
									Recommendations
								</Localized>
							}
							organisationName={props.organisationName}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default Dashboard;
