import {makeStyles} from "@mui/styles";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";
import React from "react";

import useDateFormat from "../../i18n/useDateFormat";
import parseDate from "../../helpers/parseDate";
import {StudentCardProps} from "./StudentCardDashboard";

const useStyles = makeStyles((theme) => ({
	typographytitle: {
		fontWeight: 500,
	},
	opacity: {opacity: "0.5"},
	watchlater: {
		height: theme.spacing(2.5),
		marginRight: theme.spacing(1),
	},
}));

const StudentCardDashboardUpcoming = (props: StudentCardProps): JSX.Element => {
	const classes = useStyles();
	const {title, image} = props;
	const formatDate = useDateFormat();

	const startDate = formatDate(parseDate(props.startDate));
	const endDate = formatDate(parseDate(props.endDate));

	return (
		<Card>
			<CardActionArea disabled>
				<CardMedia
					className={classes.opacity}
					component="img"
					alt=""
					height="136"
					image={image}
				/>
				<CardContent>
					<Typography
						className={classes.typographytitle}
						gutterBottom
						color="textSecondary"
						variant="subtitle1"
						component="h2"
					>
						{title}
					</Typography>
					<Box display="flex" flex-direction="row">
						<WatchLaterIcon className={classes.watchlater} color="secondary" />
						<Typography color="textSecondary" variant="body2">
							{startDate} - {endDate}
						</Typography>
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default StudentCardDashboardUpcoming;
