type Button = {
	class:
		| "storage"
		| "normal"
		| "function"
		| "available"
		| "action"
		| "operation"
		| "invisible";
	id: string;
	type: string;
	label: string;
	expression?: string;
};

const buttons: Button[] = [
	// 1st row
	{
		class: "storage",
		id: "memin",
		type: "storage",
		label: "M<sub id='memin'>in</sub>",
	},
	{
		class: "storage",
		id: "memout",
		type: "storage",
		label: "M<sub id='memout'>out</sub>",
	},
	{
		class: "storage",
		id: "ans",
		type: "storage",
		label: "ANS",
	},
	{
		class: "function",
		id: "log",
		type: "input",
		label: "log",
		expression: "log(",
	},
	{
		class: "function",
		id: "ln",
		type: "input",
		label: "ln",
		expression: "ln(",
	},

	// 2nd row
	{
		class: "function",
		id: "sin",
		type: "input",
		label: "sin",
		expression: "sin(",
	},
	{
		class: "function",
		id: "cos",
		type: "input",
		label: "cos",
		expression: "cos(",
	},
	{
		class: "function",
		id: "tan",
		type: "input",
		label: "tan",
		expression: "tan(",
	},
	{
		class: "function",
		id: "square",
		type: "input",
		label: "x²",
		expression: "^2",
	},
	{
		class: "function",
		id: "square-root",
		type: "input",
		label: "√",
		expression: "√(",
	},

	// 3rd row
	{
		class: "function",
		id: "asin",
		type: "input",
		label: "sin<sup id='asin'>-1</sup>",
		expression: "asin(",
	},
	{
		class: "function",
		id: "acos",
		type: "input",
		label: "cos<sup id='acos'>-1</sup>",
		expression: "acos(",
	},
	{
		class: "function",
		id: "atan",
		type: "input",
		label: "tan<sup id='atan'>-1</sup>",
		expression: "atan(",
	},
	{
		class: "function",
		id: "tenpower",
		type: "input",
		label: "10<sup id='tenpower'>x</sup>",
		expression: "×10^",
	},
	{
		class: "function",
		id: "power",
		type: "input",
		label: "x<sup id='power'>y</sup>",
		expression: "^",
	},

	// 4th row
	{
		class: "normal",
		id: "1",
		type: "input",
		label: "1",
		expression: "1",
	},
	{
		class: "normal",
		id: "2",
		type: "input",
		label: "2",
		expression: "2",
	},
	{
		class: "normal",
		id: "3",
		type: "input",
		label: "3",
		expression: "3",
	},
	{
		class: "operation",
		id: "open-parenthesis",
		type: "input",
		label: "(",
		expression: "(",
	},
	{
		class: "operation",
		id: "close-parenthesis",
		type: "input",
		label: ")",
		expression: ")",
	},

	// 5th row
	{
		class: "normal",
		id: "4",
		type: "input",
		label: "4",
		expression: "4",
	},
	{
		class: "normal",
		id: "5",
		type: "input",
		label: "5",
		expression: "5",
	},
	{
		class: "normal",
		id: "6",
		type: "input",
		label: "6",
		expression: "6",
	},
	{
		class: "operation",
		id: "addition",
		type: "input",
		label: "+",
		expression: "+",
	},
	{
		class: "operation",
		id: "subtraction",
		type: "input",
		label: "–",
		expression: "–",
	},

	// 6th row
	{
		class: "normal",
		id: "7",
		type: "input",
		label: "7",
		expression: "7",
	},
	{
		class: "normal",
		id: "8",
		type: "input",
		label: "8",
		expression: "8",
	},
	{
		class: "normal",
		id: "9",
		type: "input",
		label: "9",
		expression: "9",
	},
	{
		class: "operation",
		id: "multiplication",
		type: "input",
		label: "×",
		expression: "×",
	},
	{
		class: "operation",
		id: "division",
		type: "input",
		label: "÷",
		expression: "÷",
	},

	// 7th row
	{
		class: "normal",
		id: "comma",
		type: "input",
		label: ".",
		expression: ".",
	},
	{
		class: "normal",
		id: "0",
		type: "input",
		label: "0",
		expression: "0",
	},
	{
		class: "normal",
		id: "minus",
		type: "input",
		label: "(-)",
		expression: "-",
	},
	{
		class: "normal",
		id: "pi",
		type: "input",
		label: "π",
		expression: "π",
	},
	{
		class: "normal",
		id: "e",
		type: "input",
		label: "e",
		expression: "e",
	},

	// 8th row
	{
		class: "action",
		id: "delete",
		type: "key",
		label: "⌫",
	},
	{
		class: "action",
		id: "clear",
		type: "key",
		label: "AC",
	},
	{
		class: "invisible",
		id: "none1",
		type: "none",
		label: "",
	},
	{
		class: "invisible",
		id: "none2",
		type: "none",
		label: "",
	},
	{
		class: "normal",
		id: "equals",
		type: "key",
		label: "=",
	},
];

export {buttons};
export type {Button};
