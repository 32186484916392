import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Box, IconButton} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

import {useAppSelector} from "../../store/hooks";
import selectMessage from "../../store/tutorQuestions/selectMessage";

function MessageNavigation(props: {
	messageKeys: string[];
	current: string | null;
}): JSX.Element {
	const {current, messageKeys} = props;

	const index = (current && messageKeys.indexOf(current)) || 0;
	const backwardDisabled = index <= 0;
	const forwardDisabled = index < 0 || index === messageKeys.length - 1;

	const prevMessageId = useAppSelector(
		(state) => selectMessage(state, messageKeys[index - 1])?.id
	);
	const nextMessageId = useAppSelector(
		(state) => selectMessage(state, messageKeys[index + 1])?.id
	);

	return (
		<Box display="flex" flexGrow={1} justifyContent="flex-end">
			<Box>
				<IconButton
					size="medium"
					disabled={backwardDisabled}
					component={Link}
					to={`?questionId=${prevMessageId}`}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
			</Box>
			<Box>
				<IconButton
					size="medium"
					disabled={forwardDisabled}
					component={Link}
					to={`?questionId=${nextMessageId}`}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</Box>
		</Box>
	);
}

export default MessageNavigation;
