import {createAsyncThunk} from "@reduxjs/toolkit";

import featuresService from "../services/featuresService";

const fetchFeatures = createAsyncThunk("features/fetchFeatures", async () => {
	const features = await featuresService.getFeatures();

	return features;
});

export default fetchFeatures;
