import {createSlice} from "@reduxjs/toolkit";

import updateCourseSettings from "../courses/updateCourseSettings";
import fetchNumberOfPendingEnrolmentApplications from "./fetchNumberOfPendingEnrolmentApplications";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import {keyProvider} from "../keyProvider";

type State = {
	numberOfPending: number;
	courseKey: string;
	status: FetchStatus;
};

const initialState: State = {
	numberOfPending: 0,
	courseKey: "",
	status: "none",
};

const enrolmentApplicationsSlice = createSlice({
	name: "enrolmentApplications",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchNumberOfPendingEnrolmentApplications.pending,
			(_, action) => ({
				courseKey: keyProvider.course(action.meta.arg.courseId),
				numberOfPending: 0,
				status: "pending",
			})
		);
		builder.addCase(
			fetchNumberOfPendingEnrolmentApplications.rejected,
			(_, action) => ({
				courseKey: keyProvider.course(action.meta.arg.courseId),
				numberOfPending: 0,
				status: inferFetchStatusFromError(action),
			})
		);
		builder.addCase(
			fetchNumberOfPendingEnrolmentApplications.fulfilled,
			(_, {payload}) => ({
				numberOfPending: payload.number,
				courseKey: payload.courseKey,
				status: "succeeded",
			})
		);

		builder.addCase(updateCourseSettings.fulfilled, (state, {payload}) => {
			if (payload.settings.selfEnrolment) {
				state.numberOfPending = 0;
				state.courseKey = payload.courseKey;
				state.status = "none";
			}
		});
	},
});

export default enrolmentApplicationsSlice.reducer;
