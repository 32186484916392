import {Localized, useLocalization} from "@fluent/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DomainIcon from "@mui/icons-material/Domain";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Route, Link as RouterLink, useHistory} from "react-router-dom";

import {resolveLinkToOldSite} from "../../helpers/pathHelpers";
import ControlManagementIcon from "../../icons/ControlManagementIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import NavProfileRoleSelector from "./NavProfileRoleSelector";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import fetchUserOrganisationGroups from "../../store/organisationGroups/fetchUserOrganisationGroups";
import selectUserOrganisationGroups, {
	selectUserOrganisationGroupsStatus,
} from "../../store/organisationGroups/selectUserOrganisationGroups";
import OrganisationGroup from "../../store/organisationGroups/UserOrganisationGroup";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import logoutUser from "../../store/userSession/logoutUser";

interface Props {
	userId: number;
	children?: React.ReactNode;
	profileName: string;
	profileUserName: string;
	avatar: React.ReactElement;
	onClick: () => void;
}

function NavProfile(props: Props): JSX.Element {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const [groupSelectorOpen, setGroupSelectorOpen] = useState(false);

	const groups = useAppSelector(selectUserOrganisationGroups);
	const groupsFetchStatus = useAppSelector(selectUserOrganisationGroupsStatus);

	const user = useAppSelector(selectUserProfile);

	useEffect(() => {
		if (groupsFetchStatus === "none") {
			dispatch(fetchUserOrganisationGroups({userId: user.id}));
		}
	}, [dispatch, groupsFetchStatus, user.id]);

	const logout = () => {
		history.replace("/");
		dispatch(logoutUser({logoutOnServer: true}));
		props.onClick();
	};

	if (groupSelectorOpen) {
		return (
			<OrganisationGroupSelector
				groups={groups}
				onItemClick={props.onClick}
				onBack={() => setGroupSelectorOpen(false)}
			/>
		);
	}

	return (
		<>
			<List dense component="div">
				<ListItem>
					<ListItemAvatar>{props.avatar}</ListItemAvatar>
					<ListItemText
						primary={props.profileName}
						secondary={props.profileUserName}
						primaryTypographyProps={{
							variant: "subtitle1",
							fontWeight: "fontWeightMedium",
						}}
						secondaryTypographyProps={{variant: "caption"}}
					/>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						onClick={props.onClick}
						component={RouterLink}
						to={`/account`}
					>
						<ListItemIcon>
							<AccountCircleIcon color="action" fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={<Localized id="nav-account">Account</Localized>}
						/>
					</ListItemButton>
				</ListItem>
				{groups.length > 0 && (
					<ListItem disablePadding>
						<ListItemButton onClick={() => setGroupSelectorOpen(true)}>
							<ListItemIcon>
								<DomainIcon color="action" fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={
									<Localized id="nav-organisation-groups">
										Organisation groups
									</Localized>
								}
								sx={{mr: 2}}
							/>
							<KeyboardArrowRightIcon color="action" />
						</ListItemButton>
					</ListItem>
				)}
				{user.isSuperAdmin && (
					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href={resolveLinkToOldSite("/admin/")}
							target="_blank"
							onClick={props.onClick}
						>
							<ListItemIcon>
								<ControlManagementIcon color="action" fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={
									<Localized id="nav-system-administration">
										System administration
									</Localized>
								}
								sx={{mr: 2}}
							/>
							<OpenInNewIcon color="action" />
						</ListItemButton>
					</ListItem>
				)}
				<Route path="/courses/:id">
					<NavProfileRoleSelector
						userId={props.userId}
						onClick={props.onClick}
					/>
				</Route>
			</List>
			<Divider />
			<List dense>
				<ListItem disablePadding>
					<ListItemButton onClick={logout}>
						<ListItemIcon>
							<LogoutIcon color="action" fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={<Localized id="nav-logout">Logout</Localized>}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</>
	);
}

function OrganisationGroupSelector(props: {
	groups: OrganisationGroup[];
	onBack: () => void;
	onItemClick: () => void;
}) {
	const {groups} = props;

	const {l10n} = useLocalization();

	return (
		<>
			<Stack direction="row" spacing={0.5} sx={{p: 1, alignItems: "center"}}>
				<IconButton
					aria-label={l10n.getString("nav-organisation-groups-label-back")}
					onClick={props.onBack}
				>
					<ArrowBackIcon />
				</IconButton>
				<Typography variant="subtitle1">
					<Localized id="nav-organisation-groups">
						Organisation groups
					</Localized>
				</Typography>
			</Stack>

			<Divider />

			<List dense component="div">
				{groups.map((g) => (
					<ListItem key={g.name} disablePadding>
						<ListItemButton
							onClick={props.onItemClick}
							component="a"
							href={`/organisation-groups/${g.name}`}
						>
							<ListItemText primary={g.displayName} secondary={g.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</>
	);
}

export default NavProfile;
