import {axiosInstance as client} from "./axiosInstance";

export type CourseAssociated = "staff" | "students";

export const adminService = {
	grantAdminRigts: (orgName: string, userIds: number[]): Promise<void> => {
		const url = `/api/organisations/${orgName}/administrators`;
		return client.post(url, userIds);
	},

	revokeAdminRights: (orgName: string, userIds: number[]): Promise<void> => {
		const url = `/api/organisations/${orgName}/administrators`;
		return client.delete(url, {data: userIds});
	},

	deleteUsers: (orgName: string, userIds: number[]): Promise<void> => {
		const url = `/api/organisations/${orgName}/users`;
		return client.delete(url, {data: userIds});
	},

	deleteCourses: async (
		orgName: string,
		courseIds: number[],
		options?: {deleteAssociated?: CourseAssociated[]}
	): Promise<void> => {
		const url = `/api/organisations/${orgName}/courses`;

		const data: {
			ids: number[];
			options?: {deleteAssociated?: CourseAssociated[]};
		} = {ids: courseIds};

		if (options?.deleteAssociated && options.deleteAssociated.length > 0) {
			data.options = options;
		}

		return client.delete(url, {data});
	},

	archiveCourses: async (
		orgName: string,
		schedule: {courseId: number; archivalDate?: string}[]
	): Promise<void> => {
		await client.post(
			`/api/organisations/${orgName}/course-archival-schedules`,
			schedule
		);
	},

	restoreCourses: async (orgName: string, courseIds: number[]) => {
		await client.delete(
			`/api/organisations/${orgName}/course-archival-schedules`,
			{data: courseIds}
		);
	},
};
