import {createSelector} from "reselect";
import type {RootState} from "../store";

const selectCourseProgress = createSelector(
	(state: RootState) => state.courseProgress,
	(_: RootState, courseKey: string) => courseKey,
	(progress, courseKey) => {
		if (progress.courseKey !== courseKey) {
			return [];
		}

		return Object.values(progress.byChapters).flatMap(
			(ch) => ch?.outcomes ?? []
		);
	}
);

export default selectCourseProgress;
