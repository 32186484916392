import {createSelector} from "@reduxjs/toolkit";
import type FetchStatus from "../FetchStatus";
import type {ExerciseOutcomes} from "../outcomes/ExerciseOutcomes";
import type {RootState} from "../store";

const empty: [ExerciseOutcomes[], FetchStatus] = [[], "none"];

const selectAllChapterSessionOutcomes = createSelector(
	(state: RootState) => state.chapterSessions.outcomes,
	(_: RootState, sessionKey: string) => sessionKey,
	(outcomes, sessionKey): [ExerciseOutcomes[], FetchStatus] => {
		if (outcomes.sessionKey !== sessionKey) {
			return empty;
		}

		return [
			Object.values(outcomes.outcomes) as ExerciseOutcomes[],
			outcomes.status,
		];
	}
);

export default selectAllChapterSessionOutcomes;
