import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectNumberOfPendingEnrolmentApplications(
	state: RootState,
	courseKey: string
) {
	return state.enrolment.applications.courseKey === courseKey
		? state.enrolment.applications.numberOfPending
		: 0;
}

function selectFetchStatusOfNumberOfPendingEnrolmentApplications(
	state: RootState,
	courseKey: string
): FetchStatus {
	if (state.enrolment.applications.courseKey !== courseKey) {
		return "none";
	}

	return state.enrolment.applications.status;
}

export default selectNumberOfPendingEnrolmentApplications;
export {selectFetchStatusOfNumberOfPendingEnrolmentApplications};
