import {Localized} from "@fluent/react";
import {Tab} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, Redirect, Route, Switch, useParams} from "react-router-dom";

import Content from "../../components/content/Content";
import Tests from "../../components/content/Tests";
import CourseFramedPage, {
	StudentCoursePage,
} from "../../components/CourseFramedPage";
import MyCorner from "../../components/myCorner/MyCorner";
import StudyProcessPreferences from "../../components/myCorner/StudyProcessPreferences";
import BackDestination from "../../components/nav/BackDestination";
import SubtitleNav from "../../components/nav/SubtitleNav";
import {SidebarContextProvider} from "../../components/sidebars/SidebarContext";
import TutorHelp from "../../components/tutoring/TutorHelp";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import useTrackAppUsage from "../../hooks/useTrackAppUsage";
import fetchUnreadAnnouncementsNumber from "../../store/bulletinBoard/fetchUnreadAnnouncementsNumber";
import selectUnreadAnnouncementsNumber from "../../store/bulletinBoard/selectUnreadAnnouncementsNumber";
import unreadAnnouncementsNumberChanged from "../../store/bulletinBoard/unreadAnnouncementsNumberChanged";
import CourseFeature from "../../store/courses/CourseFeature";
import type {CourseStudyModuleName} from "../../store/courses/CourseStudyModule";
import CourseType from "../../store/courses/CourseType";
import fetchCourse from "../../store/courses/fetchCourse";
import fetchCourseStudyModules from "../../store/courses/fetchCourseStudyModules";
import selectCourse, {
	selectCourseFetchStatus,
} from "../../store/courses/selectCourse";
import useAvailableStudyModules from "../../store/courses/useAvailableStudyModules";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import AccessDeniedError from "../../store/userSession/AccessDeniedError";
import useAccessDeniedErrorTracking from "../../store/userSession/useAccessDeniedErrorTracking";
import TabLabelWithBadge from "../../utils/TabLabelWithBadge";
import Condition from "../../utils/Condition";
import LoadingError from "../../utils/errors/LoadingError";
import ObservableRoute from "../../utils/ObservableRoute";

const Studying = (props: {organisationName: string}): JSX.Element => {
	const dispatch = useAppDispatch();

	const params = useParams<{id: string}>();
	const courseId = parseInt(params.id, 10);

	useTrackAppUsage(props.organisationName, "student", courseId);

	const accessDenied = useAccessDeniedErrorTracking();

	const userId = useAppSelector(selectUserId);

	const courseKey = keyProvider.course(courseId);

	const course = useAppSelector((state) => selectCourse(state, courseKey));
	const courseFetchStatus = useAppSelector((state) =>
		selectCourseFetchStatus(state, courseKey)
	);

	const unreadAnnouncementsNumber = useAppSelector((state) =>
		selectUnreadAnnouncementsNumber(state)
	);

	const [
		modules,
		available,
		modulesFetchStatus,
		awaitingModules,
	] = useAvailableStudyModules(courseId);

	useEffect(() => {
		if (userId && courseId) {
			dispatch(fetchUnreadAnnouncementsNumber({userId, courseId}));
			return () => dispatch(unreadAnnouncementsNumberChanged({number: 0}));
		}

		return () => {};
	}, [courseId, dispatch, userId]);

	useEffect(() => {
		if (courseId && courseFetchStatus === "none") {
			dispatch(fetchCourse(courseId));
		}
	}, [courseFetchStatus, courseId, dispatch]);

	useEffect(() => {
		if (courseId && userId && modulesFetchStatus === "none") {
			dispatch(fetchCourseStudyModules({userId, courseId}));
		}
	}, [courseId, dispatch, modulesFetchStatus, userId]);

	const createSubroute = useSubrouteCreator();
	const [featureEnabled, awaitingFeatures] = useFeatureEnabled();

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	if (
		accessDenied ||
		(modulesFetchStatus === "succeeded" && modules.length === 0)
	) {
		return <AccessDeniedError />;
	}

	if (courseFetchStatus === "failed" || modulesFetchStatus === "failed") {
		return (
			<LoadingError
				description={
					<Localized id="course-studying-loading-error">
						Failed to load the course
					</Localized>
				}
				onReload={() => {
					if (courseFetchStatus === "failed") {
						dispatch(fetchCourse(courseId));
					}
					if (modulesFetchStatus === "failed") {
						dispatch(fetchCourseStudyModules({userId, courseId}));
					}
				}}
			/>
		);
	}

	if (!userId || !course) {
		return <></>;
	}

	const contentPage = createSubroute("/");
	const myCornerPage = createSubroute("/my-corner");
	const studyProcessPreferencesPage = createSubroute(
		"/study-process-preferences"
	);
	const extraMaterialsPage = createSubroute("/extra-materials");
	const bulletinBoardPage = createSubroute("/bulletin-board");
	const tutorHelpPage = createSubroute("/tutor-help");
	const forumPage = createSubroute("/forum");

	const newAnalyticsEnabled =
		featureEnabled(Feature.NewAnalytics) &&
		course.features.includes(CourseFeature.NewAnalytics);

	const isExam = course.type === CourseType.Exam;

	const moduleTabs: {
		[key in CourseStudyModuleName]: {
			label: React.ReactNode;
			page: ReturnType<typeof createSubroute>;
		};
	} = {
		bulletin_board: {
			label: (
				<TabLabelWithBadge
					content={
						<Localized id="course-studying-linktab-label-bulletin-board">
							Bulletin board
						</Localized>
					}
					badgeContent={unreadAnnouncementsNumber}
				/>
			),
			page: bulletinBoardPage,
		},
		content: {
			label: isExam ? (
				<Localized id="course-studying-linktab-label-tests">Tests</Localized>
			) : (
				<Localized id="course-studying-linktab-label-learning-material">
					Contents
				</Localized>
			),
			page: contentPage,
		},
		extra_materials: {
			label: (
				<Localized id="course-studying-linktab-label-extra-materials">
					Extra materials
				</Localized>
			),
			page: extraMaterialsPage,
		},
		forum: {
			label: (
				<Localized id="course-studying-linktab-label-forum">Forum</Localized>
			),
			page: forumPage,
		},
		my_corner: {
			label: (
				<Localized id="course-studying-linktab-label-my-corner">
					My corner
				</Localized>
			),
			page: myCornerPage,
		},
		tutoring: {
			label: (
				<Localized id="course-studying-linktab-label-tutor-help">
					Tutor help
				</Localized>
			),
			page: tutorHelpPage,
		},
	};

	const navigationTabs = (
		<SubtitleNav title={course.name} selectedTab={selectedTab}>
			{modules.map((m) => (
				<Tab
					key={m.name}
					label={moduleTabs[m.name].label}
					component={Link}
					value={moduleTabs[m.name].page.link}
					to={moduleTabs[m.name].page.link}
				/>
			))}
		</SubtitleNav>
	);

	return (
		<Switch>
			<ObservableRoute
				path={extraMaterialsPage.route}
				onRender={() => setSelectedTab(extraMaterialsPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("extra_materials")}
					otherwise={<Redirect to={contentPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={StudentCoursePage.ExtraMaterials}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={myCornerPage.route}
				exact
				onRender={() => setSelectedTab(myCornerPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("my_corner")}
					otherwise={<Redirect to={contentPage.link} />}
					pending={awaitingModules}
				>
					<Condition
						value={newAnalyticsEnabled}
						pending={awaitingFeatures}
						otherwise={
							<CourseFramedPage
								courseId={courseId}
								coursePage={StudentCoursePage.MyCorner}
							/>
						}
					>
						<MyCorner courseId={courseId} studentId={userId} />
					</Condition>
				</Condition>
			</ObservableRoute>

			<Route path={studyProcessPreferencesPage.route}>
				<Condition
					value={newAnalyticsEnabled}
					pending={awaitingFeatures}
					otherwise={<Redirect to={myCornerPage.link} />}
				>
					<BackDestination key="study-preferences" path={myCornerPage.link}>
						<StudyProcessPreferences courseId={courseId} studentId={userId} />
					</BackDestination>
				</Condition>
			</Route>

			<ObservableRoute
				path={bulletinBoardPage.route}
				onRender={() => setSelectedTab(bulletinBoardPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("bulletin_board")}
					otherwise={<Redirect to={contentPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={StudentCoursePage.BulletinBoard}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={forumPage.route}
				onRender={() => setSelectedTab(forumPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("forum")}
					otherwise={<Redirect to={contentPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={StudentCoursePage.Forum}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={tutorHelpPage.route}
				onRender={() => setSelectedTab(tutorHelpPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("tutoring")}
					otherwise={<Redirect to={contentPage.link} />}
					pending={awaitingModules}
				>
					<TutorHelp courseId={courseId} pageLink={tutorHelpPage.link} />
				</Condition>
			</ObservableRoute>

			{isExam && (
				<ObservableRoute
					path={contentPage.route}
					exact
					onRender={() => setSelectedTab(contentPage.link)}
				>
					{navigationTabs}

					<Condition value={available("content")} pending={awaitingModules}>
						<Tests courseId={courseId} />
					</Condition>
				</ObservableRoute>
			)}

			<ObservableRoute
				path={contentPage.route}
				onRender={() => setSelectedTab(contentPage.link)}
			>
				{navigationTabs}

				<Condition value={available("content")} pending={awaitingModules}>
					<SidebarContextProvider>
						<Content
							courseId={courseId}
							userId={userId}
							courseType={course.type}
						/>
					</SidebarContextProvider>
				</Condition>
			</ObservableRoute>
		</Switch>
	);
};

export default Studying;
