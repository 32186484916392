import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import GroupUsersFilters from "./GroupUsersFilters";
import parseDate from "../../helpers/parseDate";
import {formatAtLocalTimeZone} from "../../helpers/dateTimeHelpers";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import NewGroupUserDialog from "./NewGroupUserDialog";
import type FetchStatus from "../../store/FetchStatus";
import type UserSearchResult from "../../store/services/dtos/UserSearchResult";
import {userService} from "../../store/services/userService";
import type {GroupUserSearchCriteria} from "../../store/services/userService";
import useAssignToGroupCoursesAction from "./useAssignToGroupCoursesAction";
import useEnrolInGroupCourseAction from "./useEnrolInGroupCourseAction";
import useGrantAdminInGroupOrganisationAction from "./useGrantAdminInGroupOrganisationAction";
import ExtendableBox from "../../utils/ExtendableBox";
import HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";

const filtersBarWidth = 320;

const useStyles = makeStyles<Theme, {navBarHeight: number}>((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<UserSearchResult>[] = [
	{
		id: "userName",
		label: "group-users-table-column-user-name",
		sortable: true,
	},
	{
		id: "firstName",
		label: "group-users-table-column-first-name",
		sortable: true,
		width: 210,
	},
	{
		id: "lastName",
		label: "group-users-table-column-last-name",
		sortable: true,
		width: 210,
	},
	{
		id: "lastLogin",
		label: "group-users-table-column-last-login",
		sortable: false,
		width: 210,
		align: "right",
	},
];

const columnNumber = headCells.length + 1;

function GroupUsers(props: {groupName: string}) {
	const {groupName} = props;

	const navBarHeight = useNavBarHeight();
	const classes = useStyles({navBarHeight});
	const mobileMode = useMobileMode("md");

	const {
		page: usersPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage: fetchUserPage,
		retryFetching,
		reloadPage,
	} = usePaginationState<UserSearchResult>();

	const users = usersPage.content;

	const [filtersOpen, setFiltersOpen] = useState(false);
	const [criteria, setCriteria] = useState<GroupUserSearchCriteria>({});

	const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
	const [sortField, setSortField] = useState<keyof UserSearchResult>(
		"userName"
	);
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const [usersPerPage, setUsersPerPage] = useState(10);

	const changeQuery = useCallback((query: string) => {
		setCriteria((prev) => ({...prev, query}));
	}, []);

	const {
		select: selectUser,
		bulkSelectionCheckbox,
		selected: selectedUsers,
		resetSelection,
	} = useBulkSelection(usersPage, (u) => u.id);

	const formatDate = useDateTimeFormat();
	const {l10n} = useLocalization();

	const newUserDialog = useRef<{status: () => FetchStatus}>(null);

	const [
		assignToCourses,
		assignToCoursesDialog,
	] = useAssignToGroupCoursesAction(groupName, mobileMode, resetSelection);

	const [enrolInCourse, enrolInCourseDialog] = useEnrolInGroupCourseAction(
		groupName,
		mobileMode,
		resetSelection
	);

	const [grantAdmin, grantAdminDialog] = useGrantAdminInGroupOrganisationAction(
		groupName,
		resetSelection
	);

	useEffect(() => {
		fetchFirstPage(
			() =>
				userService.searchUsersInGroup(
					groupName,
					{
						...criteria,
						roles:
							criteria.roles && criteria.roles.length > 0
								? criteria.roles
								: undefined,
						lastLoginAfter: criteria.lastLoginAfter
							? formatAtLocalTimeZone(parseDate(criteria.lastLoginAfter))
							: undefined,
						lastLoginBefore: criteria.lastLoginBefore
							? formatAtLocalTimeZone(parseDate(criteria.lastLoginBefore))
							: undefined,
					},
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					usersPerPage
				),
			resetSelection
		);
	}, [
		criteria,
		fetchFirstPage,
		groupName,
		resetSelection,
		sortField,
		sortOrder,
		usersPerPage,
	]);

	function selectedUser(id: number) {
		return selectedUsers.indexOf(id) !== -1;
	}

	function userName(user?: UserSearchResult) {
		if (!user) {
			return "";
		}

		return user.firstName && user.lastName
			? `${user.firstName} ${user.lastName}`
			: user.userName;
	}

	function closeNewUserDialog() {
		setNewUserDialogOpen(false);
		if (newUserDialog.current?.status() === "succeeded") {
			reloadPage();
		}
	}

	const noUsersSelected = selectedUsers.length === 0;

	const newUserDialogId = "new-user";

	return (
		<>
			<div className={classes.root}>
				<ExtendableBox
					rightExtension={filtersOpen || mobileMode ? 0 : filtersBarWidth}
					minWidth={0}
				>
					<Box
						display="flex"
						flexDirection="column"
						pt={mobileMode ? 3 : 5}
						px={mobileMode ? 3 : 6}
						pb={1.5}
						position="relative"
						style={{height: `calc(100vh - ${navBarHeight + 1}px)`}}
					>
						<TableToolbar
							onToggleFilters={() => setFiltersOpen((prev) => !prev)}
							query={criteria.query ?? ""}
							onQueryChange={changeQuery}
							actionsHint={
								noUsersSelected && (
									<em>
										<Localized id="group-users-action-hint-select-users">
											Select users to perform actions
										</Localized>
									</em>
								)
							}
							mainAction={
								<Button
									variant="contained"
									color="primary"
									startIcon={<AddIcon />}
									onClick={() => setNewUserDialogOpen(true)}
								>
									<Localized id="group-users-action-new-user">
										New user
									</Localized>
								</Button>
							}
							actions={[
								{
									name: l10n.getString(
										"group-users-action-enrol-in-course",
										null,
										"Enrol in a course"
									),
									onClick: () => enrolInCourse(selectedUsers),
									disabled: noUsersSelected,
								},
								{
									name: l10n.getString(
										"group-users-action-assign-to-courses",
										null,
										"Assign to courses"
									),
									onClick: () => assignToCourses(selectedUsers),
									disabled: noUsersSelected,
								},
								{
									name: l10n.getString(
										"group-users-action-grant-admin-role",
										null,
										"Grant administrator role"
									),
									onClick: () =>
										grantAdmin(
											selectedUsers,
											userName(users.find((u) => u.id === selectedUsers[0]))
										),
									disabled: noUsersSelected,
								},
							]}
							searchPlaceholder={l10n.getString(
								"group-users-search-placeholder"
							)}
						/>
						<TableContainer className={classes.container}>
							<Table stickyHeader>
								<SortingHeader
									onOrderChange={(orderBy, order) => {
										setSortOrder(order);
										setSortField(orderBy);
									}}
									order={sortOrder}
									orderBy={sortField}
									headCells={headCells}
									leftAnnex={
										<TableCell padding="checkbox" style={{zIndex: 3}}>
											{bulkSelectionCheckbox}
										</TableCell>
									}
									loading={pageFetchStatus === "pending"}
								/>
								<TableBody>
									{pageFetchStatus === "failed" && (
										<LoadingErrorState
											description={
												<Localized id="group-users-loading-error-descr">
													Something has gone wrong, and we cannot load users
												</Localized>
											}
											columnNumber={columnNumber}
											onReload={retryFetching}
										/>
									)}
									{pageFetchStatus === "succeeded" && users.length === 0 && (
										<NoSearchResultsState
											columnNumber={columnNumber}
											title={
												<Localized id="group-users-no-results">
													No users
												</Localized>
											}
											description={
												<Localized id="group-users-no-results-descr">
													No users were found matching your search criteria. Try
													to adjust filters
												</Localized>
											}
										/>
									)}
									{pageFetchStatus !== "failed" &&
										users.map((user, index) => {
											const selected = selectedUser(user.id);
											const labelId = `table-checkbox-${index}`;
											return (
												<TableRow
													hover
													key={user.id}
													tabIndex={-1}
													selected={selected}
												>
													<TableCell padding="checkbox">
														<Checkbox
															checked={selected}
															inputProps={{"aria-labelledby": labelId}}
															onClick={() => selectUser(user.id)}
														/>
													</TableCell>
													<TableCell component="th" id={labelId} scope="row">
														<Link
															component={RouterLink}
															to={`/users/${user.id}`}
														>
															{user.userName}
														</Link>
													</TableCell>
													<TableCell>{user.firstName}</TableCell>
													<TableCell>{user.lastName}</TableCell>
													<TableCell align="right">
														{user.lastLogin &&
															formatDate(parseDate(user.lastLogin))}
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							ml={2}
						>
							<Typography variant="subtitle2">
								{selectedUsers.length > 0 && (
									<Localized
										id="group-users-selected-row-number"
										vars={{selected: selectedUsers.length}}
									>{`${selectedUsers.length} selected`}</Localized>
								)}
							</Typography>
							<TablePagination
								onPageChange={fetchUserPage}
								pageSize={usersPerPage}
								onPageSizeChange={setUsersPerPage}
								first={Boolean(usersPage.request.first)}
								last={Boolean(usersPage.request.last)}
								next={Boolean(usersPage.request.next)}
								previous={Boolean(usersPage.request.previous)}
								label={
									<Localized id="group-users-per-page">
										Users per page
									</Localized>
								}
								disabled={pageFetchStatus !== "succeeded"}
							/>
						</Box>
					</Box>
				</ExtendableBox>
				<GroupUsersFilters
					groupName={groupName}
					open={filtersOpen}
					width={filtersBarWidth}
					mobileMode={mobileMode}
					onClose={() => setFiltersOpen(false)}
					criteria={criteria}
					onCriteriaChange={setCriteria}
				/>
			</div>

			{enrolInCourseDialog}
			{assignToCoursesDialog}
			{grantAdminDialog}

			<Dialog
				aria-labelledby={newUserDialogId}
				open={newUserDialogOpen}
				fullWidth
				maxWidth="sm"
				onClose={closeNewUserDialog}
			>
				<NewGroupUserDialog
					titleElementId={newUserDialogId}
					groupName={groupName}
					onCancel={closeNewUserDialog}
					dialogRef={newUserDialog}
				/>
			</Dialog>
		</>
	);
}

export default GroupUsers;
