import {Localized} from "@fluent/react";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import {Box, Button, List} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

import {useAppSelector} from "../../store/hooks";
import NavigationItem from "./NavigationItem";
import selectAnsweredForUser from "../../store/tutorQuestions/selectAnsweredForUser";
import selectSentByUser from "../../store/tutorQuestions/selectSentByUser";
import StudentMessagesTab from "./StudentMessagesTab";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";

const StudentNavigation = (props: {
	open: boolean;
	studentId: number;
	selectedTab: StudentMessagesTab;
	variant: "temporary" | "permanent";
	width: string | number;
	smallScreen: boolean;
	onClose?: () => void;
	onSelect: (v: StudentMessagesTab) => void;
}): JSX.Element => {
	const {selectedTab, onSelect, studentId} = props;

	const answerMessagesCount = useAppSelector(
		(state) => selectAnsweredForUser(state, studentId).length
	);
	const sentMessagesCount = useAppSelector(
		(state) => selectSentByUser(state, studentId).length
	);

	return (
		<AppBarDependentDrawer
			anchor="left"
			open={props.open}
			variant={props.variant}
			width={props.width}
			onClose={props.onClose}
		>
			<Box display="flex" flexDirection="column" alignItems="inherit">
				{!props.smallScreen && (
					<Box mx={3} mb={2} mt={2.5}>
						<Button
							fullWidth
							color="primary"
							variant="contained"
							component={Link}
							to={{state: {newQuestion: true}}}
						>
							<Localized id="tutoring-button-ask-tutor">Ask tutor</Localized>
						</Button>
					</Box>
				)}

				<List>
					<NavigationItem
						onClick={() => onSelect(StudentMessagesTab.Answered)}
						icon={<ReplyIcon />}
						label={
							<Localized id="tutoring-student-navigation-answered">
								Answered
							</Localized>
						}
						messageCount={answerMessagesCount}
						active={selectedTab === StudentMessagesTab.Answered}
					/>
					<NavigationItem
						onClick={() => onSelect(StudentMessagesTab.Sent)}
						icon={<SendIcon />}
						label={
							<Localized id="tutoring-student-navigation-sent">Sent</Localized>
						}
						messageCount={sentMessagesCount}
						active={selectedTab === StudentMessagesTab.Sent}
					/>
				</List>
			</Box>
		</AppBarDependentDrawer>
	);
};

export default StudentNavigation;
