import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {studentResponseService} from "../services/studentResponseService";
import {ResponseToSave} from "../studentResponses/Response";

export const saveChapterSessionStudentResponse = createAsyncThunk(
	"chapterSessions/saveStudentResponse",
	async (params: {
		sessionKey: string;
		studentId: number;
		courseId: number;
		chapterId: number;
		exerciseId: number;
		response: ResponseToSave;
	}) => {
		const {chapterId, courseId, exerciseId, response, studentId} = params;

		const feedback = await studentResponseService.saveResponse(
			studentId,
			courseId,
			chapterId,
			exerciseId,
			response
		);

		const responseKey = keyProvider.chapterExercise(chapterId, exerciseId);

		return {
			responseKey,
			response,
			feedback,
		};
	}
);
