import {createSlice} from "@reduxjs/toolkit";

import {fetchStudentMessages, fetchTutorMessages} from "./fetchMessages";
import type FetchStatus from "../FetchStatus";
import sendAnswer from "./sendAnswer";
import {sendNewQuestion} from "./sendNewQuestion";
import type TutorQuestion from "./TutorQuestion";

type TutorQuestions = {
	byKey: {
		[key: string]: TutorQuestion | undefined;
	};
	keys: string[];
	answered: string[];
	courseId: number;
	status: FetchStatus;
};

const initialState: TutorQuestions = {
	byKey: {},
	keys: [],
	answered: [],
	courseId: 0,
	status: "none",
};

export const tutorQuestionsSlice = createSlice({
	name: "tutorQuestions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchStudentMessages.fulfilled, (state, action) => {
			state.byKey = action.payload.entities;
			state.answered = action.payload.answered;
			state.courseId = action.payload.courseId;
			state.keys = action.payload.keys;
		});
		builder.addCase(fetchStudentMessages.pending, (state, action) => {
			state.byKey = {};
			state.answered = [];
			state.courseId = action.meta.arg.courseId;
			state.keys = [];
			state.status = "pending";
		});
		builder.addCase(fetchStudentMessages.rejected, (state) => {
			state.status = "failed";
		});

		builder.addCase(sendAnswer.fulfilled, (state, action) => {
			const q = state.byKey[action.payload.messageKey];
			if (q) {
				state.byKey[action.payload.messageKey] = {
					...q,
					answer: action.payload.answer,
					answerTime: action.payload.answerTime,
					tutorId: action.payload.tutorId,
					tutorName: action.payload.tutorName,
				};
				state.answered.unshift(action.payload.messageKey);
			}
		});

		builder.addCase(fetchTutorMessages.fulfilled, (state, action) => {
			state.byKey = action.payload.entities;
			state.answered = action.payload.answered;
			state.courseId = action.payload.courseId;
			state.keys = action.payload.keys;
			state.status = "succeeded";
		});
		builder.addCase(fetchTutorMessages.pending, (state, action) => {
			state.byKey = {};
			state.answered = [];
			state.courseId = action.meta.arg;
			state.keys = [];
			state.status = "pending";
		});
		builder.addCase(fetchTutorMessages.rejected, (state) => {
			state.status = "failed";
		});

		builder.addCase(sendNewQuestion.fulfilled, (state, action) => {
			state.byKey[action.payload.questionKey] = action.payload.question;
			state.keys.unshift(action.payload.questionKey);
		});
	},
});

export default tutorQuestionsSlice.reducer;
