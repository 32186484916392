import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {analyticsService} from "../services/analyticsService";
import ChapterProgress from "./ChapterProgress";

export const fetchCourseProgress = createAsyncThunk(
	"courseProgress/fetchCourseProgress",
	async (params: {courseId: number; studentId: number}) => {
		const progress = await analyticsService.getStudentCourseProgress(
			params.studentId,
			params.courseId
		);

		const byChapters: {[key: string]: ChapterProgress} = {};

		progress.forEach((p) => {
			const key = keyProvider.chapter(p.chapterId);
			byChapters[key] = p;
		});

		return {byChapters};
	}
);
