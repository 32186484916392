import React, {useContext, useMemo} from "react";
import {useLocation} from "react-router-dom";

import ExerciseNavItem from "./ExerciseNavItem";
import LabelProps from "./LabelProps";
import {getSectionPathByKey} from "../../helpers/pathHelpers";
import {useAppSelector} from "../../store/hooks";
import AssessmentDeliveryContext from "../AssessmentDeliveryContext";

const SectionNavItem = (props: {
	courseId: number;
	sectionKey: string;
	index: string;

	renderExerciseLabel: (
		params: LabelProps,
		exerciseKey: string
	) => React.ReactNode;
	renderLabel: (params: LabelProps) => React.ReactNode;
}): JSX.Element => {
	const location = useLocation();

	const {makeSelectSection, selectSubsectionKeys} = useContext(
		AssessmentDeliveryContext
	);

	const selectSection = useMemo(makeSelectSection, [makeSelectSection]);

	const subsections = useAppSelector((state) =>
		selectSubsectionKeys(state, props.sectionKey)
	);

	const section = useAppSelector((state) =>
		selectSection(state, props.sectionKey)
	);

	if (!section) {
		return <></>;
	}

	const sectionPath = getSectionPathByKey(location.pathname, props.sectionKey);

	return (
		<>
			{props.renderLabel({
				link: sectionPath,
				index: props.index,
				text: section.title,
				active: location.pathname.includes(props.sectionKey) && !location.hash,
			})}

			{subsections.map((key: string, iChild: number) => (
				<ExerciseNavItem
					key={key}
					index={`${props.index}.${iChild + 1}`}
					exerciseKey={key}
					sectionPath={sectionPath}
					renderLabel={props.renderExerciseLabel}
				/>
			))}
		</>
	);
};

export default SectionNavItem;
