import {createSlice} from "@reduxjs/toolkit";
import fetchOrganisationFeatures from "./fetchOrganisationFeatures";
import OrganisationFeature from "./OrganisationFeature";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type OrganisationFeatures = {
	features: OrganisationFeature[];
	status: FetchStatus;
};

const initialState: OrganisationFeatures = {
	features: [],
	status: "none",
};

export const organisationFeaturesSlice = createSlice({
	name: "organisationFeatures",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchOrganisationFeatures.fulfilled, (state, action) => {
			state.features = action.payload;
			state.status = "succeeded";
		});

		builder.addCase(fetchOrganisationFeatures.pending, (state) => {
			state.status = "pending";
		});

		builder.addCase(fetchOrganisationFeatures.rejected, (state, action) => {
			state.status = inferFetchStatusFromError(action);
		});
	},
});

export default organisationFeaturesSlice.reducer;
