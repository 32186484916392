import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Checkbox,
	Chip,
	IconButton,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import React, {useEffect, useImperativeHandle, useState} from "react";

import WidgetTitle from "../analytics/widget/WidgetTitle";
import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import {formatAtLocalTimeZone} from "../../helpers/dateTimeHelpers";
import parseDate from "../../helpers/parseDate";
import useMobileMode from "../../hooks/useMobileMode";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import studentStatusChanged from "../../store/enrolment/studentStatusChanged";
import {useAppDispatch} from "../../store/hooks";
import enrolmentService from "../../store/services/enrolmentService";
import type {
	CourseStudentSearchCriteria,
	CourseStudentSearchResult,
} from "../../store/services/enrolmentService";
import useSnackbar from "../../store/ui/useSnackbar";
import StudentName from "./StudentName";
import StudentSearchToolbar from "./StudentSearchToolbar";
import useEnrolInAnotherCourseOfTeacherAction from "./useEnrolInAnotherCourseOfTeacherAction";
import useSuspendStudentsAction from "./useSuspendStudentsAction";
import useUnsuspendStudentsAction from "./useUnsuspendStudentsAction";
import ListDivider from "../../utils/ListDivider";
import {populate} from "../../utils/populate";
import TableHead from "../../utils/tables/Head";
import type {ColumnDefs} from "../../utils/tables/Head";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";
import useSorting from "../../utils/tables/useSorting";

function StudentsWidget(props: {
	apiRef: React.ForwardedRef<StudentsWidgetApi>;
	organisationName: string;
	courseId: number;
	userId: number;
	viewOnly?: boolean;
}) {
	return (
		<Widget id="students">
			<WidgetTitle>
				<Localized id="student-management-students-title">Students</Localized>
			</WidgetTitle>
			<WidgetBody>
				<StudentSearch
					apiRef={props.apiRef}
					organisationName={props.organisationName}
					courseId={props.courseId}
					userId={props.userId}
					viewOnly={props.viewOnly}
				/>
			</WidgetBody>
		</Widget>
	);
}

const columnKeys = ["student", "enrolmentDate"] as const;

const columnDefs: ColumnDefs<
	typeof columnKeys[number],
	keyof CourseStudentSearchResult
> = {
	student: {
		name: (
			<Localized id="student-management-students-table-column-name">
				Student name
			</Localized>
		),
		sortOptions: [
			{
				field: "firstName",
				label: (
					<Localized id="student-management-students-table-column-name-sort-by-firstname">
						Sort by first name
					</Localized>
				),
			},
			{
				field: "lastName",
				label: (
					<Localized id="student-management-students-table-column-name-sort-by-lastname">
						Sort by last name
					</Localized>
				),
			},
			{
				field: "userName",
				label: (
					<Localized id="student-management-students-table-column-name-sort-by-username">
						Sort by username
					</Localized>
				),
			},
		],
	},
	enrolmentDate: {
		name: (
			<Localized id="student-management-students-table-column-enrolment-date">
				Enrolment date
			</Localized>
		),
		align: "right",
		width: "10rem",
	},
};

const columns = columnKeys.map((key) => columnDefs[key]);

const initialCriteria: CourseStudentSearchCriteria = {};

const emptyResult: CourseStudentSearchResult = {
	id: 0,
	userName: "",
	enrolmentDate: "2022-02-02",
	status: "active",
};

export type StudentsWidgetApi = {
	reloadPage: () => void;
};

function StudentSearch(props: {
	apiRef: React.ForwardedRef<StudentsWidgetApi>;
	organisationName: string;
	courseId: number;
	userId: number;
	viewOnly?: boolean;
}) {
	const {courseId, viewOnly} = props;

	const [criteria, setCriteria] = useState(initialCriteria);

	const [studentsPerPage, setStudentsPerPage] = useState(10);

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof CourseStudentSearchResult
	>("lastName");

	const {
		page: studentsPage,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
		reloadPage,
	} = usePaginationState<CourseStudentSearchResult>();

	const students = populate(
		studentsPerPage,
		pageFetchStatus === "succeeded" && studentsPage?.content
			? studentsPage.content
			: [],
		() => ({...emptyResult, id: Math.random()})
	);

	const {
		select: selectStudent,
		bulkSelectionCheckbox,
		selected: selectedStudents,
		resetSelection,
	} = useBulkSelection(studentsPage, (s) => s.id);

	const {l10n} = useLocalization();
	const dispatch = useAppDispatch();

	const mobileMode = useMobileMode("md");

	const [
		enrolInCourse,
		enrolInCourseDialog,
	] = useEnrolInAnotherCourseOfTeacherAction(
		props.organisationName,
		courseId,
		props.userId,
		mobileMode,
		(courseId) => {
			resetSelection();

			if (selectedStudents.includes(props.userId)) {
				dispatch(studentStatusChanged({courseId}));
			}
		}
	);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();
	const showSnackbar = useSnackbar();

	function resetAndReload() {
		resetSelection();
		reloadPage();
	}

	const suspend = useSuspendStudentsAction(
		courseId,
		openConfirmationDialog,
		() => {
			if (selectedStudents.includes(props.userId)) {
				dispatch(studentStatusChanged({courseId}));
			}

			resetAndReload();
		},
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	const unsuspend = useUnsuspendStudentsAction(
		courseId,
		openConfirmationDialog,
		() => {
			if (selectedStudents.includes(props.userId)) {
				dispatch(studentStatusChanged({courseId}));
			}

			resetAndReload();
		},
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	useImperativeHandle(
		props.apiRef,
		() => ({
			reloadPage: () => {
				reloadPage(resetSelection);
			},
		}),
		[reloadPage, resetSelection]
	);

	useEffect(() => {
		fetchFirstPage(
			() =>
				enrolmentService.searchCourseStudents(
					courseId,
					{
						...criteria,
						enrolledAfter: criteria.enrolledAfter
							? formatAtLocalTimeZone(parseDate(criteria.enrolledAfter))
							: undefined,
						enrolledBefore: criteria.enrolledBefore
							? formatAtLocalTimeZone(parseDate(criteria.enrolledBefore))
							: undefined,
					},
					{field: sortField, descending: sortOrder === SortOrder.Desc},

					studentsPerPage
				),
			resetSelection
		);
	}, [
		courseId,
		criteria,
		fetchFirstPage,
		resetSelection,
		sortField,
		sortOrder,
		studentsPerPage,
	]);

	function selectedStudent(id: number) {
		return selectedStudents.includes(id);
	}

	const loading = pageFetchStatus === "pending";

	const columnNumber = columns.length + (viewOnly ? 0 : 1);

	const noStudentsSelected = selectedStudents.length === 0;

	return (
		<Stack spacing={2} sx={{width: 1}}>
			<StudentSearchToolbar
				default={initialCriteria}
				criteria={criteria}
				onCriteriaChange={setCriteria}
				renderMenuActions={(close) => [
					noStudentsSelected && (
						<MenuItem key="hint" disabled>
							<em>
								<Localized id="student-management-students-bulk-actions-hint-select-students">
									Select students to perform actions
								</Localized>
							</em>
						</MenuItem>
					),
					noStudentsSelected && <ListDivider key="divider1" />,

					<MenuItem
						key="enrol"
						disabled={noStudentsSelected}
						onClick={() => {
							close();
							enrolInCourse(selectedStudents);
						}}
					>
						<Localized id="student-management-students-bulk-action-enrol">
							Enrol in another course
						</Localized>
					</MenuItem>,

					<ListDivider key="divider2" />,

					<MenuItem
						key="suspend"
						disabled={noStudentsSelected || viewOnly}
						onClick={() => {
							close();
							suspend(selectedStudents);
						}}
					>
						<Localized id="student-management-students-bulk-action-suspend">
							Suspend
						</Localized>
					</MenuItem>,

					<MenuItem
						key="unsuspend"
						disabled={noStudentsSelected || viewOnly}
						onClick={() => {
							close();
							unsuspend(selectedStudents);
						}}
					>
						<Localized id="student-management-students-bulk-action-unsuspend">
							Unsuspend
						</Localized>
					</MenuItem>,
				]}
			/>

			<Table stickyHeader>
				<TableHead
					columns={columns}
					leftAnnex={
						!props.viewOnly && (
							<TableCell padding="checkbox" sx={{zIndex: 3}}>
								{bulkSelectionCheckbox}
							</TableCell>
						)
					}
					rightAnnex={false && <TableCell padding="checkbox" />}
					sortOrder={sortOrder}
					sortField={sortField}
					loading={loading}
					onOrderChange={changeOrder}
				/>

				<TableBody sx={{position: "relative"}}>
					{pageFetchStatus === "failed" && (
						<LoadingErrorState
							description={
								<Localized id="student-management-students-loading-error-descr">
									Something has gone wrong, and we cannot load students
								</Localized>
							}
							columnNumber={columnNumber}
							spanWholeTable
							onReload={retryFetching}
						/>
					)}
					{pageFetchStatus === "succeeded" &&
						studentsPage.content.length === 0 && (
							<NoSearchResultsState
								columnNumber={columnNumber}
								title={
									<Localized id="student-management-students-no-students">
										No students
									</Localized>
								}
								description={
									criteria === initialCriteria ? (
										<Localized id="student-management-students-no-students-descr">
											There are no students in the course
										</Localized>
									) : (
										<Localized id="student-management-students-no-results-descr">
											No students were found matching your search criteria. Try
											to adjust filters
										</Localized>
									)
								}
								spanWholeTable
							/>
						)}
					{students.map((s) => {
						const selected = selectedStudent(s.id);

						return (
							<StudentRow
								key={s.id}
								student={s}
								loading={loading}
								selectionCellContent={
									<Checkbox
										checked={selected}
										inputProps={{
											"aria-label": l10n.getString(
												"student-management-students-select-label"
											),
										}}
										onClick={() => selectStudent(s.id)}
									/>
								}
								selected={selected}
							/>
						);
					})}
				</TableBody>
			</Table>
			<Stack
				direction="row"
				sx={{justifyContent: "space-between", alignItems: "center"}}
			>
				<Typography variant="subtitle2">
					{selectedStudents.length > 0 && (
						<Localized
							id="student-management-students-selected-row-number"
							vars={{selected: selectedStudents.length}}
						>{`${selectedStudents.length} selected`}</Localized>
					)}
				</Typography>
				<TablePagination
					onPageChange={fetchRelatedPage}
					pageSize={studentsPerPage}
					onPageSizeChange={setStudentsPerPage}
					first={Boolean(studentsPage.request.first)}
					last={Boolean(studentsPage.request.last)}
					next={Boolean(studentsPage.request.next)}
					previous={Boolean(studentsPage.request.previous)}
					label={
						<Localized id="student-management-students-per-page">
							Students per page
						</Localized>
					}
					disabled={pageFetchStatus !== "succeeded"}
				/>
			</Stack>

			{confirmationDialog}
			{enrolInCourseDialog}
		</Stack>
	);
}

function StudentRow(props: {
	student: CourseStudentSearchResult;
	selected: boolean;
	loading?: boolean;
	selectionCellContent?: React.ReactNode;
	actionIcon?: React.ReactNode;
	onAction?: () => void;
}) {
	const {student: s} = props;

	const formatDate = useDateTimeFormat();

	return (
		<TableRow
			hover
			tabIndex={-1}
			selected={props.selected}
			sx={[
				!s.userName &&
					!props.loading && {
						visibility: "hidden",
						"& > td": {
							borderBottom: "1px solid transparent",
						},
					},
			]}
		>
			{props.selectionCellContent && (
				<TableCell padding="checkbox">{props.selectionCellContent}</TableCell>
			)}
			<TableCell>
				<Stack
					direction="row"
					sx={{justifyContent: "space-between", alignItems: "center"}}
				>
					<StudentName student={s} loading={props.loading} />
					{s.status === "suspended" && (
						<Chip
							label={
								<Localized id="student-management-students-student-status-suspended">
									Suspended
								</Localized>
							}
							size="small"
							variant="outlined"
						/>
					)}
				</Stack>
			</TableCell>
			<TableCell
				align={columnDefs.enrolmentDate.align}
				sx={{width: columnDefs.enrolmentDate.width}}
			>
				{props.loading && (
					<Box sx={{display: "flex", justifyContent: "flex-end"}}>
						<Skeleton width="50%" />
					</Box>
				)}
				{!props.loading && formatDate(parseDate(s.enrolmentDate))}
			</TableCell>
			{false && (
				<TableCell padding="checkbox">
					{!props.loading && (
						<IconButton onClick={props.onAction}>{props.actionIcon}</IconButton>
					)}
				</TableCell>
			)}
		</TableRow>
	);
}

export default StudentsWidget;
