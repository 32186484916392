import type {ReactLocalization} from "@fluent/react";

import type ComparisonStrictness from "../../../../store/exercises/ComparisonStrictness";

export function describeStrictness(
	l10n: ReactLocalization,
	strictness: ComparisonStrictness
): string {
	switch (strictness) {
		case "strict":
			return l10n.getString(
				"exercise-prog-strictness-strict",
				null,
				"The output of the program must be exactly the same as the example output (the most strict comparison level)"
			);
		case "ignore_space":
			return l10n.getString(
				"exercise-prog-strictness-ignore-space",
				null,
				'The verification of program output does not account for whitespace characters like "\\n", "\\t" and " "'
			);
		case "ignore_case":
			return l10n.getString(
				"exercise-prog-strictness-ignore-case",
				null,
				"The verification of program output is not case-sensitive"
			);
		case "ignore_space_and_case":
			return l10n.getString(
				"exercise-prog-strictness-ignore-space-case",
				null,
				"The verification of program output does not account for whitespace and is not case-sensitive (the least strict comparison level)"
			);
		default:
			return "";
	}
}

export function describeError(
	l10n: ReactLocalization,
	error: "compile" | "output" | "no_tests" | "runtime"
): string {
	switch (error) {
		case "compile":
			return l10n.getString(
				"exercise-prog-response-area-error-compilation-failed",
				null,
				"Error: compilation of your program has failed"
			);
		case "runtime":
		// fall through
		case "output":
			return l10n.getString(
				"exercise-prog-response-area-error-logic-failed",
				null,
				"Error: your program does not operate correctly"
			);
		case "no_tests":
			return l10n.getString(
				"exercise-prog-response-area-error-no-tests",
				null,
				"Error: no tests have been defined for this exercise"
			);
		default:
			return l10n.getString(
				"exercise-prog-response-area-error-general",
				null,
				"An error has occurred"
			);
	}
}
