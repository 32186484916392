import React, {ReactNode} from "react";

export const MenuLink = React.forwardRef(
	(props: {href?: string; children: ReactNode}, ref): JSX.Element => {
		ref;
		return (
			<a
				href={props.href}
				target="_blank"
				style={{textDecoration: "none", color: "inherit"}}
				rel="noreferrer"
			>
				{props.children}
			</a>
		);
	}
);

MenuLink.displayName = "MenuLink";

export default MenuLink;
