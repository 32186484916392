import {useCallback} from "react";

import type CourseStudyModule from "./CourseStudyModule";
import type {CourseStudyModuleName} from "./CourseStudyModule";
import type FetchStatus from "../FetchStatus";
import {useAppSelector} from "../hooks";
import {keyProvider} from "../keyProvider";
import selectCourseStudyModules, {
	selectCourseStudyModulesStatus,
} from "./selectCourseStudyModules";

function useAvailableStudyModules(
	courseId: number
): [
	CourseStudyModule[],
	(module: CourseStudyModuleName) => boolean,
	FetchStatus,
	boolean
] {
	const key = keyProvider.course(courseId);

	const modules = useAppSelector((state) =>
		selectCourseStudyModules(state, key)
	);

	const fetchStatus = useAppSelector((state) =>
		selectCourseStudyModulesStatus(state, key)
	);

	const available = useCallback(
		(module: CourseStudyModuleName) =>
			Boolean(modules.find((m) => m.name === module)),
		[modules]
	);

	const awaiting = fetchStatus === "none" || fetchStatus === "pending";

	return [modules, available, fetchStatus, awaiting];
}

export default useAvailableStudyModules;
