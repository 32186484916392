import {createAsyncThunk} from "@reduxjs/toolkit";
import selectChapterSession from "../chapterSessions/selectChapterSession";
import {keyProvider} from "../keyProvider";
import {ExerciseOutcomes} from "../outcomes/ExerciseOutcomes";
import chapterSessionsService from "../services/chapterSessionsService";
import {RootState} from "../store";
import selectChapterSessionKeys from "./selectChapterSessionKeys";

export const fetchChapterSessionOutcomes = createAsyncThunk<
	{outcomes: {[key: string]: ExerciseOutcomes}; sessionKey: string},
	{
		studentId: number;
		courseId: number;
		chapterId: number;
		sessionKey?: string;
	},
	{state: RootState}
>(
	"chapterSessionOutcomes/fetchOutcomes",
	async ({studentId, courseId, chapterId, sessionKey}, thunkApi) => {
		const state = thunkApi.getState();

		let key = sessionKey;
		if (!key) {
			const keys = selectChapterSessionKeys(
				state,
				keyProvider.chapter(chapterId)
			)[0];
			key = keys.length > 0 ? keys[0] : "";
		}
		const session = selectChapterSession(state, key);
		if (!session) {
			throw new Error(`Chapter session ${sessionKey} is not found`);
		}

		const outcomes = await chapterSessionsService.getChapterSessionOutcomes(
			studentId,
			courseId,
			chapterId,
			session.startTime
		);

		const byKey: {[key: string]: ExerciseOutcomes} = {};
		outcomes.forEach((outcome) => {
			const key = keyProvider.chapterExercise(chapterId, outcome.exerciseId);
			byKey[key] = {
				completionStatus: outcome.completionStatus,
				score: outcome.score,
			};
			if (outcome.assessorComment) {
				byKey[key].assessorComment = outcome.assessorComment;
			}
		});

		return {
			outcomes: byKey,
			sessionKey: !sessionKey
				? keyProvider.chapterSession(chapterId, session.startTime)
				: sessionKey,
		};
	}
);
