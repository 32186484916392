import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	TextField,
} from "@mui/material";
import React, {useState} from "react";

import {organisationService} from "../../store/services/organisationService";
import CountrySelector from "../../utils/CountrySelector";
import type DialogResult from "../../utils/DialogResult";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

const orgNameExp = /^[a-z][a-z0-9-]{0,61}[a-z0-9]$/;

function NewGroupOrganisationDialog(props: {
	groupName: string;
	titleElementId: string;
	mobileMode?: boolean;
	onClose: (result: DialogResult) => void;
}) {
	const [inProgress, setInProgress] = useState(false);

	const [draft, setDraft] = useState({
		name: "",
		displayName: "",
		countryCode: "",
		city: "",
	});

	const [empty, setEmpty] = useState<{
		name?: boolean;
		displayName?: boolean;
		countryCode?: boolean;
	}>({});

	const [nameErrorMessage, setNameErrorMessage] = useState("");
	const [commonErrorMessage, setCommonErrorMessage] = useState("");

	const {l10n} = useLocalization();

	function validate() {
		const validName = orgNameExp.test(draft.name);
		if (!validName) {
			setNameErrorMessage(
				l10n.getString(
					"group-organisations-new-organisation-dialog-name-invalid"
				)
			);
		}

		const empty = {
			displayName: draft.displayName === "",
			name: draft.name === "",
			countryCode: draft.countryCode === "",
		};

		setEmpty(empty);

		if (Object.values(empty).some((v) => v) || !validName) {
			return false;
		}

		return true;
	}

	async function submit() {
		setCommonErrorMessage("");

		if (!validate()) {
			return;
		}

		setInProgress(true);

		try {
			await organisationService.createGroupOrganisation(props.groupName, draft);

			props.onClose({status: "success"});
		} catch (err) {
			switch ((err as {code: string}).code) {
				case "validation_failed":
					setNameErrorMessage(
						l10n.getString(
							"group-organisations-new-organisation-dialog-error-forbidden-name",
							null,
							"The name is forbidden"
						)
					);

					break;
				case "name_already_used":
					setNameErrorMessage(
						l10n.getString(
							"group-organisations-new-organisation-dialog-error-name-in-use",
							null,
							"The name is already in use"
						)
					);
					break;

				default:
					setCommonErrorMessage(l10n.getString("error-general"));
			}
		}

		setInProgress(false);
	}

	function close() {
		props.onClose({status: "cancelled"});
	}

	return (
		<form>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="group-organisations-new-organisation-dialog-title">
							New organisation
						</Localized>
					}
					actionButtonLabel={
						<Localized id="group-organisations-new-organisation-dialog-action-create">
							Create
						</Localized>
					}
					actionInProgress={inProgress}
					onAction={submit}
					onClose={close}
				/>
			) : (
				<WindowedDialogTitle
					onClose={close}
					id={props.titleElementId}
					title={
						<Localized id="group-organisations-new-organisation-dialog-title">
							New organisation
						</Localized>
					}
				/>
			)}

			<DialogContent dividers>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							id="name"
							fullWidth
							required
							value={draft.name}
							label={
								<Localized id="group-organisations-new-organisation-dialog-label-name">
									Name
								</Localized>
							}
							error={nameErrorMessage !== "" || empty.name}
							helperText={!empty.name && nameErrorMessage}
							onChange={({target}) => {
								setDraft((prev) => ({
									...prev,
									name: target.value.toLowerCase(),
								}));
								setEmpty((prev) => ({...prev, name: false}));
								setNameErrorMessage("");
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="displayName"
							fullWidth
							required
							value={draft.displayName}
							label={
								<Localized id="group-organisations-new-organisation-dialog-label-display-name">
									Display name
								</Localized>
							}
							error={empty.displayName}
							onChange={({target}) => {
								setDraft((prev) => ({...prev, displayName: target.value}));
								setEmpty((prev) => ({...prev, displayName: false}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<CountrySelector
							value={draft.countryCode}
							required
							error={empty.countryCode}
							onChange={(val) => {
								setDraft((prev) => ({...prev, countryCode: val}));
								setEmpty((prev) => ({...prev, countryCode: false}));
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="city"
							fullWidth
							value={draft.city}
							label={
								<Localized id="group-organisations-new-organisation-dialog-label-city">
									City
								</Localized>
							}
							onChange={({target}) => {
								setDraft((prev) => ({...prev, city: target.value}));
							}}
						/>
					</Grid>
					{commonErrorMessage && (
						<Grid item xs={12}>
							<DialogContentText color="error" style={{marginBottom: 0}}>
								{commonErrorMessage}
							</DialogContentText>
						</Grid>
					)}
				</Grid>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button onClick={close} color="primary" disabled={inProgress}>
						<Localized id="group-organisations-new-organisation-dialog-action-cancel">
							Cancel
						</Localized>
					</Button>
					<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
						<Localized id="group-organisations-new-organisation-dialog-action-create">
							Create
						</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</form>
	);
}

export default NewGroupOrganisationDialog;
