import {createSlice} from "@reduxjs/toolkit";

import fetchUserOrganisations from "./fetchUserOrganisations";
import type FetchStatus from "../FetchStatus";
import leaveOrganisation from "./leaveOrganisation";

type State = {
	organisations: string[];
	status: FetchStatus;
};

const initialState: State = {
	organisations: [],
	status: "none",
};

export const userOrganisationsSlice = createSlice({
	name: "userOrganisations",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUserOrganisations.fulfilled, (state, {payload}) => {
			state.organisations = payload.map((o) => o.name);
			state.status = "succeeded";
		});
		builder.addCase(fetchUserOrganisations.pending, (state) => {
			state.status = "pending";
		});
		builder.addCase(fetchUserOrganisations.rejected, (state) => {
			state.status = "failed";
		});

		builder.addCase(leaveOrganisation.fulfilled, (state, {payload}) => {
			state.organisations = state.organisations.filter(
				(o) => o !== payload.organisationName
			);
		});
	},
});

export default userOrganisationsSlice.reducer;
