import {Localized, useLocalization} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
	Autocomplete,
	Button,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import {validEmail, validUserName} from "../../helpers/validationHelpers";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import Loading from "./Loading";
import PageLayout from "./PageLayout";
import type FetchStatus from "../../store/FetchStatus";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type UserCourse from "../../store/models/UserCourse";
import useFetchOrganisation from "../../store/organisation/useFetchOrganisation";
import fetchCoursesAvailableForEnrolment from "../../store/selfEnrolment/fetchCoursesAvailableForEnrolment";
import selectCoursesAvailableForEnrolment, {
	selectCoursesAvailableForEnrolmentFetchStatus,
} from "../../store/selfEnrolment/selectCoursesAvailableForEnrolment";
import selfEnrolmentService from "../../store/services/selfEnrolmentService";
import useSnackbar from "../../store/ui/useSnackbar";
import OrganisationNotFoundError from "../../utils/errors/OrganisationNotFoundError";

const SignUp = (props: {
	logo: string;
	organisationName: string;
}): JSX.Element => {
	const {logo, organisationName} = props;

	const theme = useTheme();

	const [selectedCourse, setSelectedCourse] = useState<UserCourse | null>(null);
	const [username, setUsername] = useState("");

	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [emailAddress, setEmailAddress] = useState("");

	const [emptyField, setEmptyField] = useState(() => ({
		course: false,
		username: false,
		password: false,
		repeatPassword: false,
		firstName: false,
		lastName: false,
		emailAddress: false,
	}));

	const [passwordsMismatch, setPasswordsMismatch] = useState(false);

	const [errorMessages, setErrorMessages] = useState<{
		emailAddress?: string;
		password?: string;
		username?: string;
	}>(() => ({}));

	const [status, setStatus] = useState<FetchStatus>("none");

	const [org, orgFetchStatus] = useFetchOrganisation(organisationName);

	const courses = useAppSelector(selectCoursesAvailableForEnrolment);
	const coursesFetchStatus = useAppSelector(
		selectCoursesAvailableForEnrolmentFetchStatus
	);

	const language = useCurrentLocale();
	const {l10n} = useLocalization();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (coursesFetchStatus === "none") {
			dispatch(fetchCoursesAvailableForEnrolment({orgName: organisationName}));
		}
	}, [coursesFetchStatus, dispatch, organisationName]);

	const showSnackbar = useSnackbar();

	const validate = () => {
		const empty: typeof emptyField = {
			course: !selectedCourse,
			emailAddress: emailAddress === "",
			firstName: firstName === "",
			lastName: lastName === "",
			password: password === "",
			repeatPassword: repeatPassword === "",
			username: username === "",
		};

		setEmptyField(empty);

		setPasswordsMismatch(password !== repeatPassword);

		const errors: typeof errorMessages = {};

		if (
			username.length < 3 ||
			username.length > 40 ||
			!validUserName(username)
		) {
			errors.username = l10n.getString("signup-username-requirements");
		}

		if (emailAddress.length > 0 && !validEmail(emailAddress)) {
			errors.emailAddress = l10n.getString("signup-error-invalid-email");
		}

		if (password.length < 8) {
			errors.password = l10n.getString("signup-error-password-min-length");
		} else if (password.length > 128) {
			errors.password = l10n.getString("signup-error-password-max-length");
		}

		setErrorMessages(errors);

		return !(
			Object.values(empty).some((e) => e) ||
			Object.values(errors).some((e) => e) ||
			password !== repeatPassword
		);
	};

	const createAccount = async () => {
		const valid = validate();
		if (!valid || !selectedCourse) {
			return;
		}

		try {
			setStatus("pending");

			await selfEnrolmentService.submitEnrolmentApplicationForNewUser(
				organisationName,
				selectedCourse.id,
				{
					username,
					emailAddress,
					password,
					firstName,
					lastName,
					language,
				}
			);

			setStatus("succeeded");
		} catch (error) {
			const err = error as {code: string; field: string};
			if (err.code === "duplicate_id") {
				if (err.field === "emailAddress") {
					setErrorMessages((prev) => ({
						...prev,
						emailAddress: l10n.getString("signup-error-duplicate-email"),
					}));
				} else if (err.field === "name") {
					setErrorMessages((prev) => ({
						...prev,
						username: l10n.getString("signup-error-duplicate-username"),
					}));
				}

				setStatus("none");
			} else {
				setStatus("failed");
				showSnackbar("error", l10n.getString("error-general"));
			}
		}
	};

	if (
		orgFetchStatus === "none" ||
		orgFetchStatus === "pending" ||
		coursesFetchStatus === "none" ||
		coursesFetchStatus === "pending"
	) {
		return (
			<PageLayout logo={logo}>
				<Loading />
			</PageLayout>
		);
	}

	if (coursesFetchStatus === "failed") {
		return (
			<PageLayout logo={logo} backRef="/login">
				<CourseLoadingError
					onReload={() => {
						dispatch(
							fetchCoursesAvailableForEnrolment({orgName: organisationName})
						);
					}}
				/>
			</PageLayout>
		);
	}

	if (orgFetchStatus === "succeeded" && !org) {
		return (
			<PageLayout logo={logo}>
				<OrganisationNotFoundError />
			</PageLayout>
		);
	}

	if (coursesFetchStatus === "succeeded" && courses.length === 0) {
		return (
			<PageLayout logo={logo} backRef="/login">
				<EmptyState />
			</PageLayout>
		);
	}

	if (status === "succeeded") {
		return (
			<PageLayout logo={logo} backRef="/login">
				<Success />
			</PageLayout>
		);
	}

	return (
		<PageLayout logo={logo} backRef="/login">
			<Grid
				container
				direction="column"
				spacing={2}
				style={{padding: theme.spacing(0, 10)}}
			>
				<Grid item>
					<Typography variant="body2">{org?.displayName}</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4">
						<Localized id="signup-new-account">New account</Localized>
					</Typography>
				</Grid>
				<Grid item>
					<Autocomplete
						options={courses}
						getOptionLabel={(opt) => opt.name}
						renderInput={(params) => (
							<TextField
								{...params}
								label={
									<Localized id="signup-select-course-label">Course</Localized>
								}
								helperText={l10n.getString(
									"signup-select-course-helper-text",
									null,
									"A course in which you want to enrol"
								)}
								required
								error={emptyField.course}
							/>
						)}
						value={selectedCourse}
						onChange={(_, val) => {
							setSelectedCourse(val);
							setEmptyField((prev) => ({...prev, course: false}));
						}}
						fullWidth
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="login-username">Username</Localized>}
						required
						value={username}
						onChange={({target}) => {
							setUsername(target.value);
							setEmptyField((prev) => ({...prev, username: false}));
							setErrorMessages((prev) => ({...prev, username: ""}));
						}}
						fullWidth
						error={emptyField.username || Boolean(errorMessages.username)}
						helperText={
							errorMessages.username || (
								<Localized id="signup-username-requirements">
									Username should start with a letter and may only contain
									digits, lowercase Latin letters and non-repeated symbols .-_
								</Localized>
							)
						}
						autoComplete="username"
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="login-password">Password</Localized>}
						required
						value={password}
						onChange={({target}) => {
							setPassword(target.value);
							setEmptyField((prev) => ({...prev, password: false}));
							setErrorMessages((prev) => ({...prev, password: ""}));
						}}
						type={showPassword ? "text" : "password"}
						autoComplete="new-password"
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword((prev) => !prev)}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={emptyField.password || Boolean(errorMessages.password)}
						helperText={!emptyField.password && errorMessages.password}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={
							<Localized id="signup-repeat-password-label">
								Repeat password
							</Localized>
						}
						required
						value={repeatPassword}
						onChange={({target}) => {
							setRepeatPassword(target.value);
							setEmptyField((prev) => ({...prev, repeatPassword: false}));
							setPasswordsMismatch(false);
						}}
						type={showPassword ? "text" : "password"}
						autoComplete="new-password"
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword((prev) => !prev)}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={emptyField.repeatPassword || passwordsMismatch}
						helperText={
							passwordsMismatch && (
								<Localized id="signup-error-password-mismatch">
									Passwords mismatch
								</Localized>
							)
						}
					/>
				</Grid>
				<Grid item style={{marginTop: theme.spacing(6)}}>
					<TextField
						label={
							<Localized id="signup-first-name-label">First name</Localized>
						}
						required
						value={firstName}
						onChange={({target}) => {
							setFirstName(target.value);
							setEmptyField((prev) => ({...prev, firstName: false}));
						}}
						fullWidth
						error={emptyField.firstName}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="signup-last-name-label">Last name</Localized>}
						required
						value={lastName}
						onChange={({target}) => {
							setLastName(target.value);
							setEmptyField((prev) => ({...prev, lastName: false}));
						}}
						fullWidth
						error={emptyField.lastName}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={<Localized id="signup-last-email-label">Email</Localized>}
						required
						value={emailAddress}
						onChange={({target}) => {
							setEmailAddress(target.value);
							setEmptyField((prev) => ({...prev, emailAddress: false}));
							setErrorMessages((prev) => ({...prev, emailAddress: ""}));
						}}
						fullWidth
						error={
							emptyField.emailAddress || Boolean(errorMessages.emailAddress)
						}
						helperText={
							errorMessages.emailAddress && errorMessages.emailAddress
						}
					/>
				</Grid>
				<Grid item style={{marginTop: theme.spacing(8)}}>
					<Button
						variant="contained"
						color="primary"
						onClick={createAccount}
						fullWidth
						disabled={status === "pending"}
					>
						<Localized id="signup-create-btn">Create account</Localized>
					</Button>
				</Grid>
			</Grid>
		</PageLayout>
	);
};

function EmptyState() {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<EventBusyIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="signup-no-courses">
					No courses available for enrolment
				</Localized>
			</Typography>
		</Container>
	);
}

function Success() {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DoneIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="signup-success-message">
					You have successfully created an account.
				</Localized>
			</Typography>
			<Typography>
				<Localized
					id="signup-login-link-message"
					elems={{
						ref: <Link to="/login" component={RouterLink}></Link>,
					}}
				>
					<> {`Now you can log in <ref>here</ref>`}</>
				</Localized>
			</Typography>
		</Container>
	);
}

function CourseLoadingError(props: {onReload: () => void}) {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<ErrorIcon
				style={{height: "50px", width: "50px", color: theme.palette.error.main}}
			/>
			<Typography variant="h6">
				<Localized id="signup-courses-loading-error-title">
					An error has occured
				</Localized>
			</Typography>
			<Typography>
				<Localized id="signup-courses-loading-error-description">
					Failed to load courses available for enrolment
				</Localized>
			</Typography>
			<Button
				color="primary"
				variant="contained"
				style={{marginTop: theme.spacing(2)}}
				onClick={props.onReload}
			>
				<Localized id="signup-courses-loading-error-reload-btn">
					Reload
				</Localized>
			</Button>
		</Container>
	);
}

export default SignUp;
