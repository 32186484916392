import {createSelector} from "@reduxjs/toolkit";

import selectCourseChapterKeys from "../chapters/selectCourseChapterKeys";
import type {RootState} from "../store";

const selectAnyChapterEditing = createSelector(
	(state: RootState) => state.ui.chapterEditing.byKey,
	selectCourseChapterKeys,
	(editing, keys) => {
		return keys.some((key) => editing[key]?.editing);
	}
);

export default selectAnyChapterEditing;
