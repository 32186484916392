import React, {createContext, useEffect, useReducer, useRef} from "react";
import type {Dispatch, ReactNode} from "react";
import {useLocation} from "react-router";

type Destination = {path: string};

type Action =
	| {
			type: "add";
			payload: {path?: string; fallback?: string};
	  }
	| {
			type: "remove";
	  };

const BackNavContext = createContext<Readonly<Destination[]>>([]);

const BackNavManagementContext = createContext<Dispatch<Action>>(() => {
	return;
});

function BackNavProvider(props: {children: ReactNode}): JSX.Element {
	const location = useLocation<{backButtonClicked?: boolean}>();

	const currentLocation = useRef<{path: string; from?: string}>();

	const [destinations, dispatch] = useReducer(
		(state: Destination[], action: Action) => {
			switch (action.type) {
				case "add": {
					let path = action.payload.path;

					if (!location.state?.backButtonClicked) {
						path = path ?? currentLocation.current?.from;
					}

					path = path ?? action.payload.fallback ?? "/";

					return state.concat({path});
				}
				case "remove":
					return state.slice(0, -1);
				default:
					return state;
			}
		},
		[{path: "/"}]
	);

	useEffect(() => {
		currentLocation.current = {
			path: location.pathname + location.search + location.hash,
			from: currentLocation.current?.path,
		};
	}, [location]);

	return (
		<BackNavManagementContext.Provider value={dispatch}>
			<BackNavContext.Provider value={destinations}>
				{props.children}
			</BackNavContext.Provider>
		</BackNavManagementContext.Provider>
	);
}

export default BackNavProvider;
export {BackNavContext, BackNavManagementContext};
