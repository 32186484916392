import parseDate from "../helpers/parseDate";

type TimeLeft = {
	total: number;
	hours: number;
	minutes: number;
	seconds: number;
};

const calculateTimeLeft = (deadline: string): TimeLeft => {
	const total = Number(parseDate(deadline)) - Date.now();
	const left = {total, hours: 0, minutes: 0, seconds: 0};

	if (total > 0) {
		left.hours = Math.floor(total / (1000 * 60 * 60));
		left.minutes = Math.floor((total / 1000 / 60) % 60);
		left.seconds = Math.floor((total / 1000) % 60);
	}

	return left;
};

export default TimeLeft;
export {calculateTimeLeft};
