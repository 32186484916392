import {createAsyncThunk, unwrapResult} from "@reduxjs/toolkit";

import makeSelectChapterStructure from "../chapters/makeSelectChapterStructure";
import updateChapterStructure from "../chapters/updateChapterStructure";
import {keyProvider} from "../keyProvider";
import {AppDispatch, RootState} from "../store";

const selectChapterStructure = makeSelectChapterStructure();

const removeExercise = createAsyncThunk<
	{removedExerciseKey: string},
	{courseId: number; chapterExerciseKey: string},
	{dispatch: AppDispatch; state: RootState}
>("chapters/removeExercise", async function (params, thunkApi) {
	const courseId = params.courseId;
	const exerciseKey = params.chapterExerciseKey;

	const state = thunkApi.getState();

	const exercise = state.chapterExercises.byKey[exerciseKey];
	if (!exercise) {
		throw new Error(`Exercise ${exerciseKey} is not found`);
	}

	const chapterId = exercise.chapterId;
	const chapterKey = keyProvider.chapter(chapterId);

	const sectionId = exercise.sectionId;
	const sectionKey = keyProvider.section(chapterId, sectionId);

	const structure = [...selectChapterStructure(state, chapterKey)];

	const i = structure.findIndex((s) => s.sectionKey === sectionKey);
	if (i < 0) {
		throw new Error(`Section ${sectionKey} is not found`);
	}

	structure[i] = {
		...structure[i],
		subsectionKeys: structure[i].subsectionKeys.filter(
			(s) => s !== exerciseKey
		),
	};

	const result = await thunkApi.dispatch(
		updateChapterStructure({courseId, chapterId, structure})
	);
	unwrapResult(result);

	return {
		removedExerciseKey: exerciseKey,
	};
});

export default removeExercise;
