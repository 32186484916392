import type {RootState} from "../store";
import WidgetSettings from "./WidgetSettings";

function selectWidgetSettings<TSettings extends WidgetSettings>(
	state: RootState,
	widgetSettingsKey: string
): TSettings | null {
	return (
		(state.widgets.courseWidgetSettings.byKey[
			widgetSettingsKey
		] as TSettings) ?? null
	);
}

export default selectWidgetSettings;
