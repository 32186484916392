import React from "react";

import "./index.css";

function HtmlContent(props: {content: string}): JSX.Element {
	return (
		<div
			className="html-content"
			dangerouslySetInnerHTML={{__html: props.content}}
		/>
	);
}

export default HtmlContent;
