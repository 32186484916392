export const scrollToElement = (id: string): void => {
	document.getElementById(id)?.scrollIntoView({
		behavior: "smooth",
	});
};

export const scrollToTop = (): void => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};

export function scrollIntoView(elem: HTMLElement | null): void {
	elem?.scrollIntoView({behavior: "smooth", block: "nearest"});
}

export function pushElementToViewport(
	elemId: string,
	viewportSizeCorrection?: {bottom?: number}
): void {
	const elem = document.getElementById(elemId);
	if (!elem) {
		return;
	}

	const inViewport = isElementInViewport(elem, viewportSizeCorrection);

	if (inViewport) {
		return;
	}

	elem.scrollIntoView({
		behavior: "smooth",
	});
}

function isElementInViewport(
	elem: HTMLElement,
	viewportSizeCorrection?: {bottom?: number}
) {
	const rect = elem.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) +
				(viewportSizeCorrection?.bottom ?? 0) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}
