import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";
import type DashboardName from "./DashboardName";
import type {FollowUpWidgetKey, MyCornerWidgetKey} from "./WidgetKey";

type ArrayType<T extends DashboardName> = T extends "follow-up"
	? FollowUpWidgetKey[]
	: T extends "my_corner"
	? MyCornerWidgetKey[]
	: never;

const empty: FollowUpWidgetKey[] | MyCornerWidgetKey[] = [];

function selectWidgetKeys<T extends DashboardName>(
	state: RootState,
	dashboardName: T,
	courseKey: string
): ArrayType<T> {
	return (state.widgets.courseWidgetKeys[dashboardName].byKey[courseKey]
		?.keys ?? empty) as ArrayType<T>;
}

function selectWidgetFetchStatus(
	state: RootState,
	dashboardName: DashboardName,
	courseKey: string
): FetchStatus {
	return (
		state.widgets.courseWidgetKeys[dashboardName].byKey[courseKey]?.status ??
		"none"
	);
}

export default selectWidgetKeys;
export {selectWidgetFetchStatus};
