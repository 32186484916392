import React from "react";
import {ExerciseFromBank} from "../../store/services/dtos/ExerciseFromBank";
import SortOrder from "../../utils/tables/SortOrder";

const TableContext = React.createContext<{
	top: number;

	order: SortOrder;
	orderBy: keyof ExerciseFromBank;
	onOrderChange: (orderBy: keyof ExerciseFromBank, order: SortOrder) => void;
}>({
	top: 0,

	order: SortOrder.Asc,
	orderBy: "title",
	onOrderChange: () => {
		return;
	},
});

export default TableContext;
