import React, {useState} from "react";

import AssignToOrganisationCoursesDialog from "./AssignToOrganisationCoursesDialog";

function useAssignToOrganisationCoursesAction(
	organisationName: string,
	mobileMode: boolean,
	onDone?: (courseIds: number[]) => void
): [(userIds: number[]) => void, JSX.Element] {
	const [userIds, setUserIds] = useState<number[]>([]);

	return [
		setUserIds,
		<AssignToOrganisationCoursesDialog
			key="assign-to-organisation-courses"
			open={userIds.length > 0}
			mobileMode={mobileMode}
			organisationName={organisationName}
			userIds={userIds}
			onCancel={() => {
				setUserIds([]);
			}}
			onDone={(ids) => {
				setUserIds([]);
				onDone?.(ids);
			}}
		/>,
	];
}

export default useAssignToOrganisationCoursesAction;
