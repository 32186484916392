import {Localized} from "@fluent/react";
import {Box, Tab} from "@mui/material";
import React, {useState} from "react";
import {Link, Switch} from "react-router-dom";

import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import SubtitleNav from "../nav/SubtitleNav";
import ObservableRoute from "../../utils/ObservableRoute";
import Organisations from "./Organisations";
import Profile from "./Profile";

function Account(props: {organisationName?: string}): JSX.Element {
	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	const createSubroute = useSubrouteCreator();

	const profilePage = createSubroute("");
	const orgsPage = createSubroute("/organisations");

	return (
		<>
			<SubtitleNav
				title={<Localized id="account">Account</Localized>}
				selectedTab={selectedTab}
			>
				<Tab
					label={<Localized id="account-tab-profile">Profile</Localized>}
					component={Link}
					value={profilePage.link}
					to={profilePage.link}
				/>
				<Tab
					label={<Localized id="account-tab-orgs">Organisations</Localized>}
					component={Link}
					value={orgsPage.link}
					to={orgsPage.link}
				/>
			</SubtitleNav>

			<Box maxWidth={2048} p={6}>
				<Switch>
					<ObservableRoute
						path={orgsPage.route}
						onRender={() => setSelectedTab(orgsPage.link)}
					>
						<Organisations currentOrganisationName={props.organisationName} />
					</ObservableRoute>
					<ObservableRoute
						path={profilePage.route}
						onRender={() => setSelectedTab(profilePage.link)}
					>
						<Profile />
					</ObservableRoute>
				</Switch>
			</Box>
		</>
	);
}

export default Account;
