import {Localized, useLocalization} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SettingsIcon from "@mui/icons-material/Settings";
import {
	Box,
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";

import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetBodyOverlay from "../analytics/widget/WidgetBodyOverlay";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import {AssessmentPageDialogContextProvider} from "./AssessmentPageDialogContext";
import {LinkToPage, Page} from "../../helpers/paginatedSearchHelpers";
import ResponseAssessmentDialog from "./ResponseAssessmentDialog";
import selectCourseStats from "../../store/courseStatistics/selectCourseStats";
import selectCourseStatsFetchStatus from "../../store/courseStatistics/selectCourseStatsFetchStatus";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type {
	ProgressByChapter,
	StudentProgressByType,
	StudentResultsInCourse,
	StudentResultsSearchCriteria,
} from "../../store/services/analyticsService";
import {analyticsService} from "../../store/services/analyticsService";
import useSnackbar from "../../store/ui/useSnackbar";
import selectWidgetSettings from "../../store/widgets/selectWidgetSettings";
import type {StudentResultsWidgetSettings as WidgetSettings} from "../../store/widgets/WidgetSettings";
import updateWidgetSettings from "../../store/widgets/updateWidgetSettings";
import StudentProgressInChapters from "./StudentProgressInChapters";
import StudentResultsActionToolbar from "./StudentResultsActionToolbar";
import {
	studentResultsColumnDefs,
	studentResultsColumnKeys,
} from "./studentResultsColumns";
import StudentResultsRow from "./StudentResultsRow";
import StudentResultsTableToolbar from "./StudentResultsTableToolbar";
import StudentResultsWidgetSettings from "./StudentResultsWidgetSettings";
import StudentSearchEmptyState from "./StudentSearchEmptyState";
import {populate} from "../../utils/populate";
import TableHead from "../../utils/tables/Head";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import useSorting from "../../utils/tables/useSorting";

const columns = studentResultsColumnKeys.map(
	(key) => studentResultsColumnDefs[key]
);

const emptyResults: StudentResultsInCourse = {
	id: 0,
	userName: "",
	firstName: "",
	lastName: "",
	numberOfCompletedExercises: 0,
	score: 0,
	assessmentStatus: "not_assessed",
};

const StudentResultsWidget = (props: {
	id: string;
	title: React.ReactNode;
	courseId: number;
	userId: number;
	viewOnly?: boolean;
}): JSX.Element => {
	const [
		selectedStudent,
		setSelectedStudent,
	] = useState<StudentResultsInCourse | null>(null);

	const [selectedStudentProgress, setSelectedStudentProgress] = useState<
		ProgressByChapter[]
	>([]);

	const [settingsOpen, setSettingsOpen] = useState(false);

	const widgetSettings = useAppSelector((state) =>
		selectWidgetSettings<WidgetSettings>(
			state,
			keyProvider.widgetSettings(props.courseId, "student_results")
		)
	);

	const settings = useRef(widgetSettings);

	const dispatch = useAppDispatch();

	const updateSettings = useCallback(
		(val: WidgetSettings) => {
			dispatch(
				updateWidgetSettings({
					userId: props.userId,
					courseId: props.courseId,
					dashboardName: "follow-up",
					widgetKey: "student_results",
					settings: val,
				})
			);
		},
		[dispatch, props.courseId, props.userId]
	);

	return (
		<Widget id={props.id}>
			<WidgetTitle
				actions={
					<Box mt={-1.5} mr={-1.5}>
						<IconButton
							onClick={() => {
								settings.current = widgetSettings;
								setSettingsOpen((prev) => !prev);
							}}
						>
							{settingsOpen ? <CloseIcon /> : <SettingsIcon />}
						</IconButton>
					</Box>
				}
				subtitle={settingsOpen && props.title}
			>
				{settingsOpen ? (
					<Localized id="widget-settings-title">Settings</Localized>
				) : (
					props.title
				)}
			</WidgetTitle>
			<WidgetBody style={{overflow: "auto"}}>
				<Box
					position="relative"
					display="flex"
					flexDirection="column"
					width="100%"
					height="100%"
				>
					<ResultsSearch
						courseId={props.courseId}
						onSearchResultExpand={(s, p) => {
							setSelectedStudent(s);
							setSelectedStudentProgress(p);
						}}
						settings={settings.current}
						viewOnly={props.viewOnly}
					/>
					<AssessmentPageDialogContextProvider>
						<WidgetBodyOverlay in={Boolean(selectedStudent)} order={1}>
							<StudentProgressInChapters
								courseId={props.courseId}
								student={selectedStudent ?? emptyResults}
								studentProgressInChapters={selectedStudentProgress}
								onCollapse={() => setSelectedStudent(null)}
								barType={settings.current?.progressBarType ?? "plain"}
								viewOnly={props.viewOnly}
							/>
						</WidgetBodyOverlay>

						{!props.viewOnly && (
							<ResponseAssessmentDialog courseId={props.courseId} />
						)}
					</AssessmentPageDialogContextProvider>

					<WidgetBodyOverlay in={settingsOpen} order={2}>
						<StudentResultsWidgetSettings
							settings={settings.current}
							onChange={updateSettings}
						/>
					</WidgetBodyOverlay>
				</Box>
			</WidgetBody>
		</Widget>
	);
};

function ResultsSearch(props: {
	courseId: number;
	onSearchResultExpand: (
		student: StudentResultsInCourse,
		progress: ProgressByChapter[]
	) => void;
	settings: WidgetSettings | null;
	viewOnly?: boolean;
}): JSX.Element {
	const {courseId, settings} = props;

	const [
		searchCriteria,
		setSearchCriteria,
	] = useState<StudentResultsSearchCriteria>(() => ({query: ""}));

	const [
		studentsPage,
		setStudentsPage,
	] = useState<Page<StudentResultsInCourse> | null>(null);

	const [progressByType, setProgressByType] = useState<StudentProgressByType[]>(
		[]
	);

	const [studentsPerPage, setStudentsPerPage] = useState(10);

	const [selectedStudents, setSelectedStudents] = useState<number[]>(() => []);

	const [pageLoading, setPageLoading] = useState(true);
	const [progressByTypeLoading, setProgressByTypeLoading] = useState(false);

	const courseKey = keyProvider.course(courseId);
	const courseStats = useAppSelector((state) =>
		selectCourseStats(state, courseKey)
	) || {maxScore: 0, numberOfExercises: 0};
	const courseStatsStatus = useAppSelector((state) =>
		selectCourseStatsFetchStatus(state, courseKey)
	);

	const [sortField, sortOrder, changeOrder] = useSorting<
		keyof StudentResultsInCourse
	>("lastName");

	useEffect(() => {
		setPageLoading(true);
		setStudentsPage(null);
		setProgressByType([]);

		analyticsService
			.searchStudentResultsInCourse(
				courseId,
				searchCriteria,
				{field: sortField, descending: sortOrder === SortOrder.Desc},
				studentsPerPage
			)
			.then((response) => {
				setStudentsPage(response);
			})
			.finally(() => setPageLoading(false));
	}, [courseId, searchCriteria, sortField, sortOrder, studentsPerPage]);

	useEffect(() => {
		if (
			!settings ||
			!settings.progressBarType ||
			settings.progressBarType === "plain"
		) {
			return;
		}

		if (studentsPage?.content && studentsPage.content.length > 0) {
			setProgressByTypeLoading(true);

			analyticsService
				.getProgressByType(courseId, ...studentsPage.content.map((s) => s.id))
				.then((response) => {
					setProgressByType(response);
				})
				.finally(() => setProgressByTypeLoading(false));
		}
	}, [courseId, settings, studentsPage?.content]);

	const fetchStudentsPage = useCallback(
		async (pageRel: LinkToPage) => {
			if (!studentsPage) {
				return;
			}
			const requestPage = studentsPage.request[pageRel];
			if (requestPage) {
				const newPage = await requestPage();
				setStudentsPage(newPage);
			}
		},
		[studentsPage]
	);

	const [actionInProgress, setActionInProgress] = useState<
		{[key in "markAssessed" | "markUnassessed"]?: boolean}
	>({});

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	function tryCallAction(
		actionName: keyof typeof actionInProgress,
		action: () => Promise<void>
	) {
		return async () => {
			setActionInProgress({[actionName]: true});

			try {
				await action();
			} catch {
				showSnackbar("error", l10n.getString("error-general"));
			} finally {
				setActionInProgress({[actionName]: false});
			}
		};
	}

	async function reloadPage() {
		if (!studentsPage?.reload) {
			return;
		}

		const reloaded = await studentsPage.reload();
		setStudentsPage(reloaded);
		setSelectedStudents([]);
	}

	const students = populate(
		studentsPerPage,
		studentsPage?.content ?? [],
		() => ({...emptyResults, id: Math.random()})
	);

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<Box display={selectedStudents.length > 0 ? "" : "none"}>
				<StudentResultsActionToolbar
					actionInProgress={actionInProgress}
					onMarkAssessed={tryCallAction("markAssessed", async () => {
						await analyticsService.markAssessed(courseId, selectedStudents);
						await reloadPage();
					})}
					onMarkUnassessed={tryCallAction("markUnassessed", async () => {
						await analyticsService.markUnassessed(courseId, selectedStudents);
						await reloadPage();
					})}
				/>
			</Box>
			<Box
				display={selectedStudents.length > 0 ? "none" : ""}
				pr={1}
				pb={2}
				pl={2}
			>
				<StudentResultsTableToolbar
					criteria={searchCriteria}
					onCriteriaChange={setSearchCriteria}
				/>
			</Box>
			<Table>
				<TableHead
					columns={columns}
					leftAnnex={
						!props.viewOnly && (
							<TableCell padding="checkbox">
								{selectedStudents.length > 0 && (
									<Checkbox
										indeterminate
										onChange={() => setSelectedStudents([])}
									/>
								)}
							</TableCell>
						)
					}
					rightAnnex={<TableCell padding="checkbox" />}
					sortOrder={sortOrder}
					sortField={sortField}
					onOrderChange={changeOrder}
				/>

				<TableBody style={{position: "relative"}}>
					{studentsPage && studentsPage.content.length === 0 && (
						<TableRow>
							<TableCell style={{borderBottom: "none"}}>
								<StudentSearchEmptyState />
							</TableCell>
						</TableRow>
					)}
					{students.map((s) => {
						const progressInChapters =
							progressByType.find((p) => p.studentId === s.id)?.chapters ?? [];

						return (
							<StudentResultsRow
								key={s.id}
								student={s}
								courseStats={courseStats}
								loading={pageLoading || courseStatsStatus === "pending"}
								actionIcon={<FullscreenIcon />}
								onAction={() =>
									props.onSearchResultExpand(s, progressInChapters)
								}
								barType={settings?.progressBarType}
								progressByTypeInChapters={progressInChapters}
								progressByTypeLoading={progressByTypeLoading}
								courseId={courseId}
								selectionCellContent={
									!props.viewOnly && (
										<Checkbox
											checked={selectedStudents.includes(s.id)}
											onChange={({target}) => {
												setSelectedStudents((prev) =>
													target.checked
														? [...prev, s.id]
														: prev.filter((id) => id !== s.id)
												);
											}}
										/>
									)
								}
							/>
						);
					})}
				</TableBody>
			</Table>
			<Localized
				id="follow-up-student-results-widget-pagination-label"
				attrs={{label: true}}
			>
				<TablePagination
					onPageChange={fetchStudentsPage}
					pageSize={studentsPerPage}
					onPageSizeChange={setStudentsPerPage}
					first={Boolean(studentsPage?.request.first)}
					last={Boolean(studentsPage?.request.last)}
					next={Boolean(studentsPage?.request.next)}
					previous={Boolean(studentsPage?.request.previous)}
					label="Students per page"
				/>
			</Localized>
		</Box>
	);
}

export default StudentResultsWidget;
