import {createAsyncThunk} from "@reduxjs/toolkit";

import {courseService} from "../services/courseService";

const publishChapter = createAsyncThunk(
	"chapters/publishChapter",
	async (params: {courseId: number; chapterId: number}) => {
		const {courseId, chapterId} = params;

		const startDate = new Date().toISOString();

		await courseService.updateChapter(courseId, chapterId, {startDate});

		return {
			id: chapterId,
			startDate,
		};
	}
);

export default publishChapter;
