import DocumentFormat from "../../utils/attachments/DocumentFormat";
import type UploadedFile from "../../utils/attachments/UploadedFile";
import {axiosInstance as client} from "./axiosInstance";

type ExerciseFileRequest = {
	courseId: number;
	documentFormat: DocumentFormat;
	exerciseId: number;
	personId: number;
};
type ExerciseUploadRequest = ExerciseFileRequest & {
	file: Blob;
};

type ExerciseResponseFileRequest = ExerciseFileRequest & {
	chapterId: number;
};

type AssessorCommentFileRequest = ExerciseFileRequest & {
	documentFormat: DocumentFormat.AssessorComment;
	chapterId: number;
};

type ExerciseResponseUploadRequest = ExerciseResponseFileRequest & {
	file: Blob;
};

type TutoringFileRequest = {
	documentFormat: DocumentFormat.TutorQuestion | DocumentFormat.TutorAnswer;
	questionId?: number;
	courseId: number;
	personId: number;
};
type TutoringUploadRequest = TutoringFileRequest & {
	file: Blob;
};

type CourseUploadRequest = {
	courseId: number;
	file: File;
	documentFormat: DocumentFormat;
};

export type GetFilesRequest =
	| AssessorCommentFileRequest
	| ExerciseFileRequest
	| ExerciseResponseFileRequest
	| TutoringFileRequest;
export type UploadFileRequest =
	| ExerciseUploadRequest
	| ExerciseResponseUploadRequest
	| TutoringUploadRequest;

type FileResponse = {
	document_id: number;
	deleteUrl: string;
	name: string;
	url: string;
	size: number;
};

const url = "/api/public/content";

export const contentService = {
	getAll: async (request: GetFilesRequest): Promise<UploadedFile[]> => {
		const params = new URLSearchParams();
		fillRequest(params, request);

		const {data} = await client.get<{files: FileResponse[]}>(url, {params});

		return data.files.map(mapToUploadedFile);
	},
	upload: async (
		request: UploadFileRequest,
		uploadName?: string
	): Promise<UploadedFile> => {
		const body = new FormData();
		fillRequest(body, request);
		body.append("files[]", request.file, uploadName);

		const {data} = await client.post<{files: FileResponse[]}>(url, body, {
			headers: {"Content-Type": "multipart/form-data"},
		});

		return mapToUploadedFile(data.files[0]);
	},
	delete: async (deleteUrl: string): Promise<void> => {
		client.delete(`/api${deleteUrl}`);
	},
	uploadCourseFile: async (
		request: CourseUploadRequest
	): Promise<UploadedFile> => {
		const body = new FormData();
		body.append("course_id", `${request.courseId}`);
		body.append("document_format", request.documentFormat);
		body.append("file", request.file);

		const {data} = await client.post<{files: FileResponse[]}>(url, body, {
			headers: {"Content-Type": "multipart/form-data"},
		});

		return mapToUploadedFile(data.files[0]);
	},
};

function fillRequest(
	req: {append: (key: string, value: string) => void},
	dto: GetFilesRequest
) {
	req.append("course_id", `${dto.courseId}`);
	req.append("person_id", `${dto.personId}`);
	req.append("document_format", dto.documentFormat);

	if ("exerciseId" in dto) {
		req.append("exercise_template_id", `${dto.exerciseId}`);
	}

	if ("questionId" in dto) {
		req.append("tutor_question_id", `${dto.questionId}`);
	}

	if ("chapterId" in dto) {
		req.append("chapter_id", `${dto.chapterId}`);
	}
}

function mapToUploadedFile(file: FileResponse): UploadedFile {
	return {
		id: file.document_id,
		deleteUrl: file.deleteUrl,
		name: file.name,
		size: file.size,
		url: file.url,
	};
}
