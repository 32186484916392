import type {TooltipProps} from "@mui/material/Tooltip";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import React from "react";

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
	<Tooltip classes={{popper: className}} {...props} />
))(({theme}) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		maxWidth: "none",
	},
}));

export default LightTooltip;
