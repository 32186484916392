import {useLocalization} from "@fluent/react";
import {Box, Typography} from "@mui/material";
import React from "react";

function ExerciseParameters(props: {
	maxScore?: number;
	category: string;
	difficultyLevel: number;
}) {
	const {l10n} = useLocalization();

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: (theme) => theme.spacing(5),
			}}
		>
			{props.maxScore !== 0 &&
				ExeciseParameter(
					l10n.getString("content-exercise-max-score", null, "Max score"),
					props.maxScore
				)}

			{props.category && ExeciseParameter("Category", props.category)}

			{props.difficultyLevel !== 0 &&
				ExeciseParameter("Difficulty", props.difficultyLevel)}
		</Box>
	);
}

function ExeciseParameter(description: string, value?: string | number) {
	return (
		<Typography variant="overline" component="span">
			<Typography
				variant="inherit"
				color="textSecondary"
				component="span"
				whiteSpace="pre"
			>
				{description + ": "}
			</Typography>

			{value}
		</Typography>
	);
}

export default ExerciseParameters;
