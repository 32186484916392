import {createSlice} from "@reduxjs/toolkit";

import type Response from "./Response";
import {fetchStudentResponses} from "./fetchStudentResponses";
import {resetStudentResponse} from "./resetStudentResponse";
import submitStudentResponse from "./submitStudentResponse";

type Responses = {
	byKey: {
		[key: string]: Response | undefined;
	};
};

const initialState: Responses = {
	byKey: {},
};

export const studentResponsesSlice = createSlice({
	name: "studentResponses",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchStudentResponses.fulfilled, (state, action) => {
			const responses = action.payload.responses;
			state.byKey = responses;
		});

		builder.addCase(submitStudentResponse.fulfilled, (state, action) => {
			state.byKey[action.payload.responseKey] = action.payload.response;
		});

		builder.addCase(resetStudentResponse.fulfilled, (state, action) => {
			delete state.byKey[action.payload.responseKey];
		});
	},
});

export default studentResponsesSlice.reducer;
