import {blue, orange, purple, red, teal, yellow} from "@mui/material/colors";

import ExerciseType from "../../store/exercises/ExerciseType";

const exerciseColorMap = {
	[ExerciseType.External]: purple[500],
	[ExerciseType.Math]: teal[500],
	[ExerciseType.Multi]: red[500],
	[ExerciseType.Open]: blue[500],
	[ExerciseType.Prog]: yellow[500],
	[ExerciseType.Short]: orange[500],
	[ExerciseType.Theory]: "",
};

export default exerciseColorMap;
