import "core-js/stable";
import "regenerator-runtime/runtime";
import "symbol-observable";

import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import App from "./App";
import LocalisationProvider from "./i18n/LocalisationProvider";
import {store} from "./store/store";

ReactDOM.render(
	<Provider store={store}>
		<LocalisationProvider>
			<App />
		</LocalisationProvider>
	</Provider>,
	document.getElementById("root")
);
