import {MathExerciseSubtype} from "../exercises/ExerciseSubtype";
import {axiosInstance as client} from "./axiosInstance";

export const mathService = {
	generateExpressions: async (
		subtype: MathExerciseSubtype,
		difficulty: number,
		variable: string,
		courseId: number
	): Promise<string[]> => {
		const url = `/api/courses/${courseId}/maths-expressions`;
		const {data} = await client.post<{success?: 1; new_expr: string[]}>(url, {
			exercise_type: "math",
			evaluation_packet: subtype,
			difficulty_level: difficulty,
			variable,
		});

		if (!data.success) {
			throw new Error();
		}

		return data.new_expr;
	},

	evaluateExpression: async (
		expression: string,
		variable: string,
		subtype: MathExerciseSubtype,
		courseId: number
	): Promise<string[]> => {
		const url = `/api/courses/${courseId}/simplified-maths-expression`;
		const payload = {
			exercise_type: "math",
			evaluation_packet: subtype,
			extra_info: expression,
			variable,
			action: "teacher_question",
		};
		const {data} = await client.post<{success: 1; result: string[]}>(
			url,
			payload
		);

		if (!data.success) {
			throw new Error();
		}

		return data.result;
	},
};
