import React, {useState} from "react";

import EnrolInOrganisationCourseDialog from "./EnrolInOrganisationCourseDialog";

function useEnrolInOrganisationCourseAction(
	organisationName: string,
	mobileMode: boolean,
	onDone?: (courseId: number) => void
): [(userIds: number[]) => void, JSX.Element] {
	const [userIds, setUserIds] = useState<number[]>([]);

	return [
		setUserIds,
		<EnrolInOrganisationCourseDialog
			key="enrol-in-course"
			open={userIds.length > 0}
			mobileMode={mobileMode}
			organisationName={organisationName}
			userIds={userIds}
			onCancel={() => {
				setUserIds([]);
			}}
			onDone={(id) => {
				setUserIds([]);
				onDone?.(id);
			}}
		/>,
	];
}

export default useEnrolInOrganisationCourseAction;
