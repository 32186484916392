import {Localized} from "@fluent/react";
import React from "react";

import ExerciseType from "../../../store/exercises/ExerciseType";
import {CompletionStatus} from "../../../store/outcomes/ExerciseOutcomes";
import type Correctness from "../../../store/studentResponses/Correctness";
import type SubmissionResult from "../../../store/studentResponses/SubmissionResult";

const messageIds = {
	correct: "exercise-response-area-feedback-correct",
	incorrect: "exercise-response-area-feedback-incorrect",
	partially_correct: "exercise-response-area-feedback-partially-correct",
};

function createFeedbackSummary(
	submissionResult: SubmissionResult
): {
	correctness: Correctness;
	summary: React.ReactNode;
	message?: string;
} | null {
	if (
		submissionResult.outcomes?.completionStatus !== CompletionStatus.Complete ||
		submissionResult.feedback.status === "unspecified"
	) {
		return null;
	}

	const common = {
		correctness: submissionResult.feedback.status,
		summary: (
			<Localized
				id={messageIds[submissionResult.feedback.status]}
				vars={{score: submissionResult.outcomes.score ?? 0}}
			/>
		),
	};

	switch (submissionResult.feedback.type) {
		case ExerciseType.Open:
		case ExerciseType.Short:
			return {
				...common,
				message: submissionResult.feedback.message,
			};

		default:
			return common;
	}
}

export default createFeedbackSummary;
