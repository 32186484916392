import createSerialIdProvider from "../../../../helpers/createSerialIdProvider";

type AnswerStep = {
	id: string;
	asciiFormula: string;
	operation: string;
};

const id = createSerialIdProvider();

export function unmarshalStep(step: string): AnswerStep {
	const formulaStart = step[0] === "⇔" ? 1 : step.indexOf("=") + 1;

	return {
		id: id(),
		asciiFormula: step.slice(formulaStart),
		operation: step.slice(0, formulaStart),
	};
}

export function marshalStep(step: AnswerStep): string {
	return step.operation + step.asciiFormula;
}

export function createStep(
	asciiFormula: string,
	operation: string
): AnswerStep {
	return {
		id: id(),
		asciiFormula,
		operation,
	};
}

export default AnswerStep;
