import {Localized, useLocalization} from "@fluent/react";
import CreateIcon from "@mui/icons-material/Create";
import HelpIcon from "@mui/icons-material/Help";
import ReplayIcon from "@mui/icons-material/Replay";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Box,
	Button,
	CardMedia,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	InputAdornment,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	Switch,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import {makeStyles, withStyles} from "@mui/styles";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";

import {equalArrays} from "../../helpers/arrayHelpers";
import {addToDate} from "../../helpers/dateTimeHelpers";
import parseDate from "../../helpers/parseDate";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import AnswerVisibility from "../../store/chapterExercises/AnswerVisibility";
import changeCoursePicture from "../../store/courses/changeCoursePicture";
import type Course from "../../store/courses/Course";
import CourseFeature from "../../store/courses/CourseFeature";
import type CoursePatch from "../../store/courses/CoursePatch";
import CourseType from "../../store/courses/CourseType";
import deleteCoursePicture from "../../store/courses/deleteCoursePicture";
import NavigationMode from "../../store/courses/NavigationMode";
import SelfEnrolmentStatus from "../../store/courses/SelfEnrolmentStatus";
import updateCourseSettings from "../../store/courses/updateCourseSettings";
import selectNumberOfPendingEnrolmentApplications from "../../store/enrolment/selectNumberOfPendingEnrolmentApplications";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import ConfirmationFooter from "../../utils/ConfirmationFooter";
import LightTooltip from "../../utils/LightTooltip";

const useStyles = makeStyles((theme) => ({
	courseImage: {
		height: "136px",
	},
	textDisabled: {
		color: theme.palette.text.disabled,
	},
	archiveHint: {
		gap: theme.spacing(1),
	},
}));
const Tooltip = withStyles(() => ({
	tooltip: {
		padding: 16,
		whiteSpace: "pre-line",
	},
}))(LightTooltip);

type Draft = {
	answerVisibility: AnswerVisibility;
	selfAssessment: boolean;
	redoingExercises: boolean;
	courseName: string;
	startDate: string;
	endDate: string;
	selfEnrolment: SelfEnrolmentStatus;
	navigationMode: NavigationMode;
	showExerciseDifficulty: boolean;
	emptyAnswersToOpenExercises: boolean;
	extraMaterials: boolean;
	forum: boolean;
	tutoring: boolean;
	newAnalytics: boolean;
	calculator: boolean;
	workload?: number | null;
};

const GeneralSettings = (props: {
	course: Course;
	viewOnly?: boolean;
}): JSX.Element => {
	const classes = useStyles();

	const {course, viewOnly} = props;

	const courseId = course.id;

	const dispatch = useAppDispatch();

	const [initialDraft, setInitialDraft] = useState<Draft | null>(null);
	const [draft, setDraft] = useState<Draft | null>(null);

	const [dirty, setDirty] = useState(false);

	const [requiredEmpty, setRequiredEmpty] = useState<{
		courseName?: boolean;
		startDate?: boolean;
		endDate?: boolean;
	}>({});

	const [startAfterEnd, setStartAfterEnd] = useState(false);
	const [endAfterArchivalDate, setEndAfterArchivalDate] = useState(false);

	const numberOfPendingApplications = useAppSelector((state) =>
		selectNumberOfPendingEnrolmentApplications(
			state,
			keyProvider.course(courseId)
		)
	);

	const formatDateTime = useDateTimeFormat();

	const [saving, setSaving] = useState(false);

	const [featureEnabled] = useFeatureEnabled();

	useEffect(() => {
		if (!draft) {
			const init: Draft = {
				answerVisibility: course.answerVisibility,
				selfAssessment: course.features.includes(CourseFeature.SelfAssessment),
				redoingExercises: course.features.includes(
					CourseFeature.RedoingExercises
				),
				courseName: course.name,
				startDate: course.startDate,
				endDate: course.endDate,
				selfEnrolment: course.selfEnrolment,
				navigationMode: course.navigationMode,
				showExerciseDifficulty: course.features.includes(
					CourseFeature.ExerciseDifficulty
				),
				emptyAnswersToOpenExercises: course.features.includes(
					CourseFeature.EmptyAnswersToOpenExercises
				),
				tutoring: course.features.includes(CourseFeature.Tutoring),
				newAnalytics: course.features.includes(CourseFeature.NewAnalytics),
				calculator: course.features.includes(CourseFeature.Calculator),
				forum: course.features.includes(CourseFeature.Forum),
				extraMaterials: course.features.includes(CourseFeature.ExtraMaterials),
				workload: course.workload,
			};
			setDraft(init);
			setInitialDraft(init);
		}
	}, [course, draft]);

	const {l10n} = useLocalization();
	const theme = useTheme();

	if (!initialDraft || !draft) {
		return <></>;
	}

	const now = new Date();

	const update = (
		propName: keyof Draft,
		value:
			| string
			| boolean
			| AnswerVisibility
			| SelfEnrolmentStatus
			| NavigationMode
			| number
	) => {
		if (viewOnly) {
			return;
		}

		setDirty(
			value !== initialDraft[propName] ||
				Object.keys(draft)
					.filter((key) => key !== propName)
					.reduce((prev: boolean, curr: string) => {
						const key = curr as keyof Draft;
						const equal = draft[key] !== initialDraft[key];
						return prev || equal;
					}, false)
		);

		setDraft((prev) => {
			const oldDraft = prev ? prev : initialDraft;

			return {...oldDraft, [propName]: value};
		});
	};

	const discardHandler = () => {
		setDraft(initialDraft);

		setDirty(false);

		setRequiredEmpty({
			courseName: false,
			startDate: false,
			endDate: false,
		});

		setStartAfterEnd(false);
		setEndAfterArchivalDate(false);
	};

	let maxEndDate: string | undefined;
	if (course.archivalDate) {
		maxEndDate = addToDate(course.archivalDate, {days: -1}).slice(0, 10);
	}

	const validate = () => {
		const emptyFields = {
			courseName: draft.courseName.length === 0,
			startDate: draft.startDate.length === 0,
			endDate: draft.endDate.length === 0,
		};
		const inconsistentDates =
			!emptyFields.startDate &&
			!emptyFields.endDate &&
			draft.startDate > draft.endDate;

		const endDateMoreArchival =
			!emptyFields.endDate && maxEndDate && draft.endDate > maxEndDate;

		setRequiredEmpty(emptyFields);
		setStartAfterEnd(inconsistentDates);
		setEndAfterArchivalDate(Boolean(endDateMoreArchival));

		return !(
			Object.values(emptyFields).some((e) => e) ||
			inconsistentDates ||
			endDateMoreArchival
		);
	};

	const saveHandler = async () => {
		const valid = validate();
		if (!valid) {
			return;
		}

		const patch: CoursePatch = {};

		if (draft.courseName !== initialDraft.courseName) {
			patch.name = draft.courseName;
		}

		if (draft.answerVisibility !== initialDraft.answerVisibility) {
			patch.answerVisibility = draft.answerVisibility;
		}

		if (draft.navigationMode !== initialDraft.navigationMode) {
			patch.navigationMode = draft.navigationMode;
		}

		if (draft.selfEnrolment !== initialDraft.selfEnrolment) {
			patch.selfEnrolment = draft.selfEnrolment;
		}

		if (draft.startDate !== initialDraft.startDate) {
			patch.startDate = draft.startDate;
		}

		if (draft.endDate !== initialDraft.endDate) {
			patch.endDate = draft.endDate;
		}

		if (draft.workload !== initialDraft.workload) {
			patch.workload = draft.workload || null;
		}

		const features = [];

		if (draft.selfAssessment) {
			features.push(CourseFeature.SelfAssessment);
		}

		if (draft.redoingExercises) {
			features.push(CourseFeature.RedoingExercises);
		}

		if (draft.showExerciseDifficulty) {
			features.push(CourseFeature.ExerciseDifficulty);
		}

		const emptyAnswerAllowed =
			draft.selfAssessment && draft.emptyAnswersToOpenExercises;
		if (emptyAnswerAllowed) {
			features.push(CourseFeature.EmptyAnswersToOpenExercises);
		}
		if (emptyAnswerAllowed !== draft.emptyAnswersToOpenExercises) {
			update("emptyAnswersToOpenExercises", emptyAnswerAllowed);
		}

		if (draft.extraMaterials) {
			features.push(CourseFeature.ExtraMaterials);
		}

		if (draft.forum) {
			features.push(CourseFeature.Forum);
		}

		if (draft.tutoring) {
			features.push(CourseFeature.Tutoring);
		}

		if (draft.newAnalytics) {
			features.push(CourseFeature.NewAnalytics);
		}

		if (draft.calculator) {
			features.push(CourseFeature.Calculator);
		}

		if (!equalArrays(features, course.features)) {
			patch.features = features;
		}

		setSaving(true);

		const res = await dispatch(
			updateCourseSettings({courseId, request: patch})
		);

		setSaving(false);

		unwrapResult(res);

		setDirty(false);
		setInitialDraft(draft);
	};

	const changeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = e.target.files;

		if (!fileList) {
			return;
		}
		const file = fileList[0];

		dispatch(changeCoursePicture({courseId, file}));
	};

	const deleteImage = () => {
		if (course.picture) {
			dispatch(deleteCoursePicture({courseId, pictureUri: course.picture}));
		}
	};

	const redoingVisible =
		featureEnabled(Feature.RedoingExercises) &&
		course.type === CourseType.Ordinary;

	const createdDate = formatDateTime(parseDate(course.dateCreated));

	const archivalDate = course.archivalDate && parseDate(course.archivalDate);

	const min = draft.startDate;
	const max = draft.endDate;

	return (
		<>
			<Grid container spacing={3}>
				<Grid
					item
					container
					xs={12}
					md={3}
					style={{alignContent: "flex-start"}}
					spacing={1}
				>
					<Grid item xs={3} md={12}>
						<CardMedia
							image={course.picture ? course.picture : course.defaultPicture}
							className={classes.courseImage}
							component="img"
						/>
					</Grid>
					<Grid item xs md={12} container direction="column">
						<Grid item>
							<Button
								component="label"
								color="primary"
								size="small"
								startIcon={<CreateIcon fontSize="small" />}
								disabled={viewOnly}
							>
								<Box onChange={changeImage}>
									<Localized id="course-settings-form-edit-photo">
										Edit photo
									</Localized>
									<input type="file" hidden />
								</Box>
							</Button>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								size="small"
								startIcon={<ReplayIcon fontSize="small" />}
								onClick={deleteImage}
								disabled={viewOnly}
								sx={{textAlign: "left"}}
							>
								<Localized id="course-settings-form-return-default-photo">
									Return to default photo
								</Localized>
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={8}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								required
								label={
									<Localized id="course-settings-form-label-course-name">
										Course name
									</Localized>
								}
								error={requiredEmpty.courseName}
								helperText={
									requiredEmpty.courseName && (
										<Localized id="course-settings-form-helper-mandatory-field">
											This field is mandatory
										</Localized>
									)
								}
								InputLabelProps={{shrink: true}}
								value={draft.courseName}
								InputProps={{readOnly: viewOnly}}
								onChange={(event) => update("courseName", event.target.value)}
							/>
						</Grid>
						<Grid item xs={6} md={3}>
							<TextField
								label={
									<Localized id="course-settings-form-label-start-date">
										Start date
									</Localized>
								}
								type="date"
								fullWidth
								required
								error={requiredEmpty.startDate || startAfterEnd}
								helperText={
									(requiredEmpty.startDate && (
										<Localized id="course-settings-form-helper-mandatory-field">
											This field is mandatory
										</Localized>
									)) ||
									(startAfterEnd && (
										<Localized id="course-settings-form-helper-inconsistent-date">
											Start date cannot be later than the end date
										</Localized>
									))
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{inputProps: {max}, readOnly: viewOnly}}
								onChange={(event) => {
									update("startDate", event.target.value);
									setStartAfterEnd(false);
								}}
								value={draft.startDate}
							/>
						</Grid>
						<Grid item xs={6} md={3}>
							<TextField
								label={
									<Localized id="course-settings-form-label-end-date">
										End date
									</Localized>
								}
								type="date"
								fullWidth
								required
								error={
									requiredEmpty.endDate || startAfterEnd || endAfterArchivalDate
								}
								helperText={
									<>
										{requiredEmpty.endDate && (
											<Localized id="course-settings-form-helper-mandatory-field">
												This field is mandatory
											</Localized>
										)}
										{endAfterArchivalDate && (
											<Localized id="course-settings-form-helper-end-date-more-archival-date">
												End date should be before archival date
											</Localized>
										)}
									</>
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputProps: {min, max: maxEndDate, readOnly: viewOnly},
								}}
								value={draft.endDate}
								onChange={(event) => {
									update("endDate", event.target.value);
									setStartAfterEnd(false);
									setEndAfterArchivalDate(false);
								}}
							/>
						</Grid>
					</Grid>
					<Box display="flex" mt={2}>
						<Typography variant="body2" color="textSecondary">
							<Localized id="course-settings-form-created">Created</Localized>{" "}
							{createdDate}
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography variant="body2" color="textSecondary">
							<Localized id="course-settings-form-course-template">
								Course template:
							</Localized>{" "}
							{course.templateName}
						</Typography>
					</Box>
					{archivalDate && (
						<Box
							display="flex"
							alignItems="center"
							className={classes.archiveHint}
							mt={1}
						>
							<Typography variant="body2" color="textSecondary">
								<Localized
									id={
										archivalDate > now
											? "course-settings-form-archival-date-future"
											: "course-settings-form-archival-date-past"
									}
									vars={{archivalDate: formatDateTime(archivalDate)}}
								>
									The course will be archived
								</Localized>
							</Typography>
							<Tooltip
								title={
									<Localized
										id="course-settings-form-hint-archiving"
										elems={{paragraph: <Typography></Typography>}}
									>
										<>
											{`Only administrators can see, access and manage archived courses.` +
												` However, no changes can be made to archived courses.` +
												`The end date of the course should be before its archival date,` +
												` and only administrators can change archival date.`}
										</>
									</Localized>
								}
							>
								<HelpIcon color="primary" fontSize="small" />
							</Tooltip>
						</Box>
					)}

					<Box display="flex" flexDirection="column">
						{course.type === CourseType.Ordinary && (
							<Box display="flex">
								<Box display="flex" flexDirection="column" mt={3}>
									<FormControlLabel
										control={
											<Switch
												checked={draft.selfAssessment}
												onChange={(event) => {
													update("selfAssessment", event.target.checked);
												}}
											/>
										}
										label={
											<Localized id="course-settings-form-self-assessment-mode">
												Self-assesment mode
											</Localized>
										}
									/>
									<Box
										display="flex"
										flexDirection="column"
										ml={6}
										color="text.secondary"
									>
										<Typography variant="caption" display="inline">
											<Localized id="course-settings-form-self-assessment-description1">
												The student will evaluate himself
											</Localized>
										</Typography>
										<Typography variant="caption" display="inline">
											<Localized id="course-settings-form-self-assessment-description2">
												without checking by the teacher
											</Localized>
										</Typography>
									</Box>
								</Box>
								{draft.selfAssessment && (
									<Box display="flex" flexDirection="column" mt={3} ml={3}>
										<FormControlLabel
											control={
												<Checkbox
													checked={draft.emptyAnswersToOpenExercises}
													onChange={(event) =>
														update(
															"emptyAnswersToOpenExercises",
															event.target.checked
														)
													}
												/>
											}
											label={
												<Localized id="course-settings-form-self-assessment-allow-empty-answer">
													Allow an empty answer for open exercises
												</Localized>
											}
										/>
										<Box ml={4}>
											<Typography variant="caption" color="textSecondary">
												<Localized id="course-settings-form-self-assessment-label-allow-empty-answer">
													The student can skip the answer in the open exercise
												</Localized>
											</Typography>
										</Box>
									</Box>
								)}
							</Box>
						)}
						<Box display="flex" mt={3} mb={2} style={{gap: theme.spacing(3)}}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={draft.showExerciseDifficulty}
											onChange={(event) =>
												update("showExerciseDifficulty", event.target.checked)
											}
										/>
									}
									label={
										<Typography variant="body2">
											<Localized id="course-settings-form-show-exercise-difficulty">
												Show exercise difficulty
											</Localized>
										</Typography>
									}
								/>
								{redoingVisible && (
									<Box display="flex" flexDirection="column" mt={2}>
										<FormControlLabel
											control={
												<Checkbox
													checked={draft.redoingExercises}
													onChange={(event) =>
														update("redoingExercises", event.target.checked)
													}
												/>
											}
											label={
												<Typography variant="body2">
													<Localized id="course-settings-form-allow-redoing-exercises">
														Allow redoing exercises
													</Localized>
												</Typography>
											}
										/>
										<Box ml={4}>
											<Typography variant="caption" color="textSecondary">
												<Localized id="course-settings-form-label-allow-redoing-exercises">
													Many attempts to solve the exercise
												</Localized>
											</Typography>
										</Box>
									</Box>
								)}
								<Box display="flex" flexDirection="column" mt={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={draft.extraMaterials}
												onChange={(event) =>
													update("extraMaterials", event.target.checked)
												}
											/>
										}
										label={
											<Typography variant="body2" display="inline">
												<Localized id="course-settings-form-label-extra-materials">
													Extra materials
												</Localized>
											</Typography>
										}
									/>
								</Box>
								<Box display="flex" flexDirection="column" mt={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={draft.forum}
												onChange={(event) =>
													update("forum", event.target.checked)
												}
											/>
										}
										label={
											<Typography variant="body2" display="inline">
												<Localized id="course-settings-form-label-forum">
													Forum
												</Localized>
											</Typography>
										}
									/>
								</Box>
								<Box display="flex" flexDirection="column" mt={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={draft.tutoring}
												onChange={(event) =>
													update("tutoring", event.target.checked)
												}
											/>
										}
										label={
											<Typography variant="body2" display="inline">
												<Localized id="course-settings-form-label-tutoring">
													Tutoring
												</Localized>
											</Typography>
										}
									/>
								</Box>
								{featureEnabled(Feature.NewAnalytics) && (
									<Box mt={2}>
										<FormControlLabel
											control={
												<Checkbox
													checked={draft.newAnalytics}
													onChange={(event) =>
														update("newAnalytics", event.target.checked)
													}
												/>
											}
											label={
												<Typography variant="body2" display="inline">
													<Localized id="course-settings-form-label-new-analytics">
														New analytics
													</Localized>
												</Typography>
											}
										/>
									</Box>
								)}
								<Box mt={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={draft.calculator}
												onChange={(event) =>
													update("calculator", event.target.checked)
												}
											/>
										}
										label={
											<Typography variant="body2" display="inline">
												<Localized id="course-settings-form-label-calculator">
													Calculator
												</Localized>
											</Typography>
										}
									/>
								</Box>
							</FormGroup>

							{course.type === CourseType.Ordinary && (
								<FormControl disabled>
									<FormLabel>
										<Typography
											variant="subtitle1"
											className={classes.textDisabled}
											style={{fontWeight: 500}}
										>
											<Localized id="course-settings-form-heading-order-of-completion">
												Order of course completion
											</Localized>
										</Typography>
									</FormLabel>
									<RadioGroup
										value={draft.navigationMode}
										onChange={(event) =>
											update(
												"navigationMode",
												event.target.value as NavigationMode
											)
										}
									>
										<FormControlLabel
											value={NavigationMode.NonLinear}
											control={<Radio />}
											label={
												<Typography
													variant="body2"
													className={classes.textDisabled}
												>
													<Localized id="course-settings-form-course-completion-non-linear">
														Non-linear
													</Localized>
												</Typography>
											}
										/>
										<Box display="flex" flexDirection="column">
											<FormControlLabel
												value={NavigationMode.InChaptersOrder}
												control={<Radio />}
												label={
													<Typography
														variant="body2"
														className={classes.textDisabled}
													>
														<Localized id="course-settings-form-course-completion-chapters">
															By chapters
														</Localized>
													</Typography>
												}
											/>
											<Box ml={4}>
												<Typography
													variant="caption"
													className={classes.textDisabled}
												>
													<Localized id="course-settings-form-label-course-completion-chapters">
														The next chapter is available after completing the
														previous one
													</Localized>
												</Typography>
											</Box>
										</Box>
										<Box display="flex" flexDirection="column">
											<FormControlLabel
												value={NavigationMode.InExercisesOrder}
												control={<Radio />}
												label={
													<Typography
														variant="body2"
														className={classes.textDisabled}
													>
														<Localized id="course-settings-form-course-completion-exercises">
															By exercises
														</Localized>
													</Typography>
												}
											/>
											<Box ml={4}>
												<Typography
													variant="caption"
													className={classes.textDisabled}
												>
													<Localized id="course-settings-form-label-course-completion-exercises">
														The next exercise is available after completing the
														previous one
													</Localized>
												</Typography>
											</Box>
										</Box>
									</RadioGroup>
								</FormControl>
							)}
						</Box>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} xl={4}>
								<TextField
									label={
										<Localized id="course-settings-form-label-show-example-answers">
											Show example answers
										</Localized>
									}
									select
									value={draft.answerVisibility}
									onChange={(event) =>
										update(
											"answerVisibility",
											event.target.value as AnswerVisibility
										)
									}
									fullWidth
									slotProps={{
										input: {
											readOnly: viewOnly,
										},
									}}
								>
									<MenuItem value={AnswerVisibility.Never}>
										<Localized id="exercise-answer-visibility-never">
											Never
										</Localized>
									</MenuItem>
									<MenuItem value={AnswerVisibility.AfterSubmission}>
										<Localized id="exercise-answer-visibility-submission">
											After submission
										</Localized>
									</MenuItem>
									{course.type === CourseType.Exam && (
										<MenuItem value={AnswerVisibility.AfterDeadline}>
											<Localized id="exercise-answer-visibility-deadline">
												After deadline
											</Localized>
										</MenuItem>
									)}
									<MenuItem value={AnswerVisibility.Always}>
										<Localized id="exercise-answer-visibility-always">
											Always
										</Localized>
									</MenuItem>
								</TextField>
							</Grid>

							{featureEnabled(Feature.NewAnalytics) &&
								course.features.includes(CourseFeature.NewAnalytics) && (
									<Grid item xs={12} md={6} xl={4}>
										<TextField
											type="number"
											fullWidth
											value={draft.workload || ""}
											onChange={(event) => {
												update("workload", parseInt(event.target.value || "0"));
											}}
											InputProps={{
												inputProps: {min: 0},
												endAdornment: (
													<InputAdornment position="end">
														<Localized
															id="course-settings-form-field-workload-units"
															vars={{value: draft.workload ?? 0}}
														>
															week(s)
														</Localized>
													</InputAdornment>
												),
												readOnly: viewOnly,
											}}
											InputLabelProps={{shrink: true}}
											label={
												<Localized id="course-settings-form-field-workload-label">
													Course workload
												</Localized>
											}
											placeholder={l10n.getString(
												"course-settings-form-field-workload-placeholder",
												null,
												"Not set"
											)}
										/>
									</Grid>
								)}
						</Grid>
					</Box>
					<Box display="flex" flexDirection={"column"} mt={6}>
						<Typography variant="h6" color="textSecondary">
							<Localized id="course-settings-form-heading-enrolment">
								Enrolment
							</Localized>
						</Typography>
						<Stack
							direction={{xs: "column-reverse", md: "row"}}
							spacing={3}
							sx={{mt: 1, alignItems: {xs: "flex-start", md: "center"}}}
						>
							<FormControl sx={{flexGrow: 1}}>
								<RadioGroup
									value={draft.selfEnrolment}
									onChange={(event) =>
										update(
											"selfEnrolment",
											event.target.value as SelfEnrolmentStatus
										)
									}
								>
									<FormControlLabel
										value={SelfEnrolmentStatus.Disabled}
										control={<Radio />}
										label={
											<Localized id="course-settings-form-label-not-allowed">
												Not allowed
											</Localized>
										}
									/>
									<FormControlLabel
										value={SelfEnrolmentStatus.RequiresApproval}
										control={<Radio />}
										label={
											<Localized id="course-settings-form-label-with-confirmation">
												Allowed with confirmation of course teacher
											</Localized>
										}
									/>
									<FormControlLabel
										value={SelfEnrolmentStatus.Enabled}
										control={<Radio />}
										label={
											<Localized id="course-settings-form-label-without-confirmation">
												Allowed without confirmation (MOOC)
											</Localized>
										}
									/>
								</RadioGroup>
							</FormControl>
							{numberOfPendingApplications > 0 &&
								initialDraft.selfEnrolment ===
									SelfEnrolmentStatus.RequiresApproval &&
								draft.selfEnrolment !==
									SelfEnrolmentStatus.RequiresApproval && (
									<PendingApplicationsWarning
										numberOfApplications={numberOfPendingApplications}
										newEnrolmentStatus={draft.selfEnrolment}
									/>
								)}
						</Stack>
					</Box>
				</Grid>
			</Grid>
			{dirty && (
				<ConfirmationFooter
					onDiscard={discardHandler}
					onSave={saveHandler}
					saveInProgress={saving}
				/>
			)}
		</>
	);
};

function PendingApplicationsWarning(props: {
	numberOfApplications: number;
	newEnrolmentStatus: SelfEnrolmentStatus;
}) {
	return (
		<Stack
			spacing={2}
			direction="row"
			sx={{
				p: 2,
				maxWidth: {xs: 1, md: 0.5},
				alignItems: "flex-start",
			}}
			component={Paper}
		>
			<WarningIcon color="warning" />
			<Typography>
				{props.newEnrolmentStatus === SelfEnrolmentStatus.Disabled && (
					<Localized
						id="course-settings-form-warning-pending-enrolment-applications-rejected"
						vars={{number: props.numberOfApplications}}
					>
						There are pending enrolment applications. They will be automatically
						rejected if you save the changes.
					</Localized>
				)}
				{props.newEnrolmentStatus === SelfEnrolmentStatus.Enabled && (
					<Localized
						id="course-settings-form-warning-pending-enrolment-applications-accepted"
						vars={{number: props.numberOfApplications}}
					>
						There are pending enrolment applications. They will be automatically
						accepted if you save the changes.
					</Localized>
				)}
			</Typography>
		</Stack>
	);
}

export default GeneralSettings;
