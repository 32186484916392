import {createAsyncThunk} from "@reduxjs/toolkit";

import exerciseService from "../services/exerciseService";
import AnswerVisibility from "./AnswerVisibility";

const updateChapterExerciseSettings = createAsyncThunk(
	"chapterExercises/updateChapterExerciseSettings",
	async function (params: {
		courseId: number;
		chapterId: number;
		exerciseId: number;
		settings: {
			maxScore?: number;
			answerVisibility?: AnswerVisibility | null;
		};
	}) {
		const {courseId, chapterId, exerciseId, settings} = params;

		await exerciseService.patchExerciseSettings(
			courseId,
			chapterId,
			exerciseId,
			settings
		);

		return {
			chapterId,
			exerciseId,
			maxScore: settings.maxScore,
			answerVisibility: settings.answerVisibility,
		};
	}
);

export default updateChapterExerciseSettings;
