import {Localized, useLocalization} from "@fluent/react";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid2 as Grid,
	Paper,
	Stack,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";

import getNotificationMessage from "./notificationMessages";
import selectCourse from "../../store/courses/selectCourse";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {courseService} from "../../store/services/courseService";
import type {
	NotificationRule,
	NotificationRuleDefinition,
} from "../../store/services/notificationsService";
import notificationsService from "../../store/services/notificationsService";
import useSnackbar from "../../store/ui/useSnackbar";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import SubmitButton from "../../utils/SubmitButton";

type NotificationStatus = "on" | "off";

function NotificationsSettings(props: {
	course: {id: number; name: string};
	viewOnly?: boolean;
}) {
	const {course} = props;

	const [featureEnabled] = useFeatureEnabled();

	const [allRules, setAllRules] = useState<NotificationRuleDefinition[]>([]);
	const [courseRules, setCourseRules] = useState<NotificationRule[]>([]);

	const [anyStudyTarget, setAnyStudyTarget] = useState(false);
	const targetNotificationsEnabled = featureEnabled(
		Feature.TargetsAccomplishingNotification
	);

	const [editorOpen, setEditorOpen] = useState("");

	const workload = useAppSelector(
		(state) => selectCourse(state, keyProvider.course(course.id))?.workload
	);

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	useEffect(() => {
		async function fetchRules() {
			const rules = await notificationsService.getAvailableNotificationRules();
			setAllRules(rules);
		}

		async function fetchCourseRules() {
			const rules = await notificationsService.getCourseNotificationRules(
				course.id
			);

			setCourseRules(rules);
		}

		fetchRules();
		fetchCourseRules();
	}, [course.id]);

	useEffect(() => {
		async function fetchTargets() {
			const targets = await courseService.getCourseStudyTargets(course.id);
			setAnyStudyTarget(targets.length > 0);
		}
		if (targetNotificationsEnabled) {
			fetchTargets();
		}
	}, [course.id, targetNotificationsEnabled]);

	async function save(rule: string, status: NotificationStatus) {
		let rules = courseRules;
		if (status === "on") {
			const add = allRules.find((r) => r.name === rule);
			if (add) {
				rules = [...rules, add];
			}
		} else {
			rules = rules.filter((r) => r.name !== rule);
		}

		try {
			await notificationsService.updateCourseNotificationRules(
				course.id,
				rules
			);

			setEditorOpen("");

			setCourseRules(rules);
		} catch (err) {
			showSnackbar(
				"error",
				l10n.getString("error-general", null, "An error has occured")
			);

			throw err;
		}
	}

	function warning(r: NotificationRuleDefinition) {
		if (
			(r.name === "course_study_period_ends_soon" ||
				r.name === "half_of_course_study_period_passed") &&
			!workload
		) {
			return (
				<Localized id="course-settings-notifications-warning-no-workload">
					The notification will not be sent because the course workload is not
					set
				</Localized>
			);
		}

		if (r.name === "course_targets_accomplished") {
			if (!targetNotificationsEnabled) {
				return (
					<Localized id="course-settings-notifications-warning-targets-disabled">
						The feature is disabled for the course
					</Localized>
				);
			}

			if (!anyStudyTarget) {
				return (
					<Localized id="course-settings-notifications-warning-no-targets">
						The notification will not be sent because there are no study targets
						set for the course
					</Localized>
				);
			}
		}

		return "";
	}

	function notificationStatus(
		r: NotificationRuleDefinition
	): NotificationStatus {
		return courseRules.findIndex((cr) => cr.name === r.name) >= 0
			? "on"
			: "off";
	}

	return (
		<Paper>
			<Stack paddingY={{xs: 1, sm: 2}}>
				<Typography variant="h6" sx={{ml: 2, mb: 2}}>
					<Localized id="course-settings-notifications-title">
						Notifications
					</Localized>
				</Typography>
				{allRules.map((r) => {
					if (editorOpen === r.name) {
						return (
							<Box key={r.name}>
								<Editor
									courseName={course.name}
									initialStatus={notificationStatus(r)}
									warning={warning(r)}
									rule={r}
									onSave={(status) => save(r.name, status)}
									onCancel={() => {
										setEditorOpen("");
									}}
								/>
							</Box>
						);
					}

					return (
						<NotificationRuleRow
							key={r.name}
							rule={r}
							status={notificationStatus(r)}
							warning={warning(r)}
							viewOnly={props.viewOnly}
							onEdit={() => setEditorOpen(r.name)}
						/>
					);
				})}
			</Stack>
		</Paper>
	);
}

function NotificationRuleRow(props: {
	rule: NotificationRuleDefinition;
	status: NotificationStatus;
	warning?: React.ReactNode;
	viewOnly?: boolean;
	onEdit: () => void;
}) {
	const {rule} = props;

	let status;
	if (props.status === "on") {
		status = (
			<Typography color="primary" textTransform="uppercase">
				<Localized id="course-settings-notifications-status-enabled">
					on
				</Localized>
			</Typography>
		);
	} else {
		status = (
			<Typography textTransform="uppercase">
				<Localized id="course-settings-notifications-status-disabled">
					off
				</Localized>
			</Typography>
		);
	}

	return (
		<Stack
			direction="row"
			spacing={2}
			padding={2}
			sx={(theme) => ({
				"&:hover": {
					backgroundColor: theme.palette.action.hover,
				},
			})}
		>
			<Grid container spacing={{xs: 1, sm: 2}} flexGrow={1}>
				<Grid size={{xs: 12, sm: 6, md: 4}} container>
					<Stack
						direction="row"
						spacing={3}
						flexGrow={1}
						justifyContent="space-between"
					>
						<Typography fontWeight="fontWeightMedium">
							<Localized
								id="course-settings-notifications-notification-title"
								vars={{name: rule.name}}
							/>
						</Typography>
						<Box sx={{display: {xs: "block", sm: "none"}}}>{status}</Box>
					</Stack>
				</Grid>
				<Grid size={{xs: 12, sm: 6, md: 8}}>
					<Stack spacing={1}>
						<Box sx={{display: {xs: "none", sm: "block"}}}>{status}</Box>
						{props.status === "on" && props.warning && (
							<Stack direction="row" spacing={1} alignItems="center">
								<WarningIcon color="warning" fontSize="small" />
								<Typography>{props.warning}</Typography>
							</Stack>
						)}
					</Stack>
				</Grid>
			</Grid>
			<Box
				style={{marginTop: -12}}
				sx={{display: props.viewOnly ? "none" : "block"}}
			>
				<IconButtonWithTooltip
					tooltipTitle={
						<Localized id="course-settings-notifications-action-edit">
							Edit
						</Localized>
					}
					onClick={props.onEdit}
				>
					<EditIcon />
				</IconButtonWithTooltip>
			</Box>
		</Stack>
	);
}

function Editor(props: {
	courseName: string;
	initialStatus: NotificationStatus;
	rule: NotificationRuleDefinition;
	warning?: React.ReactNode;
	onSave: (status: NotificationStatus) => Promise<void>;
	onCancel: () => void;
}) {
	const {rule} = props;

	const initial = props.initialStatus === "on";
	const [enabled, setEnabled] = useState(initial);

	const [dirty, setDirty] = useState(false);
	const [saving, setSaving] = useState(false);

	async function save() {
		setSaving(true);

		try {
			await props.onSave(enabled ? "on" : "off");
			setDirty(false);
		} finally {
			setSaving(false);
		}
	}

	return (
		<Paper sx={{marginX: {xs: -1, sm: -2}}} elevation={6}>
			<Stack>
				<Grid
					container
					spacing={{xs: 1, sm: 2}}
					flexGrow={1}
					py={2}
					pl={{xs: 3, sm: 4}}
					pr={{xs: 3, sm: 0}}
				>
					<Grid size={{xs: 12, sm: 6, md: 4}} container>
						<Typography fontWeight="fontWeightMedium">
							<Localized
								id="course-settings-notifications-notification-title"
								vars={{name: rule.name}}
							/>
						</Typography>
					</Grid>
					<Grid size={{xs: 12, sm: 6, md: 8}} ml={{xs: 0, sm: -6, md: -4}}>
						<Stack spacing={2}>
							<span>
								<FormControlLabel
									control={
										<Checkbox
											checked={enabled}
											onChange={({target}) => {
												setEnabled(target.checked);
												setDirty(target.checked !== initial);
											}}
										/>
									}
									label={
										<Localized id="course-settings-notifications-editor-label-enabled">
											Enabled
										</Localized>
									}
								/>
							</span>
							{enabled && props.warning && (
								<Stack direction="row" spacing={1} alignItems="center">
									<WarningIcon color="warning" fontSize="small" />
									<Typography>{props.warning}</Typography>
								</Stack>
							)}
							<Typography>
								<Localized
									id="course-settings-notifications-notification-description"
									vars={{name: rule.name}}
								/>
							</Typography>
							<MessageTabs courseName={props.courseName} rule={rule} />
						</Stack>
					</Grid>
				</Grid>
				<Stack
					direction="row"
					spacing={1}
					justifyContent="flex-end"
					padding={1}
				>
					<Button color="primary" disabled={saving} onClick={props.onCancel}>
						<Localized id="course-settings-notifications-editor-action-cancel">
							Cancel
						</Localized>
					</Button>
					<SubmitButton
						variant="text"
						color="primary"
						disabled={!dirty}
						inProgress={saving}
						onClick={save}
					>
						<Localized id="course-settings-notifications-editor-action-save">
							Save
						</Localized>
					</SubmitButton>
				</Stack>
			</Stack>
		</Paper>
	);
}

function MessageTabs(props: {
	courseName: string;
	rule: NotificationRuleDefinition;
}) {
	const [selectedTab, setSelectedTab] = useState(0);

	const eng = getNotificationMessage(
		props.courseName,
		props.rule.name,
		"english"
	);

	const fin = getNotificationMessage(
		props.courseName,
		props.rule.name,
		"finnish"
	);

	return (
		<Stack spacing={3}>
			<Tabs
				value={selectedTab}
				onChange={(_, index) => setSelectedTab(index)}
				variant="scrollable"
			>
				<Tab
					label={
						<Localized id="course-settings-notifications-editor-tab-english">
							English
						</Localized>
					}
					disableRipple
				/>
				<Tab
					label={
						<Localized id="course-settings-notifications-editor-tab-finnish">
							Finnish
						</Localized>
					}
					disableRipple
				/>
			</Tabs>

			<TabPanel value={0} index={selectedTab}>
				<MessageViewer subject={eng.subject} body={eng.body} />
			</TabPanel>
			<TabPanel value={1} index={selectedTab}>
				<MessageViewer subject={fin.subject} body={fin.body} />
			</TabPanel>
		</Stack>
	);
}

function MessageViewer(props: {subject: string; body: string}) {
	return (
		<Stack spacing={3}>
			<TextField
				fullWidth
				variant="outlined"
				label={
					<Localized id="course-settings-notifications-editor-message-label-subject">
						Subject
					</Localized>
				}
				value={props.subject}
				slotProps={{
					input: {
						readOnly: true,
					},
				}}
			/>
			<TextField
				fullWidth
				variant="outlined"
				multiline
				rows={10}
				label={
					<Localized id="course-settings-notifications-editor-message-label-body">
						Body
					</Localized>
				}
				value={props.body}
				slotProps={{
					input: {
						readOnly: true,
					},
				}}
			/>
		</Stack>
	);
}

function TabPanel(props: {
	children?: React.ReactNode;
	index: number;
	value: number;
}) {
	const {children, value, index} = props;

	return <div hidden={value !== index}>{children}</div>;
}

export default NotificationsSettings;
