enum ExerciseType {
	External = "external",
	Math = "math",
	Multi = "multi",
	Open = "open",
	Prog = "prog",
	Short = "short",
	Theory = "theory",
}

export default ExerciseType;
