import {axiosInstance as client} from "./axiosInstance";

export const bulletinBoardService = {
	getUnreadAnnouncementsNumber: async (
		userId: number,
		courseId: number
	): Promise<number> => {
		const {data} = await client.get<number>(
			`/api/users/${userId}/courses/${courseId}/unread-announcements-number`
		);

		return data;
	},
};
