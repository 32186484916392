import {createAsyncThunk, unwrapResult} from "@reduxjs/toolkit";

import makeSelectChapterStructure from "../chapters/makeSelectChapterStructure";
import updateChapterStructure from "../chapters/updateChapterStructure";
import {keyProvider} from "../keyProvider";
import {AppDispatch, RootState} from "../store";

const selectChapterStructure = makeSelectChapterStructure();

const deleteSection = createAsyncThunk<
	{removedSectionKey: string; removedExerciseKeys: string[]},
	{courseId: number; sectionKey: string},
	{dispatch: AppDispatch; state: RootState}
>("chapters/deleteSection", async function (params, thunkApi) {
	const courseId = params.courseId;
	const sectionKey = params.sectionKey;

	const state = thunkApi.getState();

	const section = state.sections.byKey[sectionKey]?.section;
	if (!section) {
		throw new Error(`Section ${sectionKey} is not found`);
	}

	const chapterId = section.chapterId;
	const chapterKey = keyProvider.chapter(chapterId);

	const structure = [...selectChapterStructure(state, chapterKey)];

	const i = structure.findIndex((s) => s.sectionKey === sectionKey);
	if (i < 0) {
		throw new Error(`Section ${sectionKey} is not found`);
	}

	const exerciseKeys = structure[i].subsectionKeys;

	structure.splice(i, 1);

	const result = await thunkApi.dispatch(
		updateChapterStructure({courseId, chapterId, structure})
	);
	unwrapResult(result);

	return {
		removedSectionKey: sectionKey,
		removedExerciseKeys: [section.exercise, ...exerciseKeys],
	};
});

export default deleteSection;
