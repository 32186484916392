import {Localized} from "@fluent/react";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import React from "react";

import {addToDate} from "../../helpers/dateTimeHelpers";

type CourseStatus = "past" | "ongoing" | "upcoming";

function generateDayTriple() {
	const today = new Date().toISOString();
	const yesterday = addToDate(today, {days: -1});
	const tomorrow = addToDate(today, {days: 1});

	return [yesterday, today, tomorrow].map((d) => d.slice(0, 10));
}

type Dates = {
	startDateAfter?: string;
	startDateBefore?: string;
	endDateAfter?: string;
	endDateBefore?: string;
};

function CourseStatusSelector(props: {
	dates: Dates;
	onChange: (d: Dates) => void;
}) {
	const {dates} = props;

	function selectDates(status: CourseStatus[]) {
		const dates = selectDatesByStatuses(status);
		props.onChange(dates);
	}

	return (
		<ToggleButtonGroup
			value={defineSelectedStatuses(
				dates.startDateAfter,
				dates.startDateBefore,
				dates.endDateAfter,
				dates.endDateBefore
			)}
			size="small"
			onChange={(_, val) => {
				selectDates(val);
			}}
		>
			<ToggleButton value="past">
				<Localized id="filters-course-status-past">Past</Localized>
			</ToggleButton>
			<ToggleButton value="ongoing">
				<Localized id="filters-course-status-ongoing">Ongoing</Localized>
			</ToggleButton>
			<ToggleButton value="upcoming">
				<Localized id="filters-course-status-upcoming">Upcoming</Localized>
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

function selectDatesByStatuses(status: CourseStatus[]) {
	if (status.length === 0 || status.length === 3) {
		return {
			startDateAfter: undefined,
			startDateBefore: undefined,
			endDateAfter: undefined,
			endDateBefore: undefined,
		};
	}

	if (status.includes("past") && status.includes("upcoming")) {
		status = [status[1]];
	}

	const [yesterday, today, tomorrow] = generateDayTriple();

	let startDateAfter: string | undefined;
	let startDateBefore: string | undefined;
	let endDateAfter: string | undefined;
	let endDateBefore: string | undefined;

	switch (true) {
		case status.includes("past") && status.includes("ongoing"):
			startDateBefore = today;
			break;
		case status.includes("ongoing") && status.includes("upcoming"):
			endDateAfter = today;
			break;
		case status.includes("past"):
			endDateBefore = yesterday;
			break;
		case status.includes("upcoming"):
			startDateAfter = tomorrow;
			break;
		case status.includes("ongoing"):
			startDateBefore = today;
			endDateAfter = today;
			break;
	}

	return {
		startDateAfter,
		startDateBefore,
		endDateAfter,
		endDateBefore,
	};
}

function defineSelectedStatuses(
	startDateFrom: string | undefined,
	startDateTo: string | undefined,
	endDateFrom: string | undefined,
	endDateTo: string | undefined
) {
	const [yesterday, today, tomorrow] = generateDayTriple();

	const status: CourseStatus[] = [];

	if ((startDateFrom || startDateTo) && (endDateFrom || endDateTo)) {
		if (
			startDateTo === today &&
			endDateFrom === today &&
			!startDateFrom &&
			!endDateTo
		) {
			status.push("ongoing");
		}
	} else if (startDateFrom || startDateTo) {
		if (startDateTo === today && !startDateFrom) {
			status.push("past", "ongoing");
		} else if (startDateFrom === tomorrow && !startDateTo) {
			status.push("upcoming");
		}
	} else if (endDateFrom || endDateTo) {
		if (endDateFrom === today && !endDateTo) {
			status.push("ongoing", "upcoming");
		} else if (endDateTo === yesterday && !endDateFrom) {
			status.push("past");
		}
	} else {
		status.push("past", "ongoing", "upcoming");
	}

	return status;
}

export default CourseStatusSelector;
