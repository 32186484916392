import {Localized} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import {
	AppBar,
	Box,
	Fab,
	Grid,
	Tab,
	Tabs,
	Toolbar,
	Tooltip,
	Zoom,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import DashboardHeader from "./DashboardHeader";
import StudentDashboard from "./StudentDashboard";
import TeacherDashboard from "./TeacherDashboard";
import {useAppSelector} from "../../store/hooks";
import UserRole from "../../store/models/UserRole";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import selectUserRolesInOrganisation from "../../store/organisation/selectUserRolesInOrganisation";
import useMobileMode from "../../hooks/useMobileMode";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 2048,
		margin: theme.spacing(0, 7.5, 6),
		[theme.breakpoints.down("sm")]: {
			margin: theme.spacing(3),
		},
	},
	tabBar: {
		position: "sticky",
		top: theme.mixins.toolbar.minHeight,
	},
}));

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props;

	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && children}
		</div>
	);
}

const Dashboard = (props: {organisationName: string}): JSX.Element => {
	const classes = useStyles();

	const roles = useAppSelector(selectUserRolesInOrganisation);
	const {firstName, userName} = useAppSelector((state) =>
		selectUserProfile(state)
	);

	const rolesWithDashboard = getRolesWithDashboard(roles);

	const [selectedTab, setSelectedTab] = useState(() =>
		rolesWithDashboard.includes(UserRole.Teacher) ? 1 : 0
	);

	return (
		<>
			<DashboardHeader userDisplayName={firstName || userName} />
			{rolesWithDashboard.length > 1 && (
				<AppBar className={classes.tabBar} elevation={0}>
					<Toolbar>
						<Grid container direction="column" alignItems="center">
							<Grid item>
								<Tabs
									indicatorColor="secondary"
									textColor="inherit"
									value={selectedTab}
									onChange={(_, val) => setSelectedTab(val)}
								>
									<Tab
										label={
											<Localized id="dashboard-student-tab-label">
												Student
											</Localized>
										}
									/>
									<Tab
										label={
											<Localized id="dashboard-teacher-tab-label">
												Teacher
											</Localized>
										}
									/>
								</Tabs>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
			)}

			{rolesWithDashboard.length > 0 && (
				<Box className={classes.root}>
					<NewCourseButton hidden={selectedTab !== 1} />

					<TabPanel value={selectedTab} index={0}>
						<StudentDashboard organisationName={props.organisationName} />
					</TabPanel>

					<TabPanel value={selectedTab} index={1}>
						<TeacherDashboard organisationName={props.organisationName} />
					</TabPanel>
				</Box>
			)}
		</>
	);
};

const useNewCourseButtonsStyles = makeStyles((theme) => ({
	fabs: {
		zIndex: theme.zIndex.drawer + 3,
		margin: theme.spacing(-3, 0, 3, -5),
		position: "sticky",
		top: theme.spacing(9),

		[theme.breakpoints.down("sm")]: {
			margin: 0,
			position: "fixed",
			top: "unset",
			bottom: theme.spacing(3),
			right: theme.spacing(9),
		},
	},
	positioned: {
		position: "absolute",
		top: theme.spacing(-0.5),
		left: 0,

		[theme.breakpoints.down("sm")]: {
			top: 0,
		},
	},
	extendedIcon: {
		marginRight: theme.spacing(1.5),
	},
}));

function NewCourseButton(props: {hidden?: boolean}) {
	const {hidden} = props;

	const classes = useNewCourseButtonsStyles();
	const mobileMode = useMobileMode("md");

	return (
		<Box width={0} className={classes.fabs}>
			<Zoom in={!hidden && !mobileMode}>
				<Fab
					variant="extended"
					color="secondary"
					component={Link}
					to="/courses/new"
					style={{whiteSpace: "nowrap"}}
				>
					<AddIcon className={classes.extendedIcon} />
					<Localized id="dashboard-teacher-new-course">New course</Localized>
				</Fab>
			</Zoom>

			<Zoom in={!hidden && mobileMode}>
				<Tooltip
					title={
						<Localized id="dashboard-teacher-new-course">New course</Localized>
					}
				>
					<Fab
						variant="circular"
						color="secondary"
						className={classes.positioned}
						component={Link}
						to="/courses/new"
					>
						<AddIcon />
					</Fab>
				</Tooltip>
			</Zoom>
		</Box>
	);
}

function getRolesWithDashboard(roles: UserRole[]) {
	const rolesWithDashboard = [UserRole.Student, UserRole.Teacher];

	return replaceTutor(roles).filter((role) =>
		rolesWithDashboard.includes(role)
	);
}

function replaceTutor(roles: UserRole[]): UserRole[] {
	const replaced = roles.map((role) =>
		role === UserRole.Tutor ? UserRole.Teacher : role
	);
	return replaced.filter((role, i) => replaced.indexOf(role) === i);
}

export default Dashboard;
