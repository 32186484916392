import {Localized} from "@fluent/react";
import {Box, LinearProgress, Typography} from "@mui/material";
import React from "react";

import ButtonsChaptersManage from "./ButtonsChaptersManage";
import TreeDragAndDrop from "../dragdrop/TreeDragAndDrop";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectCourseEditing from "../../store/ui/selectCourseEditing";
import selectAnyChapterEditing from "../../store/ui/selectAnyChapterEditing";

function LearningMaterialManage(props: {
	courseId: number;
	width: string;
	maxWidth: number | string;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
}): JSX.Element {
	const courseKey = keyProvider.course(props.courseId);

	const courseEditing = useAppSelector((state) =>
		selectCourseEditing(state, courseKey)
	);
	const contentEditing = useAppSelector((state) =>
		selectAnyChapterEditing(state, courseKey)
	);

	return (
		<AppBarDependentDrawer
			open
			variant="permanent"
			width={props.width}
			maxWidth={props.maxWidth}
			anchor="left"
			topFixedPanel={
				props.viewOnly ? (
					<Box my={2} ml={2} mr={0.5}>
						<Typography variant="h6">
							<Localized id="learning-material-navigation-header">
								Table of contents
							</Localized>
						</Typography>
					</Box>
				) : (
					<>
						<ButtonsChaptersManage
							courseId={props.courseId}
							courseEditing={courseEditing}
							contentEditing={contentEditing}
						/>
						<Box
							visibility={
								courseEditing || contentEditing ? "visible" : "hidden"
							}
						>
							<LinearProgress />
						</Box>
					</>
				)
			}
		>
			<Box mx={props.viewOnly ? 2 : 0}>
				<TreeDragAndDrop
					courseId={props.courseId}
					viewOnly={props.viewOnly}
					openConfirmDialog={props.openConfirmDialog}
				/>
			</Box>
		</AppBarDependentDrawer>
	);
}

export default LearningMaterialManage;
