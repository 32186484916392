import {useLocalization} from "@fluent/react";
import {Button} from "@mui/material";
import {Box, InputAdornment, Paper, TextField} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import React, {useRef, useState} from "react";
import type {KeyboardEvent} from "react";

import {Button as ButtonInfo, buttons} from "./buttons";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			width: "100%",
			padding: theme.spacing(0.5),
			background: theme.palette.background.default,
			borderRadius: "0 4px 4px 4px",
		},
		display: {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(0.5),
			padding: theme.spacing(0.25),
		},
		keyboard: {
			display: "grid",
			gridTemplateColumns: "repeat(5, 1fr)",
			padding: theme.spacing(0.25),
			rowGap: theme.spacing(0.5),
			columnGap: theme.spacing(0.5),
		},

		normal: {
			backgroundColor: theme.palette.background.paper,
			"&:hover": {
				backgroundColor: alpha(theme.palette.grey[300], 0.6),
			},
			textTransform: "none",
		},
		storage: {
			backgroundColor: alpha(theme.palette.grey[300], 0.9),
			"&:hover": {
				backgroundColor: alpha(theme.palette.grey[300], 0.6),
			},
			textTransform: "none",
		},
		function: {
			backgroundColor: alpha(theme.palette.primary.dark, 0.4),
			"&:hover": {
				backgroundColor: alpha(theme.palette.primary.dark, 0.7),
			},
			textTransform: "none",
		},
		operation: {
			backgroundColor: alpha(theme.palette.primary.light, 0.7),
			"&:hover": {
				backgroundColor: alpha(theme.palette.primary.light, 0.9),
			},
			textTransform: "none",
		},
		available: {
			backgroundColor: alpha(theme.palette.secondary.main, 0.7),
			"&:hover": {
				backgroundColor: alpha(theme.palette.secondary.main, 0.9),
			},
			textTransform: "none",
		},
		action: {
			backgroundColor: alpha(theme.palette.error.main, 0.4),
			"&:hover": {
				backgroundColor: alpha(theme.palette.error.main, 0.5),
			},
			textTransform: "none",
		},
		invisible: {
			visibility: "hidden",
		},
	})
);

const evalExpressions: {expr: string; evalExpr: string}[] = [
	{expr: "sin(", evalExpr: "trigo(Math.sin,"},
	{expr: "cos(", evalExpr: "trigo(Math.cos,"},
	{expr: "tan(", evalExpr: "trigo(Math.tan,"},
	{expr: "asin(", evalExpr: "invTrigo(Math.asin,"},
	{expr: "acos(", evalExpr: "invTrigo(Math.acos,"},
	{expr: "atan(", evalExpr: "invTrigo(Math.atan,"},

	{expr: "log", evalExpr: "Math.log10"},
	{expr: "ln", evalExpr: "Math.log"},

	{expr: "^", evalExpr: "**"},
	{expr: "√", evalExpr: "Math.sqrt"},

	{expr: "×", evalExpr: "*"},
	{expr: "–", evalExpr: "-"},
	{expr: "÷", evalExpr: "/"},

	{expr: "π", evalExpr: "Math.PI"},
	{expr: "e", evalExpr: "Math.E"},
];

const Calculator = (): JSX.Element => {
	const classes = useStyles();

	const result = useRef<HTMLInputElement>(null);
	const expression = useRef<HTMLInputElement>(null);

	const [memory, setMemory] = useState<string | null>(null);
	const [answer, setAnswer] = useState<string | null>(null);

	const [meminAvailable, setMeminAvailable] = useState(false);
	const [memoutAvailable, setMemoutAvailable] = useState(false);
	const [ansAvailable, setAnsAvailable] = useState(false);

	const {l10n} = useLocalization();

	function tokenise(expr: string) {
		const tokens = [];
		const tokenRegExp = /\s*((?:sin\(|asin\(|cos\(|acos\(|tan\(|atan\(|log|ln)|[0-9]+|\S)\s*/g;

		let match;
		while ((match = tokenRegExp.exec(expr)) !== null) {
			tokens.push(match[1]);
		}

		return tokens;
	}

	function replaceNonEvalExpressions(tokens: string[]) {
		for (let i = 0; i < tokens.length; i++) {
			const replacement = evalExpressions.find((f) => f.expr === tokens[i]);
			if (replacement) {
				tokens[i] = replacement.evalExpr;
			}
		}

		return tokens;
	}

	function evalExpression(): number {
		let expr = expression.current?.value ?? "";

		expr = expr.replace(/\s/g, "");
		expr = expr.replace(/ANS/g, "(" + answer + ")");

		const tokens = tokenise(expr);
		const jsTokens = replaceNonEvalExpressions(tokens);
		const jsExpr = jsTokens.join("");

		return eval(jsExpr);
	}

	function tryEvalExpression() {
		let calculated = false;
		if (expression.current?.value) {
			try {
				const result = evalExpression();
				if (!isNaN(result)) {
					calculated = true;
				}
			} catch {
				calculated = false;
			}
		}

		if (calculated) {
			setMeminAvailable(true);
		} else {
			setMeminAvailable(false);
		}
	}

	function formatInput(addMissingBrackets: boolean, cursorPos: number) {
		if (!expression.current) {
			return;
		}

		let value = expression.current.value;

		if (addMissingBrackets) {
			let counter = 0;
			for (let i = 0; i < value.length && counter >= 0; i++) {
				if (value[i] === "(") {
					counter++;
				} else if (value[i] === ")") {
					counter--;
				}
			}

			if (counter > 0) {
				value += ")".repeat(counter);
			}
		}

		value = value.replace(/([0-9)eπS])([Asctlaeπ(√])/g, "$1×$2");
		value = value.replace(/\*/g, "×");
		value = value.replace(/-/g, "–");
		value = value.replace(/\//g, "÷");
		value = value.replace(/,/g, ".");
		value = value.replace(/[^0-9sincotalgeπ().+–×÷√^ANS]/g, "");

		expression.current.value = value;
		expression.current.selectionStart = value.length - cursorPos;
		expression.current.selectionEnd = expression.current.selectionStart;

		expression.current.focus();

		tryEvalExpression();
	}

	function addInput(expr: string) {
		if (!expression.current) {
			return;
		}

		expr = expr.toString();

		const selectionStart = expression.current.selectionStart ?? 0;
		const value = expression.current.value;

		expression.current.value = [
			value.slice(0, selectionStart),
			expr,
			value.slice(selectionStart),
		].join("");

		const newCursor =
			expression.current.value.length - selectionStart - expr.length;

		formatInput(false, newCursor);
	}

	function formatResult(value: number, addBrackets: boolean) {
		const precision = Math.abs(value) < 0.01 ? 7 : 12;

		let precisedValue = parseFloat(value.toPrecision(precision)).toString();

		const expMatch = precisedValue.match(/([^e]*)e([+-])(\d+)$/);

		if (expMatch !== null) {
			const base = parseFloat(expMatch[1]);
			const exponent = parseInt(expMatch[2] + expMatch[3]);
			precisedValue = base + "×10^" + exponent;

			if (addBrackets) {
				precisedValue = "(" + precisedValue + ")";
			}
		}

		return precisedValue;
	}

	function calculateResult() {
		if (!result.current) {
			return;
		}

		formatInput(true, 0);

		let value = Number.NaN;
		try {
			value = evalExpression();
			setAnswer(formatResult(value, true));
		} catch (e) {
			//
		}

		if (isNaN(value)) {
			setAnsAvailable(false);
			result.current.value = l10n.getString(
				"calculator-check-input",
				null,
				"Check input"
			);
		} else {
			setAnsAvailable(true);
			result.current.value = formatResult(value, false);
		}
	}

	function hanleButtonClick(button: ButtonInfo) {
		if (!result.current || !expression.current) {
			return;
		}

		if (button.type === "input") {
			const expr = button.expression ?? "";
			addInput(expr);
		} else if (button.id === "memin") {
			if (meminAvailable) {
				try {
					setMemory(formatResult(evalExpression(), true));
					setMemoutAvailable(true);
				} catch {
					setMemory(null);
					setMemoutAvailable(false);
					tryEvalExpression();
				}
			}
		} else if (button.id === "memout") {
			if (memory) {
				addInput(memory);
			}
		} else if (button.id === "ans") {
			if (ansAvailable) {
				addInput("ANS");
			}
		} else if (button.id === "delete") {
			const expr = expression.current.value;
			expression.current.value = expr.substring(0, expr.length - 1);

			formatInput(false, 0);
		} else if (button.id === "clear") {
			if (expression.current?.value === "") {
				result.current.value = "";
			} else {
				expression.current.value = "";
			}

			tryEvalExpression();
		} else if (button.id === "equals") {
			calculateResult();
		}
	}

	function handleKey(event: KeyboardEvent) {
		if (event.key === "Enter") {
			calculateResult();
		} else {
			const cursor =
				(expression.current?.value.length ?? 0) -
				(expression.current?.selectionStart ?? 0);

			formatInput(false, cursor);
		}
	}

	return (
		<Paper elevation={4} className={classes.root}>
			<Box className={classes.display}>
				<TextField
					inputRef={result}
					fullWidth
					InputProps={{
						readOnly: true,
						spellCheck: false,
						startAdornment: <InputAdornment position="start">=</InputAdornment>,
					}}
					inputProps={{style: {textAlign: "right"}}}
					variant="outlined"
					margin="dense"
					style={{marginTop: 0}}
				/>
				<TextField
					inputRef={expression}
					fullWidth
					InputProps={{
						spellCheck: false,
					}}
					inputProps={{style: {textAlign: "right"}, inputMode: "none"}}
					variant="outlined"
					margin="dense"
					onKeyUp={handleKey}
					style={{marginTop: 0}}
				/>
			</Box>
			<Box className={classes.keyboard}>
				{buttons.map((b) => (
					<Button
						size="small"
						variant="outlined"
						key={b.id}
						className={clsx(classes[b.class], {
							[classes.available]:
								(b.id === "memin" && meminAvailable) ||
								(b.id === "memout" && memoutAvailable) ||
								(b.id === "ans" && ansAvailable),
						})}
						onClick={() => hanleButtonClick(b)}
					>
						<span dangerouslySetInnerHTML={{__html: b.label}} />
					</Button>
				))}
			</Box>
		</Paper>
	);
};

export function trigo(
	callback: (value: number) => number,
	angle: number
): number {
	angle = (angle * Math.PI) / 180;

	if (callback === Math.tan) {
		if (angle === 1.5707963267948966 || angle === 4.71238898038469) {
			return Number.POSITIVE_INFINITY;
		}
	}

	return callback(angle);
}

export function invTrigo(
	callback: (value: number) => number,
	value: number
): number {
	return (callback(value) * 180) / Math.PI;
}

export default Calculator;
