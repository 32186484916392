import {Localized} from "@fluent/react";
import {Button, DialogActions, DialogContent, TextField} from "@mui/material";
import React, {useState} from "react";

import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function RenameTemplateDialog(props: {
	titleElementId: string;
	initial: string;
	onChange: (name: string) => Promise<void>;
	onClose: () => void;
}) {
	const [inProgress, setInProgress] = useState(false);

	const [name, setName] = useState(props.initial);
	const [empty, setEmpty] = useState(false);

	async function rename() {
		if (name === "") {
			setEmpty(true);
			return;
		}

		setInProgress(true);

		await props.onChange(name);

		setInProgress(false);
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				inProgress={inProgress}
				title={
					<Localized id="group-course-templates-rename-dialog-title">
						Rename template
					</Localized>
				}
				onClose={props.onClose}
			/>

			<DialogContent>
				<TextField
					value={name}
					required
					error={empty}
					fullWidth
					focused
					label={
						<Localized id="group-course-templates-rename-dialog-label-new-name">
							New name
						</Localized>
					}
					onChange={({target}) => {
						setName(target.value);
						setEmpty(false);
					}}
				/>
			</DialogContent>

			<DialogActions>
				<Button color="primary" disabled={inProgress} onClick={props.onClose}>
					<Localized id="group-course-templates-rename-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<SubmitButton
					variant="text"
					inProgress={inProgress}
					color="primary"
					onClick={rename}
				>
					<Localized id="group-course-templates-rename-dialog-action-rename">
						Rename
					</Localized>
				</SubmitButton>
			</DialogActions>
		</>
	);
}

export default RenameTemplateDialog;
