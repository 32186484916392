import {createSlice} from "@reduxjs/toolkit";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import {createChapterSession} from "./createChapterSession";
import fetchChapterSessions from "./fetchChapterSessions";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	sessions: string[];
	status: FetchStatus;
};

type SliceState = {
	byKey: {
		[key: string]: State | undefined;
	};
};

const initialState: SliceState = {
	byKey: {},
};

export const chapterSessionsSlice = createSlice({
	name: "chapterSessions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchChapterSessions.fulfilled, (state, {payload}) => {
			state.byKey[payload.chapterKey] = {
				sessions: payload.sessionKeys,
				status: "succeeded",
			};
		});
		builder.addCase(fetchChapterSessions.pending, (state, action) => {
			const key = keyProvider.chapter(action.meta.arg.chapterId);
			updateStatus(state, key, "pending");
		});
		builder.addCase(fetchChapterSessions.rejected, (state, action) => {
			const key = keyProvider.chapter(action.meta.arg.chapterId);
			updateStatus(state, key, inferFetchStatusFromError(action));
		});

		builder.addCase(createChapterSession.fulfilled, (state, {payload}) => {
			const chapterSession = state.byKey[payload.chapterKey];
			if (chapterSession) {
				chapterSession.sessions.unshift(payload.sessionKey);
			}
		});
	},
});

function updateStatus(state: SliceState, key: string, status: FetchStatus) {
	const s = state.byKey[key];
	if (!s) {
		state.byKey[key] = {
			sessions: [],
			status: status,
		};
	} else {
		s.status = status;
	}
}

export default chapterSessionsSlice.reducer;
