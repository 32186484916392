import {Localized} from "@fluent/react";
import {Typography} from "@mui/material";
import React from "react";

import type Chapter from "../../store/chapters/Chapter";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import type ChapterSession from "../../store/chapterSessions/ChapterSession";
import {CompletionStatus} from "../../store/outcomes/ExerciseOutcomes";
import type {ExerciseOutcomes} from "../../store/outcomes/ExerciseOutcomes";
import ProgressBar from "../../utils/ProgressBar";
import ExercisesCompletionProgress from "./ExercisesCompletionProgress";

const TestProgress = (props: {
	session: ChapterSession | null;
	test: Chapter;
	outcomes: ExerciseOutcomes[];
	totalMaxScore: number;
	exerciseCount: number;
}): JSX.Element => {
	const {outcomes, session, test, exerciseCount, totalMaxScore} = props;

	if (!session) {
		return (
			<ProgressBar
				theme="grey"
				leftLabel={
					<Typography variant="body2" color="textSecondary">
						<Localized id="course-studying-chapter-progress-label-not-started">
							Not started
						</Localized>
					</Typography>
				}
			/>
		);
	}

	if (
		test.submissionMode === SubmissionMode.Individual ||
		session.submissionTime
	) {
		const submitted = outcomes.filter(
			(o) => o.completionStatus === CompletionStatus.Complete
		);

		const earnedScore = submitted.reduce((prev, curr) => prev + curr.score, 0);

		return (
			<ProgressBar
				theme="secondary"
				value={submitted.length}
				maxValue={exerciseCount}
				leftLabel={
					<ExercisesCompletionProgress
						completed={submitted.length}
						total={exerciseCount}
					/>
				}
				rightLabel={
					<Typography variant="body2" color="textSecondary">
						<Localized
							id="course-studying-chapter-progress-label-score"
							vars={{earned: earnedScore, total: totalMaxScore}}
						>{`Score: ${earnedScore}/${totalMaxScore}`}</Localized>
					</Typography>
				}
			/>
		);
	}

	const answered = outcomes.reduce(
		(prev, curr) =>
			curr.completionStatus !== CompletionStatus.Unattempted ? ++prev : prev,
		0
	);

	return (
		<ProgressBar
			theme="grey"
			value={answered}
			maxValue={exerciseCount}
			leftLabel={
				<Typography variant="body2" color="textSecondary">
					<Localized
						id="course-studying-chapter-progress-label-answered"
						vars={{
							answered,
							total: exerciseCount,
						}}
					>
						{`Answered: ${answered}/${exerciseCount}`}
					</Localized>
				</Typography>
			}
		/>
	);
};

export default TestProgress;
