import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectSessionSectionContentStatus(
	state: RootState,
	sectionKey: string
): FetchStatus {
	return (
		state.chapterSessions.lastRequested.sections[sectionKey]?.contentStatus ??
		"none"
	);
}

export default selectSessionSectionContentStatus;
