import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

type ExerciseDialogParams = {
	courseId: number;
	chapterId: number;
	sectionId: number;
};

export enum ModalDialogName {
	AddExercise,
	ExerciseBank,
}

type ModalDialog = {
	dialogName: ModalDialogName;
	dialogParams: ExerciseDialogParams;
};

type State = {
	dialog: ModalDialog | null;
};

const initialState: State = {
	dialog: null,
};

export const modalDialogSlice = createSlice({
	name: "modalDialog",
	initialState,
	reducers: {
		openDialog: (state, action: {payload: ModalDialog}) => {
			const newDialog: ModalDialog = {
				dialogName: action.payload.dialogName,
				dialogParams: action.payload.dialogParams,
			};
			state.dialog = newDialog;
		},
		closeDialog: () => initialState,
	},
});

export const {openDialog, closeDialog} = modalDialogSlice.actions;

export default modalDialogSlice.reducer;

export const selectModalDialog = (state: RootState): ModalDialog | null => {
	return state.ui.modalDialog.dialog;
};
