import {keyProvider} from "../keyProvider";
import ChapterStructureType from "./ChapterStructureType";
import CourseType from "../courses/CourseType";
import SubmissionMode from "./SubmissionMode";
import type SelectionMode from "./SelectionMode";
import {SelectionModes} from "./SelectionMode";
import type AnswerVisibility from "../chapterExercises/AnswerVisibility";
import type FeedbackVisibility from "./FeedbackVisibility";

type Chapter = {
	id: number;
	courseId: number;
	number: number;
	title: string;
	startDate?: string;
	endDate?: string;
	submissionMode: SubmissionMode;
	selectionMode: SelectionMode;
	structureType: ChapterStructureType;
	sessionLimit?: number;
	timeLimit?: number;
	answerVisibility?: AnswerVisibility;
	feedbackVisibility: FeedbackVisibility;
};

function requiresExplicitSessionStart(
	courseType: CourseType,
	chapter: {submissionMode: SubmissionMode; selectionMode: SelectionMode}
): boolean {
	return (
		courseType === CourseType.Exam ||
		chapter.submissionMode === SubmissionMode.Simultaneous ||
		chapter.selectionMode === SelectionModes.Random
	);
}

const draftKey = keyProvider.chapter(0);

export default Chapter;
export {draftKey, requiresExplicitSessionStart};
