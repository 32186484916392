import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";

const ConfirmCloseDialog = (props: {
	open: boolean;
	onContinue: () => void;
	onLeave: () => void;
}): JSX.Element => {
	return (
		<Dialog open={props.open} maxWidth="sm" onClose={props.onContinue}>
			<DialogTitle>
				<Typography variant="h6" component="span">
					{"Are you sure?"}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">
					You have chosen exercises to add. If
					<br />
					you leave this page, your choice will be lost
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onLeave}>
					{"Leave the page"}
				</Button>
				<Button color="primary" onClick={props.onContinue}>
					{"Continue adding"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmCloseDialog;
