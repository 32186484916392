import {createAsyncThunk} from "@reduxjs/toolkit";

import authService from "../services/authService";
import sessionStore from "./sessionStore";

const logoutUser = createAsyncThunk<void, {logoutOnServer: boolean}>(
	"userSession/logout",
	async ({logoutOnServer}) => {
		try {
			if (logoutOnServer) {
				await authService.logout();
			}
		} finally {
			sessionStore.removeSession();
		}
	}
);

export default logoutUser;
