import {Box} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {ReactNode} from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& > :not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},
	})
);

function AdditionalActions({
	children,
}: {
	children?: ReactNode | ReactNode[];
}): JSX.Element {
	const classes = useStyles();

	return (
		<Box display="flex" justifyContent="flex-end" className={classes.root}>
			{children}
		</Box>
	);
}

export default AdditionalActions;
