import {styled} from "@mui/material";
import {TreeItem, treeItemClasses} from "@mui/x-tree-view";
import type {TreeItemProps} from "@mui/x-tree-view";

const CustomTreeItem = styled(TreeItem, {
	shouldForwardProp: (prop) => prop !== "clickable",
})<TreeItemProps & {clickable?: boolean}>(({theme, clickable}) => ({
	[`& .${treeItemClasses.content}`]: {
		padding: 0,
		marginBottom: theme.spacing(3),
		cursor: clickable ? "cursor" : "default",
	},
	[`& .${treeItemClasses.content}:hover, .${treeItemClasses.content}.Mui-focused`]: {
		backgroundColor: "inherit",
	},
	[`& .${treeItemClasses.groupTransition}`]: {
		paddingLeft: theme.spacing(2.5),
	},
}));

export default CustomTreeItem;
