import type {EditorSelection, Editor as TinyEditor} from "tinymce";

type SizeRatio = "21x9" | "16x9" | "4x3" | "1x1";

type InitialData = {
	source: string;
	sizingMode: SizeRatio | "inline";
	dimensions: {
		width: string | null;
		height: string | null;
	};
};

export const registerInsertIframe = (editor: TinyEditor): void => {
	editor.ui.registry.addIcon(
		"webAsset",
		`<svg height="24px" width="24px"><path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm0 14H5V8h14v10z"/></svg>`
	);
	editor.ui.registry.addToggleButton("iframe", {
		icon: "webAsset",
		tooltip: "Insert/edit iframe",
		onSetup: (button) => {
			const handler = () => {
				const responsiveSelected =
					editor.selection
						.getNode()
						?.hasAttribute("data-mce-p-data-media-embed") ?? false;
				button.setActive(responsiveSelected);
			};

			editor.on("NodeChange", handler);

			return () => {
				editor.off("NodeChange", handler);
			};
		},
		onAction: () => {
			const initialData = prepareInitialData(editor.selection);

			editor.windowManager.open({
				title: "Insert/Edit Iframe",
				body: {
					type: "panel",
					items: [
						{
							type: "input",
							name: "source",
							label: "Source",
						},
						{
							type: "selectbox",
							name: "sizingMode",
							items: [
								{value: "inline", text: "Inline value"},
								{value: "21x9", text: "Responsive - 21x9"},
								{value: "16x9", text: "Responsive - 16x9"},
								{value: "4x3", text: "Responsive - 4x3"},
								{value: "1x1", text: "Responsive - 1x1"},
							],
							label: "Size",
						},
						{
							type: "sizeinput",
							name: "dimensions",
							disabled: initialData.sizingMode !== "inline",
						},
					],
				},
				buttons: [
					{
						type: "cancel",
						name: "cancel",
						text: "Cancel",
					},
					{
						type: "submit",
						name: "save",
						text: "Save",
						primary: true,
						disabled: !initialData.source,
					},
				],
				onSubmit: (api) => {
					const data = api.getData();
					if (
						editor.selection
							.getNode()
							.parentElement?.classList.contains("media-viewer-container")
					) {
						// Delete media-viewer-container to replace it with a new one.
						editor.execCommand("Delete", false);
					}
					editor.execCommand(
						"mceReplaceContent",
						false,
						data.sizingMode === "inline"
							? createIframe(
									data.source,
									data.dimensions.width,
									data.dimensions.height
							  )
							: createResponsiveIframe(data.source, data.sizingMode)
					);
					api.close();
				},
				onChange: (api, detail) => {
					const data = api.getData();
					switch (detail.name) {
						case "source":
							if (!data.source) {
								api.disable("save");
							} else {
								api.enable("save");
							}
							break;

						case "sizingMode":
							if (data.sizingMode === "inline") {
								api.enable("dimensions");
							} else {
								api.disable("dimensions");
							}
							break;

						default:
							break;
					}
				},
				initialData,
			});
		},
	});

	editor.on("BeforeObjectSelected", ({target}) => {
		if (
			target.hasAttribute("data-mce-p-data-media-embed") &&
			target.getAttribute("data-mce-resize") !== "false"
		) {
			editor.dom.setAttrib(target, "data-mce-resize", "false");
		}
	});
};

function prepareInitialData(selection: EditorSelection) {
	const node = selection.getNode();

	const initialData: InitialData = {
		source: "",
		sizingMode: "inline",
		dimensions: {width: "350px", height: "260px"},
	};

	if (
		node.hasAttribute("data-mce-p-data-media-embed") &&
		node.hasAttribute("data-mce-selected")
	) {
		// The selected element is tinymce's wrapper (mce-preview-object)
		// for our embedded iframe (that has data-media-embed attribute).
		const iframe = node.firstElementChild;

		initialData.dimensions = {
			width: iframe?.getAttribute("width") ?? "",
			height: iframe?.getAttribute("height") ?? "",
		};

		initialData.source = iframe?.getAttribute("src") ?? "";

		const parent = selection.getNode().parentElement;
		if (parent?.classList.contains("media-viewer-container")) {
			const match = parent.className.match(/\d+x\d+/);
			initialData.sizingMode = match ? (match[0] as SizeRatio) : "inline";
		}
	}
	return initialData;
}

function createIframe(
	source: string,
	width?: string | null,
	height?: string | null
) {
	return (
		`<iframe src="${source}" data-media-embed ` +
		(width ? `width="${width}" ` : "") +
		(height ? `height="${height}" ` : "") +
		`allowfullscreen="allowfullscreen"></iframe>`
	);
}

function createResponsiveIframe(source: string, ratio: SizeRatio) {
	return (
		`<span class="media-viewer-container media-viewer-container--${ratio}">` +
		`<iframe class="media-viewer" src="${source}" data-media-embed ` +
		`allowfullscreen="allowfullscreen"></iframe>` +
		`</span>`
	);
}
