import {createSelector} from "@reduxjs/toolkit";
import ExerciseType from "../exercises/ExerciseType";
import {keyProvider} from "../keyProvider";
import type {RootState} from "../store";

const empty: number[] = [];

function selectSubsectionIds(state: RootState, key: string): number[] {
	return state.sections.byKey[key]?.section?.subsectionIds ?? empty;
}

const selectNonTheorySubsectionIds = createSelector(
	[selectSubsectionIds, (state: RootState) => state.exercises.byKey],
	(subsectionIds, exercises) => {
		const ids = subsectionIds.filter((id) => {
			const subsection = exercises[keyProvider.exercise(id)];
			return subsection && subsection.type !== ExerciseType.Theory;
		});

		return ids.length > 0 ? ids : empty;
	}
);

export default selectSubsectionIds;
export {selectNonTheorySubsectionIds};
