import unreadAnnouncementsNumberChanged from "./bulletinBoard/unreadAnnouncementsNumberChanged";
import {AppDispatch} from "./store";

import {oldSiteOrigin} from "../helpers/pathHelpers";

function createOldSiteMessageListener(dispatch: AppDispatch) {
	return function (event: MessageEvent): void {
		if (event.origin !== oldSiteOrigin) {
			return;
		}

		switch (event.data.type) {
			case "unread-announcements-number":
				dispatch(
					unreadAnnouncementsNumberChanged({
						number: event.data.payload.number,
					})
				);

				return;
		}
	};
}

export default createOldSiteMessageListener;
