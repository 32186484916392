import {useCallback} from "react";

import type CourseManagementModule from "./CourseManagementModule";
import type {CourseManagementModuleName} from "./CourseManagementModule";
import type FetchStatus from "../FetchStatus";
import {useAppSelector} from "../hooks";
import {keyProvider} from "../keyProvider";
import selectCourseManagementModules, {
	selectCourseManagementModulesStatus,
} from "./selectCourseManagementModules";

function useAvailableManagementModules(
	courseId: number
): [
	CourseManagementModule[],
	(module: CourseManagementModuleName) => boolean,
	FetchStatus,
	boolean
] {
	const key = keyProvider.course(courseId);

	const modules = useAppSelector((state) =>
		selectCourseManagementModules(state, key)
	);

	const fetchStatus = useAppSelector((state) =>
		selectCourseManagementModulesStatus(state, key)
	);

	const available = useCallback(
		(module: CourseManagementModuleName) =>
			Boolean(modules.find((m) => m.name === module)),
		[modules]
	);

	const awaiting = fetchStatus === "none" || fetchStatus === "pending";

	return [modules, available, fetchStatus, awaiting];
}

export default useAvailableManagementModules;
