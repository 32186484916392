import {Localized} from "@fluent/react";
import {Button} from "@mui/material";
import React, {useState} from "react";

import parseDate from "../../helpers/parseDate";
import selectChapter from "../../store/chapters/selectChapter";
import fetchChapterSessions from "../../store/chapterSessions/fetchChapterSessions";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {resolveLinkToOldSite} from "../../helpers/pathHelpers";
import selectChapterSession from "../../store/chapterSessions/selectChapterSession";
import selectChapterSessionKeys from "../../store/chapterSessions/selectChapterSessionKeys";
import TestLayout from "./TestLayout";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import SubmitButton from "../../utils/SubmitButton";

export const OngoingTest = (props: {
	chapterKey: string;
	studentId: number;
	onSubmit: (id: number) => void;
	onStart: (id: number, endDate?: string) => Promise<void>;
	onOpenChapter: (id: number) => void;
}): JSX.Element => {
	const {chapterKey, studentId, onSubmit, onStart, onOpenChapter} = props;

	const test = useAppSelector((state) => selectChapter(state, chapterKey));

	const [sessionKeys, status] = useAppSelector((state) =>
		selectChapterSessionKeys(state, chapterKey)
	);

	const session = useAppSelector((state) =>
		sessionKeys.length > 0 ? selectChapterSession(state, sessionKeys[0]) : null
	);

	const dispatch = useAppDispatch();

	const [ended, setEnded] = useState(false);
	const [starting, setStarting] = useState(false);

	if (!test) {
		return <></>;
	}

	redirectIfStructureIsUnsupported(
		test.structureType,
		test.startDate,
		test.courseId
	);

	const requested = status !== "none";

	const handleExpanded = () => {
		if (!requested) {
			dispatch(
				fetchChapterSessions({
					studentId,
					courseId: test.courseId,
					chapterId: test.id,
				})
			);
		}
	};

	const openChapter = () => onOpenChapter(test.id);

	const start = async () => {
		setStarting(true);

		try {
			await onStart(test.id, test.endDate);
			setEnded(pastDate(test.endDate));
		} finally {
			setStarting(false);
		}
	};

	const createActions = () => {
		if (status !== "succeeded") {
			return null;
		}

		if (!session) {
			return (
				<SubmitButton onClick={start} inProgress={starting} disabled={ended}>
					<Localized id="course-studying-start-test-button">Start</Localized>
				</SubmitButton>
			);
		}

		if (session.submissionTime) {
			return (
				<Button variant="contained" color="primary" onClick={openChapter}>
					<Localized id="course-studying-review-test-button">Review</Localized>
				</Button>
			);
		}

		return (
			<>
				{test.submissionMode === SubmissionMode.Simultaneous && (
					<Button
						color="primary"
						onClick={() => {
							onSubmit(test.id);
							setEnded(pastDate(test.endDate));
						}}
						disabled={ended}
					>
						<Localized id="course-studying-submit-test-button">
							Submit
						</Localized>
					</Button>
				)}
				<Button color="primary" variant="contained" onClick={openChapter}>
					<Localized id="course-studying-continue-test-button">
						Continue
					</Localized>
				</Button>
			</>
		);
	};

	return (
		<TestLayout
			test={test}
			expandable
			sessionsMade={sessionKeys.length}
			actions={createActions()}
			onExpanded={handleExpanded}
		/>
	);
};

function pastDate(date?: string) {
	return Boolean(date && parseDate(date) < new Date());
}

export const UpcomingTest = (props: {chapterKey: string}): JSX.Element => {
	const {chapterKey} = props;

	const test = useAppSelector((state) => selectChapter(state, chapterKey));

	if (!test) {
		return <></>;
	}

	redirectIfStructureIsUnsupported(
		test.structureType,
		test.startDate,
		test.courseId
	);

	return <TestLayout test={test} expandable />;
};

export const PastTest = (props: {
	studentId: number;
	chapterKey: string;
	onOpenChapter: (id: number) => void;
}): JSX.Element => {
	const {chapterKey, onOpenChapter, studentId} = props;

	const test = useAppSelector((state) => selectChapter(state, chapterKey));

	const [sessionKeys, status] = useAppSelector((state) =>
		selectChapterSessionKeys(state, chapterKey)
	);

	const session = useAppSelector((state) =>
		sessionKeys.length > 0 ? selectChapterSession(state, sessionKeys[0]) : null
	);

	const dispatch = useAppDispatch();

	if (!test) {
		return <></>;
	}

	redirectIfStructureIsUnsupported(
		test.structureType,
		test.startDate,
		test.courseId
	);

	const requested = status !== "none";

	const handleExpanded = () => {
		if (!requested) {
			dispatch(
				fetchChapterSessions({
					studentId,
					courseId: test.courseId,
					chapterId: test.id,
				})
			);
		}
	};

	const createActions = () => {
		if (status !== "succeeded" || !session || !test.startDate) {
			return null;
		}

		return (
			<Button
				variant="contained"
				color="primary"
				onClick={() => onOpenChapter(test.id)}
			>
				<Localized id="course-studying-review-test-button">Review</Localized>
			</Button>
		);
	};

	return (
		<TestLayout
			test={test}
			expandable
			actions={createActions()}
			onExpanded={handleExpanded}
		/>
	);
};

function redirectIfStructureIsUnsupported(
	structureType: string,
	startDate: string | undefined,
	courseId: number
) {
	if (structureType !== "sections" && startDate) {
		window.location.replace(resolveLinkToOldSite(`/student/${courseId}/#/`));
	}
}
