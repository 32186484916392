import {createAsyncThunk} from "@reduxjs/toolkit";

import {organisationService} from "../services/organisationService";

const fetchOrganisation = createAsyncThunk(
	"organisations/fetchOrganisation",
	async (params: {organisationName: string}) => {
		const {organisationName} = params;

		const organisation = await organisationService.getOrganisation(
			organisationName
		);

		return organisation;
	}
);

export default fetchOrganisation;
