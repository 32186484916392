export function move<T>(list: T[], from: number, to: number): T[] {
	const result = [...list];
	const [removed] = result.splice(from, 1);
	result.splice(to, 0, removed);
	return result;
}

export function intersect<T>(arr1: T[], arr2: T[]): T[] {
	return arr1.filter((n) => arr2.includes(n));
}

export function equalArrays<T>(a1: T[], a2: T[]) {
	if (a1.length !== a2.length) {
		return false;
	}

	return a1.every((e) => a2.includes(e));
}
