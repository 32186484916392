import {createSlice} from "@reduxjs/toolkit";

import ExerciseAnswer from "../exercises/ExerciseAnswer";
import fetchExerciseAnswer from "../exercises/fetchExerciseAnswer";

type exerciseAnswersState = {
	byKey: {
		[key: string]: ExerciseAnswer | undefined;
	};
};

const initialState: exerciseAnswersState = {
	byKey: {},
};

export const exerciseAnswersSlice = createSlice({
	name: "exerciseAnswers",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchExerciseAnswer.fulfilled, (state, action) => {
			state.byKey[action.payload.key] = action.payload.answer;
		});
	},
});

export default exerciseAnswersSlice.reducer;
