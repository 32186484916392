import {Box, Typography, useTheme} from "@mui/material";
import React from "react";

import SuccessCircleIcon from "../../icons/SuccessCircleIcon";

const CompletionLabel = (props: {
	completed: number;
	total?: number;
}): JSX.Element => {
	const theme = useTheme();

	return (
		<Box display="flex">
			<SuccessCircleIcon
				fontSize="small"
				style={{marginRight: theme.spacing(1)}}
			/>
			<Typography variant="body2" color="textSecondary">
				{`${props.completed}/${props.total ?? "?"}`}
			</Typography>
		</Box>
	);
};

export default CompletionLabel;
