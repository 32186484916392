import {keyProvider} from "../keyProvider";
import type {RootState} from "../store";
import type CourseType from "./CourseType";
import selectCourse from "./selectCourse";

function selectCourseType(
	state: RootState,
	courseId: number
): CourseType | null {
	return selectCourse(state, keyProvider.course(courseId))?.type ?? null;
}

export default selectCourseType;
