import {createSlice} from "@reduxjs/toolkit";

import {draftKey} from "./Chapter";
import chapterDraftCreated from "./chapterDraftCreated";
import chapterDraftDeleted from "./chapterDraftDeleted";
import createChapter from "./createChapter";
import deleteChapter from "./deleteChapter";
import {getChapters} from "./getChapters";
import updateChaptersOrder from "./updateChaptersOrder";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import deleteCourses from "../courses/deleteCourses";

type State = {
	chapters: string[];
	status: FetchStatus;
};

type SliceState = {
	byKey: {
		[key: string]: State | undefined;
	};
};

const initialState: SliceState = {
	byKey: {},
};

export const courseChaptersSlice = createSlice({
	name: "courseChapters",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(createChapter.fulfilled, (state, action) => {
			const courseKey = keyProvider.course(action.payload.courseId);
			const keys = state.byKey[courseKey]?.chapters;

			if (keys) {
				const i = keys.indexOf(draftKey);
				if (i >= 0) {
					keys.splice(i, 1, keyProvider.chapter(action.payload.id));
				}
			}
		});

		builder.addCase(chapterDraftCreated, (state, action) => {
			const courseKey = keyProvider.course(action.payload.courseId);
			const keys = state.byKey[courseKey]?.chapters;

			if (keys) {
				keys.push(draftKey);
			}
		});

		builder.addCase(chapterDraftDeleted, (state, action) => {
			const courseKey = keyProvider.course(action.payload.courseId);
			const keys = state.byKey[courseKey]?.chapters;

			if (keys && keys.length > 0 && keys[keys.length - 1] === draftKey) {
				keys.pop();
			}
		});

		builder.addCase(deleteChapter.fulfilled, (state, action) => {
			const courseKey = keyProvider.course(action.payload.courseId);

			state.byKey[courseKey] = {
				chapters: action.payload.keys,
				status: "succeeded",
			};
		});

		builder.addCase(getChapters.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg);

			updateStatus(state, courseKey, "pending");
		});

		builder.addCase(getChapters.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg);

			updateStatus(state, courseKey, inferFetchStatusFromError(action));
		});

		builder.addCase(getChapters.fulfilled, (state, action) => {
			const courseKey = action.payload.result.courseKey;

			state.byKey[courseKey] = {
				chapters: action.payload.result.chapters,
				status: "succeeded",
			};
		});

		builder.addCase(updateChaptersOrder.fulfilled, (state, action) => {
			const courseKey = keyProvider.course(action.payload.courseId);

			state.byKey[courseKey] = {
				chapters: action.payload.chapterKeys,
				status: "succeeded",
			};
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				delete state.byKey[keyProvider.course(id)];
			});
		});
	},
});

function updateStatus(state: SliceState, key: string, status: FetchStatus) {
	const s = state.byKey[key];
	if (!s) {
		state.byKey[key] = {
			chapters: [],
			status: status,
		};
	} else {
		s.status = status;
	}
}

export default courseChaptersSlice.reducer;
