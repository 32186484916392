import {Localized} from "@fluent/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
	Box,
	Button,
	Grid,
	Hidden,
	Link,
	Typography,
	useTheme,
} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

import InterfaceLanguageSelector from "../../i18n/InterfaceLanguageSelector";
import {useLocaleChanger} from "../../i18n/LocalisationProvider";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import LoginLogo from "./LoginLogo";

function Copyright() {
	return (
		<Typography variant="body2" align="center" color="textSecondary">
			{"Viope Service © "}
			<Link href="https://viope.com/">Viope Education Oy</Link>
			{` 2001-${new Date().getFullYear()}`}
		</Typography>
	);
}

const PageLayout = (props: {
	children: React.ReactChild;
	logo: string;
	backRef?: string;
}): JSX.Element => {
	const theme = useTheme();

	const locale = useCurrentLocale();
	const changeLocale = useLocaleChanger();

	return (
		<Grid container style={{height: "100vh"}}>
			<Hidden only="xs">
				<Grid item sm={5} lg={6}>
					<LoginLogo logo={props.logo} />
				</Grid>
			</Hidden>
			<Grid
				item
				container
				xs={12}
				sm={7}
				lg={6}
				direction="column"
				justifyContent="space-between"
				style={{
					padding: theme.spacing(4),
					height: "inherit",
					overflow: "auto",
					flexWrap: "nowrap",
				}}
			>
				<Grid
					item
					style={{
						display: "flex",
						justifyContent: props.backRef ? "space-between" : "flex-end",
					}}
				>
					{props.backRef && (
						<Button
							color="primary"
							component={RouterLink}
							to={props.backRef}
							startIcon={<KeyboardBackspaceIcon />}
						>
							<Localized id="signup-back-btn">Back</Localized>
						</Button>
					)}
					<Box width="35%">
						<InterfaceLanguageSelector value={locale} onChange={changeLocale} />
					</Box>
				</Grid>
				<Grid item style={{margin: theme.spacing(3, 0, 5)}}>
					{props.children}
				</Grid>
				<Grid item>
					<Copyright />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PageLayout;
