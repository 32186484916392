import TutorQuestion from "../tutorQuestions/TutorQuestion";
import {axiosInstance as client} from "./axiosInstance";
import type ExerciseType from "../exercises/ExerciseType";

export type GeneralQuestion = {
	text: string;
};

export type ExerciseQuestion = {
	chapterId: number;
	exerciseId: number;
	exerciseType: ExerciseType;
	text: string;
};

interface StudentMessage {
	question: string;
	send_time: string;
	chapter_title?: string;
	exercise_title?: string;
	tutor_question_id: number;
	chapter_id?: number;
	exercise_template_id?: number;
	answer_time?: string;
	answer?: string;
	tutor_name?: string;
	sender_id?: number;
	student_name?: string;
	tutor_id?: number;
}

export const tutoringService = {
	getStudentMessages: async (
		studentId: number,
		courseId: number
	): Promise<{
		answered: TutorQuestion[];
		notAnswered: TutorQuestion[];
	}> => {
		const {data} = await client.get(
			`/api/students/${studentId}/courses/${courseId}/tutor-questions`
		);

		const answered = data.answered.map(mapToQuestion);
		const notAnswered = data.not_answered.map(mapToQuestion);

		return {answered, notAnswered};
	},

	sendQuestion: async (
		courseId: number,
		studentId: number,
		question: ExerciseQuestion | GeneralQuestion,
		attachmentIds?: number[]
	): Promise<number> => {
		const url = `/api/students/${studentId}/courses/${courseId}/tutor-questions`;

		let body;
		if ("chapterId" in question) {
			body = {
				action: "submit_question",
				question: question.text,
				exercise_type: question.exerciseType,
				chapter_id: question.chapterId,
				exercise_template_id: question.exerciseId,
				attachment_ids: attachmentIds,
			};
		} else {
			body = {
				action: "submit_question",
				question: question.text,
				exercise_type: "gen",
				attachment_ids: attachmentIds,
			};
		}

		const {data} = await client.post(url, body);

		if (!data.rows) {
			throw Error();
		}

		return data.tutor_question_id;
	},

	getTutorMessages: async (
		courseId: number
	): Promise<{
		answered: TutorQuestion[];
		notAnswered: TutorQuestion[];
	}> => {
		const {data} = await client.get(`/api/courses/${courseId}/tutor-questions`);

		const answered = data.answered.map(mapToQuestion);
		const notAnswered = data.not_answered.map(mapToQuestion);

		return {answered, notAnswered};
	},

	sendAnswer: async (
		courseId: number,
		questionId: number,
		answer: string
	): Promise<void> => {
		const {data} = await client.put(
			`/api/courses/${courseId}/tutor-questions/${questionId}/answer`,
			{
				tutor_question_id: questionId,
				answer,
				action: "submit_answer",
			}
		);

		if (!data.rows) {
			throw Error();
		}
	},
};

function mapToQuestion(message: StudentMessage): TutorQuestion {
	return {
		answerTime: message.answer_time,
		chapterId: message.chapter_id,
		exerciseId: message.exercise_template_id,
		id: message.tutor_question_id,
		question: message.question,
		sendTime: message.send_time,
		answer: message.answer,
		tutorName: message.tutor_name,
		studentId: message.sender_id,
		studentName: message.student_name,
		tutorId: message.tutor_id,
	};
}
