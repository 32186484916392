import {useMemo} from "react";
import useCurrentLocale from "./useCurrentLocale";

function useDateFormat(): (date: Date) => string {
	const locale = useCurrentLocale();

	const fmt = useMemo(() => {
		return new Intl.DateTimeFormat([locale], {dateStyle: "medium"});
	}, [locale]);

	return fmt.format;
}

export default useDateFormat;
