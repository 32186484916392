import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../store";
import selectSent from "./selectSent";

const selectSentByUser = createSelector(
	(state: RootState) => state.tutorQuestions.byKey,
	selectSent,
	(_: RootState, userId: number) => userId,
	(questions, sentKeys, userId) => {
		return sentKeys.filter((key) => questions[key].studentId === userId);
	}
);

export default selectSentByUser;
