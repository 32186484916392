import ChapterExercise from "../chapterExercises/ChapterExercise";
import {RootState} from "../store";

function selectChapterSessionExercise(
	state: RootState,
	chapterExerciseKey: string
): Readonly<ChapterExercise> | null {
	return (
		state.chapterSessions.lastRequested.sessionExercises[chapterExerciseKey] ??
		null
	);
}

export default selectChapterSessionExercise;
