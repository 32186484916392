import {StateEffect, StateField} from "@codemirror/state";
import {Decoration, EditorView} from "@codemirror/view";
import type {DecorationSet, Range} from "@codemirror/view";

export const highlightEffect = StateEffect.define<Range<Decoration>[]>();

export const removeHiglightEffect = StateEffect.define<
	(from?: number, to?: number) => boolean
>();

export const highlightLinesExtension = StateField.define({
	create() {
		return Decoration.none;
	},
	update(value: DecorationSet, transaction) {
		value = value.map(transaction.changes);

		for (const effect of transaction.effects) {
			if (effect.is(highlightEffect)) {
				value = value.update({add: effect.value, sort: true});
			} else if (effect.is(removeHiglightEffect)) {
				value = value.update({filter: effect.value});
			}
		}

		return value;
	},
	provide: (f) => EditorView.decorations.from(f),
});

export const highlightDecoration = (color: string): Decoration =>
	Decoration.line({
		attributes: {style: `background-color: ${color}`},
	});

export default highlightLinesExtension;
