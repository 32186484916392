import {createAsyncThunk} from "@reduxjs/toolkit";
import DocumentFormat from "../../utils/attachments/DocumentFormat";
import {keyProvider} from "../keyProvider";
import {contentService} from "../services/contentService";

const changeCoursePicture = createAsyncThunk(
	"courses/changeCoursePicture",
	async function (params: {courseId: number; file: File}) {
		const uploaded = await contentService.uploadCourseFile({
			...params,
			documentFormat: DocumentFormat.CourseInfoImage,
		});

		return {
			key: keyProvider.course(params.courseId),
			picture: uploaded.url.concat(`?${Math.random()}`),
			courseId: params.courseId,
		};
	}
);

export default changeCoursePicture;
