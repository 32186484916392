import {Localized} from "@fluent/react";
import {Tab} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";

import SubtitleNav from "../../components/nav/SubtitleNav";
import GroupUserOrganisations from "../../components/organisations/GroupUserOrganisations";
import Profile from "../../components/users/Profile";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import type User from "../../store/services/dtos/User";
import {userService} from "../../store/services/userService";
import LoadingError from "../../utils/errors/LoadingError";
import ObservableRoute from "../../utils/ObservableRoute";

const emptyUser: User = {
	id: 0,
	language: "",
	memberOfMultipleOrganisations: false,
	name: "",
	passwordSet: false,
};

function UserAccount(props: {groupName: string}) {
	const {
		params: {userId},
	} = useRouteMatch<{userId: string}>();

	const id = parseInt(userId);

	const [user, setUser] = useState(emptyUser);
	const [userLoadingError, setUserLoadingError] = useState<
		"not_found" | "other" | ""
	>("");

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	const createSubroute = useSubrouteCreator();

	function fetchUser(userId: number) {
		userService
			.getUserProfile(userId)
			.then(setUser)
			.catch((err) => {
				if ((err as {code: string}).code === "not_found") {
					setUserLoadingError("not_found");
				} else {
					setUserLoadingError("other");
				}
			});
	}

	useEffect(() => {
		fetchUser(id);
	}, [id]);

	const profilePage = createSubroute("/");
	const rolesPage = createSubroute("/roles");

	const userName =
		user.firstName && user.lastName
			? `${user.firstName} ${user.lastName} / ${user.name}`
			: user.name;

	let error = null;

	switch (userLoadingError) {
		case "not_found":
			error = (
				<LoadingError
					title={
						<Localized id="group-user-error-not-found-title">
							Not found
						</Localized>
					}
					description={
						<Localized id="group-user-error-not-found-description">
							The user is not found
						</Localized>
					}
				/>
			);

			break;
		case "other":
			error = (
				<LoadingError
					onReload={() => {
						setUserLoadingError("");
						fetchUser(id);
					}}
					description={
						<Localized id="group-user-error-general">
							Failed to load the user account data
						</Localized>
					}
				/>
			);
	}

	return (
		<>
			<SubtitleNav
				title={
					<Localized id="group-user-account-title">User account</Localized>
				}
				subtitle={userName}
				selectedTab={selectedTab}
			>
				<Tab
					label={
						<Localized id="group-user-account-tab-name-profile">
							Profile
						</Localized>
					}
					component={Link}
					value={profilePage.link}
					to={profilePage.link}
				/>

				<Tab
					label={
						<Localized id="group-user-account-tab-name-roles">Roles</Localized>
					}
					component={Link}
					value={rolesPage.link}
					to={rolesPage.link}
				/>
			</SubtitleNav>

			{error}

			{!error && user.id > 0 && (
				<Switch>
					<ObservableRoute
						exact
						path={profilePage.route}
						onRender={() => setSelectedTab(profilePage.link)}
					>
						<Profile user={user} onUpdate={setUser} />
					</ObservableRoute>

					<ObservableRoute
						path={rolesPage.route}
						onRender={() => setSelectedTab(rolesPage.link)}
					>
						<GroupUserOrganisations
							groupName={props.groupName}
							userId={user.id}
							userName={
								user.firstName && user.lastName
									? `${user.firstName} ${user.lastName}`
									: user.name
							}
						/>
					</ObservableRoute>

					<Route>
						<Redirect to={profilePage.link} />
					</Route>
				</Switch>
			)}
		</>
	);
}

export default UserAccount;
