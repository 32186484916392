import {Localized} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import {
	Box,
	Grid,
	IconButton,
	MenuItem,
	Popover,
	TextField,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useRef, useState} from "react";
import {ReactElement, ReactNode} from "react";

import {Page} from "../../helpers/paginatedSearchHelpers";
import type ExerciseType from "../../store/exercises/ExerciseType";
import type TagSearchResult from "../../store/exercises/TagSearchResult";
import searchScopes from "../exerciseBank/exerciseSearchScope";
import type {ExerciseSearchScope} from "../exerciseBank/exerciseSearchScope";
import ExerciseTagsSelector from "../learningMaterial/ExerciseTagsSelector";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";
import {
	ChangeExerciseSearchCriteria,
	ExerciseSearchCriteria,
} from "../exerciseBank/ExerciseSearchCriteria";
import ChoiceChip from "../../utils/ChoiceChip";
import OutlinedSearchInput from "../../utils/OutlinedSearchInput";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			padding: "2px 4px",
			display: "flex",
			alignItems: "center",
		},
		input: {
			marginLeft: theme.spacing(1),
			flex: 1,
		},
		iconButton: {
			padding: 10,
		},
		icon: {
			margin: 10,
		},
	})
);

const DialogFilters = (props: {
	availableExerciseTypes: {
		name: ExerciseType;
		displayName: ReactNode;
		icon: ReactElement;
	}[];
	tagsHidden: boolean;
	onSearchTags: (
		prefix: string,
		pageSize: number
	) => Promise<Page<TagSearchResult>>;
	onClearFilters: () => void;
	filter: ExerciseSearchCriteria;
	onFilterChange: ChangeExerciseSearchCriteria;
}): JSX.Element => {
	const {
		tagsHidden,
		onSearchTags,
		onClearFilters,
		onFilterChange,
		filter,
	} = props;
	const classes = useStyles();

	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const [applied, setApplied] = useState(false);

	const [query, setQuery] = useState(filter.query);

	useEffect(() => {
		const timeoutId = setTimeout(
			() => onFilterChange((prev) => ({...prev, query})),
			500
		);
		return () => clearTimeout(timeoutId);
	}, [onFilterChange, query]);

	const searchField = useRef<null | HTMLDivElement>(null);

	const noFilters = filter.tags.length === 0 && !filter.type && !applied;

	return (
		<>
			<OutlinedSearchInput
				ref={searchField}
				fullWidth
				autofocus
				value={query}
				onChange={(val) => {
					setQuery(val);
					setApplied(true);
				}}
				endAdornment={
					<>
						{!noFilters && (
							<IconButton
								className={classes.iconButton}
								onClick={() => {
									onClearFilters();
									setQuery("");
									setApplied(false);
								}}
							>
								<CloseIcon />
							</IconButton>
						)}
						<IconButton
							color="primary"
							className={classes.iconButton}
							onClick={() => setMenuAnchor(searchField.current)}
						>
							<TuneIcon />
						</IconButton>
					</>
				}
			/>

			<Popover
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClose={() => setMenuAnchor(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				PaperProps={{
					style: {
						maxWidth: searchField.current?.offsetWidth,
						minWidth: searchField.current?.offsetWidth,
					},
					square: true,
					variant: "outlined",
					elevation: 0,
				}}
			>
				<Box p={3} maxWidth="100%">
					<Grid container spacing={3}>
						<Grid item xs md={4} xl={3}>
							<TextField
								select
								value={filter.scope}
								onChange={({target}) => {
									onFilterChange((prev) => ({
										...prev,
										scope: (target.value as unknown) as ExerciseSearchScope,
									}));
									setApplied(true);
								}}
								label={
									<Localized id="exercise-bank-dialog-search-scope-label">
										Search scope
									</Localized>
								}
								InputLabelProps={{shrink: true}}
								fullWidth
							>
								{searchScopes.map((privacy) => {
									return (
										<MenuItem key={privacy.name} value={privacy.name}>
											<Localized id={privacy.displayName}>
												{privacy.displayName}
											</Localized>
										</MenuItem>
									);
								})}
							</TextField>
						</Grid>
						<Grid item xs md={4} xl={3}>
							<ContentLanguageSelector
								value={filter.language}
								onChange={(value) => {
									onFilterChange((prev) => ({...prev, language: value}));
									setApplied(true);
								}}
							/>
						</Grid>
						<Grid item container spacing={1}>
							{props.availableExerciseTypes.map((type) => {
								return (
									<Grid item key={type.name}>
										<ChoiceChip
											label={type.displayName}
											icon={type.icon}
											color="secondary"
											variant="outlined"
											selected={filter.type === type.name}
											onClick={() =>
												onFilterChange((prev) => ({
													...prev,
													type: filter.type === type.name ? null : type.name,
												}))
											}
										/>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
					{!tagsHidden && (
						<Box flexGrow={1} mt={3}>
							<ExerciseTagsSelector
								value={filter.tags}
								onChange={(tags) => {
									onFilterChange((prev) => ({...prev, tags}));
								}}
								searchTags={onSearchTags}
							/>
						</Box>
					)}
				</Box>
			</Popover>
		</>
	);
};

export default DialogFilters;
