import type {RootState} from "../store";

function selectStudyPeriod(
	state: RootState,
	courseKey: string
): {startDate: string; endDate: string} | null {
	return state.studentPreferences.byCourseKey[courseKey]?.studyPeriod ?? null;
}

export default selectStudyPeriod;
