import {createAsyncThunk} from "@reduxjs/toolkit";
import selectChapterSession from "../chapterSessions/selectChapterSession";
import selectChapterSessionSection from "../chapterSessions/selectChapterSessionSection";
import {selectSessionSectionNonTheorySubsectionIds} from "../chapterSessions/selectChapterSessionSectionSubsectionIds";

import {studentResponseService} from "../services/studentResponseService";
import type {RootState} from "../store";
import {normaliseStudentResponses} from "../studentResponses/NormalisedStudentResponses";
import Response from "../studentResponses/Response";

export const fetchChapterSessionStudentResponses = createAsyncThunk<
	{
		responses: {[key: string]: Response};
	},
	{
		courseId: number;
		studentId: number;
		sectionKey: string;
		sessionKey: string;
	},
	{
		state: RootState;
	}
>(
	"chapterSessions/fetchResponses",
	async ({courseId, sectionKey, studentId, sessionKey}, thunkApi) => {
		const store = thunkApi.getState();

		const section = selectChapterSessionSection(store, sectionKey);
		if (!section) {
			throw new Error(`Section ${sectionKey} is not found`);
		}

		const session = selectChapterSession(store, sessionKey);
		if (!session) {
			throw new Error(`Chapter session ${sessionKey} is not found`);
		}

		const ids = selectSessionSectionNonTheorySubsectionIds(store, sectionKey);

		if (ids.length === 0) {
			return {responses: {}};
		}

		const responses = await studentResponseService.getResponsesForChapterSession(
			studentId,
			courseId,
			section.chapterId,
			session.startTime,
			ids
		);

		const byKey = normaliseStudentResponses(responses, section.chapterId);

		return {responses: byKey};
	}
);
