import {Localized} from "@fluent/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Container,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Tooltip,
	Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useState} from "react";

import DbTable from "./DbTable";
import DbData from "./DbData";
import TableIcon from "../../../../icons/TableIcon";
import TableViewIcon from "../../../../icons/TableViewIcon";
import type TableSchema from "../../../../store/exercises/prog/TableSchema";

const useStyles = makeStyles((theme) => ({
	tablesList: {
		marginRight: theme.spacing(4),
	},
	typography: {
		color: theme.palette.action.active,
		cursor: "pointer",
	},
	selected: {
		color: theme.palette.text.primary,
		fontWeight: 500,
	},
}));

const DbSchemaAndState = (props: {schema: TableSchema}): JSX.Element => {
	const classes = useStyles();

	const {schema} = props;

	const [selectedTableIndex, setSelectedTableIndex] = useState(0);

	if (schema.length < 1) {
		return <></>;
	}

	const selectedTable = schema[selectedTableIndex];

	return (
		<Box display="flex">
			<List className={classes.tablesList}>
				{schema.map((item, i) => (
					<ListItem key={item.name}>
						<ListItemButton
							selected={i === selectedTableIndex}
							onClick={() => setSelectedTableIndex(i)}
						>
							<ListItemIcon>
								{item.type === "view" ? (
									<Tooltip
										title={
											<Localized id="exercise-prog-response-area-sql-tooltip-view">
												View
											</Localized>
										}
									>
										<TableViewIcon />
									</Tooltip>
								) : (
									<Tooltip
										title={
											<Localized id="exercise-prog-response-area-sql-tooltip-table">
												Table
											</Localized>
										}
									>
										<TableIcon />
									</Tooltip>
								)}
							</ListItemIcon>
							<ListItemText>{item.name}</ListItemText>
						</ListItemButton>
					</ListItem>
				))}
			</List>

			<Box
				display="flex"
				flexDirection="column"
				style={{gap: 8}}
				flexGrow={1}
				minWidth={0}
			>
				<DbTable fields={selectedTable.columns} />

				{selectedTable.checks.length > 0 && (
					<List
						dense
						disablePadding
						subheader={
							<ListSubheader component="div">
								<Localized id="exercise-prog-response-area-sql-checks-title">
									Check constraints:
								</Localized>
							</ListSubheader>
						}
					>
						{selectedTable.checks.map((chk, i) => (
							<ListItem key={i}>
								<ListItemText primary={`CHECK ${chk}`} />
							</ListItem>
						))}
					</List>
				)}

				{selectedTable.foreignKeys.length > 0 && (
					<List
						dense
						disablePadding
						subheader={
							<ListSubheader component="div">
								<Localized id="exercise-prog-response-area-sql-foreign-keys-title">
									Foreign key constraints:
								</Localized>
							</ListSubheader>
						}
					>
						{selectedTable.foreignKeys.map((fk, i) => (
							<ListItem key={i}>
								<ListItemText
									primary={`FOREIGN KEY (${fk.columns.join(", ")}) REFERENCES ${
										fk.referencedTable
									} (${fk.referencedColumns.join(", ")})`}
								/>
							</ListItem>
						))}
					</List>
				)}

				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography style={{flexBasis: "33.33%", flexShrink: 0}}>
							<Localized id="exercise-prog-response-area-sql-table-data-title">
								Table data
							</Localized>
						</Typography>
						<Typography color="textSecondary">
							<Localized
								id="exercise-prog-response-area-sql-table-data-rows"
								vars={{
									rows: selectedTable.data?.data?.length ?? 0,
								}}
							>
								{`Rows: ${selectedTable.data?.data?.length}`}
							</Localized>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{selectedTable.data?.data?.length === 0 ? (
							<Container style={{display: "flex", justifyContent: "center"}}>
								<Typography>
									<Localized id="exercise-prog-response-area-sql-no-data">
										No data
									</Localized>
								</Typography>
							</Container>
						) : (
							<DbData data={selectedTable.data} />
						)}
					</AccordionDetails>
				</Accordion>
			</Box>
		</Box>
	);
};

export default DbSchemaAndState;
