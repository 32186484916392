import {Localized} from "@fluent/react";
import React from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import exerciseService from "../../store/services/exerciseService";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

const createCopyAction = (
	courseId: number,
	onCopy: (id: number) => void,
	openConfirmationDialog: OpenConfirmationDialog,
	showSnackbar: ShowSnackbar
): ((exerciseId: number, title: string) => void) => {
	return function (exerciseId, title) {
		openConfirmationDialog({
			title: (
				<Localized id="exercise-bank-confirm-copy-dialog-title">
					Copy exercise?
				</Localized>
			),
			description: (
				<Localized
					id="exercise-bank-confirm-copy-dialog-description"
					vars={{title}}
				>
					{"You are going to copy exercise '{$title}'."}
				</Localized>
			),
			confirmBtnText: (
				<Localized id="exercise-bank-confirm-copy-dialog-confirm-button-text">
					Copy
				</Localized>
			),
			onConfirm: async () => {
				try {
					const copyExerciseId = await exerciseService.copyExercise(
						courseId,
						exerciseId
					);
					onCopy(copyExerciseId);
				} catch (error) {
					showSnackbar("error", "An error has occured");
				}
			},
		});
	};
};

export default createCopyAction;
