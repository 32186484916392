import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import enrolmentService from "../services/enrolmentService";

const fetchNumberOfPendingEnrolmentApplications = createAsyncThunk(
	"enrolment/fetchNumberOfPendingEnrolmentApplications",
	async (params: {courseId: number}) => {
		const ids = await enrolmentService.selectIdsOfUsersHavingPendingEnrolmentApplications(
			params.courseId,
			{}
		);

		return {
			number: ids.length,
			courseKey: keyProvider.course(params.courseId),
		};
	}
);

export default fetchNumberOfPendingEnrolmentApplications;
