import {useEffect} from "react";
import selectChapterExercise from "../../store/chapterExercises/selectChapterExercise";
import Chapter from "../../store/chapters/Chapter";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import selectChapter from "../../store/chapters/selectChapter";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectSectionOfExercise from "../../store/sections/selectSectionOfExercise";
import {ChapterExercise} from "../../store/services/dtos/ChapterContent";
import selectMessage from "../../store/tutorQuestions/selectMessage";
import TutorQuestion from "../../store/tutorQuestions/TutorQuestion";

const useMessageData = (
	messageKey: string | null,
	courseId: number
): {
	message: TutorQuestion | null;
	chapter: Chapter | null;
	section: {id: number; title: string} | null;
	exercise: ChapterExercise | null;
} => {
	const dispatch = useAppDispatch();

	const message = useAppSelector((state) =>
		messageKey ? selectMessage(state, messageKey) : null
	);

	const chapterId = message?.chapterId;

	let chapterExerciseKey = "";
	if (chapterId && message?.exerciseId) {
		chapterExerciseKey = keyProvider.chapterExercise(
			chapterId,
			message.exerciseId
		);
	}

	const section = useAppSelector((state) =>
		chapterExerciseKey
			? selectSectionOfExercise(state, chapterExerciseKey)
			: null
	);
	const exercise = useAppSelector((state) =>
		chapterExerciseKey ? selectChapterExercise(state, chapterExerciseKey) : null
	);
	const chapter = useAppSelector((state) =>
		chapterId ? selectChapter(state, keyProvider.chapter(chapterId)) : null
	);

	useEffect(() => {
		if (chapterId && !section) {
			dispatch(getChapterContent({courseId, chapterId}));
		}
	}, [chapterId, courseId, dispatch, section]);

	return {message, chapter, section, exercise};
};

export default useMessageData;
