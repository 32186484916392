import {createAsyncThunk} from "@reduxjs/toolkit";

import {getChapters} from "./getChapters";
import {courseService} from "../services/courseService";
import type {AppDispatch, RootState} from "../store";
import {keyProvider} from "../keyProvider";
import selectCourse from "../courses/selectCourse";

const publishAllChapters = createAsyncThunk<
	void,
	{courseId: number; chapterKeys: string[]},
	{dispatch: AppDispatch; state: RootState}
>("chapters/publishAllChapters", async function ({courseId}, thunkApi) {
	const state = thunkApi.getState();
	const courseKey = keyProvider.course(courseId);

	const course = selectCourse(state, courseKey);
	if (!course) {
		throw new Error(`Course ${courseId} is not found`);
	}

	await courseService.publishAllChapters(courseId, course.type);

	thunkApi.dispatch(getChapters(courseId));
});

export default publishAllChapters;
