import {createAsyncThunk} from "@reduxjs/toolkit";
import {bulletinBoardService} from "../services/bulletinBoardService";

const fetchUnreadAnnouncementsNumber = createAsyncThunk<
	number,
	{userId: number; courseId: number}
>(
	"announcements/fetchUnreadAnnouncementsNumber",
	async ({userId, courseId}) => {
		const count = await bulletinBoardService.getUnreadAnnouncementsNumber(
			userId,
			courseId
		);

		return count;
	}
);

export default fetchUnreadAnnouncementsNumber;
