import {RootState} from "../store";
import {CompletionStatus, ExerciseOutcomes} from "./ExerciseOutcomes";

const empty = {
	completionStatus: CompletionStatus.Unattempted,
	score: 0,
};

const selectExerciseOutcomes = (
	state: RootState,
	chapterExerciseKey: string
): Readonly<ExerciseOutcomes> => {
	return state.outcomes.byKey[chapterExerciseKey] ?? empty;
};

export default selectExerciseOutcomes;
