import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	TextField,
	useTheme,
} from "@mui/material";
import React, {useState} from "react";

import useMobileMode from "../../hooks/useMobileMode";
import type Organisation from "../../store/organisation/Organisation";
import {organisationService} from "../../store/services/organisationService";
import useSnackbar from "../../store/ui/useSnackbar";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import ConfirmationFooter from "../../utils/ConfirmationFooter";
import CountrySelector from "../../utils/CountrySelector";
import IndirectlyEditableTextField from "../../utils/IndirectlyEditableTextField";
import SlideUp from "../../utils/SlideUp";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function GeneralSettings(props: {
	organisation: Organisation;
	onUpdate: (o: Organisation) => void;
}) {
	const {organisation} = props;

	const [draft, setDraft] = useState<Omit<Organisation, "name">>({
		displayName: organisation.displayName,
		countryCode: organisation.countryCode,
		city: organisation.city,
	});

	const [dirty, setDirty] = useState(false);
	const [empty, setEmpty] = useState<{
		displayName?: boolean;
		countryCode?: boolean;
	}>({});

	const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);

	const [saving, setSaving] = useState(false);

	const mobileMode = useMobileMode("md");
	const theme = useTheme();

	function validate() {
		if (draft.displayName === "" || draft.countryCode === "") {
			setEmpty({
				countryCode: draft.countryCode === "",
				displayName: draft.displayName === "",
			});
			return false;
		}

		return true;
	}

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	async function save() {
		if (!validate()) {
			return;
		}

		setSaving(true);

		try {
			await organisationService.updateOrganisation(organisation.name, draft);

			props.onUpdate({name: organisation.name, ...draft});

			setDirty(false);
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		} finally {
			setSaving(false);
		}
	}

	function updateName(name: string) {
		setChangeNameDialogOpen(false);
		props.onUpdate({...organisation, name});
	}

	function cancel() {
		setDraft(organisation);
		setDirty(false);
		setEmpty({});
	}

	const changeNameTitleId = "change-org-name";

	return (
		<Box
			maxWidth={theme.breakpoints.values.xl}
			pt={mobileMode ? 3 : 5}
			px={mobileMode ? 3 : 6}
			pb={12}
		>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={4}>
					<IndirectlyEditableTextField
						id="name"
						label={
							<Localized id="organisation-settings-label-name">Name</Localized>
						}
						value={organisation.name}
						required
						onChangeClick={() => {
							setChangeNameDialogOpen(true);
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						id="displayName"
						fullWidth
						required
						label={
							<Localized id="organisation-settings-label-display-name">
								Display name
							</Localized>
						}
						value={draft.displayName}
						error={empty.displayName}
						onChange={({target}) => {
							setDraft((prev) => ({...prev, displayName: target.value}));
							setDirty(target.value !== organisation.displayName);
							setEmpty((prev) => ({...prev, displayName: false}));
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<CountrySelector
						value={draft.countryCode}
						required
						error={empty.countryCode}
						onChange={(code) => {
							setDraft((prev) => ({...prev, countryCode: code}));
							setDirty(code !== organisation.countryCode);
							setEmpty((prev) => ({...prev, countryCode: false}));
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						id="city"
						fullWidth
						label={
							<Localized id="organisation-settings-label-city">City</Localized>
						}
						value={draft.city ?? ""}
						onChange={({target}) => {
							setDraft((prev) => ({...prev, city: target.value}));
							setDirty(target.value !== organisation.city);
						}}
					/>
				</Grid>
			</Grid>

			{dirty && (
				<ConfirmationFooter
					onDiscard={cancel}
					onSave={save}
					saveInProgress={saving}
				/>
			)}

			<Dialog
				open={changeNameDialogOpen}
				maxWidth="xs"
				fullScreen={mobileMode}
				fullWidth
				onClose={() => setChangeNameDialogOpen(false)}
				aria-labelledby={changeNameTitleId}
				TransitionComponent={mobileMode ? SlideUp : undefined}
			>
				<ChangeNameDialog
					titleElementId={changeNameTitleId}
					mobileMode={mobileMode}
					initialValue={organisation.name}
					onSave={updateName}
					onClose={() => setChangeNameDialogOpen(false)}
				/>
			</Dialog>
		</Box>
	);
}

const orgNameExp = /^[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

function ChangeNameDialog(props: {
	initialValue: string;
	titleElementId: string;
	mobileMode?: boolean;
	onSave: (val: string) => void;
	onClose: () => void;
}) {
	const theme = useTheme();

	const [name, setName] = useState(props.initialValue);
	const [confirm, setConfirm] = useState("");

	const [saving, setSaving] = useState(false);

	const [incorrectConfirm, setIncorrectConfirm] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [nameError, setNameError] = useState("");
	const [generalError, setGeneralError] = useState("");

	const {l10n} = useLocalization();

	const changeNameConfirm = l10n.getString(
		"organisation-settings-change-name-dialog-confirmation-string",
		null,
		"change name and address"
	);

	function validate() {
		if (confirm !== changeNameConfirm) {
			setIncorrectConfirm(true);
			return false;
		}

		if (name === "") {
			setEmpty(true);
			return false;
		}

		if (!orgNameExp.test(name)) {
			setNameError(l10n.getString("organisation-settings-error-name-invalid"));
			return false;
		}

		return true;
	}

	async function save() {
		if (!validate()) {
			return;
		}

		try {
			await organisationService.updateOrganisation(props.initialValue, {name});
			props.onSave(name);
		} catch (err) {
			switch ((err as {code: string}).code) {
				case "validation_failed":
					setNameError(
						l10n.getString(
							"organisation-settings-error-forbidden-name",
							null,
							"The name is forbidden"
						)
					);

					break;
				case "name_already_used":
					setNameError(
						l10n.getString(
							"organisation-settings-error-name-in-use",
							null,
							"The name is already in use"
						)
					);

					break;
				default:
					setGeneralError(l10n.getString("error-general"));
			}
		} finally {
			setSaving(false);
		}
	}

	return (
		<>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="organisation-settings-change-name-dialog-title">
							Change short name
						</Localized>
					}
					actionButtonLabel={
						<Localized id="organisation-settings-change-name-dialog-action-change">
							Change
						</Localized>
					}
					actionInProgress={saving}
					onAction={save}
					onClose={props.onClose}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="organisation-settings-change-name-dialog-title">
							Change short name
						</Localized>
					}
					onClose={props.onClose}
				/>
			)}

			<DialogContent dividers>
				<DialogContentText>
					<Localized id="organisation-settings-change-name-dialog-content">
						Organisation name is a unique ID. Its change means change of base
						URL for all organisation resources.
					</Localized>
				</DialogContentText>
				<TextField
					required
					fullWidth
					value={name}
					error={empty || Boolean(nameError)}
					helperText={nameError}
					onChange={({target}) => {
						setName(target.value.toLowerCase());
						setEmpty(false);
						setNameError("");
					}}
				/>
				<DialogContentText style={{marginTop: theme.spacing(1.5)}}>
					<Localized id="organisation-settings-change-name-dialog-confirmation-demand">
						To proceed, type change name and address below.
					</Localized>
				</DialogContentText>
				<TextField
					required
					fullWidth
					placeholder={changeNameConfirm}
					value={confirm}
					error={incorrectConfirm}
					helperText={
						incorrectConfirm &&
						confirm.length > 0 && (
							<Localized id="organisation-settings-change-name-dialog-confirmation-mismatch">
								Must match change name and address
							</Localized>
						)
					}
					onChange={({target}) => {
						setConfirm(target.value);
						setIncorrectConfirm(false);
					}}
				/>
				{generalError && (
					<Grid item xs={12}>
						<DialogContentText
							color="error"
							style={{marginTop: theme.spacing(1.5)}}
						>
							{generalError}
						</DialogContentText>
					</Grid>
				)}
			</DialogContent>
			{!props.mobileMode && (
				<DialogActions>
					<Button color="primary" onClick={props.onClose}>
						<Localized id="organisation-settings-change-name-dialog-action-cancel">
							Cancel
						</Localized>
					</Button>
					<SubmitButton
						color="primary"
						inProgress={saving}
						variant="text"
						onClick={save}
					>
						<Localized id="organisation-settings-change-name-dialog-action-change">
							Change
						</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</>
	);
}

export default GeneralSettings;
