import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectChapterContentStatus(
	state: RootState,
	chapterKey: string
): FetchStatus {
	return state.chapterSections.byKey[chapterKey]?.status ?? "none";
}

export default selectChapterContentStatus;
