import {createAsyncThunk} from "@reduxjs/toolkit";

import {adminService} from "../services/adminService";

const restoreCourses = createAsyncThunk(
	"courses/restoreCourses",
	async (params: {organisationName: string; courseIds: number[]}) => {
		await adminService.restoreCourses(
			params.organisationName,
			params.courseIds
		);

		return {courseIds: params.courseIds};
	}
);

export default restoreCourses;
