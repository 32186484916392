// Source: OWASP Validation Regex Repository
//         https://owasp.org/www-community/OWASP_Validation_Regex_Repository
const emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

// See: OWASP Input Validation Cheat Sheet
//      https://cheatsheetseries.owasp.org/cheatsheets/Input_Validation_Cheat_Sheet.html#email-address-validation
export function validEmail(email: string): boolean {
	return (
		email.length <= 254 && email.indexOf("@") <= 63 && emailRegex.test(email)
	);
}

const phoneRegex = /^\+[1-9][0-9]{4,14}$/;

export function validPhoneNumber(phoneNumber: string): boolean {
	if (phoneNumber.length === 0) {
		return false;
	}

	return phoneRegex.test(phoneNumber);
}

const usernameRegex = /^[a-z]([._-]?[a-z0-9])*$/;

export function validUserName(username: string): boolean {
	if (username.length === 0) {
		return false;
	}

	return usernameRegex.test(username);
}
