import React from "react";

import ChapterLabel from "./ChapterLabel";
import CustomTreeItem from "./CustomTreeItem";

const DisabledChapterNavItem = (props: {
	chapterId: number;
	index: number;
	title: string;
}): JSX.Element => {
	return (
		<CustomTreeItem
			key={props.chapterId}
			itemId={`${props.chapterId}`}
			label={
				<ChapterLabel index={`${props.index}`} label={props.title} disabled />
			}
		/>
	);
};

export default DisabledChapterNavItem;
