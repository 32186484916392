import {createAsyncThunk} from "@reduxjs/toolkit";
import {courseService} from "../services/courseService";

const scheduleChapter = createAsyncThunk(
	"chapters/scheduleChapter",
	async (params: {courseId: number; chapterId: number; startDate: string}) => {
		const {courseId, chapterId, startDate} = params;

		await courseService.updateChapter(courseId, chapterId, {startDate});

		return {
			id: chapterId,
			startDate,
		};
	}
);

export default scheduleChapter;
