import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "../store";
import type ContentStatsByExerciseType from "./ContentStatsByExerciseType";
import ExerciseType from "../exercises/ExerciseType";

const empty: ContentStatsByExerciseType = {};

const selectCourseContentStatsByExerciseType = createSelector(
	(state: RootState) => state.courseChapters.byKey,
	(state: RootState) => state.chapterStatistics.byKey,
	(_: RootState, courseKey: string) => courseKey,
	(courseChapters, chaptersStats, courseKey) => {
		const chapters = courseChapters[courseKey]?.chapters;
		if (!chapters || chapters.length === 0) {
			return empty;
		}

		const result: ContentStatsByExerciseType = {};

		chapters.forEach((key) => {
			const stats = chaptersStats[key];
			if (!stats) {
				return;
			}

			Object.entries(stats).forEach(([k, v]) => {
				const type = k as ExerciseType;

				let r = result[type];
				if (!r) {
					r = {maxScore: 0, numberOfExercises: 0};
					result[type] = r;
				}

				r.numberOfExercises += v.numberOfExercises;
				r.maxScore += v.maxScore;
			});
		});

		return result;
	}
);

export default selectCourseContentStatsByExerciseType;
