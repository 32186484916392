import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";

import {courseService} from "../services/courseService";
import type {RootState} from "../store";
import selectCourse from "../courses/selectCourse";
import type SubmissionMode from "./SubmissionMode";
import type SelectionMode from "./SelectionMode";
import type ChapterStructureType from "./ChapterStructureType";
import type FeedbackVisibility from "./FeedbackVisibility";
import CourseType from "../courses/CourseType";
import {FeedbackVisibilities} from "./FeedbackVisibility";

const createChapter = createAsyncThunk<
	{
		id: number;
		courseId: number;
		title: string;
		number: number;
		submissionMode: SubmissionMode;
		selectionMode: SelectionMode;
		structureType: ChapterStructureType;
		sessionLimit: number;
		timeLimit?: number;
		feedbackVisibility: FeedbackVisibility;
	},
	{
		courseId: number;
		title: string;
		number: number;
		submissionMode: SubmissionMode;
		selectionMode: SelectionMode;
	},
	{state: RootState}
>(
	"chapters/createChapter",
	async function (
		{courseId, title, number, submissionMode, selectionMode},
		thunkApi
	) {
		const state = thunkApi.getState();
		const courseKey = keyProvider.course(courseId);

		const course = selectCourse(state, courseKey);
		if (!course) {
			throw new Error(`Course ${courseId} is not found`);
		}

		const defaultFeedbackVisibility =
			course.type === CourseType.Exam
				? FeedbackVisibilities.Hidden
				: FeedbackVisibilities.Visible;

		const id = await courseService.addChapter(courseId, {
			title,
			number,
			submissionMode,
			selectionMode,
			structureType: "sections",
			sessionLimit: 1,
			feedbackVisibility: defaultFeedbackVisibility,
		});

		return {
			id,
			courseId,
			title,
			number,
			submissionMode,
			selectionMode,
			structureType: "sections",
			sessionLimit: 1,
			feedbackVisibility: defaultFeedbackVisibility,
		};
	}
);

export default createChapter;
