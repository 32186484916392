enum Feature {
	CourseEditor = "course_editor",
	Exam = "exam",
	Practising = "practising",
	Reports = "reports",
	Communication = "communication",
	Trafi = "trafi",
	RedoingExercises = "redoing_exercises",
	CourseRecommendations = "course_recommendations",
	PerChapterTimeLimit = "per_chapter_time_limit",
	ChapterStructurePages = "chapter_structure_pages",
	Certificates = "certificates",
	CertificateTemplateEditor = "certificate_template_editor",
	ExternalExercises = "external_exercises",
	ExerciseTags = "exercise_tags",
	PracticeTests = "practice_tests",
	NewAnalytics = "new_analytics",
	TargetsAccomplishingNotification = "targets_accomplishing_notification",
	NewStudentManagement = "new_student_management",
}

export default Feature;
