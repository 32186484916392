import React, {useEffect, useRef, useState} from "react";
import type {LogRecord} from "../../../../store/studentResponses/Feedback";

const limit = 500;

const ProgramRunLog = (props: {
	records: LogRecord[];
	errorPlace?: {
		position: number;
		record: number;
	};
}): JSX.Element => {
	const {records, errorPlace} = props;

	const [moreBtnVisible, setMoreBtnVisible] = useState(false);

	const output = useRef<HTMLElement | null>(null);

	const printer = useRef<{
		print: () => void;
		cancel: () => void;
	}>();

	const logRef = useRef<Log>();

	useEffect(() => {
		if (output.current) {
			logRef.current = createLog(output.current, window.document, limit);

			const error = errorPlace ?? {record: records.length, position: 0};

			printer.current = createPrinter(
				records,
				error,
				logRef.current,
				setMoreBtnVisible
			);

			if (records.length > 0) {
				printer.current.print();
			}
		}
	}, [errorPlace, records]);

	useEffect(() => {
		return () => {
			printer.current?.cancel();
		};
	}, []);

	function onMoreBtnClick() {
		setMoreBtnVisible(false);
		if (logRef.current && printer.current) {
			logRef.current.n = limit;
			logRef.current.recommence();

			printer.current.print();
		}
	}

	return (
		<div className="program-run-log">
			<div>
				<samp ref={output}></samp>
			</div>
			{moreBtnVisible && (
				<div>
					<button onClick={onMoreBtnClick}>
						<span className="fold-down-icon"></span>
					</button>
				</div>
			)}
		</div>
	);
};

function createPrinter(
	records: {
		stream: number;
		message: string;
	}[],
	error: {position: number; record: number},
	log: Log,
	onCompletion: (more: boolean) => void
) {
	let i = 0;
	let position = 0;

	let printingId: ReturnType<typeof setInterval>;

	const print = () => {
		printingId = setInterval(function () {
			const record = records[i];

			if (i === error.record && error.position >= position) {
				const message = record.message.substring(position, error.position);

				const written = processMessage(log, record.stream, message);
				if (written < message.length) {
					position += written;

					onCompletion(true);

					log.complete();
					clearInterval(printingId);

					return;
				}

				position = error.position;

				log.setWrongOutput();
				log.endStream();
			}

			let message = record.message;
			if (position > 0) {
				message = message.substring(position);
			}

			const written = processMessage(log, record.stream, message);
			if (written < message.length) {
				position += written;

				onCompletion(true);

				log.complete();
				clearInterval(printingId);

				return;
			}

			i++;
			position = 0;

			log.endStream();

			if (i === records.length) {
				log.complete();
				clearInterval(printingId);
				onCompletion(false);
			}
		}, 600);
	};

	const cancel = () => {
		printingId && clearInterval(printingId);
	};

	return {print, cancel};
}

function createElementFactory(
	document: Document,
	name: string,
	classes?: string
) {
	const factory = {
		name,
		classes,
		create(text?: string) {
			const element = document.createElement(factory.name);

			if (factory.classes) {
				element.className = factory.classes;
			}

			if (text) {
				element.textContent = text;
			}

			return element;
		},
	};

	return factory;
}

type Log = {
	n: number;

	write(text: string): boolean;
	writeLine(text: string, separator: string): boolean;
	flush(): void;
	switchStream(number: number): void;
	endStream(): void;
	setWrongOutput(): void;
	complete(): void;
	recommence(): void;
};

function createLog(output: HTMLElement, document: Document, limit: number) {
	const streams = [
		createElementFactory(document, "kbd"),
		createElementFactory(document, "span"),
		createElementFactory(document, "span"),
	];

	const createText = document.createTextNode.bind(document);
	const createNewline = createElementFactory(document, "span", "newline")
		.create;

	let stream: Node | null = null;

	const log = {
		n: limit,
		write(text: string) {
			if (stream && log.n <= 0) {
				return false;
			}

			if (text.length > 0) {
				stream?.appendChild(createText(text));
			}

			return true;
		},

		writeLine(text: string, separator: string) {
			if (stream && log.n <= 0) {
				return false;
			}

			if (text.length > 0) {
				stream?.appendChild(createText(text));
			}

			stream?.appendChild(createNewline(separator));

			log.n--;

			return true;
		},

		flush() {
			stream && output.appendChild(stream);
		},

		switchStream(number: number) {
			if (!stream) {
				stream = streams[number].create();
			}
		},

		endStream() {
			stream = null;
		},

		setWrongOutput() {
			streams[1].classes = "wrong-output";
			streams[2].classes = "wrong-output";
		},

		complete() {
			output.classList.add("printing-completed");
		},

		recommence() {
			output.classList.remove("printing-completed");
		},
	};

	return log;
}

function processMessage(log: Log, stream: number, message: string) {
	log.switchStream(stream);

	let s = 0;
	let e = message.indexOf("\n");

	while (e >= 0 && log.writeLine(message.substring(s, e), "\n")) {
		s = e + 1;
		e = message.indexOf("\n", s);
	}

	if (s < message.length && log.write(message.substring(s, message.length))) {
		s = message.length;
	}

	log.flush();

	return s;
}

export default React.memo(ProgramRunLog);
