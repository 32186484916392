import {Localized} from "@fluent/react";
import React from "react";

import type {CourseStudentSearchResult} from "../../store/services/enrolmentService";
import type {ColumnDefs} from "../../utils/tables/Head";

const studentActivityColumnKeys = ["student", "studyStatus"] as const;

const studentActivityColumnDefs: ColumnDefs<
	typeof studentActivityColumnKeys[number],
	keyof CourseStudentSearchResult
> = {
	student: {
		name: (
			<Localized id="follow-up-student-activity-widget-column-name">
				Student name
			</Localized>
		),
		sortOptions: [
			{
				field: "firstName",
				label: (
					<Localized id="follow-up-student-activity-widget-column-name-sort-by-firstname">
						Sort by first name
					</Localized>
				),
			},
			{
				field: "lastName",
				label: (
					<Localized id="follow-up-student-activity-widget-column-name-sort-by-lastname">
						Sort by last name
					</Localized>
				),
			},
			{
				field: "userName",
				label: (
					<Localized id="follow-up-student-activity-widget-column-name-sort-by-username">
						Sort by username
					</Localized>
				),
			},
		],
		width: "40%",
	},
	studyStatus: {
		name: (
			<Localized id="follow-up-student-activity-widget-column-study-status">
				Study status
			</Localized>
		),
	},
};

export {studentActivityColumnDefs, studentActivityColumnKeys};
