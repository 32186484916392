import {Localized, useLocalization} from "@fluent/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
	Button,
	DialogActions,
	DialogContent,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
} from "@mui/material";
import React, {useState} from "react";

import SubmitButton from "../../utils/SubmitButton";
import type DialogResult from "../../utils/DialogResult";
import {userService} from "../../store/services/userService";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

const ChangePasswordDialog = (props: {
	userId: number;
	onClose: (result: DialogResult) => void;
	titleElementId: string;
	mobileMode: boolean;
}): JSX.Element => {
	const {onClose} = props;

	const [currentPasswordHidden, setCurrentPasswordHidden] = useState(true);
	const [currentPassword, setCurrentPassword] = useState("");

	const [passwordHidden, setPasswordHidden] = useState(true);
	const [password, setPassword] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");

	const [inProgress, setInProgress] = useState(false);

	const [emptyField, setEmptyField] = useState(() => ({
		currentPassword: false,
		password: false,
		passwordRepeat: false,
	}));
	const [validationMessages, setValidationMessages] = useState<
		{[key in "password" | "passwordRepeat"]?: React.ReactNode}
	>({});

	const {l10n} = useLocalization();

	const theme = useTheme();

	function validate() {
		const empty = {
			currentPassword: currentPassword === "",
			password: password === "",
			passwordRepeat: passwordRepeat === "",
		};

		setEmptyField(empty);

		const validationMessages = {
			password:
				(password.length > 0 && password.length < 8 && (
					<Localized id="account-change-password-min-length">
						Password should be at least 8 characters long
					</Localized>
				)) ||
				(password.length > 128 && (
					<Localized id="account-change-password-max-length">
						Password cannot be more than 128 characters long
					</Localized>
				)),
			passwordRepeat: passwordRepeat !== password && (
				<Localized id="account-change-password-mismatch">
					Passwords mismatch
				</Localized>
			),
		};

		setValidationMessages(validationMessages);

		return !(
			Object.values(empty).some((e) => e) ||
			Object.values(validationMessages).some((e) => e)
		);
	}

	function togglePasswordVisibility() {
		setPasswordHidden((prev) => !prev);
	}

	async function submit() {
		const valid = validate();
		if (!valid) {
			return;
		}

		setInProgress(true);

		try {
			await userService.patchCredentials(
				props.userId,
				{password},
				currentPassword
			);

			onClose({
				status: "success",
				message: l10n.getString(
					"account-change-password-success",
					null,
					"Password changed"
				),
			});
		} catch (err) {
			onClose({
				status: "error",
				message:
					(err as {code: string}).code === "forbidden"
						? l10n.getString(
								"account-change-password-current-password-mismatch",
								null,
								"Current password does not match"
						  )
						: l10n.getString("error-general"),
			});
		} finally {
			setInProgress(false);
		}
	}

	function close() {
		onClose({status: "cancelled"});
	}

	return (
		<form
			onKeyDown={(e) => {
				if (e.code === "Enter") {
					e.preventDefault();
					submit();
				}
			}}
		>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-change-password-dialog-title">
							Change password
						</Localized>
					}
					actionInProgress={inProgress}
					actionButtonLabel={
						<Localized id="account-change-password-btn">Change</Localized>
					}
					onAction={submit}
					onClose={close}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-change-password-dialog-title">
							Change password
						</Localized>
					}
					inProgress={inProgress}
					onClose={close}
				/>
			)}

			<DialogContent>
				<TextField
					required
					fullWidth
					label={
						<Localized id="account-change-password-current-password">
							Current password
						</Localized>
					}
					type={currentPasswordHidden ? "password" : "text"}
					value={currentPassword}
					autoFocus
					autoComplete="current-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setCurrentPasswordHidden((prev) => !prev)}
								>
									{currentPasswordHidden ? (
										<VisibilityOffIcon />
									) : (
										<VisibilityIcon />
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={(event) => {
						setCurrentPassword(event.target.value);
						setEmptyField((prev) => ({...prev, currentPassword: false}));
					}}
					error={emptyField.currentPassword}
				/>
				<TextField
					required
					fullWidth
					label={
						<Localized id="account-change-password-new-password">
							New password
						</Localized>
					}
					type={passwordHidden ? "password" : "text"}
					value={password}
					autoComplete="new-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={togglePasswordVisibility}>
									{passwordHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={(event) => {
						setPassword(event.target.value);
						setEmptyField((prev) => ({...prev, password: false}));
						setValidationMessages((prev) => ({...prev, password: null}));
					}}
					error={emptyField.password || Boolean(validationMessages.password)}
					helperText={validationMessages.password}
					style={{margin: theme.spacing(2, 0)}}
				/>
				<TextField
					required
					fullWidth
					value={passwordRepeat}
					label={
						<Localized id="account-change-password-repeat-password">
							Repeat new password
						</Localized>
					}
					type={passwordHidden ? "password" : "text"}
					autoComplete="new-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={togglePasswordVisibility}>
									{passwordHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={(event) => {
						setPasswordRepeat(event.target.value);
						setEmptyField((prev) => ({...prev, passwordRepeat: false}));
						setValidationMessages((prev) => ({
							...prev,
							passwordRepeat: null,
						}));
					}}
					error={
						emptyField.passwordRepeat ||
						Boolean(validationMessages.passwordRepeat)
					}
					helperText={validationMessages.passwordRepeat}
				/>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button onClick={close} color="primary" disabled={inProgress}>
						<Localized id="account-change-password-cancel-btn">
							Cancel
						</Localized>
					</Button>
					<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
						<Localized id="account-change-password-btn">Change</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</form>
	);
};

export default ChangePasswordDialog;
