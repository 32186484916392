import React from "react";
import {useScrollTrigger} from "@mui/material";

const BottomShadowScroll = (props: {
	children: React.ReactElement;
	scrollable?: Node;
	shadow?: string;
}): JSX.Element => {
	const defaultShadow = "0px 7px 5px -3px rgb(0 0 0 / 14%)";

	const {children, scrollable, shadow = defaultShadow} = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: scrollable,
	});

	return React.cloneElement(children, {
		boxShadow: trigger ? shadow : undefined,
	});
};

export default BottomShadowScroll;
