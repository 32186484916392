import {Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flex: "1 1 auto",
			minWidth: 0,
		},
	})
);

function DualTextLabel(props: {
	primaryText: NonNullable<React.ReactNode>;
	secondaryText: NonNullable<React.ReactNode>;
}) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="inherit" display="block">
				{props.primaryText}
			</Typography>
			<Typography variant="caption" display="block" color="textSecondary">
				{props.secondaryText}
			</Typography>
		</div>
	);
}

export default DualTextLabel;
