import {createAsyncThunk} from "@reduxjs/toolkit";

import themeService from "../services/themeService";

const fetchTheme = createAsyncThunk(
	"themes/fetchTheme",
	function ({organisationName}: {organisationName: string}) {
		return themeService.getTheme(organisationName);
	}
);

export default fetchTheme;
