import {Localized, useLocalization} from "@fluent/react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React, {useState} from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import {courseService} from "../../store/services/courseService";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

function useDeleteAction(
	onDelete: () => void,
	openConfirmationDialog: OpenConfirmationDialog,
	showSnackbar: ShowSnackbar
): [(templateId: number, templateName: string) => void, JSX.Element] {
	const [deletionErrorOpen, setDeletionErrorOpen] = useState(false);
	const {l10n} = useLocalization();

	function deleteAction(templateId: number, templateName: string) {
		return openConfirmationDialog({
			title: (
				<Localized id="course-templates-confirm-delete-dialog-title">
					Delete template?
				</Localized>
			),
			description: (
				<Localized
					id="course-templates-confirm-delete-dialog-descr"
					elems={{
						paragraph: <DialogContentText></DialogContentText>,
					}}
					vars={{template: templateName}}
				>
					<>You are going to delete template Some template.</>
				</Localized>
			),
			confirmBtnText: (
				<Localized id="course-templates-confirm-delete-dialog-action-delete">
					Delete
				</Localized>
			),
			onConfirm: async () => {
				try {
					await courseService.deleteTemplate(templateId);
					onDelete();
				} catch (error) {
					if ((error as {code: string}).code === "conflict") {
						setDeletionErrorOpen(true);
					} else {
						showSnackbar("error", l10n.getString("error-general"));
					}
				}
			},
		});
	}

	return [
		deleteAction,
		<ConflictErrorDialog
			key="error-dialog"
			open={deletionErrorOpen}
			onClose={() => setDeletionErrorOpen(false)}
		/>,
	];
}

function ConflictErrorDialog(props: {open: boolean; onClose: () => void}) {
	const titleId = "error-dialog";

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			aria-labelledby={titleId}
			maxWidth="xs"
		>
			<DialogTitle id={titleId}>
				<Localized id="course-templates-error-course-created-title">
					Cannot delete template
				</Localized>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Localized id="course-templates-error-course-created-descr">
						The template cannot be deleted: at least one course has been created
						based on it.
					</Localized>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					<Localized id="course-templates-error-course-created-action-ok">
						OK
					</Localized>
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default useDeleteAction;
