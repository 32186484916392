import type {InternalAxiosRequestConfig} from "axios";
import {useEffect} from "react";
import {axiosInstance as client} from "../store/services/axiosInstance";

function useTrackAppUsage(
	orgName: string,
	appName: "orgadmin" | "teacher" | "student",
	courseId?: number
): void {
	useEffect(() => {
		const id = client.interceptors.request.use(
			addViopeHeaders(orgName, appName, courseId)
		);

		return () => {
			client.interceptors.request.eject(id);
		};
	}, [appName, courseId, orgName]);
}

function addViopeHeaders(orgName: string, appName: string, courseId?: number) {
	return function (request: InternalAxiosRequestConfig) {
		if (request.headers) {
			request.headers["Viope-Org-Name"] = orgName;
			request.headers["Viope-App-Name"] = appName;
			if (courseId) {
				request.headers["Viope-Course-Id"] = courseId;
			}
		}
		return request;
	};
}

export default useTrackAppUsage;
