import React, {useMemo} from "react";
import {Grid} from "@mui/material";

import TeX from "@matejmazur/react-katex";
import "katex/dist/katex.min.css";

import {changeRootArgs} from "../../../helpers/mathParseHelpers";
import AsciiMathParser from "../../../utils/asciiMathParser";

function MathAnswer(props: {value: string[]}): JSX.Element {
	const parser = useMemo(() => {
		return new AsciiMathParser();
	}, []);

	return (
		<Grid container direction="column" spacing={1}>
			{props.value.map((formula, i) => (
				<Grid item key={i}>
					<TeX>{parser.parse(changeRootArgs(formula))}</TeX>
				</Grid>
			))}
		</Grid>
	);
}

export default MathAnswer;
