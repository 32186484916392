import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import {Paper, Typography, useTheme} from "@mui/material";
import {FluentNumber} from "@fluent/bundle";
import {Localized} from "@fluent/react";
import React from "react";

import type TimeLeft from "../../utils/TimeLeft";

const CountDown = (props: {timeLeft: TimeLeft}): JSX.Element => {
	const theme = useTheme();

	const {timeLeft} = props;

	let message: React.ReactNode;

	const format = {minimumIntegerDigits: 2};

	if (timeLeft.total <= 0) {
		message = (
			<Localized id="course-studying-countdown-no-time-left">
				Time is up
			</Localized>
		);
	} else if (timeLeft.hours > 0) {
		message = (
			<Localized
				id="course-studying-countdown-time-left-with-hours"
				vars={{
					hours: new FluentNumber(timeLeft.hours, format),
					minutes: new FluentNumber(timeLeft.minutes, format),
					seconds: new FluentNumber(timeLeft.seconds, format),
				}}
			>
				{"{$hours} : {$minutes} : {$seconds}"}
			</Localized>
		);
	} else {
		message = (
			<Localized
				id="course-studying-countdown-time-left-without-hours"
				vars={{
					minutes: new FluentNumber(timeLeft.minutes, format),
					seconds: new FluentNumber(timeLeft.seconds, format),
				}}
			>
				{"{$minutes} : {$seconds}"}
			</Localized>
		);
	}

	return (
		<Paper
			style={{
				display: "flex",
				gap: theme.spacing(1),
				padding: theme.spacing(1, 2, 1, 2),
			}}
		>
			{timeLeft.total > 0 && <AccessAlarmIcon />}
			<Typography variant="subtitle1">{message}</Typography>
		</Paper>
	);
};

export default CountDown;
