import {Localized, useLocalization} from "@fluent/react";
import {Box, Divider} from "@mui/material";
import type {SerializedError} from "@reduxjs/toolkit";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useMemo} from "react";
import {Draggable} from "react-beautiful-dnd";
import {useLocation} from "react-router-dom";

import type {MenuItem} from "./ChildDraggableComponent";
import ChildDraggableComponent from "./ChildDraggableComponent";
import {resolveLinkToOldSite} from "../../helpers/pathHelpers";
import {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import {makeSelectChapterExercise} from "../../store/chapterExercises/makeSelectChapterExercise";
import removeExercise from "../../store/chapterExercises/removeExercise";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";

interface Props {
	parentIndex: number;
	subsectionKey: string;
	index: number;
	courseId: number;
	navigationPath: string;
	deletionDisabled: boolean;
	chapterEditing: boolean;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
}

export const ChildComponent = (props: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const {l10n} = useLocalization();

	const {
		parentIndex,
		subsectionKey,
		index,
		courseId,
		deletionDisabled,
		chapterEditing,
	} = props;

	const selectChapterExercise = useMemo(makeSelectChapterExercise, []);

	const subsection = useAppSelector((state) =>
		selectChapterExercise(state, subsectionKey)
	);

	const userId = useAppSelector(selectUserId);

	if (!subsection) {
		return <></>;
	}

	const editLink = resolveLinkToOldSite(
		`/teacher/${courseId}/#/exercise_template/${subsection.type}/${subsection.id}`
	);

	const hashLink = `#subsection-${subsection.id}`;

	const removeSubsection = async () => {
		try {
			const res = await dispatch(
				removeExercise({courseId, chapterExerciseKey: subsectionKey})
			);
			unwrapResult(res);
		} catch (error) {
			let message = l10n.getString("dragdrop-subsection-delete-error");

			if (
				(error as SerializedError).code === "responses_exist" &&
				subsection.type !== ExerciseType.Theory
			) {
				message = l10n.getString(
					"dragdrop-subsection-delete-error-not-allowed"
				);
			}
			props.showSnackbar("error", message);
		}
	};

	const menuItems: MenuItem[] = [
		{
			action: "Delete",
			text: (
				<Localized id="dragdrop-subsection-action-delete">Delete</Localized>
			),
			handleDelete: () =>
				props.openConfirmDialog({
					title: l10n.getString(
						"dragdrop-subsection-confirm-delete-dialog-title",
						null,
						"Delete subsection?"
					),
					description: l10n.getString(
						subsection.type === ExerciseType.Theory
							? "dragdrop-subsection-confirm-delete-dialog-description-theory"
							: "dragdrop-subsection-confirm-delete-dialog-description-exercise",
						{title: subsection.title}
					),
					confirmBtnText: l10n.getString(
						"dragdrop-subsection-confirm-delete-dialog-delete",
						null,
						"Delete"
					),
					onConfirm: removeSubsection,
				}),
			disabled: deletionDisabled || chapterEditing,
		},
	];

	return (
		<Box ml={1.5}>
			<Draggable
				key={subsectionKey}
				draggableId={subsectionKey}
				index={index}
				isDragDisabled={chapterEditing || props.viewOnly}
			>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<ChildDraggableComponent
							provided={provided.dragHandleProps}
							index={`${parentIndex + 1}.${index + 1}`}
							content={subsection.title}
							menuItems={menuItems}
							navigationPath={props.navigationPath + hashLink}
							active={hashLink === location.hash}
							viewOnly={props.viewOnly}
						/>

						<Divider />
					</div>
				)}
			</Draggable>
		</Box>
	);
};
