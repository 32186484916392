import React from "react";
import createIcon from "./utils";

export default createIcon(
	<svg viewBox="0 0 32 32" stroke="currentColor" strokeWidth={1}>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path d="M7.66,26.628c0.162-0.223,0.477-0.273,0.698-0.11l0,0c0.224,0.161,0.272,0.477,0.11,0.698l0,0 c-0.162,0.223-0.475,0.272-0.697,0.11l0,0C7.547,27.164,7.498,26.851,7.66,26.628L7.66,26.628z" />
						</g>
					</g>

					<g>
						<g>
							<path d="M23.531,4.784c0.162-0.223,0.475-0.272,0.697-0.11l0,0c0.225,0.162,0.273,0.476,0.111,0.698l0,0 c-0.162,0.223-0.477,0.273-0.698,0.11l0,0C23.418,5.321,23.369,5.006,23.531,4.784L23.531,4.784z" />
						</g>
					</g>
				</g>

				<g>
					<g>
						<g>
							<path d="M3.006,19.696c0.262-0.085,0.546,0.059,0.63,0.321l0,0c0.086,0.262-0.06,0.545-0.321,0.63l0,0 c-0.262,0.085-0.544-0.059-0.629-0.321l0,0C2.6,20.063,2.744,19.781,3.006,19.696L3.006,19.696z" />
						</g>
					</g>

					<g>
						<g>
							<path d="M28.686,11.353c0.262-0.085,0.544,0.059,0.629,0.321l0,0c0.086,0.263-0.059,0.545-0.32,0.63l0,0 c-0.262,0.085-0.546-0.059-0.63-0.321l0,0C28.278,11.721,28.424,11.438,28.686,11.353L28.686,11.353z" />
						</g>
					</g>
				</g>

				<g>
					<g>
						<g>
							<path d="M3.315,11.353c0.261,0.085,0.406,0.368,0.32,0.63l0,0c-0.085,0.263-0.368,0.406-0.63,0.321l0,0 c-0.262-0.085-0.406-0.367-0.32-0.63l0,0C2.771,11.411,3.054,11.268,3.315,11.353L3.315,11.353z" />
						</g>
					</g>

					<g>
						<g>
							<path d="M28.994,19.696c0.262,0.085,0.406,0.367,0.32,0.63l0,0c-0.085,0.263-0.368,0.406-0.63,0.321l0,0 c-0.261-0.085-0.406-0.368-0.32-0.63l0,0C28.449,19.755,28.732,19.611,28.994,19.696L28.994,19.696z" />
						</g>
					</g>
				</g>

				<g>
					<g>
						<g>
							<path d="M8.47,4.784c0.161,0.223,0.112,0.537-0.111,0.698l0,0C8.136,5.645,7.822,5.595,7.66,5.372l0,0 C7.498,5.149,7.548,4.836,7.771,4.674l0,0C7.994,4.512,8.308,4.562,8.47,4.784L8.47,4.784z" />
						</g>
					</g>

					<g>
						<g>
							<path d="M24.34,26.628c0.162,0.223,0.112,0.536-0.111,0.698l0,0c-0.223,0.162-0.536,0.112-0.698-0.11l0,0 c-0.161-0.223-0.112-0.537,0.111-0.698l0,0C23.864,26.355,24.178,26.405,24.34,26.628L24.34,26.628z" />
						</g>
					</g>
				</g>

				<g>
					<g>
						<g>
							<path d="M16.5,2.5C16.5,2.775,16.275,3,16,3l0,0c-0.275,0-0.5-0.225-0.5-0.5l0,0C15.5,2.225,15.725,2,16,2l0,0 C16.275,2,16.5,2.225,16.5,2.5L16.5,2.5z" />
						</g>
					</g>

					<g>
						<g>
							<path d="M16.5,29.5c0,0.275-0.225,0.5-0.5,0.5l0,0c-0.275,0-0.5-0.225-0.5-0.5l0,0c0-0.275,0.225-0.5,0.5-0.5 l0,0C16.275,29,16.5,29.225,16.5,29.5L16.5,29.5z" />
						</g>
					</g>
				</g>
			</g>

			<path d="M16,20c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S18.206,20,16,20z M16,13c-1.654,0-3,1.346-3,3 s1.346,3,3,3s3-1.346,3-3S17.654,13,16,13z" />

			<g>
				<path d="M13.563,18.504l-2.659,3.661c0.256,0.213,0.53,0.403,0.813,0.583l2.662-3.665 C14.08,18.926,13.803,18.737,13.563,18.504z" />

				<path d="M8.249,25.818c-0.162,0.224-0.114,0.536,0.109,0.699c0.224,0.162,0.536,0.111,0.699-0.11l1.186-1.632 c-0.279-0.184-0.549-0.38-0.809-0.589L8.249,25.818z" />

				<path d="M23.751,6.182c0.162-0.224,0.114-0.536-0.109-0.699c-0.224-0.162-0.536-0.111-0.699,0.11l-1.186,1.632 c0.279,0.184,0.549,0.38,0.809,0.589L23.751,6.182z" />

				<path d="M18.438,13.496l2.659-3.661c-0.256-0.213-0.53-0.403-0.813-0.583l-2.662,3.665 C17.92,13.074,18.197,13.263,18.438,13.496z" />

				<path d="M3.958,19.388c-0.263,0.085-0.407,0.366-0.322,0.63c0.086,0.263,0.368,0.405,0.631,0.321l1.922-0.625 c-0.117-0.311-0.221-0.627-0.309-0.95L3.958,19.388z" />

				<path d="M12.56,16.592l-4.3,1.398c0.083,0.325,0.184,0.644,0.306,0.951l4.309-1.399 C12.727,17.245,12.618,16.929,12.56,16.592z" />

				<path d="M19.44,15.408l4.3-1.398c-0.083-0.325-0.184-0.644-0.306-0.951l-4.309,1.399 C19.273,14.755,19.382,15.071,19.44,15.408z" />

				<path d="M28.042,12.612c0.263-0.085,0.407-0.366,0.322-0.63c-0.086-0.263-0.368-0.405-0.631-0.321l-1.922,0.625 c0.117,0.311,0.221,0.627,0.309,0.95L28.042,12.612z" />

				<path d="M4.267,11.662c-0.263-0.085-0.545,0.058-0.631,0.32c-0.085,0.264,0.06,0.545,0.321,0.631l1.923,0.625 c0.088-0.324,0.19-0.642,0.309-0.952L4.267,11.662z" />

				<path d="M12.874,14.458l-4.309-1.399c-0.122,0.309-0.223,0.627-0.306,0.952l4.3,1.397 C12.618,15.071,12.727,14.755,12.874,14.458z" />

				<path d="M28.043,19.387l-1.923-0.625c-0.088,0.324-0.19,0.642-0.309,0.952l1.922,0.624 c0.263,0.085,0.545-0.058,0.631-0.32C28.449,19.754,28.305,19.473,28.043,19.387z" />

				<path d="M19.126,17.542l4.309,1.399c0.122-0.309,0.223-0.627,0.306-0.952l-4.3-1.397 C19.382,16.929,19.273,17.245,19.126,17.542z" />

				<path d="M14.378,12.917l-2.663-3.665c-0.281,0.18-0.556,0.37-0.813,0.583l2.66,3.661 C13.802,13.264,14.08,13.075,14.378,12.917z" />

				<path d="M9.058,5.594C8.895,5.37,8.582,5.32,8.358,5.482c-0.224,0.163-0.272,0.476-0.11,0.699l1.186,1.632 c0.26-0.209,0.529-0.405,0.809-0.589L9.058,5.594z" />

				<path d="M22.942,26.406c0.163,0.224,0.476,0.273,0.699,0.111c0.224-0.163,0.272-0.476,0.11-0.699l-1.186-1.632 c-0.26,0.209-0.529,0.405-0.809,0.589L22.942,26.406z" />

				<path d="M17.622,19.083l2.663,3.665c0.281-0.18,0.556-0.37,0.813-0.583l-2.66-3.661 C18.198,18.736,17.92,18.925,17.622,19.083z" />

				<path d="M16.5,5.525V3.5C16.5,3.224,16.276,3,16,3s-0.5,0.224-0.5,0.5v2.025C15.667,5.518,15.831,5.5,16,5.5 S16.333,5.518,16.5,5.525z" />

				<path d="M16,12.5c0.171,0,0.335,0.026,0.5,0.051V8.025C16.334,8.015,16.169,8,16,8s-0.334,0.015-0.5,0.025v4.525 C15.665,12.526,15.829,12.5,16,12.5z" />

				<path d="M15.5,26.475V28.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-2.025C16.333,26.482,16.169,26.5,16,26.5 S15.667,26.482,15.5,26.475z" />

				<path d="M16,19.5c-0.171,0-0.335-0.026-0.5-0.051v4.525C15.666,23.985,15.831,24,16,24s0.334-0.015,0.5-0.025 v-4.525C16.335,19.474,16.171,19.5,16,19.5z" />
			</g>

			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path d="M16,27C9.935,27,5,22.065,5,16c0-1.154,0.179-2.291,0.53-3.381c0.085-0.263,0.369-0.406,0.629-0.322 c0.263,0.085,0.407,0.366,0.322,0.629C6.162,13.916,6,14.95,6,16c0,5.514,4.486,10,10,10s10-4.486,10-10S21.514,6,16,6 c-1.48,0-2.906,0.316-4.236,0.939c-0.249,0.117-0.548,0.009-0.665-0.241S11.09,6.15,11.34,6.033C12.804,5.348,14.371,5,16,5 c6.065,0,11,4.935,11,11S22.065,27,16,27z M16,24.5c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5 S20.687,24.5,16,24.5z M16,8.5c-4.136,0-7.5,3.364-7.5,7.5s3.364,7.5,7.5,7.5s7.5-3.364,7.5-7.5S20.136,8.5,16,8.5z M6.9,11.259c-0.085,0-0.171-0.021-0.25-0.067c-0.239-0.138-0.321-0.443-0.183-0.683c0.778-1.349,1.857-2.54,3.12-3.447 c0.224-0.162,0.537-0.111,0.698,0.114c0.161,0.225,0.109,0.537-0.114,0.698c-1.149,0.824-2.131,1.908-2.839,3.135 C7.241,11.169,7.072,11.259,6.9,11.259z" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>

			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path d="M24.004,29.776c-0.169,0-0.334-0.086-0.428-0.241c-0.143-0.236-0.067-0.544,0.169-0.687 c2.706-1.636,4.869-4.136,6.09-7.042c0.106-0.255,0.401-0.374,0.654-0.268c0.255,0.107,0.375,0.4,0.268,0.654 c-1.303,3.1-3.608,5.768-6.494,7.511C24.182,29.753,24.093,29.776,24.004,29.776z" />
								</g>
							</g>
						</g>
					</g>
				</g>

				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<path d="M22.004,30.5c0,0.275-0.225,0.5-0.5,0.5l0,0c-0.275,0-0.5-0.225-0.5-0.5l0,0 c0-0.275,0.225-0.5,0.5-0.5l0,0C21.779,30,22.004,30.225,22.004,30.5L22.004,30.5z" />
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>

			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path d="M1.704,10.5c-0.064,0-0.131-0.013-0.193-0.039c-0.255-0.106-0.375-0.4-0.268-0.654 c1.303-3.103,3.612-5.771,6.502-7.516C7.98,2.15,8.289,2.223,8.432,2.46c0.143,0.236,0.067,0.544-0.169,0.687 c-2.711,1.636-4.876,4.139-6.098,7.047C2.085,10.385,1.899,10.5,1.704,10.5z" />
								</g>
							</g>
						</g>
					</g>
				</g>

				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<path d="M11.004,1.5c0,0.275-0.225,0.5-0.5,0.5l0,0c-0.275,0-0.5-0.225-0.5-0.5l0,0 c0-0.275,0.225-0.5,0.5-0.5l0,0C10.779,1,11.004,1.225,11.004,1.5L11.004,1.5z" />
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>,
	"ControlManagementIcon"
);
