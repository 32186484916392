import {axiosInstance as client} from "./axiosInstance";
import type {CompletionStatus} from "../outcomes/ExerciseOutcomes";

export const studentService = {
	getChapterOutcomes: async (
		studentId: number,
		courseId: number,
		chapterId: number
	): Promise<
		{
			exerciseId: number;
			score: number;
			completionStatus: CompletionStatus;
			assessorComment?: string;
		}[]
	> => {
		const url = `/api/students/${studentId}/courses/${courseId}/chapters/${chapterId}/outcomes`;

		const {data} = await client.get(url);

		return data;
	},

	postOutcomes: async (
		studentId: number,
		courseId: number,
		chapterId: number,
		exerciseId: number,
		outcomes: {
			completionStatus: CompletionStatus;
			score?: number;
		}
	): Promise<void> => {
		const url =
			`/api/students/${studentId}/courses/${courseId}` +
			`/chapters/${chapterId}/exercises/${exerciseId}/outcomes`;

		await client.post(url, outcomes);
	},
};
