import {useMemo, useState} from "react";
import type FileUploader from "./FileUploader";
import type UploadedFile from "./UploadedFile";

function useFileUploader<T extends FileUploader | null>(
	create: () => T
): [UploadedFile[], T] {
	const [files, setFiles] = useState<UploadedFile[]>([]);

	const uploader = useMemo(() => {
		const uploader = create();

		const wrapped = uploader && {
			async loadAll() {
				const res = await uploader.loadAll();
				setFiles(res);
				return res;
			},

			async upload(file: Blob, uploadName?: string) {
				const res = await uploader.upload(file, uploadName);
				setFiles((prev) => [...prev, res]);
				return res;
			},

			async delete(deleteUrl: string) {
				await uploader.delete(deleteUrl);
				setFiles((prev) => prev.filter((f) => f.deleteUrl !== deleteUrl));
			},
		};

		return wrapped;
	}, [create]);

	return [files, uploader as T];
}

export default useFileUploader;
