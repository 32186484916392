import {Localized} from "@fluent/react";
import {Box, Fade, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";

import AssessmentDeliveryContext, {
	AssessmentDeliveryContextProvider,
	SessionAssessmentDeliveryContextProvider,
} from "../AssessmentDeliveryContext";
import ChapterSubmissionButton from "./ChapterSubmissionButton";
import ContentNavigation from "./ContentNavigation";
import CountDown from "./CountDown";
import DeadlineTracker from "./DeadlineTracker";
import parseDate from "../../helpers/parseDate";
import {
	makeLinkToChapter,
	resolveLinkToOldSite,
	resolvePath,
} from "../../helpers/pathHelpers";
import useMobileMode from "../../hooks/useMobileMode";
import CalculateIcon from "../../icons/CalculateIcon";
import BackDestination from "../nav/BackDestination";
import ProctoringMonitorPanel from "../proctoring/ProctoringMonitorPanel";
import SectionContent from "./SectionContent";
import SectionNavigation from "./SectionNavigation";
import ContentSidebar from "../sidebars/ContentSidebar";
import SidebarContext, {SidebarType} from "../sidebars/SidebarContext";
import {requiresExplicitSessionStart} from "../../store/chapters/Chapter";
import type Chapter from "../../store/chapters/Chapter";
import {getChapters} from "../../store/chapters/getChapters";
import selectChapter from "../../store/chapters/selectChapter";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import selectFirstPublishedChapterKey from "../../store/chapters/selectFirstPublishedChapterKey";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import {calculateChapterSessionEndTime} from "../../store/chapterSessions/ChapterSession";
import type ChapterSession from "../../store/chapterSessions/ChapterSession";
import fetchChapterSessions from "../../store/chapterSessions/fetchChapterSessions";
import selectChapterSession from "../../store/chapterSessions/selectChapterSession";
import selectChapterSessionKeys from "../../store/chapterSessions/selectChapterSessionKeys";
import selectChapterSessionStatus from "../../store/chapterSessions/selectChapterSessionStatus";
import CourseFeature from "../../store/courses/CourseFeature";
import CourseType from "../../store/courses/CourseType";
import selectCourse from "../../store/courses/selectCourse";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import fetchProctoringSettings from "../../store/proctoring/fetchProctoringSettings";
import {selectProctoringSettingsStatus} from "../../store/proctoring/selectProctoringSettings";
import selectSectionOfExercise from "../../store/sections/selectSectionOfExercise";
import TestIntro from "./TestIntro";
import AppBarDependentContainer from "../../utils/AppBarDependentContainer";
import CalculatorPanel from "../../utils/calculator/CalculatorPanel";
import LoadingError from "../../utils/errors/LoadingError";
import ExtendableBox from "../../utils/ExtendableBox";
import OpenNavigationButton from "../../utils/OpenNavigationButton";

const courseNavigationDrawerWidth = 360;
const sidebarDrawerWidth = 320;

const RedirectToBeginning = (props: {courseKey: string; basePath: string}) => {
	const chapterKey = useAppSelector((state) =>
		selectFirstPublishedChapterKey(state, props.courseKey)
	);

	if (!chapterKey) {
		return <></>;
	}

	return <Redirect to={resolvePath(props.basePath, chapterKey)} />;
};

function CourseContent(props: {
	organisationName: string;
	courseId: number;
	userId: number;
	courseType: CourseType;
	proctoringEnabled?: boolean;
}): JSX.Element {
	const {courseId, userId, courseType} = props;

	const dispatch = useAppDispatch();

	const {url: basePath} = useRouteMatch();

	const mobileMode = useMobileMode("md");

	const courseKey = keyProvider.course(courseId);

	const chaptersRequested = useAppSelector(
		(state) => courseKey in state.courseChapters.byKey
	);

	useEffect(() => {
		if (!chaptersRequested) {
			dispatch(getChapters(courseId));
		}
	}, [courseId, dispatch, chaptersRequested]);

	const chapterKeys = useAppSelector((state) =>
		selectCourseChapterKeys(state, courseKey)
	);

	const [navigationOpen, setNavigationOpen] = useState(false);

	const toggleNavigation = useCallback(() => {
		setNavigationOpen((prev) => !prev);
	}, []);

	const {
		type: sidebarType,
		close: closeSidebar,
		fullscreen: sidebarFullscreen,
	} = useContext(SidebarContext);

	const sidebarOpen = sidebarType !== SidebarType.None;

	useEffect(() => {
		if (mobileMode) {
			if (navigationOpen && sidebarOpen) {
				closeSidebar();
			}
		}
	}, [closeSidebar, mobileMode, navigationOpen, sidebarOpen]);

	if (chapterKeys.length === 0) {
		return <></>;
	}

	return (
		<Box display="flex">
			<Switch>
				<Route
					path={resolvePath(basePath, "/chapters/:chapterId")}
					render={({match}) => {
						const chapterId = Number(match.params.chapterId);
						const chapterKey = keyProvider.chapter(chapterId);
						const chapterPath = match.isExact;

						return (
							<>
								<ContentNavigation
									courseId={courseId}
									chapterKeys={chapterKeys}
									currentChapterKey={chapterKey}
									onClose={toggleNavigation}
									open={navigationOpen}
									width={courseNavigationDrawerWidth}
									mobileMode={mobileMode}
								/>

								<ExtendableBox
									leftExtension={
										navigationOpen || mobileMode
											? 0
											: courseNavigationDrawerWidth
									}
									rightExtension={
										chapterPath ||
										sidebarOpen ||
										sidebarFullscreen ||
										mobileMode
											? 0
											: sidebarDrawerWidth
									}
									maxWidth={2048}
									minWidth={0}
									p={mobileMode ? 3 : 6}
									display="flex"
								>
									<OpenNavigationButton
										onClick={toggleNavigation}
										hidden={navigationOpen}
									/>
									<Content
										organisationName={props.organisationName}
										courseId={courseId}
										chapterId={chapterId}
										studentId={userId}
										basePath={basePath}
										courseType={courseType}
										requestContent={!chapterPath}
										proctoringEnabled={props.proctoringEnabled}
									/>
								</ExtendableBox>

								<Route
									path={resolvePath(
										basePath,
										"/chapters/:chapterId/sections/:sectionId"
									)}
									render={({match}) => {
										const sectionId = Number(match.params.sectionId);

										return (
											<ContentSidebar
												courseId={courseId}
												sectionKey={keyProvider.section(chapterId, sectionId)}
												width={sidebarDrawerWidth}
												mobileMode={mobileMode}
											/>
										);
									}}
								></Route>
							</>
						);
					}}
				></Route>

				<Route>
					<RedirectToBeginning basePath={basePath} courseKey={courseKey} />
				</Route>
			</Switch>
		</Box>
	);
}

const RedirectToExerciseSection = (props: {
	chapterId: number;
	exerciseId: number;
	basePath: string;
	fallbackTo: string;
}) => {
	const chapterExerciseKey = keyProvider.chapterExercise(
		props.chapterId,
		props.exerciseId
	);

	const sectionId = useAppSelector(
		(state) => selectSectionOfExercise(state, chapterExerciseKey)?.id
	);

	let url = props.fallbackTo;

	if (sectionId) {
		const sectionKey = keyProvider.section(props.chapterId, sectionId);

		url = resolvePath(
			props.basePath,
			`${sectionKey}#subsection-${props.exerciseId}`
		);
	}

	return <Redirect to={url} />;
};

function Content(props: {
	organisationName: string;
	courseId: number;
	courseType: CourseType;
	chapterId: number;
	studentId: number;
	basePath: string;
	requestContent: boolean;
	proctoringEnabled?: boolean;
}) {
	const {
		courseId,
		courseType,
		chapterId,
		studentId,
		basePath,
		requestContent,
		proctoringEnabled,
	} = props;

	const chapterKey = keyProvider.chapter(chapterId);

	const dispatch = useAppDispatch();

	const chapter = useAppSelector((state) => selectChapter(state, chapterKey));

	const sessionRequired = Boolean(
		chapter && requiresExplicitSessionStart(courseType, chapter)
	);

	const [sessionKeys, sessionsFetchStatus] = useAppSelector((state) =>
		selectChapterSessionKeys(state, chapterKey)
	);
	const latestSessionKey = sessionKeys.length > 0 ? sessionKeys[0] : null;

	useEffect(() => {
		if (sessionRequired && sessionsFetchStatus === "none" && requestContent) {
			dispatch(
				fetchChapterSessions({
					studentId,
					courseId,
					chapterId,
				})
			);
		}
	}, [
		chapterId,
		courseId,
		dispatch,
		studentId,
		sessionRequired,
		sessionsFetchStatus,
		requestContent,
	]);

	const proctoringSettingsStatus = useAppSelector(
		selectProctoringSettingsStatus
	);

	const proctoringSettingsShouldBeFetched =
		sessionRequired && proctoringEnabled && proctoringSettingsStatus === "none";

	useEffect(() => {
		if (proctoringSettingsShouldBeFetched) {
			dispatch(
				fetchProctoringSettings({organisationName: props.organisationName})
			);
		}
	}, [dispatch, props.organisationName, proctoringSettingsShouldBeFetched]);

	const published = useMemo(() => {
		return Boolean(
			chapter?.startDate && parseDate(chapter.startDate) <= new Date()
		);
	}, [chapter?.startDate]);

	const session = useAppSelector((state) =>
		latestSessionKey ? selectChapterSession(state, latestSessionKey) : null
	);

	if (
		!chapter ||
		(sessionRequired && sessionsFetchStatus === "pending") ||
		(proctoringEnabled && proctoringSettingsStatus === "pending")
	) {
		return <></>;
	}

	if (chapter.structureType !== "sections") {
		window.location.replace(resolveLinkToOldSite(`student/${courseId}/#`));
		return <></>;
	}

	let redirectPath = basePath;
	if (sessionRequired && courseType === CourseType.Ordinary) {
		redirectPath = resolvePath(basePath, chapterKey);
	}

	if (proctoringEnabled && proctoringSettingsStatus === "failed") {
		return (
			<LoadingError
				description={
					<Localized id="course-content-error-load-proctoring-settings">
						Failed to load proctoring settings
					</Localized>
				}
				slotProps={{pageContainer: {sx: {my: -6}}}}
			/>
		);
	}

	if (
		sessionRequired &&
		courseType === CourseType.Ordinary &&
		!requestContent
	) {
		return !chapter.startDate ? (
			<Redirect to={basePath} />
		) : (
			<TestIntro test={chapter} chapterKey={chapterKey} studentId={studentId} />
		);
	}

	const noSession = sessionsFetchStatus === "succeeded" && !session;
	if (
		!published ||
		(sessionRequired && (noSession || sessionsFetchStatus === "failed"))
	) {
		return <Redirect to={redirectPath} />;
	}

	const content = (
		<ChapterContent
			organisationName={props.organisationName}
			courseId={courseId}
			chapterId={chapterId}
			studentId={studentId}
			chapter={chapter}
			session={session}
			sessionKey={latestSessionKey}
			attemptNumber={sessionKeys.length}
			basePath={basePath}
			backDestination={sessionRequired ? redirectPath : "/"}
			proctoringEnabled={sessionRequired && proctoringEnabled}
		/>
	);

	return sessionRequired ? (
		<SessionAssessmentDeliveryContextProvider
			sessionKey={latestSessionKey ?? ""}
			courseId={courseId}
			chapterId={chapterId}
			studentId={studentId}
		>
			{content}
		</SessionAssessmentDeliveryContextProvider>
	) : (
		<AssessmentDeliveryContextProvider
			courseId={courseId}
			chapterId={chapterId}
			studentId={studentId}
		>
			{content}
		</AssessmentDeliveryContextProvider>
	);
}

function ChapterContent(props: {
	organisationName: string;
	courseId: number;
	chapterId: number;
	studentId: number;
	basePath: string;
	chapter: Chapter;
	session: ChapterSession | null;
	sessionKey: string | null;
	attemptNumber: number;
	backDestination: string;
	proctoringEnabled?: boolean;
}): JSX.Element {
	const {
		courseId,
		chapterId,
		studentId,
		basePath,
		chapter,
		session,
		sessionKey,
		backDestination,
		proctoringEnabled,
	} = props;

	const {
		fetchContent,
		fetchOutcomes,
		selectContentStatus,
		selectSectionKeys,
	} = useContext(AssessmentDeliveryContext);

	const contentRequested = useAppSelector(
		(state) => selectContentStatus(state) !== "none"
	);

	const sectionKeys = useAppSelector(selectSectionKeys);

	const deadline = useMemo(() => {
		let deadline = chapter.endDate;
		if (chapter.timeLimit && session?.startTime) {
			deadline = calculateChapterSessionEndTime(
				session.startTime,
				chapter.timeLimit
			).toISOString();

			if (chapter.endDate && chapter.endDate < deadline) {
				deadline = chapter.endDate;
			}
		}
		return deadline;
	}, [chapter.endDate, chapter.timeLimit, session?.startTime]);

	useEffect(() => {
		if (!contentRequested) {
			fetchContent();
		}
	}, [contentRequested, fetchContent]);

	useEffect(() => {
		fetchOutcomes();
	}, [fetchOutcomes]);

	const [calcOpened, setCalcOpened] = useState(false);

	const calcEnabled = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(courseId))?.features.includes(
			CourseFeature.Calculator
		)
	);

	const sessionStatus = useAppSelector((state) =>
		sessionKey
			? selectChapterSessionStatus(
					state,
					keyProvider.chapter(chapterId),
					sessionKey
			  )
			: "unknown"
	);

	const mobileMode = useMobileMode("md");

	const proctoringReturnLink = useMemo(
		() => makeLinkToChapter(props.organisationName, courseId, chapterId),
		[chapterId, courseId, props.organisationName]
	);

	if (sectionKeys.length === 0) {
		return <></>;
	}

	const createSectionContent = (
		sectionKey: string,
		viewOnly?: boolean,
		countDown?: React.ReactNode
	) => (
		<Grid container>
			<Grid item xs={10}>
				<SectionContent
					courseId={courseId}
					sectionKey={sectionKey}
					submissionMode={chapter.submissionMode}
					selectionMode={chapter.selectionMode}
					readonly={viewOnly}
					submissionDisabled={Boolean(session?.submissionTime)}
				/>
			</Grid>
			<Grid
				item
				xs={2}
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					zIndex: 1,
					pointerEvents: "none",
				}}
			>
				<AppBarDependentContainer>
					<Stack
						spacing={1}
						sx={{
							pt: mobileMode ? 2 : 5,
							pb: mobileMode ? 2 : 4.5,
							mr: -1.5,
							height: 1,
							alignItems: "flex-end",
							"& *": {pointerEvents: "auto"},
						}}
					>
						<SectionNavigation
							currentKey={sectionKey}
							sectionKeys={sectionKeys}
						/>

						{countDown}

						{calcEnabled && (
							<Fade in={!calcOpened}>
								<Tooltip
									title={
										<Localized id="calculator-button-tooltip">
											Calculator
										</Localized>
									}
								>
									<IconButton
										onClick={() => setCalcOpened(true)}
										color="primary"
									>
										<CalculateIcon />
									</IconButton>
								</Tooltip>
							</Fade>
						)}
						{proctoringEnabled && sessionStatus === "ongoing" && (
							<ProctoringMonitorPanel
								courseId={courseId}
								testId={chapterId}
								returnLink={proctoringReturnLink}
								studentId={studentId}
								attemptNumber={props.attemptNumber}
							/>
						)}
						<Box sx={{flex: "1", position: "relative"}}>
							{calcEnabled && (
								<CalculatorPanel
									open={calcOpened}
									onClose={() => setCalcOpened(false)}
								/>
							)}
						</Box>
						{chapter.submissionMode === SubmissionMode.Simultaneous &&
							!session?.submissionTime && (
								<ChapterSubmissionButton
									courseId={courseId}
									chapterId={chapterId}
									studentId={studentId}
								/>
							)}
					</Stack>
				</AppBarDependentContainer>
			</Grid>
		</Grid>
	);

	const firstSection = resolvePath(basePath, sectionKeys[0]);

	return (
		<Switch>
			<Route
				path={resolvePath(basePath, "/chapters/:chapterId/sections/:sectionId")}
				render={({match}) => {
					const sectionId = Number(match.params.sectionId);
					const sectionKey = keyProvider.section(chapterId, sectionId);
					return (
						<BackDestination key="section-content" path={backDestination}>
							{deadline ? (
								<DeadlineTracker deadline={deadline}>
									{(timeLeft) =>
										createSectionContent(
											sectionKey,
											sessionStatus === "unknown" ||
												sessionStatus === "submitted" ||
												timeLeft.total <= 0,
											sessionStatus !== "submitted" && timeLeft.hours < 24 && (
												<CountDown timeLeft={timeLeft} />
											)
										)
									}
								</DeadlineTracker>
							) : (
								<>{createSectionContent(sectionKey)}</>
							)}
						</BackDestination>
					);
				}}
			/>

			<Route
				path={resolvePath(
					basePath,
					"/chapters/:chapterId/exercises/:exerciseId"
				)}
				render={({match}) => {
					const params = match.params;

					return (
						<RedirectToExerciseSection
							chapterId={Number(params.chapterId)}
							exerciseId={Number(params.exerciseId)}
							basePath={basePath}
							fallbackTo={firstSection}
						/>
					);
				}}
			></Route>

			<Route>
				<Redirect to={firstSection} />
			</Route>
		</Switch>
	);
}

export default CourseContent;
