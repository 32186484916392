import {createAsyncThunk} from "@reduxjs/toolkit";
import {organisationService} from "../services/organisationService";

const fetchOrganisationFeatures = createAsyncThunk(
	"organisationFeatures/fetchFeatures",
	(orgName: string) => {
		return organisationService.getOrganisationFeatures(orgName);
	}
);

export default fetchOrganisationFeatures;
