import {Box, Fade, useTheme} from "@mui/material";
import React from "react";

const WidgetBodyOverlay = (props: {
	children: React.ReactNode;
	in: boolean;
	order?: number;
}): JSX.Element => {
	const theme = useTheme();

	return (
		<Fade in={props.in} mountOnEnter unmountOnExit>
			<Box position="absolute" style={{inset: 0}}>
				<Box
					style={{
						background: theme.palette.background.paper,
						height: "100%",
						width: "100%",
					}}
					position="relative"
					zIndex={props.order}
				>
					{props.children}
				</Box>
			</Box>
		</Fade>
	);
};

export default WidgetBodyOverlay;
