import React from "react";
import AttachmentItem from "./AttachmentItem";
import type UploadedFile from "./UploadedFile";

const AttachmentsList = (props: {
	items: UploadedFile[];
	onDelete?: (deleteUrl: string) => void;
}): JSX.Element => {
	return (
		<>
			{props.items.map((item) => {
				return (
					<AttachmentItem
						key={item.url}
						item={item}
						onDelete={props.onDelete}
					/>
				);
			})}
		</>
	);
};

export default AttachmentsList;
