import {Badge, Stack, Tooltip} from "@mui/material";
import React from "react";

const defaultMax = 99;

function TabLabelWithBadge(props: {
	content: React.ReactNode;
	badgeContent: number;
	max?: number;
	prepareBadgeContentDescription?: (
		number: number,
		maxExceeded: boolean
	) => string;
}) {
	if (props.badgeContent === 0) {
		return <>{props.content}</>;
	}

	const max = props.max ?? defaultMax;
	const maxExceeded = props.badgeContent > max;

	const label = props.prepareBadgeContentDescription?.(
		maxExceeded ? max : props.badgeContent,
		maxExceeded
	);

	const badge = (
		<Badge
			badgeContent={props.badgeContent}
			color="secondary"
			aria-label={label}
			max={max}
		/>
	);

	return (
		<Stack direction="row" spacing={3} sx={{alignItems: "center", pr: 0.5}}>
			{props.content}

			{label ? (
				<Tooltip title={label} enterDelay={500} enterNextDelay={500}>
					{badge}
				</Tooltip>
			) : (
				badge
			)}
		</Stack>
	);
}

export default TabLabelWithBadge;
