import {Localized, useLocalization} from "@fluent/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";

import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import exerciseService from "../../store/services/exerciseService";
import ExerciseTagsSelector from "./ExerciseTagsSelector";
import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import {useAppSelector} from "../../store/hooks";
import useExerciseFileUploader from "./useExerciseFileUploader";
import ContentEditorFooter from "./ContentEditorFooter";
import ExerciseType from "../../store/exercises/ExerciseType";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import ExerciseEditorProps from "./ExerciseEditorProps";
import useSnackbar from "../../store/ui/useSnackbar";
import H5PEditor, {H5PEditorApi} from "./H5PEditor";
import PrivacyLevelSelector from "./PrivacyLevelSelector";

type Draft = {
	id: number;
	title: string;
	maxScore: number;
	difficultyLevel: number;
	privacy: ExercisePrivacy;
	category: string;
	contentId: string;
	tags: string[];
};

const ExternalExerciseEditor = (props: ExerciseEditorProps): JSX.Element => {
	const {courseId, exercise, exerciseId, organisationName} = props;

	const userId = useAppSelector(selectUserId);

	const {l10n} = useLocalization();

	const [draft, setDraft] = useState<Draft>(() => ({
		id: exerciseId ?? 0,
		difficultyLevel: 0,
		privacy: ExercisePrivacy.PublicToOrganisation,
		title: l10n.getString(
			"learning-material-external-editor-placeholder-exercise-title",
			null,
			"New external exercise"
		),
		question: "",
		solution: "",
		category: "",
		tags: [],
		maxScore: 1,
		contentId: "new",
	}));

	const initialQuestion = useRef("");
	const initialSolution = useRef("");

	const question = useRef<TextEditorApi | null>(null);
	const solution = useRef<TextEditorApi | null>(null);

	const [errors, setErrors] = useState({title: ""});

	const showSnackbar = useSnackbar();

	if (exercise && exercise.type !== ExerciseType.External) {
		throw new Error("Unexpected exercise type");
	}

	const [featureEnabled] = useFeatureEnabled();

	useEffect(() => {
		if (exercise) {
			initialQuestion.current = exercise.question;
			initialSolution.current = exercise.solution;

			setDraft({
				id: exerciseId ?? 0,
				category: exercise.category,
				tags: exercise.tags,
				difficultyLevel: exercise.difficultyLevel,
				maxScore: exercise.maxScore,
				privacy: exercise.privacy,
				title: exercise.title,
				contentId: exercise.contentId ?? "new",
			});
		}
	}, [exercise, exerciseId]);

	const [editingStarted, setEditingStarted] = useState(!exercise);
	const updated = useRef(false);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		courseId,
		draft.id
	);

	useEffect(() => props.onUnmount, [props.onUnmount]);

	const editor = useRef<H5PEditorApi | null>(null);

	const controlEditingStarted = () => setEditingStarted(true);

	const handleTitleUpdate = (title: string) => {
		setDraft((prev) => ({...prev, title}));
		setErrors((prev) => ({...prev, title: ""}));
		controlEditingStarted();
	};

	const handleMaxScoreUpdate = (maxScore: number) => {
		if (maxScore < 0) {
			return;
		}
		controlEditingStarted();
		setDraft((prev) => ({...prev, maxScore}));
	};

	const handlePrivacyLevelUpdate = (privacy: ExercisePrivacy) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, privacy}));
	};

	const handleDifficultyUpdate = (difficultyLevel: number | null) => {
		controlEditingStarted();
		setDraft((prev) => ({
			...prev,
			difficultyLevel: difficultyLevel ?? 0,
		}));
	};

	const handleCategoryUpdate = (category: string) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, category}));
	};

	const handleTagsUpdate = (tags: string[]) => {
		controlEditingStarted();
		setDraft((prev) => ({...prev, tags}));
	};

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const page = await exerciseService.searchTags(
				{
					scope: "organisation_or_user_exercises",
					organisationName,
					userId,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[organisationName, userId]
	);

	const validate = () => {
		if (draft.title === "") {
			setErrors((prev) => ({...prev, title: "The field is required"}));

			return "Some required fields are empty";
		}

		return null;
	};

	const handleDraftSave = async () => {
		const error = validate();
		if (error) {
			showSnackbar("error", error);
			return false;
		}

		try {
			const base = exercise ?? {
				type: ExerciseType.External,
				authorId: userId,
				originId: courseId,
			};

			const id = await props.onSave({
				...base,
				title: draft.title,
				category: draft.category,
				question: question.current?.getContent() ?? "",
				solution: solution.current?.getContent() ?? "",
				tags: draft.tags,
				privacy: draft.privacy,
				difficultyLevel: draft.difficultyLevel,
				subtype: "h5p",
				maxScore: draft.maxScore,
				contentId: draft.contentId,
			});

			setDraft((prev) => ({...prev, id}));
			if (editor.current) {
				await editor.current.save(id);
			}
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		updated.current = true;

		return true;
	};

	const handleDraftDone = async () => {
		const saved = await handleDraftSave();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	};

	return (
		<>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<TextField
						autoFocus
						required
						fullWidth
						error={Boolean(errors.title)}
						value={draft.title}
						id="exerciseTitle"
						label={l10n.getString(
							"exercise-editor-title-label",
							null,
							"Exercise title"
						)}
						onChange={(e) => handleTitleUpdate(e.target.value)}
						helperText={errors.title}
					/>
				</Grid>
				<Grid item>
					<TextField
						id="score-number"
						label={l10n.getString("exercise-editor-score-label", null, "Score")}
						type="number"
						value={draft.maxScore}
						onChange={(e) => handleMaxScoreUpdate(parseInt(e.target.value))}
					/>
				</Grid>

				<Grid item>
					<Box mt={2} mb={1}>
						<Typography variant="body2">
							<Localized id="exercise-editor-description-label">
								Description
							</Localized>
						</Typography>
					</Box>

					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						onChange={() => setEditingStarted(true)}
						fileUploader={fileUploader ?? undefined}
						files={files}
						ref={question}
					/>
				</Grid>

				<Grid item>
					<H5PEditor
						ref={editor}
						contentId={draft.contentId}
						onSave={(contentId) => setDraft((prev) => ({...prev, contentId}))}
						onSaveError={() => {
							showSnackbar("error", "Failed to save interactive content");
						}}
						onLoaded={() => setEditingStarted(true)}
					/>
				</Grid>

				<Grid item>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>
								<Localized id="exercise-editor-solution-label">
									Solution
								</Localized>
							</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<Box width="100%">
								<TextEditor
									initialValue={initialSolution.current}
									ref={solution}
									onChange={() => setEditingStarted(true)}
									fileUploader={fileUploader ?? undefined}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid item container spacing={3} alignItems="flex-end">
					<Grid item xs={12} md={6}>
						<PrivacyLevelSelector
							value={draft.privacy}
							onChange={handlePrivacyLevelUpdate}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ExerciseDifficultySelector
							name="difficulty"
							value={draft.difficultyLevel}
							onChange={handleDifficultyUpdate}
						/>
					</Grid>
				</Grid>

				<Grid item>
					<TextField
						value={draft.category}
						id="caterogy"
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						onChange={(e) => handleCategoryUpdate(e.target.value)}
					/>
				</Grid>

				{featureEnabled(Feature.ExerciseTags) && (
					<Grid item>
						<ExerciseTagsSelector
							freeSolo
							value={draft.tags}
							onChange={handleTagsUpdate}
							searchTags={searchTags}
						/>
					</Grid>
				)}
			</Grid>
			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={handleDraftDone}
				onSave={handleDraftSave}
				disabled={!editingStarted}
			/>
		</>
	);
};

export default ExternalExerciseEditor;
