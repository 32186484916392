import {createSlice} from "@reduxjs/toolkit";

import {CompletionStatus, ExerciseOutcomes} from "./ExerciseOutcomes";
import {getChapterOutcomes} from "./getChapterOutcomes";
import postOutcomes from "./postOutcomes";
import {resetStudentResponse} from "../studentResponses/resetStudentResponse";
import {saveStudentResponse} from "../studentResponses/saveStudentResponse";
import submitStudentResponse from "../studentResponses/submitStudentResponse";

type State = {
	byKey: {
		[key: string]: ExerciseOutcomes;
	};
};

const initialState: State = {
	byKey: {},
};

export const outcomesSlice = createSlice({
	name: "outcomes",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getChapterOutcomes.fulfilled, (state, action) => {
			const outcomes = action.payload.outcomes;
			state.byKey = {...state.byKey, ...outcomes};
		});

		builder.addCase(submitStudentResponse.fulfilled, (state, {payload}) => {
			const outcomes = payload.submissionResult.outcomes;

			if (outcomes) {
				updateOutcomes(
					state.byKey,
					outcomes.completionStatus,
					payload.responseKey,
					outcomes.score
				);
			}
		});

		builder.addCase(saveStudentResponse.fulfilled, (state, action) => {
			updateOutcomes(
				state.byKey,
				CompletionStatus.Incomplete,
				action.payload.responseKey
			);
		});

		builder.addCase(resetStudentResponse.fulfilled, (state, action) => {
			updateOutcomes(
				state.byKey,
				CompletionStatus.Incomplete,
				action.payload.responseKey
			);
		});

		builder.addCase(postOutcomes.fulfilled, (state, action) => {
			updateOutcomes(
				state.byKey,
				action.payload.outcomes.completionStatus,
				action.payload.key,
				action.payload.outcomes.score
			);
		});
	},
});

export function updateOutcomes(
	state: {[key: string]: ExerciseOutcomes | undefined},
	status: CompletionStatus,
	key: string,
	score?: number
): void {
	const s = state[key];

	score = score ?? 0;

	if (s) {
		s.completionStatus = status;
		s.score = score;
	} else {
		state[key] = {
			score,
			completionStatus: status,
		};
	}
}

export default outcomesSlice.reducer;
