import {createAsyncThunk} from "@reduxjs/toolkit";

import selfEnrolmentService from "../services/selfEnrolmentService";

const submitEnrolmentApplication = createAsyncThunk(
	"selfEnrolment/submitEnrolmentApplication",
	async (params: {courseId: number; studentId: number; password?: string}) => {
		const {courseId, studentId, password} = params;

		const status = await selfEnrolmentService.submitEnrolmentApplication(
			courseId,
			studentId,
			password
		);

		return {status, courseId};
	}
);

export default submitEnrolmentApplication;
