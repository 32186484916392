import AttachFileIcon from "@mui/icons-material/AttachFile";
import {Box, Button, Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import AttachmentsList from "./AttachmentsList";
import type FileUploader from "./FileUploader";
import type UploadedFile from "./UploadedFile";

const useStyles = makeStyles((theme) =>
	createStyles({
		attachmentsPanel: {
			background: theme.palette.grey[100],
		},
		attachIcon: {
			marginRight: theme.spacing(1.5),
		},
		attachBtn: {
			margin: theme.spacing(0.75, 1.5, 0.25, 1),
		},
	})
);

const Attachments = (props: {
	files: UploadedFile[];
	fileUploader: FileUploader;
	readonly?: boolean;
}): JSX.Element => {
	const classes = useStyles();

	const {fileUploader, files} = props;

	const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = e.target.files;

		if (!fileList) {
			return;
		}

		fileUploader.upload(fileList[0]);
	};

	return (
		<Paper
			variant="outlined"
			square
			className={classes.attachmentsPanel}
			onChange={selectFile}
		>
			<Button
				component="label"
				color="primary"
				className={classes.attachBtn}
				style={{backgroundColor: "transparent"}}
				disabled={props.readonly}
			>
				<Box display="flex" alignItems="flex-start">
					<AttachFileIcon className={classes.attachIcon} fontSize="small" />
					{"Attach file"}
				</Box>
				<input type="file" hidden />
			</Button>
			{files.length > 0 && (
				<Box mt={1} ml={2.5}>
					<AttachmentsList
						items={files}
						onDelete={props.readonly ? undefined : fileUploader.delete}
					/>
				</Box>
			)}
		</Paper>
	);
};

export default Attachments;
