import {Box, Stack} from "@mui/material";
import React, {useRef} from "react";

import EnrolmentApplicationsWidget from "./EnrolmentApplicationsWidget";
import selectCourse from "../../store/courses/selectCourse";
import SelfEnrolmentStatus from "../../store/courses/SelfEnrolmentStatus";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import StudentsWidget from "./StudentsWidget";
import type {StudentsWidgetApi} from "./StudentsWidget";

function StudentManagement(props: {
	organisationName: string;
	courseId: number;
	userId: number;
	viewOnly?: boolean;
}) {
	const course = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(props.courseId))
	);

	const studentSearch = useRef<StudentsWidgetApi>(null);

	return (
		<Box
			sx={{
				padding: {md: 6, xs: 3},
				maxWidth: (theme) => theme.breakpoints.values.xl,
			}}
		>
			<Stack spacing={3}>
				{course?.selfEnrolment === SelfEnrolmentStatus.RequiresApproval && (
					<EnrolmentApplicationsWidget
						courseId={props.courseId}
						viewOnly={props.viewOnly}
						onAccepted={() => {
							studentSearch.current?.reloadPage();
						}}
					/>
				)}

				<StudentsWidget
					apiRef={studentSearch}
					organisationName={props.organisationName}
					courseId={props.courseId}
					userId={props.userId}
					viewOnly={props.viewOnly}
				/>
			</Stack>
		</Box>
	);
}

export default StudentManagement;
