import {
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";
import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import {FixedSizeList, FixedSizeListProps} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import {ExerciseFromBank} from "../../store/services/dtos/ExerciseFromBank";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import parseDate from "../../helpers/parseDate";
import TableContext from "./TableContext";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import SortOrder from "../../utils/tables/SortOrder";
import ExerciseTypeLabel from "../exerciseBank/ExerciseTypeLabel";
import ExercisePreviewTooltip from "../exerciseBank/ExercisePreviewTooltip";
import HeadCell from "../../utils/tables/HeadCell";
import SortingHeader from "../../utils/tables/SortingHeader";
import useMobileMode from "../../hooks/useMobileMode";

interface Props
	extends Omit<FixedSizeListProps, "children" | "innerElementType"> {
	row: FixedSizeListProps["children"];

	onOrderChange: (orderBy: keyof ExerciseFromBank, order: SortOrder) => void;
	orderBy: keyof ExerciseFromBank;
	order: SortOrder;
}

function VTable(
	{row, onOrderChange, order, orderBy, ...rest}: Props,
	ref: React.ForwardedRef<unknown>
) {
	const listRef = useRef<
		(FixedSizeList & {_getItemStyle: (index: number) => {top: number}}) | null
	>();

	const [top, setTop] = useState(0);

	const setRefs = useCallback(
		(node) => {
			if (typeof ref === "function") {
				ref(node);
			}
			listRef.current = node;
		},
		[ref]
	);

	useEffect(() => {
		if (rest.itemCount === 0) {
			setTop(0);
		}
	}, [rest.itemCount]);

	return (
		<TableContext.Provider value={{top, order, orderBy, onOrderChange}}>
			<FixedSizeList
				{...rest}
				innerElementType={TableLayout}
				onItemsRendered={(props) => {
					const style =
						listRef.current &&
						listRef.current._getItemStyle(props.overscanStartIndex);

					setTop((style && style.top) || 0);
					rest.onItemsRendered && rest.onItemsRendered(props);
				}}
				ref={setRefs}
				overscanCount={3}
			>
				{row}
			</FixedSizeList>
		</TableContext.Provider>
	);
}
const VirtualTable = React.forwardRef(VTable);

const headCells: HeadCell<ExerciseFromBank>[] = [
	{
		id: "title",
		label: "exercise-bank-table-heading-title",
		sortable: true,
		width: 210,
	},
	{
		id: "type",
		label: "exercise-bank-table-heading-type",
		sortable: false,
		width: 210,
	},
	{
		id: "authorName",
		label: "exercise-bank-table-heading-author",
		sortable: true,
		width: 125,
	},
	{
		id: "created",
		label: "exercise-bank-table-heading-created",
		sortable: true,
		width: 125,
	},
];

const headCellsSmallScreen: HeadCell<ExerciseFromBank>[] = [
	{
		id: "title",
		label: "exercise-bank-table-heading-title",
		sortable: true,
		width: 210,
	},
	{
		id: "type",
		label: "exercise-bank-table-heading-type",
		sortable: false,
		width: 50,
	},
	{
		id: "authorName",
		label: "exercise-bank-table-heading-author",
		sortable: true,
		width: 125,
	},
];

function TLayout(
	props: {children: ReactNode} & React.HTMLProps<HTMLDivElement>,
	ref: React.ForwardedRef<HTMLDivElement>
) {
	const {children, ...rest} = props;

	const {top, order, orderBy, onOrderChange} = useContext(TableContext);

	const mobileMode = useMobileMode("md");

	return (
		<TableContainer {...rest} ref={ref}>
			<Table stickyHeader style={{top, position: "absolute", width: "100%"}}>
				<SortingHeader
					onOrderChange={onOrderChange}
					order={order}
					orderBy={orderBy}
					headCells={mobileMode ? headCellsSmallScreen : headCells}
					leftAnnex={<TableCell padding="checkbox" />}
				/>
				<TableBody>{children}</TableBody>
			</Table>
		</TableContainer>
	);
}
const TableLayout = React.forwardRef(TLayout);

function TableOfExercises(props: {
	hasNextPage: boolean;
	isNextPageLoading: boolean;
	items: ExerciseFromBank[];
	loadNextPage: (startIndex: number, stopIndex: number) => Promise<void>;
	height: number;

	orderBy: keyof ExerciseFromBank;
	order: SortOrder;
	onOrderChange: (orderBy: keyof ExerciseFromBank, order: SortOrder) => void;

	onExerciseSelected: (index: number) => void;
	selectedExercises: number[];
	chapterId: number;
}): JSX.Element {
	const {hasNextPage, isNextPageLoading, items, loadNextPage} = props;

	const itemCount = hasNextPage ? items.length + 1 : items.length;

	const loadMoreItems = isNextPageLoading ? noop : loadNextPage;

	const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

	const formatDate = useDateTimeFormat();
	const mobileMode = useMobileMode("md");

	const Row = ({index}: {index: number}) => {
		let exerciseKey = "";
		if (index < items.length) {
			exerciseKey = keyProvider.chapterExercise(
				props.chapterId,
				items[index].id
			);
		}

		const added = useAppSelector(
			(state) => exerciseKey in state.chapterExercises.byKey
		);

		let content;
		if (!isItemLoaded(index)) {
			content = <></>;
		} else {
			const exercise = items[index];
			const selected = props.selectedExercises.includes(exercise.id);

			content = (
				<>
					<TableCell padding="checkbox">
						<Checkbox
							onChange={() => props.onExerciseSelected(index)}
							checked={selected || added}
							disabled={added}
						/>
					</TableCell>
					<TableCell style={{width: 210}}>
						<ExercisePreviewTooltip
							exercise={{
								title: exercise.title,
								tags: exercise.tags,
							}}
						>
							<div
								style={{
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									overflow: "hidden",
									width: "inherit",
								}}
							>
								{exercise.title}
							</div>
						</ExercisePreviewTooltip>
					</TableCell>
					<TableCell style={{whiteSpace: "nowrap"}}>
						<ExerciseTypeLabel
							type={exercise.type}
							size={mobileMode ? "small" : "default"}
						/>
					</TableCell>
					<TableCell padding="checkbox">{exercise.authorName}</TableCell>
					{!mobileMode && (
						<TableCell style={{whiteSpace: "nowrap"}}>
							{formatDate(parseDate(exercise.created))}
						</TableCell>
					)}
				</>
			);
		}

		return <TableRow hover>{content}</TableRow>;
	};

	return (
		<InfiniteLoader
			isItemLoaded={isItemLoaded}
			itemCount={itemCount}
			loadMoreItems={loadMoreItems}
		>
			{({onItemsRendered, ref}) => (
				<VirtualTable
					height={props.height}
					itemCount={itemCount}
					itemSize={rowHeight()}
					onItemsRendered={onItemsRendered}
					ref={ref}
					width="100%"
					row={Row}
					onOrderChange={props.onOrderChange}
					order={props.order}
					orderBy={props.orderBy}
				/>
			)}
		</InfiniteLoader>
	);
}

function rowHeight() {
	const fontSize: number = parseInt(
		getComputedStyle(document.documentElement).fontSize
	);

	const rowPadding = 32;
	const boder = 1;

	return fontSize * 1.5 + rowPadding + boder;
}

function noop() {
	return;
}

export default TableOfExercises;
