import {useMemo} from "react";
import {contentService} from "../../store/services/contentService";
import type FileLoader from "../../utils/attachments/FileLoader";
import DocumentFormat from "../../utils/attachments/DocumentFormat";
import type UploadedFile from "../../utils/attachments/UploadedFile";

const useQuestionFileLoader = (
	courseId: number,
	messageId?: number,
	studentId?: number
): FileLoader | undefined => {
	const loader = useMemo(() => {
		return messageId && studentId
			? {
					async loadAll(): Promise<UploadedFile[]> {
						const files = await contentService.getAll({
							documentFormat: DocumentFormat.TutorQuestion,
							questionId: messageId,
							courseId,
							personId: studentId,
						});
						return files;
					},
			  }
			: undefined;
	}, [courseId, messageId, studentId]);

	return loader;
};

export default useQuestionFileLoader;
