import {useMemo} from "react";

import makeURL from "./makeURL";
import type ProctoringSettings from "../../store/proctoring/ProctoringSettings";

function useProctoringSystemRegistrationLink(
	settings: ProctoringSettings,
	studentId: number,
	returnLink: string
) {
	return useMemo(
		() =>
			makeURL("https://swl.smowltech.net/monitor/controllerReg.php", {
				entity_Name: settings.entityName,
				swlLicenseKey: settings.licenceKey,
				user_idUser: studentId.toString(),
				lang: "en",
				Course_link: returnLink,
			}),
		[returnLink, settings.entityName, settings.licenceKey, studentId]
	);
}
export default useProctoringSystemRegistrationLink;
