import type {PayloadAction, SerializedError} from "@reduxjs/toolkit";

import type FetchStatus from "./FetchStatus";

function inferFetchStatusFromError(
	action: PayloadAction<unknown, string, never, SerializedError>
): FetchStatus {
	if (action.error.code === "unauthorised") {
		return "none";
	}

	return "failed";
}

export default inferFetchStatusFromError;
