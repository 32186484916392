import {createAsyncThunk} from "@reduxjs/toolkit";

import {courseService} from "../services/courseService";
import type AnswerVisibility from "../chapterExercises/AnswerVisibility";
import type FeedbackVisibility from "./FeedbackVisibility";

const updateChapter = createAsyncThunk(
	"chapters/updateChapter",
	async function (params: {
		courseId: number;
		chapterId: number;
		title?: string;
		endDate?: string | null;
		sessionLimit?: number | null;
		timeLimit?: number | null;
		answerVisibility?: AnswerVisibility | null;
		feedbackVisibility?: FeedbackVisibility;
	}) {
		const {
			chapterId,
			courseId,
			title,
			endDate,
			sessionLimit,
			timeLimit,
			answerVisibility,
			feedbackVisibility,
		} = params;

		await courseService.updateChapter(courseId, chapterId, {
			title,
			endDate,
			sessionLimit,
			timeLimit,
			answerVisibility,
			feedbackVisibility,
		});

		return {
			id: chapterId,
			title,
			endDate,
			sessionLimit,
			timeLimit,
			answerVisibility,
			feedbackVisibility,
		};
	}
);

export default updateChapter;
