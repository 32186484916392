import React from "react";
import {Box, IconContainerProps, Rating, Typography} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {withStyles} from "@mui/styles";
import {Localized} from "@fluent/react";

const BoxedRating = withStyles((theme) => ({
	icon: {
		width: `calc(${theme.typography.button.fontSize} * ${theme.typography.button.lineHeight} + 12px)`,
		height: `calc(${theme.typography.button.fontSize} * ${theme.typography.button.lineHeight} + 12px)`,
		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: `rgba(0, 0, 0, 0.25)`,
		fontSize: "1rem",
		"& > *": {
			margin: "auto",
		},
	},
	iconActive: {
		transform: "none",
	},
	iconFilled: {
		backgroundColor: alpha(theme.palette.secondary.main, 0.25),
	},
	iconHover: {
		backgroundColor: alpha(theme.palette.secondary.main, 0.25),
	},
	label: {
		"&:first-of-type $icon": {
			borderTopLeftRadius: theme.shape.borderRadius,
			borderBottomLeftRadius: theme.shape.borderRadius,
		},
		"&:last-of-type $icon": {
			borderTopRightRadius: theme.shape.borderRadius,
			borderBottomRightRadius: theme.shape.borderRadius,
		},
	},
}))(Rating);

const numberedBox = (props: IconContainerProps) => {
	const {value, ...other} = props;
	return (
		<span {...other}>
			<Typography variant="button" color="textPrimary">
				{value}
			</Typography>
		</span>
	);
};

const ExerciseDifficultySelector = (props: {
	name: string;
	value: number | null;
	onChange: (value: number | null) => void;
}): JSX.Element => {
	return (
		<Box display="flex" alignItems="flex-end" style={{gap: 16}}>
			<Typography>
				<Localized id="exercise-editor-difficulty-selector-label">
					Difficulty level
				</Localized>
			</Typography>
			<BoxedRating
				name={props.name}
				value={props.value}
				onChange={(_, value) => props.onChange(value)}
				getLabelText={(value) => String(value)}
				IconContainerComponent={numberedBox}
			/>
		</Box>
	);
};

export default ExerciseDifficultySelector;
