import {Localized, useLocalization} from "@fluent/react";
import {Box, TextField, useTheme} from "@mui/material";
import React from "react";

import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import OrganisationSelector from "../organisations/OrganisationSelector";
import SidebarBody from "../sidebars/SidebarBody";
import SidebarHeader from "../sidebars/SidebarHeader";
import SidebarSection from "../sidebars/SidebarSection";
import type {GroupUserSearchCriteria} from "../../store/services/userService";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import UserRoleSelector from "./UserRoleSelector";

function GroupUsersFilters(props: {
	open: boolean;
	width: number;
	mobileMode: boolean;

	groupName: string;
	criteria: GroupUserSearchCriteria;

	onClose: () => void;
	onCriteriaChange: (
		set: (prev: GroupUserSearchCriteria) => GroupUserSearchCriteria
	) => void;
}) {
	const {onCriteriaChange} = props;

	const {l10n} = useLocalization();
	const theme = useTheme();

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			topFixedPanel={
				<SidebarHeader
					title={l10n.getString("group-users-filters")}
					onClose={props.onClose}
				/>
			}
			onClose={props.onClose}
		>
			<SidebarBody>
				<SidebarSection title={l10n.getString("group-users-filters-roles")}>
					<UserRoleSelector
						selected={criteria.roles ?? []}
						onChange={(roles) => setCriteria((prev) => ({...prev, roles}))}
					/>
				</SidebarSection>
				<SidebarSection
					title={l10n.getString("group-users-filters-organisations")}
				>
					<OrganisationSelector
						groupName={props.groupName}
						onChange={(organisationNames) =>
							setCriteria((prev) => ({...prev, organisationNames}))
						}
					/>
				</SidebarSection>
				<SidebarSection
					title={l10n.getString("group-users-filters-last-login")}
				>
					<Box display="flex">
						<TextField
							fullWidth
							type="date"
							id="last-login-from"
							label={
								<Localized id="group-users-filters-last-login-from">
									From
								</Localized>
							}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								marginRight: theme.spacing(1),
							}}
							value={criteria.lastLoginAfter ?? ""}
							onChange={(event) =>
								setCriteria((prev) => ({
									...prev,
									lastLoginAfter: event.target.value,
								}))
							}
							InputProps={{inputProps: {max: criteria.lastLoginBefore}}}
						/>
						<TextField
							fullWidth
							type="date"
							id="last-login-to"
							label={
								<Localized id="group-users-filters-last-login-to">To</Localized>
							}
							InputLabelProps={{
								shrink: true,
							}}
							value={criteria.lastLoginBefore ?? ""}
							onChange={(event) =>
								setCriteria((prev) => ({
									...prev,
									lastLoginBefore: event.target.value,
								}))
							}
							InputProps={{inputProps: {min: criteria.lastLoginAfter}}}
						/>
					</Box>
				</SidebarSection>
			</SidebarBody>
		</AppBarDependentDrawer>
	);
}

export default GroupUsersFilters;
