import {useLocalization} from "@fluent/react";
import React, {useRef, useState} from "react";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import TextEditor from "../../utils/TextEditor";
import {useHistory, useParams} from "react-router-dom";
import {addSectionItem} from "../../store/sections/addSectionItem";
import {useAppDispatch} from "../../store/hooks";
import {unwrapResult} from "@reduxjs/toolkit";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import {ModalDialogName, openDialog} from "../../store/ui/modalDialogSlice";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			color: theme.palette.primary.main,
		},
		buttonSave: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			marginLeft: theme.spacing(4),
		},
		textDark: {
			color: theme.palette.primary.dark,
		},
		description: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
	})
);

type Params = {
	id: string;
	chapterId: string;
	sectionId: string;
};

const NewSectionEditor = (): JSX.Element => {
	const classes = useStyles();

	const {id: courseId, chapterId} = useParams<Params>();

	const history = useHistory();
	const dispatch = useAppDispatch();

	const {l10n} = useLocalization();
	const defaultTitle = l10n.getString(
		"learning-material-section-management-add-section-title-default-text",
		null,
		"New section"
	);
	const [title, setTitle] = useState(defaultTitle);
	const description = useRef<TextEditorApi | null>(null);

	const saveResponseHandler = async () => {
		const numCourseId = Number(courseId);
		const numChapterId = Number(chapterId);
		const result = await dispatch(
			addSectionItem({
				courseId: numCourseId,
				chapterId: numChapterId,
				sectionItem: {
					title: title,
					content: description.current?.getContent() ?? "",
				},
			})
		);
		dispatch(
			getChapterContent({
				courseId: numCourseId,
				chapterId: numChapterId,
			})
		);
		const {id} = unwrapResult(result);

		const sectionPath = history.location.pathname.replace("/new", `/${id}`);
		history.push(sectionPath);

		dispatch(
			openDialog({
				dialogName: ModalDialogName.AddExercise,
				dialogParams: {
					chapterId: numChapterId,
					courseId: numCourseId,
					sectionId: id,
				},
			})
		);
	};

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item xs={12}>
				<TextField
					value={title}
					style={{display: "flex"}}
					id="section title"
					label={l10n.getString(
						"learning-material-section-management-add-section-title-default-text-label",
						null,
						"Section title"
					)}
					onChange={(e) => setTitle(e.target.value)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography className={classes.description}>
					{l10n.getString(
						"learning-material-section-management-add-section-label-description",
						null,
						"Description"
					)}{" "}
					<span className={classes.textDark}>
						{l10n.getString(
							"learning-material-section-management-add-section-label-optional",
							null,
							"optional"
						)}
					</span>
				</Typography>
				<TextEditor ref={description} />
			</Grid>
			<Grid
				item
				container
				justifyContent="flex-end"
				direction="row"
				alignContent="center"
			>
				<Grid item>
					<Box>
						<Button className={classes.button} onClick={() => history.goBack()}>
							{l10n.getString(
								"learning-material-section-management-add-section-button-cancel",
								null,
								"Cancel"
							)}
						</Button>
						<Button
							className={classes.buttonSave}
							onClick={saveResponseHandler}
							variant="contained"
						>
							{l10n.getString(
								"learning-material-section-management-add-section-button-save-add-subsections",
								null,
								"Save and add subsections"
							)}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default NewSectionEditor;
