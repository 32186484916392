import type {AxiosInstance} from "axios";

type LinkRel =
	| "self"
	| "first"
	| "last"
	| "next"
	| "previous"
	| "http://viope.com/relations/ids";

type LinkToPage = Exclude<LinkRel, "self" | "http://viope.com/relations/ids">;

type RequestInPage<T, K = number> = {
	[key in LinkToPage]?: () => Promise<Page<T, K>>;
};

type Page<T, K = number> = {
	content: T[];
	reload?: () => Promise<Page<T, K>>;
	allIds?: () => Promise<{content: K[]}>;
	request: RequestInPage<T, K>;
};

type Link = {
	rel: LinkRel;
	href: string;
};

type PagedResponse<T> = {
	content: T[];
	links: Link[];
};

type Options<S, T> = {preprocessItem: (v: S) => T} | Record<string, never>;

type Processed<O, S> = O extends {preprocessItem: (v: S) => infer T} ? T : S;

function createPage<T, K = number, S = T>(
	client: AxiosInstance,
	content: S[],
	links: Link[],
	options?: Options<S, T>
): Page<Processed<typeof options, S>, K> {
	function createPagedLinkHandler(link: Link) {
		return async function () {
			return client.get<PagedResponse<S>>(link.href).then((response) => {
				if (response.status !== 200) {
					throw new Error();
				}
				return createPage<T, K, S>(
					client,
					response.data.content,
					response.data.links,
					options
				);
			});
		};
	}

	function createLinkHandler<TRes>(link: Link) {
		return async function () {
			return client.get<TRes>(link.href).then((response) => {
				if (response.status !== 200) {
					throw new Error();
				}
				return response.data;
			});
		};
	}

	const page: Page<Processed<typeof options, S>, K> = {
		content:
			options && "preprocessItem" in options
				? content.map(options.preprocessItem)
				: content,
		request: {},
	};

	for (let i = 0; i < links.length; i++) {
		const link = links[i];

		switch (link.rel) {
			case "self":
				page.reload = createPagedLinkHandler(link);
				break;
			case "http://viope.com/relations/ids":
				page.allIds = createLinkHandler(link);
				break;
			default:
				page.request[link.rel] = createPagedLinkHandler(link);
				break;
		}
	}

	return page;
}

function createEmptyPage<T, K = number>(): Page<T, K> {
	return {content: [], request: {}};
}

export {createEmptyPage, createPage};
export type {LinkRel, LinkToPage, Page, PagedResponse, RequestInPage};
