import {createAsyncThunk} from "@reduxjs/toolkit";

import type ExercisePatch from "./ExercisePatch";
import exerciseService from "../services/exerciseService";

const patchExercise = createAsyncThunk(
	"exercises/patchExercise",
	async (params: {exerciseId: number; patch: ExercisePatch}) => {
		const res = await exerciseService.patchExercise(
			params.exerciseId,
			params.patch
		);

		return {id: params.exerciseId, exercise: res};
	}
);

export default patchExercise;
