import {InputLabel} from "@mui/material";
import {inputClasses} from "@mui/material/Input";
import {createStyles, makeStyles} from "@mui/styles";
import {Editor} from "@tinymce/tinymce-react";
import type {IAllProps as EditorProps} from "@tinymce/tinymce-react";
import clsx from "clsx";
import React, {
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import tinymce, {AstNode, Editor as TinyEditor} from "tinymce";

import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.min.css";

import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/directionality";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/paste";
import "tinymce/plugins/print";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/tabfocus";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";

import defaultUiStyles from "tinymce/skins/ui/oxide/content.min.css";
import defaultStyles from "tinymce/skins/content/default/content.min.css";
import contentStyles from "./content.css";

import AbittiEditor, {EditorApi as AbittiEditorApi} from "../AbittiEditor";
import type FileUploader from "../attachments/FileUploader";
import {registerInsertIframe} from "./insertResponsiveIframe";
import {createMediaUrlResolver} from "./mediaUrlResolver";

import useCurrentLocale from "../../i18n/useCurrentLocale";
import {defaultLocale} from "../../i18n/locales";

type EditorSettings = EditorProps["init"];

type ImageUploadHandler = (
	blobInfo: {
		blob: () => Blob & {
			name?: string;
		};
		filename: () => string;
	},
	success: (url: string) => void,
	failure: (err: string) => void
) => void;

const editorHeight = 450;

const useStyles = makeStyles((theme) =>
	createStyles({
		editor: {
			position: "relative",
		},
		inlineEditor: {
			...theme.typography.body1,
			padding: "6px 0 7px",
			lineHeight: "1.1876em",
			"& .mce-content-body": {
				maxHeight: editorHeight,
				overflow: "auto",
				"& p": {
					margin: 0,
				},
			},
			"& .mce-content-body:focus": {
				outline: "none",
			},
		},
		// Based on https://github.com/mui/material-ui/blob/v6.1.0/packages/mui-material/src/Input/Input.js#L70 (lines 70-110).
		underlined: {
			"&::after": {
				left: 0,
				bottom: 0,
				content: '""',
				position: "absolute",
				right: 0,
				transform: "scaleX(0)",
				transition: theme.transitions.create("transform", {
					duration: theme.transitions.duration.shorter,
					easing: theme.transitions.easing.easeOut,
				}),
				pointerEvents: "none",
				borderBottom: `2px solid ${theme.palette.primary.main}`,
			},
			[`&.${inputClasses.focused}:after`]: {
				transform: "scaleX(1) translateX(0)",
			},
			"&::before": {
				borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
				left: 0,
				bottom: 0,
				content: '"\\00a0"',
				position: "absolute",
				right: 0,
				transition: theme.transitions.create("border-bottom-color", {
					duration: theme.transitions.duration.shorter,
				}),
				pointerEvents: "none",
			},
			[`&:hover:not(.${inputClasses.disabled}, .${inputClasses.error}):before`]: {
				borderBottom: `2px solid ${theme.palette.text.primary}`,
				"@media (hover: none)": {
					borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
				},
			},
		},
		backdrop: (props: {fullscreen: boolean}) => ({
			position: props.fullscreen ? "fixed" : "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			backgroundColor: "white",
			opacity: 0.5,
			zIndex: theme.zIndex.drawer + 100,
		}),
		mathEditor: (props: {fullscreen: boolean; offset: string}) => ({
			position: props.fullscreen ? "fixed" : "absolute",
			left: 0,
			top: props.offset,
			width: "100%",
			backgroundColor: "white",
			zIndex: theme.zIndex.drawer + 100,
		}),
	})
);

const fontFormats =
	"Andale Mono=andale mono,times; " +
	"Arial=arial,helvetica,sans-serif; " +
	"Arial Black=arial black,avant garde; " +
	"Book Antiqua=book antiqua,palatino; " +
	"Comic Sans MS=comic sans ms,sans-serif; " +
	"Courier New=courier new,courier; " +
	"Georgia=georgia,palatino; " +
	"Helvetica=helvetica; " +
	"Impact=impact,chicago; " +
	"Roboto=roboto,helvetica,arial,sans-serif; " +
	"Symbol=symbol; " +
	"Tahoma=tahoma,arial,helvetica,sans-serif; " +
	"Terminal=terminal,monaco; " +
	"Times New Roman=times new roman,times; " +
	"Trebuchet MS=trebuchet ms,geneva; " +
	"Verdana=verdana,geneva; " +
	"Webdings=webdings; " +
	"Wingdings=wingdings,zapf dingbats";

function createSettings(
	setup: (editor: TinyEditor) => void,
	language: string,
	mode?: "full" | "basic" | "inline",
	onImageUpload?: ImageUploadHandler,
	onResolveMediaUrl?: (
		data: {url: string},
		resolve: (success: {html: string}) => void,
		reject: (error: {msg: string}) => void
	) => void
): EditorSettings {
	let languageSettings;
	if (language !== defaultLocale) {
		languageSettings = {
			language,
			language_url: `/locales/${language}/text-editor.js`,
		};
	}

	const settings: EditorSettings = {
		elementpath: false,
		branding: false,
		font_formats: fontFormats,
		height: editorHeight,
		convert_fonts_to_spans: true,
		remove_script_host: true,
		relative_urls: false,
		default_link_target: "_blank",
		link_default_protocol: "https",
		toolbar_items_size: "small",
		content_css: false,
		content_style: [
			'@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");',
			defaultStyles,
			defaultUiStyles,
			contentStyles,
		].join("\n"),

		skin: false,
		images_upload_handler: onImageUpload,
		media_url_resolver: onResolveMediaUrl,
		paste_data_images: true,
		imagetools_toolbar: "imageoptions",
		setup: setup,
		...languageSettings,
	};

	if (mode === "basic") {
		return {
			...settings,
			statusbar: false,
			menubar: false,
			toolbar:
				"undo redo | math image link | bold italic underline strikethrough | " +
				"oudent indent | bullist numlist",
			plugins: ["autolink image link lists media paste tabfocus"],
		};
	}

	if (mode === "inline") {
		return {
			...settings,
			inline: true,
			menubar: false,
			font_size_style_values: "8pt,10pt,12pt,14pt,18pt,24pt,36pt",
			toolbar:
				"cut copy paste | undo redo | image math link media iframe | bullist numlist | " +
				"alignleft aligncenter alignright alignjustify | bold italic underline strikethrough code | " +
				"forecolor backcolor | styleselect | fontselect | fontsizeselect | fullscreen",
			plugins: [
				"anchor autolink charmap code",
				"directionality fullscreen hr image",
				"link lists media nonbreaking noneditable pagebreak paste",
				"print save searchreplace spellchecker tabfocus table template visualblocks visualchars",
			],
		};
	}

	return {
		...settings,
		min_height: 200,
		menubar: "edit insert format table",
		menu: {
			edit: {
				title: "Edit",
				items: "undo redo | cut copy paste | selectall | searchreplace",
			},
			insert: {
				title: "Insert",
				items: "link media image | charmap hr pagebreak nonbreaking anchor",
			},
			format: {
				title: "Format",
				items:
					"bold italic underline strikethrough superscript subscript | removeformat",
			},
			table: {
				title: "Table",
				items: "inserttable tableprops deletetable | cell row column",
			},
		},
		font_size_style_values: "8pt,10pt,12pt,14pt,18pt,24pt,36pt",
		toolbar:
			"cut copy paste | undo redo | image math link media iframe | bullist numlist | " +
			"alignleft aligncenter alignright alignjustify | bold italic underline strikethrough code | " +
			"forecolor backcolor | styleselect | fontselect | fontsizeselect | fullscreen",
		plugins: [
			"anchor autolink charmap code",
			"directionality fullscreen hr image",
			"link lists media nonbreaking noneditable pagebreak paste",
			"print save searchreplace spellchecker tabfocus table template visualblocks visualchars",
		],
	};
}

function createImageUploadHandler(uploader: FileUploader): ImageUploadHandler {
	return function (blobInfo, success, failure) {
		const file = blobInfo.blob();

		let uploadName;

		if (
			!file.name ||
			(file.name !== blobInfo.filename() && file.name === "image.png")
		) {
			uploadName = blobInfo.filename();
			const i = uploadName.lastIndexOf(".");
			uploadName = i < 0 ? "" : uploadName.substring(i);
			uploadName = "image" + String(Date.now()) + uploadName;
		}

		uploader
			.upload(file, uploadName)
			.then((res) => {
				success(res.url);
			})
			.catch(() => failure("File upload error"));
	};
}

export type TextEditorProps = {
	readonly?: boolean;
	onChange?: (newValue: string) => void;
	value?: string;
	initialValue?: string;
	fileUploader?: FileUploader;
	mode?: "full" | "basic" | "inline";
	id?: string;
	label?: string;
};

export type TextEditorApi = {
	getContent: () => string;
};

const TextEditor = (
	props: TextEditorProps,
	ref: React.ForwardedRef<TextEditorApi>
): JSX.Element => {
	const editorRef = useRef<TinyEditor | null>(null);
	const mathEditorRef = useRef<AbittiEditorApi | null>(null);

	useImperativeHandle(ref, () => ({
		getContent() {
			return editorRef.current?.getContent() ?? "";
		},
	}));

	const [fullscreen, setFullscreen] = useState(false);

	const [mathEditor, setMathEditor] = useState({
		offset: "0px",
		visible: false,
		initialValue: "",
	});

	const classes = useStyles({
		fullscreen: fullscreen,
		offset: mathEditor.offset,
	});

	const parser = useMemo(() => {
		const parser = new tinymce.html.DomParser();

		parser.addNodeFilter("img", function (nodes: AstNode[]) {
			let i = nodes.length;
			while (i--) {
				const node = nodes[i];
				node.attr({
					"data-v-latex-math": "",
					"data-mce-placeholder": "",
					"data-mce-resize": "false",
				});
			}
		});

		return parser;
	}, []);
	const serialiser = useMemo(() => new tinymce.html.Serializer(), []);

	useEffect(() => {
		if (mathEditor.visible) {
			mathEditorRef.current?.focus();
		}
	}, [mathEditor.visible]);

	const setup = (editor: TinyEditor) => {
		editorRef.current = editor;

		editor.on("FullscreenStateChanged", function (event: {state: boolean}) {
			setFullscreen(event.state);
		});

		editor.ui.registry.addIcon(
			"functions",
			'<svg height="24px" width="24px"><path d="M18 4H6v2l6.5 6L6 18v2h12v-3h-7l5-5-5-5h7V4z"/></svg>'
		);
		editor.ui.registry.addToggleButton("math", {
			icon: "functions",
			tooltip: "Insert/edit formula",
			onAction: function () {
				const editorTop = editor.getContainer().getBoundingClientRect().top;
				const iframeTop =
					editor.getContainer().querySelector("iframe")?.getBoundingClientRect()
						.top ?? 0;
				const selectionTop = editor.selection.getNode().getBoundingClientRect()
					.top;

				setMathEditor({
					offset: iframeTop - editorTop + selectionTop + "px",
					visible: true,
					initialValue: editor.selection.getContent({format: "html"}),
				});
			},
			onSetup: function (button) {
				const handler = () => {
					const formulaSelected =
						editor.selection.getNode().getAttribute("data-v-latex-math") !==
						null;
					button.setActive(formulaSelected);
					button.setDisabled(
						!editor.selection.isCollapsed() && !formulaSelected
					);
				};

				editor.on("NodeChange", handler);

				return () => {
					editor.off("NodeChange", handler);
				};
			},
		});

		editor.on("PreInit", function () {
			editor.parser.addAttributeFilter("data-v-latex-math", function (nodes) {
				let i = nodes.length;
				while (i--) {
					const node = nodes[i];
					node.attr({
						"data-mce-placeholder": "",
						"data-mce-resize": "false",
					});
				}
			});

			editor.serializer.addAttributeFilter(
				"data-v-latex-math",
				function (nodes) {
					let i = nodes.length;
					while (i--) {
						const node = nodes[i];
						node.attr({
							"data-mce-placeholder": null,
							"data-mce-resize": null,
						});
					}
				}
			);
		});

		registerInsertIframe(editor);
	};

	const language = useCurrentLocale();

	const editorSettings = useMemo(() => {
		return createSettings(
			setup,
			language,
			props.mode,
			props.fileUploader && createImageUploadHandler(props.fileUploader),
			createMediaUrlResolver(editorRef.current)
		);
	}, [language, props.fileUploader, props.mode]);

	const [focused, setFocused] = useState(false);
	let editor = (
		<Editor
			id={props.id}
			initialValue={props.initialValue}
			disabled={props.readonly}
			value={props.value}
			init={editorSettings}
			onEditorChange={props.onChange}
			onFocusIn={() => setFocused(true)}
			onFocusOut={() => setFocused(false)}
		/>
	);
	if (props.mode === "inline") {
		editor = (
			<>
				{props.label && (
					<InputLabel htmlFor={props.id} shrink focused={focused}>
						{props.label}
					</InputLabel>
				)}
				<div
					className={clsx(classes.inlineEditor, classes.underlined, {
						[inputClasses.focused]: focused,
					})}
				>
					{editor}
				</div>
			</>
		);
	}

	return (
		<div className={classes.editor}>
			{editor}
			{mathEditor.visible && (
				<>
					<div className={classes.backdrop}></div>
					<div className={classes.mathEditor}>
						<AbittiEditor
							initialValue={mathEditor.initialValue}
							ref={mathEditorRef}
							onBlur={() => {
								const value = serialiser.serialize(
									parser.parse(mathEditorRef.current?.content() ?? "")
								);

								editorRef.current?.selection.setContent(value);
								editorRef.current?.save();

								setMathEditor((prev) => ({
									...prev,
									visible: false,
									initialValue: "",
								}));
							}}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default React.forwardRef(TextEditor);
