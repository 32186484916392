import ClearIcon from "@mui/icons-material/Clear";
import {Box, IconButton, Typography, lighten} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

const useAdditionalFileStyles = makeStyles((theme) =>
	createStyles({
		fileNameBtn: {
			cursor: "pointer",
			"&:hover": {
				color: lighten(theme.palette.primary.main, 0.5),
			},
		},
	})
);

function ProgAdditionalFile(props: {
	file: string;
	onClick?: () => void;
	onDelete: () => void;
}): JSX.Element {
	const classes = useAdditionalFileStyles();
	const {file, onClick, onDelete} = props;
	return (
		<Box display="flex" alignItems="center" pt={1} pb={1}>
			<Typography
				color="primary"
				onClick={onClick}
				className={classes.fileNameBtn}
			>
				{file}
			</Typography>
			<IconButton size="small" onClick={onDelete}>
				<ClearIcon />
			</IconButton>
		</Box>
	);
}

export default ProgAdditionalFile;
