import {RootState} from "../store";
import CourseStats from "./CourseStats";

function selectCourseStats(
	state: RootState,
	courseKey: string
): CourseStats | null {
	return state.courseStatistics.byKey[courseKey]?.stats ?? null;
}

export default selectCourseStats;
