import {createAsyncThunk} from "@reduxjs/toolkit";
import {courseService} from "../services/courseService";
import exerciseService from "../services/exerciseService";
import type {EditableExercise} from "../services/dtos/EditableExercise";
import {mapToExerciseWithContent} from "../services/dtos/EditableExercise";
import type {ExerciseWithContent} from "./Exercise";

export const saveExercise = createAsyncThunk<
	{exerciseId: number; exercise: ExerciseWithContent},
	{
		courseId: number;
		exerciseId?: number;
		exercise: EditableExercise;
	}
>("exercise/saveExercise", async ({courseId, exercise, exerciseId}) => {
	if (!exerciseId) {
		exerciseId = await courseService.createExercise(courseId, exercise);
	} else {
		await courseService.updateExercise(courseId, exerciseId, exercise);
	}

	await exerciseService.updateExerciseTags(exerciseId, exercise.tags);

	return {
		exerciseId,
		exercise: mapToExerciseWithContent(exerciseId, exercise),
	};
});
