import {useCallback, useMemo} from "react";

import Feature from "./Feature";
import {useAppSelector} from "../hooks";
import selectFrontEndFeatures, {
	selectFrontEndFeaturesFetchStatus,
} from "./selectFrontEndFeatures";
import selectOrganisationFeatures, {
	selectOrganisationFeaturesFetchStatus,
} from "./selectOrganisationFeatures";

function commonFeature(f: Feature) {
	return f === Feature.ExternalExercises;
}

function useFeatureEnabled(): [(name: Feature) => boolean, boolean] {
	const features = useAppSelector(selectFrontEndFeatures);
	const status = useAppSelector(selectFrontEndFeaturesFetchStatus);

	const organisationFeatures = useAppSelector(selectOrganisationFeatures);
	const orgStatus = useAppSelector(selectOrganisationFeaturesFetchStatus);

	const activeFeatures = useMemo(() => {
		const set = new Set<Feature>();

		features.forEach((feature) => {
			if (!commonFeature(feature) || organisationFeatures.includes(feature)) {
				set.add(feature);
			}
		});

		organisationFeatures.forEach((feature) => {
			if (!commonFeature(feature)) {
				set.add(feature);
			}
		});

		return set;
	}, [features, organisationFeatures]);

	const enabled = useCallback((name: Feature) => activeFeatures.has(name), [
		activeFeatures,
	]);

	const pending =
		status === "none" ||
		status === "pending" ||
		orgStatus === "none" ||
		orgStatus === "pending";

	return [enabled, pending];
}

export default useFeatureEnabled;
