import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "../store";
import {requiresExplicitSessionStart} from "./Chapter";

const empty: string[] = [];

const selectChaptersWhichRequireExplicitSessionStart = createSelector(
	(state: RootState) => state.courseChapters.byKey,
	(state: RootState) => state.chapters.byKey,
	(state: RootState) => state.courses.byKey,
	(_: RootState, courseKey: string) => courseKey,
	(courseChapters, allChapters, courses, courseKey) => {
		const chapters = courseChapters[courseKey]?.chapters;
		if (!chapters || chapters.length === 0) {
			return empty;
		}

		const course = courses[courseKey]?.course;
		if (!course) {
			return empty;
		}

		return chapters.filter((key) => {
			return requiresExplicitSessionStart(course.type, allChapters[key]);
		});
	}
);

export default selectChaptersWhichRequireExplicitSessionStart;
