import {createAsyncThunk} from "@reduxjs/toolkit";
import {analyticsService} from "../services/analyticsService";
import {keyProvider} from "../keyProvider";

export const updateStudyPeriod = createAsyncThunk(
	"studyPreferences/updateStudyPeriod",
	async (params: {
		courseId: number;
		studentId: number;
		studyPeriod: {startDate: string; endDate: string};
	}) => {
		const {courseId, studyPeriod, studentId} = params;

		await analyticsService.updateCourseStudyPeriod(
			studentId,
			courseId,
			studyPeriod
		);

		return {courseKey: keyProvider.course(courseId), studyPeriod};
	}
);
