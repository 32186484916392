import React, {useRef} from "react";
import {H5PPlayerUI} from "@lumieducation/h5p-react";
import h5pContentService from "../../../../store/services/h5pContentService";

const typesFiringCompleted = new Set([
	"H5P.InteractiveVideo",
	"H5P.QuestionSet",
	"H5P.SingleChoiceSet",
	"H5P.MemoryGame",
	"H5P.SpeakTheWordsSet",
	"H5P.ImagePair",
	"H5P.BranchingScenario",
	"H5P.ThreeImage",
	"H5P.InteractiveBook",
	"H5P.SortParagraphs",
]);

const H5PPlayer = (props: {
	contentId: string;
	onSubmit: (result: {score: number; maxScore: number}) => void;
}): JSX.Element => {
	const {contentId} = props;

	const contentType = useRef("");

	const h5pPlayer = useRef<H5PPlayerUI>(null);

	return (
		<H5PPlayerUI
			ref={h5pPlayer}
			contentId={contentId}
			loadContentCallback={async (id) => {
				const data = await h5pContentService.getPlayerData(id);

				if (data?.integration?.contents) {
					const type = data.integration.contents[`cid-${id}`].library;
					contentType.current = type.substring(0, type.indexOf(" "));
				}

				return data;
			}}
			onxAPIStatement={(statement) => {
				const status = Object.values(statement.verb.display)[0];

				if (
					status === "completed" ||
					(status === "answered" &&
						!typesFiringCompleted.has(contentType.current) &&
						statement.result.completion)
				) {
					props.onSubmit({
						score: statement.result.score.raw,
						maxScore: statement.result.score.max,
					});
				}
			}}
		/>
	);
};

export default H5PPlayer;
