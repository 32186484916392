import {useLocalization} from "@fluent/react";
import React, {useEffect, useState} from "react";

import useSnackbar from "../../store/ui/useSnackbar";
import type TimeLeft from "../../utils/TimeLeft";
import {calculateTimeLeft} from "../../utils/TimeLeft";

const DeadlineTracker = (props: {
	deadline: string;
	children: (timeLeft: TimeLeft) => React.ReactElement;
}): JSX.Element => {
	const {l10n} = useLocalization();

	const {children, deadline} = props;

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deadline));

	const showSnackbar = useSnackbar();

	useEffect(() => {
		setTimeLeft(calculateTimeLeft(deadline));
	}, [deadline]);

	useEffect(() => {
		if (timeLeft.total <= 0) {
			return () => {
				return;
			};
		}

		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft(deadline));
		}, 1000);

		return () => clearTimeout(timer);
	});

	useEffect(() => {
		if (
			timeLeft.hours < 1 &&
			(timeLeft.minutes === 15 ||
				timeLeft.minutes === 5 ||
				timeLeft.minutes === 1) &&
			timeLeft.seconds === 0
		) {
			showSnackbar(
				"warning",
				l10n.getString("course-studying-minutes-left-warning", {
					minutesLeft: timeLeft.minutes,
				})
			);
		}
	}, [showSnackbar, l10n, timeLeft.hours, timeLeft.minutes, timeLeft.seconds]);

	return <>{children(timeLeft)}</>;
};

export default DeadlineTracker;
