enum DocumentFormat {
	AssessorComment = "teacher_followup_comment",
	ExerciseContent = "exercise content",
	StudentUpload = "student upload",
	CourseInfoImage = "course_info_img",
	TutorQuestion = "tutor_question",
	TutorAnswer = "tutor_answer",
}

export default DocumentFormat;
