import {useLocalization} from "@fluent/react";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import type {ChartData, Plugin, TooltipItem} from "chart.js";
import React, {useMemo} from "react";
import {Line} from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const controlLines: Plugin<"line"> = {
	id: "controlLines",
	beforeDraw: function (chart) {
		const ctx = chart.ctx;

		const x = chart.chartArea.left;
		const width = chart.chartArea.right - chart.chartArea.left;
		const areaHeight = chart.chartArea.bottom - chart.chartArea.top;

		let y = chart.chartArea.bottom;
		const height = 2;

		ctx.save();

		y -= areaHeight * 0.51;
		ctx.fillStyle = "rgba(255, 152, 0, 0.7)";
		ctx.fillRect(x, y, width, height);

		y -= areaHeight * 0.4;
		ctx.fillStyle = "rgba(76, 175, 80, 0.7)";
		ctx.fillRect(x, y, width, height);

		ctx.restore();
	},
};

const chartOptions = {
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			mode: "index" as const,
			intersect: false,
			position: "nearest" as const,
			callbacks: {
				label: (ctx: TooltipItem<"line">) => ctx.parsed.y.toFixed(2) + "%",
			},
		},
	},
	scales: {
		y: {
			display: true,
			position: "left" as const,
			ticks: {
				callback: (label: string | number) => label + "% ",
			},
		},
		x: {
			grid: {
				drawOnChartArea: false,
			},
		},
	},
};

const plugins = [controlLines];

const CourseProgressChart = (props: {
	labels: string[];
	progressData: number[];
	targetData: number[];
}): JSX.Element => {
	const {labels, progressData, targetData} = props;

	const {l10n} = useLocalization();

	const data = useMemo<ChartData<"line">>(() => {
		return {
			labels,
			datasets: [
				{
					label: l10n.getString(
						"my-corner-course-progress-widget-agenda-label-target",
						null,
						"Target"
					),
					data: targetData,
					borderColor: "rgba(94, 185, 94,1)",
					backgroundColor: "rgba(94, 185, 94,1)",
					lineTension: 0,
					borderWidth: 4,
					pointRadius: 0,
					borderCapStyle: "round",
				},
				{
					label: l10n.getString(
						"my-corner-course-progress-widget-agenda-label-progress",
						null,
						"Progress"
					),
					data: progressData,
					borderColor: "rgba(0,120,200,1)",
					backgroundColor: "rgba(0,120,200,1)",
					lineTension: 0,
					borderWidth: 4,
					pointRadius: 0,
					borderCapStyle: "round",
				},
			],
		};
	}, [l10n, labels, progressData, targetData]);

	return <Line data={data} options={chartOptions} plugins={plugins} />;
};

export default CourseProgressChart;
