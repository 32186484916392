import type {RootState} from "../store";

function selectUserOrganisationGroups(state: RootState) {
	return state.userOrganisationGroups.groups;
}

function selectUserOrganisationGroupsStatus(state: RootState) {
	return state.userOrganisationGroups.status;
}

export default selectUserOrganisationGroups;
export {selectUserOrganisationGroupsStatus};
