import {createSelector} from "@reduxjs/toolkit";
import parseDate from "../../helpers/parseDate";
import {RootState} from "../store";
import selectCourseChapterKeys from "./selectCourseChapterKeys";

const selectFirstPublishedChapterKey = createSelector(
	selectCourseChapterKeys,
	(state: RootState) => state.chapters.byKey,
	(chapterKeys, chapters) => {
		const now = new Date();

		return chapterKeys.find((key) => {
			const startDate = chapters[key].startDate;
			return startDate && parseDate(startDate) < now;
		});
	}
);

export default selectFirstPublishedChapterKey;
