import {createAsyncThunk} from "@reduxjs/toolkit";

import enrolmentService from "../services/enrolmentService";

const recordDecision = createAsyncThunk(
	"enrolment/recordDecision",
	async (params: {
		courseId: number;
		statement: "accepted" | "rejected";
		applicantIds: number[];
	}) => {
		await enrolmentService.recordDecisionOnEnrolmentApplications(
			params.courseId,
			params.statement,
			params.applicantIds
		);
	}
);

export default recordDecision;
