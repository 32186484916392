import {Localized} from "@fluent/react";
import {Button, Stack, TextField} from "@mui/material";
import type {ReactNode} from "react";
import React, {useEffect, useRef, useState} from "react";

import AdditionalActions from "../AdditionalActions";
import ConfirmResponseDialog from "./ConfirmResponseDialog";
import createFeedbackSummary from "../createFeedbackSummary";
import ExerciseExpandablePanel from "../ExerciseExpandablePanel";
import FeedbackPanel from "../FeedbackPanel";
import makeResponse from "./makeResponse";
import {OpenExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import type {
	OpenResponse,
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";
import type FileUploader from "../../../../utils/attachments/FileUploader";
import useFileUploader from "../../../../utils/attachments/useFileUploader";
import ProgressButton from "../../../../utils/ProgressButton";
import TextEditorWithAttachments from "../../../../utils/TextEditor/TextEditorWithAttachments";

type Props = {
	subtype: OpenExerciseSubtype;
	response: Readonly<OpenResponse> | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;
	emptyResponseAllowed: boolean;
	selfAssessment: boolean;

	additionalActions?: ReactNode | ReactNode[];

	userId?: number;
	courseId: number;
	chapterId?: number;
	exerciseId: number;

	onSave: (
		response: ResponseToSave,
		successMessage: string
	) => Promise<unknown>;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;

	createFileUploader: () => FileUploader | null;
};

const emptyResponse: {[key in OpenExerciseSubtype]: Readonly<OpenResponse>} = {
	[OpenExerciseSubtype.Ordinary]: {
		exerciseType: ExerciseType.Open,
		exerciseSubtype: OpenExerciseSubtype.Ordinary,
		solution: "",
	},
	[OpenExerciseSubtype.AutoAssessed]: {
		exerciseType: ExerciseType.Open,
		exerciseSubtype: OpenExerciseSubtype.AutoAssessed,
		solution: "",
		finalAnswer: "",
	},
};

const ResponseArea = (props: Props): JSX.Element => {
	const {userId, courseId, chapterId, exerciseId, onSubmit} = props;

	const [solution, setSolution] = useState("");
	const [finalAnswer, setFinalAnswer] = useState("");

	const initialValue = useRef("");
	const saveRequested = useRef(false);

	const [feedback, setFeedback] = useState<
		ReturnType<typeof createFeedbackSummary>
	>(null);

	const [selfAssessmentId, setSelfAssessmentId] = useState(0);

	const [saved, setSaved] = useState(true);
	const [openConfirmResponse, setOpenConfirmResponse] = useState(false);

	const selfAssessment =
		props.selfAssessment && props.subtype === OpenExerciseSubtype.Ordinary;

	useEffect(() => {
		const response = props.response ?? emptyResponse[props.subtype];

		setSolution(response.solution);

		if (!saveRequested.current) {
			initialValue.current = response.solution;
		}

		if ("finalAnswer" in response) {
			setFinalAnswer(response.finalAnswer);
		}

		if (!props.response) {
			setFeedback(null);
		}
	}, [props.response, props.subtype]);

	const [files, fileUploader] = useFileUploader(props.createFileUploader);

	const solutionChangeHandler = (value: string) => {
		setSolution(value);

		if (saved && value !== solution) {
			setSaved(false);
		}
	};

	const finalAnswerChangeHandler = (value: string) => {
		setFinalAnswer(value);

		if (saved) {
			setSaved(false);
		}
	};

	const save = async () => {
		saveRequested.current = true;

		await props.onSave(
			makeResponse(props.subtype, solution, finalAnswer),
			"Saved"
		);

		setSaved(true);
	};

	const submit =
		onSubmit &&
		(async (eventId?: number) => {
			let response: ResponseToSubmit = makeResponse(
				props.subtype,
				solution,
				finalAnswer
			);

			if (
				response.exerciseSubtype === OpenExerciseSubtype.Ordinary &&
				eventId
			) {
				response = {...response, eventId};
			}

			const result = await onSubmit(response);
			if (!result) {
				return;
			}

			setFeedback(createFeedbackSummary(result));

			if (result.selfAssessmentId) {
				setOpenConfirmResponse(true);
				setSelfAssessmentId(result.selfAssessmentId);
			}
		});

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || props.submitting;

	const finalAnswerIsVisible =
		props.subtype === OpenExerciseSubtype.AutoAssessed;

	return (
		<>
			<ExerciseExpandablePanel
				lazyLoading
				summary={
					<Localized id="content-exercise-do">Do the exercise</Localized>
				}
			>
				<Stack spacing={2}>
					<AdditionalActions>{props.additionalActions}</AdditionalActions>

					<TextEditorWithAttachments
						initialValue={initialValue.current}
						value={solution}
						onChange={solutionChangeHandler}
						readonly={readonly}
						fileUploader={fileUploader ?? undefined}
						files={files}
					/>

					{finalAnswerIsVisible && (
						<TextField
							fullWidth
							value={finalAnswer}
							id="final-answer"
							label="Final answer"
							disabled={readonly}
							onChange={(e) => finalAnswerChangeHandler(e.target.value)}
						/>
					)}

					{feedback && (
						<FeedbackPanel
							correctness={feedback.correctness}
							summary={feedback.summary}
							message={feedback.message}
						/>
					)}

					<Stack spacing={1} direction="row" sx={{justifyContent: "flex-end"}}>
						<Button color="primary" disabled={saved || readonly} onClick={save}>
							<Localized id="open-exercise-response-area-action-save">
								Save
							</Localized>
						</Button>

						{submit && (
							<ProgressButton
								inProgress={props.submitting}
								disabled={
									submissionDisabled ||
									(!props.emptyResponseAllowed &&
										(solution.length === 0 ||
											(finalAnswerIsVisible && finalAnswer.length === 0)))
								}
								variant="contained"
								color="primary"
								onClick={() => submit()}
							>
								<Localized id="open-exercise-response-area-action-submit">
									Submit
								</Localized>
							</ProgressButton>
						)}
					</Stack>
				</Stack>
			</ExerciseExpandablePanel>

			{selfAssessment && userId && chapterId && (
				<ConfirmResponseDialog
					open={openConfirmResponse}
					studentResponse={solution}
					exerciseId={exerciseId}
					studentId={userId}
					onCancel={() => {
						setOpenConfirmResponse(false);
					}}
					onConfirm={() => {
						setOpenConfirmResponse(false);
						submit && submit(selfAssessmentId);
					}}
					courseId={courseId}
					chapterId={chapterId}
				/>
			)}
		</>
	);
};

export default ResponseArea;
