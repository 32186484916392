export function populate<T>(
	rowsNumber: number,
	data: T[],
	produceFakeData: () => T
): T[] {
	const res = [...data];

	for (let i = res.length; i < rowsNumber; i++) {
		res.push(produceFakeData());
	}

	return res;
}
