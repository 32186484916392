import React, {useState} from "react";
import {Localized} from "@fluent/react";
import {Button, DialogActions, DialogContent, TextField} from "@mui/material";

import {validEmail} from "../../helpers/validationHelpers";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function ChangeEmailDialog(props: {
	titleElementId: string;
	initialEmail: string;
	mobileMode?: boolean;
	onChange: (email: string) => Promise<void>;
	onCancel: () => void;
}): JSX.Element {
	const [email, setEmail] = useState(props.initialEmail);
	const [empty, setEmpty] = useState(false);
	const [invalid, setInvalid] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	function validate() {
		setEmpty(email.length === 0);

		const valid = validEmail(email);
		setInvalid(!valid);

		return email.length > 0 && valid;
	}

	async function submit() {
		if (!validate()) {
			return;
		}

		setInProgress(true);

		await props.onChange(email);

		setInProgress(false);
	}

	return (
		<form
			onKeyDown={(e) => {
				if (e.code === "Enter") {
					e.preventDefault();
					submit();
				}
			}}
		>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="users-change-email-dialog-title">
							Change email address
						</Localized>
					}
					actionInProgress={inProgress}
					actionButtonLabel={
						<Localized id="users-change-email-dialog-action-change">
							Change
						</Localized>
					}
					onAction={submit}
					onClose={props.onCancel}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="users-change-email-dialog-title">
							Change email address
						</Localized>
					}
					inProgress={inProgress}
					onClose={props.onCancel}
				/>
			)}

			<DialogContent>
				<TextField
					required
					fullWidth
					autoFocus
					value={email}
					label={
						<Localized id="users-change-email-dialog-email">
							Email address
						</Localized>
					}
					error={empty || invalid}
					helperText={
						invalid && (
							<Localized id="users-change-email-dialog-error-incorrect-email">
								Email address is incorrect
							</Localized>
						)
					}
					placeholder="name@example.org"
					onChange={({target}) => {
						setEmail(target.value);
						setEmpty(false);
						setInvalid(false);
					}}
				/>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button
						onClick={props.onCancel}
						color="primary"
						disabled={inProgress}
					>
						<Localized id="users-change-email-dialog-action-cancel">
							Cancel
						</Localized>
					</Button>
					<SubmitButton onClick={submit} inProgress={inProgress} variant="text">
						<Localized id="users-change-email-dialog-action-change">
							Change
						</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</form>
	);
}

export default ChangeEmailDialog;
