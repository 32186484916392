import {createAsyncThunk} from "@reduxjs/toolkit";
import {courseService} from "../services/courseService";
import NewCourse from "./NewCourse";

const createCourse = createAsyncThunk(
	"courses/createCourse",
	async function (params: {organisationName: string; course: NewCourse}) {
		const id = await courseService.createCourse(
			params.organisationName,
			params.course
		);

		return {courseId: id};
	}
);

export default createCourse;
