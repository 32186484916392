import {Skeleton, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import UserAvatar from "../nav/UserAvatar";

const useStyles = makeStyles((theme) =>
	createStyles({
		userNameContainer: {
			display: "flex",
			alignItems: "center",
			gap: theme.spacing(1),
			flexGrow: 1,
		},
		userNameItem: {
			flex: "1 1 auto",
			minWidth: 0,
		},
		emptyPlaceholder: {
			"&::before": {
				content: `"\\00a0"`,
			},
		},
	})
);

const StudentName = (props: {
	student: {
		userName: string;
		firstName?: string;
		lastName?: string;
	};
	loading?: boolean;
}): JSX.Element => {
	const {student: s, loading} = props;

	const classes = useStyles();

	return (
		<div className={classes.userNameContainer}>
			{loading ? (
				<Skeleton variant="circular" width={32} height={32} />
			) : (
				<UserAvatar user={s} size="small" />
			)}
			<div className={classes.userNameItem}>
				{loading ? (
					<>
						<Skeleton variant="rectangular" width="70%" />
						<Skeleton variant="text" width="40%" />
					</>
				) : (
					<>
						<Typography display="block" variant="inherit">
							{s.firstName || s.lastName
								? [s.firstName, s.lastName].join(" ")
								: s.userName}
						</Typography>
						<Typography variant="caption" display="block" color="textSecondary">
							{s.firstName || s.lastName ? s.userName : ""}
						</Typography>
					</>
				)}
			</div>
			<div>
				<Typography
					display="block"
					variant="inherit"
					className={classes.emptyPlaceholder}
				/>
				<Typography
					variant="caption"
					display="block"
					color="textSecondary"
					className={classes.emptyPlaceholder}
				/>
			</div>
		</div>
	);
};

export default StudentName;
