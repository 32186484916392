import {Localized} from "@fluent/react";
import {Box, Button, Grid, useTheme} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";

import createCopyAction from "./createCopyAction";
import ExercisePlaceholder from "../content/ExercisePlaceholder";
import ExerciseEditor from "./ExerciseEditor";
import ExercisePreview from "./ExercisePreview";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import ExerciseEditorPlaceholder from "../learningMaterial/ExerciseEditorPlaceholder";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import {EditableExercise} from "../../store/services/dtos/EditableExercise";
import {mapToExerciseWithContent} from "../../store/services/dtos/EditableExercise";
import exerciseService from "../../store/services/exerciseService";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import LoadingError from "../../utils/errors/LoadingError";
import useDeleteAction from "./useDeleteAction";

const Exercise = (props: {
	organisationName: string;
	courseId: number;
	viewOnly?: boolean;
}): JSX.Element => {
	const {courseId} = props;

	const [exercise, setExercise] = useState<EditableExercise>();

	const [exerciseLoadingError, setExerciseLoadingError] = useState<
		"forbidden" | "not_found" | "other" | false
	>(false);

	const showSnackbar = useSnackbar();
	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const {search} = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const exerciseType = query.get("type") as ExerciseType | null;
	const editing = query.get("editing") !== null;

	const {exerciseId: id} = useParams<{exerciseId: string}>();
	const exerciseId = id === "new" ? id : parseInt(id);

	const history = useHistory();

	function moveToEdit() {
		history.push({search: "?editing"});
	}

	const userId = useAppSelector(selectUserId);

	const editingAllowed =
		exercise?.authorId === userId || exercise?.originId === courseId;

	const exerciseBankPath = `/courses/${courseId}/management/exercise-bank`;

	const [deleteExercise, deletionErrorDialog] = useDeleteAction(
		() => history.push(exerciseBankPath),
		openConfirmationDialog,
		showSnackbar
	);

	const onClose = (
		exerciseId: number | null,
		updated: EditableExercise | null
	) => {
		if (!exerciseId) {
			history.goBack();
			return;
		}

		if (updated) {
			setExercise(updated);
		}

		history.push(`/courses/${courseId}/management/exercises/${exerciseId}`);
	};

	const onCopy = (newId: number) => {
		history.push(`/courses/${courseId}/management/exercises/${newId}?editing`);
	};

	const copyExercise = createCopyAction(
		courseId,
		onCopy,
		openConfirmationDialog,
		showSnackbar
	);

	const theme = useTheme();

	async function fetchExercise(exerciseId: number) {
		try {
			const res = await exerciseService.getExercise(exerciseId);
			setExercise(res);
		} catch (err) {
			switch ((err as {code: string}).code) {
				case "forbidden":
					setExerciseLoadingError("forbidden");
					break;
				case "not_found":
					setExerciseLoadingError("not_found");
					break;
				default:
					setExerciseLoadingError("other");
					break;
			}
		}
	}

	useEffect(() => {
		if (exerciseId !== "new") {
			fetchExercise(exerciseId);
		}
	}, [exerciseId]);

	switch (exerciseLoadingError) {
		case "forbidden":
			return (
				<LoadingError
					title={
						<Localized id="exercise-bank-exercise-error-forbidden-title">
							Access denied
						</Localized>
					}
					description={
						<Localized id="exercise-bank-exercise-error-forbidden-description">
							You are not allowed to view the exercise
						</Localized>
					}
				/>
			);
		case "not_found":
			return (
				<LoadingError
					title={
						<Localized id="exercise-bank-exercise-error-not-found-title">
							Not found
						</Localized>
					}
					description={
						<Localized id="exercise-bank-exercise-error-not-found-description">
							The exercise is not found
						</Localized>
					}
				/>
			);
		case "other":
			return (
				<LoadingError
					onReload={() => {
						setExerciseLoadingError(false);
						fetchExercise(exerciseId as number);
					}}
					description={
						<Localized id="exercise-bank-exercise-loading-error-description">
							Failed to load the exercise
						</Localized>
					}
				/>
			);
	}

	let element = <></>;
	let buttons = <></>;

	if (exerciseId === "new") {
		if (exerciseType) {
			element = (
				<ExerciseEditor
					organisationName={props.organisationName}
					courseId={courseId}
					exerciseType={exerciseType}
					onClose={onClose}
				/>
			);
		}
	} else if (editing) {
		element = exercise ? (
			<ExerciseEditor
				organisationName={props.organisationName}
				courseId={courseId}
				exercise={exercise}
				exerciseId={exerciseId}
				onClose={onClose}
			/>
		) : (
			<ExerciseEditorPlaceholder />
		);
	} else if (exercise) {
		element = (
			<ExercisePreview
				courseId={courseId}
				exercise={mapToExerciseWithContent(exerciseId, exercise)}
			/>
		);
		buttons = props.viewOnly ? (
			<></>
		) : (
			<Box
				display="flex"
				justifyContent="flex-end"
				style={{gap: theme.spacing(2)}}
			>
				{editingAllowed && exercise.type !== ExerciseType.External && (
					<Button
						color="primary"
						onClick={() => deleteExercise(exerciseId, exercise.title)}
					>
						<Localized id="exercise-bank-exercise-button-label-delete">
							Delete
						</Localized>
					</Button>
				)}
				{exercise.type !== ExerciseType.External && (
					<Button
						color="primary"
						onClick={() => copyExercise(exerciseId, exercise.title)}
					>
						<Localized id="exercise-bank-exercise-button-label-copy">
							Copy
						</Localized>
					</Button>
				)}
				{editingAllowed && (
					<Button variant="contained" color="primary" onClick={moveToEdit}>
						<Localized id="exercise-bank-exercise-button-label-edit">
							Edit
						</Localized>
					</Button>
				)}
			</Box>
		);
	} else {
		element = <ExercisePlaceholder />;
	}

	return (
		<Box p={6} pb={14} maxWidth={theme.breakpoints.values.xl}>
			<Grid container spacing={4}>
				<Grid item xs={9}>
					{element}
				</Grid>
				<Grid item xs={3}>
					{buttons}
				</Grid>
			</Grid>

			{confirmationDialog}
			{deletionErrorDialog}
		</Box>
	);
};

export default Exercise;
