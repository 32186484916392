import type FetchStatus from "../FetchStatus";
import type OrganisationFeature from "./OrganisationFeature";
import type {RootState} from "../store";

function selectOrganisationFeatures(state: RootState): OrganisationFeature[] {
	return state.features.organisation.features;
}

function selectOrganisationFeaturesFetchStatus(state: RootState): FetchStatus {
	return state.features.organisation.status;
}

export default selectOrganisationFeatures;
export {selectOrganisationFeaturesFetchStatus};
