import {Box, Typography} from "@mui/material";
import React from "react";

import TableStateRow from "./TableStateRow";

function NoSearchResultsState(props: {
	columnNumber: number;
	spanWholeTable?: boolean;
	title: React.ReactNode;
	description: React.ReactNode;
	action?: React.ReactNode;
}) {
	return (
		<TableStateRow
			columnNumber={props.columnNumber}
			spanWholeTable={props.spanWholeTable}
		>
			<Typography variant="h6">{props.title}</Typography>
			<Typography>{props.description}</Typography>
			{props.action && <Box sx={{mt: 2}}>{props.action}</Box>}
		</TableStateRow>
	);
}

export default NoSearchResultsState;
