import Structure from "../services/dtos/ChapterStructure";
import {keyProvider} from "../keyProvider";

type NormalisedStructure = {
	sectionKeys: string[];
	sections: {
		[key: string]: {
			subsections: string[];
			subsectionIds: number[];
		};
	};
	chapterExercises: {
		[key: string]: {
			sectionId: number;
			number: number;
		};
	};
};

function normaliseChapterStructure(
	chapterId: number,
	structure: Structure
): NormalisedStructure {
	if (!structure.sections) {
		return {chapterExercises: {}, sectionKeys: [], sections: {}};
	}

	const keys: string[] = new Array(structure.sections.length);

	const sections: NormalisedStructure["sections"] = {};
	const exercises: NormalisedStructure["chapterExercises"] = {};

	for (let i = 0; i < structure.sections.length; i++) {
		const s = structure.sections[i];

		const subsections: string[] = new Array(s.subsections.length);
		const subsectionIds: number[] = new Array(s.subsections.length);

		for (let j = 0; j < s.subsections.length; j++) {
			const e = s.subsections[j];

			const key = keyProvider.chapterExercise(chapterId, e.id);

			exercises[key] = {
				sectionId: s.id,
				number: e.number,
			};

			subsections[j] = key;
			subsectionIds[j] = e.id;
		}

		exercises[keyProvider.chapterExercise(chapterId, s.id)] = {
			sectionId: s.id,
			number: i + 1,
		};

		const key = keyProvider.section(chapterId, s.id);

		sections[key] = {
			subsections,
			subsectionIds,
		};

		keys[i] = key;
	}

	return {
		sectionKeys: keys,
		sections: sections,
		chapterExercises: exercises,
	};
}

export {normaliseChapterStructure};
export default NormalisedStructure;
