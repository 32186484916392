import {createAsyncThunk} from "@reduxjs/toolkit";

import widgetService from "../services/widgetService";
import type DashboardName from "./DashboardName";
import type WidgetKey from "./WidgetKey";
import type WidgetSettings from "./WidgetSettings";
import {keyProvider} from "../keyProvider";

const updateWidgetSettings = createAsyncThunk(
	"widgetSettings/update",
	async (params: {
		userId: number;
		courseId: number;
		dashboardName: DashboardName;
		widgetKey: WidgetKey;
		settings: WidgetSettings;
	}) => {
		const {courseId, dashboardName, settings, userId, widgetKey} = params;

		await widgetService.updateSettings(
			userId,
			courseId,
			dashboardName,
			widgetKey,
			settings
		);

		return {
			key: keyProvider.widgetSettings(courseId, widgetKey),
			settings,
		};
	}
);

export default updateWidgetSettings;
