import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Box,
	IconButton,
	TablePagination as MaterialTablePagination,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";

import type {LinkToPage} from "../../helpers/paginatedSearchHelpers";

const useStyles = makeStyles(() =>
	createStyles({
		pagination: {
			minHeight: "52px",
		},
	})
);

const defaultPageSizeOptions = [10, 25, 50];

const TablePagination = (props: {
	pageSize: number;
	pageSizeOptions?: number[];
	first: boolean;
	last: boolean;
	next: boolean;
	previous: boolean;
	label: React.ReactNode;
	disabled?: boolean;
	onPageChange: (rel: LinkToPage) => void;
	onPageSizeChange: (size: number) => void;
}): JSX.Element => {
	const {
		onPageChange: changePage,
		pageSize,
		onPageSizeChange,
		first,
		last,
		next,
		previous,
		label,
		disabled,
	} = props;
	const classes = useStyles();

	const [pageNumber, setPageNumber] = useState(0);

	useEffect(() => {
		setPageNumber(0);
	}, [pageSize]);

	const onRowsPerPageChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		onPageSizeChange(parseInt(event.target.value, 10));
	};

	function TablePaginationActions(props: {
		count: number;
		page: number;
		rowsPerPage: number;
		onPageChange: (
			event: React.MouseEvent<HTMLButtonElement>,
			newPage: number
		) => void;
	}) {
		const {page, onPageChange} = props;

		const firstPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, 0);
			changePage("first");
		};

		const prevPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page - 1);
			changePage("previous");
		};

		const nextPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page + 1);
			changePage("next");
		};

		const lastPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, 0);
			changePage("last");
		};

		return (
			<Box flexShrink={0} ml={2.5}>
				<IconButton onClick={firstPageClick} disabled={disabled || !first}>
					<FirstPageIcon />
				</IconButton>
				<IconButton onClick={prevPageClick} disabled={disabled || !previous}>
					<KeyboardArrowLeft />
				</IconButton>
				<IconButton onClick={nextPageClick} disabled={disabled || !next}>
					<KeyboardArrowRight />
				</IconButton>
				<IconButton onClick={lastPageClick} disabled={disabled || !last}>
					<LastPageIcon />
				</IconButton>
			</Box>
		);
	}

	return (
		<MaterialTablePagination
			ActionsComponent={TablePaginationActions}
			className={classes.pagination}
			component="div"
			count={-1}
			labelDisplayedRows={() => null}
			labelRowsPerPage={label}
			onPageChange={(_, number) => {
				setPageNumber(number);
			}}
			onRowsPerPageChange={onRowsPerPageChange}
			page={pageNumber}
			rowsPerPage={pageSize}
			rowsPerPageOptions={props.pageSizeOptions ?? defaultPageSizeOptions}
		/>
	);
};

export default React.memo(TablePagination);
