import React, {useEffect, useRef} from "react";
import Attachments from "../attachments/Attachments";
import type UploadedFile from "../attachments/UploadedFile";
import type {TextEditorApi, TextEditorProps} from "./TextEditor";
import TextEditor from "./TextEditor";

const TextEditorWithAttachments = (
	props: TextEditorProps & {
		mode?: Exclude<TextEditorProps["mode"], "inline">;
		files: UploadedFile[];
	},
	ref: React.ForwardedRef<TextEditorApi>
): JSX.Element => {
	const {fileUploader} = props;

	const fetched = useRef(false);
	useEffect(() => {
		if (fileUploader && !fetched.current) {
			fileUploader.loadAll();
			fetched.current = true;
		}
	}, [fileUploader]);

	return (
		<>
			<TextEditor {...props} ref={ref} />
			{fileUploader && (
				<Attachments
					fileUploader={fileUploader}
					readonly={props.readonly}
					files={props.files}
				/>
			)}
		</>
	);
};

export default React.forwardRef(TextEditorWithAttachments);
