import {useLayoutEffect, useState} from "react";

import {axiosInstance} from "../services/axiosInstance";

function useAccessDeniedErrorTracking() {
	const [forbidden, setForbidden] = useState(false);

	useLayoutEffect(() => {
		const id = axiosInstance.interceptors.response.use(
			(response) => response,
			(err) => {
				if (err.response?.status === 403) {
					setForbidden(true);
				}

				throw err;
			}
		);

		return () => {
			axiosInstance.interceptors.response.eject(id);
		};
	}, []);

	return forbidden;
}

export default useAccessDeniedErrorTracking;
