import {Localized} from "@fluent/react";
import {
	Button,
	DialogActions,
	DialogContent,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";

import type {Page} from "../helpers/paginatedSearchHelpers";
import HeadCell from "./tables/HeadCell";
import ServerPaginationControls from "./tables/ServerPaginationControls";
import SortingHeader from "./tables/SortingHeader";
import SortOrder from "./tables/SortOrder";
import useBulkSelection from "./tables/useBulkSelection";
import usePaginationState from "./tables/usePaginationState";

type Item = {id: number; name: string};

function SearchAndSelectionDialog<T extends Item>(props: {
	toolbar: NonNullable<React.ReactNode>;
	actionLabel: NonNullable<React.ReactNode>;
	multiple?: boolean;
	headCells: HeadCell<T>[];

	fetch: (
		sort: {field: keyof T; descending?: boolean},
		pageSize: number
	) => Promise<Page<T>>;
	rowRenderer: (
		item: T,
		selected: boolean,
		select: () => void
	) => React.ReactNode;

	noSearchResultsRenderer: () => React.ReactNode;
	generalErrorRenderer: (onReload?: () => void) => React.ReactNode;

	onSelected: (ids: number[]) => void;
	onCancel: () => void;
}) {
	const [sortField, setSortField] = useState<keyof T>("name");
	const [sortOrder, setSortOrder] = useState(SortOrder.Asc);

	function changeOrder(orderBy: keyof T, order: SortOrder) {
		setSortOrder(order);
		setSortField(orderBy);
	}

	const {
		page,
		fetchFirstPage,
		fetchRelatedPage,
		pageFetchStatus,
		retryFetching,
	} = usePaginationState<T>();

	const {
		select: selectItem,
		bulkSelectionCheckbox,
		selected,
		resetSelection,
	} = useBulkSelection(page, (o) => o.id);

	const items = page.content;

	const [singleSelectedItem, setSingleSelectedItem] = useState(0);

	const fetchPage = props.fetch;
	useEffect(() => {
		fetchFirstPage(
			() =>
				fetchPage(
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					10
				),
			resetSelection
		);
	}, [fetchFirstPage, fetchPage, resetSelection, sortField, sortOrder]);

	let selectedItems = selected;
	if (!props.multiple && singleSelectedItem) {
		selectedItems = [singleSelectedItem];
	}

	function selectedItem(id: number) {
		return selectedItems.indexOf(id) !== -1;
	}

	function select(id: number, selected: boolean) {
		if (props.multiple) {
			selectItem(id);
		} else {
			setSingleSelectedItem(selected ? 0 : id);
		}
	}

	const notFetched = pageFetchStatus !== "succeeded";

	return (
		<>
			<DialogContent
				dividers
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100vh",
					paddingBottom: 0,
				}}
			>
				<Stack direction="row" spacing={0.5}>
					{props.toolbar}
					<ServerPaginationControls
						disabledControls={{
							first: notFetched || !page.request.first,
							next: notFetched || !page.request.next,
							previous: notFetched || !page.request.previous,
						}}
						onClick={fetchRelatedPage}
					/>
				</Stack>
				<TableContainer sx={{mt: 2, height: "100%"}}>
					<Table stickyHeader>
						<SortingHeader
							onOrderChange={changeOrder}
							order={sortOrder}
							orderBy={sortField}
							headCells={props.headCells}
							leftAnnex={
								props.multiple ? (
									<TableCell padding="checkbox" style={{zIndex: 3}}>
										{bulkSelectionCheckbox}
									</TableCell>
								) : (
									<TableCell padding="checkbox" />
								)
							}
							loading={pageFetchStatus === "pending"}
						/>
						<TableBody>
							{pageFetchStatus === "failed" &&
								props.generalErrorRenderer(retryFetching)}
							{pageFetchStatus === "succeeded" &&
								items.length === 0 &&
								props.noSearchResultsRenderer()}
							{pageFetchStatus !== "failed" &&
								items.length > 0 &&
								items.map((c) => {
									const selected = selectedItem(c.id);
									return props.rowRenderer(c, selected, () =>
										select(c.id, selected)
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				{selectedItems.length > 0 && (
					<Typography
						variant="subtitle2"
						component="span"
						sx={{flexGrow: 1, ml: 4}}
					>
						<Localized
							id="search-and-selection-dialog-selected-row-number"
							vars={{selected: selectedItems.length}}
						>{`${selectedItems.length} selected`}</Localized>
					</Typography>
				)}
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="search-and-selection-dialog-action-cancel">
						Cancel
					</Localized>
				</Button>
				<Button
					color="primary"
					disabled={selectedItems.length === 0}
					onClick={() => props.onSelected(selectedItems)}
				>
					{props.actionLabel}
				</Button>
			</DialogActions>
		</>
	);
}

export default SearchAndSelectionDialog;
