import {FluentBundle, FluentResource} from "@fluent/bundle";
import {negotiateLanguages} from "@fluent/langneg";
import {LocalizationProvider, ReactLocalization} from "@fluent/react";
import React, {
	Children,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

import {axiosInstance as client} from "../store/services/axiosInstance";
import {useAppSelector} from "../store/hooks";
import selectUserProfile from "../store/userProfile/selectUserProfile";
import {availableLocales, defaultLocale} from "./locales";
import createLocaleCache from "./cache";

const persistantCache = createLocaleCache();

async function fetchMessages(locale: string): Promise<string> {
	const {data} = await client.get(`/locales/${locale}/content.ftl`);
	return data;
}

function parseBundle(locale: string, messages: string) {
	const resource = new FluentResource(messages);
	const bundle = new FluentBundle(locale);
	bundle.addResource(resource);
	return bundle;
}

const LocalisationContext = createContext<(...locales: string[]) => void>(
	() => {
		return;
	}
);

function useLocaleChanger(): (...locales: string[]) => void {
	return useContext(LocalisationContext);
}

const LocalisationProvider = (props: {
	children: React.ReactNode;
}): JSX.Element => {
	const [l10n, setL10n] = useState<ReactLocalization | null>(null);

	const [defaultBundle, setDefaultBundle] = useState<FluentBundle>();

	const userLocale = useAppSelector(
		(state) => selectUserProfile(state).language
	);

	const changeLocale = useCallback(
		async (...locales: string[]) => {
			if (!defaultBundle) {
				return;
			}

			const [current] = negotiateLanguages(
				locales,
				Object.keys(availableLocales),
				{
					strategy: "lookup",
					defaultLocale,
				}
			);

			let bundles = [defaultBundle];
			if (current !== defaultLocale) {
				const messages = await fetchMessages(current);
				bundles = [parseBundle(current, messages), defaultBundle];
			}
			setL10n(new ReactLocalization(bundles));
			persistantCache.setLocale(current);
		},
		[defaultBundle]
	);

	useEffect(() => {
		fetchMessages(defaultLocale).then((messages) => {
			setDefaultBundle(parseBundle(defaultLocale, messages));
		});
	}, []);

	useEffect(() => {
		if (userLocale) {
			changeLocale(userLocale);
			return;
		}

		const cached = persistantCache.getLocale();
		if (cached) {
			changeLocale(cached);
			return;
		}

		changeLocale(...navigator.languages);
	}, [changeLocale, userLocale]);

	if (!l10n) {
		return <></>;
	}

	return (
		<LocalisationContext.Provider value={changeLocale}>
			<LocalizationProvider l10n={l10n}>
				{Children.only(props.children)}
			</LocalizationProvider>
		</LocalisationContext.Provider>
	);
};

export {useLocaleChanger};
export default LocalisationProvider;
