import type {AxiosInstance} from "axios";

import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type AnswerVisibility from "../chapterExercises/AnswerVisibility";
import type {EditableExercise} from "./dtos/EditableExercise";
import type ExercisePatch from "../exercises/ExercisePatch";
import type ExerciseWithSettings from "./dtos/ExerciseWithSettings";
import {mapToEditableExercise} from "./dtos/ExerciseWithSettings";
import ComparisonStrictness from "../exercises/ComparisonStrictness";
import type ConditionType from "../exercises/ConditionType";
import type ExerciseAnswer from "../exercises/ExerciseAnswer";
import ExercisePrivacy from "../exercises/ExercisePrivacy";
import ExerciseType from "../exercises/ExerciseType";
import type FeedbackRule from "../exercises/FeedbackRule";
import type TagSearchResult from "../exercises/TagSearchResult";
import {countLines} from "../../helpers/fileHelpers";
import {createPage} from "../../helpers/paginatedSearchHelpers";
import type {Page} from "../../helpers/paginatedSearchHelpers";

type ExerciseSearchCriteria = {
	core: string;
	language: string;
	courseId?: number;
	organisationName?: string;
	userId?: number;
	exerciseType?: ExerciseType;
	tags?: string[];
	query?: string;
};

export type ExerciseSearchResult = {
	id: number;
	title: string;
	type: string;
	language: string;
	authorId: number;
	authorName: string;
	courseId: number;
	created: string;
	question: string;
	tags: string[];
};

export type TagSearchCriteria = {
	scope:
		| "course_exercises"
		| "exercises_public_to_organisation"
		| "organisation_or_user_exercises"
		| "public_exercises"
		| "user_exercises";
	courseId?: number;
	organisationName?: string;
	userId?: number;
	prefix?: string;
};

type AnswerRepresentation =
	| {
			exerciseType: ExerciseType.Math;
			solution: string;
			typeSpecific: {
				finalSteps: string[];
			};
	  }
	| {
			exerciseType: ExerciseType.Multi;
			solution: string;
			typeSpecific: {
				choices: {
					id: number;
					text: string;
				}[];
			};
	  }
	| {
			exerciseType: ExerciseType.Open;
			solution: string;
			typeSpecific?: {
				shortText: string;
			};
	  }
	| {
			exerciseType: ExerciseType.Prog;
			solution: string;
			typeSpecific: {
				files: {
					name: string;
					content: string;
				}[];
			};
	  }
	| {
			exerciseType: ExerciseType.Short;
			solution: string;
			typeSpecific: {
				options: string[];
			};
	  };

function createService(client: AxiosInstance) {
	async function copyExercise(
		courseId: number,
		exerciseId: number
	): Promise<number> {
		const {data} = await client.post(`/api/courses/${courseId}/exercises`, {
			action: "copy",
			exercise_template_id: exerciseId,
		});
		if (!data.exercise_template_id) {
			throw new Error();
		}
		return data.exercise_template_id;
	}

	async function createExercise(
		courseId: number,
		exercise: EditableExercise
	): Promise<number> {
		if (
			exercise.type !== ExerciseType.External &&
			exercise.type !== ExerciseType.Open &&
			exercise.type !== ExerciseType.Short
		) {
			const params = mapToExerciseRequestParams(exercise);
			const {data} = await client.post(
				`/api/courses/${courseId}/exercises`,
				params
			);
			if (!data.exercise_template_id) {
				throw new Error();
			}
			return data.exercise_template_id;
		}

		if (exercise.type === ExerciseType.External) {
			exercise = {...exercise, settings: {...exercise.settings}};
			delete exercise.settings.contentId;
		}

		const [id] = await createExercise2({
			...exercise,
			originId: courseId,
		});

		return id;
	}

	async function createExercise2(
		exercise: EditableExercise
	): Promise<[number, EditableExercise]> {
		const {data} = await client.post<ExerciseWithSettings>(
			"/api/exercises",
			exercise
		);

		return [data.id, mapToEditableExercise(data)];
	}

	async function patchExercise(
		exerciseId: number,
		patch: ExercisePatch
	): Promise<EditableExercise> {
		const {data} = await client.patch<ExerciseWithSettings>(
			`/api/exercises/${exerciseId}`,
			patch,
			{
				headers: {
					"Content-Type": "application/merge-patch+json",
				},
			}
		);

		return mapToEditableExercise(data);
	}

	async function deleteExercise(exerciseId: number): Promise<void> {
		const url = `/api/exercises/${exerciseId}`;

		try {
			await client.delete(url);
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 409) {
				throw {code: "conflict"};
			} else {
				throw error;
			}
		}
	}

	async function getExercise(exerciseId: number): Promise<EditableExercise> {
		try {
			const {data} = await client.get<ExerciseWithSettings>(
				`/api/exercises/${exerciseId}`
			);

			return mapToEditableExercise(data);
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			} else if (httpClientError(err) && err.response?.status === 404) {
				throw {code: "not_found"};
			}

			throw err;
		}
	}

	async function getExerciseAnswer(
		courseId: number,
		chapterId: number,
		exerciseId: number
	): Promise<ExerciseAnswer> {
		const {data} = await client.get<AnswerRepresentation>(
			`/api/courses/${courseId}/chapters/${chapterId}/exercises/${exerciseId}/answer`
		);

		let answer: ExerciseAnswer;

		switch (data.exerciseType) {
			case ExerciseType.Math:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					finalAnswer: data.typeSpecific.finalSteps,
				};

				break;
			case ExerciseType.Multi:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					finalAnswer: data.typeSpecific.choices.map((ch) => ch.text),
				};

				break;
			case ExerciseType.Open:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					finalAnswer: data.typeSpecific?.shortText ?? "",
				};

				break;
			case ExerciseType.Prog:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					files: data.typeSpecific.files,
				};

				break;
			case ExerciseType.Short:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					options: data.typeSpecific.options,
				};

				break;
			default:
				throw new Error();
		}

		return answer;
	}

	async function getMathFeedbackRules(
		courseId: number,
		exerciseId: number
	): Promise<FeedbackRule[]> {
		const {data} = await client.get<
			{
				feedback: string;
				math_expression: string;
				condition_type: ConditionType;
			}[]
		>(`/api/courses/${courseId}/exercises/${exerciseId}/math-feedback`);

		return data.map((r) => ({
			conditionType: r.condition_type,
			expression: r.math_expression,
			message: r.feedback,
		}));
	}

	async function patchExerciseSettings(
		courseId: number,
		chapterId: number,
		exerciseId: number,
		settings: {
			maxScore?: number;
			answerVisibility?: AnswerVisibility | null;
		}
	): Promise<void> {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/exercises/${exerciseId}`;

		await client.patch(url, settings, {
			headers: {
				"Content-Type": "application/merge-patch+json",
			},
		});
	}

	async function saveMathFeedbackRules(
		courseId: number,
		exerciseId: number,
		rules: FeedbackRule[]
	): Promise<void> {
		const url = `/api/courses/${courseId}/exercises/${exerciseId}/math-feedback`;

		const payload = {
			cust_feed: rules.map((r) => ({
				condition_type: r.conditionType,
				math_expression: r.expression,
				feedback: r.message,
			})),
		};

		const {data} = await client.put<{success?: 1}>(url, payload);
		if (!data.success) {
			throw new Error();
		}
	}

	async function searchExercises(
		criteria: ExerciseSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<ExerciseSearchResult>> {
		const url = "/api/exercises";

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		params.append("core", criteria.core);
		params.append("language", criteria.language);

		if (criteria.organisationName) {
			params.append("organisationName", criteria.organisationName);
		} else if (criteria.courseId) {
			params.append("courseId", criteria.courseId.toString());
		} else if (criteria.userId) {
			params.append("userId", criteria.userId.toString());
		}

		if (criteria.exerciseType) {
			params.append("exerciseType", criteria.exerciseType);
		}

		if (criteria.tags) {
			criteria.tags.forEach((tag) => params.append("tag", tag));
		}

		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	}

	async function searchTags(
		criteria: TagSearchCriteria,
		pageSize: number
	): Promise<Page<TagSearchResult>> {
		const url = `/api/exercise-tags`;
		const params = new URLSearchParams();

		params.append("scope", criteria.scope);
		params.append("pageSize", pageSize.toString());

		if (criteria.prefix) {
			params.append("prefix", criteria.prefix);
		}

		if (
			criteria.organisationName &&
			(criteria.scope === "exercises_public_to_organisation" ||
				criteria.scope === "organisation_or_user_exercises")
		) {
			params.append("organisationName", criteria.organisationName);
		}

		if (criteria.courseId && criteria.scope === "course_exercises") {
			params.append("courseId", criteria.courseId.toString());
		}

		if (
			criteria.userId &&
			(criteria.scope === "organisation_or_user_exercises" ||
				criteria.scope === "user_exercises")
		) {
			params.append("userId", criteria.userId.toString());
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	}

	async function updateExercise(
		courseId: number,
		exerciseId: number,
		exercise: EditableExercise
	): Promise<void> {
		if (
			exercise.type !== ExerciseType.External &&
			exercise.type !== ExerciseType.Open &&
			exercise.type !== ExerciseType.Short
		) {
			const url = `/api/courses/${courseId}/exercises/${exerciseId}`;
			const params = mapToExerciseRequestParams(exercise, exerciseId);

			await client.put(url, params);

			return;
		}

		await patchExercise(exerciseId, {
			title: exercise.title,
			maxScore: exercise.maxScore,
			question: exercise.question,
			category: exercise.category || null,
			difficultyLevel: exercise.difficultyLevel,
			privacyLevel: exercise.privacyLevel,
			solution: exercise.solution || null,
		});
	}

	async function updateExerciseTags(
		exerciseId: number,
		tags: string[]
	): Promise<void> {
		await patchExercise(exerciseId, {tags: tags});
	}

	return {
		copyExercise: copyExercise,
		createExercise: createExercise,
		createExercise2: createExercise2,
		deleteExercise: deleteExercise,
		getExercise: getExercise,
		getExerciseAnswer: getExerciseAnswer,
		getMathFeedbackRules: getMathFeedbackRules,
		patchExercise: patchExercise,
		patchExerciseSettings: patchExerciseSettings,
		saveMathFeedbackRules: saveMathFeedbackRules,
		searchExercises: searchExercises,
		searchTags: searchTags,
		updateExercise: updateExercise,
		updateExerciseTags: updateExerciseTags,
	};
}

function mapToExerciseRequestParams(
	exercise: EditableExercise,
	exerciseId?: number,
	action?: string
): Record<string, unknown> {
	const mapped = {
		exercise_template_id: exerciseId ?? "new",
		exercise_title: exercise.title,
		exercise_type: exercise.type,
		question: exercise.question,
		exercise_solution: exercise.solution,
		exercise_category: exercise.category,
		question_orig: exercise.question,
		content_syntax: "wiz",
		difficulty_level: exercise.difficultyLevel,
		action: action ?? "save",
	};

	switch (exercise.type) {
		case ExerciseType.Multi: {
			const answer = exercise.choices.map((el) => ({
				choice_text: el.text,
				choice_points: el.score,
				choice_comment: el.comment,
			}));

			return {
				...mapped,
				answer: answer,
				evaluation_packet: exercise.subtype,
				public_exercise: exercise.privacy === ExercisePrivacy.Public,
				public_to_org:
					exercise.privacy === ExercisePrivacy.PublicToOrganisation,
			};
		}
		case ExerciseType.Math:
			return {
				...mapped,
				answer_example: exercise.finalAnswer,
				evaluation_packet: exercise.subtype,
				extra_info: exercise.expression,
				show_expression: exercise.expressionVisible,
				variable: exercise.variable,
				public_exercise: exercise.privacy === ExercisePrivacy.Public,
				public_to_org:
					exercise.privacy === ExercisePrivacy.PublicToOrganisation,
				exercise_points: exercise.maxScore,
			};
		case ExerciseType.Prog: {
			let subtype = exercise.files.length > 1 ? "multi" : "single";

			if (exercise.language === "sql") {
				subtype = exercise.subtype;
			}

			const files = exercise.files.map((f) => {
				let start = null;
				let end = null;

				if (f.readOnlyFromBeginning || f.readOnlyFromEnd) {
					subtype = "partial";

					start = 1;
					end = countLines(f.content);

					if (f.readOnlyFromBeginning) {
						start += f.readOnlyFromBeginning;
					}

					if (f.readOnlyFromEnd) {
						end -= f.readOnlyFromEnd;
					}
				}

				return {
					filename: f.name,
					content: f.content,
					file_group_type: "answer",
					partial_start: start,
					partial_end: end,
				};
			});

			return {
				...mapped,
				exercise_points: exercise.maxScore,
				run_type: subtype,
				answer: files,
				main_file: exercise.mainFile,
				public_exercise: exercise.privacy === ExercisePrivacy.Public,
				public_to_org:
					exercise.privacy === ExercisePrivacy.PublicToOrganisation,
				must: exercise.requiredWords,
				deny: exercise.prohibitedWords,
				run_cmd: exercise.command || null,
				level: mapComparisonStrictness(exercise.comparisonStrictness),
			};
		}
		default:
			return {};
	}
}

function mapComparisonStrictness(strictness: ComparisonStrictness) {
	switch (strictness) {
		case "strict":
			return "1";
		case "ignore_space":
			return "2";
		case "ignore_case":
			return "3";
		case "ignore_space_and_case":
			return "4";
		default:
			return "";
	}
}

export default createService(client);
export {mapToExerciseRequestParams};
