import {Slide} from "@mui/material";
import type {TransitionProps} from "@mui/material/transitions";
import React from "react";

const SlideUp = React.forwardRef(function SlideUp(
	props: TransitionProps & {children: React.ReactElement},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default SlideUp;
