import {Localized, useLocalization} from "@fluent/react";
import {Box, Grid, Tab, TextField, Typography, useTheme} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import {fetchStudyPeriod} from "../../store/studyPreferences/fetchStudyPeriod";
import selectStudyPeriod from "../../store/studyPreferences/selectStudyPeriod";
import {updateStudyPeriod} from "../../store/studyPreferences/updateStudyPeriod";
import selectCourse from "../../store/courses/selectCourse";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import useSnackbar from "../../store/ui/useSnackbar";
import ConfirmationFooter from "../../utils/ConfirmationFooter";
import SubtitleNav from "../nav/SubtitleNav";

type Period = {
	startDate: string;
	endDate: string;
};

const StudyProcessPreferences = (props: {
	courseId: number;
	studentId: number;
}): JSX.Element => {
	const {courseId, studentId} = props;

	const courseKey = keyProvider.course(props.courseId);

	const theme = useTheme();

	const initialStudyPeriod = useAppSelector((state) =>
		selectStudyPeriod(state, courseKey)
	);

	const [studyPeriod, setStudyPeriod] = useState<Period | null>(null);

	const [emptyFields, setEmptyFields] = useState({
		studyStartDate: false,
		studyEndDate: false,
	});

	const [saving, setSaving] = useState(false);

	const course = useAppSelector((state) => selectCourse(state, courseKey));

	const createSubroute = useSubrouteCreator();

	const showSnackbar = useSnackbar();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!initialStudyPeriod) {
			dispatch(fetchStudyPeriod({courseId, studentId}));
		} else {
			setStudyPeriod(initialStudyPeriod);
		}
	}, [courseId, dispatch, initialStudyPeriod, studentId]);

	const {l10n} = useLocalization();

	if (!course) {
		return <></>;
	}

	const generalPage = createSubroute("");

	const navigationTabs = (
		<SubtitleNav
			title={
				<Localized id="study-process-preferences-header">
					Study process preferences
				</Localized>
			}
			subtitle={course.name}
			selectedTab={generalPage.link}
		>
			<Tab
				label={
					<Localized id="study-process-preferences-general-tab">
						General
					</Localized>
				}
				component={Link}
				value={generalPage.link}
				to={generalPage.link}
			/>
		</SubtitleNav>
	);

	if (!studyPeriod) {
		return navigationTabs;
	}

	const studyPeriodChanged =
		initialStudyPeriod?.startDate !== studyPeriod.startDate ||
		initialStudyPeriod?.endDate !== studyPeriod.endDate;

	const discard = () => {
		if (studyPeriodChanged) {
			setStudyPeriod(initialStudyPeriod);
			setEmptyFields({
				studyEndDate: false,
				studyStartDate: false,
			});
		}
	};

	const validate = () => {
		setEmptyFields({
			studyStartDate: !studyPeriod.startDate,
			studyEndDate: !studyPeriod.endDate,
		});

		if (!studyPeriod.startDate || !studyPeriod.endDate) {
			return false;
		}

		return true;
	};

	const save = async () => {
		if (!validate()) {
			return;
		}

		setSaving(true);

		try {
			if (studyPeriodChanged) {
				const res = await dispatch(
					updateStudyPeriod({
						courseId,
						studentId,
						studyPeriod,
					})
				);
				unwrapResult(res);
			}
			showSnackbar("success", l10n.getString("success-on-saving"));
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			{navigationTabs}

			<Box p={6} maxWidth={theme.breakpoints.values.xl}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<Box display="flex" flexDirection="column">
							<Typography variant="h5" style={{marginBottom: theme.spacing(2)}}>
								<Localized id="study-process-preferences-study-period-title">
									Study period
								</Localized>
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField
										label={
											<Localized id="study-process-preferences-study-period-start-date-label">
												Start date
											</Localized>
										}
										type="date"
										fullWidth
										value={studyPeriod.startDate}
										required
										error={emptyFields.studyStartDate}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputProps: {min: course.startDate, max: course.endDate},
										}}
										onChange={({target}) => {
											setStudyPeriod(
												(prev) => prev && {...prev, startDate: target.value}
											);
											setEmptyFields((prev) => ({
												...prev,
												studyStartDate: false,
											}));
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label={
											<Localized id="study-process-preferences-study-period-end-date-label">
												End date
											</Localized>
										}
										type="date"
										fullWidth
										value={studyPeriod.endDate}
										required
										error={emptyFields.studyEndDate}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputProps: {min: course.startDate, max: course.endDate},
										}}
										onChange={({target}) => {
											setStudyPeriod(
												(prev) => prev && {...prev, endDate: target.value}
											);
											setEmptyFields((prev) => ({
												...prev,
												studyEndDate: false,
											}));
										}}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{studyPeriodChanged && (
				<Box mt={15}>
					<ConfirmationFooter
						onDiscard={discard}
						onSave={save}
						saveInProgress={saving}
					/>
				</Box>
			)}
		</>
	);
};

export default StudyProcessPreferences;
