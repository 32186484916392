import {createSlice} from "@reduxjs/toolkit";

import fetchUnreadAnnouncementsNumber from "./fetchUnreadAnnouncementsNumber";
import unreadAnnouncementsNumberChanged from "./unreadAnnouncementsNumberChanged";

type State = {
	numberOfUnread: number;
	bulletinData: {
		title: string;
		content: string;
		authorName: string;
		datePosted: string;
		read: boolean;
		type: string;
	}[];
};

const initialState: State = {
	numberOfUnread: 0,
	bulletinData: [],
};

export const announcementsSlice = createSlice({
	name: "announcements",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchUnreadAnnouncementsNumber.fulfilled,
			(state, action) => {
				state.numberOfUnread = action.payload;
			}
		);

		builder.addCase(unreadAnnouncementsNumberChanged, (state, action) => {
			state.numberOfUnread = action.payload.number;
		});
	},
});

export default announcementsSlice.reducer;
