import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";

const fetchCourse = createAsyncThunk(
	"courses/fetchCourse",
	async function (courseId: number) {
		const course = await courseService.getCourse(courseId);

		return {
			key: keyProvider.course(courseId),
			course: course,
		};
	}
);

export default fetchCourse;
