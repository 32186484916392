import UserCourse from "../store/models/UserCourse";

export function setCoursePicture(
	courses: UserCourse[],
	courseId: number,
	picture?: string
): void {
	const course = courses.find((c) => c.id === courseId);
	if (!course) {
		return;
	}

	if (picture) {
		course.picture = picture;
	} else {
		delete course.picture;
	}
}
