import {useMemo} from "react";
import {contentService} from "../../../store/services/contentService";
import DocumentFormat from "../../../utils/attachments/DocumentFormat";
import FileLoader from "../../../utils/attachments/FileLoader";
import UploadedFile from "../../../utils/attachments/UploadedFile";

const useAssessorFileLoader = (
	courseId: number,
	chapterId: number,
	exerciseId: number,
	studentId: number
): FileLoader => {
	const loader = useMemo(() => {
		return {
			async loadAll(): Promise<UploadedFile[]> {
				const files = await contentService.getAll({
					documentFormat: DocumentFormat.AssessorComment,
					chapterId,
					courseId,
					exerciseId,
					personId: studentId,
				});
				return files;
			},
		};
	}, [chapterId, courseId, exerciseId, studentId]);

	return loader;
};

export default useAssessorFileLoader;
