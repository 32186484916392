import {Localized, useLocalization} from "@fluent/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Checkbox,
	Chip,
	Dialog,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import GrantCourseTemplatesDialog from "./GrantCourseTemplatesDialog";
import GroupCourseTemplatesFilters from "./GroupCourseTemplatesFilters";
import parseDate from "../../helpers/parseDate";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import useDateFormat from "../../i18n/useDateFormat";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import RenameTemplateDialog from "./RenameTemplateDialog";
import type {GroupCourseTemplateSearchCriteria} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import CourseTemplate from "../../store/services/dtos/CourseTemplate";
import useSnackbar from "../../store/ui/useSnackbar";
import useDeleteAction from "./useDeleteAction";
import ExtendableBox from "../../utils/ExtendableBox";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import TableToolbar from "../../utils/tables/TableToolbar";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import usePaginationState from "../../utils/tables/usePaginationState";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const filtersBarWidth = 320;

const headCells: HeadCell<CourseTemplate>[] = [
	{
		id: "name",
		label: "group-course-templates-table-column-name",
		sortable: true,
	},
	{
		id: "creationTime",
		label: "group-course-templates-table-column-creation-date",
		sortable: false,
		width: 160,
		align: "right",
	},
	{
		id: "language",
		label: "group-course-templates-table-column-language",
		sortable: false,
		width: 160,
	},
];

const columnNumber = headCells.length + 2;

const initialCriteria: GroupCourseTemplateSearchCriteria = {};

function GroupCourseTemplates(props: {groupName: string}): JSX.Element {
	const {groupName} = props;

	const navBarHeight = useNavBarHeight();
	const classes = useStyles();

	const [criteria, setCriteria] = useState(initialCriteria);

	const [filtersOpen, setFiltersOpen] = useState(false);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val ?? undefined}));
	}, []);

	const [templatesPerPage, setTemplatesPerPage] = useState(10);

	const [sortField, setSortField] = useState<keyof CourseTemplate>("name");
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const {
		page: templatesPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage: fetchTemplatesPage,
		retryFetching,
		reloadPage,
	} = usePaginationState<CourseTemplate>();

	const templates = templatesPage.content;

	const {
		select: selectTemplate,
		bulkSelectionCheckbox,
		selected: selectedTemplates,
		resetSelection,
	} = useBulkSelection(templatesPage, (t) => t.id);

	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [menuTemplate, setMenuTemplate] = useState<CourseTemplate | null>(null);

	const [renameDialogOpen, setRenameDialogOpen] = useState(false);

	const [templatesToGrant, setTemplatesToGrant] = useState<number[]>([]);

	const mobileMode = useMobileMode("md");
	const theme = useTheme();
	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();
	const locale = useCurrentLocale();
	const formatDate = useDateFormat();
	const formatDatetime = useDateTimeFormat();
	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const [deleteTemplate, deleteTemplateErrorDialog] = useDeleteAction(
		reloadPage,
		openConfirmationDialog,
		showSnackbar
	);

	const langNames = useMemo(() => {
		const langNames = new Intl.DisplayNames([locale], {type: "language"});
		return langNames;
	}, [locale]);

	useEffect(() => {
		fetchFirstPage(
			() =>
				courseService.searchGroupTemplates(
					groupName,
					criteria,
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					templatesPerPage
				),
			resetSelection
		);
	}, [
		criteria,
		fetchFirstPage,
		groupName,
		resetSelection,
		sortField,
		sortOrder,
		templatesPerPage,
	]);

	function templateSelected(id: number) {
		return selectedTemplates.indexOf(id) !== -1;
	}

	function changeOrder(orderBy: keyof CourseTemplate, order: SortOrder) {
		setSortOrder(order);
		setSortField(orderBy);
	}

	const noTemplatesSelected = selectedTemplates.length === 0;

	async function renameTemplate(id: number, name: string) {
		try {
			await courseService.patchTemplate(id, {name});

			setRenameDialogOpen(false);
			reloadPage();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	const renameDialogId = "rename-template";

	return (
		<div className={classes.root}>
			<ExtendableBox
				rightExtension={filtersOpen || mobileMode ? 0 : filtersBarWidth}
				minWidth={0}
			>
				<Box
					display="flex"
					flexDirection="column"
					pt={mobileMode ? 3 : 5}
					px={mobileMode ? 3 : 6}
					pb={1.5}
					position="relative"
					style={{height: `calc(100vh - ${navBarHeight + 1}px)`}}
				>
					<TableToolbar
						query={criteria.query ?? ""}
						onQueryChange={setQuery}
						onToggleFilters={() => setFiltersOpen((prev) => !prev)}
						actionsHint={
							noTemplatesSelected && (
								<em>
									<Localized id="group-course-templates-bulk-actions-hint-select-to-perform">
										Select templates to perform actions
									</Localized>
								</em>
							)
						}
						actions={[
							{
								name: l10n.getString(
									"group-course-templates-bulk-action-grant-templates",
									null,
									"Grant the use of templates"
								),
								onClick: () => setTemplatesToGrant(selectedTemplates),
								disabled: noTemplatesSelected,
							},
						]}
						searchPlaceholder={l10n.getString(
							"group-course-templates-search-placeholder"
						)}
					/>
					<TableContainer className={classes.container}>
						<Table stickyHeader>
							<SortingHeader
								onOrderChange={changeOrder}
								order={sortOrder}
								orderBy={sortField}
								headCells={headCells}
								leftAnnex={
									<TableCell padding="checkbox" style={{zIndex: 3}}>
										{bulkSelectionCheckbox}
									</TableCell>
								}
								rightAnnex={<TableCell />}
								loading={pageFetchStatus === "pending"}
							/>
							<TableBody>
								{pageFetchStatus === "failed" && (
									<LoadingErrorState
										description={
											<Localized id="group-course-templates-loading-error-descr">
												Something has gone wrong, and we cannot load course
												templates
											</Localized>
										}
										columnNumber={columnNumber}
										onReload={retryFetching}
									/>
								)}
								{pageFetchStatus === "succeeded" && templates.length === 0 && (
									<NoSearchResultsState
										columnNumber={columnNumber}
										title={
											<Localized id="group-course-templates-no-templates">
												No course templates
											</Localized>
										}
										description={
											criteria === initialCriteria ? (
												<Localized id="group-course-templates-no-templates-descr">
													There are no course templates available to the group
												</Localized>
											) : (
												<Localized id="group-course-templates-no-results-descr">
													No course templates were found matching your search
													criteria. Try to adjust filters
												</Localized>
											)
										}
									/>
								)}
								{pageFetchStatus !== "failed" &&
									templates.length > 0 &&
									templates.map((template, index) => {
										const selected = templateSelected(template.id);
										const labelId = `table-checkbox-${index}`;
										const owned = template.organisationGroupName === groupName;
										const creationTime = parseDate(template.creationTime);

										return (
											<TableRow
												hover
												key={template.id}
												tabIndex={-1}
												selected={selected}
											>
												<TableCell padding="checkbox">
													<Checkbox
														checked={selected}
														inputProps={{"aria-labelledby": labelId}}
														onClick={() => selectTemplate(template.id)}
													/>
												</TableCell>
												<TableCell
													component="th"
													id={labelId}
													scope="row"
													style={{
														paddingTop: owned ? theme.spacing(1.5) : undefined,
														paddingBottom: owned
															? theme.spacing(1.5)
															: undefined,
													}}
												>
													<Box
														display="flex"
														justifyContent="space-between"
														alignItems="center"
													>
														{template.name}
														{owned && (
															<Chip
																label={
																	<Localized id="group-course-templates-template-label-owned">
																		Owned
																	</Localized>
																}
																size="small"
																variant="outlined"
															/>
														)}
													</Box>
												</TableCell>
												<TableCell align="right">
													<Tooltip
														title={formatDatetime(creationTime)}
														enterDelay={500}
														enterNextDelay={500}
													>
														<span>{formatDate(creationTime)}</span>
													</Tooltip>
												</TableCell>
												<TableCell>{langNames.of(template.language)}</TableCell>
												<TableCell padding="checkbox">
													<Tooltip
														title={
															<Localized id="group-course-templates-actions-label">
																Actions
															</Localized>
														}
														enterDelay={500}
														enterNextDelay={500}
													>
														<IconButton
															aria-label={l10n.getString(
																"group-course-templates-actions-label"
															)}
															onClick={(e) => {
																setMenuAnchor(e.currentTarget);
																setMenuTemplate(template);
															}}
														>
															<MoreVertIcon />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						ml={2}
					>
						<Typography variant="subtitle2">
							{selectedTemplates.length > 0 && (
								<Localized
									id="group-course-templates-selected-row-number"
									vars={{selected: selectedTemplates.length}}
								>{`${selectedTemplates.length} selected`}</Localized>
							)}
						</Typography>
						<TablePagination
							onPageChange={fetchTemplatesPage}
							pageSize={templatesPerPage}
							onPageSizeChange={setTemplatesPerPage}
							first={Boolean(templatesPage.request.first)}
							last={Boolean(templatesPage.request.last)}
							next={Boolean(templatesPage.request.next)}
							previous={Boolean(templatesPage.request.previous)}
							label={
								<Localized id="group-course-templates-per-page">
									Course templates per page
								</Localized>
							}
							disabled={pageFetchStatus !== "succeeded"}
						/>
					</Box>
				</Box>
			</ExtendableBox>
			<GroupCourseTemplatesFilters
				open={filtersOpen}
				width={
					mobileMode ? `calc(100vw - ${theme.spacing(7)})` : filtersBarWidth
				}
				mobileMode={mobileMode}
				criteria={criteria}
				onCriteriaChange={setCriteria}
				onClose={() => setFiltersOpen(false)}
			/>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				PaperProps={{style: {minWidth: theme.spacing(14)}}}
				onClose={() => setMenuAnchor(null)}
			>
				<MenuItem
					onClick={() => {
						menuTemplate && setTemplatesToGrant([menuTemplate.id]);
						setMenuAnchor(null);
					}}
				>
					<Localized id="group-course-templates-action-grant-use">
						Grant the use of template
					</Localized>
				</MenuItem>

				{menuTemplate?.organisationGroupName === groupName && [
					<MenuItem
						key="rename"
						onClick={() => {
							setRenameDialogOpen(true);
							setMenuAnchor(null);
						}}
					>
						<Localized id="group-course-templates-action-rename">
							Rename
						</Localized>
					</MenuItem>,

					<Divider
						key="divider"
						component="li"
						style={{margin: theme.spacing(1, 0)}}
					/>,

					<MenuItem
						key="delete"
						onClick={() => {
							deleteTemplate(menuTemplate.id, menuTemplate.name);
							setMenuAnchor(null);
						}}
					>
						<Localized id="group-course-templates-action-delete">
							Delete
						</Localized>
					</MenuItem>,
				]}
			</Menu>

			<GrantCourseTemplatesDialog
				open={templatesToGrant.length > 0}
				groupName={groupName}
				templateIds={templatesToGrant}
				onCancel={() => {
					setTemplatesToGrant([]);
				}}
				onDone={() => {
					setTemplatesToGrant([]);
					resetSelection();
				}}
			/>

			<Dialog
				maxWidth="sm"
				fullWidth
				open={renameDialogOpen}
				aria-labelledby={renameDialogId}
				onClose={() => setRenameDialogOpen(false)}
			>
				{menuTemplate && (
					<RenameTemplateDialog
						titleElementId={renameDialogId}
						initial={menuTemplate.name}
						onChange={(name) => renameTemplate(menuTemplate.id, name)}
						onClose={() => setRenameDialogOpen(false)}
					/>
				)}
			</Dialog>

			{confirmationDialog}
			{deleteTemplateErrorDialog}
		</div>
	);
}

export default GroupCourseTemplates;
