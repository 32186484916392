import {RootState} from "../store";
import ChapterProgress from "./ChapterProgress";

function selectProgressInChapter(
	state: RootState,
	chapterKey: string
): ChapterProgress | null {
	return state.courseProgress.byChapters[chapterKey] ?? null;
}

export default selectProgressInChapter;
