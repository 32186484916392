import {Localized} from "@fluent/react";
import {MenuItem, TextField} from "@mui/material";
import React from "react";

import CourseType from "../../store/courses/CourseType";

function CourseTypeSelector(props: {
	value: CourseType | undefined;
	onChange: (val: CourseType | undefined) => void;
}) {
	return (
		<TextField
			select
			fullWidth
			value={props.value ?? ""}
			label={<Localized id="filters-course-type-label">Type</Localized>}
			onChange={({target}) => {
				props.onChange(target.value ? (target.value as CourseType) : undefined);
			}}
		>
			<MenuItem value="">
				<em>
					<Localized id="filters-course-type-option-any">Any</Localized>
				</em>
			</MenuItem>
			<MenuItem value={CourseType.Ordinary}>
				<Localized id="filters-course-type-option-ordinary">Ordinary</Localized>
			</MenuItem>
			<MenuItem value={CourseType.Exam}>
				<Localized id="filters-course-type-option-exam">Exam</Localized>
			</MenuItem>
		</TextField>
	);
}

export default CourseTypeSelector;
