import {batch} from "react-redux";
import {createStructuredSelector} from "reselect";

import type FetchStatus from "../../../store/FetchStatus";
import type {AppDispatch, RootState} from "../../../store/store";

export type DataActionCreator<T extends string> = {
	[key in T]: () => Parameters<AppDispatch>[0];
};

export type DataFetchStatuses<T extends string> = {
	[key in T]: FetchStatus;
};

export function selectNotFetched<T extends string>(
	statuses: DataFetchStatuses<T>
): T[] {
	return Object.entries(statuses)
		.filter(([, v]) => v === "none")
		.map(([k]) => k) as T[];
}

export type DataFetcher<T extends string> = {
	selectFetchStatuses: (state: RootState) => DataFetchStatuses<T>;
	fetch: (keys: T[]) => Promise<void>;
};

export function createDataFetcher<T extends string>(
	dispatch: AppDispatch,
	selectors: {[key in T]: (state: RootState) => FetchStatus},
	actionCreator: DataActionCreator<T>
): DataFetcher<T> {
	return {
		selectFetchStatuses: createStructuredSelector<
			RootState,
			DataFetchStatuses<T>
		>(selectors),

		async fetch(keys) {
			batch(() => keys.forEach((key) => dispatch(actionCreator[key]())));
		},
	};
}
