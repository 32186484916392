import {Stack, Typography} from "@mui/material";
import React from "react";

function ShortAnswer(props: {value: string[]}) {
	return (
		<Stack spacing={1}>
			{props.value.map((v, i) => (
				<Typography key={i}>{v}</Typography>
			))}
		</Stack>
	);
}

export default ShortAnswer;
