import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";

const fetchCourseManagementModules = createAsyncThunk(
	"courseManagementModules/fetch",
	async (params: {courseId: number; userId: number}) => {
		const modules = await courseService.getManagementModules(
			params.courseId,
			params.userId
		);

		return {modules, key: keyProvider.course(params.courseId)};
	}
);

export default fetchCourseManagementModules;
