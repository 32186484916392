import React from "react";
import AddExerciseDialog from "../components/addExerciseDialog/AddExerciseDialog";
import ExerciseBankDialog from "../components/exerciseBankDialog/ExerciseBankDialog";
import {useAppSelector} from "../store/hooks";
import {ModalDialogName, selectModalDialog} from "../store/ui/modalDialogSlice";

const ModalDialogSelector = (props: {
	organisationName: string;
}): JSX.Element => {
	const dialog = useAppSelector(selectModalDialog);

	if (!dialog) {
		return <></>;
	}

	const params = dialog.dialogParams;

	const selectDialog = () => {
		switch (dialog.dialogName) {
			case ModalDialogName.AddExercise:
				return (
					<AddExerciseDialog
						courseId={params.courseId}
						sectionId={params.sectionId}
						chapterId={params.chapterId}
					/>
				);
			case ModalDialogName.ExerciseBank:
				return (
					<ExerciseBankDialog
						organisationName={props.organisationName}
						courseId={params.courseId}
						sectionId={params.sectionId}
						chapterId={params.chapterId}
					/>
				);
			default:
				return <></>;
		}
	};

	return selectDialog();
};

export default ModalDialogSelector;
