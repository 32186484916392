import {createAsyncThunk} from "@reduxjs/toolkit";

import authService from "../services/authService";
import sessionStore from "./sessionStore";

const loginUser = createAsyncThunk(
	"userSession/login",
	async (data: {username: string; password: string; remember: boolean}) => {
		try {
			await authService.login(data.username, data.password, data.remember);
		} finally {
			sessionStore.removeSession();
		}
	}
);

export default loginUser;
