import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "../store";
import {keyProvider} from "../keyProvider";
import ExerciseType from "../exercises/ExerciseType";

const empty: ExerciseType[] = [];

const selectAvailableExerciseTypes = createSelector(
	(state: RootState) => state.courses.byKey,
	(_: RootState, courseId: number) => courseId,
	(_: RootState, __: number, externalExercisesEnabled?: boolean) =>
		externalExercisesEnabled,
	(courses, courseId, externalExercisesEnabled): ExerciseType[] => {
		const course = courses[keyProvider.course(courseId)]?.course;
		if (!course) {
			return empty;
		}
		let types = course.availableExerciseTypes.slice();
		if (!externalExercisesEnabled) {
			types = types.filter((t) => t !== ExerciseType.External);
		}

		return types.sort();
	}
);

export default selectAvailableExerciseTypes;
