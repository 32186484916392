import {
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {Localized} from "@fluent/react";
import React, {useEffect, useState} from "react";

import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import {analyticsService} from "../../store/services/analyticsService";
import type {StudentRanking} from "../../store/services/analyticsService";
import {useAppSelector} from "../../store/hooks";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import selectCourseStats from "../../store/courseStatistics/selectCourseStats";
import {keyProvider} from "../../store/keyProvider";
import PlainProgressBar from "../analytics/PlainProgressBar";
import CompletionLabel from "../analytics/CompletionLabel";

function createFakeRankings(): StudentRanking[] {
	return Array.from({length: 15}, (_, i) => {
		let number = 1;
		if (4 <= i && i <= 10) {
			number = 3;
		} else if (i > 10) {
			number = 5;
		}

		return {
			id: Math.random(),
			number,
			numberOfCompleted: 0,
			ranking: i,
			score: 0,
		};
	});
}

const HighScoresWidget = (props: {
	id: string;
	title: React.ReactNode;
	studentId: number;
	courseId: number;
}): JSX.Element => {
	const {studentId, courseId} = props;

	const [rankings, setRankings] = useState<StudentRanking[]>(() => []);

	const [fetching, setFetching] = useState(true);

	useEffect(() => {
		async function fetchRankings() {
			setFetching(true);

			try {
				const res = await analyticsService.getStudentRankings(
					studentId,
					courseId
				);

				setRankings(res);
			} finally {
				setFetching(false);
			}
		}

		fetchRankings();
	}, [courseId, studentId]);

	const courseStats = useAppSelector((state) =>
		selectCourseStats(state, keyProvider.course(courseId))
	);

	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody>
				<RankingsTable
					rankings={fetching ? createFakeRankings() : rankings}
					courseStats={courseStats}
					loading={fetching}
				/>
			</WidgetBody>
		</Widget>
	);
};

const columns = [
	{
		id: "rank",
		label: (
			<Localized id="my-corner-high-scores-widget-column-rank">Rank</Localized>
		),
	},
	{
		id: "name",
		label: (
			<Localized id="my-corner-high-scores-widget-column-name">Name</Localized>
		),
	},
	{
		id: "progress",
		label: (
			<Localized id="my-corner-high-scores-widget-column-progress">
				Progress
			</Localized>
		),
	},
];

function RankingsTable(props: {
	rankings: StudentRanking[];
	courseStats: {
		maxScore: number;
		numberOfExercises: number;
	} | null;
	loading?: boolean;
}) {
	const {rankings, courseStats, loading} = props;

	const theme = useTheme();

	const {userName, id: userId, firstName, lastName} = useAppSelector(
		selectUserProfile
	);

	const name =
		firstName || lastName ? [firstName, lastName].join(" ") : userName;

	return (
		<Table>
			<TableHead>
				<TableRow>
					{columns.map((c) => (
						<TableCell key={c.id}>{c.label}</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{rankings.map((r, i) => {
					const progressLabel = (
						<CompletionLabel
							completed={r.numberOfCompleted}
							total={courseStats?.numberOfExercises}
						/>
					);

					const scoreLabel = (
						<Typography variant="body2" color="textSecondary">
							<Localized
								id="my-corner-high-scores-widget-progress-score-label"
								vars={{
									earned: r.score,
									total: courseStats?.maxScore ?? "?",
								}}
							>{`Score: ${r.score}/${courseStats?.maxScore ?? "?"}`}</Localized>
						</Typography>
					);

					return (
						<React.Fragment key={r.id}>
							<TableRow selected={userId === r.id}>
								<TableCell>
									{loading ? <Skeleton variant="rectangular" /> : r.ranking}
								</TableCell>
								<TableCell>{userId === r.id ? name : "****"}</TableCell>
								<TableCell>
									<PlainProgressBar
										overallProgress={r}
										leftLabel={progressLabel}
										rightLabel={scoreLabel}
										stats={courseStats ?? {maxScore: 0, numberOfExercises: 0}}
										loading={loading}
									/>
								</TableCell>
							</TableRow>
							{i < rankings.length - 1 &&
								rankings[i + 1].number - r.number > 1 && (
									<TableRow>
										<TableCell
											colSpan={4}
											align="center"
											style={{
												fontSize: "1.5rem",
												paddingTop: theme.spacing(0.5),
												paddingBottom: theme.spacing(0.5),
											}}
										>
											⋮
										</TableCell>
									</TableRow>
								)}
						</React.Fragment>
					);
				})}
			</TableBody>
		</Table>
	);
}

export default HighScoresWidget;
