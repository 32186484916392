import {Localized} from "@fluent/react";
import {IconButton, Tooltip, useTheme} from "@mui/material";
import React from "react";
import {Link, Route} from "react-router-dom";

import Nav from "../components/nav/Nav";
import OrganisationSelector from "../components/nav/OrganisationSelector";
import AdminPanelIcon from "../icons/AdminPanelIcon";
import UserRole from "../store/models/UserRole";
import useUserHasRoleInOrganisation from "../store/organisation/useUserHasRoleInOrganisation";

function NavigationBar(props: {organisationName: string}) {
	const [userHasRole] = useUserHasRoleInOrganisation();

	const theme = useTheme();

	return (
		<Nav
			logo={`/api/organisations/${props.organisationName}/theme/logo`}
			quickLinks={
				userHasRole(UserRole.Administrator) && (
					<Tooltip
						title={
							<Localized id="nav-organisation-administration">
								Organisation administration
							</Localized>
						}
					>
						<IconButton
							size="small"
							component={Link}
							to="/administration"
							style={{
								color: theme.palette.primary.contrastText,
								padding: theme.spacing(0.5),
							}}
						>
							<AdminPanelIcon />
						</IconButton>
					</Tooltip>
				)
			}
		>
			<Route exact path={["/", "/enrolment-application"]}>
				<OrganisationSelector
					currentOrganisationName={props.organisationName}
				/>
			</Route>
		</Nav>
	);
}

export default NavigationBar;
