import {Localized} from "@fluent/react";
import {
	Box,
	Chip,
	IconButton,
	Skeleton,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import CompletionLabel from "../analytics/CompletionLabel";
import PlainProgressBar from "../analytics/PlainProgressBar";
import ProgressBarLegend, {
	calculateValuesForColors,
} from "../analytics/ProgressBarLegend";
import ProgressTooltip from "../analytics/ProgressTooltip";
import ScoreLabel from "../analytics/ScoreLabel";
import selectChaptersWhichRequireExplicitSessionStart from "../../store/chapters/selectChaptersWhichRequireExplicitSessionStart";
import type ContentStatsByExerciseType from "../../store/courseStatistics/ContentStatsByExerciseType";
import type CourseStats from "../../store/courseStatistics/CourseStats";
import selectCourseContentStatsByExerciseType from "../../store/courseStatistics/selectCourseContentStatsByExerciseType";
import type ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type {
	AssessmentStatus,
	ProgressByChapter,
	ProgressByType,
	StudentResultsInCourse,
} from "../../store/services/analyticsService";
import StudentName from "../students/StudentName";
import {studentResultsColumnDefs} from "./studentResultsColumns";
import ProgressBar from "../../utils/ProgressBar";
import StackedProgressBar from "../../utils/StackedProgressBar";

const useStyles = makeStyles(() =>
	createStyles({
		hidden: {
			visibility: "hidden",
			"& > td": {
				borderBottom: "1px solid transparent",
			},
		},
	})
);

const StudentResultsRow = (props: {
	student: StudentResultsInCourse;
	courseId: number;
	courseStats: CourseStats;
	loading?: boolean;
	actionIcon: React.ReactNode;
	onAction: () => void;
	barType?: "stacked" | "plain";
	progressByTypeInChapters: ProgressByChapter[];
	progressByTypeLoading?: boolean;
	selectionCellContent?: React.ReactNode;
}): JSX.Element => {
	const {student: s, loading, courseStats} = props;

	const classes = useStyles();

	const progressLabel = (
		<CompletionLabel
			completed={s.numberOfCompletedExercises}
			total={courseStats.numberOfExercises}
		/>
	);

	const scoreLabel = (
		<ScoreLabel score={s.score} maxScore={courseStats.maxScore} />
	);

	const overallProgress = {
		numberOfCompleted: s.numberOfCompletedExercises,
		score: s.score,
	};

	let progressBar = <></>;
	if (loading) {
		progressBar = (
			<ProgressBar
				theme="grey"
				leftLabel={<Skeleton variant="rectangular">{progressLabel}</Skeleton>}
				rightLabel={<Skeleton variant="rectangular">{scoreLabel}</Skeleton>}
			/>
		);
	} else if (props.barType === "stacked" && !props.progressByTypeLoading) {
		progressBar = (
			<StackedBar
				courseId={props.courseId}
				progressByChapters={props.progressByTypeInChapters}
				courseStats={courseStats}
				overallProgress={overallProgress}
				leftLabel={progressLabel}
				rightLabel={scoreLabel}
			/>
		);
	} else {
		progressBar = (
			<PlainProgressBar
				overallProgress={overallProgress}
				stats={courseStats}
				leftLabel={progressLabel}
				rightLabel={scoreLabel}
				loading={props.progressByTypeLoading}
			/>
		);
	}

	return (
		<TableRow
			tabIndex={-1}
			className={!s.userName && !loading ? classes.hidden : undefined}
		>
			{props.selectionCellContent && (
				<TableCell padding="checkbox">{props.selectionCellContent}</TableCell>
			)}
			<TableCell sx={{width: studentResultsColumnDefs.student.width}}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<StudentName student={s} loading={loading} />
					<AssessementStatusLabel status={s.assessmentStatus} />
				</Box>
			</TableCell>
			<TableCell>{progressBar}</TableCell>
			<TableCell padding="checkbox">
				{!loading && (
					<IconButton onClick={props.onAction}>{props.actionIcon}</IconButton>
				)}
			</TableCell>
		</TableRow>
	);
};

function AssessementStatusLabel(props: {status: AssessmentStatus}) {
	if (props.status === "assessed") {
		return (
			<Chip
				label={
					<Localized id="follow-up-student-results-widget-assessment-status-assessed">
						Assessed
					</Localized>
				}
				size="small"
				variant="outlined"
			/>
		);
	}

	return <></>;
}

function StackedBar(props: {
	courseId: number;
	progressByChapters: ProgressByChapter[];
	courseStats: CourseStats;
	leftLabel: React.ReactNode;
	rightLabel?: React.ReactNode;
	overallProgress: {
		numberOfCompleted: number;
		score: number;
	};
}): JSX.Element {
	const {courseStats, progressByChapters, overallProgress} = props;

	const courseKey = keyProvider.course(props.courseId);

	const chaptersWithSessions = useAppSelector((state) =>
		selectChaptersWhichRequireExplicitSessionStart(state, courseKey)
	);

	const courseContentStats = useAppSelector((state) =>
		selectCourseContentStatsByExerciseType(state, courseKey)
	);

	const progressGroups = groupByChapterType(
		progressByChapters,
		chaptersWithSessions
	);

	return (
		<ProgressTooltip
			completed={overallProgress.numberOfCompleted}
			total={courseStats.numberOfExercises}
			score={overallProgress.score}
			maxScore={courseStats.maxScore}
			additionalText={
				<Box mt={1}>
					{progressGroups.chapters.length > 0 && (
						<LegendGroup
							title={
								<Localized id="follow-up-student-results-widget-legend-chapters">
									Chapters
								</Localized>
							}
							source={progressGroups.chapters}
							contentStats={courseContentStats}
						/>
					)}

					{progressGroups.tests.length > 0 && (
						<LegendGroup
							title={
								<Localized id="follow-up-student-results-widget-legend-tests">
									Tests
								</Localized>
							}
							source={progressGroups.tests}
						/>
					)}
				</Box>
			}
		>
			<StackedProgressBar
				maxValue={courseStats.numberOfExercises}
				rightLabel={props.rightLabel}
				values={calculateValuesForColors(
					calculateTotalProgressByExerciseType(
						progressByChapters.flatMap((p) => p.progress)
					)
				)}
				leftLabel={props.leftLabel}
			/>
		</ProgressTooltip>
	);
}

function LegendGroup(props: {
	title: React.ReactNode;
	source: ProgressByChapter[];
	contentStats?: ContentStatsByExerciseType;
}) {
	return (
		<>
			<Typography variant="subtitle2">{props.title}</Typography>
			<ProgressBarLegend
				progress={calculateTotalProgressByExerciseType(
					props.source.flatMap((p) => p.progress)
				)}
				stats={props.contentStats}
			/>
		</>
	);
}

function calculateTotalProgressByExerciseType(progress: ProgressByType[]) {
	const valuesByType: {
		[key in ExerciseType]?: {numberOfCompleted: number; score: number};
	} = {};

	progress.forEach((p) => {
		let v = valuesByType[p.exerciseType];
		if (!v) {
			v = {numberOfCompleted: 0, score: 0};
			valuesByType[p.exerciseType] = v;
		}

		v.numberOfCompleted += p.numberOfCompleted;
		v.score += p.score;
	});

	return valuesByType;
}

function groupByChapterType(
	progressByChapters: ProgressByChapter[],
	chaptersWithSession: string[]
) {
	const res: {
		chapters: ProgressByChapter[];
		tests: ProgressByChapter[];
	} = {chapters: [], tests: []};

	progressByChapters.forEach((p) => {
		if (chaptersWithSession.includes(keyProvider.chapter(p.chapterId))) {
			res.tests.push(p);
		} else {
			res.chapters.push(p);
		}
	});

	return res;
}

export default StudentResultsRow;
