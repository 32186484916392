import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type AnswerVisibility from "../chapterExercises/AnswerVisibility";
import type ChapterStructureType from "../chapters/ChapterStructureType";
import type FeedbackVisibility from "../chapters/FeedbackVisibility";
import type SelectionMode from "../chapters/SelectionMode";
import type SubmissionMode from "../chapters/SubmissionMode";
import type CoursePatch from "../courses/CoursePatch";
import type CourseManagementModule from "../courses/CourseManagementModule";
import type CourseStudyModule from "../courses/CourseStudyModule";
import CourseType from "../courses/CourseType";
import type NewCourse from "../courses/NewCourse";
import type RoleInCourse from "../courses/RoleInCourse";
import type StaffMemberRole from "../courses/StaffMemberRole";
import type StudyTarget from "../courses/StudyTarget";
import type {Chapter} from "./dtos/Chapter";
import type {ChapterContent} from "./dtos/ChapterContent";
import type ChapterStructure from "./dtos/ChapterStructure";
import type CourseTemplate from "./dtos/CourseTemplate";
import type CourseWithSettings from "./dtos/CourseWithSettings";
import type OrganisationCourseSearchResult from "./dtos/OrganisationCourseSearchResult";
import type OrganisationGroupCourseSearchResult from "./dtos/OrganisationGroupCourseSearchResult";
import type ComparisonStrictness from "../exercises/ComparisonStrictness";
import type Interactions from "../exercises/Interactions";
import type {ProgInteractions} from "../exercises/Interactions";
import type {EditableExercise, ExternalExercise} from "./dtos/EditableExercise";
import type {Exercise} from "./dtos/Exercise";
import type ExerciseItem from "../exercises/ExerciseItem";
import ExercisePrivacy from "../exercises/ExercisePrivacy";
import ExerciseType from "../exercises/ExerciseType";
import {convertDateToDateTimeInUTC} from "../../helpers/dateTimeHelpers";
import {countLines} from "../../helpers/fileHelpers";
import {Page, createPage} from "../../helpers/paginatedSearchHelpers";
import DefaultCoursePicture from "../../images/default-course-picture.jpg";
import type SearchResult from "../models/SearchResult";
import type UserCourse from "../models/UserCourse";
import {mapTableSchema} from "./progExerciseService";
import type {RawTableSchema} from "./progExerciseService";
import {updateChapterStructureParams} from "../sections/updateChapterStructureParams";

interface Params {
	type?: string;
	status?: string | string[];
	userRole?: RoleInCourse | RoleInCourse[];
	excluded?: number[];
	query?: string;
	pageSize?: number;
}

export type UserCourseSearchCriteria = {
	type?: CourseType;
	status?: string[];
	userRole?: RoleInCourse[];
	excluded?: number[];
	query?: string;
};

export type CourseTemplateSearchCriteria = {
	language?: string;
	query?: string;
};

export type GroupCourseTemplateSearchCriteria = CourseTemplateSearchCriteria & {
	ids?: number[];
	ownership?: "any" | "owned";
};

export type OrganisationCourseSearchCriteria = {
	ids?: number[];
	type?: CourseType;
	startDateAfter?: string;
	startDateBefore?: string;
	endDateAfter?: string;
	endDateBefore?: string;
	language?: string;
	query?: string;
};

export type OrganisationGroupCourseSearchCriteria = OrganisationCourseSearchCriteria & {
	organisationNames?: string[];
};

export const courseService = {
	assignStaff: async (
		courseId: number,
		staff: {id: number; roles: ("teacher" | "tutor")[]}[]
	) => {
		const url = `/api/courses/${courseId}/staff-members`;

		await client.post(url, staff);
	},

	changeRolesOfStaffMember: async (
		courseId: number,
		memberId: number,
		roles: StaffMemberRole[]
	) => {
		const url = `/api/courses/${courseId}/staff-members/${memberId}`;

		await client.patch(url, {roles});
	},

	removeStaffMember: async (courseId: number, memberId: number) => {
		const url = `/api/courses/${courseId}/staff-members/${memberId}`;

		await client.delete(url);
	},

	loadMoreUserCourses: async (
		link: string
	): Promise<SearchResult<UserCourse>> => {
		const {data} = await client.get(link);
		for (const item of data.content) {
			item.defaultPicture = DefaultCoursePicture;
		}

		return data;
	},

	getUserCourses: async (
		orgName: string,
		userId: number,
		params: Params,
		sort?: {field: string; descending?: boolean}
	): Promise<SearchResult<UserCourse>> => {
		const resource = `/api/organisations/${orgName}/users/${userId}/courses`;

		const ps = new URLSearchParams();

		if (params.type) {
			ps.append("type", params.type);
		}

		if (Array.isArray(params.status)) {
			params.status.forEach((status) => {
				ps.append("status", status);
			});
		} else if (params.status) {
			ps.append("status", params.status);
		}

		if (Array.isArray(params.userRole)) {
			params.userRole.forEach((role) => {
				ps.append("userRole", role);
			});
		} else if (params.userRole) {
			ps.append("userRole", params.userRole);
		}

		params.excluded?.forEach((id) => {
			ps.append("excluded", id.toString());
		});

		if (params.pageSize) {
			ps.append("pageSize", params.pageSize.toString());
		}

		if (sort) {
			ps.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		}

		if (params.query) {
			ps.append("query", params.query);
		}

		const {data} = await client.get<SearchResult<UserCourse>>(resource, {
			params: ps,
		});

		for (const item of data.content) {
			item.defaultPicture = DefaultCoursePicture;
		}
		return data;
	},

	searchUserCourses: async (
		orgName: string,
		userId: number,
		criteria: UserCourseSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<UserCourse>> => {
		const res = await courseService.getUserCourses(
			orgName,
			userId,
			{...criteria, pageSize},
			sort
		);

		return createPage(client, res.content, res.links);
	},

	getCourse: async (courseId: number): Promise<CourseWithSettings> => {
		const {data} = await client.get<CourseWithSettings>(
			`/api/courses/${courseId}`
		);
		data.defaultPicture = DefaultCoursePicture;

		data.startDate = data.startDate.slice(0, 10);
		data.endDate = data.endDate.slice(0, 10);

		return data;
	},

	searchTemplates: async (
		orgName: string,
		criteria: CourseTemplateSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<CourseTemplate>> => {
		const url = `/api/organisations/${orgName}/course-templates`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		if (criteria.language) {
			params.append("language", criteria.language);
		}
		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	searchGroupTemplates: async (
		groupName: string,
		criteria: GroupCourseTemplateSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<CourseTemplate>> => {
		const url = `/api/organisation-groups/${groupName}/course-templates`;

		const params = new URLSearchParams();

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		criteria.ids?.forEach((id) => params.append("id", id.toString()));

		if (criteria.language) {
			params.append("language", criteria.language);
		}
		if (criteria.ownership) {
			params.append("ownership", criteria.ownership);
		}
		if (criteria.query) {
			params.append("query", criteria.query);
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	createTemplateInOrganisationGroup: async (
		groupName: string,
		courseId: number,
		templateName: string
	): Promise<void> => {
		const url = `/api/organisation-groups/${groupName}/course-templates`;

		await client.post(url, {courseId, templateName});
	},

	patchTemplate: async (
		templateId: number,
		patch: {name?: string}
	): Promise<void> => {
		const url = `/api/course-templates/${templateId}`;

		await client.patch(url, patch, {
			headers: {"Content-Type": "application/merge-patch+json"},
		});
	},

	deleteTemplate: async (templateId: number): Promise<void> => {
		const url = `/api/course-templates/${templateId}`;

		try {
			await client.delete(url);
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 409) {
				throw {code: "conflict"};
			}

			throw err;
		}
	},

	createCourse: async (
		organisatonName: string,
		course: NewCourse
	): Promise<number> => {
		const {data} = await client.post(
			`/api/organisations/${organisatonName}/courses`,
			course
		);

		return data.id;
	},

	copyCourse: async (
		organisationName: string,
		courseId: number,
		name: string,
		options?: {
			teacherIds?: number[];
		}
	): Promise<number> => {
		const {data} = await client.post(
			`/api/organisations/${organisationName}/courses`,
			{
				copyOf: courseId,
				name,
				teacherIds: options?.teacherIds,
			}
		);

		return data.id;
	},

	getChaptersFromCourse: async (courseId: number): Promise<Chapter[]> => {
		const {data} = await client.get<Chapter[]>(
			`/api/courses/${courseId}/chapters`
		);
		return data;
	},

	getExercises: async (
		courseId: number,
		chapterId: number,
		exerciseIds: number[]
	): Promise<Exercise[]> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/exercises`;

		return getExercises(url, exerciseIds);
	},

	getUserRolesInCourse: async (
		userId: number,
		courseId: number
	): Promise<RoleInCourse[]> => {
		const {data} = await client.get<RoleInCourse[]>(
			`/api/users/${userId}/courses/${courseId}/roles`
		);

		return data;
	},

	publishAllChapters: async (
		courseId: number,
		courseType: CourseType
	): Promise<void> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/published-chapters`,
			{
				action: "pub_all",
				is_exam: courseType === CourseType.Exam,
			}
		);

		if (!data.rows) {
			throw new Error();
		}
	},

	unpublishAllChapters: async (
		courseId: number,
		courseType: CourseType
	): Promise<void> => {
		const {data} = await client.delete(
			`/api/courses/${courseId}/published-chapters`,
			{
				data: {
					action: "un_pub_all",
					is_exam: courseType === CourseType.Exam,
					publishing_date: null,
				},
			}
		);

		if (!data.rows) {
			throw new Error();
		}
	},

	getChapterContent: async (
		courseId: number,
		chapterId: number
	): Promise<ChapterContent> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/contents`;

		return getChapterContent(url, chapterId);
	},

	updateChapterStructure: async (
		courseId: number,
		chapterId: number,
		params: updateChapterStructureParams
	): Promise<ChapterStructure> => {
		try {
			const {data} = await client.put(
				`/api/courses/${courseId}/chapters/${chapterId}/structure`,
				params
			);

			return data;
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 409) {
				throw {code: "responses_exist"};
			} else {
				throw error;
			}
		}
	},

	addChapter: async (
		courseId: number,
		chapter: {
			title: string;
			number: number;
			submissionMode: SubmissionMode;
			selectionMode: SelectionMode;
			structureType: ChapterStructureType;
			sessionLimit?: number;
			timeLimit?: number;
			feedbackVisibility: FeedbackVisibility;
		}
	): Promise<number> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/chapters`,
			chapter
		);
		return data.id;
	},

	deleteChapter: async (
		courseId: number,
		chapterId: number,
		courseType: CourseType
	): Promise<void> => {
		const {data} = await client.delete(
			`/api/courses/${courseId}/chapters/${chapterId}`,
			{
				data: {
					chapter_id: chapterId,
					is_exam: courseType === CourseType.Exam,
					action: "del_chapter",
				},
			}
		);

		if (!data.rows) {
			throw new Error();
		}
	},

	moveChapter: async (
		courseId: number,
		id: number,
		place: {before?: number}
	): Promise<void> => {
		await client.post(`/api/courses/${courseId}/chapters`, {
			id,
			...place,
		});
	},

	addSection: async (
		courseId: number,
		chapterId: number,
		section: {
			title: string;
			content: string;
		}
	): Promise<{id: number}> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/chapters/${chapterId}/exercises`,
			[
				{
					type: ExerciseType.Theory,
					title: section.title,
					contentSyntax: "wiz",
					content: section.content,
					rawContent: section.content,
				},
			]
		);
		return data;
	},

	updateChapter: async (
		courseId: number,
		chapterId: number,
		updated: {
			title?: string;
			startDate?: string | null;
			endDate?: string | null;
			sessionLimit?: number | null;
			timeLimit?: number | null;
			answerVisibility?: AnswerVisibility | null;
			feedbackVisibility?: FeedbackVisibility;
		}
	): Promise<void> => {
		try {
			await client.patch(
				`/api/courses/${courseId}/chapters/${chapterId}`,
				updated,
				{headers: {"Content-Type": "application/merge-patch+json"}}
			);
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 409) {
				throw {code: "conflict"};
			} else {
				throw error;
			}
		}
	},

	updateCourseSettings: async (
		courseId: number,
		request: CoursePatch
	): Promise<void> => {
		const url = `/api/courses/${courseId}`;

		const body = {...request};

		if (request.startDate) {
			body.startDate = convertDateToDateTimeInUTC(request.startDate);
		}
		if (request.endDate) {
			body.endDate = convertDateToDateTimeInUTC(request.endDate);
		}

		await client.patch(url, body, {
			headers: {"Content-Type": "application/merge-patch+json"},
		});
	},

	addTheoryExericise: async (
		courseId: number,
		chapterId: number,
		sectionId: number,
		exercise: ExerciseItem
	): Promise<{id: number}> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}/exercises`,
			[
				{
					type: ExerciseType.Theory,
					title: exercise.title,
					contentSyntax: "wiz",
					content: exercise.content,
					rawContent: exercise.content,
				},
			]
		);
		return data;
	},

	updateTheoryExercise: async (
		courseId: number,
		exercise: ExerciseItem
	): Promise<{id: number}> => {
		const {data} = await client.put(
			`/api/courses/${courseId}/exercises/${exercise.id}`,
			{
				exercise_template_id: exercise.id,
				exercise_title: exercise.title,
				exercise_type: ExerciseType.Theory,
				question: exercise.content,
				question_orig: exercise.content,
				content_syntax: "wiz",
				action: "save",
			}
		);
		return {id: data.exercise_template_id};
	},

	createExercise: async (
		courseId: number,
		exercise: EditableExercise
	): Promise<number> => {
		if (exercise.type !== ExerciseType.External) {
			const params = mapToExerciseRequestParams(exercise);
			const {data} = await client.post(
				`/api/courses/${courseId}/exercises`,
				params
			);
			if (!data.exercise_template_id) {
				throw new Error();
			}
			return data.exercise_template_id;
		}

		const params = mapToExternalExerciseRequestParams(exercise, courseId);
		const {data} = await client.post("/api/exercises", params);

		return data.id;
	},

	updateExercise: async (
		courseId: number,
		exerciseId: number,
		exercise: EditableExercise
	): Promise<void> => {
		let url = "";
		let params;
		if (exercise.type !== ExerciseType.External) {
			url = `/api/courses/${courseId}/exercises/${exerciseId}`;
			params = mapToExerciseRequestParams(exercise, exerciseId);
		} else {
			url = `/api/exercises/${exerciseId}`;
			params = mapToExternalExerciseRequestParams(exercise, courseId);
		}

		await client.put(url, params);
	},

	getOrganisationCourses: async (
		orgName: string,
		ids: number[]
	): Promise<OrganisationCourseSearchResult[]> => {
		const url = `/api/organisations/${orgName}/courses`;

		const params = new URLSearchParams();
		ids.forEach((id) => {
			params.append("id", id.toString());
		});

		const {data} = await client.get<{
			content: OrganisationCourseSearchResult[];
		}>(url, {params});

		return data.content;
	},

	searchOrganisationCourses: async (
		orgName: string,
		criteria: OrganisationCourseSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<OrganisationCourseSearchResult>> => {
		const url = `/api/organisations/${orgName}/courses`;

		const params = makeCourseSearchParams(criteria, sort, pageSize);

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	searchCoursesInOrganisationGroup: async (
		groupName: string,
		criteria: OrganisationGroupCourseSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<OrganisationGroupCourseSearchResult>> => {
		const url = `/api/organisation-groups/${groupName}/courses`;

		const params = makeCourseSearchParams(criteria, sort, pageSize);

		criteria.organisationNames?.forEach((o) => {
			params.append("organisationName", o);
		});

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	getCourseStudyTargets: async (courseId: number): Promise<StudyTarget[]> => {
		const {data} = await client.get(`/api/courses/${courseId}/study-targets`);
		return data.content;
	},

	saveCourseStudyTargets: async (
		courseId: number,
		targets: StudyTarget[]
	): Promise<void> => {
		await client.put(`/api/courses/${courseId}/study-targets`, targets);
	},

	getStudyModules: async (
		courseId: number,
		userId?: number
	): Promise<CourseStudyModule[]> => {
		const url = `/api/courses/${courseId}/study-modules`;

		const params = new URLSearchParams();

		if (userId) {
			params.append("availableTo", userId.toString());
		}

		const {data} = await client.get(url, {params});

		return data.content;
	},

	getManagementModules: async (
		courseId: number,
		userId?: number
	): Promise<CourseManagementModule[]> => {
		const url = `/api/courses/${courseId}/management-modules`;

		const params = new URLSearchParams();

		if (userId) {
			params.append("availableTo", userId.toString());
		}

		const {data} = await client.get(url, {params});

		return data.content;
	},
};

export async function getExercises(
	url: string,
	exerciseIds: number[]
): Promise<Exercise[]> {
	const params = new URLSearchParams();
	exerciseIds.forEach((id) => {
		params.append("id", id.toString());
	});

	type responseExercise = Omit<Exercise, "interactions"> & {
		interactions:
			| Exclude<Interactions, ProgInteractions>
			| (Omit<ProgInteractions, "initialSchemaAndState"> & {
					initialSchemaAndState: RawTableSchema;
			  });
	};

	const {data} = await client.get<responseExercise[]>(url, {params});

	return data.map<Exercise>((ex) => {
		if (
			ex.type === ExerciseType.Prog &&
			"initialSchemaAndState" in ex.interactions &&
			ex.interactions.initialSchemaAndState.length > 0
		) {
			return {
				...ex,
				interactions: {
					...ex.interactions,
					initialSchemaAndState: mapTableSchema(
						ex.interactions.initialSchemaAndState
					),
				},
			};
		}

		return ex as Exercise;
	});
}

export async function getChapterContent(url: string, chapterId: number) {
	const {data} = await client.get<ChapterContent>(url);

	data.id = chapterId;

	data.sections.forEach((section) => {
		section.subsections.forEach((exercise) => {
			if ((exercise.answerVisibility as string) === "default") {
				delete exercise.answerVisibility;
			}
		});
	});

	return data;
}

export function mapToExerciseRequestParams(
	exercise: EditableExercise,
	exerciseId?: number,
	action?: string
): Record<string, unknown> {
	const mapped = {
		exercise_template_id: exerciseId ?? "new",
		exercise_title: exercise.title,
		exercise_type: exercise.type,
		question: exercise.question,
		exercise_solution: exercise.solution,
		exercise_category: exercise.category,
		question_orig: exercise.question,
		content_syntax: "wiz",
		difficulty_level: exercise.difficultyLevel,
		action: action ?? "save",
	};

	switch (exercise.type) {
		case ExerciseType.Multi: {
			const answer = exercise.choices.map((el) => ({
				choice_text: el.text,
				choice_points: el.score,
				choice_comment: el.comment,
			}));

			return {
				...mapped,
				answer: answer,
				evaluation_packet: exercise.subtype,
				public_exercise: exercise.privacy === ExercisePrivacy.Public,
				public_to_org:
					exercise.privacy === ExercisePrivacy.PublicToOrganisation,
			};
		}
		case ExerciseType.Open:
			return {
				...mapped,
				exercise_points: exercise.maxScore,
				final_answer: exercise.finalAnswer,
				evaluation_packet: exercise.subtype,
				privacy: exercise.privacy,
			};
		case ExerciseType.Math:
			return {
				...mapped,
				answer_example: exercise.finalAnswer,
				evaluation_packet: exercise.subtype,
				extra_info: exercise.expression,
				show_expression: exercise.expressionVisible,
				variable: exercise.variable,
				public_exercise: exercise.privacy === ExercisePrivacy.Public,
				public_to_org:
					exercise.privacy === ExercisePrivacy.PublicToOrganisation,
				exercise_points: exercise.maxScore,
			};
		case ExerciseType.Prog: {
			let subtype = exercise.files.length > 1 ? "multi" : "single";

			if (exercise.language === "sql") {
				subtype = exercise.subtype;
			}

			const files = exercise.files.map((f) => {
				let start = null;
				let end = null;

				if (f.readOnlyFromBeginning || f.readOnlyFromEnd) {
					subtype = "partial";

					start = 1;
					end = countLines(f.content);

					if (f.readOnlyFromBeginning) {
						start += f.readOnlyFromBeginning;
					}

					if (f.readOnlyFromEnd) {
						end -= f.readOnlyFromEnd;
					}
				}

				return {
					filename: f.name,
					content: f.content,
					file_group_type: "answer",
					partial_start: start,
					partial_end: end,
				};
			});

			return {
				...mapped,
				exercise_points: exercise.maxScore,
				run_type: subtype,
				answer: files,
				main_file: exercise.mainFile,
				public_exercise: exercise.privacy === ExercisePrivacy.Public,
				public_to_org:
					exercise.privacy === ExercisePrivacy.PublicToOrganisation,
				must: exercise.requiredWords,
				deny: exercise.prohibitedWords,
				run_cmd: exercise.command || null,
				level: mapComparisonStrictness(exercise.comparisonStrictness),
			};
		}
		default:
			return {};
	}
}

function mapToExternalExerciseRequestParams(
	exercise: ExternalExercise,
	courseId: number
) {
	return {
		originId: courseId,
		type: exercise.type,
		subtype: exercise.subtype,
		title: exercise.title,
		question: exercise.question,
		maxScore: exercise.maxScore,
		category: exercise.category,
		difficultyLevel: exercise.difficultyLevel,
		privacyLevel: exercise.privacy,
		solution: exercise.solution,
	};
}

function mapComparisonStrictness(strictness: ComparisonStrictness) {
	switch (strictness) {
		case "strict":
			return "1";
		case "ignore_space":
			return "2";
		case "ignore_case":
			return "3";
		case "ignore_space_and_case":
			return "4";
		default:
			return "";
	}
}

function makeCourseSearchParams(
	criteria: OrganisationCourseSearchCriteria,
	sort: {field: string; descending?: boolean},
	pageSize: number
): URLSearchParams {
	const params = new URLSearchParams();

	params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
	params.append("pageSize", pageSize.toString());

	if (criteria.type) {
		params.append("type", criteria.type);
	}
	if (criteria.language) {
		params.append("language", criteria.language);
	}
	if (criteria.query) {
		params.append("query", criteria.query);
	}

	if (criteria.startDateAfter) {
		params.append("startsAfter", criteria.startDateAfter);
	}
	if (criteria.startDateBefore) {
		params.append("startsBefore", criteria.startDateBefore);
	}
	if (criteria.endDateAfter) {
		params.append("endsAfter", criteria.endDateAfter);
	}
	if (criteria.endDateBefore) {
		params.append("endsBefore", criteria.endDateBefore);
	}

	criteria.ids?.forEach((id) => {
		params.append("id", id.toString());
	});

	return params;
}
