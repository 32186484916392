import {Localized} from "@fluent/react";
import React from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import enrolmentService from "../../store/services/enrolmentService";

function useUnsuspendStudentsAction(
	courseId: number,
	openConfirmationDialog: OpenConfirmationDialog,
	onSuccess: () => void,
	onError: () => void
) {
	async function unsuspend(studentIds: number[]) {
		try {
			await enrolmentService.unsuspendStudents(courseId, studentIds);
			onSuccess();
		} catch {
			onError();
		}
	}

	function confirmSuspend(studentIds: number[]) {
		openConfirmationDialog({
			confirmBtnText: (
				<Localized id="student-management-unsuspend-confirm-dialog-action">
					Unsuspend
				</Localized>
			),
			title: (
				<Localized
					id="student-management-unsuspend-confirm-dialog-title"
					vars={{number: studentIds.length}}
				>
					Unsuspend students?
				</Localized>
			),
			description: (
				<Localized
					id="student-management-unsuspend-confirm-dialog-descr"
					vars={{number: studentIds.length}}
				>
					You are going to unsuspend students
				</Localized>
			),
			onConfirm: () => unsuspend(studentIds),
		});
	}

	return confirmSuspend;
}

export default useUnsuspendStudentsAction;
