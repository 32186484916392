import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";
import type CoursePatch from "./CoursePatch";

const updateCourseSettings = createAsyncThunk(
	"courses/updateCourseSettings",
	async function (params: {courseId: number; request: CoursePatch}) {
		await courseService.updateCourseSettings(params.courseId, params.request);

		return {
			courseKey: keyProvider.course(params.courseId),
			settings: params.request,
		};
	}
);

export default updateCourseSettings;
