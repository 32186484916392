// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rich-text-editor-tools {
	z-index: 10000;
}

.rich-text-editor-help-button {
	display: none;
}

@-moz-document url-prefix() {
	.tox .tox-editor-container .tox-editor-header[style*="position: fixed"] {
		position: relative !important;
	}
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;AACd;;AAEA;CACC;EACC,6BAA6B;CAC9B;AACD","sourcesContent":[".rich-text-editor-tools {\n\tz-index: 10000;\n}\n\n.rich-text-editor-help-button {\n\tdisplay: none;\n}\n\n@-moz-document url-prefix() {\n\t.tox .tox-editor-container .tox-editor-header[style*=\"position: fixed\"] {\n\t\tposition: relative !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
