import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import exerciseService from "../services/exerciseService";

const fetchExerciseAnswer = createAsyncThunk(
	"exercises/fetchExerciseAnswer",
	async function (params: {
		courseId: number;
		chapterId: number;
		exerciseId: number;
	}) {
		const {courseId, chapterId, exerciseId} = params;
		const answer = await exerciseService.getExerciseAnswer(
			courseId,
			chapterId,
			exerciseId
		);

		return {
			key: keyProvider.exercise(exerciseId),
			answer: answer,
		};
	}
);

export default fetchExerciseAnswer;
