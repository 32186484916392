import {createAsyncThunk} from "@reduxjs/toolkit";

import {adminService} from "../services/adminService";

const archiveCourses = createAsyncThunk(
	"courses/archiveCourses",
	async (params: {
		organisationName: string;
		schedule: {courseId: number; archivalDate?: string}[];
	}) => {
		await adminService.archiveCourses(params.organisationName, params.schedule);

		return {courseIds: params.schedule.map((s) => s.courseId)};
	}
);

export default archiveCourses;
