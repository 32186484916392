export type SupportedLanguages =
	| "c"
	| "cpp"
	| "cs"
	| "dart"
	| "html"
	| "java"
	| "js"
	| "json"
	| "kt"
	| "php"
	| "py"
	| "rb"
	| "rust"
	| "scala"
	| "sql";

const extensionMap: {[key: string]: SupportedLanguages} = {
	c: "c",
	cpp: "cpp",
	cs: "cs",
	dart: "dart",
	java: "java",
	js: "js",
	html: "html",
	htm: "html",
	json: "json",
	kt: "kt",
	php: "php",
	py: "py",
	rb: "rb",
	rs: "rust",
	scala: "scala",
	sql: "sql",
};

export function getLanguage(
	fileExtension: string
): SupportedLanguages | undefined {
	return extensionMap[fileExtension] ?? undefined;
}
