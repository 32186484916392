import {debounce} from "@mui/material";
import {useEffect, useRef, useState} from "react";

function useStateWithDebounce<T>(
	initialValue: T,
	fn: (set: (prev: T) => T) => void
): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [state, setState] = useState<T>(initialValue);

	const initial = useRef(true);

	useEffect(() => {
		if (initial.current) {
			initial.current = false;
			return () => {};
		}

		const debounced = debounce(fn, 500);

		debounced((prev) => ({...prev, ...state}));

		return () => {
			debounced.clear();
		};
	}, [fn, state]);

	return [state, setState];
}

export default useStateWithDebounce;
