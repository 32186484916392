import type {
	IContentMetadata,
	IEditorModel,
	IPlayerModel,
} from "@lumieducation/h5p-server";

import {axiosInstance as client} from "./axiosInstance";

const baseUrl = "/api/h5p/content";

const h5pContentService = {
	getEditorData: async (contentId: string): Promise<IEditorModel> => {
		const {data} = await client.get(`${baseUrl}/${contentId}/editor-data`);

		return data;
	},

	getPlayerData: async (contentId: string): Promise<IPlayerModel> => {
		const {data} = await client.get(`${baseUrl}/${contentId}/player-data`);

		return data;
	},

	save: async (
		exerciseId: number,
		contentId: string,
		content: Record<string, unknown>
	): Promise<{contentId: string; metadata: IContentMetadata}> => {
		const url = contentId
			? `${baseUrl}/${contentId}`
			: `/api/exercises/${exerciseId}/h5p-content`;

		const {data} = await client.put(url, content);

		return data;
	},
};

export default h5pContentService;
