import {createAsyncThunk} from "@reduxjs/toolkit";

import {adminService} from "../services/adminService";
import type {CourseAssociated} from "../services/adminService";

const deleteCourses = createAsyncThunk(
	"courses/deleteCourses",
	async (params: {
		organisationName: string;
		ids: number[];
		options?: {deleteAssociated?: CourseAssociated[]};
	}) => {
		await adminService.deleteCourses(
			params.organisationName,
			params.ids,
			params.options
		);

		return {ids: params.ids};
	}
);

export default deleteCourses;
