import {createSelector} from "@reduxjs/toolkit";
import selectChapterSessionExercise from "../chapterSessions/selectChapterSessionExercise";
import selectChapterSessionSection from "../chapterSessions/selectChapterSessionSection";
import Exercise, {ExerciseWithContent} from "../exercises/Exercise";
import type {RootState} from "../store";
import type SectionEntity from "../sections/Section";
import ChapterExercise from "../chapterExercises/ChapterExercise";

export type Section = {
	id: number;
	number: number;
	title: string;
	selectionSize: number;
	maxScorePerExercise: number;
	chapterId: number;
	authorId?: number;
	originId?: number;
	content: string;
};

function selectSection(state: RootState, key: string) {
	const section = state.sections.byKey[key];

	return section ? section.section : null;
}

function selectChapterExercise(state: RootState, key: string) {
	const exerciseKey = state.sections.byKey[key]?.section?.exercise;

	return exerciseKey ? state.chapterExercises.byKey[exerciseKey] : null;
}

function selectExercise(
	state: RootState,
	key: string
): Exercise | ExerciseWithContent | null {
	const exerciseKey = selectChapterExercise(state, key)?.exercise;

	return exerciseKey ? state.exercises.byKey[exerciseKey] : null;
}

function makeSelectSection(): (
	state: RootState,
	key: string
) => Section | null {
	return createSelector(
		[selectSection, selectChapterExercise, selectExercise],
		prepareSelectedSection
	);
}

function selectSessionChapterExercise(state: RootState, key: string) {
	const exerciseKey = selectChapterSessionSection(state, key)?.exercise;

	return exerciseKey ? selectChapterSessionExercise(state, exerciseKey) : null;
}

function selectSessionExercise(
	state: RootState,
	key: string
): Exercise | ExerciseWithContent | null {
	const exerciseKey = selectSessionChapterExercise(state, key)?.exercise;

	return exerciseKey ? state.exercises.byKey[exerciseKey] : null;
}

function makeSelectChapterSessionSection(): (
	state: RootState,
	key: string
) => Section | null {
	return createSelector(
		[
			selectChapterSessionSection,
			selectSessionChapterExercise,
			selectSessionExercise,
		],
		prepareSelectedSection
	);
}

function prepareSelectedSection(
	section?: Readonly<SectionEntity> | null,
	chapterExercise?: Readonly<ChapterExercise> | null,
	exercise?: Exercise | ExerciseWithContent | null
): Section | null {
	if (!section || !chapterExercise || !exercise) {
		return null;
	}

	let content = "";
	if ("question" in exercise) {
		content = exercise.question;
	}

	return {
		id: exercise.id,
		number: chapterExercise.number,
		title: exercise.title,
		selectionSize: section.selectionSize,
		maxScorePerExercise: section.maxScorePerExercise,
		authorId: exercise.authorId,
		originId: exercise.originId,
		chapterId: chapterExercise.chapterId,
		content: content,
	};
}

export default makeSelectSection;
export {
	makeSelectChapterSessionSection,
	makeSelectSection,
	prepareSelectedSection,
};
