import {createSelector} from "@reduxjs/toolkit";

import type ChapterStructure from "./ChapterStructure";
import selectChapterSectionKeys from "./selectChapterSectionKeys";
import type {RootState} from "../store";

const empty: string[] = [];

function makeSelectChapterStructure(): (
	state: RootState,
	key: string
) => ChapterStructure {
	return createSelector(
		[selectChapterSectionKeys, (state: RootState) => state.sections.byKey],
		(sectionKeys, sections) =>
			sectionKeys.map((key) => ({
				sectionKey: key,
				subsectionKeys: sections[key]?.section?.subsections ?? empty,
			}))
	);
}

export default makeSelectChapterStructure;
