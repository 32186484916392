import {Localized, useLocalization} from "@fluent/react";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVert from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	Menu,
	MenuItem,
	Stack,
	Table,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import staffMemberRolesChanged from "../../store/courses/staffMemberRolesChanged";
import studentStatusChanged from "../../store/enrolment/studentStatusChanged";
import type FetchStatus from "../../store/FetchStatus";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import UserRole from "../../store/models/UserRole";
import {adminService} from "../../store/services/adminService";
import {userService} from "../../store/services/userService";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useAssignToOrganisationCoursesAction from "./useAssignToOrganisationCoursesAction";
import useEnrolInOrganisationCourseAction from "./useEnrolInOrganisationCourseAction";
import UserCourses from "./UserCourses";
import UserRoleChips from "./UserRoleChips";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import ListDivider from "../../utils/ListDivider";

function OrganisationUserRoles(props: {
	organisationName: string;
	userId: number;
}) {
	const {organisationName, userId} = props;

	const navBarHeight = useNavBarHeight();
	const mobileMode = useMobileMode("sm");

	const [roles, setRoles] = useState<UserRole[]>([]);
	const [rolesFetchStatus, setRolesFetchStatus] = useState<FetchStatus>("none");

	const currentUserId = useAppSelector(selectUserId);

	const showSnackbar = useSnackbar();
	const dispath = useAppDispatch();
	const {l10n} = useLocalization();

	async function fetchRoles(userId: number, organisationName: string) {
		setRolesFetchStatus("pending");

		try {
			const roles = await userService.getUserRolesInOrganisation(
				userId,
				organisationName
			);

			setRolesFetchStatus("succeeded");
			setRoles(roles);
		} catch {
			setRolesFetchStatus("failed");
		}
	}

	const userCourses = useRef<{reload: () => void}>(null);

	const [
		assignToCourses,
		assignToCoursesDialog,
	] = useAssignToOrganisationCoursesAction(
		organisationName,
		mobileMode,
		(courseIds) => {
			fetchRoles(userId, organisationName);
			userCourses.current?.reload();

			if (userId === currentUserId) {
				dispath(staffMemberRolesChanged({courseIds}));
			}
		}
	);

	const [
		enrolInCourse,
		enrolInCourseDialog,
	] = useEnrolInOrganisationCourseAction(
		organisationName,
		mobileMode,
		(courseId) => {
			fetchRoles(userId, organisationName);
			userCourses.current?.reload();

			if (userId === currentUserId) {
				dispath(studentStatusChanged({courseId}));
			}
		}
	);

	useEffect(() => {
		if (rolesFetchStatus === "none") {
			fetchRoles(userId, organisationName);
		}
	}, [organisationName, rolesFetchStatus, userId]);

	async function grantAdminRole() {
		try {
			await adminService.grantAdminRigts(organisationName, [userId]);
			fetchRoles(userId, organisationName);
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	async function revokeAdminRole() {
		try {
			await adminService.revokeAdminRights(organisationName, [userId]);
			fetchRoles(userId, organisationName);
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	const admin = roles.includes(UserRole.Administrator);

	return (
		<Box
			sx={{
				background: (theme) => theme.palette.background.paper,
				height: `calc(100vh - ${navBarHeight + 1}px)`,
				pt: mobileMode ? 3 : 5,
				px: mobileMode ? 3 : 6,
				pb: 1.5,
			}}
		>
			<UserCourses
				ref={userCourses}
				topPanel={
					<RolesSummary
						roles={roles}
						status={rolesFetchStatus}
						renderMenuItems={(closeMenu) => [
							<MenuItem
								key="enrol"
								onClick={() => {
									closeMenu();
									enrolInCourse([userId]);
								}}
							>
								<Localized id="organisation-user-roles-action-enrol">
									Enrol in a course
								</Localized>
							</MenuItem>,
							<MenuItem
								key="assign"
								onClick={() => {
									closeMenu();
									assignToCourses([userId]);
								}}
							>
								<Localized id="organisation-user-roles-action-assign-to-courses">
									Assign to courses
								</Localized>
							</MenuItem>,
							<ListDivider key="divider1" />,
							admin ? (
								<MenuItem
									key="revoke"
									onClick={() => {
										closeMenu();
										revokeAdminRole();
									}}
								>
									<Localized id="organisation-user-roles-action-revoke-admin-role">
										Revoke administrator role
									</Localized>
								</MenuItem>
							) : (
								<MenuItem
									key="grant"
									onClick={() => {
										closeMenu();
										grantAdminRole();
									}}
								>
									<Localized id="organisation-user-roles-action-grant-admin-role">
										Grant administrator role
									</Localized>
								</MenuItem>
							),
						]}
						onReload={() => fetchRoles(userId, organisationName)}
					/>
				}
				organisationName={organisationName}
				userId={userId}
				onRolesUpdated={(courseId) => {
					fetchRoles(userId, organisationName);

					if (currentUserId === userId) {
						dispath(staffMemberRolesChanged({courseIds: [courseId]}));
					}
				}}
			/>

			{assignToCoursesDialog}
			{enrolInCourseDialog}
		</Box>
	);
}

function RolesSummary(props: {
	roles: UserRole[];
	status: FetchStatus;
	renderMenuItems: (closeMenu: () => void) => React.ReactNode[];
	onReload: () => void;
}) {
	const [addRoleMenu, setAddRoleMenu] = useState<HTMLButtonElement | null>(
		null
	);

	function closeMenu() {
		setAddRoleMenu(null);
	}

	return (
		<Table stickyHeader sx={{mb: 1}}>
			<TableHead>
				<TableRow sx={{"& > th": {borderBottom: 0}}}>
					<TableCell>
						{props.status !== "failed" && (
							<UserRoleChips
								roles={props.roles}
								loading={props.status === "pending"}
							/>
						)}
						{props.status === "failed" && (
							<Stack
								spacing={1}
								direction="row"
								sx={{height: (theme) => theme.spacing(3), alignItems: "center"}}
							>
								<ErrorIcon color="error" fontSize="small" />
								<Localized id="organisation-user-roles-loading-error-desc">
									Failed to load user roles
								</Localized>
								<Button color="primary" size="small" onClick={props.onReload}>
									<Localized id="organisation-user-roles-loading-error-action-reload">
										Reload
									</Localized>
								</Button>
							</Stack>
						)}
					</TableCell>
					<TableCell padding="checkbox">
						<IconButtonWithTooltip
							tooltipTitle={
								<Localized id="organisation-user-roles-actions-label">
									Actions
								</Localized>
							}
							onClick={({currentTarget}) => {
								setAddRoleMenu(currentTarget);
							}}
						>
							<MoreVert />
						</IconButtonWithTooltip>
					</TableCell>
				</TableRow>
			</TableHead>

			<Menu
				anchorEl={addRoleMenu}
				open={Boolean(addRoleMenu)}
				onClose={closeMenu}
			>
				{props.renderMenuItems(closeMenu)}
			</Menu>
		</Table>
	);
}

export default OrganisationUserRoles;
