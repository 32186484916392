import {createAsyncThunk} from "@reduxjs/toolkit";

import {mapToExerciseWithContent} from "../../services/dtos/EditableExercise";
import type {ProgExercise} from "../../services/dtos/EditableExercise";
import type ProgExerciseBuildResult from "../../services/dtos/ProgExerciseBuildResult";
import exerciseService from "../../services/exerciseService";
import {progExerciseService} from "../../services/progExerciseService";
import type {RootState} from "../../store";
import ExerciseType from "../ExerciseType";
import type {ExerciseWithContent} from "../Exercise";

const buildProgExercise = createAsyncThunk<
	{
		exerciseId: number;
		exercise: ExerciseWithContent;
		buildResult: ProgExerciseBuildResult;
	},
	{courseId: number; exercise: ProgExercise; exerciseId: number},
	{state: RootState}
>(
	"exercise/prog/buildExercise",
	async function ({courseId, exercise, exerciseId}) {
		const buildResult = await progExerciseService.build(
			courseId,
			exerciseId,
			exercise
		);

		await exerciseService.updateExerciseTags(exerciseId, exercise.tags);

		const exerciseWithContent = mapToExerciseWithContent(exerciseId, exercise);

		if (
			exerciseWithContent.type === ExerciseType.Prog &&
			buildResult.db &&
			buildResult.buildStatus === "success"
		) {
			exerciseWithContent.interactions.initialSchemaAndState =
				buildResult.db.initialSchemaAndState;
		}

		return {exerciseId, exercise: exerciseWithContent, buildResult};
	}
);

export default buildProgExercise;
