import {RootState} from "../store";
import type ChapterSession from "./ChapterSession";

function selectChapterSession(
	state: RootState,
	sessionKey: string
): Readonly<ChapterSession> | null {
	return state.chapterSessions.sessions.byKey[sessionKey] ?? null;
}

export default selectChapterSession;
