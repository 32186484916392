import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectCourseProgressFetchStatus(
	state: RootState,
	courseKey: string
): FetchStatus {
	return state.courseProgress.courseKey === courseKey
		? state.courseProgress.lastRequestStatus
		: "none";
}

export default selectCourseProgressFetchStatus;
