import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Box, Paper, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
	},
	resultSuccess: {
		backgroundColor: theme.palette.success.main,
		color: "white",
	},
	resultError: {
		backgroundColor: theme.palette.error.main,
		color: "white",
	},
	resultIcon: {
		marginRight: theme.spacing(1.5),
	},
}));

const SubmitResultCard = (props: {
	success: boolean;
	text: string;
	className?: string;
}): JSX.Element => {
	const classes = useStyles();

	const root = clsx(classes.paper, props.className, {
		[classes.resultSuccess]: props.success,
		[classes.resultError]: !props.success,
	});

	return (
		<Paper className={root} elevation={3}>
			<Box display="flex">
				{props.success ? (
					<CheckCircleIcon className={classes.resultIcon} />
				) : (
					<ErrorOutlineIcon className={classes.resultIcon} />
				)}
				<Typography variant="body1">{props.text}</Typography>
			</Box>
		</Paper>
	);
};

export default SubmitResultCard;
