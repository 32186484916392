import {Localized} from "@fluent/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	Grid,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
} from "@mui/material";
import React, {useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";

import {resolvePath} from "../../helpers/pathHelpers";
import publishAllChapters from "../../store/chapters/publishAllChapters";
import unpublishAllChapters from "../../store/chapters/unpublishAllChapters";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import {keyProvider} from "../../store/keyProvider";
import selectChapterEditorOpened from "../../store/ui/selectChapterEditorOpened";

function ButtonsChaptersManage({
	courseId,
	courseEditing,
	contentEditing,
}: {
	courseId: number;
	courseEditing: boolean;
	contentEditing: boolean;
}): JSX.Element {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const {url: basePath} = useRouteMatch();

	const [menuAnchor, setMenuAnchor] = useState<null | Element>(null);

	const courseKey = keyProvider.course(courseId);

	const chapterKeys = useAppSelector((state) =>
		selectCourseChapterKeys(state, courseKey)
	);

	const editorOpen = useAppSelector(selectChapterEditorOpened);

	const closeMenu = () => {
		setMenuAnchor(null);
	};

	const publishAll = () => {
		dispatch(publishAllChapters({courseId, chapterKeys}));

		closeMenu();
	};

	const unpublishAll = () => {
		dispatch(unpublishAllChapters({courseId, chapterKeys}));

		closeMenu();
	};

	const addChapter = () => {
		history.push(resolvePath(basePath, "/chapters/new"));
	};

	const publicationDisabled = editorOpen || courseEditing || contentEditing;

	return (
		<Box pb={0.5} pt={1} pl={2}>
			<Grid
				container
				wrap="nowrap"
				justifyContent="space-between"
				alignItems="center"
				alignContent="center"
			>
				<Grid item>
					<Box py={1}>
						<Button
							variant="contained"
							color="primary"
							onClick={addChapter}
							disabled={editorOpen || courseEditing}
							style={{
								whiteSpace: "nowrap",
							}}
						>
							<Localized id="learning-material-button-add-chapter">
								Add chapter
							</Localized>
						</Button>
					</Box>
				</Grid>
				<Grid item>
					<Hidden xlDown>
						<Box display="inline-flex" py={1} pr={2}>
							<Button
								color="primary"
								onClick={publishAll}
								disabled={publicationDisabled}
								style={{
									whiteSpace: "nowrap",
								}}
							>
								<Localized id="learning-material-button-publish-all">
									Publish all
								</Localized>
							</Button>
							<Button
								color="primary"
								onClick={unpublishAll}
								disabled={publicationDisabled}
								style={{
									whiteSpace: "nowrap",
								}}
							>
								<Localized id="learning-material-button-unpublish-all">
									Unpublish all
								</Localized>
							</Button>
						</Box>
					</Hidden>
					<Hidden xlUp>
						<Tooltip
							title={
								<Localized id="dragdrop-actions-button-tooltip">
									Actions
								</Localized>
							}
							enterDelay={500}
							enterNextDelay={500}
						>
							<IconButton
								onClick={(event) => setMenuAnchor(event.currentTarget)}
								color="primary"
							>
								<MoreVertIcon />
							</IconButton>
						</Tooltip>
						<Menu
							anchorEl={menuAnchor}
							open={Boolean(menuAnchor)}
							onClose={closeMenu}
						>
							<MenuItem onClick={publishAll} disabled={publicationDisabled}>
								<Localized id="learning-material-button-publish-all">
									Publish all
								</Localized>
							</MenuItem>
							<MenuItem onClick={unpublishAll} disabled={publicationDisabled}>
								<Localized id="learning-material-button-unpublish-all">
									Unpublish all
								</Localized>
							</MenuItem>
						</Menu>
					</Hidden>
				</Grid>
			</Grid>
		</Box>
	);
}

export default ButtonsChaptersManage;
