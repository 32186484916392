import {createSlice} from "@reduxjs/toolkit";

import deleteCourses from "../courses/deleteCourses";
import {fetchCourseStats} from "./fetchCourseStats";
import type CourseStats from "./CourseStats";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	stats?: CourseStats;
	status: FetchStatus;
};

type SliceState = {
	byKey: {
		[key: string]: State | undefined;
	};
};

const initialState: SliceState = {
	byKey: {},
};

export const courseStatisticsSlice = createSlice({
	name: "courseStatistics",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCourseStats.fulfilled, (state, {payload}) => {
			const s = obtainState(state, payload.key);
			s.stats = payload.stats;
			s.status = "succeeded";
		});
		builder.addCase(fetchCourseStats.pending, (state, action) => {
			const key = keyProvider.course(action.meta.arg.courseId);
			const s = obtainState(state, key);
			s.status = "pending";
		});
		builder.addCase(fetchCourseStats.rejected, (state, action) => {
			const key = keyProvider.course(action.meta.arg.courseId);
			const s = obtainState(state, key);
			s.status = inferFetchStatusFromError(action);
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				delete state.byKey[keyProvider.course(id)];
			});
		});
	},
});

function obtainState(state: SliceState, key: string) {
	let s = state.byKey[key];
	if (!s) {
		s = {status: "none"};
		state.byKey[key] = s;
	}

	return s;
}

export default courseStatisticsSlice.reducer;
