import {
	AppBar,
	Box,
	ButtonBase,
	Fade,
	Paper,
	Popover,
	Toolbar,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import NavProfile from "./NavProfile";
import {useAppSelector} from "../../store/hooks";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			boxShadow: "none",
			justifyContent: "center",
			zIndex: theme.zIndex.drawer + 2,
		},
		logo: {
			maxHeight: theme.mixins.toolbar.minHeight,
			verticalAlign: "middle",
		},
		profile: {
			minWidth: "280px",
		},
	})
);

function Nav(props: {
	logo: string;
	children?: React.ReactNode;
	quickLinks?: React.ReactNode;
}): JSX.Element {
	const classes = useStyles();

	const [profileAnchor, setProfileAnchor] = useState<Element | null>(null);

	const openProfile = (event: React.MouseEvent) => {
		setProfileAnchor(event.currentTarget);
	};
	const closeProfile = () => {
		setProfileAnchor(null);
	};

	const userProfile = useAppSelector(selectUserProfile);
	const profileName =
		userProfile.firstName && userProfile.lastName
			? `${userProfile.firstName} ${userProfile.lastName}`
			: "";
	const profileUserName = userProfile.userName;

	const profileOpen = Boolean(profileAnchor);

	return (
		<>
			<AppBar className={classes.root}>
				<Toolbar>
					<Link to="/">
						<img src={props.logo} className={classes.logo} alt="Logo" />
					</Link>

					<Box ml={3} flexGrow={1}>
						{props.children}
					</Box>

					{props.quickLinks && (
						<Fade in={!profileOpen}>
							<Box mr={2.5}>{props.quickLinks}</Box>
						</Fade>
					)}

					<Fade in={!profileOpen}>
						<ButtonBase onClick={openProfile} style={{borderRadius: "50%"}}>
							<UserAvatar user={userProfile} size="small" />
						</ButtonBase>
					</Fade>
				</Toolbar>
			</AppBar>

			<Toolbar />

			<Popover
				open={profileOpen}
				anchorEl={profileAnchor}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				onClose={closeProfile}
			>
				<Paper elevation={3} className={classes.profile}>
					<NavProfile
						userId={userProfile.id}
						profileName={profileName}
						profileUserName={profileUserName}
						avatar={<UserAvatar user={userProfile} />}
						onClick={closeProfile}
					/>
				</Paper>
			</Popover>
		</>
	);
}

export default Nav;
