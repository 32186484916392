import {createSlice} from "@reduxjs/toolkit";

import sessionStore from "./sessionStore";
import refreshUserSession from "./refreshUserSession";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import logoutUser from "./logoutUser";
import loginUser from "./loginUser";
import parseDate from "../../helpers/parseDate";

type State = {
	status: FetchStatus;
	loggedIn: boolean;
};

const userSessionSlice = createSlice({
	name: "userSession",
	initialState: () => {
		const session = sessionStore.getSession();
		const state: State = {
			status: "none",
			loggedIn: Boolean(session && parseDate(session.endTime) > new Date()),
		};

		return state;
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(refreshUserSession.fulfilled, (state, {payload}) => {
			if (payload !== null) {
				state.status = "succeeded";
				state.loggedIn = true;
			} else {
				state.loggedIn = false;
			}
		});
		builder.addCase(refreshUserSession.pending, (state) => {
			state.status = "pending";
		});
		builder.addCase(refreshUserSession.rejected, (state, action) => {
			state.status = inferFetchStatusFromError(action);
			state.loggedIn = false;
		});

		builder.addCase(loginUser.fulfilled, (state) => {
			state.status = "none";
			state.loggedIn = true;
		});

		builder.addCase(logoutUser.pending, (state) => {
			state.status = "succeeded";
			state.loggedIn = false;
		});
	},
});

export default userSessionSlice.reducer;
