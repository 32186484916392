import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {tutoringService} from "../services/tutoringService";
import type TutorQuestion from "./TutorQuestion";

export const fetchStudentMessages = createAsyncThunk(
	"tutorQuestions/fetchStudentMessages",
	async (params: {studentId: number; courseId: number}) => {
		const response = await tutoringService.getStudentMessages(
			params.studentId,
			params.courseId
		);

		const {entities, keys, answeredKeys: answered} = preparePayload(
			response.answered,
			response.notAnswered
		);

		return {entities, keys, answered, courseId: params.courseId};
	}
);

export const fetchTutorMessages = createAsyncThunk(
	"tutorQuestions/fetchTutorMessages",
	async (courseId: number) => {
		const response = await tutoringService.getTutorMessages(courseId);
		const {entities, keys, answeredKeys: answered} = preparePayload(
			response.answered,
			response.notAnswered
		);

		return {entities, keys, answered, courseId};
	}
);

function preparePayload(
	answered: TutorQuestion[],
	notAnswered: TutorQuestion[]
) {
	const allQuestions = [...answered, ...notAnswered].sort((a, b) =>
		a.sendTime > b.sendTime ? -1 : 1
	);

	const entities: {
		[key: string]: TutorQuestion;
	} = {};
	const keys: string[] = [];

	allQuestions.forEach((question) => {
		const key = keyProvider.tutorQuestion(question.id);
		entities[key] = question;
		keys.push(key);
	});

	const answeredKeys = answered
		.sort((a, b) => ((a.answerTime ?? "") > (b.answerTime ?? "") ? -1 : 1))
		.map((question) => keyProvider.tutorQuestion(question.id));

	return {entities, keys, answeredKeys};
}
