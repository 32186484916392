import {Localized} from "@fluent/react";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import MenuIcon from "@mui/icons-material/Menu";
import {
	Box,
	Container,
	IconButton,
	List,
	Typography,
	Zoom,
	useTheme,
} from "@mui/material";
import React from "react";

import MessageListItem from "./MessageListItem";
import TutorQuestion from "../../store/tutorQuestions/TutorQuestion";
import ScrollableContainer from "../../utils/ScrollableContainer";

const MessageList = (props: {
	header: React.ReactNode;
	messageKeys: string[];
	selectedMessage: string | null;
	errorState?: React.ReactNode;
	messageInfoSelector: (
		message: TutorQuestion
	) => {
		avatar: React.ReactElement;
		date?: string;
		name: React.ReactNode;
		answered?: boolean;
	};
	onNavClick?: () => void;
}): JSX.Element => {
	const theme = useTheme();

	return (
		<Box display="flex" flexDirection="column" style={{height: "100%"}}>
			<ScrollableContainer
				header={
					<Box
						display="flex"
						alignItems="center"
						style={{gap: theme.spacing(1)}}
						my={props.onNavClick ? 1 : 2}
						mx={props.onNavClick ? 1.5 : 3}
					>
						<Zoom in={Boolean(props.onNavClick)} unmountOnExit>
							<IconButton onClick={props.onNavClick}>
								<MenuIcon />
							</IconButton>
						</Zoom>
						<Typography component="span" variant="h6" noWrap>
							{props.header}
						</Typography>
						<Typography component="span" variant="h6" color="textSecondary">
							{props.messageKeys.length}
						</Typography>
					</Box>
				}
			>
				{!props.errorState && props.messageKeys.length > 0 && (
					<List>
						{props.messageKeys.map((key: string) => (
							<MessageListItem
								key={key}
								messageKey={key}
								selected={props.selectedMessage === key}
								infoSelector={props.messageInfoSelector}
							/>
						))}
					</List>
				)}

				{!props.errorState && props.messageKeys.length === 0 && <Empty />}

				{props.errorState}
			</ScrollableContainer>
		</Box>
	);
};

function Empty() {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<EventBusyIcon style={{height: "50px", width: "50px"}} color="disabled" />
			<Typography variant="h6">
				<Localized id="tutoring-message-list-empty">No messages</Localized>
			</Typography>
		</Container>
	);
}

export default MessageList;
