import {createAsyncThunk} from "@reduxjs/toolkit";
import ExerciseType from "../exercises/ExerciseType";
import {sectionService} from "../services/sectionService";

export const addExercisesToSection = createAsyncThunk(
	"sections/addExercises",
	async (params: {
		courseId: number;
		chapterId: number;
		sectionId: number;
		exercises: {id: number; type: ExerciseType}[];
	}) => {
		await sectionService.addExercises(
			params.courseId,
			params.chapterId,
			params.sectionId,
			params.exercises
		);
	}
);
