import {useEffect} from "react";

import {getChapters} from "../store/chapters/getChapters";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {keyProvider} from "../store/keyProvider";

const useEnsureChaptersLoaded = (courseId: number): void => {
	const dispatch = useAppDispatch();

	const requested = useAppSelector(
		(state) => keyProvider.course(courseId) in state.courseChapters.byKey
	);

	useEffect(() => {
		if (!requested) {
			dispatch(getChapters(courseId));
		}
	}, [courseId, dispatch, requested]);
};

export default useEnsureChaptersLoaded;
