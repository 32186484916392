import {useLocalization} from "@fluent/react";
import {Radio, RadioGroup} from "@mui/material";
import React, {useEffect, useState} from "react";

import Choice from "./Choice";
import createChoiceFeedback from "./createChoiceFeedback";
import SubmitableResponseArea from "../SubmitableResponseArea";
import {MultiExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import {MultiInteractions} from "../../../../store/exercises/Interactions";
import type {MultiResponse} from "../../../../store/studentResponses/Response";
import {MultiFeedbackSubmitted} from "../../../../store/studentResponses/Feedback";
import type {
	ResponseToSave,
	ResponseToSubmit,
} from "../../../../store/studentResponses/Response";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";
import createSubmitSummary from "../createSubmitSummary";

const SingleResponse = (props: {
	id: string;

	subtype: MultiExerciseSubtype;
	interactions: MultiInteractions;
	response: MultiResponse | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;

	onSave: (response: ResponseToSave) => void;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;
}): JSX.Element => {
	const {l10n} = useLocalization();

	const [response, setResponse] = useState(0);

	const [
		submissionResult,
		setSubmissionResult,
	] = useState<SubmissionResult | null>(null);

	useEffect(() => {
		const savedResponse = props.response
			? props.response.selectedChoices[0]
			: 0;
		setResponse(savedResponse);
		if (savedResponse === 0) {
			setSubmissionResult(null);
		}
	}, [props.response]);

	const changeHandler = (choiceId: number) => {
		setResponse(choiceId);

		props.onSave({
			exerciseType: ExerciseType.Multi,
			exerciseSubtype: MultiExerciseSubtype.Single,
			selectedChoices: [choiceId],
		});
	};

	const onSubmit = props.onSubmit;
	const submitHandler =
		onSubmit &&
		(async () => {
			const result = await onSubmit({
				exerciseType: ExerciseType.Multi,
				exerciseSubtype: MultiExerciseSubtype.Single,
				selectedChoices: [response],
			});

			setSubmissionResult(result);
		});

	const submitSummary =
		submissionResult && createSubmitSummary(l10n, submissionResult);

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || props.submitting;

	return (
		<SubmitableResponseArea
			submitDisabled={response === 0 || submissionDisabled}
			onSubmit={submitHandler}
			submitSummary={submitSummary}
			submitting={props.submitting}
		>
			<RadioGroup value={response}>
				{props.interactions.choices.map((choice) => (
					<Choice
						key={choice.id}
						control={
							<Radio
								inputProps={{"aria-labelledby": `${props.id}-${choice.id}`}}
								color="primary"
								onChange={() => changeHandler && changeHandler(choice.id)}
								value={choice.id}
								disabled={readonly}
							/>
						}
						label={choice.text}
						disabled={readonly}
						onChange={() => changeHandler && changeHandler(choice.id)}
						labelId={`${props.id}-${choice.id}`}
						feedback={
							submissionResult &&
							createChoiceFeedback(
								choice.id,
								submissionResult.feedback as MultiFeedbackSubmitted
							)
						}
					/>
				))}
			</RadioGroup>
		</SubmitableResponseArea>
	);
};

export default SingleResponse;
