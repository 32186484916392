// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/arrow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cm-breakpoint-gutter {
	cursor: pointer;
}

.cm-breakpoint-gutter .cm-gutterElement .readonly-selector::before {
	display: inline-block;
	content: "";
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-repeat: no-repeat;
	background-size: 1em 1em;
	vertical-align: middle;
	width: 1em;
	height: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/utils/CodeEditor/index.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,qBAAqB;CACrB,WAAW;CACX,yDAA+C;CAC/C,4BAA4B;CAC5B,wBAAwB;CACxB,sBAAsB;CACtB,UAAU;CACV,WAAW;AACZ","sourcesContent":[".cm-breakpoint-gutter {\n\tcursor: pointer;\n}\n\n.cm-breakpoint-gutter .cm-gutterElement .readonly-selector::before {\n\tdisplay: inline-block;\n\tcontent: \"\";\n\tbackground-image: url(\"../../images/arrow.svg\");\n\tbackground-repeat: no-repeat;\n\tbackground-size: 1em 1em;\n\tvertical-align: middle;\n\twidth: 1em;\n\theight: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
