import {Localized} from "@fluent/react";
import React from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import enrolmentService from "../../store/services/enrolmentService";

function useAcceptApplications(
	courseId: number,
	openConfirmationDialog: OpenConfirmationDialog,
	onSuccess: (applicantIds: number[]) => void,
	onError: () => void
) {
	async function accept(applicantIds: number[]) {
		try {
			await enrolmentService.recordDecisionOnEnrolmentApplications(
				courseId,
				"accepted",
				applicantIds
			);

			onSuccess(applicantIds);
		} catch {
			onError();
		}
	}

	function confirm(applicantIds: number[], applicantName?: string) {
		openConfirmationDialog({
			title: (
				<Localized
					id="student-management-enrolment-applications-accept-confirmation-dialog-title"
					vars={{number: applicantIds.length}}
				>
					Accept enrolment applications
				</Localized>
			),
			description: (
				<Localized
					id={
						applicantName
							? "student-management-enrolment-applications-accept-confirmation-dialog-desc-with-name"
							: "student-management-enrolment-applications-accept-confirmation-dialog-desc-no-name"
					}
					vars={{number: applicantIds.length, name: applicantName ?? ""}}
				>
					You are going to accept enrolment applications
				</Localized>
			),
			confirmBtnText: (
				<Localized id="student-management-enrolment-applications-accept-confirmation-dialog-action-accept">
					Accept
				</Localized>
			),
			onConfirm: () => accept(applicantIds),
		});
	}

	return confirm;
}

export default useAcceptApplications;
