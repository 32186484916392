import {Box} from "@mui/material";
import React from "react";

import StackedLinearProgress from "./StackedLinearProgress";

const StackedProgressBar = (props: {
	values?: {value: number; color: string}[];
	maxValue?: number;
	leftLabel?: React.ReactNode;
	rightLabel?: React.ReactNode;
}): JSX.Element => {
	const {values = []} = props;

	return (
		<Box display="flex" flexDirection="column">
			<Box width="100%" mb={0.25}>
				<StackedLinearProgress
					values={values.map((v) => ({
						...v,
						value: (v.value * 100) / (props.maxValue ?? 100),
					}))}
				/>
			</Box>
			<Box display="flex" justifyContent="space-between">
				{props.leftLabel}
				{props.rightLabel}
			</Box>
		</Box>
	);
};

export default StackedProgressBar;
