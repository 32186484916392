import {createSlice} from "@reduxjs/toolkit";

import type ExerciseType from "./ExerciseType";
import createExercise from "./createExercise";
import {saveExerciseDraft} from "./saveExerciseDraft";
import {saveTheoryDraft} from "./saveTheoryDraft";

export enum DraftType {
	None,
	Exercise,
	Section,
}

export type DraftContent = {
	id: number;
	content: string;
	title: string;
	exerciseType: ExerciseType;
	sectionId?: number;
	newlyAdded?: boolean;
};

export type ExerciseDraft = {
	type: DraftType;
	draft: DraftContent | null;
};

const initialState: ExerciseDraft = {
	type: DraftType.None,
	draft: null,
};

export const exerciseDraftSlice = createSlice({
	name: "exercisesDraft",
	initialState,
	reducers: {
		exerciseCreationStarted: (state, {payload}) => {
			state.type = DraftType.Exercise;
			state.draft = {
				id: 0,
				content: "",
				title: "",
				exerciseType: payload.exerciseType,
				sectionId: payload.sectionId,
				newlyAdded: true,
			};
		},
		editingStarted: (state, {payload}) => {
			state.type = payload.draftType;
			state.draft = {
				id: payload.id,
				content: payload.content,
				title: payload.title,
				exerciseType: payload.exerciseType,
				sectionId: payload.sectionId,
				newlyAdded: false,
			};
		},
		editingEnded: () => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(saveTheoryDraft.fulfilled, (state, {payload}) => {
			state.draft = payload;
		});

		builder.addCase(saveExerciseDraft.fulfilled, (state, {payload}) => {
			state.draft = payload;
		});

		builder.addCase(createExercise.fulfilled, (state, {payload}) => {
			if (state.draft) {
				state.draft = {...state.draft, id: payload.id};
			}
		});
	},
});

export const {
	editingStarted,
	editingEnded,
	exerciseCreationStarted,
} = exerciseDraftSlice.actions;

export default exerciseDraftSlice.reducer;
