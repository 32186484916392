import type FileUploader from "../../utils/attachments/FileUploader";
import type UploadedFile from "../../utils/attachments/UploadedFile";
import DocumentFormat from "../../utils/attachments/DocumentFormat";
import useFileUploader from "../../utils/attachments/useFileUploader";
import {useCallback} from "react";
import createFileUploader from "../../utils/attachments/createFileUploader";

const useAnswerFileUploader = (
	courseId: number,
	personId: number,
	questionId: number
): [UploadedFile[], FileUploader] => {
	const creator = useCallback(
		() =>
			createFileUploader({
				documentFormat: DocumentFormat.TutorAnswer,
				courseId,
				personId,
				questionId,
			}),
		[courseId, personId, questionId]
	);

	return useFileUploader(creator);
};

export default useAnswerFileUploader;
