import {createSlice} from "@reduxjs/toolkit";

import type ChapterExercise from "./ChapterExercise";
import removeExercise from "./removeExercise";
import deleteSection from "../sections/deleteSection";
import {getChapterContent} from "../chapters/getChapterContent";
import updateChapterStructure from "../chapters/updateChapterStructure";
import {fetchChapterExercise} from "./fetchChapterExercise";
import updateChapterExerciseSettings from "./updateChapterExerciseSettings";
import {keyProvider} from "../keyProvider";
import {patchField} from "../patching";

type ChapterExercises = {
	byKey: {
		[key: string]: ChapterExercise;
	};
};

const initialState: ChapterExercises = {
	byKey: {},
};

export const chapterExercisesSlice = createSlice({
	name: "chapterExercises",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getChapterContent.fulfilled, (state, action) => {
			const chapterExercises = action.payload.entities.chapterExercises;

			Object.keys(chapterExercises).forEach((key) => {
				state.byKey[key] = {...chapterExercises[key]};
			});
		});

		builder.addCase(updateChapterStructure.fulfilled, (state, action) => {
			const structure = action.payload.structure;

			Object.keys(structure.chapterExercises).forEach((key) => {
				const src = state.byKey[key];
				const val = structure.chapterExercises[key];

				state.byKey[key] = {...src, ...val};
			});
		});

		builder.addCase(deleteSection.fulfilled, (state, action) => {
			const keys = action.payload.removedExerciseKeys;

			for (const key of keys) {
				delete state.byKey[key];
			}
		});

		builder.addCase(removeExercise.fulfilled, (state, action) => {
			const key = action.payload.removedExerciseKey;

			delete state.byKey[key];
		});

		builder.addCase(fetchChapterExercise.fulfilled, (state, action) => {
			const {
				chapterExerciseKey,
				chapterExercise,
				chapterId,
				sectionId,
				exerciseKey,
			} = action.payload;

			state.byKey[chapterExerciseKey] = {
				...state.byKey[chapterExerciseKey],
				chapterId,
				exercise: exerciseKey,
				number: chapterExercise.number,
				sectionId,
				maxScore: chapterExercise.maxScore,
			};
		});

		builder.addCase(
			updateChapterExerciseSettings.fulfilled,
			(state, {payload}) => {
				const key = keyProvider.chapterExercise(
					payload.chapterId,
					payload.exerciseId
				);
				const exercise = state.byKey[key];
				if (exercise) {
					patchField(exercise, "maxScore", payload.maxScore);
					patchField(exercise, "answerVisibility", payload.answerVisibility);
				}
			}
		);
	},
});

export default chapterExercisesSlice.reducer;
