import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {Box, IconButton} from "@mui/material";
import React, {useMemo, useState} from "react";

import makeURL from "./makeURL";
import {useAppSelector} from "../../store/hooks";
import type ProctoringSettings from "../../store/proctoring/ProctoringSettings";
import selectProctoringSettings from "../../store/proctoring/selectProctoringSettings";

function ProctoringMonitorPanel(props: {
	studentId: number;
	courseId: number;
	testId: number;
	returnLink: string;
	attemptNumber: number;
	verificationMode?: boolean;
}) {
	const [loaded, setLoaded] = useState(false);
	const [collapsed, setCollapsed] = useState(false);

	const settings = useAppSelector(selectProctoringSettings);

	const monitoringLink = useMonitoringBlockLink(settings, {
		studentId: props.studentId,
		courseId: props.courseId,
		testId: props.testId,
		returnLink: props.returnLink,
		attemptNumber: props.attemptNumber,
		verificationMode: props.verificationMode,
	});

	return (
		<Box sx={{background: "transparent", position: "relative"}}>
			{loaded && (
				<IconButton
					size="small"
					disableRipple
					onClick={() => {
						setCollapsed((prev) => !prev);
					}}
					sx={{color: "white", position: "absolute", p: 0, pl: 1, pt: 0.5}}
				>
					{collapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
				</IconButton>
			)}
			<iframe
				allow="microphone; camera"
				sandbox="allow-top-navigation allow-scripts allow-modals allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"
				src={monitoringLink}
				allowFullScreen
				style={{
					border: "none",
					overflow: "hidden",
					width: "220px",
					height: collapsed ? "114px" : "300px",
				}}
				onLoad={() => {
					setLoaded(true);
				}}
			></iframe>
		</Box>
	);
}

function useMonitoringBlockLink(
	settings: ProctoringSettings,
	params: {
		studentId: number;
		courseId: number;
		testId: number;
		returnLink: string;
		attemptNumber: number;
		verificationMode?: boolean;
	}
) {
	return useMemo(() => {
		const name =
			settings.attemptIdentificationEnabled && params.attemptNumber > 0
				? `${params.testId}_${params.attemptNumber}`
				: `${params.testId}`;

		return makeURL("https://swl.smowltech.net/monitor/controller.php", {
			entity_Name: settings.entityName,
			swlLicenseKey: settings.licenceKey,
			modality_ModalityName: "test",
			course_CourseName: name,
			course_Container: params.courseId.toString(),
			user_idUser: params.studentId.toString(),
			lang: "en",
			type: "0",
			savePhoto: params.verificationMode ? "0" : "1",
			Course_link: params.returnLink,
		});
	}, [
		params.attemptNumber,
		params.courseId,
		params.returnLink,
		params.studentId,
		params.testId,
		params.verificationMode,
		settings.attemptIdentificationEnabled,
		settings.entityName,
		settings.licenceKey,
	]);
}

export default ProctoringMonitorPanel;
