import {Localized} from "@fluent/react";
import MoreVert from "@mui/icons-material/MoreVert";
import {
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import UserRole from "../../store/models/UserRole";

function TeacherCardDashboard(props: {
	course: {
		id: number;
		name: string;
		picture?: string;
		defaultPicture?: string;
		startDate: string;
		endDate: string;
		userRoles: UserRole[];
	};
	organisationName: string;
	userId: number;
	onCopy: (course: {id: number; name: string}) => void;
}): JSX.Element {
	const {course} = props;

	const theme = useTheme();
	const formatDate = useDateFormat();

	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

	const menuItems = [];
	if (course.userRoles.includes(UserRole.Teacher)) {
		menuItems.push(
			<MenuItem
				key="copy"
				onClick={() => {
					props.onCopy({id: course.id, name: course.name});
					setMenuAnchor(null);
				}}
			>
				<Localized id="dashboard-teacher-action-copy-course">Copy</Localized>
			</MenuItem>
		);
	}

	return (
		<>
			<Card>
				<CardActionArea
					component={Link}
					to={`/courses/${course.id}/management`}
				>
					<CardMedia
						component="img"
						alt=""
						height="136"
						image={course.picture || course.defaultPicture}
					/>
					<CardContent>
						<Typography
							variant="subtitle1"
							component="h2"
							style={{fontWeight: 500}}
						>
							{course.name}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions
					disableSpacing
					style={{
						justifyContent: "space-between",
						padding:
							menuItems.length > 0
								? theme.spacing(0.5, 0.5, 0.5, 2)
								: theme.spacing(1, 1, 1, 2),
					}}
				>
					<Typography variant="body2" color="textSecondary">
						{`${formatDate(parseDate(course.startDate))} - ${formatDate(
							parseDate(course.endDate)
						)}`}
					</Typography>
					{menuItems.length > 0 && (
						<IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
							<MoreVert />
						</IconButton>
					)}
				</CardActions>
			</Card>

			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				PaperProps={{style: {minWidth: theme.spacing(14)}}}
				onClose={() => setMenuAnchor(null)}
			>
				{menuItems}
			</Menu>
		</>
	);
}

export default TeacherCardDashboard;
