import type {EditorSelection, Editor as TinyEditor} from "tinymce";

export function createMediaUrlResolver(
	editor: TinyEditor | null
): (
	data: {url: string},
	resolve: (success: {html: string}) => void,
	reject: (error: {msg: string}) => void
) => void {
	return function (data, resolve, reject) {
		let tmpl = "";
		let err;
		if (/^[^?#]+.pdf([?#]|$)/.test(data.url)) {
			const res = handlePdf(data, editor?.selection);
			tmpl = res.tmpl ?? "";
			err = res.err;
		}

		if (err) {
			reject({msg: err});
		} else {
			resolve({html: tmpl});
		}
	};
}

function handlePdf(file: {url: string}, selection?: EditorSelection) {
	let src = getAbsolutePathRelativeUrl(file.url);
	if (!src) {
		return {tmpl: null, err: "Cannot embed PDF from different server."};
	}

	if (src.substring(0, 7) !== "/embed/") {
		src = "/embed" + src;
	}

	let tmpl = [
		'<iframe src="',
		src,
		'" class="pdf-viewer" ',
		'allowfullscreen="allowfullscreen">',
		"</iframe>",
	].join("");

	const parent = selection?.getNode().parentElement;
	if (
		parent?.nodeName !== "SPAN" ||
		!parent?.classList.contains("pdf-viewer-container")
	) {
		tmpl = ['<span class="pdf-viewer-container">', tmpl, "</span>"].join("");
	}

	return {tmpl, err: null};
}

function getAbsolutePathRelativeUrl(url: string) {
	// We treat userinfo subcomponent and schemes other then http or https as errors.
	const re = new RegExp(
		"^((https?:)?//" + window.location.hostname + "(:[0-9]+)?)?/",
		"ig"
	);
	let relative = null;

	if (re.test(url)) {
		relative = url.substring(re.lastIndex - 1);
	}

	return relative;
}
