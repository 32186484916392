import {Avatar} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			color: theme.palette.primary.contrastText,
			background: theme.palette.secondary.main,
		},
		small: {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: "16px",
		},
		medium: {
			width: theme.spacing(6),
			height: theme.spacing(6),
		},
	})
);

type UserInfo = {
	firstName?: string;
	lastName?: string;
	userName: string;
	profilePhotoLink?: string;
};

const UserAvatar = (props: {
	user: UserInfo;
	size?: "small" | "medium";
}): JSX.Element => {
	const classes = useStyles();

	const {user} = props;
	const avatarPhoto = user.profilePhotoLink ?? "";
	const usersInitials =
		user.firstName?.length && user.lastName?.length
			? user.firstName[0] + user.lastName[0]
			: user.userName[0];

	return (
		<Avatar
			alt={usersInitials}
			src={avatarPhoto}
			className={clsx(classes.root, {
				[classes.small]: props.size === "small",
				[classes.medium]: props.size === "medium" || !props.size,
			})}
		>
			{usersInitials}
		</Avatar>
	);
};

export default UserAvatar;
