import {createSlice} from "@reduxjs/toolkit";

import archiveCourses from "./archiveCourses";
import type Course from "./Course";
import fetchCourse from "./fetchCourse";
import updateCourseSettings from "./updateCourseSettings";
import changeCoursePicture from "./changeCoursePicture";
import deleteCoursePicture from "./deleteCoursePicture";
import {patchField} from "../patching";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import restoreCourses from "./restoreCourses";
import deleteCourses from "./deleteCourses";

type CourseState = {
	course?: Course;
	status: FetchStatus;
};

type CoursesState = {
	byKey: {
		[key: string]: CourseState | undefined;
	};
};

const initialState: CoursesState = {
	byKey: {},
};

export const coursesSlice = createSlice({
	name: "courses",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCourse.pending, (state, action) => {
			const s = obtainState(state, keyProvider.course(action.meta.arg));
			s.status = "pending";
		});
		builder.addCase(fetchCourse.rejected, (state, action) => {
			const s = obtainState(state, keyProvider.course(action.meta.arg));
			s.status = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchCourse.fulfilled, (state, action) => {
			const s = obtainState(state, action.payload.key);
			s.course = action.payload.course;
			s.status = "succeeded";
		});

		builder.addCase(updateCourseSettings.fulfilled, (state, {payload}) => {
			const s = state.byKey[payload.courseKey];

			if (s?.course) {
				const course = s.course;
				const settings = payload.settings;

				patchField(course, "answerVisibility", settings.answerVisibility);
				patchField(course, "name", settings.name);
				patchField(course, "startDate", settings.startDate);
				patchField(course, "endDate", settings.endDate);
				patchField(course, "navigationMode", settings.navigationMode);
				patchField(course, "selfEnrolment", settings.selfEnrolment);
				patchField(course, "features", settings.features);
				patchField(course, "workload", settings.workload);
			}
		});

		builder.addCase(changeCoursePicture.fulfilled, (state, action) => {
			const course = state.byKey[action.payload.key]?.course;
			if (course) {
				course.picture = action.payload.picture;
			}
		});

		builder.addCase(deleteCoursePicture.fulfilled, (state, action) => {
			const course = state.byKey[action.payload.key]?.course;
			if (course) {
				delete course.picture;
			}
		});

		builder.addCase(archiveCourses.fulfilled, (state, {payload}) => {
			payload.courseIds.forEach((id) => invalidateCourse(state, id));
		});

		builder.addCase(restoreCourses.fulfilled, (state, {payload}) => {
			payload.courseIds.forEach((id) => invalidateCourse(state, id));
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				const key = keyProvider.course(id);
				delete state.byKey[key];
			});
		});
	},
});

function invalidateCourse(state: CoursesState, courseId: number) {
	const s = state.byKey[keyProvider.course(courseId)];
	if (s) {
		s.status = "none";
	}
}

function obtainState(state: CoursesState, key: string) {
	let s = state.byKey[key];
	if (!s) {
		s = {
			status: "none",
		};
		state.byKey[key] = s;
	}

	return s;
}

export default coursesSlice.reducer;
