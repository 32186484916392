import {useLocalization} from "@fluent/react";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import React from "react";

function IndirectlyEditableTextField(props: {
	id?: string;
	label: React.ReactNode;
	value: string;
	placeholder?: string;
	required?: boolean;
	onChangeClick?: () => void;
}) {
	const {l10n} = useLocalization();

	const title = l10n.getString("indirectly-editable-textfield-button-change");

	return (
		<TextField
			id={props.id}
			required={props.required}
			label={props.label}
			value={props.value}
			placeholder={props.placeholder}
			fullWidth
			InputProps={{
				readOnly: true,
				endAdornment: props.onChangeClick && (
					<InputAdornment position="end">
						<Tooltip title={title} enterDelay={500} enterNextDelay={500}>
							<span>
								<IconButton
									size="small"
									aria-label={title}
									onClick={props.onChangeClick}
								>
									<EditIcon fontSize="inherit" />
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
}

export default IndirectlyEditableTextField;
