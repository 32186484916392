import {Localized} from "@fluent/react";
import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {unwrapResult} from "@reduxjs/toolkit";

import parseDate from "../../helpers/parseDate";
import type Chapter from "../../store/chapters/Chapter";
import {createChapterSession} from "../../store/chapterSessions/createChapterSession";
import fetchChapterSessions from "../../store/chapterSessions/fetchChapterSessions";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useHistory} from "react-router-dom";
import ConfirmChapterSubmissionDialog from "./ConfirmChapterSubmissionDialog";
import type DialogResult from "../../utils/DialogResult";
import useSnackbar from "../../store/ui/useSnackbar";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import selectChapterSessionKeys from "../../store/chapterSessions/selectChapterSessionKeys";
import selectChapterSession from "../../store/chapterSessions/selectChapterSession";
import TestLayout from "./TestLayout";
import selectAllChapterSessionOutcomes from "../../store/chapterSessions/selectAllChapterSessionOutcomes";
import {fetchChapterSessionOutcomes} from "../../store/chapterSessions/fetchChapterSessionOutcomes";
import {CompletionStatus} from "../../store/outcomes/ExerciseOutcomes";
import {fetchChapterSessionContent} from "../../store/chapterSessions/fetchSessionContent";
import {calculateChapterSessionEndTime} from "../../store/chapterSessions/ChapterSession";
import selectChapterSessionContentStatus from "../../store/chapterSessions/selectChapterSessionContentStatus";
import TestProgress from "./TestProgress";
import selectChapterSessionContentStats from "../../store/chapterSessions/selectChapterSessionContentStats";
import SubmitButton from "../../utils/SubmitButton";

const TestIntro = (props: {
	studentId: number;
	chapterKey: string;
	test: Chapter;
}): JSX.Element => {
	const {chapterKey, studentId, test} = props;
	const courseId = test.courseId;

	const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
	const [sessionStarting, setSessionStarting] = useState(false);

	const [sessionKeys, sessionsFetchStatus] = useAppSelector((state) =>
		selectChapterSessionKeys(state, chapterKey)
	);
	const latestSessionKey = sessionKeys.length > 0 ? sessionKeys[0] : null;

	const session = useAppSelector((state) =>
		latestSessionKey ? selectChapterSession(state, latestSessionKey) : null
	);

	const [outcomes, outcomesFetchStatus] = useAppSelector((state) =>
		latestSessionKey
			? selectAllChapterSessionOutcomes(state, latestSessionKey)
			: null
	) ?? [[], "none"];

	const contentFetchStatus = useAppSelector((state) =>
		latestSessionKey
			? selectChapterSessionContentStatus(state, latestSessionKey)
			: "none"
	);

	const [exerciseCount, totalMaxScore] = useAppSelector((state) =>
		latestSessionKey
			? selectChapterSessionContentStats(state, latestSessionKey)
			: null
	) ?? [0, 0];

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (sessionsFetchStatus === "none") {
			dispatch(
				fetchChapterSessions({
					studentId,
					courseId,
					chapterId: test.id,
				})
			);
		}
	}, [dispatch, sessionsFetchStatus, studentId, courseId, test.id]);

	useEffect(() => {
		if (latestSessionKey && contentFetchStatus === "none") {
			dispatch(
				fetchChapterSessionContent({
					chapterId: test.id,
					courseId,
					studentId,
					sessionKey: latestSessionKey,
				})
			);
		}
	}, [
		contentFetchStatus,
		courseId,
		dispatch,
		latestSessionKey,
		studentId,
		test.id,
	]);

	useEffect(() => {
		if (outcomesFetchStatus === "none" && latestSessionKey) {
			dispatch(
				fetchChapterSessionOutcomes({
					chapterId: test.id,
					courseId,
					studentId,
					sessionKey: latestSessionKey,
				})
			);
		}
	}, [
		courseId,
		dispatch,
		latestSessionKey,
		outcomesFetchStatus,
		studentId,
		test.id,
		test.submissionMode,
	]);

	const history = useHistory();

	const showSnackbar = useSnackbar();

	const openChapter = () => {
		history.push(`/courses/${courseId}/chapters/${test.id}/sections`);
	};

	const startSession = async () => {
		setSessionStarting(true);

		const res = await dispatch(
			createChapterSession({studentId, courseId, chapterId: test.id})
		);

		try {
			unwrapResult(res);
			openChapter();
		} finally {
			setSessionStarting(false);
		}
	};

	const closeConfirmSubmission = (result: DialogResult) => {
		setSubmitDialogOpen(false);

		if (result.status !== "cancelled") {
			showSnackbar(result.status, result.message ?? "");
		}
	};

	const createActions = () => {
		const now = new Date();

		if (
			!test.startDate ||
			now < parseDate(test.startDate) ||
			sessionsFetchStatus !== "succeeded"
		) {
			return null;
		}

		if (!session) {
			return (
				<SubmitButton inProgress={sessionStarting} onClick={startSession}>
					<Localized id="course-studying-start-test-button">Start</Localized>
				</SubmitButton>
			);
		}

		const limitNotExceeded =
			!test.sessionLimit || test.sessionLimit > sessionKeys.length;

		const pastTest = test.endDate && parseDate(test.endDate) < now;

		if (pastTest) {
			return (
				<Button variant="contained" color="primary" onClick={openChapter}>
					<Localized id="course-studying-review-test-button">Review</Localized>
				</Button>
			);
		}

		if (session.submissionTime) {
			return (
				<>
					{test.submissionMode === SubmissionMode.Simultaneous &&
						limitNotExceeded && (
							<Button color="primary" onClick={startSession}>
								<Localized id="course-studying-retake-test-button">
									Retake
								</Localized>
							</Button>
						)}
					<Button variant="contained" color="primary" onClick={openChapter}>
						<Localized id="course-studying-review-test-button">
							Review
						</Localized>
					</Button>
				</>
			);
		}

		const timeIsUp =
			test.timeLimit &&
			calculateChapterSessionEndTime(session.startTime, test.timeLimit) <= now;

		return (
			<>
				{test.submissionMode === SubmissionMode.Simultaneous && (
					<Button
						color="primary"
						onClick={() => {
							setSubmitDialogOpen(true);
						}}
					>
						<Localized id="utils-button-submit">Submit</Localized>
					</Button>
				)}
				{test.submissionMode === SubmissionMode.Individual &&
					limitNotExceeded &&
					(timeIsUp ||
						outcomes?.some(
							(o) => o.completionStatus === CompletionStatus.Complete
						)) && (
						<Button color="primary" onClick={startSession}>
							<Localized id="course-studying-retake-test-button">
								Retake
							</Localized>
						</Button>
					)}
				<Button color="primary" variant="contained" onClick={openChapter}>
					{timeIsUp ? (
						<Localized id="course-studying-review-test-button">
							Review
						</Localized>
					) : (
						<Localized id="course-studying-continue-test-button">
							Continue
						</Localized>
					)}
				</Button>
			</>
		);
	};

	return (
		<>
			<TestLayout
				test={test}
				sessionsMade={sessionKeys.length}
				actions={createActions()}
				progress={
					sessionsFetchStatus === "succeeded" && (
						<TestProgress
							session={session}
							test={test}
							outcomes={outcomes}
							exerciseCount={exerciseCount}
							totalMaxScore={totalMaxScore}
						/>
					)
				}
			/>

			<ConfirmChapterSubmissionDialog
				open={submitDialogOpen}
				onClose={closeConfirmSubmission}
				studentId={studentId}
				courseId={courseId}
				chapterId={test.id}
			/>
		</>
	);
};

export default TestIntro;
