import {useLocalization} from "@fluent/react";
import {defaultLocale} from "./locales";

function useCurrentLocale(): string {
	const {l10n} = useLocalization();
	const bundle = Array.from(l10n.bundles)[0];
	return bundle?.locales[0] ?? defaultLocale;
}

export default useCurrentLocale;
