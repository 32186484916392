import type {ReactLocalization} from "@fluent/react";

import {CompletionStatus} from "../../../store/outcomes/ExerciseOutcomes";
import SubmissionResult from "../../../store/studentResponses/SubmissionResult";

function createSubmitSummary(
	l10n: ReactLocalization,
	submissionResult: SubmissionResult
): {
	success: boolean;
	text: string;
} | null {
	if (
		submissionResult.outcomes?.completionStatus !== CompletionStatus.Complete ||
		submissionResult.feedback.status === "unspecified"
	) {
		return null;
	}

	const success = submissionResult.feedback.status === "correct";
	return {
		success: success,
		text: success
			? l10n.getString(
					"exercise-response-area-feedback-correct",
					{score: submissionResult.outcomes?.score ?? 0},
					"Correct. Earned score: {$score}"
			  )
			: l10n.getString(
					"exercise-response-area-feedback-incorrect",
					{score: submissionResult.outcomes?.score ?? 0},
					"Incorrect. Earned score: {$score}"
			  ),
	};
}

export default createSubmitSummary;
