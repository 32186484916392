import ExerciseType from "../exercises/ExerciseType";
import {keyProvider} from "../keyProvider";
import type Response from "./Response";
import type {SavedResponse} from "../services/dtos/SavedResponse";

type NormalisedStudentResponses = {[key: string]: Response};

export function normaliseStudentResponses(
	responses: SavedResponse[],
	chapterId: number
): NormalisedStudentResponses {
	const byKey: {[key: string]: Response} = {};

	if (responses.length === 0) {
		return {};
	}

	for (const r of responses) {
		const key = keyProvider.chapterExercise(chapterId, r.exerciseId);

		let response: Response | null = null;
		switch (r.exerciseType) {
			case ExerciseType.Math:
				response = {
					exerciseType: r.exerciseType,
					exerciseSubtype: r.interactionsType,
					...r.answer,
				};

				break;
			case ExerciseType.Multi:
				response = {
					exerciseType: r.exerciseType,
					exerciseSubtype: r.interactionsType,
					...r.answer,
				};

				break;
			case ExerciseType.Open:
				response = {
					exerciseType: r.exerciseType,
					exerciseSubtype: r.interactionsType,
					...r.answer,
				};

				break;
			case ExerciseType.Prog:
				response = {
					exerciseType: r.exerciseType,
					...r.answer,
				};

				break;
			case ExerciseType.Short:
				response = {
					exerciseType: r.exerciseType,
					...r.answer,
				};
		}

		if (response) {
			byKey[key] = response;
		}
	}

	return byKey;
}

export default NormalisedStudentResponses;
