import {Localized} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import React from "react";

import type WidgetKey from "../../../store/widgets/WidgetKey";

const AddWidgetDialog = <TKey extends WidgetKey>(props: {
	widgetDescriptions: {
		[key in TKey]: {
			icon: React.ReactElement;
			title: React.ReactNode;
			description: React.ReactNode;
		};
	};
	widgetsToAdd: TKey[];
	onSelect: (widgetKey: TKey) => void;
	onClose: () => void;
}): JSX.Element => {
	return (
		<>
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h5" component="span">
						<Localized id="widget-add-widget-dialog-title">
							Add widget
						</Localized>
					</Typography>
					<IconButton onClick={props.onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent>
				<List>
					{props.widgetsToAdd.map((key) => {
						const w = props.widgetDescriptions[key];

						return (
							<ListItem disablePadding key={key}>
								<ListItemButton onClick={() => props.onSelect(key)}>
									<ListItemAvatar>{w.icon}</ListItemAvatar>
									<ListItemText primary={w.title} secondary={w.description} />
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</DialogContent>
		</>
	);
};

export default AddWidgetDialog;
