import {createSelector} from "@reduxjs/toolkit";
import ExerciseType from "../exercises/ExerciseType";
import {keyProvider} from "../keyProvider";
import type {RootState} from "../store";
import selectChapterSessionSection from "./selectChapterSessionSection";

const empty: number[] = [];

function selectSessionSectionSubsectionIds(
	state: RootState,
	sectionKey: string
): number[] {
	return selectChapterSessionSection(state, sectionKey)?.subsectionIds ?? empty;
}

const selectSessionSectionNonTheorySubsectionIds = createSelector(
	[
		selectSessionSectionSubsectionIds,
		(state: RootState) => state.exercises.byKey,
	],
	(subsectionIds, exercises) => {
		const ids = subsectionIds.filter((id) => {
			const subsection = exercises[keyProvider.exercise(id)];
			return subsection && subsection.type !== ExerciseType.Theory;
		});

		return ids.length > 0 ? ids : empty;
	}
);

export {selectSessionSectionNonTheorySubsectionIds};
