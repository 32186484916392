import {Localized} from "@fluent/react";
import {Container, Typography} from "@mui/material";
import React from "react";

import SearchOffIcon from "../../icons/SearchOffIcon";

const StudentSearchEmptyState = (): JSX.Element => {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			}}
		>
			<SearchOffIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="follow-up-student-search-no-results">
					No students have been found
				</Localized>
			</Typography>
		</Container>
	);
};

export default StudentSearchEmptyState;
