import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {
	CourseStatistic,
	statisticsService,
} from "../services/statisticsService";

export const fetchCourseStats = createAsyncThunk(
	"courseStatistics/fetchCourseStats",
	async (params: {courseId: number}) => {
		const stats = await statisticsService.getCourseStatistics(params.courseId, [
			CourseStatistic.MaxScore,
			CourseStatistic.NumberOfExercises,
		]);

		return {
			key: keyProvider.course(params.courseId),
			stats: {
				maxScore: stats.maxScore,
				numberOfExercises: stats.numberOfExercises,
			},
		};
	}
);
