import {Localized} from "@fluent/react";
import {Box, Button, Paper} from "@mui/material";
import React from "react";

type Props = {
	onCancel: () => void;
	onSave: () => void;
	onCompeleted: () => void;
	disabled: boolean;
	additionalActions?: React.ReactNode;
};

const ContentEditorFooter = (props: Props): JSX.Element => {
	return (
		<Box
			position="fixed"
			bottom={0}
			zIndex={1}
			ml={-6}
			width="-webkit-fill-available"
			style={{width: "-moz-available"}}
		>
			<Paper elevation={3}>
				<Box
					height={80}
					mr={6}
					ml={6}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box display="flex">{props.additionalActions}</Box>
					<Box display="flex" justifyContent="flex-end">
						<Box mr={4}>
							<Button color="primary" onClick={props.onCancel}>
								<Localized id="learning-material-content-editor-footer-cancel">
									Cancel
								</Localized>
							</Button>
						</Box>

						<Box mr={4}>
							<Button
								color="primary"
								disabled={props.disabled}
								onClick={props.onSave}
							>
								<Localized id="learning-material-content-editor-footer-save">
									Save
								</Localized>
							</Button>
						</Box>

						<Box>
							<Button
								color="primary"
								disabled={props.disabled}
								onClick={props.onCompeleted}
								variant="contained"
							>
								<Localized id="learning-material-content-editor-footer-done">
									Done
								</Localized>
							</Button>
						</Box>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default ContentEditorFooter;
