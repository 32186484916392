import UserRole from "../models/UserRole";
import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import {createPage} from "../../helpers/paginatedSearchHelpers";
import type {Page} from "../../helpers/paginatedSearchHelpers";
import type UserSearchResult from "./dtos/UserSearchResult";
import type User from "./dtos/User";
import type Organisation from "../organisation/Organisation";
import type OrganisationGroup from "../organisationGroups/UserOrganisationGroup";

const userPath = "/api/users/";

type NewUser = {
	courseId?: number;
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	role: UserRole;
	userName: string;
};

export type UserSearchCriteria = {
	ids?: number[];
	courseIds?: number[];
	roles?: UserRole[];
	lastLoginAfter?: string;
	lastLoginBefore?: string;
	query?: string;
};

export type GroupUserSearchCriteria = {
	ids?: number[];
	organisationNames?: string[];
	roles?: UserRole[];
	lastLoginAfter?: string;
	lastLoginBefore?: string;
	query?: string;
};

export const userService = {
	createNewUser: async (
		organisationName: string,
		user: NewUser
	): Promise<void> => {
		let newUser;
		if (user.role === UserRole.Administrator) {
			newUser = {
				admin: true,
				email_address: user.email,
				first_name: user.firstName,
				last_name: user.lastName,
				org_name: organisationName,
				password: user.password,
				person_id: "new",
				user_name: user.userName,
			};
		} else {
			newUser = {
				courses: [
					{
						course_id: user.courseId,
						student: user.role === UserRole.Student,
						teacher: user.role === UserRole.Teacher,
						tutor: user.role === UserRole.Tutor,
					},
				],
				email_address: user.email,
				first_name: user.firstName,
				last_name: user.lastName,
				org_name: organisationName,
				password: user.password,
				person_id: "new",
				user_name: user.userName,
			};
		}

		const {data} = await client.post(`/api/users`, newUser);

		if (data.error) {
			throw new Error(data.error_text);
		}

		return data;
	},

	createUser: async (
		organisationName: string,
		user: {
			name: string;
			emailAddress: string;
			password: string;
			firstName: string;
			lastName: string;
		}
	): Promise<number> => {
		try {
			const url = `/api/organisations/${organisationName}/users`;

			const {data} = await client.post(url, user);

			return data.id;
		} catch (err) {
			if (
				httpClientError(err) &&
				err.response?.status === 409 &&
				err.response.data.type === "http://viope.com/problem-types/duplicate-id"
			) {
				throw {code: "duplicate_id", field: err.response.data.field};
			}

			throw err;
		}
	},

	getUserOrganisations: async (userId: number): Promise<Organisation[]> => {
		const {data} = await client.get<Organisation[]>(
			`/api/users/${userId}/organisations`
		);

		return data;
	},

	getUserRolesInOrganisation: async (
		userId: number,
		orgName: string
	): Promise<UserRole[]> => {
		const {data} = await client.get<UserRole[]>(
			`${userPath}${userId}/organisations/${orgName}/roles`
		);

		return data;
	},

	leaveOrganisation: async (
		orgName: string,
		userId: number,
		password: string
	): Promise<void> => {
		try {
			await client.delete(`/api/organisations/${orgName}/users`, {
				auth: {username: "", password: password},
				data: [userId],
			});
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			}
			throw err;
		}
	},

	searchUsersInOrganisation: async (
		organisationName: string,
		criteria: UserSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<UserSearchResult>> => {
		const url = `/api/organisations/${organisationName}/users`;

		const params = makeUserSearchParams(criteria);

		criteria.ids?.forEach((id) => {
			params.append("id", id.toString());
		});

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	searchUsersInGroup: async (
		groupName: string,
		criteria: GroupUserSearchCriteria,
		sort: {field: string; descending?: boolean},
		pageSize: number
	): Promise<Page<UserSearchResult>> => {
		const url = `/api/organisation-groups/${groupName}/users`;

		const params = new URLSearchParams();

		criteria.ids?.forEach((id) => {
			params.append("id", id.toString());
		});

		criteria.organisationNames?.forEach((o) => {
			params.append("organisationName", o);
		});

		criteria.roles?.forEach((role) => {
			params.append("role", role === UserRole.Administrator ? "admin" : role);
		});

		if (criteria.query) {
			params.append("query", criteria.query);
		}

		if (criteria.lastLoginAfter) {
			params.append("lastLoginSince", criteria.lastLoginAfter);
		}

		if (criteria.lastLoginBefore) {
			params.append("lastLoginBefore", criteria.lastLoginBefore);
		}

		params.append("sort", sort.descending ? `-${sort.field}` : sort.field);
		params.append("pageSize", pageSize.toString());

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	selectOrganisationUserIds: async (
		organisationName: string,
		criteria: UserSearchCriteria
	): Promise<number[]> => {
		const url = `/api/organisations/${organisationName}/user-ids`;

		const {data} = await client.get(url, {
			params: makeUserSearchParams(criteria),
		});

		return data.content;
	},

	getUserProfile: async (userId: number): Promise<User> => {
		try {
			const {data} = await client.get<User>(`/api/users/${userId}`);
			return data;
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 404) {
				throw {code: "not_found"};
			}
			throw err;
		}
	},

	updateUserProfile: (
		userId: number,
		profile: {
			language: string;
			firstName: string;
			lastName: string;
			phoneNumber?: string;
		}
	): Promise<void> => {
		return client.put(`/api/users/${userId}`, profile);
	},

	patchCredentials: async (
		userId: number,
		patch: {email?: string; password?: string},
		password?: string
	): Promise<void> => {
		try {
			await client.patch(
				`/api/users/${userId}/credentials`,
				patch,
				password ? {auth: {password, username: ""}} : undefined
			);
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			}
			throw err;
		}
	},

	deleteUser: async (
		userId: number,
		username: string,
		password: string
	): Promise<void> => {
		try {
			await client.delete(`/api/users/${userId}`, {
				auth: {password, username: ""},
			});
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			}
			throw err;
		}
	},

	getOrganisationGroups: async (
		userId: number
	): Promise<OrganisationGroup[]> => {
		const url = `/api/users/${userId}/organisation-groups`;

		const {data} = await client.get<{content: OrganisationGroup[]}>(url);

		return data.content.sort((a, b) => {
			if (a.displayName < b.displayName) {
				return -1;
			}
			if (a.displayName > b.displayName) {
				return 1;
			}
			return 0;
		});
	},
};

function makeUserSearchParams(criteria: UserSearchCriteria): URLSearchParams {
	const params = new URLSearchParams();

	criteria.courseIds?.forEach((id) => params.append("courseId", id.toString()));

	criteria.roles?.forEach((role) => {
		params.append("role", role === UserRole.Administrator ? "admin" : role);
	});

	if (criteria.lastLoginAfter) {
		params.append("lastLoginSince", criteria.lastLoginAfter);
	}

	if (criteria.lastLoginBefore) {
		params.append("lastLoginBefore", criteria.lastLoginBefore);
	}

	if (criteria.query) {
		params.append("query", criteria.query);
	}

	return params;
}
