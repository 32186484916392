import detectIndent from "detect-indent";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";

import {
	c,
	cpp,
	csharp,
	dart,
	kotlin,
	scala,
} from "@codemirror/legacy-modes/mode/clike";
import {ruby} from "@codemirror/legacy-modes/mode/ruby";
import {java} from "@codemirror/lang-java";
import {javascript} from "@codemirror/lang-javascript";
import {json} from "@codemirror/lang-json";
import {html} from "@codemirror/lang-html";
import {php} from "@codemirror/lang-php";
import {python} from "@codemirror/lang-python";
import {rust} from "@codemirror/lang-rust";
import {sql} from "@codemirror/lang-sql";
import {indentUnit} from "@codemirror/language";
import {StreamLanguage} from "@codemirror/stream-parser";
import CodeMirror from "@uiw/react-codemirror";
import type {
	Decoration,
	EditorState,
	EditorView,
	Extension,
	Range,
} from "@uiw/react-codemirror";

import editableRangeSelectorExtension from "./editableRangeSelectorExtension";
import highlightLinesExtension, {
	highlightDecoration,
	highlightEffect,
	removeHiglightEffect,
} from "./highlightLinesExtension";
import readOnlyRangesExtension, {
	paintReadOnly,
} from "./readOnlyRangesExtension";
import {SupportedLanguages} from "./SupportedLanguages";

const defaultHeight = "450px";

const languageSupports = {
	c: () => StreamLanguage.define(c),
	cpp: () => StreamLanguage.define(cpp),
	cs: () => StreamLanguage.define(csharp),
	dart: () => StreamLanguage.define(dart),
	html: html,
	java: java,
	js: javascript,
	json: json,
	kt: () => StreamLanguage.define(kotlin),
	php: php,
	py: python,
	rb: () => StreamLanguage.define(ruby),
	rust: rust,
	scala: () => StreamLanguage.define(scala),
	sql: sql,
};

export type EditorCanvasApi = {
	getContent: () => string;
};

const EditorCanvas = (
	props: {
		lang?: SupportedLanguages;
		value: string;
		onChange?: (id: string, val: string) => void;
		readonly?: boolean;
		id: string;
		highlightings?: {
			ranges: {from: number; to?: number}[];
			color: string;
		}[];
		onReadOnlyRangeChange?: (
			fileId: string,
			readOnlyRange: {
				readOnlyFromBeginning: number;
				readOnlyFromEnd: number;
			} | null
		) => void;
		readOnlyFromBeginning?: number;
		readOnlyFromEnd?: number;
	},
	ref: React.ForwardedRef<EditorCanvasApi>
): JSX.Element => {
	const {
		readOnlyFromBeginning,
		readOnlyFromEnd,
		highlightings,
		lang,
		id,
		value,
		onReadOnlyRangeChange,
	} = props;

	const editorState = useRef<EditorState>();

	const editorView = useRef<EditorView>();

	useImperativeHandle(ref, () => ({
		getContent() {
			return editorState.current?.doc.toString() ?? "";
		},
	}));

	const [langSpecificExts, setLangSpecificExts] = useState<Extension[]>([]);
	const [rangeExts, setRangeExts] = useState<Extension[]>([]);

	useEffect(() => {
		const ext = [];
		if (lang) {
			ext.push(languageSupports[lang]());
		}

		let indent = defaultIndent(lang);
		if (value !== "") {
			indent = detectIndent(value).indent || indent;
		}

		ext.push(indentUnit.of(indent));

		setLangSpecificExts(ext);
	}, [lang, value]);

	useEffect(() => {
		const ext = [];

		const getReadOnlyRanges = (state: EditorState) => {
			if (
				(readOnlyFromBeginning || 0) + (readOnlyFromEnd || 0) >=
				state.doc.lines
			) {
				return [
					{
						from: 0,
						to: state.doc.line(state.doc.lines).to,
					},
				];
			}

			const range = [];

			if (readOnlyFromBeginning) {
				range.push({
					from: 0,
					to: state.doc.line(readOnlyFromBeginning).to,
				});
			}

			if (readOnlyFromEnd) {
				range.push({
					from: state.doc.line(state.doc.lines - readOnlyFromEnd + 1).from,
					to: state.doc.line(state.doc.lines).to,
				});
			}

			return range;
		};

		if (onReadOnlyRangeChange) {
			ext.push(
				editableRangeSelectorExtension(
					(range) => {
						onReadOnlyRangeChange(
							id,
							range
								? {
										readOnlyFromBeginning: range.from - 1,
										readOnlyFromEnd:
											(editorState.current?.doc.lines ?? 1) - range.to,
								  }
								: null
						);
					},
					(state) => {
						if (!readOnlyFromBeginning && !readOnlyFromEnd) {
							return null;
						}

						const from = (readOnlyFromBeginning ?? 0) + 1;
						const to = state.doc.lines - (readOnlyFromEnd ?? 0);

						if (from > to) {
							setTimeout(() => onReadOnlyRangeChange(id, null), 10);
							return null;
						}

						return {from, to};
					}
				)
			);

			ext.push(paintReadOnly(getReadOnlyRanges));
		} else if (readOnlyFromBeginning || readOnlyFromEnd) {
			ext.push(readOnlyRangesExtension(getReadOnlyRanges));
		}

		setRangeExts(ext);
	}, [id, onReadOnlyRangeChange, readOnlyFromBeginning, readOnlyFromEnd]);

	useEffect(() => {
		editorView.current?.dispatch({
			effects: removeHiglightEffect.of(() => false),
		});

		if (!highlightings) {
			return;
		}

		highlightings.forEach((h) => {
			const decoration = highlightDecoration(h.color);

			const doc = editorView.current?.state.doc;

			const decors: Range<Decoration>[] = [];

			h.ranges.forEach((r) => {
				for (
					let lineNum = r.from || 1;
					lineNum <= (r.to || doc?.lines || 1) && lineNum <= (doc?.lines || 1);
					lineNum++
				) {
					const lineStart = doc?.line(lineNum).from || 0;

					decors.push(decoration.range(lineStart, lineStart));
				}
			});

			editorView.current?.dispatch({
				effects: highlightEffect.of(decors),
			});
		});
	}, [highlightings]);

	return (
		<CodeMirror
			value={props.value}
			height={defaultHeight}
			extensions={[highlightLinesExtension, ...langSpecificExts, ...rangeExts]}
			onChange={(value, vu) => {
				editorState.current = vu?.state;
				props.onChange && props.onChange(id, value);
			}}
			editable={!props.readonly}
			ref={(editor) => {
				if (!editorState.current) {
					editorState.current = editor?.state;
				}
				if (!editorView.current) {
					editorView.current = editor?.view;
				}
			}}
		/>
	);
};

function defaultIndent(lang?: SupportedLanguages) {
	return lang === "py" ? "    " : "\t";
}

export default React.memo(React.forwardRef(EditorCanvas));
