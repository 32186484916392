import React from "react";
import createIcon from "./utils";

export default createIcon(
	<g>
		<path d="M19,19H5V5h9V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-9h-2V19z" />
		<rect height="4" width="2" x="15" y="13" />
		<rect height="7" width="2" x="7" y="10" />
		<rect height="10" width="2" x="11" y="7" />
		<polygon points="19,5 19,3 17,3 17,5 15,5 15,7 17,7 17,9 19,9 19,7 21,7 21,5" />
	</g>,
	"AddChart"
);
