import type FetchStatus from "../FetchStatus";
import type UserRole from "../models/UserRole";
import type {RootState} from "../store";

function selectUserRolesInOrganisation(state: RootState): UserRole[] {
	return state.organisationUserRoles.roles;
}

function selectUserRolesInOrganisationFetchStatus(
	state: RootState
): FetchStatus {
	return state.organisationUserRoles.status;
}

export default selectUserRolesInOrganisation;
export {selectUserRolesInOrganisationFetchStatus};
