import {contentService} from "../../store/services/contentService";
import type FileUploader from "./FileUploader";
import type FileUploadParams from "./FileUploadParams";
import type UploadedFile from "./UploadedFile";

function createFileUploader(params: FileUploadParams): FileUploader {
	return {
		async loadAll(): Promise<UploadedFile[]> {
			const files = await contentService.getAll(params);
			return files;
		},

		async delete(deleteUrl: string): Promise<void> {
			await contentService.delete(deleteUrl);
		},

		async upload(file: Blob, uploadName?: string): Promise<UploadedFile> {
			const uploaded = await contentService.upload(
				{
					...params,
					file,
				},
				uploadName
			);
			return uploaded;
		},
	};
}

export default createFileUploader;
