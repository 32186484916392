import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {studentService} from "../services/studentService";
import {CompletionStatus} from "./ExerciseOutcomes";

const postOutcomes = createAsyncThunk(
	"outcomes/postOutcomes",
	async (params: {
		studentId: number;
		courseId: number;
		chapterId: number;
		exerciseId: number;
		outcomes: {completionStatus: CompletionStatus; score?: number};
	}) => {
		await studentService.postOutcomes(
			params.studentId,
			params.courseId,
			params.chapterId,
			params.exerciseId,
			params.outcomes
		);

		return {
			key: keyProvider.chapterExercise(params.chapterId, params.exerciseId),
			outcomes: params.outcomes,
		};
	}
);

export default postOutcomes;
