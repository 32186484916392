import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {contentService} from "../services/contentService";

const deleteCoursePicture = createAsyncThunk(
	"courses/deleteCoursePicture",
	async function (params: {courseId: number; pictureUri: string}) {
		const deleteUri =
			"/file/delete" +
			params.pictureUri +
			"/del_course_info_img/" +
			params.courseId;

		await contentService.delete(deleteUri);

		return {
			key: keyProvider.course(params.courseId),
			courseId: params.courseId,
		};
	}
);

export default deleteCoursePicture;
