import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Button,
	Checkbox,
	FormControlLabel,
	Grid2 as Grid,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, {
	createRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import type {RE2JSSyntaxException} from "re2js";

import createSerialIdProvider from "../../helpers/createSerialIdProvider";
import type ShortTextAssessmentRule from "../../store/exercises/ShortTextAssessmentRule";
import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";

type Parser = {
	compile: (regexp: string) => void;
};

const id = createSerialIdProvider();

type RulesEditorApi = {
	validate: (opt?: {allowNoRules?: boolean}) => string | null;
	getRules: () => ShortTextAssessmentRule[];
};

function ShortTextAssessmentRulesEditor(props: {
	rules: ShortTextAssessmentRule[];
	maxScore: number;
	notice: React.ReactNode;
	onDirty: () => void;

	editor: React.RefObject<RulesEditorApi>;
}) {
	const [rules, setRules] = useState<
		(ShortTextAssessmentRule & {id: string})[]
	>(props.rules.map((r) => ({...r, id: id()})));

	const [lastAdded, setLastAdded] = useState("");

	const [ruleEditors, setRuleEditors] = useState<{
		[key: string]: React.RefObject<RuleEditorApi>;
	}>(
		rules.reduce(
			(prev, curr) => ({...prev, [curr.id]: createRef<RuleEditorApi>()}),
			{}
		)
	);

	const [parser, setParser] = useState<Parser | null>(null);

	const {l10n} = useLocalization();

	useEffect(() => {
		async function loadParser() {
			const {RE2JS} = await import("re2js");
			setParser(() => RE2JS);
		}

		loadParser();
	}, []);

	useImperativeHandle(props.editor, () => ({
		validate: validate,
		getRules() {
			const editors = Object.values(ruleEditors);

			const res = new Array<ShortTextAssessmentRule>(editors.length);

			editors.forEach((e) => {
				if (e.current) {
					const {index, ...rule} = e.current.getRule();
					res[index - 1] = rule;
				}
			});

			return res;
		},
	}));

	function validate(opt?: {allowNoRules?: boolean}) {
		const editors = Object.values(ruleEditors);

		if (opt?.allowNoRules && editors.length === 0) {
			return null;
		}

		const allValid = editors.every((r) => r.current?.validate());

		if (!allValid) {
			return l10n.getString(
				"short-text-assessment-rule-editor-error-invalid-rules"
			);
		}

		const correctExists = editors.some(
			(r) => r.current?.getRule().scorePercentage === 100
		);

		if (!correctExists) {
			return l10n.getString(
				"short-text-assessment-rule-editor-error-no-rule-with-full-score"
			);
		}

		return null;
	}

	function addRule() {
		const newId = id();

		setRules((prev) => [
			...prev,
			{
				id: newId,
				caseSensitive: false,
				conditionType: "exact_match",
				expression: "",
				message: "",
				scorePercentage: 100,
			},
		]);

		setLastAdded(newId);

		setRuleEditors((prev) => ({...prev, [newId]: createRef<RuleEditorApi>()}));

		props.onDirty();
	}

	function deleteRule(id: string) {
		return function () {
			setRules((prev) => prev.filter((r) => r.id !== id));
			setRuleEditors((prev) => {
				const p = {...prev};
				delete p[id];
				return p;
			});
			props.onDirty();
		};
	}

	return (
		<Stack spacing={2}>
			<Typography>{props.notice}</Typography>
			<Stack>
				{rules.map((r, i) => (
					<AssesmentRuleRow
						key={r.id}
						editor={ruleEditors[r.id]}
						initiallyExpanded={lastAdded === r.id}
						index={i + 1}
						rule={r}
						maxScore={props.maxScore}
						onDirty={props.onDirty}
						onDelete={deleteRule(r.id)}
						parser={parser}
					/>
				))}
			</Stack>

			<Button
				color="primary"
				startIcon={<AddIcon />}
				onClick={addRule}
				sx={{alignSelf: "flex-end"}}
			>
				<Localized id="short-text-assessment-rule-editor-action-add-rule">
					Add
				</Localized>
			</Button>
		</Stack>
	);
}

type RuleEditorApi = {
	getRule: () => ShortTextAssessmentRule & {index: number};
	validate: () => boolean;
};

function AssesmentRuleRow(props: {
	index: number;
	rule: ShortTextAssessmentRule;
	maxScore: number;
	initiallyExpanded?: boolean;

	parser: Parser | null;

	editor: React.RefObject<RuleEditorApi>;

	onDirty: () => void;
	onDelete: () => void;
}) {
	const {rule, onDirty} = props;

	const [expanded, setExpanded] = useState(props.initiallyExpanded);

	const [conditionType, setConditionType] = useState(rule.conditionType);
	const [caseSensitive, setCaseSensitive] = useState(rule.caseSensitive);
	const [scorePercentage, setScorePercentage] = useState(rule.scorePercentage);

	const [expression, setExpression] = useState(rule.expression);
	const [expressionError, setExpressionError] = useState("");
	const [expressionEmpty, setExpressionEmpty] = useState(false);

	const message = useRef<TextEditorApi>(null);

	const {l10n} = useLocalization();

	function validate() {
		const expressionEmpty = expression === "";
		if (expressionEmpty) {
			setExpressionEmpty(true);
		}

		let expressionError = false;

		if (
			conditionType === "pattern_match" ||
			conditionType === "no_pattern_match"
		) {
			const res = validateRegExp(props.parser, expression);
			if (res !== null) {
				setExpressionError(
					l10n.getString(
						"short-text-assessment-rule-editor-error-rule-pattern",
						{code: res.code},
						res.message
					)
				);
				expressionError = true;
			}
		}

		return !expressionEmpty && !expressionError;
	}

	useImperativeHandle(props.editor, () => ({
		getRule() {
			return {
				caseSensitive: caseSensitive,
				conditionType: conditionType,
				expression: expression,
				message: message.current?.getContent() ?? "",
				scorePercentage: scorePercentage,
				index: props.index,
			};
		},
		validate: validate,
	}));

	const score = Math.trunc((props.maxScore * scorePercentage) / 100);

	return (
		<Accordion
			expanded={expanded}
			onChange={(_, expanded) => setExpanded(expanded)}
			sx={{width: 1}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>
					<Localized
						id="short-text-assessment-rule-editor-rule-preview"
						vars={{
							index: props.index,
							conditionType,
							expression,
							scorePercentage,
						}}
						elems={{
							expr:
								expression !== "" ? (
									<code
										style={{
											borderRadius: "0.25rem",
											fontSize: "0.95rem",
											padding: "0.125rem 0.25rem",
											backgroundColor: "#eeeeee",
										}}
									></code>
								) : (
									<span></span>
								),
						}}
					>
						<>{"Incorrect if matches exactly"}</>
					</Localized>
				</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<Grid container spacing={3}>
					<Grid size={{xs: 12, md: 8}}>
						<TextField
							select
							fullWidth
							label={
								<Localized id="short-text-assessment-rule-editor-label-rule-condition">
									Condition
								</Localized>
							}
							value={conditionType}
						>
							<MenuItem
								value="exact_match"
								onClick={() => {
									setConditionType("exact_match");
									onDirty();
								}}
							>
								<Localized id="short-text-assessment-rule-editor-label-rule-condition-type-exact-match">
									Answer matches exactly
								</Localized>
							</MenuItem>
							<MenuItem
								value="pattern_match"
								onClick={() => {
									setConditionType("pattern_match");
									onDirty();
								}}
							>
								<Localized id="short-text-assessment-rule-editor-label-rule-condition-type-pattern-match">
									Answer matches pattern
								</Localized>
							</MenuItem>
							<MenuItem
								value="no_pattern_match"
								onClick={() => {
									setConditionType("no_pattern_match");
									onDirty();
								}}
							>
								<Localized id="short-text-assessment-rule-editor-label-rule-condition-type-no-pattern-match">
									Answer does not matches pattern
								</Localized>
							</MenuItem>
						</TextField>
					</Grid>
					<Grid size={{xs: 12, md: 4}} sx={{alignSelf: "flex-end"}}>
						<FormControlLabel
							control={
								<Checkbox
									value={caseSensitive}
									onChange={(_, checked) => {
										setCaseSensitive(checked);
										onDirty();
									}}
								/>
							}
							label={
								<Localized id="short-text-assessment-rule-editor-label-rule-case-sensitive">
									Case sensitive
								</Localized>
							}
						/>
					</Grid>
					<Grid size={12}>
						<TextField
							fullWidth
							required
							label={
								conditionType === "exact_match" ? (
									<Localized id="short-text-assessment-rule-editor-label-rule-value">
										Value
									</Localized>
								) : (
									<Localized id="short-text-assessment-rule-editor-label-rule-pattern">
										Pattern
									</Localized>
								)
							}
							value={expression}
							error={Boolean(expressionError) || expressionEmpty}
							helperText={expressionError}
							onChange={({target: {value}}) => {
								setExpression(value);
								setExpressionError("");
								setExpressionEmpty(false);
								onDirty();
							}}
						/>
					</Grid>
					<Grid size={{xs: 12, md: 4}}>
						<TextField
							type="number"
							fullWidth
							label={
								<Localized id="short-text-assessment-rule-editor-label-rule-score-percentage">
									Score percentage
								</Localized>
							}
							value={scorePercentage}
							slotProps={{
								input: {
									endAdornment: (
										<InputAdornment position="end">%</InputAdornment>
									),
								},
								htmlInput: {max: 100, min: 0},
							}}
							onChange={({target: {value}}) => {
								const v = parseInt(value || "0");
								if (0 <= v && v <= 100) {
									setScorePercentage(v);
									onDirty();
								}
							}}
						/>
					</Grid>
					<Grid size={{xs: 12, md: 8}} sx={{alignSelf: "flex-end"}}>
						<Typography>
							<Localized
								id="short-text-assessment-rule-editor-label-rule-default-score"
								vars={{score: score}}
							>
								Default score
							</Localized>
						</Typography>
					</Grid>
					<Grid size={12}>
						<TextEditor
							ref={message}
							mode="inline"
							initialValue={rule.message}
							label={
								<Localized id="short-text-assessment-rule-editor-label-rule-message">
									Message
								</Localized>
							}
							onDirty={onDirty}
						/>
					</Grid>
				</Grid>
			</AccordionDetails>

			<AccordionActions>
				<Button onClick={props.onDelete}>
					<Localized id="short-text-assessment-rule-editor-action-delete-rule">
						Delete
					</Localized>
				</Button>
			</AccordionActions>
		</Accordion>
	);
}

function validateRegExp(parser: Parser | null, expr: string) {
	try {
		parser?.compile(expr);
	} catch (error) {
		if ((error as {name: string}).name === "RE2JSSyntaxException") {
			const err = error as RE2JSSyntaxException;
			return {code: toErrorCode(err.error), message: err.message};
		}
	}

	return null;
}

function toErrorCode(error: string) {
	const errorMapping: {[key: string]: string} = {
		"invalid character class range": "ERR_INVALID_CHAR_RANGE",
		"invalid escape sequence": "ERR_INVALID_ESCAPE",
		"invalid named capture": "ERR_INVALID_NAMED_CAPTURE",
		"invalid or unsupported Perl syntax": "ERR_INVALID_PERL_OP",
		"invalid nested repetition operator": "ERR_INVALID_REPEAT_OP",
		"invalid repeat count": "ERR_INVALID_REPEAT_SIZE",
		"missing closing ]": "ERR_MISSING_BRACKET",
		"missing closing )": "ERR_MISSING_PAREN",
		"missing argument to repetition operator": "ERR_MISSING_REPEAT_ARGUMENT",
		"trailing backslash at end of expression": "ERR_TRAILING_BACKSLASH",
		"duplicate capture group name": "ERR_DUPLICATE_NAMED_CAPTURE",
		"unexpected )": "ERR_UNEXPECTED_PAREN",
		"expression nests too deeply": "ERR_NESTING_DEPTH",
		"expression too large": "ERR_LARGE",
	};

	return errorMapping[error] || error;
}

export default ShortTextAssessmentRulesEditor;
export type {RulesEditorApi};
