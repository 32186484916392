import {Localized} from "@fluent/react";
import {Button, Container, Typography, useTheme} from "@mui/material";
import React from "react";

const OrganisationNotFoundError = (): JSX.Element => {
	const theme = useTheme();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<Typography variant="h6">
				<Localized id="organisation-not-found-error-title">Not found</Localized>
			</Typography>
			<Typography>
				<Localized id="organisation-not-found-error-description">
					{`It seems this organisation doesn't exist. Please check the address line`}
				</Localized>
			</Typography>
			<Button
				href="/"
				color="primary"
				style={{marginTop: theme.spacing(2)}}
				variant="contained"
			>
				<Localized id="organisation-not-found-error-go-to-front-page-btn">
					Go to front page
				</Localized>
			</Button>
		</Container>
	);
};

export default OrganisationNotFoundError;
