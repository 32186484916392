import {createSlice} from "@reduxjs/toolkit";

interface extraMaterialFilesData {
	link?: string;
	userName?: string;
	time: string;
	linkDescription?: string;
	fileSize?: string;
}

interface ExtraMaterialsState {
	filesData: extraMaterialFilesData[];
	linkData: extraMaterialFilesData[];
}

const initialState: ExtraMaterialsState = {
	filesData: [
		{
			link: "File_name.jpg",
			userName: "Mike Smith",
			time: "2021-07-11T23:35:24Z",
			fileSize: "0.32 KB",
		},
		{
			link: "File_name_22.jpg",
			userName: "Mike Smith",
			time: "2021-07-12T07:35:24Z",
			fileSize: "0.32 KB",
		},
		{
			link: "File_name.jpg",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			fileSize: "0.32 KB",
		},
		{
			link: "File_name_22.jpg",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			fileSize: "0.32 KB",
		},
		{
			link: "File_name.jpg",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			fileSize: "0.32 KB",
		},
		{
			link: "File_name_22.jpg",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			fileSize: "0.32 KB",
		},
	],

	linkData: [
		{
			link: "Testlink 1",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			linkDescription:
				"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
		},
		{
			link: "Testlink 1",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			linkDescription: "Lorem ipsum dolor",
		},
		{
			link: "Testlink 1",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			// linkDescription: "",
		},
		{
			link: "Testlink 1",
			userName: "Mike Smith",
			time: "2021-05-11T12:35:24Z",
			linkDescription: "Lorem ipsum dolor",
		},
	],
};

export const extraMaterials = createSlice({
	name: "extraMaterials",
	initialState,
	reducers: {
		extraMaterialsData: (state) => {
			state.filesData;
			state.linkData;
		},
	},
});

export const {extraMaterialsData} = extraMaterials.actions;
export default extraMaterials.reducer;
