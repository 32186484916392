import {createStyles, makeStyles} from "@mui/styles";
import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {makeRichText} from "rich-text-editor";

const useStyles = makeStyles((theme) =>
	createStyles({
		editor: {
			position: "relative",
		},
		content: {
			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: theme.palette.grey["A200"],
			padding: theme.spacing(1),
			margin: "1px",
			boxSizing: "content-box",
			minHeight: "100px",
			color: theme.palette.text.primary,
			fontSize: theme.typography.fontSize,
			fontFamily: theme.typography.fontFamily,
		},
	})
);

export type EditorApi = {
	focus: () => void;
	content: () => string;
};

function AbittiEditor(
	props: {
		initialValue: string;
		onBlur?: () => void;
	},
	ref: React.ForwardedRef<EditorApi>
): JSX.Element {
	const initialValue = props.initialValue;

	const classes = useStyles();

	const field = useRef<HTMLDivElement>(null);
	const value = useRef(initialValue);

	useImperativeHandle(ref, () => ({
		focus: () => {
			field.current?.focus();
		},
		content: () => value.current,
	}));

	useEffect(() => {
		if (field.current) {
			field.current.innerHTML = initialValue;
		}
	}, [initialValue]);

	useEffect(() => {
		if (field.current) {
			makeRichText(
				field.current,
				{
					screenshotSaver: function (): Promise<string> {
						return Promise.reject("Not supported");
					},
				},
				function (data) {
					if (data.answerHTML !== undefined) {
						value.current = removeEmptyImgTags(data.answerHTML);
					}
				}
			);
		}
	}, []);

	const onBlur = props.onBlur
		? createBlurEventHandler(props.onBlur)
		: undefined;

	return (
		<div className={classes.editor}>
			<div className={classes.content} ref={field} onBlur={onBlur}></div>
		</div>
	);
}

function createBlurEventHandler(inner: () => void) {
	return function (event: React.FocusEvent<HTMLDivElement>) {
		const field = event.currentTarget;

		if (!event.relatedTarget) {
			setTimeout(() => {
				if (field !== document.activeElement) {
					inner();
				}
			});
		} else if (
			event.relatedTarget instanceof Node &&
			!field.contains(event.relatedTarget)
		) {
			inner();
		}
	};
}

function removeEmptyImgTags(html: string): string {
	return html.replace(/(<br \/>)?<img src(="\/math\.svg\?latex=")? \/>/gi, "");
}

export default forwardRef(AbittiEditor);
