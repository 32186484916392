import {Alert, Button, Snackbar as MuiSnackbar, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../hooks";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import Notification from "./Notification";
import NotificationAction from "./NotificationAction";
import notificationShown from "./notificationShown";
import selectOldestNotification from "./selectOldestNotification";

const snackbarActions: {
	[key in NotificationAction]: {
		label: string;
		onClick: () => void;
	};
} = {
	reload: {
		label: "Reload",
		onClick: () => {
			location.reload();
		},
	},
};

const Snackbar = (): JSX.Element => {
	const notification = useAppSelector(selectOldestNotification);

	const dispatch = useAppDispatch();

	const [cache, setCache] = useState<Notification>();

	useEffect(() => {
		if (notification) {
			setCache(notification);
		}
	}, [notification]);

	const height = useNavBarHeight();

	const theme = useTheme();

	const close = () => {
		dispatch(notificationShown());
	};

	const open = Boolean(notification);

	const action =
		cache?.options?.action && snackbarActions[cache.options.action];

	return (
		<MuiSnackbar
			anchorOrigin={{vertical: "top", horizontal: "right"}}
			autoHideDuration={6000}
			open={open}
			onClose={close}
			style={{top: height + parseInt(theme.spacing(3))}}
		>
			<Alert
				elevation={6}
				variant="filled"
				severity={cache?.severity}
				action={
					action && (
						<Button onClick={action.onClick} color="inherit">
							{action.label}
						</Button>
					)
				}
				sx={{
					color: cache?.severity === "success" ? "white" : undefined,
				}}
			>
				{cache?.message}
			</Alert>
		</MuiSnackbar>
	);
};

export default Snackbar;
