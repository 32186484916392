import {Localized, useLocalization} from "@fluent/react";
import {Tab} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, Redirect, Route, Switch, useParams} from "react-router-dom";

import CourseFramedPage, {
	TeacherCoursePage,
} from "../../components/CourseFramedPage";
import CourseSettings from "../../components/courseSettings/CourseSettings";
import Exercise from "../../components/exerciseBank/Exercise";
import ExerciseBank from "../../components/exerciseBank/ExericseBank";
import FollowUp from "../../components/followUp/FollowUp";
import LearningMaterial from "../../components/learningMaterial/LearningMaterial";
import BackDestination from "../../components/nav/BackDestination";
import SubtitleNav from "../../components/nav/SubtitleNav";
import {SidebarContextProvider} from "../../components/sidebars/SidebarContext";
import StudentManagement from "../../components/students/StudentManagement";
import Tutoring from "../../components/tutoring/Tutoring";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import useTrackAppUsage from "../../hooks/useTrackAppUsage";
import CourseFeature from "../../store/courses/CourseFeature";
import type {CourseManagementModuleName} from "../../store/courses/CourseManagementModule";
import fetchCourse from "../../store/courses/fetchCourse";
import fetchCourseManagementModules from "../../store/courses/fetchCourseManagementModules";
import selectCourse, {
	selectCourseFetchStatus,
} from "../../store/courses/selectCourse";
import SelfEnrolmentStatus from "../../store/courses/SelfEnrolmentStatus";
import useAvailableManagementModules from "../../store/courses/useAvailableManagementModules";
import fetchNumberOfPendingEnrolmentApplications from "../../store/enrolment/fetchNumberOfPendingEnrolmentApplications";
import selectNumberOfPendingEnrolmentApplications, {
	selectFetchStatusOfNumberOfPendingEnrolmentApplications,
} from "../../store/enrolment/selectNumberOfPendingEnrolmentApplications";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectUserProfile from "../../store/userProfile/selectUserProfile";
import AccessDeniedError from "../../store/userSession/AccessDeniedError";
import useAccessDeniedErrorTracking from "../../store/userSession/useAccessDeniedErrorTracking";
import Condition from "../../utils/Condition";
import LoadingError from "../../utils/errors/LoadingError";
import ObservableRoute from "../../utils/ObservableRoute";
import TabLabelWithBadge from "../../utils/TabLabelWithBadge";

const Management = (props: {organisationName: string}): JSX.Element => {
	const params = useParams<{id: string}>();
	const courseId = parseInt(params.id, 10);

	useTrackAppUsage(props.organisationName, "teacher", courseId);

	const accessDenied = useAccessDeniedErrorTracking();

	const dispatch = useAppDispatch();
	const {l10n} = useLocalization();

	const {id: userId} = useAppSelector(selectUserProfile);

	const courseKey = keyProvider.course(courseId);

	const courseName =
		useAppSelector((state) => selectCourse(state, courseKey)?.name) ?? "";

	const courseFeatures =
		useAppSelector((state) => selectCourse(state, courseKey)?.features) ?? [];

	const selfEnrolmentMode = useAppSelector(
		(state) => selectCourse(state, courseKey)?.selfEnrolment
	);

	const courseFetchStatus = useAppSelector((state) =>
		selectCourseFetchStatus(state, courseKey)
	);

	const numberOfPendingApplications = useAppSelector((state) =>
		selectNumberOfPendingEnrolmentApplications(state, courseKey)
	);

	const numberOfPendingApplicationsFetchStatus = useAppSelector((state) =>
		selectFetchStatusOfNumberOfPendingEnrolmentApplications(state, courseKey)
	);

	const createSubroute = useSubrouteCreator();

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	const [
		modules,
		available,
		modulesFetchStatus,
		awaitingModules,
	] = useAvailableManagementModules(courseId);

	useEffect(() => {
		if (courseId && courseFetchStatus === "none") {
			dispatch(fetchCourse(courseId));
		}
	}, [courseFetchStatus, courseId, dispatch]);

	useEffect(() => {
		if (courseId && userId && modulesFetchStatus === "none") {
			dispatch(fetchCourseManagementModules({userId, courseId}));
		}
	}, [courseId, dispatch, modulesFetchStatus, userId]);

	useEffect(() => {
		if (
			courseId &&
			selfEnrolmentMode === SelfEnrolmentStatus.RequiresApproval &&
			numberOfPendingApplicationsFetchStatus === "none"
		) {
			dispatch(fetchNumberOfPendingEnrolmentApplications({courseId}));
		}
	}, [
		courseId,
		dispatch,
		numberOfPendingApplicationsFetchStatus,
		selfEnrolmentMode,
	]);

	const learningMaterialPage = createSubroute("/content");
	const exerciseBankPage = createSubroute("/exercise-bank");
	const extraMaterialsPage = createSubroute("/extra-materials");
	const bulletinBoardPage = createSubroute("/bulletin-board");
	const courseSettingsPage = createSubroute("/settings");
	const studentsPage = createSubroute("/students");
	const followUpPage = createSubroute("/follow-up");
	const statisticsPage = createSubroute("/statistics");
	const forumPage = createSubroute("/forum");
	const tutoringPage = createSubroute("/tutoring");
	const followUpExercisePage = createSubroute(
		"/follow-up/students/:studentId/chapters/:chapterId/exercises/:exerciseId/type/:exerciseType"
	);
	const exercisePage = createSubroute("/exercises/:exerciseId");

	const [featureEnabled, awaitingFeatures] = useFeatureEnabled();
	const newAnalyticsEnabled =
		featureEnabled(Feature.NewAnalytics) &&
		courseFeatures.includes(CourseFeature.NewAnalytics);

	if (accessDenied) {
		return <AccessDeniedError />;
	}

	if (courseFetchStatus === "failed" || modulesFetchStatus === "failed") {
		return (
			<LoadingError
				description={
					<Localized id="course-management-loading-error">
						Failed to load the course
					</Localized>
				}
				onReload={() => {
					if (courseFetchStatus === "failed") {
						dispatch(fetchCourse(courseId));
					}
					if (modulesFetchStatus === "failed") {
						dispatch(fetchCourseManagementModules({userId, courseId}));
					}
				}}
			/>
		);
	}

	const moduleTabs: {
		[key in Exclude<CourseManagementModuleName, "settings">]: {
			label: React.ReactNode;
			page: ReturnType<typeof createSubroute>;
		};
	} = {
		bulletin_board: {
			label: (
				<Localized id="course-management-subtitlenav-label-bulletin-board">
					Bulletin board
				</Localized>
			),
			page: bulletinBoardPage,
		},
		content: {
			label: (
				<Localized id="course-management-subtitlenav-label-learning-material">
					Learning material
				</Localized>
			),
			page: learningMaterialPage,
		},
		exercise_bank: {
			label: (
				<Localized id="course-management-subtitlenav-label-exercise-bank">
					Exercise bank
				</Localized>
			),
			page: exerciseBankPage,
		},
		extra_materials: {
			label: (
				<Localized id="course-management-subtitlenav-label-extra-materials">
					Extra materials
				</Localized>
			),
			page: extraMaterialsPage,
		},
		"follow-up": {
			label: (
				<Localized id="course-management-subtitlenav-label-follow-up">
					Follow-up
				</Localized>
			),
			page: followUpPage,
		},
		forum: {
			label: (
				<Localized id="course-management-subtitlenav-label-forum">
					Forum
				</Localized>
			),
			page: forumPage,
		},
		statistics: {
			label: (
				<Localized id="course-management-subtitlenav-label-statistics">
					Statistics
				</Localized>
			),
			page: statisticsPage,
		},
		students: {
			label: (
				<TabLabelWithBadge
					content={
						<Localized id="course-management-subtitlenav-label-students">
							Students
						</Localized>
					}
					badgeContent={numberOfPendingApplications}
					prepareBadgeContentDescription={(number, maxExceeded) =>
						l10n.getString(
							maxExceeded
								? "course-management-subtitlenav-badge-pending-enrolment-applications-label-max-exceeded"
								: "course-management-subtitlenav-badge-pending-enrolment-applications-label-default",
							{number}
						)
					}
				/>
			),
			page: studentsPage,
		},
		tutoring: {
			label: (
				<Localized id="course-management-subtitlenav-label-tutoring">
					Tutoring
				</Localized>
			),
			page: tutoringPage,
		},
	};

	const navigationTabs = (
		<SubtitleNav
			title={courseName}
			selectedTab={selectedTab}
			settingsUrl={available("settings") ? courseSettingsPage.link : undefined}
		>
			{modules.map(
				(m) =>
					m.name !== "settings" && (
						<Tab
							key={m.name}
							label={moduleTabs[m.name].label}
							component={Link}
							value={moduleTabs[m.name].page.link}
							to={moduleTabs[m.name].page.link}
						/>
					)
			)}
		</SubtitleNav>
	);

	function viewOnly(name: CourseManagementModuleName) {
		return modules.find((m) => m.name === name)?.accessMode === "view-only";
	}

	return (
		<Switch>
			<Route path={courseSettingsPage.route}>
				<Condition
					value={available("settings")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={modules.length === 0 && awaitingModules}
				>
					<BackDestination
						key="course-settings"
						fallback={learningMaterialPage.link}
					>
						<CourseSettings
							courseId={courseId}
							viewOnly={viewOnly("settings")}
						/>
					</BackDestination>
				</Condition>
			</Route>

			<ObservableRoute
				path={learningMaterialPage.route}
				onRender={() => setSelectedTab(learningMaterialPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("content")}
					otherwise={"/"}
					pending={awaitingModules}
				>
					<SidebarContextProvider>
						<LearningMaterial
							organisationName={props.organisationName}
							courseId={courseId}
							viewOnly={viewOnly("content")}
						/>
					</SidebarContextProvider>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={exerciseBankPage.route}
				onRender={() => setSelectedTab(exerciseBankPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("exercise_bank")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<ExerciseBank
						organisationName={props.organisationName}
						viewOnly={viewOnly("exercise_bank")}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={exercisePage.route}
				onRender={() => setSelectedTab(exerciseBankPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("exercise_bank")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<BackDestination key="exercise" path={exerciseBankPage.link}>
						<Exercise
							organisationName={props.organisationName}
							courseId={courseId}
							viewOnly={viewOnly("exercise_bank")}
						/>
					</BackDestination>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={extraMaterialsPage.route}
				onRender={() => setSelectedTab(extraMaterialsPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("extra_materials")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={TeacherCoursePage.ExtraMaterials}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={bulletinBoardPage.route}
				onRender={() => setSelectedTab(bulletinBoardPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("bulletin_board")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={TeacherCoursePage.BulletinBoard}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={studentsPage.route}
				onRender={() => setSelectedTab(studentsPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("students")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					{featureEnabled(Feature.NewStudentManagement) ? (
						<StudentManagement
							organisationName={props.organisationName}
							courseId={courseId}
							userId={userId}
							viewOnly={viewOnly("students")}
						/>
					) : (
						<CourseFramedPage
							courseId={courseId}
							coursePage={TeacherCoursePage.Students}
						/>
					)}
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={followUpPage.route}
				onRender={() => setSelectedTab(followUpPage.link)}
				exact
			>
				{navigationTabs}

				<Condition
					value={available("follow-up")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<Condition
						value={newAnalyticsEnabled}
						pending={awaitingFeatures}
						otherwise={
							<CourseFramedPage
								courseId={courseId}
								coursePage={TeacherCoursePage.FollowUp}
							/>
						}
					>
						<FollowUp
							courseId={courseId}
							userId={userId}
							viewOnly={viewOnly("follow-up")}
						/>
					</Condition>
				</Condition>
			</ObservableRoute>

			<Route
				path={followUpExercisePage.route}
				render={({match}) => (
					<>
						{navigationTabs}

						<CourseFramedPage
							params={{
								chapterId: match.params.chapterId,
								studentId: match.params.studentId,
								exerciseId: match.params.exerciseId,
								exerciseType: match.params.exerciseType,
							}}
							courseId={courseId}
							coursePage={TeacherCoursePage.FollowUpExercise}
						/>
					</>
				)}
			/>

			<ObservableRoute
				path={statisticsPage.route}
				onRender={() => setSelectedTab(statisticsPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("statistics")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={TeacherCoursePage.Statistics}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={forumPage.route}
				onRender={() => setSelectedTab(forumPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("forum")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<CourseFramedPage
						courseId={courseId}
						coursePage={TeacherCoursePage.Forum}
					/>
				</Condition>
			</ObservableRoute>

			<ObservableRoute
				path={tutoringPage.route}
				onRender={() => setSelectedTab(tutoringPage.link)}
			>
				{navigationTabs}

				<Condition
					value={available("tutoring")}
					otherwise={<Redirect to={learningMaterialPage.link} />}
					pending={awaitingModules}
				>
					<Tutoring
						courseId={courseId}
						pageLink={tutoringPage.link}
						viewOnly={viewOnly("tutoring")}
					/>
				</Condition>
			</ObservableRoute>

			<Route>
				<Redirect to={learningMaterialPage.link} />
			</Route>
		</Switch>
	);
};

export default Management;
