import {createSlice} from "@reduxjs/toolkit";

import type ExerciseType from "../exercises/ExerciseType";
import fetchCourseContentStatistics from "./fetchCourseContentStatistics";

export type ChapterStats = {
	[key in ExerciseType]?: {
		numberOfExercises: number;
		maxScore: number;
	};
};

type SliceState = {
	byKey: {
		[chapterKey: string]: ChapterStats | undefined;
	};
};

const initialState: SliceState = {
	byKey: {},
};

export const chapterStatisticsSlice = createSlice({
	name: "chapterStatistics",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchCourseContentStatistics.fulfilled,
			(state, {payload}) => {
				payload.forEach((s) => {
					state.byKey[s.chapterKey] = s.stats;
				});
			}
		);
	},
});

export default chapterStatisticsSlice.reducer;
