import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import {Button, Menu, MenuItem} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";

import type {
	ChangeExerciseSearchCriteria,
	ExerciseSearchCriteria,
} from "./ExerciseSearchCriteria";
import ExerciseType from "../../store/exercises/ExerciseType";
import TableToolbar from "../../utils/tables/TableToolbar";

const useStyles = makeStyles(() =>
	createStyles({
		mainAction: {
			whiteSpace: "nowrap",
		},
	})
);

const exerciseTypes = [
	ExerciseType.Multi,
	ExerciseType.Short,
	ExerciseType.Open,
	ExerciseType.Prog,
	ExerciseType.Math,
	ExerciseType.External,
];

const ExerciseBankToolbar = (props: {
	courseId: number;
	availableExerciseTypes: ExerciseType[];
	criteria: ExerciseSearchCriteria;
	mainActionHidden?: boolean;
	onCriteriaChange: ChangeExerciseSearchCriteria;
	onToggleFilters: () => void;
}): JSX.Element => {
	const {
		courseId,
		availableExerciseTypes,
		criteria,
		onCriteriaChange,
		onToggleFilters,
	} = props;

	const classes = useStyles();
	const history = useHistory();
	const {l10n} = useLocalization();

	const [typeSelector, setTypeSelector] = useState<HTMLButtonElement | null>(
		null
	);

	const setQuery = useCallback(
		(val: string) =>
			onCriteriaChange((prev) => ({...prev, query: val ?? undefined})),
		[onCriteriaChange]
	);

	const linksToCreateExercises = useMemo(
		() =>
			exerciseTypes
				.filter((type) => availableExerciseTypes.includes(type))
				.map((type) => (
					<MenuItem
						key={type}
						onClick={() => {
							history.push(
								`/courses/${courseId}/management/exercises/new?type=${type}`
							);
							setTypeSelector(null);
						}}
					>
						<Localized
							id="exercise-bank-toolbar-action-new-exercise-of-type"
							vars={{type}}
						/>
					</MenuItem>
				)),
		[availableExerciseTypes, courseId, history]
	);

	return (
		<>
			<TableToolbar
				query={criteria.query ?? ""}
				actions={[]}
				mainAction={
					!props.mainActionHidden && (
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							onClick={(event) => setTypeSelector(event.currentTarget)}
							className={classes.mainAction}
						>
							<Localized id="exercise-bank-toolbar-action-new-exercise">
								New exercise
							</Localized>
						</Button>
					)
				}
				searchPlaceholder={l10n.getString("exercise-bank-search-label")}
				onQueryChange={setQuery}
				onToggleFilters={onToggleFilters}
			/>

			<Menu
				open={Boolean(typeSelector)}
				anchorEl={typeSelector}
				onClose={() => setTypeSelector(null)}
			>
				{linksToCreateExercises}
			</Menu>
		</>
	);
};

export default ExerciseBankToolbar;
