import {Localized} from "@fluent/react";
import React, {useCallback, useState} from "react";

import useConfirmationDialog from "../hooks/useConfirmationDialog";
import type FetchStatus from "../store/FetchStatus";

function useConfirmClosing(onCancel: () => void, onDone: () => void) {
	const [status, setStatus] = useState<FetchStatus>("none");

	const [
		confirmationDialog,
		openConfirmationDialog,
		closeConfirmationDialog,
	] = useConfirmationDialog();

	const changeStatus = useCallback(
		(status: FetchStatus) => {
			if (status === "succeeded" || status === "failed") {
				closeConfirmationDialog();
			}

			setStatus(status);
		},
		[closeConfirmationDialog]
	);

	function close() {
		switch (status) {
			case "pending":
				return confirmClosing();
			case "succeeded":
				return closeOnDone();
			default:
				return closeOnCancel();
		}
	}

	function confirmClosing() {
		openConfirmationDialog({
			title: (
				<Localized id="confirm-closing-dialog-title">Interrupt?</Localized>
			),
			description: (
				<Localized id="confirm-closing-dialog-descr">
					The operation is still ongoing. Do you want to interrupt it?
				</Localized>
			),
			confirmBtnText: (
				<Localized id="confirm-closing-dialog-action-confirm">
					Interrupt
				</Localized>
			),
			onConfirm: closeOnCancel,
		});
	}

	async function closeOnCancel() {
		onCancel();
	}

	function closeOnDone() {
		onDone();
	}

	return {confirmationDialog, status, close, changeStatus};
}

export default useConfirmClosing;
