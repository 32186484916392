import {Localized, useLocalization} from "@fluent/react";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useCallback, useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import useCopyAction from "../../courseCopying/useCopyAction";
import useArchiveCoursesAction from "../../courses/useArchiveCoursesAction";
import useDeleteAction from "../../courses/useDeleteAction";
import useRestoreCoursesConfirmation from "../../courses/useRestoreCoursesConfirmation";
import Filters from "./Filters";
import {
	addToDate,
	formatAtLocalTimeZone,
} from "../../../helpers/dateTimeHelpers";
import parseDate from "../../../helpers/parseDate";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import useMobileMode from "../../../hooks/useMobileMode";
import useNavBarHeight from "../../../hooks/useNavBarHeight";
import useDateFormat from "../../../i18n/useDateFormat";
import useDateTimeFormat from "../../../i18n/useDateTimeFormat";
import archiveCourses from "../../../store/courses/archiveCourses";
import deleteCourses from "../../../store/courses/deleteCourses";
import restoreCourses from "../../../store/courses/restoreCourses";
import {useAppDispatch} from "../../../store/hooks";
import type {OrganisationCourseSearchCriteria} from "../../../store/services/courseService";
import {courseService} from "../../../store/services/courseService";
import type OrganisationCourseSearchResult from "../../../store/services/dtos/OrganisationCourseSearchResult";
import useSnackbar from "../../../store/ui/useSnackbar";
import ExtendableBox from "../../../utils/ExtendableBox";
import HeadCell from "../../../utils/tables/HeadCell";
import LoadingErrorState from "../../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../../utils/tables/SortingHeader";
import SortOrder from "../../../utils/tables/SortOrder";
import TablePagination from "../../../utils/tables/TablePagination";
import TableToolbar from "../../../utils/tables/TableToolbar";
import useBulkSelection from "../../../utils/tables/useBulkSelection";
import usePaginationState from "../../../utils/tables/usePaginationState";

const filtersBarWidth = 320;

const useStyles = makeStyles<Theme, {navBarHeight: number}>((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<OrganisationCourseSearchResult>[] = [
	{
		id: "name",
		label: "administration-courses-table-label-name",
		sortable: true,
	},
	{
		id: "startDate",
		label: "administration-courses-table-label-start-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "endDate",
		label: "administration-courses-table-label-end-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "archivalDate",
		label: "administration-courses-table-label-archival-date",
		sortable: true,
		width: 160,
		align: "right",
	},
	{
		id: "type",
		label: "administration-courses-table-label-type",
		sortable: false,
		width: 100,
	},
];

const columnNumber = headCells.length + 2;

const initialCriteria: OrganisationCourseSearchCriteria = {};

function Courses(props: {organisationName: string}): JSX.Element {
	const {organisationName} = props;

	const navBarHeight = useNavBarHeight();
	const classes = useStyles({navBarHeight});

	const [filtersOpen, setFiltersOpen] = useState(false);

	const [criteria, setCriteria] = useState(initialCriteria);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val ?? undefined}));
	}, []);

	const [coursesPerPage, setCoursesPerPage] = useState(10);

	const [sortField, setSortField] = useState<
		keyof OrganisationCourseSearchResult
	>("name");
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const {
		page: coursesPage,
		pageFetchStatus,
		fetchRelatedPage: fetchCoursesPage,
		fetchFirstPage,
		reloadPage,
		retryFetching,
	} = usePaginationState<OrganisationCourseSearchResult>();

	const courses = coursesPage.content;

	const {
		select: selectCourse,
		bulkSelectionCheckbox,
		selected: selectedCourses,
		resetSelection,
	} = useBulkSelection(coursesPage, (c) => c.id);

	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [menuCourseId, setMenuCourseId] = useState(0);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();
	const dispatch = useAppDispatch();
	const formatDate = useDateFormat();
	const formatDatetime = useDateTimeFormat();
	const {l10n} = useLocalization();
	const mobileMode = useMobileMode("md");
	const theme = useTheme();
	const showSnackbar = useSnackbar();

	const showError = () =>
		showSnackbar("error", l10n.getString("error-general"));

	const [copyCourse, copyCourseDialog] = useCopyAction(
		organisationName,
		() => reloadPage(resetSelection),
		showError
	);

	const [removeCourses, deleteCourseDialog] = useDeleteAction(
		async (organisationName, ids, deleteAssociated) => {
			const res = await dispatch(
				deleteCourses({
					ids,
					organisationName,
					options: {deleteAssociated},
				})
			);

			unwrapResult(res);
		},
		() => reloadPage(resetSelection),
		showError
	);

	const [archive, archiveCoursesDialog] = useArchiveCoursesAction(
		async (organisationName, schedule) => {
			const res = await dispatch(archiveCourses({organisationName, schedule}));
			unwrapResult(res);
		},
		() => reloadPage(resetSelection),
		() => showSnackbar("error", l10n.getString("error-general"))
	);

	async function restore(organisationName: string, courseIds: number[]) {
		try {
			const res = await dispatch(restoreCourses({organisationName, courseIds}));
			unwrapResult(res);

			reloadPage(resetSelection);
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	const confirmRestore = useRestoreCoursesConfirmation(
		openConfirmationDialog,
		restore
	);

	useEffect(() => {
		fetchFirstPage(
			() =>
				courseService.searchOrganisationCourses(
					organisationName,
					{
						...criteria,
						startDateAfter: criteria.startDateAfter
							? formatAtLocalTimeZone(parseDate(criteria.startDateAfter))
							: undefined,
						startDateBefore: criteria.startDateBefore
							? formatAtLocalTimeZone(
									parseDate(addToDate(criteria.startDateBefore, {days: 1}))
							  )
							: undefined,
						endDateAfter: criteria.endDateAfter
							? formatAtLocalTimeZone(parseDate(criteria.endDateAfter))
							: undefined,
						endDateBefore: criteria.endDateBefore
							? formatAtLocalTimeZone(
									parseDate(addToDate(criteria.endDateBefore, {days: 1}))
							  )
							: undefined,
					},
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					coursesPerPage
				),
			resetSelection
		);
	}, [
		coursesPerPage,
		criteria,
		fetchFirstPage,
		organisationName,
		resetSelection,
		sortField,
		sortOrder,
	]);

	function changeOrder(
		orderBy: keyof OrganisationCourseSearchResult,
		order: SortOrder
	) {
		setSortOrder(order);
		setSortField(orderBy);
	}

	function isSelected(id: number) {
		return selectedCourses.indexOf(id) !== -1;
	}

	const now = new Date();

	function createCourseActions(courseId: number) {
		const course = courses.find((course) => course.id === courseId);
		if (!course) {
			return null;
		}

		const archivalDate = course.archivalDate && parseDate(course.archivalDate);

		const items = [];

		items.push(
			<MenuItem key="settings">
				<Link
					key="settings"
					component={RouterLink}
					to={`/courses/${courseId}/management/settings`}
					style={{textDecoration: "none", color: "inherit"}}
				>
					<Localized id="administration-courses-settings">Settings</Localized>
				</Link>
			</MenuItem>
		);

		items.push(
			<MenuItem
				key="copy"
				onClick={() => {
					copyCourse({id: courseId, name: course.name});
					setMenuAnchor(null);
				}}
			>
				<Localized id="administration-courses-action-copy">Copy</Localized>
			</MenuItem>
		);

		items.push(
			<Divider
				key="divider1"
				component="li"
				style={{
					margin: theme.spacing(1, 0),
				}}
			/>
		);

		if (!archivalDate || archivalDate > now) {
			items.push(
				<MenuItem
					key="archive"
					onClick={() => {
						archive(organisationName, [courseId]);
						setMenuAnchor(null);
					}}
				>
					<Localized id="administration-courses-action-archive">
						Archive
					</Localized>
				</MenuItem>
			);
		}

		if (archivalDate && archivalDate > now) {
			items.push(
				<MenuItem
					key="cancel-archiving"
					onClick={() => {
						restore(organisationName, [courseId]);
						setMenuAnchor(null);
					}}
				>
					<Localized id="administration-courses-action-cancel-archiving">
						Cancel archiving
					</Localized>
				</MenuItem>
			);
		}

		if (archivalDate && archivalDate <= now) {
			items.push(
				<MenuItem
					key="restore"
					onClick={() => {
						confirmRestore(organisationName, [courseId], course.name);
						setMenuAnchor(null);
					}}
				>
					<Localized id="administration-courses-action-restore">
						Restore
					</Localized>
				</MenuItem>
			);
		}

		items.push(
			<Divider
				key="divider2"
				component="li"
				style={{
					margin: theme.spacing(1, 0),
				}}
			/>
		);

		items.push(
			<MenuItem
				key="delete"
				onClick={() => {
					removeCourses(organisationName, [menuCourseId], course.name);
					setMenuAnchor(null);
				}}
			>
				<Localized id="administration-courses-action-delete">Delete</Localized>
			</MenuItem>
		);

		return items;
	}

	return (
		<>
			<div className={classes.root}>
				<ExtendableBox
					rightExtension={filtersOpen || mobileMode ? 0 : filtersBarWidth}
					minWidth={0}
				>
					<Box
						display="flex"
						flexDirection="column"
						pt={mobileMode ? 3 : 5}
						px={mobileMode ? 3 : 6}
						pb={1.5}
						position="relative"
						style={{height: `calc(100vh - ${navBarHeight + 1}px)`}}
					>
						<TableToolbar
							mainAction={
								<Button
									variant="contained"
									color="primary"
									startIcon={<AddIcon />}
									component={RouterLink}
									to={"/courses/new"}
									style={{whiteSpace: "nowrap"}}
								>
									<Localized id="administration-courses-new-course-btn">
										New course
									</Localized>
								</Button>
							}
							onToggleFilters={() => setFiltersOpen((prev) => !prev)}
							query={criteria.query ?? ""}
							onQueryChange={setQuery}
							actions={[
								{
									name: l10n.getString(
										"administration-courses-bulk-action-archive",
										null,
										"Archive"
									),
									disabled: selectedCourses.length === 0,
									onClick: () => archive(organisationName, selectedCourses),
								},
								{
									name: l10n.getString(
										"administration-courses-bulk-action-restore-or-cancel-archiving",
										null,
										"Restore or cancel archiving"
									),
									disabled: selectedCourses.length === 0,
									divider: true,
									onClick: () =>
										confirmRestore(organisationName, selectedCourses),
								},
								{
									name: l10n.getString(
										"administration-courses-bulk-action-delete",
										null,
										"Delete"
									),
									disabled: selectedCourses.length === 0,
									onClick: () =>
										removeCourses(organisationName, selectedCourses),
								},
							]}
						/>
						<TableContainer className={classes.container}>
							<Table stickyHeader>
								<SortingHeader
									onOrderChange={changeOrder}
									order={sortOrder}
									orderBy={sortField}
									headCells={headCells}
									leftAnnex={
										<TableCell padding="checkbox" style={{zIndex: 3}}>
											{bulkSelectionCheckbox}
										</TableCell>
									}
									rightAnnex={<TableCell />}
									loading={pageFetchStatus === "pending"}
								/>
								<TableBody>
									{pageFetchStatus === "failed" && (
										<LoadingErrorState
											description={
												<Localized id="administration-courses-loading-error-descr">
													Something has gone wrong, and we cannot load courses
												</Localized>
											}
											columnNumber={columnNumber}
											onReload={retryFetching}
										/>
									)}
									{pageFetchStatus === "succeeded" && courses.length === 0 && (
										<NoSearchResultsState
											columnNumber={columnNumber}
											title={
												<Localized id="organisation-courses-no-courses">
													No courses
												</Localized>
											}
											description={
												criteria === initialCriteria ? (
													<Localized id="organisation-courses-no-courses-descr">
														There are no courses in the organisation
													</Localized>
												) : (
													<Localized id="organisation-courses-no-results-descr">
														No courses were found matching your search criteria.
														Try to adjust filters
													</Localized>
												)
											}
											action={
												criteria === initialCriteria && (
													<Button
														variant="contained"
														color="primary"
														component={RouterLink}
														to={"/courses/new"}
													>
														<Localized id="administration-courses-no-courses-create-btn">
															Create course
														</Localized>
													</Button>
												)
											}
										/>
									)}
									{pageFetchStatus !== "failed" &&
										courses.map((course, index) => {
											const isItemSelected = isSelected(course.id);
											const labelId = `table-checkbox-${index}`;
											const archivalDate =
												course.archivalDate && parseDate(course.archivalDate);
											const archived = Boolean(
												archivalDate && archivalDate <= now
											);

											return (
												<TableRow
													hover
													key={course.id}
													tabIndex={-1}
													selected={isItemSelected}
												>
													<TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															inputProps={{"aria-labelledby": labelId}}
															onClick={() => selectCourse(course.id)}
														/>
													</TableCell>
													<TableCell
														component="th"
														id={labelId}
														scope="row"
														style={{
															paddingTop: archived
																? theme.spacing(1.5)
																: undefined,
															paddingBottom: archived
																? theme.spacing(1.5)
																: undefined,
														}}
													>
														<Box
															display="flex"
															justifyContent="space-between"
															alignItems="center"
														>
															<Link
																component={RouterLink}
																to={`/courses/${course.id}/management`}
															>
																{course.name}
															</Link>
															{archived && (
																<Chip
																	label={
																		<Localized id="administration-courses-course-label-archived">
																			Archived
																		</Localized>
																	}
																	size="small"
																	variant="outlined"
																/>
															)}
														</Box>
													</TableCell>
													<TableCell align="right">
														{formatDate(parseDate(course.startDate))}
													</TableCell>
													<TableCell align="right">
														{formatDate(parseDate(course.endDate))}
													</TableCell>
													<TableCell align="right">
														{archivalDate && (
															<Tooltip title={formatDatetime(archivalDate)}>
																<div>{formatDate(archivalDate)}</div>
															</Tooltip>
														)}
													</TableCell>
													<TableCell>
														<Localized
															id={`administration-courses-type-${course.type}`}
														>
															{course.type}
														</Localized>
													</TableCell>
													<TableCell padding="checkbox">
														<IconButton
															onClick={(e) => {
																setMenuAnchor(e.currentTarget);
																setMenuCourseId(course.id);
															}}
														>
															<MoreVertIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							ml={2}
						>
							<Typography variant="subtitle2">
								{selectedCourses.length > 0 && (
									<Localized
										id="administration-selected-row-number"
										vars={{selected: selectedCourses.length}}
									>{`${selectedCourses.length} selected`}</Localized>
								)}
							</Typography>
							<TablePagination
								onPageChange={fetchCoursesPage}
								pageSize={coursesPerPage}
								onPageSizeChange={setCoursesPerPage}
								first={Boolean(coursesPage.request.first)}
								last={Boolean(coursesPage.request.last)}
								next={Boolean(coursesPage.request.next)}
								previous={Boolean(coursesPage.request.previous)}
								label={
									<Localized id="administration-courses-per-page">
										Courses per page
									</Localized>
								}
								disabled={pageFetchStatus !== "succeeded"}
							/>
						</Box>
					</Box>
				</ExtendableBox>
				<Filters
					open={filtersOpen}
					width={
						mobileMode ? `calc(100vw - ${theme.spacing(7)})` : filtersBarWidth
					}
					mobileMode={mobileMode}
					criteria={criteria}
					onCriteriaChange={setCriteria}
					onClose={() => setFiltersOpen(false)}
				/>
			</div>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				onClose={() => setMenuAnchor(null)}
				PaperProps={{style: {minWidth: theme.spacing(14)}}}
			>
				{createCourseActions(menuCourseId)}
			</Menu>

			{copyCourseDialog}
			{archiveCoursesDialog}
			{confirmationDialog}
			{deleteCourseDialog}
		</>
	);
}

export default Courses;
