import {createAsyncThunk} from "@reduxjs/toolkit";

import {sectionService} from "../services/sectionService";

const updateSectionSettings = createAsyncThunk(
	"sections/updateSectionSettings",
	async function (params: {
		courseId: number;
		chapterId: number;
		sectionId: number;
		settings: {
			selectionSize?: number;
			maxScorePerExercise?: number;
		};
	}) {
		const {courseId, chapterId, sectionId, settings} = params;

		await sectionService.patchSectionSettings(
			courseId,
			chapterId,
			sectionId,
			settings
		);

		return {
			chapterId,
			sectionId,
			selectionSize: settings.selectionSize,
			maxScorePerExercise: settings.maxScorePerExercise,
		};
	}
);

export default updateSectionSettings;
