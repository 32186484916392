import {Localized, useLocalization} from "@fluent/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid2 as Grid,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

import ContentEditorFooter from "./ContentEditorFooter";
import ExerciseDifficultySelector from "../content/exercises/ExerciseDifficultySelector";
import type ExerciseEditorProps from "./ExerciseEditorProps";
import {equalArrays} from "../../helpers/arrayHelpers";
import PrivacyLevelSelector from "./PrivacyLevelSelector";
import ShortTextAssessmentRulesEditor from "./ShortTextAssessmentRulesEditor";
import type {RulesEditorApi} from "./ShortTextAssessmentRulesEditor";
import type {ShortExercisePatch} from "../../store/exercises/ExercisePatch";
import ExercisePrivacy from "../../store/exercises/ExercisePrivacy";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import type {ShortAnswerExercise} from "../../store/services/dtos/EditableExercise";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useExerciseEditorTagSelector from "./useExerciseEditorTagSelector";
import useExerciseFileUploader from "./useExerciseFileUploader";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";
import TextEditor from "../../utils/TextEditor/TextEditor";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";

function ShortAnswerExerciseEditor(props: Omit<ExerciseEditorProps, "onSave">) {
	const {exerciseId, exercise} = props;

	const {l10n} = useLocalization();
	const showSnackbar = useSnackbar();

	const [dirty, setDirty] = useState(false);

	const [id, setId] = useState(exerciseId ?? 0);

	const [title, setTitle] = useState(
		l10n.getString("short-answer-exercise-editor-default-title")
	);

	const [maxScore, setMaxScore] = useState(1);
	const initialQuestion = useRef("");
	const initialSolution = useRef("");
	const [privacy, setPrivacy] = useState<ExercisePrivacy>(
		ExercisePrivacy.PublicToOrganisation
	);
	const [difficultyLevel, setDifficultyLevel] = useState(0);
	const [category, setCategory] = useState("");
	const [tags, setTags] = useState<string[]>([]);

	const questionEditor = useRef<TextEditorApi>(null);
	const solutionEditor = useRef<TextEditorApi>(null);

	const [emptyFields, setEmptyFields] = useState<{
		title?: boolean;
		question?: boolean;
	}>({});

	const userId = useAppSelector(selectUserId);

	const [files, fileUploader] = useExerciseFileUploader(
		userId,
		props.courseId,
		id
	);

	const TagSelector = useExerciseEditorTagSelector(
		props.organisationName,
		userId
	);

	const updated = useRef(false);

	if (exercise && exercise.type !== ExerciseType.Short) {
		throw new Error("Unexpected exercise type");
	}

	const savedExercise = useRef<ShortAnswerExercise | null>(exercise);

	const rulesEditor = useRef<RulesEditorApi>(null);
	const rulesChanged = useRef(false);

	useEffect(() => {
		if (!exercise) {
			return;
		}

		savedExercise.current = exercise;

		setId(exerciseId ?? 0);

		setTitle(exercise.title);

		setMaxScore(exercise.maxScore);

		initialQuestion.current = exercise.question;

		initialSolution.current = exercise.solution;

		setPrivacy(exercise.privacyLevel);

		setDifficultyLevel(exercise.difficultyLevel);

		setCategory(exercise.category);

		if (exercise.tags.length > 0) {
			setTags(exercise.tags);
		}
	}, [exercise, exerciseId]);

	useEffect(() => props.onUnmount, [props.onUnmount]);

	const save = async () => {
		const question = questionEditor.current?.getContent() ?? "";

		const error = validate(question);
		if (error !== "") {
			showSnackbar("error", error);
			return false;
		}

		let ex: ShortAnswerExercise;

		try {
			if (!savedExercise.current) {
				ex = await create(question);
			} else {
				ex = await update(id, savedExercise.current, question);
			}
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return false;
		}

		updated.current = true;

		savedExercise.current = ex;

		questionEditor.current?.setContent(ex.question);
		questionEditor.current?.markAsSaved();

		solutionEditor.current?.setContent(ex.solution);
		solutionEditor.current?.markAsSaved();

		setDirty(false);
		rulesChanged.current = false;

		return true;
	};

	const create = async (question: string) => {
		const res = await props.onCreate({
			type: ExerciseType.Short,
			originId: props.courseId,
			category: category,
			difficultyLevel: difficultyLevel,
			tags: tags,
			solution: solutionEditor.current?.getContent() ?? "",
			maxScore: maxScore,
			privacyLevel: privacy,
			question: question,
			title: title,
			language: props.defaultLanguage,
			settings: {
				rules: rulesEditor.current?.getRules() ?? [],
			},
		});

		setId(res.id);

		return res.exercise as ShortAnswerExercise;
	};

	const update = async (
		exerciseId: number,
		prev: ShortAnswerExercise,
		question: string
	) => {
		const patch: ShortExercisePatch = {};

		if (prev.title !== title) {
			patch.title = title;
		}

		if (prev.question !== question) {
			patch.question = question;
		}

		const solution = solutionEditor.current?.getContent();
		if (prev.solution !== solution) {
			patch.solution = solution === "" ? null : solution;
		}

		if (prev.maxScore !== maxScore) {
			patch.maxScore = maxScore;
		}

		if (prev.difficultyLevel !== difficultyLevel) {
			patch.difficultyLevel = difficultyLevel;
		}

		if (prev.privacyLevel !== privacy) {
			patch.privacyLevel = privacy;
		}

		if (prev.category !== category) {
			patch.category = category === "" ? null : category;
		}

		if (!equalArrays(prev.tags, tags)) {
			patch.tags = tags;
		}

		if (rulesChanged.current) {
			patch.settings = {rules: rulesEditor.current?.getRules()};
		}

		const ex = await props.onUpdate(exerciseId, patch);

		return ex as ShortAnswerExercise;
	};

	function validate(question: string) {
		const empty: typeof emptyFields = {
			title: title === "",
			question: question === "",
		};

		if (Object.values(empty).some((v) => v)) {
			setEmptyFields(empty);

			return l10n.getString(
				"exercise-editor-error-required-fields",
				null,
				"Some required fields are empty"
			);
		}

		const rulesError = rulesEditor.current?.validate();
		if (rulesError) {
			return rulesError;
		}

		return "";
	}

	async function complete() {
		const saved = await save();
		if (!saved) {
			return;
		}

		props.onClose(updated.current);
	}

	return (
		<>
			<Grid container spacing={4}>
				<Grid size={12}>
					<TextField
						id="exercise-title"
						required
						error={emptyFields.title}
						value={title}
						autoFocus
						fullWidth
						label={
							<Localized id="exercise-editor-title-label">
								Exercise title
							</Localized>
						}
						onChange={({target}) => {
							setTitle(target.value);
							setDirty(true);
							setEmptyFields((prev) => {
								if (prev.title) {
									return {...prev, title: false};
								}
								return prev;
							});
						}}
					/>
				</Grid>

				<Grid size={12}>
					<TextField
						id="score-number"
						type="number"
						value={maxScore}
						label={
							<Localized id="exercise-editor-score-label">Score</Localized>
						}
						onChange={({target}) => {
							const s = parseInt(target.value);
							if (s >= 0) {
								setMaxScore(s);
								setDirty(true);
							}
						}}
					/>
				</Grid>

				<Grid size={12}>
					<InputLabel required error={emptyFields.question} shrink sx={{mb: 1}}>
						<Localized id="exercise-editor-description-label">
							Description
						</Localized>
					</InputLabel>
					<TextEditorWithAttachments
						initialValue={initialQuestion.current}
						ref={questionEditor}
						fileUploader={fileUploader ?? undefined}
						files={files}
						onDirty={() => {
							setDirty(true);
							setEmptyFields((prev) => {
								if (prev.question) {
									return {...prev, question: false};
								}
								return prev;
							});
						}}
					/>
				</Grid>

				<Grid size={12}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>
								<Localized id="exercise-editor-solution-label">
									Solution
								</Localized>
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{width: 1}}>
								<TextEditor
									initialValue={initialSolution.current}
									fileUploader={fileUploader ?? undefined}
									ref={solutionEditor}
									onDirty={() => setDirty(true)}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Grid>

				<Grid size={12}>
					<Stack spacing={1}>
						<Typography variant="h6">
							<Localized id="short-answer-exercise-editor-label-assessment-rules">
								Assessment rules
							</Localized>
						</Typography>
						<ShortTextAssessmentRulesEditor
							rules={exercise?.settings.rules ?? []}
							editor={rulesEditor}
							maxScore={maxScore}
							notice={
								<Localized id="short-answer-exercise-editor-rules-notice">
									At least one rule for correct answer (i.e. with score
									percentage equal to 100) should be specified.
								</Localized>
							}
							onDirty={() => {
								setDirty(true);
								rulesChanged.current = true;
							}}
						/>
					</Stack>
				</Grid>

				<Grid size={{xs: 12, md: 6}}>
					<PrivacyLevelSelector
						value={privacy}
						onChange={(value) => {
							setPrivacy(value);
							setDirty(true);
						}}
					/>
				</Grid>

				<Grid size={{xs: 12, md: 6}}>
					<ExerciseDifficultySelector
						name="exercise-difficulty"
						value={difficultyLevel}
						onChange={(value) => {
							setDifficultyLevel(value ?? 0);
							setDirty(true);
						}}
					/>
				</Grid>

				<Grid size={{xs: 12, md: 6}}>
					<TextField
						id="category"
						fullWidth
						value={category}
						label={
							<Localized id="exercise-editor-category-label">
								Category
							</Localized>
						}
						onChange={({target}) => {
							setDirty(true);
							setCategory(target.value);
						}}
					/>
				</Grid>

				{TagSelector && (
					<Grid size={12}>
						<TagSelector
							freeSolo
							value={tags}
							onChange={(value) => {
								setTags(value);
								setDirty(true);
							}}
						/>
					</Grid>
				)}
			</Grid>

			<ContentEditorFooter
				onCancel={() => props.onClose(updated.current)}
				onCompeleted={complete}
				onSave={save}
				disabled={!dirty}
			/>
		</>
	);
}

export default ShortAnswerExerciseEditor;
