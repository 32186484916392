import {Localized, useLocalization} from "@fluent/react";
import {Grid2 as Grid, MenuItem, TextField, Typography} from "@mui/material";
import React from "react";
import type {Dispatch, SetStateAction} from "react";

import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import type StudentStatus from "../../store/enrolment/StudentStatus";
import type {CourseStudentSearchCriteria} from "../../store/services/enrolmentService";
import SearchAndActionsToolbar from "../../utils/SearchAndActionsToolbar";

function StudentSearchToolbar(props: {
	criteria: CourseStudentSearchCriteria;
	default: CourseStudentSearchCriteria;
	onCriteriaChange: Dispatch<SetStateAction<CourseStudentSearchCriteria>>;
	renderMenuActions: (close: () => void) => React.ReactNode[];
}) {
	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		props.onCriteriaChange
	);

	const {l10n} = useLocalization();

	const canClear =
		Boolean(criteria.query) ||
		Boolean(criteria.studentStatus) ||
		Boolean(criteria.enrolledAfter) ||
		Boolean(criteria.enrolledBefore);

	return (
		<SearchAndActionsToolbar
			value={criteria.query ?? ""}
			placeholder={l10n.getString(
				"student-management-students-search-placeholder"
			)}
			onChange={(val) => setCriteria((prev) => ({...prev, query: val}))}
			onClear={
				canClear
					? () =>
							setCriteria({
								query: "",
								enrolledAfter: "",
								enrolledBefore: "",
								studentStatus: undefined,
							})
					: undefined
			}
			renderFilters={() => (
				<CriteriaPanel criteria={criteria} onCriteriaChange={setCriteria} />
			)}
			renderMenuActions={props.renderMenuActions}
		/>
	);
}

function CriteriaPanel(props: {
	criteria: CourseStudentSearchCriteria;
	onCriteriaChange: Dispatch<SetStateAction<CourseStudentSearchCriteria>>;
}) {
	const {criteria, onCriteriaChange} = props;

	const enrolledAfter = criteria.enrolledAfter?.slice(0, 10) ?? "";
	const enrolledBefore = criteria.enrolledBefore?.slice(0, 10) ?? "";

	return (
		<Grid container spacing={4}>
			<Grid container size={12} spacing={1}>
				<Grid size={12}>
					<Typography variant="caption" color="textSecondary">
						<Localized id="student-management-students-filters-enrolment-date">
							Enrolment date
						</Localized>
					</Typography>
				</Grid>
				<Grid size={{xs: 12, sm: 6, lg: 4}}>
					<TextField
						fullWidth
						type="date"
						label={
							<Localized id="student-management-students-filters-enrolment-date-after">
								After
							</Localized>
						}
						slotProps={{inputLabel: {shrink: true}}}
						value={enrolledAfter}
						onChange={({target}) =>
							onCriteriaChange((prev) => ({
								...prev,
								enrolledAfter: target.value ?? undefined,
							}))
						}
					/>
				</Grid>
				<Grid size={{xs: 12, sm: 6, lg: 4}}>
					<TextField
						fullWidth
						type="date"
						label={
							<Localized id="student-management-students-filters-enrolment-date-before">
								Before
							</Localized>
						}
						slotProps={{inputLabel: {shrink: true}}}
						value={enrolledBefore}
						onChange={({target}) =>
							onCriteriaChange((prev) => ({
								...prev,
								enrolledBefore: target.value ?? undefined,
							}))
						}
					/>
				</Grid>
			</Grid>
			<Grid size={12}>
				<TextField
					select
					fullWidth
					label={
						<Localized id="student-management-students-filters-student-status">
							Student status
						</Localized>
					}
					value={criteria.studentStatus ?? ""}
					onChange={({target}) =>
						onCriteriaChange((prev) => ({
							...prev,
							studentStatus:
								target.value === ""
									? undefined
									: (target.value as StudentStatus),
						}))
					}
				>
					<MenuItem value="">
						<em>
							<Localized id="student-management-students-filters-student-status-option-not-specified">
								Not specified
							</Localized>
						</em>
					</MenuItem>
					<MenuItem value="active">
						<Localized id="student-management-students-filters-student-status-option-active">
							Active
						</Localized>
					</MenuItem>
					<MenuItem value="suspended">
						<Localized id="student-management-students-filters-student-status-option-suspended">
							Suspended
						</Localized>
					</MenuItem>
				</TextField>
			</Grid>
		</Grid>
	);
}

export default StudentSearchToolbar;
