import {createSlice} from "@reduxjs/toolkit";

import fetchTheme from "./fetchTheme";

interface OrganisationTheme {
	enabled: boolean;
	primary: string;
	secondary: string;
}

const initialState: OrganisationTheme = {
	enabled: false,
	primary: "#2196F3",
	secondary: "#8BC34A",
};

export const themeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTheme.fulfilled, (state, action) => {
			state.enabled = action.payload.state === "enabled";

			const primary = action.payload.colours.find(
				(colour: {name: string}) => colour.name === "primary"
			);
			if (primary) {
				state.primary = primary.value;
			}

			const secondary = action.payload.colours.find(
				(colour: {name: string}) => colour.name === "secondary"
			);
			if (secondary) {
				state.secondary = secondary.value;
			}
		});
	},
});

export default themeSlice.reducer;
