import Section from "../sections/Section";
import {RootState} from "../store";

function selectChapterSessionSection(
	state: RootState,
	sectionKey: string
): Readonly<Section> | null {
	return (
		state.chapterSessions.lastRequested.sections[sectionKey]?.section ?? null
	);
}

export default selectChapterSessionSection;
