import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import amber from "@mui/material/colors/amber";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import {CompletionStatus} from "../../store/outcomes/ExerciseOutcomes";

const useStyles = makeStyles((theme) =>
	createStyles({
		pauseIcon: {
			color: amber[500],
		},
		doneIcon: {
			color: theme.palette.success.main,
		},
	})
);

const CompletionStatusIcon = (props: {
	status: CompletionStatus;
}): JSX.Element => {
	const classes = useStyles();

	switch (props.status) {
		case CompletionStatus.Incomplete:
			return (
				<PauseCircleFilledIcon fontSize="small" className={classes.pauseIcon} />
			);
		case CompletionStatus.Complete:
			return <CheckCircleIcon fontSize="small" className={classes.doneIcon} />;
		default:
			return <></>;
	}
};

export default CompletionStatusIcon;
