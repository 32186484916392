import {useLocalization} from "@fluent/react";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";
import {DragDropContext, DropResult, Droppable} from "react-beautiful-dnd";
import {useLocation} from "react-router-dom";

import DraggableComponent from "./DraggableComponent";
import {getChapters} from "../../store/chapters/getChapters";
import selectCourseChapterKeys from "../../store/chapters/selectCourseChapterKeys";
import updateChaptersOrder from "../../store/chapters/updateChaptersOrder";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import useSnackbar from "../../store/ui/useSnackbar";
import {move} from "../../helpers/arrayHelpers";
import selectCourseEditing from "../../store/ui/selectCourseEditing";

function findNewPlace(keys: string[], src: number, dst: number) {
	const before = dst > src ? dst + 1 : dst;

	let place = {};
	if (before < keys.length) {
		place = {before: keys[before]};
	}

	return place;
}

function TreeDragAndDrop(props: {
	courseId: number;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
}): JSX.Element {
	const courseId = props.courseId;
	const dispatch = useAppDispatch();
	const location = useLocation();

	const {l10n} = useLocalization();
	const generalError = l10n.getString(
		"error-general",
		null,
		"An error has occured"
	);

	const courseKey = keyProvider.course(courseId);

	const courseEditing = useAppSelector((state) =>
		selectCourseEditing(state, courseKey)
	);

	useEffect(() => {
		dispatch(getChapters(courseId));
	}, [courseId, dispatch]);

	const chapters = useAppSelector((state) =>
		selectCourseChapterKeys(state, keyProvider.course(courseId))
	);

	const [cache, setCache] = useState<string[]>(chapters);

	useEffect(() => {
		setCache(chapters);
	}, [chapters]);

	const [expandedChapterKey, setExpandedChapterKey] = useState("");

	const showSnackbar = useSnackbar();

	const chapterToggle = (key: string) => () => {
		setExpandedChapterKey(expandedChapterKey !== key ? key : "");
	};

	useEffect(() => {
		for (const chapter of chapters) {
			if (location.pathname.includes(chapter)) {
				setExpandedChapterKey(chapter);
				return;
			}
		}
	}, [chapters, location.pathname]);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const sourceIndex = result.source.index;
		const destIndex = result.destination.index;

		if (sourceIndex === destIndex) {
			return;
		}

		if (result.type === "chapter") {
			const reordered = move(cache, sourceIndex, destIndex);
			setCache(reordered);

			const saveNewOrder = async () => {
				try {
					const res = await dispatch(
						updateChaptersOrder({
							courseId: courseId,
							chapterKeys: reordered,
							chapterKey: cache[sourceIndex],
							place: findNewPlace(cache, sourceIndex, destIndex),
						})
					);
					unwrapResult(res);
				} catch {
					setCache(chapters);
					showSnackbar("error", generalError);
				}
			};
			saveNewOrder();
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable
				droppableId="course"
				type="chapter"
				isDropDisabled={props.viewOnly}
			>
				{(provided) => (
					<div ref={provided.innerRef}>
						{cache.map((key: string, index: number) => (
							<DraggableComponent
								key={key}
								chapterKey={key}
								index={index}
								viewOnly={props.viewOnly}
								onToggle={chapterToggle(key)}
								expanded={expandedChapterKey === key}
								openConfirmDialog={props.openConfirmDialog}
								showSnackbar={showSnackbar}
								courseEditing={courseEditing}
							/>
						))}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default TreeDragAndDrop;
