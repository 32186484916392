import type {AxiosError} from "axios";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

import {useAppDispatch} from "../hooks";
import {axiosInstance} from "../services/axiosInstance";
import type {AppDispatch} from "../store";
import logoutUser from "./logoutUser";

const LogOutOnUnauthorisedError = (): JSX.Element => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	useEffect(() => {
		const errorHandler = createErrorHandler(dispatch);

		const id = axiosInstance.interceptors.response.use(
			(response) => response,
			errorHandler
		);

		return () => {
			axiosInstance.interceptors.response.eject(id);
		};
	}, [dispatch, history]);

	return <></>;
};

function createErrorHandler(dispatch: AppDispatch) {
	return async function (error: AxiosError) {
		if (error.response?.status === 401) {
			await dispatch(logoutUser({logoutOnServer: false}));
			return Promise.reject({code: "unauthorised"});
		}

		return Promise.reject(error);
	};
}

export default LogOutOnUnauthorisedError;
