import type {AxiosInstance} from "axios";

import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import DefaultCoursePicture from "../../images/default-course-picture.jpg";
import type UserCourse from "../models/UserCourse";
import type EnrolmentApplicationStatus from "../selfEnrolment/EnrolmentApplicationStatus";

function createService(client: AxiosInstance) {
	async function getCoursesAvailableForEnrolment(
		organisationName: string,
		userId?: number
	): Promise<UserCourse[]> {
		const url = `/api/organisations/${organisationName}/courses-available-for-enrolment`;

		const params = new URLSearchParams();
		if (userId) {
			params.append("to", userId.toString());
		}

		const {data} = await client.get(url, {params});

		for (const item of data) {
			item.defaultPicture = DefaultCoursePicture;
		}

		return data;
	}

	async function getEnrolmentApplications(
		organisationName: string,
		studentId: number
	): Promise<UserCourse[]> {
		const {data} = await client.get<{course: UserCourse}[]>(
			`/api/organisations/${organisationName}/students/${studentId}/enrolment-applications`
		);

		const courses = data.map((d) => d.course);

		for (const item of courses) {
			item.defaultPicture = DefaultCoursePicture;
		}

		return courses;
	}

	async function submitEnrolmentApplication(
		courseId: number,
		studentId: number,
		password?: string
	): Promise<EnrolmentApplicationStatus> {
		try {
			const {data} = await client.post(
				`/api/courses/${courseId}/enrolment-applications`,
				{
					userId: studentId,
				},
				{auth: password ? {password, username: ""} : undefined}
			);

			return data.status;
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			}

			throw err;
		}
	}

	async function submitEnrolmentApplicationForNewUser(
		organisationName: string,
		courseId: number,
		applicant: {
			username: string;
			emailAddress: string;
			password: string;
			firstName: string;
			lastName: string;
			language: string;
		}
	): Promise<void> {
		try {
			await client.post(
				`/api/organisations/${organisationName}/enrolment-applications`,
				{
					courseId: courseId,
					applicantData: applicant,
				}
			);
		} catch (err) {
			if (
				httpClientError(err) &&
				err.response?.status === 409 &&
				err.response.data.type === "http://viope.com/problem-types/duplicate-id"
			) {
				throw {code: "duplicate_id", field: err.response.data.field};
			}

			throw err;
		}
	}

	return {
		getCoursesAvailableForEnrolment,
		getEnrolmentApplications,
		submitEnrolmentApplication,
		submitEnrolmentApplicationForNewUser,
	};
}

export default createService(client);
