import {Localized} from "@fluent/react";
import {Box, Toolbar} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import SubmitButton from "../../utils/SubmitButton";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			padding: theme.spacing(0, 1, 2, 2),
		},
		buttonsPanel: {
			display: "flex",
			gap: theme.spacing(1),
			padding: theme.spacing(0.25, 0.5),
			alignItems: "center",
			height: `calc(1.5rem + ${theme.spacing(3)})`,
			width: "100%",
		},
	})
);

const StudentResultsActionToolbar = (props: {
	actionInProgress: {[key in "markAssessed" | "markUnassessed"]?: boolean};
	onMarkAssessed: () => Promise<void>;
	onMarkUnassessed: () => Promise<void>;
}): JSX.Element => {
	const classes = useStyles();

	return (
		<Toolbar className={classes.root}>
			<Box className={classes.buttonsPanel}>
				<SubmitButton
					variant="outlined"
					inProgress={props.actionInProgress.markAssessed}
					disabled={props.actionInProgress.markUnassessed}
					onClick={props.onMarkAssessed}
				>
					<Localized id="follow-up-student-results-widget-mark-assessed-btn">
						Mark as assessed
					</Localized>
				</SubmitButton>
				<SubmitButton
					variant="outlined"
					inProgress={props.actionInProgress.markUnassessed}
					disabled={props.actionInProgress.markAssessed}
					onClick={props.onMarkUnassessed}
				>
					<Localized id="follow-up-student-results-widget-mark-not-assessed-btn">
						Mark as not assessed
					</Localized>
				</SubmitButton>
			</Box>
		</Toolbar>
	);
};

export default StudentResultsActionToolbar;
