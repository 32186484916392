import {OpenExerciseSubtype} from "../../../../store/exercises/ExerciseSubtype";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import type {OpenResponse} from "../../../../store/studentResponses/Response";

function makeResponse(
	subtype: OpenExerciseSubtype,
	solution: string,
	finalAnswer: string
): OpenResponse {
	let response: OpenResponse;

	switch (subtype) {
		case OpenExerciseSubtype.Ordinary:
			response = {
				exerciseType: ExerciseType.Open,
				exerciseSubtype: subtype,
				solution: solution,
			};

			break;
		case OpenExerciseSubtype.AutoAssessed:
			response = {
				exerciseType: ExerciseType.Open,
				exerciseSubtype: subtype,
				solution: solution,
				finalAnswer: finalAnswer,
			};

			break;
		default:
			throw new Error(`unsupported open exercise subtype $(props.subtype)`);
	}

	return response;
}

export default makeResponse;
