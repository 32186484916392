import ComparisonStrictness from "../../exercises/ComparisonStrictness";
import type {ExerciseWithContent} from "../../exercises/Exercise";
import type ExercisePrivacy from "../../exercises/ExercisePrivacy";
import type {
	ExternalExerciseSettings,
	OpenExerciseSettings,
	ShortAnswerExerciseSettings,
} from "../../exercises/ExerciseSettings";
import type {
	MathExerciseSubtype,
	MultiExerciseSubtype,
} from "../../exercises/ExerciseSubtype";
import ExerciseType from "../../exercises/ExerciseType";
import type {ProgInteractions} from "../../exercises/Interactions";
import type DbExerciseSolutionType from "../../exercises/prog/DbExerciseSolutionType";
import type TableSchema from "../../exercises/prog/TableSchema";

type Exercise = {
	title: string;
	category: string;
	question: string;
	solution: string;
	difficultyLevel: number;
	tags: string[];

	readonly authorId?: number;
	readonly originId?: number;
};

export type OpenExercise = Exercise & {
	type: ExerciseType.Open;
	language: string;
	privacyLevel: ExercisePrivacy;
	maxScore: number;
	settings: OpenExerciseSettings;
};

type MultiExercise = Exercise & {
	type: ExerciseType.Multi;
	contentLanguage: string;
	privacy: ExercisePrivacy;
	choices: {id: number; text: string; score: number; comment: string}[];
	subtype: MultiExerciseSubtype;
};

type MathExercise = Exercise & {
	type: ExerciseType.Math;
	contentLanguage: string;
	privacy: ExercisePrivacy;
	subtype: MathExerciseSubtype;
	finalAnswer: string[];
	expression: string;
	expressionVisible: boolean;
	variable?: string;
	maxScore: number;
};

export type SupportedLanguage =
	| "cpp"
	| "c"
	| "cs"
	| "dart"
	| "java"
	| "js"
	| "kotlin"
	| "php"
	| "python"
	| "ruby"
	| "rust"
	| "scala"
	| "sql";

type ProgExerciseBase = Exercise & {
	type: ExerciseType.Prog;
	contentLanguage: string;
	privacy: ExercisePrivacy;
	maxScore: number;
	files: {
		name: string;
		content: string;
		readOnlyFromBeginning?: number;
		readOnlyFromEnd?: number;
	}[];
	language: SupportedLanguage;
	additionalFiles: string[];
	mainFile: string;
	command?: string;
	comparisonStrictness: ComparisonStrictness;
	initialSchemaAndState: TableSchema;
	requiredWords: string;
	prohibitedWords: string;
	exampleOutput: string;
};

type ProgrammingExercise = ProgExerciseBase & {
	language: Exclude<SupportedLanguage, "sql">;
};

type SqlExercise = ProgExerciseBase & {
	language: "sql";
	subtype: DbExerciseSolutionType;
};

export type ProgExercise = ProgrammingExercise | SqlExercise;

export type ExternalExercise = Exercise & {
	type: ExerciseType.External;
	maxScore: number;
	language: string;
	privacyLevel: ExercisePrivacy;
	settings: ExternalExerciseSettings;
};

export type ShortAnswerExercise = Exercise & {
	type: ExerciseType.Short;
	maxScore: number;
	language: string;
	privacyLevel: ExercisePrivacy;
	settings: ShortAnswerExerciseSettings;
};

export type EditableExercise =
	| ExternalExercise
	| MathExercise
	| MultiExercise
	| OpenExercise
	| ProgExercise
	| ShortAnswerExercise;

export function mapToExerciseWithContent(
	exerciseId: number,
	exercise: EditableExercise
): ExerciseWithContent {
	const common = {
		id: exerciseId,
		title: exercise.title,
		question: exercise.question,
		difficultyLevel: exercise.difficultyLevel,
		category: exercise.category,
		authorId: exercise.authorId,
		originId: exercise.originId,
	};

	switch (exercise.type) {
		case ExerciseType.External:
			return {
				...common,
				type: ExerciseType.External,
				subtype: exercise.settings.subtype,
				interactions: {
					contentId: exercise.settings.contentId ?? "",
				},
			};

		case ExerciseType.Math:
			return {
				...common,
				type: ExerciseType.Math,
				interactions: {
					expression: exercise.expressionVisible
						? exercise.expression
						: undefined,
				},
				subtype: exercise.subtype,
			};

		case ExerciseType.Multi:
			return {
				...common,
				type: ExerciseType.Multi,
				interactions: {
					choices: exercise.choices.map((ch) => ({
						id: ch.id,
						text: ch.text,
					})),
				},
				subtype: exercise.subtype,
			};

		case ExerciseType.Open:
			return {
				...common,
				type: ExerciseType.Open,
				subtype: exercise.settings.subtype,
			};

		case ExerciseType.Prog: {
			const files = exercise.files
				.filter((f) => f.name !== "InitialSchema.sql")
				.map((f) => {
					const file: ProgInteractions["files"][0] = {
						name: f.name,
					};

					if (f.readOnlyFromBeginning) {
						const i = nthIndex(f.content, "\n", f.readOnlyFromBeginning);

						file.readOnlyBeginning = f.content.substring(0, i + 1);
					}

					if (f.readOnlyFromEnd) {
						const i = nthIndex(f.content, "\n", -f.readOnlyFromEnd);

						file.readOnlyEnd = f.content.substring(i + 1);
					}

					return file;
				});

			return {
				...common,
				type: ExerciseType.Prog,
				interactions: {
					comparisonStrictness: exercise.comparisonStrictness,
					exampleOutput: exercise.exampleOutput,
					files: files,
					initialSchemaAndState: exercise.initialSchemaAndState,
					language: exercise.language,
				},
			};
		}
		case ExerciseType.Short:
			return {
				...common,
				type: ExerciseType.Short,
			};
		default:
			throw new Error("Unexpected exercise type");
	}
}

function nthIndex(str: string, substr: string, n: number) {
	let i = -1;

	if (n > 0) {
		do {
			i = str.indexOf(substr, i + 1);
			n--;
		} while (i !== -1 && n > 0);
	} else if (n < 0) {
		i = str.length;

		do {
			i = str.lastIndexOf(substr, i - 1);
			n++;
		} while (i !== -1 && n < 0);
	}

	return i;
}
