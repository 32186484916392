import React, {ReactNode} from "react";

const Condition = (props: {
	value: boolean;
	children: ReactNode;
	otherwise?: ReactNode;
	pending?: boolean;
	placeHolder?: ReactNode;
}): JSX.Element => {
	if (props.pending) {
		return <>{props.placeHolder}</>;
	}

	return <>{props.value ? props.children : props.otherwise}</>;
};

export default Condition;
