import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import createChapter from "../chapters/createChapter";
import deleteChapter from "../chapters/deleteChapter";
import {getChapters} from "../chapters/getChapters";
import deleteCourses from "../courses/deleteCourses";
import updateChaptersOrder from "../chapters/updateChaptersOrder";
import {keyProvider} from "../keyProvider";

type State = {
	byKey: {
		[key: string]: {editing: boolean} | undefined;
	};
};

const initialState: State = {
	byKey: {},
};

function updateState(value: {editing: boolean}) {
	return function (
		state: State,
		action: PayloadAction<unknown, string, {arg: {courseId: number}}>
	) {
		const courseKey = keyProvider.course(action.meta.arg.courseId);
		state.byKey[courseKey] = value;
	};
}

export const courseEditingSlice = createSlice({
	name: "courseEditing",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		const actions = [updateChaptersOrder, createChapter, deleteChapter];

		actions.forEach((action) => {
			builder.addCase(action.pending, updateState({editing: true}));
			builder.addCase(action.fulfilled, updateState({editing: false}));
			builder.addCase(action.rejected, updateState({editing: false}));
		});

		builder.addCase(getChapters.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg);
			state.byKey[courseKey] = {editing: true};
		});
		builder.addCase(getChapters.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg);
			state.byKey[courseKey] = {editing: false};
		});
		builder.addCase(getChapters.fulfilled, (state, action) => {
			const courseKey = action.payload.result.courseKey;
			state.byKey[courseKey] = {editing: false};
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				delete state.byKey[keyProvider.course(id)];
			});
		});
	},
});

export default courseEditingSlice.reducer;
