import {normalize, schema} from "normalizr";

import ChapterExercise from "../chapterExercises/ChapterExercise";
import Exercise from "../exercises/Exercise";
import ExerciseType from "../exercises/ExerciseType";
import {keyProvider} from "../keyProvider";
import Section from "../sections/Section";
import * as dto from "../services/dtos/ChapterContent";

type NormalisedChapterContent = {
	chapters: {
		[key: string]: {
			id: number;
			sections: string[];
		};
	};
	sections: {
		[key: string]: Section;
	};
	chapterExercises: {
		[key: string]: ChapterExercise;
	};
	exercises: {
		[key: string]: Exercise;
	};
};

export function normaliseChapterContent(
	chapterId: number,
	content: dto.ChapterContent
): NormalisedChapterContent {
	const normalized = normalize<
		unknown,
		{
			chapters: {[key: string]: {id: number; sections: string[]}};
			sections: {[key: string]: Section};
			chapterExercises: {[key: string]: ChapterExercise};
			exercises: {[key: string]: Exercise};
		}
	>(content, chapter);

	const entities =
		content.sections.length === 0
			? {
					chapters: {
						[keyProvider.chapter(chapterId)]: {
							id: chapterId,
							sections: [],
						},
					},
					sections: {},
					chapterExercises: {},
					exercises: {},
			  }
			: normalized.entities;

	return entities;
}

export default NormalisedChapterContent;

const exercise = new schema.Entity<dto.Exercise>(
	"exercises",
	{},
	{
		idAttribute: (value: Exercise) => keyProvider.exercise(value.id),
	}
);

const chapterExercise = new schema.Entity(
	"chapterExercises",
	{
		exercise: exercise,
	},
	{
		idAttribute: (value: dto.ChapterExercise) =>
			keyProvider.chapterExercise(value.chapterId, value.id),
		processStrategy: (value: dto.ChapterExercise, parent: {id: number}) => ({
			chapterId: value.chapterId,
			number: value.number,
			maxScore: value.maxScore,
			answerVisibility: value.answerVisibility,
			sectionId: parent.id,
			exercise: {
				id: value.id,
				type: value.type,
				title: value.title,
				difficultyLevel: value.difficultyLevel,
				authorId: value.authorId,
				originId: value.originId,
			},
		}),
	}
);

const section = new schema.Entity(
	"sections",
	{
		exercise: chapterExercise,
		subsections: [chapterExercise],
	},
	{
		idAttribute: (value: dto.Section) =>
			keyProvider.section(value.chapterId, value.id),
		processStrategy: (value: dto.Section) => ({
			id: value.id,
			chapterId: value.chapterId,
			exercise: {
				id: value.id,
				chapterId: value.chapterId,
				type: ExerciseType.Theory,
				title: value.title,
				number: value.number,
				originId: value.originId,
				authorId: value.authorId,
			},
			selectionSize: value.selectionSize,
			maxScorePerExercise: value.maxScorePerExercise,
			subsections: value.subsections,
			subsectionIds: value.subsections.map((s) => s.id),
		}),
	}
);

const chapter = new schema.Entity<dto.ChapterContent>(
	"chapters",
	{
		sections: [section],
	},
	{
		idAttribute: (value: dto.ChapterContent) => keyProvider.chapter(value.id),
	}
);
