import {useLocalization} from "@fluent/react";
import React, {useEffect} from "react";

import {getChapterContent} from "../../store/chapters/getChapterContent";
import {saveTheoryDraft} from "../../store/exercises/saveTheoryDraft";
import {editingEnded} from "../../store/exercises/exericseDraftSlice";
import {useAppDispatch} from "../../store/hooks";
import TitleEditor from "./TitleEditor";

type Props = {
	setEdit: (value: boolean) => void;
	exercise: {
		title: string;
		content: string | undefined;
	};

	courseId: number;
	chapterId: number;
	sectionId: number;
};

const SectionTitleEditor = (props: Props): JSX.Element => {
	const {
		exercise,
		setEdit: setSectionTitleEdit,
		courseId,
		chapterId,
		sectionId,
	} = props;

	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(editingEnded());
		};
	}, [dispatch]);

	const save = async (title: string) => {
		await dispatch(
			saveTheoryDraft({
				courseId,
				chapterId,
				sectionId,
				exerciseItem: {
					title,
					content: exercise?.content ?? "",
				},
			})
		);
		await dispatch(getChapterContent({courseId, chapterId}));
		setSectionTitleEdit(false);
	};

	const {l10n} = useLocalization();
	return (
		<TitleEditor
			label={l10n.getString(
				"learning-material-section-title-editor-label",
				null,
				"Section title"
			)}
			onSave={save}
			onCancel={() => setSectionTitleEdit(false)}
			initialValue={exercise?.title ?? ""}
		/>
	);
};

export default SectionTitleEditor;
