import {RootState} from "../store";
import Response from "./Response";

function selectStudentResponse(
	state: RootState,
	chapterExerciseKey: string
): Readonly<Response> | null {
	return state.studentResponses.byKey[chapterExerciseKey] ?? null;
}

export default selectStudentResponse;
