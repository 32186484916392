import {Localized, useLocalization} from "@fluent/react";
import React, {useCallback, useEffect, useState} from "react";
import CreateIcon from "@mui/icons-material/Create";
import {
	Box,
	Button,
	Dialog,
	Divider,
	FormControlLabel,
	Grid,
	Hidden,
	IconButton,
	InputAdornment,
	MenuItem,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";

import AnswerVisibilitySelector from "./AnswerVisibilitySelector";
import TitleEditor from "./TitleEditor";
import ChapterStatusBadge from "../dragdrop/ChapterStatusBadge";
import selectChapter from "../../store/chapters/selectChapter";
import createPublishChapterAction from "./createPublishChapterAction";
import createUnpublishChapterAction from "./createUnpublishChapterAction";
import useSnackbar from "../../store/ui/useSnackbar";
import updateChapter from "../../store/chapters/updateChapter";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import ScheduleChapterDialog from "./ScheduleChapterDialog";
import parseDate from "../../helpers/parseDate";
import ConfirmationFooter from "../../utils/ConfirmationFooter";
import {unwrapResult} from "@reduxjs/toolkit";
import {
	convertLocalTimeToUTC,
	formatToLocalTimeZone,
} from "../../helpers/dateTimeHelpers";
import selectCourse from "../../store/courses/selectCourse";
import CourseType from "../../store/courses/CourseType";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";
import AnswerVisibility from "../../store/chapterExercises/AnswerVisibility";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import {SelectionModes} from "../../store/chapters/SelectionMode";
import createDeleteChapterAction from "./createDeleteChapterAction";
import {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import type FeedbackVisibility from "../../store/chapters/FeedbackVisibility";
import {FeedbackVisibilities} from "../../store/chapters/FeedbackVisibility";
import selectCourseEditing from "../../store/ui/selectCourseEditing";
import selectChapterEditing from "../../store/ui/selectChapterEditing";

const useStyles = makeStyles((theme) =>
	createStyles({
		icon: {
			color: theme.palette.text.secondary,
		},
		checkIcon: {
			color: theme.palette.success.main,
		},
		endDateSwitch: {
			marginLeft: -11,
		},
	})
);

type Draft = {
	endDate: string;
	sessionLimit: number;
	timeLimit: number;
	answerVisibility: AnswerVisibility | "";
	feedbackVisibility: FeedbackVisibility;
};

function ChapterManagement(props: {
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
}): JSX.Element {
	const {viewOnly} = props;

	const {l10n} = useLocalization();
	const classes = useStyles();

	const [featureEnabled] = useFeatureEnabled();
	const practiceTestsEnabled = featureEnabled(Feature.PracticeTests);

	const params = useParams<{
		chapterId: string;
		id: string;
	}>();
	const chapterId = Number(params.chapterId);
	const courseId = Number(params.id);

	const courseKey = keyProvider.course(courseId);
	const chapterKey = keyProvider.chapter(chapterId);

	const courseEditing = useAppSelector((state) =>
		selectCourseEditing(state, courseKey)
	);

	const chapterEditing = useAppSelector((state) =>
		selectChapterEditing(state, chapterKey)
	);

	const [modalOpen, setModalOpen] = useState(false);
	const modalClose = useCallback(() => setModalOpen(false), []);

	const showSnackbar = useSnackbar();

	const [editingTitle, setEditingTitle] = useState(false);

	const [enableEndDate, setEnableEndDate] = useState(false);

	const [initialDraft, setInitialDraft] = useState<Draft | null>(null);
	const [draft, setDraft] = useState<Draft | null>(null);

	const courseAnswerVisibility = useAppSelector(
		(state) =>
			selectCourse(state, keyProvider.course(courseId))?.answerVisibility ??
			AnswerVisibility.Never
	);

	const [saving, setSaving] = useState(false);
	const [dirty, setDirty] = useState(false);

	const chapter = useAppSelector((state) =>
		selectChapter(state, keyProvider.chapter(chapterId))
	);

	const courseType = useAppSelector(
		(state) => selectCourse(state, keyProvider.course(courseId))?.type
	);

	useEffect(() => {
		setDirty(false);
		setEditingTitle(false);
	}, [chapterId]);

	useEffect(() => {
		if (chapter && !dirty) {
			const init: Draft = {
				endDate: chapter.endDate ? formatToLocalTimeZone(chapter.endDate) : "",
				sessionLimit: chapter.sessionLimit ?? 0,
				timeLimit: chapter.timeLimit ? Math.floor(chapter.timeLimit / 60) : 0,
				answerVisibility: chapter.answerVisibility ?? "",
				feedbackVisibility: chapter.feedbackVisibility,
			};
			setDraft(init);
			setInitialDraft(init);

			setEnableEndDate(Boolean(chapter.endDate));
		}
	}, [chapter, dirty]);

	const dispatch = useAppDispatch();

	const history = useHistory();

	const {url: baseUrl} = useRouteMatch();

	if (!chapter || !initialDraft || !draft) {
		return <></>;
	}

	const addSectionHandler = () => {
		history.push(baseUrl + "/sections/new");
	};

	const attemptsSettingsVisible =
		practiceTestsEnabled &&
		(chapter.submissionMode === SubmissionMode.Simultaneous ||
			chapter.selectionMode === SelectionModes.Random);

	const cancelTitleChange = () => {
		setEditingTitle(false);
	};

	const saveTitle = async (title: string) => {
		await dispatch(updateChapter({courseId, chapterId, title}));
		setEditingTitle(false);
	};

	const deleteChapter = createDeleteChapterAction(
		courseId,
		dispatch,
		props.openConfirmDialog
	);

	const publishChapter = createPublishChapterAction(
		courseId,
		l10n,
		dispatch,
		showSnackbar
	);

	const unpublishChapter = createUnpublishChapterAction(
		courseId,
		l10n,
		dispatch,
		showSnackbar
	);

	const updateEndDate = (value: string) => {
		setDraft((prev) => {
			const oldDraft = prev ?? initialDraft;
			return {...oldDraft, endDate: value};
		});

		setDirty(
			value !== initialDraft.endDate ||
				draft.sessionLimit !== initialDraft.sessionLimit ||
				draft.timeLimit !== initialDraft.timeLimit ||
				draft.answerVisibility !== initialDraft.answerVisibility ||
				draft.feedbackVisibility !== initialDraft.feedbackVisibility
		);
	};

	const updateSessionLimit = (value: number) => {
		if (viewOnly) {
			return;
		}

		setDraft((prev) => {
			const oldDraft = prev ?? initialDraft;
			return {...oldDraft, sessionLimit: value};
		});

		setDirty(
			value !== initialDraft.sessionLimit ||
				draft.endDate !== initialDraft.endDate ||
				draft.timeLimit !== initialDraft.timeLimit ||
				draft.answerVisibility !== initialDraft.answerVisibility ||
				draft.feedbackVisibility !== initialDraft.feedbackVisibility
		);
	};

	const updateTimeLimit = (value: number) => {
		if (viewOnly) {
			return;
		}

		setDraft((prev) => {
			const oldDraft = prev ?? initialDraft;
			return {...oldDraft, timeLimit: value};
		});

		setDirty(
			value !== initialDraft.timeLimit ||
				draft.sessionLimit !== initialDraft.sessionLimit ||
				draft.endDate !== initialDraft.endDate ||
				draft.answerVisibility !== initialDraft.answerVisibility ||
				draft.feedbackVisibility !== initialDraft.feedbackVisibility
		);
	};

	const updateAnswerVisibility = (value: AnswerVisibility | "") => {
		setDraft((prev) => {
			const oldDraft = prev ?? initialDraft;
			return {...oldDraft, answerVisibility: value};
		});

		setDirty(
			value !== initialDraft.answerVisibility ||
				draft.endDate !== initialDraft.endDate ||
				draft.sessionLimit !== initialDraft.sessionLimit ||
				draft.timeLimit !== initialDraft.timeLimit ||
				draft.feedbackVisibility !== initialDraft.feedbackVisibility
		);
	};

	const updateFeedbackVisibility = (value: FeedbackVisibility) => {
		setDraft((prev) => {
			const oldDraft = prev ?? initialDraft;
			return {...oldDraft, feedbackVisibility: value};
		});

		setDirty(
			value !== initialDraft.feedbackVisibility ||
				draft.endDate !== initialDraft.endDate ||
				draft.sessionLimit !== initialDraft.sessionLimit ||
				draft.timeLimit !== initialDraft.timeLimit ||
				draft.answerVisibility !== initialDraft.answerVisibility
		);
	};

	const save = async () => {
		setSaving(true);
		const res = await dispatch(
			updateChapter({
				courseId,
				chapterId,
				endDate:
					draft.endDate === "" ? null : convertLocalTimeToUTC(draft.endDate),
				sessionLimit: draft.sessionLimit === 0 ? null : draft.sessionLimit,
				timeLimit: draft.timeLimit === 0 ? null : draft.timeLimit * 60,
				answerVisibility:
					draft.answerVisibility === "" ? null : draft.answerVisibility,
				feedbackVisibility: draft.feedbackVisibility,
			})
		);

		setSaving(false);

		unwrapResult(res);

		setDirty(false);
	};

	function PublicationActions() {
		if (!chapter?.startDate) {
			return (
				<>
					<Grid item>
						<Button
							color="primary"
							disabled={chapterEditing}
							onClick={() => publishChapter(chapterId)}
						>
							<Localized id="learning-material-button-publish">
								Publish
							</Localized>
						</Button>
					</Grid>
					<Grid item>
						<Button
							color="primary"
							disabled={chapterEditing}
							onClick={() => setModalOpen(true)}
						>
							<Localized id="learning-material-button-schedule-publication">
								Schedule publication
							</Localized>
						</Button>
					</Grid>
				</>
			);
		}

		if (new Date() < parseDate(chapter.startDate)) {
			return (
				<>
					<Grid item>
						<Button
							color="primary"
							disabled={chapterEditing}
							onClick={() => publishChapter(chapterId)}
						>
							<Localized id="learning-material-button-publish-now">
								Publish now
							</Localized>
						</Button>
					</Grid>
					<Grid item>
						<Button
							color="primary"
							disabled={chapterEditing}
							onClick={() => unpublishChapter(chapterId)}
						>
							<Localized id="learning-material-button-cancel-schedule">
								Cancel schedule
							</Localized>
						</Button>
					</Grid>
				</>
			);
		}

		return (
			<Grid item>
				<Button
					color="primary"
					disabled={chapterEditing}
					onClick={() => unpublishChapter(chapterId)}
				>
					<Localized id="learning-material-button-unpublish">
						Unpublish
					</Localized>
				</Button>
			</Grid>
		);
	}

	return (
		<Box mb={dirty ? 15 : 3}>
			<Box mb={3}>
				{editingTitle ? (
					<TitleEditor
						label={l10n.getString("learning-material-form-label-chapter-title")}
						onSave={saveTitle}
						onCancel={cancelTitleChange}
						initialValue={chapter.title}
					/>
				) : (
					<Box display="flex" alignItems="flex-start" style={{gap: 16}}>
						<Typography variant="h4" color="textSecondary">
							{chapter.number}
						</Typography>
						<Typography variant="h4" color="textPrimary">
							{chapter.title}
							{!viewOnly && (
								<IconButton onClick={() => setEditingTitle(true)}>
									<CreateIcon className={classes.icon} />
								</IconButton>
							)}
						</Typography>
					</Box>
				)}
			</Box>
			<Grid container alignItems="center" spacing={3}>
				<Grid item>
					<ChapterStatusBadge startDate={chapter.startDate} size="medium" />
				</Grid>
				{!viewOnly && <PublicationActions />}
			</Grid>

			<Box mt={4} />

			<Grid container spacing={3} direction="column">
				<Grid item container spacing={3}>
					{(courseType === CourseType.Exam || practiceTestsEnabled) && (
						<Grid item xs={12} md={6} xl={4}>
							<TextField
								select
								label={
									<Localized id="learning-material-form-label-submission-mode">
										Submission mode
									</Localized>
								}
								value={chapter.submissionMode}
								fullWidth
								disabled
							>
								<MenuItem value={SubmissionMode.Individual}>
									<Localized id="learning-material-form-label-submission-mode-individual">
										Individual
									</Localized>
								</MenuItem>
								<MenuItem value={SubmissionMode.Simultaneous}>
									<Localized id="learning-material-form-label-submission-mode-simultaneous">
										Simultaneous
									</Localized>
								</MenuItem>
							</TextField>
						</Grid>
					)}
					{practiceTestsEnabled && (
						<Grid item xs={12} md={6} xl={4}>
							<TextField
								select
								label={
									<Localized id="learning-material-form-label-selection-mode">
										Selection mode
									</Localized>
								}
								value={chapter.selectionMode}
								fullWidth
								disabled
							>
								<MenuItem value={SelectionModes.All}>
									<Localized id="learning-material-form-label-selection-mode-all">
										All
									</Localized>
								</MenuItem>
								<MenuItem value={SelectionModes.Random}>
									<Localized id="learning-material-form-label-selection-mode-random">
										Random
									</Localized>
								</MenuItem>
							</TextField>
						</Grid>
					)}
				</Grid>
				{courseType === CourseType.Exam && (
					<Grid item>
						<Box display="flex" alignItems="flex-end">
							<Switch
								checked={enableEndDate}
								onChange={(event) => {
									setEnableEndDate(event.target.checked);
									updateEndDate(
										event.target.checked ? initialDraft.endDate : ""
									);
								}}
								inputProps={{
									"aria-label": "Set end date",
								}}
								classes={{root: classes.endDateSwitch}}
								disabled={viewOnly}
							/>

							<TextField
								type="datetime-local"
								label="End date"
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{readOnly: viewOnly}}
								disabled={!enableEndDate}
								value={draft.endDate}
								onChange={(event) => updateEndDate(event.target.value)}
							/>
						</Box>
					</Grid>
				)}
				{attemptsSettingsVisible && (
					<>
						<Grid item container spacing={3} alignItems="flex-end">
							<Grid item xs={12} md={6} xl={4}>
								<FormControlLabel
									label={
										<Localized id="learning-material-chapter-editor-limit-attempts-switch">
											Limit attempts
										</Localized>
									}
									control={
										<Switch
											checked={draft.sessionLimit > 0}
											onChange={(event) => {
												updateSessionLimit(
													event.target.checked
														? initialDraft.sessionLimit || 1
														: 0
												);
											}}
											disabled={courseType === CourseType.Exam}
										/>
									}
								/>
							</Grid>
							<Grid item xs={12} md xl={4}>
								<Hidden lgDown={!draft.sessionLimit}>
									<TextField
										label={
											<Localized id="learning-material-chapter-editor-attempts-field">
												Number of attempts
											</Localized>
										}
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputProps: {min: 1},
											readOnly: viewOnly,
										}}
										onChange={(event) => {
											const value = parseInt(event.target.value);
											if (value > 0) {
												updateSessionLimit(value);
											}
										}}
										value={draft.sessionLimit}
										fullWidth
										style={{
											visibility: !draft.sessionLimit ? "hidden" : "visible",
										}}
										disabled={courseType === CourseType.Exam}
									/>
								</Hidden>
							</Grid>
						</Grid>
						<Grid item container spacing={3} alignItems="flex-end">
							<Grid item xs={12} md={6} xl={4}>
								<FormControlLabel
									label={
										<Localized id="learning-material-chapter-editor-time-limit-switch">
											Limit attempt duration
										</Localized>
									}
									control={
										<Switch
											checked={draft.timeLimit > 0}
											onChange={(event) => {
												updateTimeLimit(
													event.target.checked
														? initialDraft.timeLimit || 10
														: 0
												);
											}}
										/>
									}
								/>
							</Grid>
							<Grid item xs={12} md xl={4}>
								<Hidden mdDown={!draft.timeLimit}>
									<TextField
										label={
											<Localized id="learning-material-chapter-editor-time-limit-field">
												Time limit
											</Localized>
										}
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											inputProps: {min: 1, max: 1440},
											endAdornment: (
												<InputAdornment position="end">
													<Localized id="learning-material-chapter-editor-time-limit-field-units">
														min.
													</Localized>
												</InputAdornment>
											),
											readOnly: viewOnly,
										}}
										onChange={(event) => {
											const value = parseInt(event.target.value);
											if (value > 0) {
												updateTimeLimit(value);
											}
										}}
										value={draft.timeLimit}
										fullWidth
										style={{
											visibility: !draft.timeLimit ? "hidden" : "visible",
										}}
									/>
								</Hidden>
							</Grid>
						</Grid>
					</>
				)}
				<Grid item container spacing={3}>
					{practiceTestsEnabled && (
						<Grid item xs={12} md={6} xl={4}>
							<AnswerVisibilitySelector
								courseType={courseType}
								inheritedValue={courseAnswerVisibility}
								value={draft.answerVisibility}
								readonly={viewOnly}
								onChange={updateAnswerVisibility}
							/>
						</Grid>
					)}
					<Grid item xs={12} md={6} xl={4}>
						<TextField
							select
							label={
								<Localized id="learning-material-chapter-editor-feedback-visibility-label">
									Feedback visibility
								</Localized>
							}
							value={draft.feedbackVisibility}
							onChange={(event) =>
								updateFeedbackVisibility(
									event.target.value as FeedbackVisibility
								)
							}
							fullWidth
							InputProps={{readOnly: viewOnly}}
						>
							<MenuItem value={FeedbackVisibilities.Hidden}>
								<Localized id="learning-material-chapter-editor-feedback-visibility-hidden">
									Hidden
								</Localized>
							</MenuItem>
							<MenuItem value={FeedbackVisibilities.Visible}>
								<Localized id="learning-material-chapter-editor-feedback-visibility-visible">
									Visible
								</Localized>
							</MenuItem>
						</TextField>
					</Grid>
				</Grid>
			</Grid>

			{!viewOnly && (
				<Box mt={4} mb={3}>
					<Divider />
				</Box>
			)}

			{!viewOnly && (
				<Grid container justifyContent="space-between">
					<Grid item>
						<Button
							color="primary"
							disabled={courseEditing || chapterEditing}
							onClick={() => deleteChapter(chapterId, chapter.title)}
						>
							<Localized id="learning-material-chapter-management-button-delete">
								Delete
							</Localized>
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={addSectionHandler}
							disabled={chapter.structureType !== "sections" || chapterEditing}
						>
							<Localized id="learning-material-chapter-management-button-add-section">
								Add section
							</Localized>
						</Button>
					</Grid>
				</Grid>
			)}
			<Dialog open={modalOpen} onClose={modalClose} maxWidth="xs" fullWidth>
				<ScheduleChapterDialog
					courseId={courseId}
					chapterId={chapterId}
					onClose={modalClose}
				/>
			</Dialog>

			{dirty && (
				<ConfirmationFooter
					onDiscard={() => setDirty(false)}
					onSave={save}
					saveInProgress={saving}
				/>
			)}
		</Box>
	);
}

export default ChapterManagement;
