import {useLocalization} from "@fluent/react";
import CodeIcon from "@mui/icons-material/Code";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import ExtensionIcon from "@mui/icons-material/Extension";
import {Link} from "@mui/material";
import React from "react";

import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppSelector} from "../../store/hooks";
import selectCourse from "../../store/courses/selectCourse";
import {keyProvider} from "../../store/keyProvider";
import MathExerciseIcon from "../../icons/MathExerciseIcon";
import MultipleChoiseExcerciseIcon from "../../icons/MultipleChoiceExerciseIcon";
import OpenExerciseIcon from "../../icons/OpenExerciseIcon";
import DialogMenuItem from "./DialogMenuItem";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";

const ExerciseTypeSelector = (props: {
	courseId: number;
	chapterId: number;
	sectionId: number;
	onExerciseTypeSelected: (type: ExerciseType) => void;
}): JSX.Element => {
	const {courseId, chapterId, sectionId} = props;

	const [featureEnabled] = useFeatureEnabled();
	const externalEnabled = featureEnabled(Feature.ExternalExercises);

	const {availableExerciseTypes} = useAppSelector(
		(state) =>
			selectCourse(state, keyProvider.course(courseId)) || {
				availableExerciseTypes: [] as ExerciseType[],
			}
	);

	const params = new URLSearchParams();
	params.append("chapterId", `${chapterId}`);
	params.append("sectionId", `${sectionId}`);

	const {l10n} = useLocalization();

	const exerciseModalData = [
		{
			type: ExerciseType.Open,
			image: <OpenExerciseIcon color="action" fontSize="small" />,
			header: l10n.getString(
				"add-exercise-dialog-menu-item-link-open-exercise",
				null,
				"OPEN EXERCISE"
			),
			subheader: l10n.getString(
				"add-exercise-dialog-menu-item-subheader-open-exercise",
				null,
				"Exercise with an open answer form"
			),
		},
		{
			type: ExerciseType.Prog,
			image: <CodeIcon color="action" fontSize="small" />,
			header: l10n.getString(
				"add-exercise-dialog-menu-item-link-programming",
				null,
				"PROGRAMMING EXERCISE"
			),
			subheader: l10n.getString(
				"add-exercise-dialog-menu-item-subheader-programming",
				null,
				"Exercise for creating and verifying code"
			),
		},
		{
			type: ExerciseType.Math,
			image: <MathExerciseIcon color="action" fontSize="small" />,
			header: l10n.getString(
				"add-exercise-dialog-menu-item-link-math",
				null,
				"MATH EXERCISE"
			),
			subheader: l10n.getString(
				"add-exercise-dialog-menu-item-subheader-math",
				null,
				"Math exercise"
			),
		},
		{
			type: ExerciseType.Multi,
			image: <MultipleChoiseExcerciseIcon color="action" fontSize="small" />,
			header: l10n.getString(
				"add-exercise-dialog-menu-item-link-multi-choice",
				null,
				"MULTIPLE CHOICE EXERCISE"
			),
			subheader: l10n.getString(
				"add-exercise-dialog-menu-item-subheader-multi-choice",
				null,
				"Exercise with a choice of several answers"
			),
		},
		{
			type: ExerciseType.Theory,
			image: <ArtTrackIcon color="action" fontSize="small" />,
			header: l10n.getString(
				"add-exercise-dialog-menu-item-link-text-block",
				null,
				"TEXT BLOCK"
			),
			subheader: l10n.getString(
				"add-exercise-dialog-menu-item-subheader-text-block",
				null,
				"Block with text, images, videos"
			),
		},
		{
			type: ExerciseType.External,
			image: <ExtensionIcon color="action" fontSize="small" />,
			header: l10n.getString(
				"add-exercise-dialog-menu-item-link-external",
				null,
				"EXTERNAL EXERCISE"
			),
			subheader: l10n.getString(
				"add-exercise-dialog-menu-item-subheader-external",
				null,
				"Exercise with interactive content built with H5P"
			),
		},
	].filter(
		(data) =>
			(data.type !== ExerciseType.External || externalEnabled) &&
			availableExerciseTypes.includes(data.type)
	);

	return (
		<>
			{exerciseModalData.map((exercise) => {
				const linkProps = {
					onClick: () => props.onExerciseTypeSelected(exercise.type),
					style: {cursor: "pointer"},
				};

				return (
					<DialogMenuItem
						mb={3}
						ml={2}
						key={exercise.type}
						subheader={exercise.subheader}
						mainContent={<Link {...linkProps}>{exercise.header}</Link>}
						icon={exercise.image}
					/>
				);
			})}
		</>
	);
};

export default ExerciseTypeSelector;
