import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

function selectCourseStatsFetchStatus(
	state: RootState,
	courseKey: string
): FetchStatus {
	return state.courseStatistics.byKey[courseKey]?.status ?? "none";
}

export default selectCourseStatsFetchStatus;
