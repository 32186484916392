import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import type NormalisedChapterContent from "../chapters/NormalisedChapterContent.ts";
import {normaliseChapterContent} from "../chapters/NormalisedChapterContent.ts";
import {RootState} from "../store";
import selectChapterSession from "./selectChapterSession";
import chapterSessionsService from "../services/chapterSessionsService";

export const fetchChapterSessionContent = createAsyncThunk<
	{
		courseId: number;
		courseKey: string;
		chapterId: number;
		sessionKey: string;
		entities: NormalisedChapterContent;
	},
	{
		courseId: number;
		chapterId: number;
		sessionKey: string;
		studentId: number;
	},
	{state: RootState}
>(
	"chapterSessions/fetchContent",
	async ({courseId, chapterId, studentId, sessionKey}, thunkApi) => {
		const session = selectChapterSession(thunkApi.getState(), sessionKey);
		if (!session) {
			throw new Error(`Chapter session ${sessionKey} is not found`);
		}

		const content = await chapterSessionsService.getChapterSessionContent(
			studentId,
			courseId,
			chapterId,
			session.startTime
		);

		const entities = normaliseChapterContent(chapterId, content);

		return {
			courseId,
			courseKey: keyProvider.course(courseId),
			chapterId,
			sessionKey,
			entities,
		};
	}
);
