import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import {studentService} from "../services/studentService";
import {ExerciseOutcomes} from "./ExerciseOutcomes";

export const getChapterOutcomes = createAsyncThunk<
	{outcomes: {[key: string]: ExerciseOutcomes}},
	{studentId: number; courseId: number; chapterId: number}
>("outcomes/getChapterOutcomes", async ({studentId, courseId, chapterId}) => {
	const outcomes = await studentService.getChapterOutcomes(
		studentId,
		courseId,
		chapterId
	);

	const byKey: {[key: string]: ExerciseOutcomes} = {};
	outcomes.forEach((outcome) => {
		const key = keyProvider.chapterExercise(chapterId, outcome.exerciseId);
		byKey[key] = {
			completionStatus: outcome.completionStatus,
			score: outcome.score,
		};
		if (outcome.assessorComment) {
			byKey[key].assessorComment = outcome.assessorComment;
		}
	});

	return {outcomes: byKey};
});
