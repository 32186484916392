import {useMediaQuery, useTheme} from "@mui/material";
import type {Breakpoint} from "@mui/material";

function useMobileMode(mobileModeBreakpoint: Breakpoint): boolean {
	const theme = useTheme();

	const mobileMode = useMediaQuery(
		theme.breakpoints.down(mobileModeBreakpoint)
	);

	return mobileMode;
}

export default useMobileMode;
