import React, {useCallback} from "react";

import exerciseService from "../../store/services/exerciseService";
import ExerciseTagsSelector from "./ExerciseTagsSelector";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import Feature from "../../store/features/Feature";

type SelectorProps = {
	freeSolo?: boolean;
	value: string[];
	onChange: (value: string[]) => void;
};

function useExerciseEditorTagSelector(
	organisationName: string,
	userId: number
): React.ElementType<SelectorProps> | null {
	const [featureEnabled] = useFeatureEnabled();

	if (!featureEnabled(Feature.ExerciseTags)) {
		return null;
	}

	return function Selector(props: SelectorProps) {
		const searchTags = useCallback(async (prefix: string, pageSize: number) => {
			const page = await exerciseService.searchTags(
				{
					scope: "organisation_or_user_exercises",
					organisationName,
					userId,
					prefix,
				},
				pageSize
			);

			return page;
		}, []);

		return (
			<ExerciseTagsSelector
				freeSolo={props.freeSolo}
				value={props.value}
				onChange={props.onChange}
				searchTags={searchTags}
			/>
		);
	};
}

export default useExerciseEditorTagSelector;
