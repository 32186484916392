import {createAsyncThunk} from "@reduxjs/toolkit";

import {statisticsService} from "../services/statisticsService";
import type ExerciseType from "../exercises/ExerciseType";
import {keyProvider} from "../keyProvider";

const fetchCourseContentStatistics = createAsyncThunk(
	"courseContentStatistics/fetch",
	async (params: {courseId: number}) => {
		const stats = await statisticsService.getChaptersStatistics(
			params.courseId
		);

		return stats.map((chSt) => {
			const byType: {
				[key in ExerciseType]?: {numberOfExercises: number; maxScore: number};
			} = {};

			chSt.stats.forEach((st) => {
				byType[st.exerciseType] = {
					maxScore: st.maxScore,
					numberOfExercises: st.numberOfExercises,
				};
			});

			return {chapterKey: keyProvider.chapter(chSt.chapterId), stats: byType};
		});
	}
);

export default fetchCourseContentStatistics;
