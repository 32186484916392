import {axiosInstance as client} from "./axiosInstance";
import type RoleInCourse from "../courses/RoleInCourse";
import type ExerciseType from "../exercises/ExerciseType";

export enum Statistic {
	NumberOfCourses = "numberOfCourses",
	NumberOfPastCourses = "numberOfPastCourses",
	NumberOfExams = "numberOfExams",
	NumberOfPastExams = "numberOfPastExams",
	NumberOfTeachers = "numberOfTeachers",
	NumberOfStudents = "numberOfStudents",
}

export enum CourseStatistic {
	NumberOfExercises = "numberOfExercises",
	MaxScore = "maxScore",
}

export type ChapterStatistics = {
	chapterId: number;
	stats: {
		exerciseType: ExerciseType;
		maxScore: number;
		numberOfExercises: number;
	}[];
};

export const statisticsService = {
	getUserStatistics: async <T extends Statistic>(
		userId: number,
		orgName: string,
		names: readonly T[],
		roles: readonly RoleInCourse[]
	): Promise<{[key in T]: number}> => {
		const url = `/api/organisations/${orgName}/users/${userId}/courses/statistics`;

		const params = new URLSearchParams();
		names.forEach((name) => {
			params.append("name", name);
		});
		roles.forEach((role) => {
			params.append("userRole", role);
		});

		const {data} = await client.get(url, {params});
		return data;
	},

	getCourseStatistics: async <T extends CourseStatistic>(
		courseId: number,
		names: readonly T[]
	): Promise<{[key in T]: number}> => {
		const url = `/api/courses/${courseId}/statistics`;

		const params = new URLSearchParams();
		names.forEach((name) => {
			params.append("name", name);
		});

		const {data} = await client.get(url, {params});

		return data[0];
	},

	getChaptersStatistics: async (
		courseId: number
	): Promise<ChapterStatistics[]> => {
		const {data} = await client.get(
			`/api/courses/${courseId}/content-statistics-by-exercise-type`
		);

		return data.content;
	},
};
