import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useRef, useState} from "react";

const useStyles = makeStyles((theme) => ({
	summary: {
		marginLeft: theme.spacing(1),
	},
	headingOnCollapse: {
		...theme.typography.button,
		color: theme.palette.primary.main,
	},
	headingOnExpand: {
		...theme.typography.subtitle1,
	},
	details: {
		padding: theme.spacing(0, 3, 3, 3),
	},
}));

const ExerciseExpandablePanel = (props: {
	children: React.ReactNode;
	lazyLoading?: boolean;
	summary: React.ReactNode;
}): JSX.Element => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	const loaded = useRef(!props.lazyLoading);

	const clickHandler = () => {
		setExpanded((prev) => !prev);
		loaded.current = true;
	};

	return (
		<Accordion>
			<AccordionSummary
				className={classes.summary}
				expandIcon={<ExpandMoreIcon />}
				onClick={clickHandler}
			>
				<Typography
					className={
						expanded ? classes.headingOnExpand : classes.headingOnCollapse
					}
				>
					{props.summary}
				</Typography>
			</AccordionSummary>

			<AccordionDetails className={classes.details}>
				{loaded.current && props.children}
			</AccordionDetails>
		</Accordion>
	);
};

export default ExerciseExpandablePanel;
