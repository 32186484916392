import {createSlice} from "@reduxjs/toolkit";
import ChapterSession from "./ChapterSession";
import {createChapterSession} from "./createChapterSession";
import fetchChapterSessions from "./fetchChapterSessions";
import {submitChapterSession} from "./submitChapterSession";

type ChapterSessions = {
	byKey: {
		[key: string]: ChapterSession | undefined;
	};
};

const initialState: ChapterSessions = {
	byKey: {},
};

export const sessionsSlice = createSlice({
	name: "sessions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchChapterSessions.fulfilled, (state, {payload}) => {
			const sessions = payload.sessions;
			Object.keys(sessions).forEach((key) => {
				state.byKey[key] = {...sessions[key]};
			});
		});

		builder.addCase(createChapterSession.fulfilled, (state, {payload}) => {
			state.byKey[payload.sessionKey] = {
				startTime: payload.startTime,
			};
		});

		builder.addCase(submitChapterSession.fulfilled, (state, action) => {
			const session = state.byKey[action.payload.sessionKey];
			if (session) {
				session.submissionTime = action.payload.submissionTime;
			}
		});
	},
});

export default sessionsSlice.reducer;
