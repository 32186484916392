import {createAsyncThunk} from "@reduxjs/toolkit";

import proctoringService from "../services/proctoringService";

const fetchProctoringSettings = createAsyncThunk(
	"proctoringSettings/fetch",
	async (params: {organisationName: string}) => {
		return await proctoringService.getSettings(params.organisationName);
	}
);

export default fetchProctoringSettings;
