import {createAsyncThunk} from "@reduxjs/toolkit";
import {keyProvider} from "../keyProvider";
import chapterSessionsService from "../services/chapterSessionsService";

export const createChapterSession = createAsyncThunk<
	{chapterKey: string; sessionKey: string; startTime: string},
	{studentId: number; courseId: number; chapterId: number}
>("chapterSessions/create", async ({studentId, courseId, chapterId}) => {
	const session = await chapterSessionsService.createChapterSession(
		studentId,
		courseId,
		chapterId
	);

	return {
		chapterKey: keyProvider.chapter(chapterId),
		sessionKey: keyProvider.chapterSession(chapterId, session.startTime),
		startTime: session.startTime,
	};
});
