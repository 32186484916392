import {createSlice} from "@reduxjs/toolkit";

import logoutUser from "../userSession/logoutUser";
import loginUser from "../userSession/loginUser";
import updateUserProfile from "../users/updateUserProfile";
import refreshUserSession from "../userSession/refreshUserSession";
import type UserProfile from "./UserProfile";

const initialState: {profile: UserProfile | null} = {
	profile: null,
};

export const userProfileSlice = createSlice({
	name: "userProfile",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(refreshUserSession.fulfilled, (state, {payload}) => {
			if (payload) {
				state.profile = payload.user;
			}
		});

		builder.addCase(logoutUser.pending, () => initialState);

		builder.addCase(loginUser.fulfilled, () => initialState);

		builder.addCase(updateUserProfile.fulfilled, (state, action) => {
			if (state.profile && state.profile.id === action.payload.id) {
				state.profile.firstName = action.payload.profile.firstName;
				state.profile.lastName = action.payload.profile.lastName;
				state.profile.language = action.payload.profile.language;
			}
		});
	},
});

export const userProfileActions = userProfileSlice.actions;

export default userProfileSlice;
