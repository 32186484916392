import type {RootState} from "../store";
import type TutorQuestion from "./TutorQuestion";

const selectMessage = (
	state: RootState,
	messageKey: string
): TutorQuestion | null => {
	return state.tutorQuestions.byKey[messageKey] ?? null;
};

export default selectMessage;
