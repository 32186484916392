import {Localized} from "@fluent/react";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {Button, Container, Typography, useTheme} from "@mui/material";
import React from "react";

import useNavBarHeight from "../../../hooks/useNavBarHeight";

function NoWidgetsEmptyState(props: {onAdd: () => void}): JSX.Element {
	const theme = useTheme();
	const navBarHeight = useNavBarHeight();

	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: `calc(100vh - ${navBarHeight}px - ${theme.spacing(12)})`,
			}}
		>
			<EventBusyIcon style={{height: "50px", width: "50px"}} color="primary" />
			<Typography variant="h6">
				<Localized id="widget-no-widgets-empty-state-title">
					No widgets
				</Localized>
			</Typography>
			<Typography>
				<Localized id="widget-no-widgets-empty-state-description">
					{`You haven't added any widget yet`}
				</Localized>
			</Typography>
			<Button
				color="primary"
				variant="contained"
				style={{marginTop: theme.spacing(2)}}
				onClick={props.onAdd}
			>
				<Localized id="widget-no-widgets-empty-state-add-btn">
					Add widget
				</Localized>
			</Button>
		</Container>
	);
}

export default NoWidgetsEmptyState;
