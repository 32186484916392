import {createAsyncThunk} from "@reduxjs/toolkit";

import selectSubsectionIds from "./selectSubsectionIds";
import {courseService} from "../services/courseService";
import {RootState} from "../store";
import type NormalisedSectionContent from "./NormalisedSectionContent";
import {normaliseSectionContent} from "./NormalisedSectionContent";

export const getSectionContent = createAsyncThunk<
	{
		data: NormalisedSectionContent;
		sectionKey: string;
	},
	{
		courseId: number;
		sectionKey: string;
	},
	{
		state: RootState;
	}
>("sections/getContent", async (params, thunkApi) => {
	const store = thunkApi.getState();

	const sectionKey = params.sectionKey;

	const section = store.sections.byKey[sectionKey]?.section;

	if (!section) {
		throw new Error(`Section ${sectionKey} is not found`);
	}

	const ids = selectSubsectionIds(store, sectionKey);

	const content = await courseService.getExercises(
		params.courseId,
		section.chapterId,
		[section.id, ...ids]
	);

	const entities = normaliseSectionContent(section.chapterId, content);

	return {data: entities, sectionKey: sectionKey};
});
