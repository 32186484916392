import {Localized, useLocalization} from "@fluent/react";
import {Box, Chip, MenuItem, TextField} from "@mui/material";
import React from "react";

import type {
	ChangeExerciseSearchCriteria,
	ExerciseSearchCriteria,
} from "./ExerciseSearchCriteria";
import type {ExerciseSearchScope} from "./exerciseSearchScope";
import exerciseSearchScope from "./exerciseSearchScope";
import {exerciseTypes} from "./ExerciseTypeLabel";
import type {Page} from "../../helpers/paginatedSearchHelpers";
import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import ExerciseTagsSelector from "../learningMaterial/ExerciseTagsSelector";
import SidebarBody from "../sidebars/SidebarBody";
import SidebarHeader from "../sidebars/SidebarHeader";
import SidebarSection from "../sidebars/SidebarSection";
import type ExerciseType from "../../store/exercises/ExerciseType";
import type TagSearchResult from "../../store/exercises/TagSearchResult";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";

const ExerciseBankFilters = (props: {
	open: boolean;
	width: number;
	mobileMode?: boolean;
	onClose: () => void;
	availableExerciseTypes: ExerciseType[];
	criteria: ExerciseSearchCriteria;
	searchTags?: (
		prefix: string,
		pageSize: number
	) => Promise<Page<TagSearchResult>>;
	onCriteriaChange: ChangeExerciseSearchCriteria;
}): JSX.Element => {
	const {onCriteriaChange, searchTags} = props;

	const {l10n} = useLocalization();

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			topFixedPanel={
				<SidebarHeader
					title={l10n.getString("exercise-bank-sidebar-header-title-filters")}
					onClose={props.onClose}
				/>
			}
		>
			<SidebarBody>
				<SidebarSection title="">
					<TextField
						select
						label={
							<Localized id="exercise-bank-filter-search-scope-label">
								Search scope
							</Localized>
						}
						fullWidth
						value={criteria.scope}
						onChange={({target}) =>
							setCriteria((prev) => ({
								...prev,
								scope: (target.value as unknown) as ExerciseSearchScope,
							}))
						}
					>
						{exerciseSearchScope.map((scope) => (
							<MenuItem key={scope.name} value={scope.name}>
								<Localized id={scope.displayName}>
									{scope.displayName}
								</Localized>
							</MenuItem>
						))}
					</TextField>
				</SidebarSection>
				<SidebarSection title="">
					<ContentLanguageSelector
						value={criteria.language}
						onChange={(language) =>
							setCriteria((prev) => ({...prev, language}))
						}
					/>
				</SidebarSection>
				<SidebarSection
					title={
						<Localized id="exercise-bank-filter-type-label">Type</Localized>
					}
				>
					{exerciseTypes
						.filter((type) => props.availableExerciseTypes.includes(type.name))
						.map((type) => (
							<Box key={type.name} mr={1} mb={1}>
								<Chip
									label={type.displayName}
									icon={type.icon}
									variant={criteria.type === type.name ? undefined : "outlined"}
									color={criteria.type === type.name ? "primary" : undefined}
									onClick={() =>
										setCriteria((prev) => ({
											...prev,
											type: prev.type !== type.name ? type.name : null,
										}))
									}
								/>
							</Box>
						))}
				</SidebarSection>
				{searchTags && (
					<SidebarSection title="">
						<ExerciseTagsSelector
							value={criteria.tags}
							onChange={(tags) => {
								setCriteria((prev) => ({...prev, tags: tags}));
							}}
							searchTags={searchTags}
						/>
					</SidebarSection>
				)}
			</SidebarBody>
		</AppBarDependentDrawer>
	);
};

export default React.memo(ExerciseBankFilters);
