import {createAsyncThunk} from "@reduxjs/toolkit";

import selfEnrolmentService from "../services/selfEnrolmentService";

const fetchEnrolmentApplications = createAsyncThunk(
	"selfEnrolment/fetchEnrolmentApplications",
	async (params: {orgName: string; userId: number}) => {
		const applications = await selfEnrolmentService.getEnrolmentApplications(
			params.orgName,
			params.userId
		);

		return applications;
	}
);

export default fetchEnrolmentApplications;
