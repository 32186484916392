import {Localized} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Box,
	Button,
	Container,
	Grid,
	Skeleton,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import Widget from "../../analytics/widget/Widget";
import WidgetBody from "../../analytics/widget/WidgetBody";
import WidgetTitle from "../../analytics/widget/WidgetTitle";
import {
	addToDate,
	formatToLocalTimeZoneISO,
	resetTimeComponent,
} from "../../../helpers/dateTimeHelpers";
import type FetchStatus from "../../../store/FetchStatus";
import UserRole from "../../../store/models/UserRole";
import {userService} from "../../../store/services/userService";
import LoadingError from "../../../utils/errors/LoadingError";
import useMobileMode from "../../../hooks/useMobileMode";

const inactivityPeriod = 6;

const inactiveUsersCriteria = {
	roles: [UserRole.Administrator, UserRole.Teacher, UserRole.Tutor],
	lastLoginBefore: addToDate(resetTimeComponent(new Date()).toISOString(), {
		months: -inactivityPeriod,
	}),
};

function RecommendationsWidget(props: {
	id: string;
	title: React.ReactNode;
	organisationName: string;
}): JSX.Element {
	const {organisationName} = props;

	const [inactiveUserNumber, setInactiveUserNumber] = useState(0);
	const [usersFetchStatus, setUsersFetchStatus] = useState<FetchStatus>("none");

	const fetchInactiveUsers = useCallback(async () => {
		setUsersFetchStatus("pending");

		try {
			const ids = await userService.selectOrganisationUserIds(
				organisationName,
				inactiveUsersCriteria
			);

			setInactiveUserNumber(ids.length);
			setUsersFetchStatus("succeeded");
		} catch {
			setUsersFetchStatus("failed");
		}
	}, [organisationName]);

	useEffect(() => {
		fetchInactiveUsers();
	}, [fetchInactiveUsers]);

	if (usersFetchStatus === "failed") {
		return (
			<TitledWidget id={props.id} title={props.title}>
				<Container>
					<LoadingError
						variant="block"
						description={
							<Localized id="administration-dashboard-recommendations-error-descr">
								Failed to load data for the widget
							</Localized>
						}
						onReload={fetchInactiveUsers}
					/>
				</Container>
			</TitledWidget>
		);
	}

	return (
		<TitledWidget id={props.id} title={props.title}>
			<Grid container>
				<Grid item xs={12}>
					{usersFetchStatus === "pending" && <LoadingRecommendation />}
					{usersFetchStatus === "succeeded" && (
						<InactiveStaffMembersRecommendation
							usersNumber={inactiveUserNumber}
							criteria={inactiveUsersCriteria}
						/>
					)}
				</Grid>
			</Grid>
		</TitledWidget>
	);
}

function InactiveStaffMembersRecommendation(props: {
	usersNumber: number;
	criteria: typeof inactiveUsersCriteria;
}) {
	const {criteria, usersNumber} = props;
	const theme = useTheme();
	const mobileMode = useMobileMode("sm");

	if (usersNumber === 0) {
		return (
			<Box display="flex" style={{gap: theme.spacing(1.5)}}>
				<DoneIcon style={{color: theme.palette.success.main}} />
				<Typography>
					<Localized
						id="administration-dashboard-recommendations-inactive-staff-members-none"
						vars={{inactivityPeriod}}
					>
						All staff members have been active in the past months.
					</Localized>
				</Typography>
			</Box>
		);
	}

	const params = new URLSearchParams();
	criteria.roles.forEach((role) => params.append("role", role));
	params.append(
		"lastLoginBefore",
		formatToLocalTimeZoneISO(criteria.lastLoginBefore).slice(0, 10)
	);

	return (
		<Box
			display="flex"
			flexWrap={mobileMode ? "wrap" : "nowrap"}
			style={{gap: theme.spacing(1)}}
		>
			<Box display="flex" style={{gap: theme.spacing(1.5)}}>
				<WarningIcon style={{color: theme.palette.warning.main}} />
				<Box
					display="flex"
					flexDirection="column"
					style={{gap: theme.spacing(1)}}
				>
					<Typography>
						<Localized
							id="administration-dashboard-recommendations-inactive-staff-members-text"
							vars={{usersNumber, inactivityPeriod}}
						>
							Staff members did not login last months
						</Localized>
					</Typography>
					<Typography variant="body2">
						<Localized id="administration-dashboard-recommendations-inactive-staff-members-descr">
							This is a security issue if they should no longer have access to
							the system. Consider deleting inactive accounts.
						</Localized>
					</Typography>
				</Box>
			</Box>
			{mobileMode && <div style={{height: 0, flexBasis: "100%"}} />}
			<Box
				display="flex"
				justifyContent="right"
				alignItems="start"
				flexGrow={1}
				style={{
					marginTop: mobileMode ? undefined : theme.spacing(-0.75),
				}}
			>
				<Button
					color="primary"
					component={Link}
					to={`/administration/users?${params.toString()}`}
					style={{whiteSpace: "nowrap"}}
				>
					<Localized id="administration-dashboard-recommendations-inactive-staff-members-action">
						{"Go to 'Users'"}
					</Localized>
				</Button>
			</Box>
		</Box>
	);
}

function TitledWidget(props: {
	id: string;
	title: React.ReactNode;
	children: React.ReactNode;
}) {
	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody>{props.children}</WidgetBody>
		</Widget>
	);
}

function LoadingRecommendation() {
	const theme = useTheme();

	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid item xs={12} sm={10}>
				<Box display="flex" style={{gap: theme.spacing(1.5)}}>
					<Skeleton variant="rectangular" width="1.5rem" />
					<Skeleton variant="rectangular" width="100%" />
				</Box>
			</Grid>
			<Grid item xs={12} sm style={{display: "flex", justifyContent: "right"}}>
				<Skeleton variant="rectangular" width="64px" />
			</Grid>
		</Grid>
	);
}

export default RecommendationsWidget;
