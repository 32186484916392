import {Localized} from "@fluent/react";
import React, {useEffect, useRef, useState} from "react";
import {Stack, TextField} from "@mui/material";

import AdditionalActions from "../AdditionalActions";
import createFeedbackSummary from "../createFeedbackSummary";
import FeedbackPanel from "../FeedbackPanel";
import ExerciseType from "../../../../store/exercises/ExerciseType";
import type {
	ResponseToSave,
	ResponseToSubmit,
	ShortAnswerResponse,
} from "../../../../store/studentResponses/Response";
import type SubmissionResult from "../../../../store/studentResponses/SubmissionResult";
import ProgressButton from "../../../../utils/ProgressButton";

const saveResponseDelay = 300;

function ResponseArea(props: {
	additionalActions?: React.ReactNode | React.ReactNode[];
	response: ShortAnswerResponse | null;

	readonly?: boolean;
	submissionDisabled?: boolean;
	submitting: boolean;

	onSave: (response: ResponseToSave) => void;
	onSubmit?: (response: ResponseToSubmit) => Promise<SubmissionResult>;
}) {
	const [response, setResponse] = useState(props.response?.shortText ?? "");

	const [feedback, setFeedback] = useState<
		ReturnType<typeof createFeedbackSummary>
	>(null);

	const delay = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		const savedResponse = props.response?.shortText ?? "";
		setResponse(savedResponse);

		if (savedResponse === "") {
			setFeedback(null);
		}
	}, [props.response?.shortText]);

	function updateResponse(v: string) {
		setResponse(v);

		clearTimeout(delay.current);

		delay.current = setTimeout(() => {
			props.onSave({exerciseType: ExerciseType.Short, shortText: v});
		}, saveResponseDelay);
	}

	const onSubmit = props.onSubmit;
	const submit =
		onSubmit &&
		(async () => {
			clearTimeout(delay.current);

			const result = await onSubmit({
				exerciseType: ExerciseType.Short,
				shortText: response,
			});

			setFeedback(createFeedbackSummary(result));
		});

	const readonly = props.readonly || props.submitting;
	const submissionDisabled = props.submissionDisabled || props.submitting;

	return (
		<Stack spacing={2}>
			<AdditionalActions>{props.additionalActions}</AdditionalActions>

			<TextField
				id="response"
				fullWidth
				label={
					<Localized id="short-answer-exercise-response-area-label-answer">
						Answer
					</Localized>
				}
				value={response}
				disabled={readonly}
				onChange={({target}) => updateResponse(target.value)}
			/>

			{feedback && (
				<FeedbackPanel
					correctness={feedback.correctness}
					summary={feedback.summary}
					message={feedback.message}
				/>
			)}

			{onSubmit && (
				<Stack direction="row" sx={{justifyContent: "flex-end"}}>
					<ProgressButton
						inProgress={props.submitting}
						disabled={response === "" || submissionDisabled}
						variant="contained"
						color="primary"
						onClick={submit}
					>
						<Localized id="short-answer-exercise-response-area-action-submit">
							Submit
						</Localized>
					</ProgressButton>
				</Stack>
			)}
		</Stack>
	);
}

export default ResponseArea;
