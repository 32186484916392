import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

import {Localized} from "@fluent/react";
import ExerciseType from "../../store/exercises/ExerciseType";
import type {StudentResultsWidgetSettings as WidgetSettings} from "../../store/widgets/WidgetSettings";
import type {ProgressByType} from "../../store/services/analyticsService";
import ProgressTooltip from "../analytics/ProgressTooltip";
import ProgressBarLegend, {
	calculateValuesForColors,
} from "../analytics/ProgressBarLegend";
import StackedProgressBar from "../../utils/StackedProgressBar";
import CompletionLabel from "../analytics/CompletionLabel";
import ScoreLabel from "../analytics/ScoreLabel";
import PlainProgressBar from "../analytics/PlainProgressBar";

type ProgressBarType = "plain" | "stacked";

const numberOfExercises = 20;
const numberOfCompletedExercises = 15;
const score = 40;
const maxScore = 50;

const valuesByExercise = [
	{numberOfCompleted: 4, score: 10, exerciseType: ExerciseType.Prog},
	{numberOfCompleted: 6, score: 10, exerciseType: ExerciseType.Multi},
	{numberOfCompleted: 5, score: 20, exerciseType: ExerciseType.Open},
];

const statsByType = {
	[ExerciseType.Prog]: {numberOfExercises: 4, maxScore: 10},
	[ExerciseType.Multi]: {numberOfExercises: 10, maxScore: 15},
	[ExerciseType.Open]: {numberOfExercises: 6, maxScore: 25},
};

const StudentResultsWidgetSettings = (props: {
	settings: WidgetSettings | null;
	onChange: (val: WidgetSettings) => void;
}): JSX.Element => {
	const {onChange} = props;
	const [settings, setSettings] = useState<WidgetSettings>(
		props.settings ?? {progressBarType: "plain"}
	);

	const first = useRef(true);
	useEffect(() => {
		if (first.current) {
			first.current = false;
			return () => {
				return;
			};
		}

		const id = setTimeout(() => {
			onChange(settings);
		}, 500);
		return () => clearTimeout(id);
	}, [onChange, settings]);

	const progressLabel = (
		<CompletionLabel
			completed={numberOfCompletedExercises}
			total={numberOfExercises}
		/>
	);

	const scoreLabel = <ScoreLabel score={score} maxScore={maxScore} />;

	const overallProgress = {
		numberOfCompleted: numberOfCompletedExercises,
		score: score,
	};

	const chapterStats = {
		numberOfExercises: numberOfExercises,
		maxScore,
	};

	return (
		<Box p={2}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl component="fieldset" fullWidth>
						<FormLabel>
							<Localized id="student-results-widget-settings-bar-type-label">
								Progress bar type
							</Localized>
						</FormLabel>
						<RadioGroup
							onChange={({target}) => {
								setSettings((prev) => ({
									...prev,
									progressBarType: target.value as ProgressBarType,
								}));
							}}
							value={settings.progressBarType ?? "plain"}
						>
							<FormControlLabel
								value="plain"
								control={<Radio />}
								label={
									<Localized id="student-results-widget-settings-bar-type-plain">
										Plain
									</Localized>
								}
							/>
							<Box
								mt={1}
								mb={3}
								width="60%"
								style={{marginLeft: `calc(1.5rem + 9px)`}}
							>
								<PlainProgressBar
									overallProgress={overallProgress}
									stats={chapterStats}
									leftLabel={progressLabel}
									rightLabel={scoreLabel}
								/>
							</Box>

							<FormControlLabel
								value="stacked"
								control={<Radio />}
								label={
									<Localized id="student-results-widget-settings-bar-type-stacked">
										Stacked
									</Localized>
								}
							/>
							<Box
								mt={1}
								ml={4}
								width="60%"
								style={{marginLeft: `calc(1.5rem + 9px)`}}
							>
								<StackedBar
									progressByType={valuesByExercise}
									overallProgress={overallProgress}
									chapterStats={chapterStats}
									leftLabel={progressLabel}
									rightLabel={scoreLabel}
								/>
							</Box>
						</RadioGroup>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
};

function StackedBar(props: {
	progressByType: ProgressByType[];
	overallProgress: {
		numberOfCompleted: number;
		score: number;
	};
	chapterStats: {
		numberOfExercises: number;
		maxScore: number;
	};
	leftLabel: React.ReactNode;
	rightLabel: React.ReactNode;
}) {
	const {progressByType, overallProgress, chapterStats} = props;

	const byType = progressByType.reduce(
		(prev, curr) => ({...prev, [curr.exerciseType]: curr}),
		{}
	);

	return (
		<ProgressTooltip
			completed={overallProgress.numberOfCompleted}
			total={chapterStats.numberOfExercises}
			score={overallProgress.score}
			maxScore={chapterStats.maxScore}
			additionalText={
				<ProgressBarLegend progress={byType} stats={statsByType} />
			}
		>
			<StackedProgressBar
				rightLabel={props.rightLabel}
				values={calculateValuesForColors(byType)}
				leftLabel={props.leftLabel}
				maxValue={chapterStats.numberOfExercises}
			/>
		</ProgressTooltip>
	);
}

export default StudentResultsWidgetSettings;
