import {createSlice} from "@reduxjs/toolkit";
import type ChapterProgress from "./ChapterProgress";
import {fetchCourseProgress} from "./fetchCourseProgress";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	byChapters: {
		[key: string]: ChapterProgress | undefined;
	};
	lastRequestStatus: FetchStatus;
	courseKey: string;
};

const initialState: State = {
	byChapters: {},
	lastRequestStatus: "none",
	courseKey: "",
};

export const courseProgressSlice = createSlice({
	name: "courseProgress",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCourseProgress.pending, (state, action) => {
			const key = keyProvider.course(action.meta.arg.courseId);
			state.lastRequestStatus = "pending";
			if (state.courseKey !== key) {
				state.byChapters = {};
				state.courseKey = key;
			}
		});
		builder.addCase(fetchCourseProgress.fulfilled, (state, {payload}) => {
			state.byChapters = payload.byChapters;
			state.lastRequestStatus = "succeeded";
		});
		builder.addCase(fetchCourseProgress.rejected, (state, action) => {
			state.lastRequestStatus = inferFetchStatusFromError(action);
		});
	},
});

export default courseProgressSlice.reducer;
