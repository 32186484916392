import type FileUploader from "../../utils/attachments/FileUploader";
import type UploadedFile from "../../utils/attachments/UploadedFile";
import DocumentFormat from "../../utils/attachments/DocumentFormat";
import useFileUploader from "../../utils/attachments/useFileUploader";
import {useCallback} from "react";
import createFileUploader from "../../utils/attachments/createFileUploader";

const useQuestionFileUploader = (
	courseId: number,
	personId: number
): [UploadedFile[], FileUploader] => {
	const creator = useCallback(
		() =>
			createFileUploader({
				documentFormat: DocumentFormat.TutorQuestion,
				courseId,
				personId,
			}),
		[courseId, personId]
	);

	return useFileUploader(creator);
};

export default useQuestionFileUploader;
