import {Localized} from "@fluent/react";
import React, {useContext} from "react";
import {Button} from "@mui/material";
import SidebarContext, {SidebarType} from "../SidebarContext";

function AnswerButton(props: {chapterExerciseKey: string}): JSX.Element {
	const ctx = useContext(SidebarContext);

	const open = () => {
		ctx.open(SidebarType.Answer, props.chapterExerciseKey);
	};

	return (
		<Button color="primary" onClick={open}>
			<Localized id="sidebars-button-show-answer">Answer</Localized>
		</Button>
	);
}

export default AnswerButton;
