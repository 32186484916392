import React, {ReactNode} from "react";
import {Box, Typography} from "@mui/material";

const DialogMenuItem = (props: {
	icon: ReactNode;
	mainContent: ReactNode | ReactNode[];
	subheader: string;
	mb?: number;
	ml?: number;
}): JSX.Element => {
	return (
		<Box display="flex" mb={props.mb} ml={props.ml}>
			{props.icon}
			<Box ml={3}>
				<Box fontWeight={500} mb={1}>
					{props.mainContent}
				</Box>
				<Typography variant="body2" color="textSecondary">
					{props.subheader}
				</Typography>
			</Box>
		</Box>
	);
};

export default DialogMenuItem;
