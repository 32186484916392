import {useMemo} from "react";
import useCurrentLocale from "./useCurrentLocale";

function useDateTimeFormat(): (date: Date) => string {
	const locale = useCurrentLocale();

	const fmt = useMemo(() => {
		return new Intl.DateTimeFormat([locale], {
			dateStyle: "short",
			timeStyle: "short",
		});
	}, [locale]);

	return fmt.format;
}

export default useDateTimeFormat;
