import {Localized} from "@fluent/react";
import {Tab} from "@mui/material";
import React, {useState} from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";

import Courses from "../../components/administration/courses/Courses";
import Dashboard from "../../components/administration/dashboard/Dashboard";
import Users from "../../components/administration/users/Users";
import BackDestination from "../../components/nav/BackDestination";
import SubtitleNav from "../../components/nav/SubtitleNav";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import useTrackAppUsage from "../../hooks/useTrackAppUsage";
import useFetchOrganisation from "../../store/organisation/useFetchOrganisation";
import AccessDeniedError from "../../store/userSession/AccessDeniedError";
import useAccessDeniedErrorTracking from "../../store/userSession/useAccessDeniedErrorTracking";
import UserAccount from "../user/UserAccount";
import ObservableRoute from "../../utils/ObservableRoute";

const Administration = (props: {organisationName: string}): JSX.Element => {
	const {organisationName} = props;

	const accessDenied = useAccessDeniedErrorTracking();

	const [organisation] = useFetchOrganisation(organisationName);

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	const createSubroute = useSubrouteCreator();

	const dashboardPage = createSubroute("/");
	const usersPage = createSubroute("/users");
	const userEditPage = createSubroute("/users/:id");
	const coursesPage = createSubroute("/courses");

	useTrackAppUsage(organisationName, "orgadmin");

	if (accessDenied) {
		return <AccessDeniedError />;
	}

	const navigationTabs = (
		<SubtitleNav
			title={organisation?.displayName ?? ""}
			selectedTab={selectedTab}
		>
			<Tab
				label={
					<Localized id="administration-tab-name-dashboard">
						Dashboard
					</Localized>
				}
				component={Link}
				value={dashboardPage.link}
				to={dashboardPage.link}
			/>
			<Tab
				label={<Localized id="administration-tab-name-users">Users</Localized>}
				component={Link}
				value={usersPage.link}
				to={usersPage.link}
			/>
			<Tab
				label={
					<Localized id="administration-tab-name-courses">Courses</Localized>
				}
				component={Link}
				value={coursesPage.link}
				to={coursesPage.link}
			/>
		</SubtitleNav>
	);

	return (
		<>
			<Switch>
				<Route path={userEditPage.route}>
					<BackDestination key="user" path={usersPage.link}>
						<UserAccount organisationName={organisationName} />
					</BackDestination>
				</Route>

				<ObservableRoute
					path={usersPage.route}
					onRender={() => setSelectedTab(usersPage.link)}
				>
					{navigationTabs}

					<Users organisationName={organisationName} />
				</ObservableRoute>

				<ObservableRoute
					path={coursesPage.route}
					onRender={() => setSelectedTab(coursesPage.link)}
				>
					{navigationTabs}

					<Courses organisationName={organisationName} />
				</ObservableRoute>

				<ObservableRoute
					path={dashboardPage.route}
					onRender={() => setSelectedTab(dashboardPage.link)}
				>
					{navigationTabs}

					<Dashboard organisationName={organisationName} />
				</ObservableRoute>

				<Redirect to={dashboardPage.link} />
			</Switch>
		</>
	);
};

export default Administration;
