import {Localized} from "@fluent/react";
import {Typography} from "@mui/material";
import React from "react";

import {useAppSelector} from "../../store/hooks";
import selectAnswered from "../../store/tutorQuestions/selectAnswered";
import selectSent from "../../store/tutorQuestions/selectSent";
import TutorQuestion from "../../store/tutorQuestions/TutorQuestion";
import MessageList from "./MessageList";
import TutorMessagesTab from "./TutorMessagesTab";

const TutorMessageList = (props: {
	courseId: number;
	selectedTab: TutorMessagesTab;
	selectedMessageKey: string | null;
	onNavClick?: () => void;
}): JSX.Element => {
	const questions = useAppSelector(selectSent);
	const answers = useAppSelector(selectAnswered);

	return (
		<MessageList
			header={
				props.selectedTab === TutorMessagesTab.Questions ? (
					<Localized id="tutoring-tutor-navigation-questions-label">
						Questions
					</Localized>
				) : (
					<Localized id="tutoring-tutor-navigation-answers-label">
						Answers
					</Localized>
				)
			}
			messageKeys={
				props.selectedTab === TutorMessagesTab.Questions ? questions : answers
			}
			selectedMessage={props.selectedMessageKey}
			messageInfoSelector={
				props.selectedTab === TutorMessagesTab.Questions
					? renderQuestionItem
					: renderAnswerItem
			}
			onNavClick={props.onNavClick}
		/>
	);
};

function renderQuestionItem(message: TutorQuestion) {
	return {
		avatar: (
			<Typography>{message.studentName?.charAt(0).toUpperCase()}</Typography>
		),
		date: message.sendTime,
		name: message.studentName ?? "unknown",
		answered: Boolean(message.answerTime),
	};
}

function renderAnswerItem(message: TutorQuestion) {
	return {
		avatar: (
			<Typography>{message.tutorName?.charAt(0).toUpperCase()}</Typography>
		),
		date: message.answerTime,
		name: message.tutorName ?? "unknown",
	};
}

export default TutorMessageList;
