enum CourseFeature {
	Countdown = "countdown",
	EmptyAnswersToOpenExercises = "empty_answers_to_open_exercises",
	ExerciseDifficulty = "exercise_difficulty",
	SelfAssessment = "self_assessment",
	RedoingExercises = "redoing_exercises",
	ExtraMaterials = "extra_materials",
	Forum = "forum",
	Tutoring = "tutoring",
	NewAnalytics = "new_analytics",
	Calculator = "calculator",
}

export default CourseFeature;
