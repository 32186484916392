export const keyProvider = {
	chapter: (chapterId: number): string => `/chapters/${chapterId}`,
	chapterExercise: (chapterId: number, exerciseId: number): string =>
		`/chapters/${chapterId}/exercises/${exerciseId}`,
	chapterSession: (chapterId: number, startTime: string): string =>
		`/chapters/${chapterId}/sessions/${startTime}`,
	course: (courseId: number): string => `/courses/${courseId}`,
	exercise: (id: number): string => `/exercises/${id}`,
	section: (chapterId: number, sectionId: number): string =>
		`/chapters/${chapterId}/sections/${sectionId}`,
	tutorQuestion: (id: number): string => `/questions/${id}`,
	widgetSettings: (courseId: number, name: string): string =>
		`/courses/${courseId}/widgets/${name}`,
};
