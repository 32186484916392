import {createSlice} from "@reduxjs/toolkit";

import deleteSection from "./deleteSection";
import {getSectionContent} from "./getSectionContent";
import type FetchStatus from "../FetchStatus";
import {getChapterContent} from "../chapters/getChapterContent";
import updateChapterStructure from "../chapters/updateChapterStructure";
import {keyProvider} from "../keyProvider";
import updateSectionSettings from "./updateSectionSettings";
import type Section from "../sections/Section";
import {patchField} from "../patching";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type SectionState = {
	section?: Section;
	contentStatus: FetchStatus;
};

type SectionsState = {
	byKey: {
		[key: string]: SectionState | undefined;
	};
};

const initialState: SectionsState = {
	byKey: {},
};

export const sectionsSlice = createSlice({
	name: "sections",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getChapterContent.fulfilled, (state, action) => {
			const sections = action.payload.entities.sections;

			for (const [key, section] of Object.entries(sections)) {
				const s = obtainState(state, key);

				s.section = section;
			}
		});

		builder.addCase(getSectionContent.pending, (state, action) => {
			const s = obtainState(state, action.meta.arg.sectionKey);

			s.contentStatus = "pending";
		});

		builder.addCase(getSectionContent.rejected, (state, action) => {
			const s = obtainState(state, action.meta.arg.sectionKey);

			s.contentStatus = inferFetchStatusFromError(action);
		});

		builder.addCase(getSectionContent.fulfilled, (state, action) => {
			const s = obtainState(state, action.payload.sectionKey);

			s.contentStatus = "succeeded";
		});

		builder.addCase(updateChapterStructure.fulfilled, (state, action) => {
			const sections = action.payload.structure.sections;

			for (const [key, section] of Object.entries(sections)) {
				const s = state.byKey[key];
				if (s && s.section) {
					if (section.subsections.length > s.section.subsections.length) {
						s.contentStatus = "none";
					}

					s.section.subsections = section.subsections;
					s.section.subsectionIds = section.subsectionIds;
				}
			}
		});

		builder.addCase(updateSectionSettings.fulfilled, (state, {payload}) => {
			const key = keyProvider.section(payload.chapterId, payload.sectionId);

			const section = state.byKey[key]?.section;
			if (section) {
				patchField(section, "selectionSize", payload.selectionSize);
				patchField(section, "maxScorePerExercise", payload.maxScorePerExercise);
			}
		});

		builder.addCase(deleteSection.fulfilled, (state, action) => {
			const key = action.payload.removedSectionKey;

			delete state.byKey[key];
		});
	},
});

function obtainState(slice: SectionsState, key: string) {
	let s = slice.byKey[key];
	if (!s) {
		s = {contentStatus: "none"};
		slice.byKey[key] = s;
	}

	return s;
}

export default sectionsSlice.reducer;
