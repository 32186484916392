import {createSlice} from "@reduxjs/toolkit";
import {fetchStudyPeriod} from "./fetchStudyPeriod";
import {updateStudyPeriod} from "./updateStudyPeriod";
import type FetchStatus from "../FetchStatus";
import {keyProvider} from "../keyProvider";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type StudyPeriodState = {
	studyPeriod?: {startDate: string; endDate: string};
	status: FetchStatus;
};

type State = {
	byCourseKey: {
		[key: string]: StudyPeriodState | undefined;
	};
};

const initialState: State = {
	byCourseKey: {},
};

export const studyPreferencesSlice = createSlice({
	name: "studyPreferences",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchStudyPeriod.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const s = obtainState(state, courseKey);
			s.status = "pending";
		});
		builder.addCase(fetchStudyPeriod.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const s = obtainState(state, courseKey);
			s.status = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchStudyPeriod.fulfilled, (state, {payload}) => {
			const s = obtainState(state, payload.courseKey);
			s.studyPeriod = payload.studyPeriod;
			s.status = "succeeded";
		});

		builder.addCase(updateStudyPeriod.fulfilled, (state, {payload}) => {
			const s = obtainState(state, payload.courseKey);
			s.studyPeriod = payload.studyPeriod;
		});
	},
});

function obtainState(slice: State, key: string) {
	let s = slice.byCourseKey[key];
	if (!s) {
		s = {status: "none"};
		slice.byCourseKey[key] = s;
	}

	return s;
}

export default studyPreferencesSlice.reducer;
