import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import {courseService} from "../services/courseService";

export const fetchChapterExercise = createAsyncThunk(
	"exercises/fetchChapterExercise",
	async (params: {
		courseId: number;
		chapterId: number;
		exerciseId: number;
		sectionId: number;
	}) => {
		const exercises = await courseService.getExercises(
			params.courseId,
			params.chapterId,
			[params.exerciseId]
		);

		if (exercises.length === 0) {
			throw new Error(
				`Exercise ($(params.chapterId), $(params.exerciseId)) was not found`
			);
		}

		const value = exercises[0];

		const chapterExercise = {
			number: value.number,
			maxScore: value.maxScore,
		};

		const exercise = {
			id: value.id,
			type: value.type,
			title: value.title,
			question: value.question,
			difficultyLevel: value.difficultyLevel,
			category: value.category,
			subtype: value.interactionsType,
			interactions: value.interactions,
		};

		return {
			chapterExerciseKey: keyProvider.chapterExercise(
				params.chapterId,
				params.exerciseId
			),
			exerciseKey: keyProvider.exercise(params.exerciseId),
			chapterExercise,
			exercise,
			chapterId: params.chapterId,
			sectionId: params.sectionId,
		};
	}
);
