import type {ReactLocalization} from "@fluent/react";
import {unwrapResult} from "@reduxjs/toolkit";
import type {SerializedError} from "@reduxjs/toolkit";

import publishChapter from "../../store/chapters/publishChapter";
import type {AppDispatch} from "../../store/store";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

function createPublishChapterAction(
	courseId: number,
	l10n: ReactLocalization,
	dispatch: AppDispatch,
	showSnackbar: ShowSnackbar
): (chapterId: number) => Promise<void> {
	return async function (chapterId: number) {
		const res = await dispatch(publishChapter({courseId, chapterId}));

		try {
			unwrapResult(res);
		} catch (error) {
			let message = l10n.getString(
				"error-general",
				null,
				"An error has occured"
			);

			if ((error as SerializedError).code === "conflict") {
				message = l10n.getString(
					"learning-material-publish-chapter-error-empty-or-inconsistent-settings",
					null,
					"The chapter cannot be published: it is empty or has inconsistent settings"
				);
			}

			showSnackbar("error", message);
		}
	};
}

export default createPublishChapterAction;
