import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

const BackdropComponent = (props: {open: boolean}): JSX.Element => {
	const {open} = props;

	return (
		<Backdrop style={{zIndex: 1000}} open={open}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export default BackdropComponent;
