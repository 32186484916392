import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {getChapterContent} from "../chapters/getChapterContent";
import publishChapter from "../chapters/publishChapter";
import scheduleChapter from "../chapters/scheduleChapter";
import unpublishChapter from "../chapters/unpublishChapter";
import updateChapter from "../chapters/updateChapter";
import updateChapterStructure from "../chapters/updateChapterStructure";
import publishAllChapters from "../chapters/publishAllChapters";
import unpublishAllChapters from "../chapters/unpublishAllChapters";
import {keyProvider} from "../keyProvider";

type State = {
	byKey: {
		[chapterKey: string]: {editing: boolean} | undefined;
	};
};

const initialState: State = {
	byKey: {},
};

function updateState(value: {editing: boolean}) {
	return function (
		state: State,
		action: PayloadAction<unknown, string, {arg: {chapterId: number}}>
	) {
		const chapterKey = keyProvider.chapter(action.meta.arg.chapterId);
		state.byKey[chapterKey] = value;
	};
}

export const chapterEditingSlice = createSlice({
	name: "chapterEditing",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		const actions = [
			getChapterContent,
			publishChapter,
			unpublishChapter,
			scheduleChapter,
			updateChapter,
			updateChapterStructure,
		];

		actions.forEach((action) => {
			builder.addCase(action.pending, updateState({editing: true}));
			builder.addCase(action.fulfilled, updateState({editing: false}));
			builder.addCase(action.rejected, updateState({editing: false}));
		});

		builder.addCase(publishAllChapters.pending, (state, action) => {
			const chapterKeys = action.meta.arg.chapterKeys;
			chapterKeys.forEach((key) => {
				state.byKey[key] = {editing: true};
			});
		});
		builder.addCase(publishAllChapters.fulfilled, (state, action) => {
			const chapterKeys = action.meta.arg.chapterKeys;
			chapterKeys.forEach((key) => {
				state.byKey[key] = {editing: false};
			});
		});
		builder.addCase(publishAllChapters.rejected, (state, action) => {
			const chapterKeys = action.meta.arg.chapterKeys;
			chapterKeys.forEach((key) => {
				state.byKey[key] = {editing: false};
			});
		});

		builder.addCase(unpublishAllChapters.pending, (state, action) => {
			const chapterKeys = action.meta.arg.chapterKeys;
			chapterKeys.forEach((key) => {
				state.byKey[key] = {editing: true};
			});
		});
		builder.addCase(unpublishAllChapters.fulfilled, (state, action) => {
			const chapterKeys = action.meta.arg.chapterKeys;
			chapterKeys.forEach((key) => {
				state.byKey[key] = {editing: false};
			});
		});
		builder.addCase(unpublishAllChapters.rejected, (state, action) => {
			const chapterKeys = action.meta.arg.chapterKeys;
			chapterKeys.forEach((key) => {
				state.byKey[key] = {editing: false};
			});
		});
	},
});

export default chapterEditingSlice.reducer;
