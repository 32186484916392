import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Divider, Paper, Stack, Typography} from "@mui/material";
import React from "react";

import type Correctness from "../../../store/studentResponses/Correctness";
import HtmlContent from "../../../utils/HtmlContent";

function FeedbackPanel(props: {
	correctness: Correctness;
	summary: React.ReactNode;
	message?: string;
}) {
	return (
		<Paper sx={{p: 2}}>
			<Stack spacing={2}>
				<Stack direction="row" spacing={1}>
					{props.correctness === "incorrect" ? (
						<ErrorOutlineIcon color="error" />
					) : (
						<CheckCircleOutlineOutlinedIcon color="success" />
					)}
					<Typography>{props.summary}</Typography>
				</Stack>

				{props.message && (
					<>
						<Divider />

						<Typography variant="body1" component="div">
							<HtmlContent content={props.message} />
						</Typography>
					</>
				)}
			</Stack>
		</Paper>
	);
}

export default FeedbackPanel;
