import ProgFile from "../studentResponses/ProgFile";
import {axiosInstance as client} from "./axiosInstance";
import {mapToExerciseRequestParams} from "./courseService";
import {ProgExercise} from "./dtos/EditableExercise";
import type ProgExerciseBuildResult from "./dtos/ProgExerciseBuildResult";
import type {
	ProgExerciseTest,
	ProgExerciseTestResult,
} from "../exercises/prog/ProgExerciseTest";
import TableSchema from "../exercises/prog/TableSchema";
import TableData from "../exercises/prog/TableData";

export type TestRunParams = {
	args: string;
	number: number;
	input: string;
	inputFiles: ProgFile[];
};

export const progExerciseService = {
	getFile: async (
		courseId: number,
		exerciseId: number,
		filename: string
	): Promise<ProgFile> => {
		const params = new URLSearchParams();
		params.append("file_group_type", "lib");
		params.append("filename", filename);

		const {data} = await client.get<{content: string; filename: string}>(
			`/api/courses/${courseId}/exercises/${exerciseId}/files`,
			{params}
		);

		return {
			content: data.content,
			name: data.filename,
		};
	},

	uploadFile: async (
		courseId: number,
		exerciseId: number,
		uploadParams:
			| {
					fileGroup: "lib";
					file: File;
			  }
			| {
					fileGroup: "inputfile";
					testNumber: number;
					file: File;
			  }
	): Promise<void> => {
		const body = new FormData();
		body.append("new_lib", uploadParams.file);
		body.append("file_group_type", uploadParams.fileGroup);
		if (uploadParams.fileGroup === "inputfile") {
			body.append("runtime_serial", uploadParams.testNumber.toString());
		}

		const {
			data,
		} = await client.post(
			`/api/courses/${courseId}/exercises/${exerciseId}/files`,
			body,
			{headers: {"Content-Type": "multipart/form-data"}}
		);

		if (data.rows !== 1) {
			throw new Error("Failed to upload a file");
		}
	},

	deleteFile: async (
		courseId: number,
		exerciseId: number,
		deleteParams:
			| {
					fileGroup: "lib";
					filename: string;
			  }
			| {
					fileGroup: "inputfile";
					testNumber: number;
					filename: string;
			  }
	): Promise<void> => {
		const params = new URLSearchParams();
		params.append("file_group_type", deleteParams.fileGroup);
		params.append("filename", deleteParams.filename);
		if (deleteParams.fileGroup === "inputfile") {
			params.append("runtime_serial", deleteParams.testNumber.toString());
		}

		const {
			data,
		} = await client.delete(
			`/api/courses/${courseId}/exercises/${exerciseId}/files`,
			{params}
		);

		if (data.rows !== 1) {
			throw new Error("Failed to delete a file");
		}
	},

	build: async (
		courseId: number,
		exerciseId: number,
		exercise: ProgExercise
	): Promise<ProgExerciseBuildResult> => {
		const url = `/api/courses/${courseId}/exercises/${exerciseId}`;
		const params = mapToExerciseRequestParams(exercise, exerciseId, "compile");
		const {data} = await client.put(url, params);

		const buildStatus = data.compile_status === 0 ? "failed" : "success";

		if (exercise.language !== "sql" || buildStatus === "failed") {
			return {
				buildStatus,
				message: data.msg,
			};
		}

		const db: {
			initialSchemaAndState: TableSchema;
			schemaAndState?: TableSchema;
			queryResult?: TableData;
		} = {
			initialSchemaAndState: mapTableSchema(data.extra_info),
		};

		if (Array.isArray(data.example_output)) {
			db.schemaAndState = mapTableSchema(data.example_output);
		} else {
			db.queryResult = mapTableData(data.example_output);
		}

		return {
			buildStatus,
			message: data.msg,
			db,
		};
	},

	getTests: async (
		courseId: number,
		exerciseId: number
	): Promise<ProgExerciseTest[]> => {
		const {data} = await client.get<
			{
				outputfiles: {filename: string; content: string}[];
				inputfiles: {filename: string; content: string}[];
				args: string | null;
				expect: string | null;
				input: string | null;
				status: 0 | 1 | 2;
				runtime_serial: number;
				art_msg?: string;
				compiler_msg?: string;
			}[]
		>(`/api/courses/${courseId}/exercises/${exerciseId}/tests`);

		return data.map((t) => ({
			outputFiles: t.outputfiles.map((f) => ({
				name: f.filename,
				content: f.content,
			})),
			inputFiles: t.inputfiles.map((f) => ({
				name: f.filename,
				content: f.content,
			})),
			args: t.args ?? "",
			exampleOutput: t.expect ?? "",
			input: t.input ?? "",
			number: t.runtime_serial,
			status: mapStatus(t.status),
			errorDescription:
				t.art_msg === "compile_fail" ? t.compiler_msg : t.art_msg,
		}));
	},

	addTest: async (
		courseId: number,
		exerciseId: number
	): Promise<ProgExerciseTest> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/exercises/${exerciseId}/tests`,
			{
				action: "save",
				exercise_template_id: exerciseId,
			}
		);

		return {
			args: "",
			exampleOutput: "",
			input: "",
			inputFiles: [],
			outputFiles: [],
			number: data.runtime_serial,
			status: "not_updated",
		};
	},

	runTest: async (
		courseId: number,
		exerciseId: number,
		runParams: TestRunParams
	): Promise<ProgExerciseTestResult> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/exercises/${exerciseId}/tests`,
			{
				action: "run",
				exercise_template_id: exerciseId,
				args: runParams.args || null,
				runtime_serial: runParams.number,
				input: runParams.input,
				inputfiles: runParams.inputFiles,
			}
		);

		if (data.status === 0) {
			return {
				status: "error",
				errorDescription:
					data.art_msg === "compile_fail" ? data.compiler_msg : data.art_msg,
			};
		}

		return {
			status: "updated",
			output: data.expect,
		};
	},

	updateTestFiles: async (
		courseId: number,
		exerciseId: number,
		testNumber: number,
		inputFiles: ProgFile[]
	): Promise<"error" | "updated" | "not_updated"> => {
		const {data} = await client.post(
			`/api/courses/${courseId}/exercises/${exerciseId}/tests`,
			{
				action: "save",
				exercise_template_id: exerciseId,
				runtime_serial: testNumber,
				inputfiles: inputFiles,
			}
		);

		return mapStatus(data.status);
	},

	deleteTest: async (
		courseId: number,
		exerciseId: number,
		serialNumber: number
	): Promise<void> => {
		await client.delete(
			`/api/courses/${courseId}/exercises/${exerciseId}/tests/${serialNumber}`
		);
	},
};

export type RawTableSchema = {
	chks: Record<string, {check_clause: string; column_name: string}[]>;
	data: RawTableData;
	fields: {
		default: string | null;
		is_fk: boolean;
		is_pk: boolean;
		name: string;
		nullable: boolean;
		type: string;
		varchar_length: number | null;
	}[];
	fks: {our_fields: string[]; ref_fields: string[]; table: string}[];
	name: string;
	pk: string[];
	type: string;
}[];

export type RawTableData = {
	colnames: string[];
	data: (string | number | null)[][];
};

export function mapTableData(data: RawTableData): TableData {
	return {
		columns: data.colnames,
		data: data.data,
	};
}

export function mapTableSchema(schema: RawTableSchema): TableSchema {
	function mapChecks(
		chks: Record<string, {check_clause: string; column_name: string}[]>
	) {
		const checks = new Set<string>();

		Object.values(chks).forEach((v) => {
			v.forEach((c) => checks.add(c.check_clause));
		});

		return Array.from(checks);
	}

	return schema.map((s) => ({
		checks: mapChecks(s.chks),
		data: mapTableData(s.data),
		columns: s.fields.map((item) => ({
			default: item.default,
			isForeignKey: item.is_fk,
			isPrimaryKey: item.is_pk,
			name: item.name,
			nullable: item.nullable,
			type: item.type,
			varcharLength: item.varchar_length,
		})),
		foreignKeys: s.fks.map((fk) => ({
			columns: fk.our_fields,
			referencedColumns: fk.ref_fields,
			referencedTable: fk.table,
		})),
		name: s.name,
		primaryKeys: s.pk,
		type: s.type,
	}));
}

function mapStatus(status: 0 | 1 | 2) {
	switch (status) {
		case 0:
			return "error";
		case 1:
			return "updated";
		case 2:
			return "not_updated";
		default:
			throw new Error("Unexpected test status");
	}
}
