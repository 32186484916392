import {Localized} from "@fluent/react";
import ErrorIcon from "@mui/icons-material/Error";
import {Box, Button, Typography, useTheme} from "@mui/material";
import React from "react";

import PageContainer from "../PageContainer";

const LoadingError = (props: {
	title?: React.ReactNode;
	description: React.ReactNode;
	variant?: "page" | "block";
	onReload?: () => void;
}): JSX.Element => {
	const theme = useTheme();

	const content = (
		<>
			<ErrorIcon
				style={{
					height: "50px",
					width: "50px",
					color: theme.palette.error.main,
				}}
			/>
			<Typography variant="h6">
				{props.title ?? (
					<Localized id="loading-error-default-title">
						An error has occured
					</Localized>
				)}
			</Typography>
			<Typography align="center">{props.description}</Typography>
			{props.onReload && (
				<Button
					onClick={props.onReload}
					variant="contained"
					color="primary"
					style={{marginTop: theme.spacing(2)}}
				>
					<Localized id="loading-error-reload-btn">Reload</Localized>
				</Button>
			)}
		</>
	);

	const variant = props.variant ?? "page";

	if (variant === "block") {
		return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				{content}
			</Box>
		);
	}

	return <PageContainer>{content}</PageContainer>;
};

export default LoadingError;
