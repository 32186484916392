import {createAsyncThunk} from "@reduxjs/toolkit";

import {keyProvider} from "../keyProvider";
import type {RootState} from "../store";
import {studentResponseService} from "../services/studentResponseService";
import type {ResponseToSubmit} from "../studentResponses/Response";
import type SubmissionResult from "../studentResponses/SubmissionResult";

export const submitChapterSessionStudentResponse = createAsyncThunk<
	{
		responseKey: string;
		response: ResponseToSubmit;
		submissionResult: SubmissionResult;
	},
	{
		studentId: number;
		courseId: number;
		chapterId: number;
		exerciseId: number;
		sessionKey: string;
		response: ResponseToSubmit;
	},
	{state: RootState}
>(
	"chapterSessions/submitStudentResponse",
	async ({chapterId, courseId, exerciseId, response, studentId}) => {
		const result = await studentResponseService.submitResponse(
			studentId,
			courseId,
			chapterId,
			exerciseId,
			response
		);

		const responseKey = keyProvider.chapterExercise(chapterId, exerciseId);

		return {
			responseKey,
			response: response,
			submissionResult: result,
		};
	}
);
