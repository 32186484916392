import {createAsyncThunk} from "@reduxjs/toolkit";

import {selectNonTheorySubsectionIds} from "../sections/selectSubsectionIds";
import {studentResponseService} from "../services/studentResponseService";
import type {RootState} from "../store";
import {normaliseStudentResponses} from "./NormalisedStudentResponses";
import type Response from "./Response";

export const fetchStudentResponses = createAsyncThunk<
	{
		responses: {[key: string]: Response};
	},
	{
		courseId: number;
		studentId: number;
		sectionKey: string;
	},
	{
		state: RootState;
	}
>(
	"studentResponses/fetchStudentResponses",
	async ({courseId, sectionKey, studentId}, thunkApi) => {
		const store = thunkApi.getState();

		const section = store.sections.byKey[sectionKey]?.section;
		if (!section) {
			throw new Error(`Section ${sectionKey} is not found`);
		}

		const ids = selectNonTheorySubsectionIds(store, sectionKey);

		if (ids.length === 0) {
			return {responses: {}};
		}

		const responses = await studentResponseService.getResponsesForChapter(
			studentId,
			courseId,
			section.chapterId,
			ids
		);

		if (responses.length === 0) {
			return {responses: {}};
		}

		const byKey = normaliseStudentResponses(responses, section.chapterId);

		return {responses: byKey};
	}
);
