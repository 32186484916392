import React from "react";
import {Route, Switch} from "react-router-dom";

import Administration from "./administration/Administration";
import Account from "../components/account/Account";
import BackDestination from "../components/nav/BackDestination";
import NavigationBar from "./NavigationBar";
import OrganisationSettings from "./organisation/OrganisationSettings";
import AccessDeniedError from "../store/userSession/AccessDeniedError";
import useAccessDeniedErrorTracking from "../store/userSession/useAccessDeniedErrorTracking";
import UserAccount from "./user/UserAccount";

function App(props: {groupName: string}) {
	const {groupName} = props;

	const accessDenied = useAccessDeniedErrorTracking();

	return (
		<>
			<NavigationBar groupName={groupName} />

			<Switch>
				<Route path={`/account`}>
					<BackDestination key="account">
						<Account />
					</BackDestination>
				</Route>

				<Route>
					{accessDenied && <AccessDeniedError />}

					{!accessDenied && (
						<Switch>
							<Route path={"/organisations/:organisationName"}>
								<BackDestination
									key="organisation-settings"
									fallback="/organisations"
								>
									<OrganisationSettings />
								</BackDestination>
							</Route>

							<Route path={"/users/:userId"}>
								<BackDestination key="user-account" fallback="/users">
									<UserAccount groupName={groupName} />
								</BackDestination>
							</Route>

							<Route path={"/"}>
								<Administration groupName={groupName} />
							</Route>
						</Switch>
					)}
				</Route>
			</Switch>
		</>
	);
}

export default App;
