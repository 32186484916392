import {createAsyncThunk} from "@reduxjs/toolkit";

import type {ResponseToSubmit} from "./Response";
import type SubmissionResult from "./SubmissionResult";
import {keyProvider} from "../keyProvider";
import type {RootState} from "../store";
import {studentResponseService} from "../services/studentResponseService";

const submitStudentResponse = createAsyncThunk<
	{
		responseKey: string;
		response: ResponseToSubmit;
		submissionResult: SubmissionResult;
	},
	{
		studentId: number;
		courseId: number;
		chapterId: number;
		exerciseId: number;
		response: ResponseToSubmit;
	},
	{state: RootState}
>(
	"studentResponses/submitStudentResponse",
	async ({chapterId, courseId, exerciseId, response, studentId}) => {
		const result = await studentResponseService.submitResponse(
			studentId,
			courseId,
			chapterId,
			exerciseId,
			response
		);

		const responseKey = keyProvider.chapterExercise(chapterId, exerciseId);

		return {
			responseKey,
			response: response,
			submissionResult: result,
		};
	}
);

export default submitStudentResponse;
