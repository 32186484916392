import {Localized} from "@fluent/react";
import {MenuItem, TextField} from "@mui/material";
import React, {useMemo} from "react";

import useCurrentLocale from "../i18n/useCurrentLocale";

const ContentLanguageSelector = (props: {
	value: string;
	withAny?: boolean;
	onChange: (value: string) => void;
}): JSX.Element => {
	const {value, onChange} = props;

	const locale = useCurrentLocale();

	const languages = useMemo(() => {
		const langNames = new Intl.DisplayNames([locale], {type: "language"});

		const langs = [
			{code: "en", name: langNames.of("en")},
			{code: "fi", name: langNames.of("fi")},
			{code: "pt", name: langNames.of("pt")},
			{code: "ru", name: langNames.of("ru")},
			{code: "sv", name: langNames.of("sv")},
			{code: "zh", name: langNames.of("zh")},
		];

		const collator = new Intl.Collator(locale);

		return langs.sort((a, b) => collator.compare(a.name ?? "", b.name ?? ""));
	}, [locale]);

	return (
		<TextField
			select
			label={
				<Localized id="content-language-selector-label">Language</Localized>
			}
			fullWidth
			value={value}
			onChange={({target: {value}}) => onChange(value as string)}
		>
			{props.withAny && (
				<MenuItem value="">
					<em>
						<Localized id="content-language-selector-any">Any</Localized>
					</em>
				</MenuItem>
			)}
			{languages.map((lang) => (
				<MenuItem value={lang.code} key={lang.code}>
					{lang.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default ContentLanguageSelector;
