import {useCallback, useState} from "react";

import SortOrder from "./SortOrder";

function useSorting<T extends string>(
	initial: T
): [T, SortOrder, (orderBy: T, order: SortOrder) => void] {
	const [sortField, setSortField] = useState<T>(initial);
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const changeOrder = useCallback((orderBy: T, order: SortOrder) => {
		setSortOrder(order);
		setSortField(orderBy);
	}, []);

	return [sortField, sortOrder, changeOrder];
}

export default useSorting;
