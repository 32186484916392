import {Localized, useLocalization} from "@fluent/react";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	List,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";

import type FetchStatus from "../../store/FetchStatus";
import {courseService} from "../../store/services/courseService";
import ChoiceChip from "../../utils/ChoiceChip";
import LoadingError from "../../utils/errors/LoadingError";
import StatusInfoNotice from "../../utils/StatusInfoNotice";
import StatusListItem from "../../utils/StatusListItem";
import useConfirmClosing from "../../utils/useConfirmClosing";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

type StaffRole = "teacher" | "tutor";

type Course = {id: number; name: string};

function AssignToCoursesDialog<T extends Course>(props: {
	open: boolean;
	mobileMode: boolean;
	userIds: number[];

	CoursesSelector: (props: {
		onSelected: (ids: number[]) => void;
		onCancel: () => void;
	}) => JSX.Element;

	fetchCourses: (ids: number[]) => Promise<T[]>;

	onCancel: () => void;
	onDone: (courseIds: number[]) => void;
}) {
	const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
	const [selectedRoles, setSelectedRoles] = useState<StaffRole[]>([]);

	const [currentStep, setCurrentStep] = useState<
		"courses" | "roles" | "status"
	>("courses");

	const {
		status,
		changeStatus,
		close,
		confirmationDialog,
	} = useConfirmClosing(props.onCancel, () => props.onDone(selectedCourses));

	useEffect(() => {
		if (props.open) {
			setSelectedCourses([]);
			setCurrentStep("courses");
		}
	}, [props.open]);

	const titleId = "assign-staff";

	return (
		<>
			<Dialog
				open={props.open}
				maxWidth="lg"
				fullWidth
				fullScreen={props.mobileMode}
				aria-labelledby={titleId}
				onClose={close}
			>
				<WindowedDialogTitle
					id={titleId}
					title={
						<Localized
							id="users-assign-to-courses-dialog-title"
							vars={{step: currentStep}}
						>
							Assign to courses
						</Localized>
					}
					onClose={close}
					onGoBack={
						currentStep !== "courses"
							? () => {
									setCurrentStep((curr) =>
										curr === "status" ? "roles" : "courses"
									);
							  }
							: undefined
					}
				/>

				{currentStep === "courses" && (
					<props.CoursesSelector
						onSelected={(ids) => {
							setSelectedCourses(ids);
							setCurrentStep("roles");
						}}
						onCancel={close}
					/>
				)}

				{currentStep === "roles" && (
					<StaffRoleSelectorDialog
						onSelected={(r) => {
							setSelectedRoles(r);
							setCurrentStep("status");
						}}
						onCancel={close}
					/>
				)}

				{currentStep === "status" && (
					<ProcessStatusDialog
						courseIds={selectedCourses}
						roles={selectedRoles}
						userIds={props.userIds}
						status={status}
						fetchCourses={props.fetchCourses}
						onStatusChanged={changeStatus}
					/>
				)}
			</Dialog>

			{confirmationDialog}
		</>
	);
}

function StaffRoleSelectorDialog(props: {
	onCancel: () => void;
	onSelected: (roles: StaffRole[]) => void;
}) {
	const [roles, setRoles] = useState<StaffRole[]>([]);

	function toggleRole(role: StaffRole) {
		setRoles((prev) =>
			prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
		);
	}

	const teacherSelected = roles.includes("teacher");
	const tutorSelected = roles.includes("tutor");

	return (
		<>
			<DialogContent dividers sx={{height: "100vh"}}>
				<DialogContentText>
					<Localized id="users-assign-to-courses-dialog-role-selector-descr">
						All selected users will be assigned with the same roles to all
						selected courses.
					</Localized>
				</DialogContentText>

				<Stack spacing={1} direction="row">
					<ChoiceChip
						label={
							<Localized id="users-assign-to-courses-dialog-role-selector-role-teacher">
								Teacher
							</Localized>
						}
						color="secondary"
						variant="outlined"
						selected={teacherSelected}
						onClick={() => toggleRole("teacher")}
					/>

					<ChoiceChip
						label={
							<Localized id="users-assign-to-courses-dialog-role-selector-role-tutor">
								Tutor
							</Localized>
						}
						color="secondary"
						variant="outlined"
						selected={tutorSelected}
						onClick={() => toggleRole("tutor")}
					/>
				</Stack>

				{tutorSelected && (
					<Paper sx={{p: 2, mt: 2}}>
						<Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
							<WarningIcon color="warning" />
							<Typography align="justify">
								<Localized id="users-assign-to-courses-dialog-warning-check-tutoring-enabled">
									Please ensure that feature Tutoring is enabled in settings of
									the selected courses. Otherwise, students would not be able to
									ask questions.
								</Localized>
							</Typography>
						</Stack>
					</Paper>
				)}
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					<Localized id="users-assign-to-courses-dialog-role-selector-action-cancel">
						Cancel
					</Localized>
				</Button>
				<Button
					color="primary"
					disabled={roles.length === 0}
					onClick={() => props.onSelected(roles)}
				>
					<Localized id="users-assign-to-courses-dialog-role-selector-action-assign">
						Assign
					</Localized>
				</Button>
			</DialogActions>
		</>
	);
}

function ProcessStatusDialog<T extends Course>(props: {
	courseIds: number[];
	userIds: number[];
	roles: StaffRole[];
	status: FetchStatus;
	fetchCourses: (ids: number[]) => Promise<T[]>;
	onStatusChanged: (status: FetchStatus) => void;
}) {
	const {userIds, roles, fetchCourses, onStatusChanged} = props;

	const [courses, setCourses] = useState<T[]>([]);
	const [coursesFetchStatus, setCoursesFetchStatus] = useState<FetchStatus>(
		"none"
	);

	const [statuses, setStatuses] = useState<Record<number, FetchStatus>>({});

	const {l10n} = useLocalization();

	useEffect(() => {
		if (props.courseIds.length === 0) {
			return;
		}

		setCoursesFetchStatus("pending");

		fetchCourses(props.courseIds)
			.then((res) => {
				setCourses(res);
				setCoursesFetchStatus("succeeded");
			})
			.catch(() => setCoursesFetchStatus("failed"));
	}, [fetchCourses, props.courseIds]);

	useEffect(() => {
		let cancelled = false;

		async function assignToAll() {
			onStatusChanged("pending");

			let failed = false;

			for (const c of courses) {
				if (cancelled) {
					break;
				}

				setStatuses((prev) => ({...prev, [c.id]: "pending"}));

				try {
					await courseService.assignStaff(
						c.id,
						userIds.map((id) => ({id, roles}))
					);

					setStatuses((prev) => ({...prev, [c.id]: "succeeded"}));
				} catch {
					setStatuses((prev) => ({...prev, [c.id]: "failed"}));
					failed = true;
				}
			}

			onStatusChanged(failed ? "failed" : "succeeded");
		}

		if (courses.length > 0 && userIds.length > 0) {
			assignToAll();
		}

		return () => {
			cancelled = true;
			onStatusChanged("none");
		};
	}, [courses, onStatusChanged, roles, userIds]);

	return (
		<>
			<DialogContent dividers sx={{height: "100vh", position: "relative"}}>
				{coursesFetchStatus === "succeeded" && (
					<StatusNotice status={props.status} />
				)}
				{coursesFetchStatus === "pending" && (
					<StatusNotice status={coursesFetchStatus} />
				)}

				{coursesFetchStatus === "failed" && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							position: "absolute",
							inset: 0,
						}}
					>
						<LoadingError
							description={
								<Localized id="users-assign-to-courses-dialog-error-load-courses">
									Failed to load the list of courses
								</Localized>
							}
							variant="block"
						/>
					</Box>
				)}

				<List>
					{courses.map((c) => (
						<StatusListItem
							key={c.id}
							primaryText={c.name}
							status={statuses[c.id]}
							labelOnPending={l10n.getString(
								"users-assign-to-courses-dialog-status-course-in-progress"
							)}
							labelOnSuccess={l10n.getString(
								"users-assign-to-courses-dialog-status-course-assigned"
							)}
						/>
					))}
				</List>
			</DialogContent>
		</>
	);
}

function Notice(props: {status: FetchStatus}) {
	return (
		<StatusInfoNotice
			status={props.status}
			labelOnPending={
				<Localized id="users-assign-to-courses-dialog-status-overall-in-progress">
					Please do not close the dialogue window while the operation is in
					progress, otherwise the users would be assigned to not all courses.
				</Localized>
			}
			labelOnSuccess={
				<Localized id="users-assign-to-courses-dialog-status-overall-success">
					The users have been successfully assigned to all selected courses.
				</Localized>
			}
			labelOnFailure={
				<Localized id="users-assign-to-courses-dialog-status-overall-failure">
					Unfortunately, not all requests have finished successfully. Please,
					try again.
				</Localized>
			}
			sx={{mb: 2}}
		/>
	);
}
const StatusNotice = React.memo(Notice);

export default AssignToCoursesDialog;
