import {Localized, useLocalization} from "@fluent/react";
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Link,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {unwrapResult} from "@reduxjs/toolkit";

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
	selectSortedUserOrganisations,
	selectUserOrganisationsFetchStatus,
} from "../../store/organisation/selectUserOrganisations";
import type Organisation from "../../store/organisation/Organisation";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import fetchUserOrganisations from "../../store/organisation/fetchUserOrganisations";
import leaveOrganisation from "../../store/organisation/leaveOrganisation";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useSnackbar from "../../store/ui/useSnackbar";
import useMobileMode from "../../hooks/useMobileMode";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import SubmitButton from "../../utils/SubmitButton";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";
import SlideUp from "../../utils/SlideUp";

function Organisations(props: {currentOrganisationName?: string}): JSX.Element {
	const mobileMode = useMobileMode("sm");
	const locale = useCurrentLocale();

	const orgs = useAppSelector((state) =>
		selectSortedUserOrganisations(state, locale)
	);
	const orgsStatus = useAppSelector(selectUserOrganisationsFetchStatus);

	const [orgToLeave, setOrgToLeave] = useState<Organisation | null>(null);
	const [leaving, setLeaving] = useState(false);

	const userId = useAppSelector(selectUserId);

	const dispatch = useAppDispatch();
	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	useEffect(() => {
		if (userId && orgsStatus === "none") {
			dispatch(fetchUserOrganisations(userId));
		}
	}, [dispatch, orgsStatus, userId]);

	async function leave(password: string) {
		if (!orgToLeave) {
			return;
		}

		setLeaving(true);

		const res = await dispatch(
			leaveOrganisation({orgName: orgToLeave.name, userId, password})
		);

		try {
			unwrapResult(res);
		} catch (err) {
			showSnackbar(
				"error",
				(err as {code: string}).code === "forbidden"
					? l10n.getString(
							"account-orgs-leave-incorrect-password",
							null,
							"Incorrect password"
					  )
					: l10n.getString("error-general")
			);
		} finally {
			setLeaving(false);
		}

		setOrgToLeave(null);
	}

	if (orgsStatus === "none" || orgsStatus === "pending") {
		return <Loading />;
	}

	if (orgs.length === 0) {
		return <Empty />;
	}

	const titleElementId = "leave-org-dialog";

	function closeDialog() {
		if (leaving) {
			return;
		}

		setOrgToLeave(null);
	}

	return (
		<Paper>
			<List>
				{orgs.map((o) => (
					<OrganisationListItem
						key={o.name}
						current={o.name === props.currentOrganisationName}
						org={o}
						leaveDisabled={orgs.length === 1}
						onLeave={() => setOrgToLeave(o)}
					/>
				))}
			</List>

			<Dialog
				open={Boolean(orgToLeave)}
				maxWidth="sm"
				fullScreen={mobileMode}
				fullWidth
				onClose={closeDialog}
				aria-labelledby={titleElementId}
				TransitionComponent={mobileMode ? SlideUp : undefined}
			>
				<ConfirmationDialog
					mobileMode={mobileMode}
					titleElementId={titleElementId}
					organisation={orgToLeave || emptyOrg}
					leaving={leaving}
					onLeave={leave}
					onClose={closeDialog}
				/>
			</Dialog>
		</Paper>
	);
}

const emptyOrg: Organisation = {displayName: "", name: "", countryCode: ""};

function ConfirmationDialog(props: {
	mobileMode: boolean;
	titleElementId: string;
	organisation: Organisation;
	leaving: boolean;
	onLeave: (password: string) => void;
	onClose: () => void;
}) {
	const {organisation: org, leaving} = props;

	const theme = useTheme();

	const [password, setPassword] = useState("");
	const [passwordEmpty, setPasswordEmpty] = useState(false);
	const [orgName, setOrgName] = useState("");
	const [orgNameEmpty, setOrgNameEmpty] = useState(false);
	const [orgNameMismatch, setOrgNameMismatch] = useState(false);

	const locale = useCurrentLocale();
	const collator = useMemo(() => new Intl.Collator(locale), [locale]);

	async function leave() {
		const orgNameMismatch =
			orgName !== "" && collator.compare(org.displayName, orgName) !== 0;

		setOrgNameEmpty(orgName === "");
		setPasswordEmpty(password === "");
		setOrgNameMismatch(orgNameMismatch);

		if (orgName === "" || password === "" || orgNameMismatch) {
			return;
		}

		props.onLeave(password);
	}

	return (
		<>
			{props.mobileMode ? (
				<FullScreenDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-orgs-leave-dialog-title">
							Leave organisation?
						</Localized>
					}
					actionButtonLabel={
						<Localized id="account-orgs-leave-btn">Leave</Localized>
					}
					actionInProgress={leaving}
					onAction={leave}
					onClose={props.onClose}
				/>
			) : (
				<WindowedDialogTitle
					id={props.titleElementId}
					title={
						<Localized id="account-orgs-leave-dialog-title">
							Leave organisation?
						</Localized>
					}
					inProgress={leaving}
					onClose={props.onClose}
				/>
			)}

			<DialogContent
				dividers
				onKeyDown={(event) => {
					if (event.code === "Enter" || event.code === "NumpadEnter") {
						leave();
					}
				}}
			>
				<Localized
					id="account-orgs-leave-dialog-description"
					elems={{
						paragraph: <DialogContentText align="justify"></DialogContentText>,
						bold: (
							<DialogContentText
								align="justify"
								style={{fontWeight: 500}}
							></DialogContentText>
						),
					}}
					vars={{orgName: org.displayName}}
				>
					<>{`You are going to leave organisation '${org.displayName}'.`}</>
				</Localized>

				<TextField
					required
					label={
						<Localized id="account-orgs-leave-dialog-organisation">
							Organisation
						</Localized>
					}
					autoFocus
					fullWidth
					value={orgName}
					error={orgNameEmpty || orgNameMismatch}
					helperText={
						orgNameMismatch && (
							<Localized
								id="account-orgs-leave-dialog-org-name-mismatch"
								vars={{orgName: org.displayName}}
							>
								{`Must match '${org.displayName}'`}
							</Localized>
						)
					}
					style={{marginBottom: theme.spacing(1)}}
					onChange={(event) => {
						setOrgName(event.target.value);
						setOrgNameEmpty(false);
						setOrgNameMismatch(false);
					}}
				/>

				<input type="text" autoComplete="username" style={{display: "none"}} />

				<TextField
					required
					label={
						<Localized id="account-orgs-leave-dialog-password">
							Password
						</Localized>
					}
					fullWidth
					value={password}
					error={passwordEmpty}
					onChange={(event) => {
						setPassword(event?.target.value);
						setPasswordEmpty(false);
					}}
					type="password"
				/>

				<DialogContentText
					style={{marginTop: theme.spacing(3), marginBottom: 0}}
				>
					<Localized id="account-orgs-leave-dialog-description-confirm">
						There will be no turning back. Are you sure?
					</Localized>
				</DialogContentText>
			</DialogContent>

			{!props.mobileMode && (
				<DialogActions>
					<Button onClick={props.onClose} color="primary" disabled={leaving}>
						<Localized id="account-orgs-leave-cancel-btn">Cancel</Localized>
					</Button>
					<SubmitButton onClick={leave} inProgress={leaving} variant="text">
						<Localized id="account-orgs-leave-btn">Leave</Localized>
					</SubmitButton>
				</DialogActions>
			)}
		</>
	);
}

function Empty() {
	return (
		<Paper>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "40vh",
				}}
			>
				<Typography variant="h6" align="center">
					<Localized id="account-orgs-empty">
						You are not a member of any organisation
					</Localized>
				</Typography>
			</Container>
		</Paper>
	);
}

function Loading() {
	return (
		<Paper>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "40vh",
				}}
			>
				<CircularProgress color="primary" />
			</Container>
		</Paper>
	);
}

function OrganisationListItem(props: {
	current: boolean;
	org: Organisation;
	leaveDisabled: boolean;
	onLeave: () => void;
}) {
	const {org} = props;

	const theme = useTheme();

	let text = <></>;

	if (props.current) {
		text = (
			<ListItemText
				primary={
					<Box display="flex" style={{gap: theme.spacing(2)}}>
						<Typography noWrap>{org.displayName}</Typography>
						<Chip
							label={
								<Localized id="account-orgs-list-current">Current</Localized>
							}
							size="small"
							variant="outlined"
						/>
					</Box>
				}
				disableTypography
			/>
		);
	} else {
		text = (
			<ListItemText
				primary={
					<Link
						color="textPrimary"
						href={`/organisations/${org.name}`}
						target="_blank"
					>
						{org.displayName}
					</Link>
				}
			/>
		);
	}

	return (
		<ListItem divider style={{paddingRight: theme.spacing(12)}}>
			{text}
			<ListItemSecondaryAction>
				<Button
					color="primary"
					onClick={props.onLeave}
					disabled={props.leaveDisabled}
				>
					<Localized id="account-orgs-leave-btn">Leave</Localized>
				</Button>
			</ListItemSecondaryAction>
		</ListItem>
	);
}

export default Organisations;
