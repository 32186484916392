import {Localized} from "@fluent/react";
import {Box, Link, Typography} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

const Subject = (props: {
	text: React.ReactNode;
	link?: string;
	response?: boolean;
}): JSX.Element => {
	const {link, text} = props;

	let subject;
	if (link) {
		subject = (
			<Link target="_blank" to={link} component={RouterLink} rel="noreferrer">
				<Typography component="span" variant="inherit">
					{text}
				</Typography>
			</Link>
		);
	} else {
		subject = text;
	}

	return (
		<Box display="flex" flexDirection="column">
			<Typography variant="caption" color="textSecondary">
				<Localized id="tutoring-message-subject">Subject</Localized>
			</Typography>
			<Typography variant="h6">
				{props.response && (
					<>
						<Localized id="tutoring-message-subject-response-prefix">
							Re:
						</Localized>{" "}
					</>
				)}
				{subject}
			</Typography>
		</Box>
	);
};

export default Subject;
