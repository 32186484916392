import {Localized, useLocalization} from "@fluent/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import type CourseTemplate from "../../store/services/dtos/CourseTemplate";
import type {CourseTemplateSearchCriteria} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import {organisationService} from "../../store/services/organisationService";
import useSnackbar from "../../store/ui/useSnackbar";
import SortOrder from "../../utils/tables/SortOrder";
import useBulkSelection from "../../utils/tables/useBulkSelection";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import TableToolbar from "../../utils/tables/TableToolbar";
import SortingHeader from "../../utils/tables/SortingHeader";
import TablePagination from "../../utils/tables/TablePagination";
import usePaginationState from "../../utils/tables/usePaginationState";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<CourseTemplate>[] = [
	{
		id: "name",
		label: "organisation-course-templates-table-column-name",
		sortable: true,
	},
	{
		id: "language",
		label: "organisation-course-templates-table-column-language",
		sortable: false,
		width: 160,
	},
];

const columnNumber = headCells.length + 1;

const initialCriteria: CourseTemplateSearchCriteria = {};

function OrganisationCourseTemplates(props: {
	organisationName: string;
}): JSX.Element {
	const {organisationName} = props;

	const navBarHeight = useNavBarHeight();
	const classes = useStyles();

	const [criteria, setCriteria] = useState(initialCriteria);

	const setQuery = useCallback((val: string) => {
		setCriteria((prev) => ({...prev, query: val ?? undefined}));
	}, []);

	const [templatesPerPage, setTemplatesPerPage] = useState(10);

	const [sortField, setSortField] = useState<keyof CourseTemplate>("name");
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [menuTemplate, setMenuTemplate] = useState<CourseTemplate | null>(null);

	const {
		page: templatesPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage: fetchTemplatesPage,
		retryFetching,
		reloadPage,
	} = usePaginationState<CourseTemplate>();

	const templates = templatesPage.content;

	const {
		select: selectTemplate,
		bulkSelectionCheckbox,
		selected: selectedTemplates,
		resetSelection,
	} = useBulkSelection(templatesPage, (t) => t.id);

	const theme = useTheme();
	const mobileMode = useMobileMode("md");

	const locale = useCurrentLocale();
	const {l10n} = useLocalization();

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();
	const showSnackbar = useSnackbar();

	const langNames = useMemo(() => {
		const langNames = new Intl.DisplayNames([locale], {type: "language"});
		return langNames;
	}, [locale]);

	useEffect(() => {
		fetchFirstPage(
			() =>
				courseService.searchTemplates(
					organisationName,
					criteria,
					{field: sortField, descending: sortOrder === SortOrder.Desc},
					templatesPerPage
				),
			resetSelection
		);
	}, [
		criteria,
		fetchFirstPage,
		organisationName,
		resetSelection,
		sortField,
		sortOrder,
		templatesPerPage,
	]);

	function isSelected(id: number) {
		return selectedTemplates.indexOf(id) !== -1;
	}

	function changeOrder(orderBy: keyof CourseTemplate, order: SortOrder) {
		setSortOrder(order);
		setSortField(orderBy);
	}

	async function revokeTemplates(templateIds: number[]) {
		try {
			await organisationService.revokeUseOfCourseTemplates(
				organisationName,
				templateIds
			);

			reloadPage();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		}
	}

	function confirmRevocation() {
		if (!menuTemplate) {
			return;
		}

		openConfirmationDialog({
			confirmBtnText: (
				<Localized id="organisation-course-templates-revoke-confirmation-action-revoke">
					Revoke
				</Localized>
			),
			description: (
				<Localized
					id="organisation-course-templates-revoke-confirmation-descr"
					vars={{templateName: menuTemplate?.name}}
				>
					You are going to revoke the use of course template from the member
					organisation. After revocation, it would not be possible to create new
					courses using this template. However, this change does not affect
					existing courses in any way.
				</Localized>
			),
			title: (
				<Localized id="organisation-course-templates-revoke-confirmation-title">
					Revoke the use of template?
				</Localized>
			),
			onConfirm: () => revokeTemplates([menuTemplate.id]),
		});
	}

	return (
		<div className={classes.root}>
			<Box
				display="flex"
				flexDirection="column"
				pt={mobileMode ? 3 : 5}
				px={mobileMode ? 3 : 6}
				pb={1.5}
				style={{height: `calc(100vh - ${navBarHeight + 1}px)`, width: "100%"}}
			>
				<TableToolbar
					query={criteria.query ?? ""}
					onQueryChange={setQuery}
					actions={[]}
				/>
				<TableContainer className={classes.container}>
					<Table stickyHeader>
						<SortingHeader
							onOrderChange={changeOrder}
							order={sortOrder}
							orderBy={sortField}
							headCells={headCells}
							leftAnnex={
								false && (
									<TableCell padding="checkbox" style={{zIndex: 3}}>
										{bulkSelectionCheckbox}
									</TableCell>
								)
							}
							rightAnnex={<TableCell />}
							loading={pageFetchStatus === "pending"}
						/>
						<TableBody>
							{pageFetchStatus === "failed" && (
								<LoadingErrorState
									description={
										<Localized id="organisation-course-templates-loading-error-descr">
											Something has gone wrong, and we cannot load course
											templates
										</Localized>
									}
									columnNumber={columnNumber}
									onReload={retryFetching}
								/>
							)}
							{pageFetchStatus === "succeeded" && templates.length === 0 && (
								<NoSearchResultsState
									columnNumber={columnNumber}
									title={
										<Localized id="organisation-course-templates-no-templates">
											No course templates
										</Localized>
									}
									description={
										criteria === initialCriteria ? (
											<Localized id="organisation-course-templates-no-templates-descr">
												There are no course templates available to the
												organisation
											</Localized>
										) : (
											<Localized id="organisation-course-templates-no-results-descr">
												No course templates were found matching your search
												criteria. Try to adjust filters
											</Localized>
										)
									}
								/>
							)}
							{pageFetchStatus !== "failed" &&
								templates.length > 0 &&
								templates.map((template, index) => {
									const isItemSelected = isSelected(template.id);
									const labelId = `table-checkbox-${index}`;
									return (
										<TableRow
											hover
											key={template.id}
											tabIndex={-1}
											selected={isItemSelected}
										>
											{false && (
												<TableCell padding="checkbox">
													<Checkbox
														checked={isItemSelected}
														inputProps={{"aria-labelledby": labelId}}
														onClick={() => selectTemplate(template.id)}
													/>
												</TableCell>
											)}
											<TableCell component="th" id={labelId} scope="row">
												{template.name}
											</TableCell>
											<TableCell>{langNames.of(template.language)}</TableCell>
											<TableCell padding="checkbox">
												<Tooltip
													title={
														<Localized id="organisation-course-templates-actions-label">
															Actions
														</Localized>
													}
												>
													<IconButton
														aria-label={l10n.getString(
															"organisation-course-templates-actions-label"
														)}
														onClick={(e) => {
															setMenuAnchor(e.currentTarget);
															setMenuTemplate(template);
														}}
													>
														<MoreVertIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					ml={2}
				>
					<Typography variant="subtitle2">
						{selectedTemplates.length > 0 && (
							<Localized
								id="administration-selected-row-number"
								vars={{selected: selectedTemplates.length}}
							>{`${selectedTemplates.length} selected`}</Localized>
						)}
					</Typography>
					<TablePagination
						onPageChange={fetchTemplatesPage}
						pageSize={templatesPerPage}
						onPageSizeChange={setTemplatesPerPage}
						first={Boolean(templatesPage.request.first)}
						last={Boolean(templatesPage.request.last)}
						next={Boolean(templatesPage.request.next)}
						previous={Boolean(templatesPage.request.previous)}
						label={
							<Localized id="organisation-course-templates-per-page">
								Course templates per page
							</Localized>
						}
						disabled={pageFetchStatus !== "succeeded"}
					/>
				</Box>
			</Box>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				PaperProps={{style: {minWidth: theme.spacing(14)}}}
				onClose={() => setMenuAnchor(null)}
			>
				<MenuItem
					onClick={() => {
						confirmRevocation();
						setMenuAnchor(null);
					}}
				>
					<Localized id="organisation-course-templates-action-revoke">
						Revoke
					</Localized>
				</MenuItem>
			</Menu>

			{confirmationDialog}
		</div>
	);
}

export default OrganisationCourseTemplates;
