import type Course from "./Course";
import type {RootState} from "../store";
import type FetchStatus from "../FetchStatus";

function selectCourse(
	state: Readonly<RootState>,
	key: string
): Readonly<Course> | null {
	return state.courses.byKey[key]?.course ?? null;
}

function selectCourseFetchStatus(
	state: Readonly<RootState>,
	key: string
): FetchStatus {
	return state.courses.byKey[key]?.status ?? "none";
}

export default selectCourse;
export {selectCourseFetchStatus};
