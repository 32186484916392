import {MenuItem, TextField} from "@mui/material";
import React from "react";
import {availableLocales} from "./locales";

const InterfaceLanguageSelector = (props: {
	value: string;
	onChange: (value: string) => void;
	label?: React.ReactNode;
}): JSX.Element => {
	const {value, onChange, label} = props;

	return (
		<TextField
			select
			value={value}
			fullWidth
			onChange={({target: {value: locale}}) => {
				onChange(locale);
			}}
			label={label}
		>
			{Object.entries(availableLocales).map(([locale, name]) => (
				<MenuItem key={locale} value={locale}>
					{name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default InterfaceLanguageSelector;
