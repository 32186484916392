import {Localized} from "@fluent/react";
import {Tab} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {
	Link,
	Redirect,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from "react-router-dom";

import OrganisationCourseTemplates from "../../components/courseTemplates/OrganisationCourseTemplates";
import GeneralSettings from "../../components/organisations/GeneralSettings";
import IntegrationConfiguration from "../../components/organisations/IntegrationConfiguration";
import useSubrouteCreator from "../../hooks/useSubrouteCreator";
import SubtitleNav from "../../components/nav/SubtitleNav";
import Organisation from "../../store/organisation/Organisation";
import {organisationService} from "../../store/services/organisationService";
import LoadingError from "../../utils/errors/LoadingError";
import ObservableRoute from "../../utils/ObservableRoute";

function OrganisationSettings() {
	const {
		params: {organisationName},
	} = useRouteMatch<{organisationName: string}>();

	const [selectedTab, setSelectedTab] = useState<string | boolean>(false);

	const [organisation, setOrganisation] = useState<Organisation | null>(null);
	const [loadingError, setLoadingError] = useState<"not_found" | "other" | "">(
		""
	);

	const history = useHistory();
	const requested = useRef(false);

	function fetchOrganisation(organisationName: string) {
		organisationService
			.getOrganisation(organisationName)
			.then((o) => {
				setOrganisation(o);
				requested.current = true;
			})
			.catch((err) => {
				if ((err as {code: string}).code === "not_found") {
					setLoadingError("not_found");
				} else {
					setLoadingError("other");
				}
			});
	}

	useEffect(() => {
		if (!requested.current) {
			fetchOrganisation(organisationName);
		}
	}, [organisationName]);

	const createSubroute = useSubrouteCreator();

	const generalSettingsPage = createSubroute("/settings");
	const integrationssPage = createSubroute("/integrations");
	const templatesPage = createSubroute("/course-templates");

	let error = null;

	switch (loadingError) {
		case "not_found":
			error = (
				<LoadingError
					title={
						<Localized id="organisation-settings-error-not-found-title">
							Not found
						</Localized>
					}
					description={
						<Localized id="organisation-settings-error-not-found-description">
							The organisation is not found
						</Localized>
					}
				/>
			);

			break;
		case "other":
			error = (
				<LoadingError
					onReload={() => {
						setLoadingError("");
						fetchOrganisation(organisationName);
					}}
					description={
						<Localized id="organisation-settings-error-general">
							Failed to load the organisation
						</Localized>
					}
				/>
			);
	}

	return (
		<>
			<SubtitleNav
				title={
					<Localized id="organisation-settings-title">
						Organisation settings
					</Localized>
				}
				subtitle={organisation?.displayName}
				selectedTab={selectedTab}
			>
				<Tab
					label={
						<Localized id="organisation-settings-tab-name-general">
							General
						</Localized>
					}
					component={Link}
					value={generalSettingsPage.link}
					to={generalSettingsPage.link}
				/>
				<Tab
					label={
						<Localized id="organisation-settings-tab-name-integrations">
							Integrations
						</Localized>
					}
					component={Link}
					value={integrationssPage.link}
					to={integrationssPage.link}
				/>
				<Tab
					label={
						<Localized id="organisation-settings-tab-name-templates">
							Templates
						</Localized>
					}
					component={Link}
					value={templatesPage.link}
					to={templatesPage.link}
				/>
			</SubtitleNav>

			{error}

			{!error && organisation && (
				<Switch>
					<ObservableRoute
						path={generalSettingsPage.route}
						onRender={() => setSelectedTab(generalSettingsPage.link)}
					>
						<GeneralSettings
							organisation={organisation}
							onUpdate={(o) => {
								if (organisation.name !== o.name) {
									history.replace(`/organisations/${o.name}`);
								}

								setOrganisation(o);
							}}
						/>
					</ObservableRoute>

					<ObservableRoute
						path={integrationssPage.route}
						onRender={() => setSelectedTab(integrationssPage.link)}
					>
						<IntegrationConfiguration />
					</ObservableRoute>

					<ObservableRoute
						path={templatesPage.route}
						onRender={() => setSelectedTab(templatesPage.link)}
					>
						<OrganisationCourseTemplates organisationName={organisationName} />
					</ObservableRoute>

					<Route>
						<Redirect to={generalSettingsPage.link} />
					</Route>
				</Switch>
			)}
		</>
	);
}

export default OrganisationSettings;
