import {Localized} from "@fluent/react";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Dialog,
	DialogContent,
	Divider,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, {useState} from "react";

import type FileUploader from "../../utils/attachments/FileUploader";
import type UploadedFile from "../../utils/attachments/UploadedFile";
import TextEditorWithAttachments from "../../utils/TextEditor/TextEditorWithAttachments";
import ActionButtons from "../sidebars/askTutor/ActionButtons";
import StyledAvatar from "./StyledAvatar";
import FullScreenDialogTitle from "../../utils/FullScreenDialogTitle";
import SlideUp from "../../utils/SlideUp";

interface NewMessageFormProps {
	title: React.ReactNode;
	subject: string;
	senderName?: string;
	fileUploader?: FileUploader;
	previousMessage?: React.ReactNode;
	attachments: UploadedFile[];
	mobileMode?: boolean;
	onCancel: () => void;
	onSubmit: (e: string) => Promise<void>;
	onChooseSubjectClick?: () => void;
	onResetSubjectClick?: () => void;
}

const NewMessageForm = (props: NewMessageFormProps): JSX.Element => {
	const [question, setQuestion] = useState("");

	const [sending, setSending] = useState(false);

	const submit = async () => {
		setSending(true);

		try {
			await props.onSubmit(question);
		} finally {
			setSending(false);
		}

		props.onCancel();
	};

	const content = (
		<>
			{props.senderName && (
				<Box display="flex" alignItems="center" mt={2}>
					<Typography variant="caption">
						<Localized id="tutoring-new-message-form-to">To</Localized>
					</Typography>
					<Box ml={1.5} mr={1}>
						<StyledAvatar size="small">
							{props.senderName?.charAt(0).toUpperCase()}
						</StyledAvatar>
					</Box>
					<Typography variant="body2">{props.senderName}</Typography>
				</Box>
			)}

			<Box display="flex" mb={3} mt={2} alignItems="flex-end">
				<TextField
					label={
						<Localized id="tutoring-new-message-form-subject">
							Subject
						</Localized>
					}
					value={props.subject}
					slotProps={{
						input: {
							readOnly: true,
							endAdornment: (
								<>
									{props.onResetSubjectClick && (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												size="small"
												onClick={props.onResetSubjectClick}
											>
												<ClearIcon fontSize="inherit" />
											</IconButton>
										</InputAdornment>
									)}
									{props.onChooseSubjectClick && (
										<Tooltip
											title={
												<Localized id="tutoring-new-message-form-choose-subject">
													Choose subject
												</Localized>
											}
											enterDelay={500}
											enterNextDelay={500}
										>
											<IconButton
												edge="end"
												size="small"
												onClick={props.onChooseSubjectClick}
											>
												<EditIcon fontSize="inherit" />
											</IconButton>
										</Tooltip>
									)}
								</>
							),
						},
					}}
					fullWidth
				/>
			</Box>
			<TextEditorWithAttachments
				value={question}
				onChange={setQuestion}
				fileUploader={props.fileUploader}
				files={props.attachments}
				autoFocus
			/>
			{props.previousMessage && <Box mt={3}>{props.previousMessage}</Box>}
		</>
	);

	if (props.mobileMode) {
		const id = "new-message-dialog";
		return (
			<Dialog
				open
				fullScreen
				TransitionComponent={SlideUp}
				aria-labelledby={id}
			>
				<FullScreenDialogTitle
					id={id}
					title={props.title}
					actionButtonLabel={
						<Localized id="sidebars-action-button-send">Send</Localized>
					}
					actionInProgress={sending}
					onAction={submit}
					onClose={props.onCancel}
				/>
				<DialogContent>{content}</DialogContent>
			</Dialog>
		);
	}

	return (
		<Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
			<Box height="100%" overflow="auto" p={3}>
				<Typography variant="h6">{props.title}</Typography>
				{content}
			</Box>
			<Divider />
			<ActionButtons
				onCancel={props.onCancel}
				onSubmit={submit}
				submitInProgress={sending}
				submitDisabled={!question}
			/>
		</Box>
	);
};

export default NewMessageForm;
