import {Localized} from "@fluent/react";
import {Box, Typography} from "@mui/material";
import React from "react";

import SuccessCircleIcon from "../../icons/SuccessCircleIcon";
import LightTooltip from "../../utils/LightTooltip";

const ExercisesCompletionProgress = (props: {
	completed: number;
	total?: number;
	additionalText?: React.ReactNode;
}): JSX.Element => {
	const {completed, total} = props;

	return (
		<LightTooltip
			title={
				<>
					<Typography>
						<Localized
							id={
								total || total === 0
									? "exercises-completion-progress-tooltip"
									: "exercises-completion-progress-tooltip-no-total"
							}
							vars={total || total === 0 ? {completed, total} : {completed}}
						>
							{`${completed} exercises out of ${total} have been completed.`}
						</Localized>
					</Typography>
					{props.additionalText}
				</>
			}
			placement="top-start"
		>
			<Box display="flex">
				<SuccessCircleIcon fontSize="small" style={{marginRight: 8}} />
				<Typography variant="body2" color="textSecondary">
					{`${completed}/${total ?? "?"}`}
				</Typography>
			</Box>
		</LightTooltip>
	);
};

export default ExercisesCompletionProgress;
