import {Localized} from "@fluent/react";
import {Box, Button, Divider} from "@mui/material";
import React from "react";
import {useHistory} from "react-router-dom";

import TutorMessagesTab from "./TutorMessagesTab";
import useMessageData from "./useMessageData";
import useAnswerFileLoader from "./useAnswerFileLoader";
import useQuestionFileLoader from "./useQuestionFileLoader";
import Subject from "./Subject";
import Conversation from "./Conversation";
import ExerciseType from "../../store/exercises/ExerciseType";
import ScrollableContainer from "../../utils/ScrollableContainer";
import {useAppSelector} from "../../store/hooks";
import selectAnswered from "../../store/tutorQuestions/selectAnswered";
import selectSent from "../../store/tutorQuestions/selectSent";
import MessageNavigation from "./MessageNavigation";

const TutorMessage = (props: {
	selectedMessageKey: string | null;
	selectedTab: TutorMessagesTab;
	courseId: number;
	mobileMode?: boolean;
	viewOnly?: boolean;
}): JSX.Element => {
	const {selectedMessageKey, selectedTab, courseId} = props;

	const {message, chapter, section, exercise} = useMessageData(
		selectedMessageKey,
		courseId
	);

	const questionFileLoader = useQuestionFileLoader(
		courseId,
		message?.id,
		message?.studentId
	);

	const answerFileLoader = useAnswerFileLoader(
		courseId,
		message?.id,
		message?.tutorId
	);

	const messageKeys = useAppSelector((state) =>
		selectedTab === TutorMessagesTab.Questions
			? selectSent(state)
			: selectAnswered(state)
	);

	const history = useHistory();

	let link;
	let subject: string | React.ReactNode = (
		<Localized id="tutoring-subject-general-question">
			General question
		</Localized>
	);
	if (message && message.chapterId) {
		subject = `${chapter?.title} / ${section?.title} / ${exercise?.title}`;
		if (exercise?.type !== ExerciseType.Theory) {
			link =
				`follow-up/students/${message.studentId}/chapters/${message.chapterId}` +
				`/exercises/${message.exerciseId}/type/${exercise?.type}`;
		}
	}

	function reply() {
		history.push({
			search: `?questionId=${message?.id}`,
			state: {reply: true},
		});
	}

	return (
		<ScrollableContainer
			header={
				<Box display="flex" px={props.mobileMode ? 2 : 3} pt={2} pb={1}>
					<Subject
						text={subject}
						response={selectedTab === TutorMessagesTab.Answers}
						link={link}
					/>
					{props.mobileMode && (
						<MessageNavigation
							messageKeys={messageKeys}
							current={selectedMessageKey}
						/>
					)}
				</Box>
			}
			footer={
				selectedTab === TutorMessagesTab.Questions &&
				!message?.answer &&
				!props.viewOnly &&
				!props.mobileMode && (
					<>
						<Divider />

						<Box m={3} display="flex" justifyContent="flex-end">
							<Button variant="contained" color="primary" onClick={reply}>
								<Localized id="tutoring-tutor-reply-btn">Reply</Localized>
							</Button>
						</Box>
					</>
				)
			}
		>
			<Conversation
				expandedByDefault={
					selectedTab === TutorMessagesTab.Answers ? "answer" : "question"
				}
				message={message}
				studentName={message?.studentName}
				tutorName={message?.tutorName}
				questionFileLoader={questionFileLoader}
				answerFileLoader={answerFileLoader}
				onReply={
					!message?.answer && !props.viewOnly && props.mobileMode
						? reply
						: undefined
				}
			/>
		</ScrollableContainer>
	);
};

export default TutorMessage;
