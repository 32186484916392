import {Box, Grid, Hidden, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";

import useEnsureChaptersLoaded from "../../hooks/useEnsureChaptersLoaded";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {fetchTutorMessages} from "../../store/tutorQuestions/fetchMessages";
import {selectCourseIdInTutorHelp} from "../../store/tutorQuestions/selectCourseIdInTutorHelp.ts";
import EmptyState from "./EmptyState";
import NewAnswerForm from "./NewAnswerForm";
import TutorMessage from "./TutorMessage";
import TutorMessageList from "./TutorMessageList";
import TutorMessagesTab from "./TutorMessagesTab";
import TutorNavigation from "./TutorNavigation";
import useMobileMode from "../../hooks/useMobileMode";
import BackDestination from "../nav/BackDestination";
import {keyProvider} from "../../store/keyProvider";
import MessageSlidebar from "./MessageSlidebar";

function Tutoring(props: {
	courseId: number;
	pageLink: string;
	viewOnly?: boolean;
}): JSX.Element {
	const {courseId} = props;

	const navBarHeight = useNavBarHeight();
	const mobileMode = useMobileMode("md");

	const history = useHistory<{reply?: boolean}>();
	const reply = Boolean(history.location.state?.reply);

	const messageId = new URLSearchParams(history.location.search).get(
		"questionId"
	);
	const messageKey =
		messageId && keyProvider.tutorQuestion(parseInt(messageId));

	useEnsureChaptersLoaded(courseId);
	const prevCourseId = useAppSelector(selectCourseIdInTutorHelp);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchTutorMessages(courseId));
	}, [dispatch, courseId]);

	const [selectedTab, setSelectedTab] = useState(TutorMessagesTab.Questions);

	const tutorMessage = (
		<MessageSlidebar in={Boolean(messageKey) && !reply}>
			<TutorMessage
				selectedMessageKey={messageKey}
				selectedTab={selectedTab}
				courseId={courseId}
				mobileMode={mobileMode}
				viewOnly={props.viewOnly}
			/>
		</MessageSlidebar>
	);

	const answerForm = (
		<MessageSlidebar in={reply}>
			<NewAnswerForm
				courseId={courseId}
				selectedMessage={messageKey}
				mobileMode={mobileMode}
				onClose={() => history.push(`?questionId=${messageId}`)}
			/>
		</MessageSlidebar>
	);

	if (prevCourseId !== courseId) {
		return <></>;
	}

	let content = <></>;

	if (!mobileMode || !messageKey) {
		content = (
			<TutoringPage
				courseId={courseId}
				messageKey={messageKey}
				tutorMessage={tutorMessage}
				answerForm={answerForm}
				mobileMode={mobileMode}
				selectedTab={selectedTab}
				onTabChange={(tab) => {
					if (tab !== selectedTab) {
						setSelectedTab(tab);
						history.push("?");
					}
				}}
			/>
		);
	} else {
		content = (
			<BackDestination key="message" path={props.pageLink}>
				{reply ? answerForm : tutorMessage}
			</BackDestination>
		);
	}

	return (
		<Box
			display="flex"
			height={`calc(100vh - ${navBarHeight + 1}px)`}
			style={{height: `calc(100dvh - ${navBarHeight + 1}px)`}}
		>
			{content}
		</Box>
	);
}

function TutoringPage(props: {
	courseId: number;
	messageKey: string | null;
	tutorMessage: React.ReactElement;
	answerForm: React.ReactElement;
	mobileMode: boolean;
	selectedTab: TutorMessagesTab;
	onTabChange: (tab: TutorMessagesTab) => void;
}) {
	const smallScreen = useMobileMode("lg");
	const theme = useTheme();

	const [navOpen, setNavOpen] = useState(false);

	function selectMessagesTab(tab: TutorMessagesTab) {
		props.onTabChange(tab);
		setNavOpen(false);
	}

	let width = "220px";
	if (props.mobileMode) {
		width = `calc(100vw - ${theme.spacing(7)})`;
	} else if (smallScreen) {
		width = "38vw";
	}

	return (
		<>
			<TutorNavigation
				open={!smallScreen || navOpen}
				selectedTab={props.selectedTab}
				variant={smallScreen ? "temporary" : "permanent"}
				width={width}
				onClose={() => setNavOpen(false)}
				onSelect={selectMessagesTab}
			/>

			<Grid container style={{height: "100%", overflowX: "hidden"}}>
				<Grid item xs={12} md={4} lg={4} style={{height: "100%"}}>
					<TutorMessageList
						courseId={props.courseId}
						selectedTab={props.selectedTab}
						selectedMessageKey={props.messageKey}
						onNavClick={smallScreen ? () => setNavOpen(true) : undefined}
					/>
				</Grid>

				<Hidden mdDown>
					<Grid
						item
						md={8}
						lg={8}
						style={{height: "100%", position: "relative"}}
					>
						{!props.messageKey && <EmptyState />}
						{props.tutorMessage}
						{props.answerForm}
					</Grid>
				</Hidden>
			</Grid>
		</>
	);
}

export default Tutoring;
