import {Localized, useLocalization} from "@fluent/react";
import Close from "@mui/icons-material/Close";
import MoreVert from "@mui/icons-material/MoreVert";
import Tune from "@mui/icons-material/Tune";
import {
	Box,
	ClickAwayListener,
	IconButton,
	Menu,
	Paper,
	Popper,
	Toolbar,
	Tooltip,
} from "@mui/material";
import React, {useRef, useState} from "react";

import OutlinedSearchInput from "./OutlinedSearchInput";

function SearchAndActionsToolbar(props: {
	value: string;
	placeholder?: string;
	onChange: (val: string) => void;
	mainAction?: React.ReactNode;
	renderMenuActions?: (close: () => void) => React.ReactNode[];
	renderFilters?: () => NonNullable<React.ReactNode>;
	onClear?: () => void;
}) {
	const [criteriaOpen, setCriteriaOpen] = useState(false);

	const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

	const searchField = useRef<HTMLDivElement>(null);

	const {l10n} = useLocalization();

	function closeMenu() {
		setMenuAnchor(null);
	}

	function closeCriteria(event: MouseEvent | TouchEvent) {
		if (
			!(
				searchField.current &&
				event.target instanceof HTMLElement &&
				searchField.current.contains(event.target)
			)
		) {
			setCriteriaOpen(false);
		}
	}

	const labelClear = l10n.getString(
		"search-toolbar-action-clear",
		null,
		"Clear all filters"
	);

	const labelFilters = l10n.getString(
		"search-toolbar-action-filters",
		null,
		"Filters"
	);

	return (
		<>
			<Toolbar disableGutters sx={{flexGrow: 1}}>
				{props.mainAction && (
					<Box sx={{ml: 0.5, mr: 2}}>{props.mainAction}</Box>
				)}

				<OutlinedSearchInput
					ref={searchField}
					fullWidth
					value={props.value}
					placeholder={props.placeholder}
					endAdornment={
						<>
							{props.onClear && (
								<Tooltip title={labelClear}>
									<IconButton
										aria-label={labelClear}
										onClick={() => {
											setCriteriaOpen(false);
											props.onClear?.();
										}}
									>
										<Close />
									</IconButton>
								</Tooltip>
							)}

							{props.renderFilters && (
								<Tooltip title={labelFilters}>
									<IconButton
										color="primary"
										aria-label={labelFilters}
										onClick={() => setCriteriaOpen((prev) => !prev)}
									>
										<Tune />
									</IconButton>
								</Tooltip>
							)}
						</>
					}
					onChange={props.onChange}
				/>

				{props.renderMenuActions && (
					<Tooltip
						title={
							<Localized id="table-toolbar-tooltip-actions">Actions</Localized>
						}
					>
						<IconButton
							onClick={(e) => setMenuAnchor(e.currentTarget)}
							color="primary"
							sx={{mx: 0.5}}
						>
							<MoreVert />
						</IconButton>
					</Tooltip>
				)}
			</Toolbar>

			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				slotProps={{paper: {sx: {minWidth: (theme) => theme.spacing(14)}}}}
				onClose={closeMenu}
			>
				{props.renderMenuActions?.(closeMenu)}
			</Menu>

			<Popper
				open={criteriaOpen}
				anchorEl={searchField.current}
				disablePortal
				placement="bottom-start"
				keepMounted
				sx={{zIndex: 4}}
			>
				<ClickAwayListener onClickAway={closeCriteria} mouseEvent="onMouseUp">
					<Paper
						square
						variant="outlined"
						sx={{width: searchField.current?.offsetWidth}}
					>
						<Box sx={{p: 3}}>{props.renderFilters?.()}</Box>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</>
	);
}

export default SearchAndActionsToolbar;
