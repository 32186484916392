import {createSlice} from "@reduxjs/toolkit";

import fetchFeatures from "./fetchFeatures";
import type FetchStatus from "../FetchStatus";
import type FrontEndFeature from "./FrontEndFeature";
import inferFetchStatusFromError from "../inferFetchStatusFromError";

type State = {
	features: FrontEndFeature[];
	status: FetchStatus;
};

const initialState: State = {
	features: [],
	status: "none",
};

const featuresSlice = createSlice({
	name: "features",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchFeatures.fulfilled, (state, action) => {
			state.features = action.payload;
			state.status = "succeeded";
		});

		builder.addCase(fetchFeatures.pending, (state) => {
			state.status = "pending";
		});

		builder.addCase(fetchFeatures.rejected, (state, action) => {
			state.status = inferFetchStatusFromError(action);
		});
	},
});

export default featuresSlice.reducer;
