import {RootState} from "../store";
import Notification from "./Notification";

function selectOldestNotification(
	state: RootState
): Readonly<Notification> | null {
	const notifications = state.ui.notifications;
	return notifications.length > 0 ? notifications[0] : null;
}

export default selectOldestNotification;
