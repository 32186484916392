import * as React from "react";
import createSvgIcon from "./utils";

export default createSvgIcon(
	<g>
		<path d="M9.79365 11.7143L7.15079 9.14286L5.72222 10.5714L9.79365 14.694L18.5794 7.14286L17.1508 5.42857L9.79365 11.7143Z" />
		<path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H5.17L4 17.17V4H20V16Z" />
	</g>,
	"RightWithCommentIcon"
);
