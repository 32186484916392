export enum ExerciseSearchScope {
	Course,
	Personal,
	Organisation,
	Public,
}

const exerciseSearchScope = [
	{
		name: ExerciseSearchScope.Course,
		displayName: "exercise-bank-filter-search-scope-option-course-exercises",
	},
	{
		name: ExerciseSearchScope.Personal,
		displayName: "exercise-bank-filter-search-scope-option-my-exercises",
	},
	{
		name: ExerciseSearchScope.Organisation,
		displayName:
			"exercise-bank-filter-search-scope-option-organisation-exercises",
	},
	{
		name: ExerciseSearchScope.Public,
		displayName: "exercise-bank-filter-search-scope-option-public-exercises",
	},
];

export default exerciseSearchScope;
