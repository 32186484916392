import {createAsyncThunk} from "@reduxjs/toolkit";

import {courseService} from "../services/courseService";
import {keyProvider} from "../keyProvider";
import type {AppDispatch, RootState} from "../store";
import selectCourse from "../courses/selectCourse";

const unpublishAllChapters = createAsyncThunk<
	{keys: string[]},
	{courseId: number; chapterKeys: string[]},
	{dispatch: AppDispatch; state: RootState}
>(
	"chapters/unpublishAllChapters",
	async function ({courseId, chapterKeys}, thunkApi) {
		const state = thunkApi.getState();
		const courseKey = keyProvider.course(courseId);

		const course = selectCourse(state, courseKey);
		if (!course) {
			throw new Error(`Course ${courseId} is not found`);
		}

		await courseService.unpublishAllChapters(courseId, course.type);

		return {
			keys: chapterKeys,
		};
	}
);

export default unpublishAllChapters;
