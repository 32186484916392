import type {EditableExercise, SupportedLanguage} from "./EditableExercise";
import type ComparisonStrictness from "../../exercises/ComparisonStrictness";
import type ExercisePrivacy from "../../exercises/ExercisePrivacy";
import type {
	ExternalExerciseSettings,
	OpenExerciseSettings,
	ShortAnswerExerciseSettings,
} from "../../exercises/ExerciseSettings";
import type {
	MathExerciseSubtype,
	MultiExerciseSubtype,
} from "../../exercises/ExerciseSubtype";
import ExerciseType from "../../exercises/ExerciseType";
import type DbExerciseSolutionType from "../../exercises/prog/DbExerciseSolutionType";
import type TableSchema from "../../exercises/prog/TableSchema";
import type {RawTableSchema} from "../progExerciseService";
import {mapTableSchema} from "../progExerciseService";

type ExerciseWithSettingsBase = {
	id: number;
	title: string;
	question: string;
	solution?: string;
	difficultyLevel: number;
	privacyLevel: ExercisePrivacy;
	category?: string;
	language: string;
	tags: string[];

	authorId?: number;
	originId?: number;
};

type ExternalExerciseWithSettings = ExerciseWithSettingsBase & {
	type: ExerciseType.External;
	maxScore: number;
	settings: ExternalExerciseSettings;
};

type MathExerciseWithSettings = ExerciseWithSettingsBase & {
	type: ExerciseType.Math;
	maxScore: number;
	settings: {
		subtype: MathExerciseSubtype;
		finalAnswer: string[];
		expression: string;
		expressionVisible: boolean;
		variable?: string;
	};
};

type MultiExerciseWithSettings = ExerciseWithSettingsBase & {
	type: ExerciseType.Multi;
	settings: {
		choices: {id: number; text: string; score: number; message: string}[];
		subtype: MultiExerciseSubtype;
	};
};

type OpenExerciseWithSettings = ExerciseWithSettingsBase & {
	type: ExerciseType.Open;
	maxScore: number;
	settings: OpenExerciseSettings;
};

type ProgExerciseWithSettings = ExerciseWithSettingsBase & {
	type: ExerciseType.Prog;
	maxScore: number;
	settings: {
		files: {
			name: string;
			content: string;
			readOnlyFromBeginning?: number;
			readOnlyFromEnd?: number;
		}[];
		additionalFiles: string[];
		mainFile: string;
		command?: string;
		comparisonStrictness: ComparisonStrictness;
		initialSchemaAndState: RawTableSchema;
		requiredWords: string;
		prohibitedWords: string;
		exampleOutput: string;
	} & (
		| {language: Exclude<SupportedLanguage, "sql">}
		| {
				language: "sql";
				subtype: DbExerciseSolutionType;
		  }
	);
};

type ShortExerciseWithSettings = ExerciseWithSettingsBase & {
	type: ExerciseType.Short;
	maxScore: number;
	settings: ShortAnswerExerciseSettings;
};

type ExerciseWithSettings =
	| ExternalExerciseWithSettings
	| MathExerciseWithSettings
	| MultiExerciseWithSettings
	| OpenExerciseWithSettings
	| ProgExerciseWithSettings
	| ShortExerciseWithSettings;

function mapToEditableExercise(resp: ExerciseWithSettings) {
	const common: {
		title: string;
		question: string;
		solution: string;
		difficultyLevel: number;
		category: string;
		tags: string[];

		authorId?: number;
		originId?: number;
	} = {
		title: resp.title,
		question: resp.question,
		solution: resp.solution ?? "",
		difficultyLevel: resp.difficultyLevel,
		category: resp.category ?? "",
		tags: resp.tags ?? [],
	};

	if (resp.authorId) {
		common.authorId = resp.authorId;
	}
	if (resp.originId) {
		common.originId = resp.originId;
	}

	let exercise: EditableExercise;

	switch (resp.type) {
		default:
			throw new Error("Unsupported exercise type");
		case ExerciseType.External:
			exercise = {
				type: ExerciseType.External,
				...common,
				language: resp.language,
				privacyLevel: resp.privacyLevel,
				maxScore: resp.maxScore,
				settings: resp.settings,
			};

			break;
		case ExerciseType.Math:
			exercise = {
				type: ExerciseType.Math,
				...common,
				...resp.settings,
				privacy: resp.privacyLevel,
				contentLanguage: resp.language,
				maxScore: resp.maxScore,
			};

			break;
		case ExerciseType.Multi:
			exercise = {
				type: ExerciseType.Multi,
				...common,
				...resp.settings,
				privacy: resp.privacyLevel,
				contentLanguage: resp.language,
				choices: resp.settings.choices.map(
					(choice: {
						id: number;
						text: string;
						score: number;
						message: string;
					}) => ({
						id: choice.id,
						text: choice.text,
						score: choice.score,
						comment: choice.message,
					})
				),
			};

			break;
		case ExerciseType.Open:
			exercise = {
				type: ExerciseType.Open,
				...common,
				language: resp.language,
				privacyLevel: resp.privacyLevel,
				maxScore: resp.maxScore,
				settings: resp.settings,
			};

			break;
		case ExerciseType.Prog: {
			const settings = resp.settings;

			let initialSchemaAndState: TableSchema = [];
			if (resp.settings.language === "sql") {
				initialSchemaAndState = mapTableSchema(settings.initialSchemaAndState);
			}

			exercise = {
				type: ExerciseType.Prog,
				...common,
				...settings,
				privacy: resp.privacyLevel,
				contentLanguage: resp.language,
				initialSchemaAndState: initialSchemaAndState,
				maxScore: resp.maxScore,
			};

			break;
		}
		case ExerciseType.Short:
			exercise = {
				type: ExerciseType.Short,
				...common,
				language: resp.language,
				privacyLevel: resp.privacyLevel,
				maxScore: resp.maxScore,
				settings: resp.settings,
			};
	}

	return exercise;
}

export default ExerciseWithSettings;
export {mapToEditableExercise};
