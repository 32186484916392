import AsciiMath2Tex from "asciimath2tex";

class AsciiMathParser {
	private parser: typeof AsciiMath2Tex;

	constructor() {
		this.parser = new AsciiMath2Tex();
	}

	parse(input: string): string {
		try {
			return this.parser.parse(input);
		} catch {
			return input;
		}
	}
}

export default AsciiMathParser;
