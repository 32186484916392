import type {RootState} from "../store";
import type UserProfile from "./UserProfile";

const emptyProfile = {
	id: 0,
	userName: "",
	firstName: "",
	lastName: "",
	language: "",
	email: "",
	profilePhotoLink: "",
};

function selectUserProfile(state: RootState): UserProfile {
	return state.userProfile.profile ?? emptyProfile;
}

function selectUserId(state: RootState): number {
	return state.userProfile.profile?.id ?? 0;
}

export default selectUserProfile;
export {selectUserId};
