export type ExerciseOutcomes = {
	score: number;
	completionStatus: CompletionStatus;
	assessorComment?: string;
};

export enum CompletionStatus {
	Unattempted = "unattemped",
	Incomplete = "incomplete",
	Complete = "complete",
}
