import {createAsyncThunk} from "@reduxjs/toolkit";
import {courseService} from "../services/courseService";
import {AppDispatch, RootState} from "../store";
import {DraftContent} from "./exericseDraftSlice";

type ExerciseDraftParams = {
	courseId: number;
	chapterId: number;
	sectionId: number;
	exerciseItem: {title: string; content: string};
};

export const saveTheoryDraft = createAsyncThunk<
	DraftContent,
	ExerciseDraftParams,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>("exercise/saveTheoryDraft", async (params, thunkApi) => {
	const draft = thunkApi.getState().ui.exerciseDraft.draft;

	if (!draft) {
		throw new Error("Incorrect operation.");
	}

	const draftContent = {...draft};
	const exercise = {...params.exerciseItem, id: draftContent.id};

	if (draftContent.id === 0) {
		const result = await courseService.addTheoryExericise(
			params.courseId,
			params.chapterId,
			params.sectionId,
			exercise
		);

		draftContent.id = result.id;
	} else {
		await courseService.updateTheoryExercise(params.courseId, exercise);
	}

	draftContent.title = params.exerciseItem.title;
	draftContent.content = params.exerciseItem.content;
	draftContent.sectionId = params.sectionId;

	return draftContent;
});
