import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			position: "relative",
			overflow: "hidden",
			height: 4,
		},
		colorGrey: {
			backgroundColor: theme.palette.grey[300],
		},
		bar: {
			width: "100%",
			position: "absolute",
			left: 0,
			bottom: 0,
			top: 0,
			transition: "transform 0.2s linear",
			transformOrigin: "left",
		},
		bar1: {
			transition: `transform .4s linear`,
		},
	})
);

const StackedLinearProgress = (props: {
	values: {value: number; color: string}[];
}): JSX.Element => {
	const classes = useStyles();

	const {values} = props;

	const bars: {value: number; color: string}[] = [];

	let sum = 0;
	values.forEach((b) => {
		if (b.value > 0) {
			sum += b.value;
			bars.push({...b, value: sum});
		}
	});

	bars.sort((b1, b2) => b2.value - b1.value);

	return (
		<div className={clsx(classes.root, classes.colorGrey)}>
			{bars.map((b, i) => {
				return (
					<div
						className={clsx(classes.bar, classes.bar1)}
						key={i}
						style={{
							backgroundColor: b.color,
							transform: `translateX(${b.value - 100}%)`,
						}}
					></div>
				);
			})}
		</div>
	);
};

export default StackedLinearProgress;
