import {Localized} from "@fluent/react";
import {FormControlLabel, Switch} from "@mui/material";
import React from "react";

import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import SidebarBody from "../sidebars/SidebarBody";
import SidebarHeader from "../sidebars/SidebarHeader";
import SidebarSection from "../sidebars/SidebarSection";
import type {GroupCourseTemplateSearchCriteria} from "../../store/services/courseService";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";

function GroupCourseTemplatesFilters(props: {
	open: boolean;
	width: number | string;
	mobileMode: boolean;

	criteria: GroupCourseTemplateSearchCriteria;
	onCriteriaChange: (
		set: (
			prev: GroupCourseTemplateSearchCriteria
		) => GroupCourseTemplateSearchCriteria
	) => void;

	onClose: () => void;
}) {
	const {onCriteriaChange} = props;

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			onClose={props.onClose}
			topFixedPanel={
				<SidebarHeader
					title={
						<Localized id="group-course-templates-filters">Filters</Localized>
					}
					onClose={props.onClose}
				/>
			}
		>
			<SidebarBody>
				<SidebarSection title="">
					<FormControlLabel
						control={
							<Switch
								value={criteria.ownership === "owned"}
								onChange={(event) =>
									setCriteria((prev) => ({
										...prev,
										ownership: event.target.checked ? "owned" : "any",
									}))
								}
							/>
						}
						label={
							<Localized id="group-course-templates-filters-ownership-owned">
								Show only owned templates
							</Localized>
						}
					/>
				</SidebarSection>

				<SidebarSection title="">
					<ContentLanguageSelector
						value={criteria.language ?? ""}
						withAny
						onChange={(val) =>
							setCriteria((prev) => ({...prev, language: val}))
						}
					/>
				</SidebarSection>
			</SidebarBody>
		</AppBarDependentDrawer>
	);
}

export default GroupCourseTemplatesFilters;
