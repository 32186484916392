import ExerciseType from "../exercises/ExerciseType";
import type TableData from "../exercises/prog/TableData";
import type TableSchema from "../exercises/prog/TableSchema";

type FeedbackBase = {
	type: ExerciseType;
	status: "correct" | "incorrect" | "unspecified";
};

type MathFeedbackBase = FeedbackBase & {
	type: ExerciseType.Math;
};

type MathEmptyFeedback = MathFeedbackBase & {
	status: "unspecified";
};

type MathNonEmptyFeedback = MathFeedbackBase & {
	status: "correct" | "incorrect";
	message: string;
};

export type MathFeedback = MathNonEmptyFeedback | MathEmptyFeedback;

type MultiFeedbackBase = FeedbackBase & {
	type: ExerciseType.Multi;
};

export type MultiFeedbackSubmitted = MultiFeedbackBase & {
	status: "correct" | "incorrect";
	perChoice: {
		choiceId: number;
		correctness: "correct" | "incorrect";
		message: string;
	}[];
};

export type MultiFeedback =
	| MultiFeedbackSubmitted
	| (MultiFeedbackBase & {
			status: "unspecified";
	  });

export type OpenFeedback = FeedbackBase & {
	type: ExerciseType.Open;
	selfAssessmentId?: number;
};

export type LogRecord = {
	stream: number;
	message: string;
};

export type CorrectOutputResult = {
	cputime: number;
	runtimeSerial: number;
	expect: string;
	log: LogRecord[];

	db?: {
		schemaAndState?: TableSchema;
		queryResult?: TableData;
	};
};

export type IncorrectOutputResult = {
	error: "output" | "runtime";
	runtimeSerial: number;
	errorPlace: {
		position: number;
		record: number;
	};
	log: LogRecord[];
	message: string;
	cputime: number;
	right: string;
	wrong: string | null;
	file: string | null;

	db?: {
		schemaAndState?: TableSchema;
		expectedSchemaAndState?: TableSchema;
		queryResult?: TableData;
		expectedQueryResult?: TableData;
	};
};

type SuccessProgRunResult = {
	mac: string;
	runtimes: CorrectOutputResult[];
};

type ErrorProgRunResult = {
	error: "output" | "runtime";
	runtimes: (IncorrectOutputResult | CorrectOutputResult)[];
};

export type CompilationError = {
	error: "compile";
	message: string;
	compilerMessage?: string;
	file?: string;
	line?: number;
};

type ProgFeedbackBase = FeedbackBase & {
	type: ExerciseType.Prog;
};

type SuccessRun = ProgFeedbackBase & {
	status: "correct";
	result: SuccessProgRunResult;
};

type ErrorRun = ProgFeedbackBase & {
	status: "incorrect";
	result: CompilationError | ErrorProgRunResult;
};

export type ProgramRunOutput =
	| SuccessProgRunResult
	| ErrorProgRunResult
	| CompilationError;

export type ProgFeedback = SuccessRun | ErrorRun | ProgFeedbackBase;

export function compilationError(
	result: CorrectOutputResult | IncorrectOutputResult | ProgramRunOutput
): result is CompilationError {
	return "error" in result && result.error === "compile";
}

type Feedback = MathFeedback | MultiFeedback | OpenFeedback | ProgFeedback;

export default Feedback;
