import {Localized} from "@fluent/react";
import {Chip, Skeleton, Stack} from "@mui/material";
import React from "react";

import UserRole from "../../store/models/UserRole";

const placeholderRoles = [
	UserRole.Administrator,
	UserRole.Teacher,
	UserRole.Student,
];

function UserRoleChips(props: {
	roles: UserRole[];
	align?: "right";
	loading?: boolean;
}) {
	const roles = props.loading ? placeholderRoles : props.roles;

	return (
		<Stack
			justifyContent={props.align === "right" ? "flex-end" : undefined}
			spacing={0.5}
			direction="row"
			sx={{flexWrap: "wrap"}}
		>
			{roles.map((r) => (
				<RoleChip role={r} key={r} loading={props.loading} />
			))}
		</Stack>
	);
}

function RoleChip(props: {role: UserRole; loading?: boolean}) {
	const chip = (
		<Chip
			label={
				<Localized id="user-role-chips-role" vars={{role: props.role}}>
					{props.role}
				</Localized>
			}
			variant="outlined"
			size="small"
		/>
	);

	return props.loading ? <Skeleton variant="text">{chip}</Skeleton> : chip;
}

export default UserRoleChips;
