import {Box, CircularProgress} from "@mui/material";
import React from "react";

const Loading = (): JSX.Element => {
	return (
		<Box display="flex" justifyContent="center">
			<CircularProgress color="primary" />
		</Box>
	);
};

export default Loading;
