import {useLocalization} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import {
	Box,
	ClickAwayListener,
	IconButton,
	Paper,
	Popper,
	Toolbar,
	Tooltip,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useEffect, useRef, useState} from "react";

import OutlinedSearchInput from "./OutlinedSearchInput";

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		iconButton: {
			padding: 10,
		},
		popper: {
			zIndex: 4,
		},
	})
);

function SearchToolbar<TCriteria extends {query?: string}>(props: {
	criteria: TCriteria;
	children: React.ReactNode;
	searchPlaceholder?: string;
	mainAction?: React.ReactNode;
	onCriteriaChange: (
		value: TCriteria | ((prevState: TCriteria) => TCriteria)
	) => void;
	onClear?: () => void;
}): JSX.Element {
	const {criteria, onCriteriaChange} = props;

	const classes = useStyles();

	const [query, setQuery] = useState(criteria.query);

	const [criteriaOpen, setCriteriaOpen] = useState(false);

	const searchField = useRef<HTMLDivElement>(null);

	const {l10n} = useLocalization();

	const init = useRef(true);

	useEffect(() => {
		if (init.current) {
			init.current = false;
			return () => {
				return;
			};
		}

		const timeoutId = setTimeout(
			() => onCriteriaChange((prev) => ({...prev, query})),
			500
		);
		return () => clearTimeout(timeoutId);
	}, [onCriteriaChange, query]);

	const noCriteriaApplied = Object.values(criteria).reduce(
		(prev, curr) => prev && !curr,
		true
	);

	function closeCriteria(event: MouseEvent | TouchEvent) {
		if (
			!(
				searchField.current &&
				event.target instanceof HTMLElement &&
				searchField.current.contains(event.target)
			)
		) {
			setCriteriaOpen(false);
		}
	}

	const labelClear = l10n.getString(
		"search-toolbar-action-clear",
		null,
		"Clear all filters"
	);

	const labelFilters = l10n.getString(
		"search-toolbar-action-filters",
		null,
		"Filters"
	);

	return (
		<>
			<Toolbar className={classes.root} disableGutters>
				{props.mainAction && (
					<Box ml={0.5} mr={2}>
						{props.mainAction}
					</Box>
				)}
				<OutlinedSearchInput
					ref={searchField}
					fullWidth
					value={query ?? ""}
					placeholder={props.searchPlaceholder}
					onChange={(val) => setQuery(val)}
					endAdornment={
						<>
							<Tooltip title={labelClear}>
								<IconButton
									className={classes.iconButton}
									onClick={() => {
										onCriteriaChange({} as TCriteria);
										setQuery("");
										setCriteriaOpen(false);
										props.onClear?.();
									}}
									style={{
										visibility: noCriteriaApplied ? "hidden" : "inherit",
									}}
									aria-label={labelClear}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title={labelFilters}>
								<IconButton
									color="primary"
									className={classes.iconButton}
									aria-label={labelFilters}
									onClick={() => setCriteriaOpen((prev) => !prev)}
								>
									<TuneIcon />
								</IconButton>
							</Tooltip>
						</>
					}
				/>
			</Toolbar>

			<Popper
				open={criteriaOpen}
				anchorEl={searchField.current}
				disablePortal
				placement="bottom-start"
				className={classes.popper}
				keepMounted
			>
				<Paper
					square
					style={{width: searchField.current?.offsetWidth}}
					variant="outlined"
				>
					<ClickAwayListener onClickAway={closeCriteria} mouseEvent="onMouseUp">
						<Box p={3}>{props.children}</Box>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
}

export default SearchToolbar;
