export function patchField<T extends Record<string, unknown>>(
	target: T,
	fieldName: keyof T,
	value?: unknown | null
): void {
	switch (value) {
		case undefined:
			return;
		case null:
			delete target[fieldName];
			return;
		default:
			Object.assign(target, {[fieldName]: value});
	}
}
