import selectChapterSessionSection from "./selectChapterSessionSection";
import type {RootState} from "../store";

const empty: string[] = [];

function selectSessionSubsectionKeys(
	state: RootState,
	sectionKey: string
): string[] {
	return selectChapterSessionSection(state, sectionKey)?.subsections ?? empty;
}

export default selectSessionSubsectionKeys;
