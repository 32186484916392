import {createAsyncThunk} from "@reduxjs/toolkit";

import selfEnrolmentService from "../services/selfEnrolmentService";

const fetchCoursesAvailableForEnrolment = createAsyncThunk(
	"selfEnrolment/fetchCoursesAvailableForEnrolment",
	async (params: {orgName: string; userId?: number}) => {
		const courses = await selfEnrolmentService.getCoursesAvailableForEnrolment(
			params.orgName,
			params.userId
		);

		return {courses, userId: params.userId};
	}
);

export default fetchCoursesAvailableForEnrolment;
