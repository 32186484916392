import type FetchStatus from "../FetchStatus";
import type FrontEndFeature from "./FrontEndFeature";
import type {RootState} from "../store";

function selectFrontEndFeatures(state: RootState): FrontEndFeature[] {
	return state.features.global.features;
}

function selectFrontEndFeaturesFetchStatus(state: RootState): FetchStatus {
	return state.features.global.status;
}

export default selectFrontEndFeatures;
export {selectFrontEndFeaturesFetchStatus};
