import {Localized} from "@fluent/react";
import {Box, Fab, Grid, Hidden, Tooltip, Zoom, useTheme} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import useNavBarHeight from "../../hooks/useNavBarHeight";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {fetchStudentMessages} from "../../store/tutorQuestions/fetchMessages";
import StudentMessageList from "./StudentMessageList";
import StudentMessage from "./StudentMessage";
import StudentNavigation from "./StudentNavigation";
import {selectCourseIdInTutorHelp} from "../../store/tutorQuestions/selectCourseIdInTutorHelp.ts";
import EmptyState from "./EmptyState";
import useEnsureChaptersLoaded from "../../hooks/useEnsureChaptersLoaded";
import NewQuestionForm from "./NewQuestionForm";
import StudentMessagesTab from "./StudentMessagesTab";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useMobileMode from "../../hooks/useMobileMode";
import {keyProvider} from "../../store/keyProvider";
import MessageSlidebar from "./MessageSlidebar";
import BackDestination from "../nav/BackDestination";

function TutorHelp(props: {courseId: number; pageLink: string}): JSX.Element {
	const {courseId} = props;

	const userId = useAppSelector(selectUserId);
	const navBarHeight = useNavBarHeight();
	const dispatch = useAppDispatch();

	const mobileMode = useMobileMode("md");
	const smallScreen = useMobileMode("lg");

	const history = useHistory<{newQuestion?: boolean}>();
	const newQuestion = Boolean(history.location.state?.newQuestion);

	const messageId = new URLSearchParams(history.location.search).get(
		"questionId"
	);
	const messageKey =
		messageId && keyProvider.tutorQuestion(parseInt(messageId));

	useEnsureChaptersLoaded(courseId);
	const checkCourseId = useAppSelector(selectCourseIdInTutorHelp);

	const fetchMessages = useCallback(
		(studentId: number, courseId: number) => {
			dispatch(fetchStudentMessages({studentId, courseId}));
		},
		[dispatch]
	);

	useEffect(() => {
		if (userId) {
			fetchMessages(userId, courseId);
		}
	}, [courseId, fetchMessages, userId]);

	const [selectedTab, setSelectedTab] = useState(
		messageKey ? StudentMessagesTab.Sent : StudentMessagesTab.Answered
	);

	const studentMessage = (
		<MessageSlidebar in={Boolean(messageKey) && !newQuestion}>
			<StudentMessage
				currentMessage={messageKey}
				selectedTab={selectedTab}
				courseId={courseId}
				studentId={userId}
				mobileMode={mobileMode}
			/>
		</MessageSlidebar>
	);

	const questionForm = (
		<MessageSlidebar in={newQuestion}>
			<NewQuestionForm
				open={newQuestion}
				courseId={courseId}
				userId={userId}
				mobileMode={mobileMode}
				onClose={() => history.push(`?`)}
			/>
		</MessageSlidebar>
	);

	if (checkCourseId !== courseId) {
		return <></>;
	}

	let content = <></>;

	if (mobileMode && (newQuestion || messageKey)) {
		content = (
			<BackDestination key="message" path={props.pageLink}>
				{newQuestion ? questionForm : studentMessage}
			</BackDestination>
		);
	} else {
		content = (
			<TutorHelpPage
				courseId={courseId}
				studentId={userId}
				messageKey={messageKey}
				mobileMode={mobileMode}
				smallScreen={smallScreen}
				questionForm={questionForm}
				emptyState={!messageKey && !newQuestion && <EmptyState />}
				studentMessage={studentMessage}
				selectedTab={selectedTab}
				onTabChange={(tab) => {
					if (tab !== selectedTab) {
						setSelectedTab(tab);
						history.push("?");
					}
				}}
				onReload={() => fetchMessages(userId, courseId)}
			/>
		);
	}

	return (
		<Box
			display="flex"
			height={`calc(100vh - ${navBarHeight + 1}px)`}
			style={{height: `calc(100dvh - ${navBarHeight + 1}px)`}}
		>
			{content}

			<Box position="fixed" bottom={0} right={0} mr={4} mb={4}>
				<Zoom in={smallScreen && !newQuestion} unmountOnExit>
					<Tooltip
						title={
							<Localized id="tutoring-button-ask-tutor">Ask tutor</Localized>
						}
						enterDelay={500}
						enterNextDelay={500}
					>
						<Fab
							color="primary"
							onClick={() => history.push("?", {newQuestion: true})}
						>
							<CreateIcon />
						</Fab>
					</Tooltip>
				</Zoom>
			</Box>
		</Box>
	);
}

function TutorHelpPage(props: {
	courseId: number;
	messageKey: string | null;
	studentId: number;
	mobileMode: boolean;
	smallScreen: boolean;
	studentMessage: React.ReactElement;
	questionForm: React.ReactElement;
	emptyState: React.ReactNode;
	selectedTab: StudentMessagesTab;
	onTabChange: (tab: StudentMessagesTab) => void;
	onReload: () => void;
}) {
	const theme = useTheme();

	const [navOpen, setNavOpen] = useState(false);

	function selectMessagesTab(tab: StudentMessagesTab) {
		props.onTabChange(tab);
		setNavOpen(false);
	}

	let width = "220px";
	if (props.mobileMode) {
		width = `calc(100vw - ${theme.spacing(7)})`;
	} else if (props.smallScreen) {
		width = "38vw";
	}

	return (
		<>
			<StudentNavigation
				open={!props.smallScreen || navOpen}
				studentId={props.studentId}
				selectedTab={props.selectedTab}
				variant={props.smallScreen ? "temporary" : "permanent"}
				width={width}
				smallScreen={props.smallScreen}
				onClose={() => setNavOpen(false)}
				onSelect={selectMessagesTab}
			/>

			<Grid container style={{height: "100%", overflowX: "hidden"}}>
				<Grid item xs={12} md={4} lg={4} style={{height: "100%"}}>
					<StudentMessageList
						courseId={props.courseId}
						studentId={props.studentId}
						selectedTab={props.selectedTab}
						selectedMessageKey={props.messageKey}
						onNavClick={props.smallScreen ? () => setNavOpen(true) : undefined}
						onReload={props.onReload}
					/>
				</Grid>

				<Hidden mdDown>
					<Grid
						item
						md={8}
						lg={8}
						style={{height: "100%", position: "relative"}}
					>
						{props.emptyState}
						{props.studentMessage}
						{props.questionForm}
					</Grid>
				</Hidden>
			</Grid>
		</>
	);
}

export default TutorHelp;
