import React, {createContext, useState} from "react";

import type ExerciseType from "../../store/exercises/ExerciseType";

type PageParams = {
	studentId: number;
	chapterId: number;
	exerciseId: number;
	exerciseType: ExerciseType;
};

const AssessmentPageDialogContext = createContext<{
	pageParams: PageParams | null;
	openDialog: (params: PageParams) => void;
	closeDialog: () => void;
}>({
	pageParams: null,
	openDialog() {
		return;
	},
	closeDialog() {
		return;
	},
});

const AssessmentPageDialogContextProvider = (props: {
	children: React.ReactNode;
}): JSX.Element => {
	const [pageParams, setPageParams] = useState<PageParams | null>(null);

	return (
		<AssessmentPageDialogContext.Provider
			value={{
				pageParams,
				openDialog: setPageParams,
				closeDialog: () => setPageParams(null),
			}}
		>
			{props.children}
		</AssessmentPageDialogContext.Provider>
	);
};

export default AssessmentPageDialogContext;
export {AssessmentPageDialogContextProvider};
