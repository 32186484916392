import {
	axiosInstance as client,
	isAxiosError as httpClientError,
} from "./axiosInstance";
import type {EditableExercise} from "./dtos/EditableExercise";
import type {ExerciseFromBank} from "./dtos/ExerciseFromBank";
import type AnswerVisibility from "../chapterExercises/AnswerVisibility";
import type ConditionType from "../exercises/ConditionType";
import type ExerciseAnswer from "../exercises/ExerciseAnswer";
import ExerciseType from "../exercises/ExerciseType";
import type FeedbackRule from "../exercises/FeedbackRule";
import {createPage} from "../../helpers/paginatedSearchHelpers";
import type {Page} from "../../helpers/paginatedSearchHelpers";
import TagSearchResult from "../exercises/TagSearchResult";
import {mapTableSchema} from "./progExerciseService";

export type ExercisePageRequest = {
	pageSize: number;
	sort: {field: string; order: "asc" | "desc"};
	core: string;
	language: string;
	courseId?: number;
	organisationName?: string;
	userId?: number;
	exerciseType?: ExerciseType;
	query?: string;
	tags?: string[];
};

export type TagSearchCriteria = {
	scope:
		| "course_exercises"
		| "exercises_public_to_organisation"
		| "organisation_or_user_exercises"
		| "public_exercises"
		| "user_exercises";
	courseId?: number;
	organisationName?: string;
	userId?: number;
	prefix?: string;
};

type AnswerRepresentation =
	| {
			exerciseType: ExerciseType.Math;
			solution: string;
			typeSpecific: {
				finalSteps: string[];
			};
	  }
	| {
			exerciseType: ExerciseType.Multi;
			solution: string;
			typeSpecific: {
				choices: {
					id: number;
					text: string;
				}[];
			};
	  }
	| {
			exerciseType: ExerciseType.Open;
			solution: string;
			typeSpecific?: {
				shortText: string;
			};
	  }
	| {
			exerciseType: ExerciseType.Prog;
			solution: string;
			typeSpecific: {
				files: {
					name: string;
					content: string;
				}[];
			};
	  };

const exerciseService = {
	getExercise: async (exerciseId: number): Promise<EditableExercise> => {
		let resp;

		try {
			const {data} = await client.get(`/api/exercises/${exerciseId}`);
			resp = data;
		} catch (err) {
			if (httpClientError(err) && err.response?.status === 403) {
				throw {code: "forbidden"};
			} else if (httpClientError(err) && err.response?.status === 404) {
				throw {code: "not_found"};
			} else {
				throw err;
			}
		}

		const common: {
			type: ExerciseType;
			title: string;
			question: string;
			solution: string;
			difficultyLevel: number;
			privacy: string;
			category: string;
			tags: string[];

			authorId?: number;
			originId?: number;
		} = {
			type: resp.type,
			title: resp.title,
			question: resp.question,
			solution: resp.solution ?? "",
			difficultyLevel: resp.difficultyLevel,
			privacy: resp.privacyLevel,
			category: resp.category ?? "",
			tags: resp.tags ?? ([] as string[]),
		};

		if (resp.authorId) {
			common.authorId = resp.authorId;
		}
		if (resp.originId) {
			common.originId = resp.originId;
		}

		let exercise;

		switch (resp.type) {
			default:
				throw new Error("Unsupported exercise type");
			case ExerciseType.External:
				exercise = {
					...common,
					...resp.settings,
					maxScore: resp.maxScore,
				};

				break;
			case ExerciseType.Math:
				exercise = {
					...common,
					...resp.settings,
					maxScore: resp.maxScore,
				};

				break;
			case ExerciseType.Multi:
				exercise = {
					...common,
					...resp.settings,
					maxScore: resp.maxScore,
					choices: resp.settings.choices.map(
						(choice: {
							id: number;
							text: string;
							score: number;
							message: string;
						}) => ({
							id: choice.id,
							text: choice.text,
							score: choice.score,
							comment: choice.message,
						})
					),
				};

				break;
			case ExerciseType.Open:
				exercise = {
					...common,
					...resp.settings,
					maxScore: resp.maxScore,
					finalAnswer: resp.settings.finalAnswer ?? "",
				};

				break;
			case ExerciseType.Prog: {
				const settings = resp.settings;

				if (resp.settings.language === "sql") {
					settings.initialSchemaAndState = mapTableSchema(
						settings.initialSchemaAndState
					);
				}

				exercise = {
					...common,
					...settings,
					maxScore: resp.maxScore,
				};

				break;
			}
		}

		return exercise;
	},

	getExerciseAnswer: async (
		courseId: number,
		chapterId: number,
		exerciseId: number
	): Promise<ExerciseAnswer> => {
		const {data} = await client.get<AnswerRepresentation>(
			`/api/courses/${courseId}/chapters/${chapterId}/exercises/${exerciseId}/answer`
		);

		let answer;

		switch (data.exerciseType) {
			case ExerciseType.Math:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					finalAnswer: data.typeSpecific.finalSteps,
				};

				break;
			case ExerciseType.Multi:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					finalAnswer: data.typeSpecific.choices.map((ch) => ch.text),
				};

				break;
			case ExerciseType.Open:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					finalAnswer: data.typeSpecific?.shortText ?? "",
				};

				break;
			case ExerciseType.Prog:
				answer = {
					exerciseType: data.exerciseType,
					solution: data.solution,
					files: data.typeSpecific.files,
				};

				break;
			default:
				throw new Error();
		}

		return answer;
	},

	deleteExercise: async (exerciseId: number): Promise<void> => {
		const url = `/api/exercises/${exerciseId}`;

		try {
			await client.delete(url);
		} catch (error) {
			if (httpClientError(error) && error.response?.status === 409) {
				throw {code: "conflict"};
			} else {
				throw error;
			}
		}
	},

	saveMathFeedbackRules: async (
		courseId: number,
		exerciseId: number,
		rules: FeedbackRule[]
	): Promise<void> => {
		const url = `/api/courses/${courseId}/exercises/${exerciseId}/math-feedback`;

		const payload = {
			cust_feed: rules.map((r) => ({
				condition_type: r.conditionType,
				math_expression: r.expression,
				feedback: r.message,
			})),
		};

		const {data} = await client.put<{success?: 1}>(url, payload);
		if (!data.success) {
			throw new Error();
		}
	},

	getMathFeedbackRules: async (
		courseId: number,
		exerciseId: number
	): Promise<FeedbackRule[]> => {
		const {data} = await client.get<
			{
				feedback: string;
				math_expression: string;
				condition_type: ConditionType;
			}[]
		>(`/api/courses/${courseId}/exercises/${exerciseId}/math-feedback`);

		return data.map((r) => ({
			conditionType: r.condition_type,
			expression: r.math_expression,
			message: r.feedback,
		}));
	},

	patchExerciseSettings: async (
		courseId: number,
		chapterId: number,
		exerciseId: number,
		settings: {
			maxScore?: number;
			answerVisibility?: AnswerVisibility | null;
		}
	): Promise<void> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/exercises/${exerciseId}`;

		await client.patch(url, settings, {
			headers: {
				"Content-Type": "application/merge-patch+json",
			},
		});
	},

	searchExercises: async (
		request: ExercisePageRequest
	): Promise<Page<ExerciseFromBank>> => {
		const url = "/api/exercises";
		const params = new URLSearchParams();

		params.append(
			"sort",
			request.sort.order === "desc"
				? `-${request.sort.field}`
				: request.sort.field
		);
		params.append("pageSize", request.pageSize.toString());
		params.append("core", request.core);
		params.append("language", request.language);

		if (request.query) {
			params.append("query", request.query);
		}

		if (request.organisationName) {
			params.append("organisationName", request.organisationName);
		} else if (request.courseId) {
			params.append("courseId", request.courseId.toString());
		} else if (request.userId) {
			params.append("userId", request.userId.toString());
		}

		if (request.exerciseType) {
			params.append("exerciseType", request.exerciseType);
		}

		if (request.tags) {
			request.tags.forEach((tag) => params.append("tag", tag));
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	searchTags: async (
		criteria: TagSearchCriteria,
		pageSize: number
	): Promise<Page<TagSearchResult>> => {
		const url = `/api/exercise-tags`;
		const params = new URLSearchParams();

		params.append("scope", criteria.scope);
		params.append("pageSize", pageSize.toString());

		if (criteria.prefix) {
			params.append("prefix", criteria.prefix);
		}

		if (
			criteria.organisationName &&
			(criteria.scope === "exercises_public_to_organisation" ||
				criteria.scope === "organisation_or_user_exercises")
		) {
			params.append("organisationName", criteria.organisationName);
		}

		if (criteria.courseId && criteria.scope === "course_exercises") {
			params.append("courseId", criteria.courseId.toString());
		}

		if (
			criteria.userId &&
			(criteria.scope === "organisation_or_user_exercises" ||
				criteria.scope === "user_exercises")
		) {
			params.append("userId", criteria.userId.toString());
		}

		const {data} = await client.get(url, {params});

		return createPage(client, data.content, data.links);
	},

	updateExerciseTags: async (
		exerciseId: number,
		tags: string[]
	): Promise<void> => {
		const url = `/api/exercises/${exerciseId}/tags`;

		await client.put(url, tags);
	},

	copyExercise: async (
		courseId: number,
		exerciseId: number
	): Promise<number> => {
		const {data} = await client.post(`/api/courses/${courseId}/exercises`, {
			action: "copy",
			exercise_template_id: exerciseId,
		});
		if (!data.exercise_template_id) {
			throw new Error();
		}
		return data.exercise_template_id;
	},
};

export default exerciseService;
