function getNotificationMessage(
	courseName: string,
	notification: string,
	language: "english" | "finnish"
) {
	if (language === "english") {
		switch (notification) {
			case "course_not_opened_after_enrolment":
				return {
					subject: `Reminder to start course '${courseName}'`,
					body: `Hi, you have enrolled in course '${courseName}'. We kindly encourage you to start studying.\n\nHappy studies!`,
				};

			case "course_not_started_after_enrolment":
				return {
					subject: `Reminder to start course '${courseName}'`,
					body: `Hi, you enrolled in course '${courseName}' two weeks ago. We kindly encourage you to start studying.\n\nHappy studies!`,
				};

			case "course_study_period_ends_soon":
				return {
					subject: `Study time notification for course '${courseName}'`,
					body: `Hi, only one week of planned study time left. Keep up working to reach the target.\n\nHappy studies!`,
				};

			case "half_of_course_study_period_passed":
				return {
					subject: `Study time notification for course '${courseName}'`,
					body: `Hi, half of your study time has passed. Keep up working to reach the target.\n\nHappy studies!`,
				};

			case "course_targets_accomplished":
				return {
					subject: `Course targets were accomplished for course '${courseName}'`,
					body: `Hi, we are happy to inform that you have reached the minimum target of course '${courseName}'. Please, contact viopesupport@metropolia.fi and ask for grading.\n\nWell done!`,
				};
		}
	}

	if (language === "finnish") {
		switch (notification) {
			case "course_not_opened_after_enrolment":
				return {
					subject: `Muistutus kurssille "${courseName}" osallistumisesta`,
					body: `Hei, olet ilmottautunut kurssille "${courseName}". Voit aloittaa opiskelun kurssilla.\n\nMukavia opiskeluhetkiä!\n\n`,
				};

			case "course_not_started_after_enrolment":
				return {
					subject: `Muistutus kurssille "${courseName}" osallistumisesta`,
					body: `Hei, olet ilmottautunut kurssille "${courseName}" kaksi viikkoa sitten. Voit aloittaa opiskelun kurssilla.\n\nMukavia opiskeluhetkiä!`,
				};

			case "course_study_period_ends_soon":
				return {
					subject: `Ilmoitus "${courseName}" -kurssin opiskeluajasta`,
					body: `Hei, vielä viikko opskeluaikaa jäljellä. Tsemppiä tavoitteen saavuttamiseen.\n\nMukavia opiskeluhetkiä!`,
				};

			case "half_of_course_study_period_passed":
				return {
					subject: `Ilmoitus "${courseName}" -kurssin opiskeluajasta`,
					body: `Hei, puolet kurssin opiskeluajasta on kulunut. Tsemppiä tavoitteen saavuttamiseen.\n\nMukavia opiskeluhetkiä!`,
				};

			case "course_targets_accomplished":
				return {
					subject: `Kurssin "${courseName}" opiskelutavoitteet saavutettu`,
					body: `Hei, olet saavuttanut kurssille "${courseName}" asetetun minimi tavoitteen. Lähetä viesti osoitteeseen viopesupport@metropolia.fi ja pyydä arviointia.\n\nHyvin tehty!`,
				};
		}
	}

	return {subject: "", body: ""};
}

export default getNotificationMessage;
