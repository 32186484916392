import {Localized, useLocalization} from "@fluent/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Dialog,
	IconButton,
	Menu,
	Tooltip,
	Typography,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {TreeItem, treeItemClasses} from "@mui/x-tree-view/";
import type {TreeItemProps} from "@mui/x-tree-view/";
import React, {useCallback, useState} from "react";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";

import ChapterStatusBadge from "./ChapterStatusBadge";
import ChapterMenu from "./ChapterMenu";
import {useAppDispatch} from "../../store/hooks";
import createPublishChapterAction from "../learningMaterial/createPublishChapterAction";
import createUnpublishChapterAction from "../learningMaterial/createUnpublishChapterAction";
import ScheduleChapterDialog from "../learningMaterial/ScheduleChapterDialog";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";
import {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import createDeleteChapterAction from "../learningMaterial/createDeleteChapterAction";

type Props = TreeItemProps & {
	labelText?: string;
	number: number;
	startDate: string | undefined;
	courseId: number;
	chapterId: number;
	canAddSection: boolean;
	courseEditing: boolean;
	chapterEditing: boolean;
	draft?: boolean;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
};

const useTreeItemStyles = makeStyles((theme) =>
	createStyles({
		labelRoot: {
			display: "flex",
			alignItems: "flex-start",
			backgroundColor: theme.palette.primary.contrastText,
			justifyContent: "space-between",
		},
		number: {
			marginRight: theme.spacing(2),
		},
	})
);

function StyledTreeItem(props: Props): JSX.Element {
	const classes = useTreeItemStyles();
	const dispatch = useAppDispatch();
	const history = useHistory();
	const location = useLocation();
	const {url: baseUrl} = useRouteMatch();

	const {l10n} = useLocalization();

	const [menuAnchor, setMenuAnchor] = React.useState<null | Element>(null);

	const openMenu = (event: React.MouseEvent<Element>): void => {
		event.stopPropagation();
		setMenuAnchor(event.currentTarget);
	};

	const closeMenu = () => {
		setMenuAnchor(null);
	};

	const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
	const openSchedule = () => {
		closeMenu();
		setScheduleDialogOpen(true);
	};
	const closeScheduleDialog = useCallback(
		() => setScheduleDialogOpen(false),
		[]
	);

	const {
		labelText,
		number,
		startDate,
		courseId,
		chapterId,
		draft,
		openConfirmDialog,
		showSnackbar,
		canAddSection,
		courseEditing,
		chapterEditing,
		viewOnly,
		...other
	} = props;

	const publishChapter = createPublishChapterAction(
		courseId,
		l10n,
		dispatch,
		showSnackbar
	);

	const unpublishChapter = createUnpublishChapterAction(
		courseId,
		l10n,
		dispatch,
		showSnackbar
	);

	const handlePublishChapter = () => {
		publishChapter(chapterId);

		closeMenu();
	};

	const handleUnpublishChapter = () => {
		unpublishChapter(chapterId);

		closeMenu();
	};

	const deleteChapter = createDeleteChapterAction(
		courseId,
		dispatch,
		openConfirmDialog
	);

	const addSection = () => {
		history.push(
			`/courses/${courseId}/management/content/chapters/${chapterId}/sections/new`
		);

		closeMenu();
	};

	const chapterClickHandler = (e: {preventDefault: () => void}) => {
		const clickedChapterPath = `${baseUrl}/chapters/${chapterId}`;
		if (
			!draft &&
			location.pathname !== clickedChapterPath &&
			location.pathname !== clickedChapterPath + "/"
		) {
			e.preventDefault();
			history.push(clickedChapterPath);
		}
	};

	return (
		<>
			<TreeItem
				label={
					<div className={classes.labelRoot} onClick={chapterClickHandler}>
						<Box display="flex" flexGrow={1} pt={1.5}>
							<Typography color="textSecondary" className={classes.number}>
								{number}
							</Typography>

							<Typography>{labelText}</Typography>
						</Box>
						<Box py={2}>
							<ChapterStatusBadge startDate={startDate} size="small" />
						</Box>
						{!viewOnly && (
							<Tooltip
								title={
									<Localized id="dragdrop-actions-button-tooltip">
										Actions
									</Localized>
								}
								enterDelay={500}
								enterNextDelay={500}
							>
								<IconButton color="primary" disabled={draft} onClick={openMenu}>
									<MoreVertIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>
				}
				sx={{
					[`& .${treeItemClasses.content}:hover, .${treeItemClasses.content}.Mui-focused`]: {
						backgroundColor: "inherit",
					},
					[`& .${treeItemClasses.content}`]: {
						padding: 0,
					},
					[`& .${treeItemClasses.iconContainer}`]: {
						alignSelf: "start",
						paddingTop: "0.9rem",
					},
				}}
				{...other}
			/>

			<Dialog
				open={scheduleDialogOpen}
				onClose={closeScheduleDialog}
				maxWidth="xs"
				fullWidth
			>
				<ScheduleChapterDialog
					courseId={courseId}
					chapterId={chapterId}
					onClose={closeScheduleDialog}
				/>
			</Dialog>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				onClose={closeMenu}
			>
				<div>
					<ChapterMenu
						startDate={startDate}
						publish={handlePublishChapter}
						unpublish={handleUnpublishChapter}
						schedule={openSchedule}
						addSection={addSection}
						canAddSection={canAddSection}
						courseEditing={courseEditing}
						chapterEditing={chapterEditing}
						onDelete={() => {
							closeMenu();
							deleteChapter(chapterId, labelText ?? "");
						}}
					/>
				</div>
			</Menu>
		</>
	);
}

export default StyledTreeItem;
