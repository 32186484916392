import {Localized, useLocalization} from "@fluent/react";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";

import ChooseExerciseDialog from "./ChooseExerciseDialog";
import NewMessageForm from "./NewMessageForm";
import useQuestionFileUploader from "./useQuestionFileUploader";
import selectChapterExercise from "../../store/chapterExercises/selectChapterExercise";
import selectChapter from "../../store/chapters/selectChapter";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import selectSectionOfExercise from "../../store/sections/selectSectionOfExercise";
import {sendNewQuestion} from "../../store/tutorQuestions/sendNewQuestion";
import selectCourse from "../../store/courses/selectCourse";
import CourseType from "../../store/courses/CourseType";

const NewQuestionForm = (props: {
	open: boolean;
	courseId: number;
	userId: number;
	mobileMode: boolean;
	onClose: () => void;
}): JSX.Element => {
	const {courseId, userId} = props;

	const [files, fileUploader] = useQuestionFileUploader(courseId, userId);

	const [exerciseDialogOpened, setExerciseDialogOpened] = useState(false);
	const [chosenExercise, setChosenExercise] = useState<string | null>(null);

	useEffect(() => {
		if (!props.open) {
			setChosenExercise(null);
		}
	}, [props.open]);

	const closeExerciseDialog = (exerciseKey?: string) => {
		setExerciseDialogOpened(false);
		if (exerciseKey) {
			setChosenExercise(exerciseKey);
		}
	};

	const sectionTitle = useAppSelector((state) =>
		chosenExercise
			? selectSectionOfExercise(state, chosenExercise)?.title
			: null
	);
	const exercise = useAppSelector((state) =>
		chosenExercise ? selectChapterExercise(state, chosenExercise) : null
	);
	const chapterTitle = useAppSelector((state) =>
		exercise
			? selectChapter(state, keyProvider.chapter(exercise.chapterId))?.title
			: null
	);

	const exam = useAppSelector(
		(state) =>
			selectCourse(state, keyProvider.course(courseId))?.type ===
			CourseType.Exam
	);

	const resetChoice = () => {
		setChosenExercise(null);
	};

	const dispatch = useAppDispatch();

	const sendQuestion = async (question: string) => {
		const res = await dispatch(
			sendNewQuestion({
				courseId,
				studentId: userId,
				question: question,
				attachmentIds: files.map((f) => f.id),
				exerciseKey: chosenExercise,
			})
		);
		unwrapResult(res);
	};

	const {l10n} = useLocalization();

	const subject = chosenExercise
		? `${chapterTitle} / ${sectionTitle} / ${exercise?.title}`
		: l10n.getString(
				"tutoring-subject-general-question",
				null,
				"General question"
		  );

	return (
		<>
			<NewMessageForm
				title={
					<Localized id="tutoring-new-message-form-title">
						New question
					</Localized>
				}
				subject={subject}
				fileUploader={fileUploader}
				attachments={files}
				mobileMode={props.mobileMode}
				onCancel={props.onClose}
				onSubmit={sendQuestion}
				onChooseSubjectClick={
					exam ? undefined : () => setExerciseDialogOpened(true)
				}
				onResetSubjectClick={chosenExercise ? resetChoice : undefined}
			/>
			<ChooseExerciseDialog
				open={exerciseDialogOpened}
				courseId={props.courseId}
				mobileMode={props.mobileMode}
				onClose={closeExerciseDialog}
			/>
		</>
	);
};

export default NewQuestionForm;
